import Parse from "parse";

import type { Form } from "./Form";

export interface UnitAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  forms: Parse.Relation<Unit, Form>;
  image?: Parse.File;
  name?: string;
  source?: string;
}

export class Unit extends Parse.Object<UnitAttributes> {
  static className: string = "OD3_BDE_Unit";

  constructor(data?: Partial<UnitAttributes>) {
    super("OD3_BDE_Unit", data as UnitAttributes);
  }

  get forms(): Parse.Relation<Unit, Form> {
    return super.relation("forms");
  }
  get image(): Parse.File | undefined {
    return super.get("image");
  }
  set image(value: Parse.File | undefined) {
    super.set("image", value);
  }
  get name(): string | undefined {
    return super.get("name");
  }
  set name(value: string | undefined) {
    super.set("name", value);
  }
  get source(): string | undefined {
    return super.get("source");
  }
  set source(value: string | undefined) {
    super.set("source", value);
  }
}

Parse.Object.registerSubclass("OD3_BDE_Unit", Unit);
