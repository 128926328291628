import { AdminLayout, createInternalComponent } from "@opendash/core";
import * as React from "react";
import { Explorer } from "..";

interface Props {}

export const ExplorerRoute = createInternalComponent<Props>(
  function ExplorerRoute({}) {
    return (
      <AdminLayout contentPadding={true}>
        <Explorer />
      </AdminLayout>
    );
  }
);
