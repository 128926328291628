import { App, ConfigProvider } from "antd";
import { ConfigProviderProps } from "antd/lib/config-provider";
import * as React from "react";
import { $framework, useTranslation } from "..";

export function AntDesignProvider({ children }) {
  const { locale } = useLocale();

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: $framework.ui2.primaryColor,
          // colorSuccess: "#1e8c00",
          // colorWarning: "#ff9900",
          // colorError: "#8c0000",
          // colorInfo: "#666767",
          // colorPrimaryBg: "#dedede",
          borderRadius: 3,
          wireframe: true,
        },
      }}
    >
      <App>{children}</App>
    </ConfigProvider>
  );
}

function useLocale(): ConfigProviderProps {
  const t = useTranslation();

  return React.useMemo(() => {
    let locale = undefined;

    try {
      locale = JSON.parse(t("antd:json", { default: "{}" }));

      if (!locale.locale) {
        locale = undefined;
      }
    } catch (error) {
      console.error("@opendash/core: Error in translation parsing:", error);
    }

    return {
      locale,
    };
  }, [t]);
}
