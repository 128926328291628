import { RangeWithValuePicker } from "@opendash/ui";

import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import { Button, Drawer } from "antd";
import React from "react";
import { CustomParseFieldProps } from "../types";
type ArrayRangeWithValuePickerProps = CustomParseFieldProps;
const ArrayRangeWithValuePicker: React.FC<ArrayRangeWithValuePickerProps> = ({
  value,
  parseObject,
  fieldname,
  props,
  setValue,
}: ArrayRangeWithValuePickerProps) => {
  const [open, setOpen] = React.useState(false);
  const t = useTranslation();
  if (!setValue) {
    return <span>[{value.length}]</span>;
  }

  return (
    <>
      <Button
        icon={<Icon icon="fa:pen" />}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      />
      <Drawer
        open={open}
        placement="right"
        width={"50%"}
        onClose={() => setOpen(false)}
        title={t(props.rangeKeyLabel) + " - " + t(props.rangeValueLabel)}
      >
        <RangeWithValuePicker
          data={value}
          keyField={props.rangeKey}
          valueField={props.rangeValue}
          keyLabel={props.rangeKeyLabel}
          valueLabel={props.rangeValueLabel}
          onChange={(data) => {
            setValue(data);
          }}
        />
      </Drawer>
    </>
  );
  return <div>{/* Your component implementation */}</div>;
};

export default ArrayRangeWithValuePicker;
