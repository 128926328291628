import { AdminLayout, useTranslation } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import * as React from "react";
import { DataSelect } from "..";

interface Props {}

export const DataItemOverviewRoute = React.memo<Props>(
  function DataItemOverviewRoute() {
    const t = useTranslation();
    const [searchString, setSearchString] = React.useState("");

    return (
      <AdminLayout>
        <AdminToolbar
          title={t("opendash:account.data_item_settings.label")}
          description={t("opendash:account.data_item_settings.description")}
          search={searchString}
          onSearch={setSearchString}
          actions={[]}
        />
        <div className="od-page-main">
          <DataSelect showActions={true} />
        </div>
      </AdminLayout>
    );
  }
);
