import { Checkbox } from "antd";
import React from "react";

export interface BooleanDisplayProps {
  value: boolean;
}

export const BooleanDisplay = React.memo<BooleanDisplayProps>(
  function BooleanDisplay({ value }) {
    return <Checkbox checked={value} disabled />;
  }
);

export interface BooleanEditProps {
  value: boolean;
  setValue?: (v: boolean) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const BooleanEdit = React.memo<BooleanEditProps>(function BooleanEdit({
  value,
  setValue,
}) {
  return (
    <Checkbox
      checked={value}
      onChange={(e) => {
        setValue(e.target.checked);
      }}
    />
  );
});
