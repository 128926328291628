import { useTranslation } from "@opendash/core";
import { Select } from "antd";
import React from "react";

export interface StringSelectDisplayProps {
  value: string;
  options: [any, string][];
}

export const StringSelectDisplay = React.memo<StringSelectDisplayProps>(
  function StringSelectDisplay({ value, options }) {
    const t = useTranslation();

    const labels = Object.fromEntries(options);

    return <span>{t(labels[value])}</span>;
  }
);

export interface StringSelectEditProps {
  value: string;
  setValue?: (v: string) => void;
  options: [any, string][];
  isRequired: boolean;
  isNullable: boolean;
}

export const StringSelectEdit = React.memo<StringSelectEditProps>(
  function StringSelectEdit({ value, setValue, options }) {
    const t = useTranslation();

    return (
      <Select
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
      >
        {options.map(([value, label], i) => {
          return (
            <Select.Option key={`${i}-${value}`} value={value}>
              {t(label)}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
);
