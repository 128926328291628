import * as React from "react";
import * as ReactDOM from "react-dom";
import { AppRefsInterface, useOpenDashApp } from "..";

interface Props {
  place: keyof AppRefsInterface;
}

export const AppPortal = React.memo<React.PropsWithChildren<Props>>(
  function AppPortal({ place, children }) {
    const app = useOpenDashApp();

    if (!app._refs[place].current) {
      return null;
    }

    return ReactDOM.createPortal(children, app._refs[place].current);
  }
);
