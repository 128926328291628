import { useState } from "react";
import { useEventListener } from "..";

/**
 * A hook to store a value in local storage.
 *
 * @param key The key to store the value under
 * @param initialValue The initial value to store
 * @returns A tuple containing the stored value and a function to set the value
 */
export function useLocalStorage<T>(
  key: string,
  initialValue?: T
): [T, (next: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);

      if (item === "undefined") {
        return initialValue;
      }

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  useEventListener("storage", ({ key: k, newValue }) => {
    if (k === key && JSON.stringify(storedValue) !== newValue) {
      setStoredValue(JSON.parse(newValue));
    }
  });

  const setValue = (value: any) => {
    const newValue = value instanceof Function ? value(storedValue) : value;

    setStoredValue(newValue);

    window.localStorage.setItem(key, JSON.stringify(newValue));

    window.dispatchEvent(
      new StorageEvent("storage", {
        key,
        newValue: JSON.stringify(newValue),
      })
    );
  };

  return [storedValue, setValue];
}
