import { $framework, useTranslation } from "@opendash/core";
import { Link, useParams } from "@opendash/router";
import { createComponent } from "@opendash/state";
import { Button, Result } from "antd";
import React from "react";
import { AuthLayout } from "./AuthLayout";

export const AuthMessageRoute = createComponent(function AuthMessageRoute() {
  const { type } = useParams();
  const t = useTranslation();

  const appButton = (
    <Button
      key="login"
      type="primary"
      style={{ margin: "2px" }}
      children={t("parse-admin:auth.back_to_login")}
      onClick={() => {
        $framework.router.navigate("/auth/login");
      }}
    />
  );

  const homeButton = (
    <Button
      key="signup"
      children={t("parse-admin:auth.action_signup")}
      style={{ margin: "2px" }}
      onClick={() => {
        $framework.router.navigate(`/auth/signup`);
      }}
    />
  );

  const verifyEmailButton = (
    <Button
      key="request-verify-email"
      type="primary"
      style={{ margin: "2px" }}
      children={t("parse-admin:auth.action_verify_email_request")}
      onClick={() => {
        $framework.router.navigate(`/auth/account/verify-email-request`);
      }}
    />
  );

  const resetPasswordButton = (
    <Button
      key="reset-password"
      type="primary"
      style={{ margin: "2px" }}
      children={t("parse-admin:auth.action_reset_password_request")}
      onClick={() => {
        $framework.router.navigate(`/auth/account/password-reset-request`);
      }}
    />
  );

  const messages: Record<
    string,
    {
      status: "success" | "error" | "info" | "warning";
      extra: any[];
    }
  > = {
    "login-passwordless-success": {
      status: "success",
      extra: [],
    },
    "signup-success": {
      status: "success",
      extra: [verifyEmailButton, appButton],
    },
    "verify-email-success": {
      status: "success",
      extra: [appButton, homeButton],
    },
    "verify-email-expired": {
      status: "error",
      extra: [verifyEmailButton, appButton],
    },
    "verify-email-invalid": {
      status: "error",
      extra: [verifyEmailButton, appButton],
    },
    "verify-email-send-failed": {
      status: "error",
      extra: [verifyEmailButton, appButton],
    },
    "verify-email-send-success": {
      status: "success",
      extra: [verifyEmailButton, appButton],
    },
    "password-reset-invalid": {
      status: "error",
      extra: [resetPasswordButton, appButton],
    },
    "password-reset-success": {
      status: "success",
      extra: [appButton],
    },
    "password-reset-send-success": {
      status: "success",
      extra: [appButton],
    },
  };

  if (type && type in messages) {
    const msg = messages[type];
    const x = type.replaceAll("-", "_");

    return (
      <AuthLayout>
        <Result
          status={msg.status}
          title={t(`parse-admin:auth.message_${x}_title`)}
          subTitle={t(`parse-admin:auth.message_${x}_description`)}
          // style={{ maxWidth: 500 }}
          extra={msg.extra}
        />
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <ul>
        {Object.keys(messages).map((key) => (
          <li>
            <Link key={key} to={`/auth/account/${key}`}>
              {key}
            </Link>
          </li>
        ))}
      </ul>
    </AuthLayout>
  );
});
