type AuthErrorType =
  | "LOGIN_BAD_PASSWORD"
  | "LOGIN_BAD_USER"
  | "LOGIN_BAD_USER_PASSWORD"
  | "LOGIN_MISSING_EMAIL_VERIFICATION"
  | "LOGIN_MISSING_VERIFICATION"
  | "LOGIN_BANNED"
  | "LOGIN_UNKNOWN_ERROR"
  | "SIGNUP_DUPLICATE_USERNAME"
  | "SIGNUP_DUPLICATE_EMAIL"
  | "SIGNUP_VALIDATION_ERROR"
  | "SIGNUP_UNKNOWN_ERROR"
  | "SESSION_EXPIRED"
  | "SESSION_UNKNOWN_ERROR"
  | "UNKNOWN_ERROR";

export class AuthError extends Error {
  type: AuthErrorType;

  constructor(type: AuthErrorType) {
    super("[@opendash/core] AUTH ERROR: " + type);

    this.type = type;
  }
}
