import { useTranslation } from "@opendash/core";
import { FormEditComponentProps } from "@opendash/plugin-bde";
import { DataInput } from "@opendash/plugin-timeseries";
import { Select } from "antd";
import React from "react";

export default function DateBySensorEditForm({
  field,
  updateField,
}: FormEditComponentProps) {
  const t = useTranslation();
  return (
    <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
      <DataInput
        value={[field.options.dataItem]}
        options={{ select: "dimension", min: 1, max: 1 }}
        onChange={(newItem) => {
          updateField((draft) => {
            draft.options.dataItem = newItem[0];
          });
        }}
      />
      <Select
        style={{ width: "100%" }}
        options={[
          { label: "Wert als Zeitstempel setzen", value: "timestamp" },
          { label: "Wert direkt speichern", value: "value" },
        ]}
        value={field.options.setsDate ? "timestamp" : "value"}
        onChange={(value) => {
          updateField((draft) => {
            draft.options.setsDate = value == "timestamp";
          });
        }}
      ></Select>
    </div>
  );
}
