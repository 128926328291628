import { Button, Form, Input, Modal } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  useLocalStorage,
  useOpenDashApp,
  useOpenDashServices,
  useTranslation,
} from "../..";

export const UserPasswordChange = observer(function UserPasswordChange() {
  const t = useTranslation();
  const app = useOpenDashApp();
  const services = useOpenDashServices();

  const [lang, setLang] = useLocalStorage("opendash:language");

  return (
    <Modal
      title={t("parse-admin:auth.modal_label")}
      cancelText={t("opendash:ui.close")}
      open={app.ui2.resetPWDialogOpen}
      onOk={() => app.ui2.hideResetPWDialog()}
      onCancel={() => app.ui2.hideResetPWDialog()}
      okButtonProps={{ style: { display: "none" } }}
    >
      <Form
        onFinish={(data) => {
          services.UserService.changePassword({ password: data.password });
          //$parse.auth.changePassword({ password: data.password });
        }}
      >
        <Form.Item
          name="password"
          label={t("parse-admin:auth.field_password_label")}
          required
          tooltip={t("parse-admin:auth.field_password_tooltip")}
          rules={[
            {
              required: true,
              message: t("parse-admin:auth.field_password_validation_required"),
            },
            {
              type: "string",
              min: 8,
              message: t("parse-admin:auth.field_password_validation_length", {
                length: 8,
              }),
            },
          ]}
        >
          <Input.Password
            placeholder={t("parse-admin:auth.field_password_placeholder")}
          />
        </Form.Item>

        <Form.Item
          name="password2"
          label={t("parse-admin:auth.field_password2_label")}
          tooltip={t("parse-admin:auth.field_password2_tooltip")}
          dependencies={["password"]}
          rules={[
            {
              required: true,

              message: t(
                "parse-admin:auth.field_password2_validation_required"
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("parse-admin:auth.field_password2_validation_missmatch")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("parse-admin:auth.modal_action")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});
