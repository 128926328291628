import { Badge, Calendar, CalendarProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { CronStateProvider } from "..";

const CalendarWrapper = styled.div`
  .events {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notes-month {
    font-size: 28px;
    text-align: center;
  }
  .notes-month section {
    font-size: 28px;
  }
`;

export const CronDisplayCalendar = observer((props: { schedules: any[] }) => {
  const state = CronStateProvider.getState();

  const dateCellRender = (value: Dayjs) => {
    //One time schedules
    const onthedayschedules = props.schedules
      .filter((schedule) => !!schedule.get("run_timestamp"))
      .filter((schedule) =>
        dayjs(schedule.get("run_timestamp")).isSame(value, "day")
      );

    //Recurring schedules
    const cronschedules: Array<any> = props.schedules.filter(
      (schedule) => !!schedule.get("run_cron")
    );

    return (
      <ul className="events">
        <>
          {onthedayschedules.map((schedule) => (
            <li key={schedule.id}>
              <Badge status="default" text={schedule.get("title")} />
            </li>
          ))}

          {cronschedules.map((schedule) => {
            //Check if date fits the cron_timestamps from the schedule.
            if (
              state.checkDateAgainstCronString(schedule.get("run_cron"), value)
            ) {
              return (
                <li key={schedule.id}>
                  <Badge status="default" text={schedule.get("title")} />
                </li>
              );
            }
          })}
        </>
      </ul>
    );
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    return "";
  };

  return (
    <CalendarWrapper>
      <Calendar cellRender={cellRender} />
    </CalendarWrapper>
  );
});
