import Parse from "parse";

export interface Documentation_CategoryAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  name: string;
  parent?: Documentation_Category;
}

export class Documentation_Category extends Parse.Object<Documentation_CategoryAttributes> {
  static className: string = "OD3_Documentation_Category";

  constructor(data?: Partial<Documentation_CategoryAttributes>) {
    super("OD3_Documentation_Category", data as Documentation_CategoryAttributes);
  }

  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get parent(): Documentation_Category | undefined {
    return super.get("parent");
  }
  set parent(value: Documentation_Category | undefined) {
    super.set("parent", value);
  }
}

Parse.Object.registerSubclass("OD3_Documentation_Category", Documentation_Category);
