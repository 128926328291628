import { Form, InputNumber, Typography } from "antd";
import React from "react";

import { useTranslation } from "@opendash/core";
const { Text } = Typography;

function NumberInput(props) {
  const t = useTranslation();
  let style = { width: "100%", display: "inline-block" };
  if (props.elementStyle) {
    style = Object.assign(style, props.elementStyle);
  }
  const state = props.Data;

  const getDecimalSeperator = () => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(navigator.languages[0])
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === "decimal").value;
  };

  const rules = [];
  const options = state.options || {};
  if (state["v_required"] && state["v_required"].value) {
    rules.push({
      required: t("bde:app.forms.common.required"),
      message: t("bde:app.forms.common.error"),
    });
  }

  if (options.validation) {
    rules.push(({ getFieldValue }) => ({
      validator(rule, value) {
        if (options.validation.max || options.validation.max === 0) {
          if (value > options.validation.max)
            return Promise.reject(
              t("bde:" + options.validation.err) +
                ": <" +
                options.validation.max
            );
        }
        if (options.validation.min || options.validation.min === 0) {
          if (value < options.validation.min)
            return Promise.reject(
              t("bde:" + options.validation.err) +
                ": >" +
                options.validation.min
            );
        }
        return Promise.resolve();
      },
    }));
  }

  return (
    <Form.Item
      name={state.key}
      style={{ marginBottom: "24px" }}
      label={
        <div>
          {state.title}
          {state.options.unit ? (
            <Text style={{ display: "inline-block" }} code>
              {state.options.unit}
            </Text>
          ) : null}
        </div>
      }
      rules={rules}
    >
      <InputNumber
        style={style}
        type="text"
        inputMode="decimal"
        step="1"
        pattern="[0-9]+([\.,][0-9]+)?"
        defaultValue={props.Default}
        decimalSeparator={getDecimalSeperator()}
        placeholder={state.hint}
        min={state.options.validation?.min || Number.MIN_SAFE_INTEGER}
        max={state.options.validation?.max - 1 || Number.MAX_SAFE_INTEGER}
        value={state.value}
        size="large"
      />
    </Form.Item>
  );
}
export default NumberInput;
