import { useTranslation } from "@opendash/i18n";
import { CustomParseFieldProps } from "@opendash/plugin-parse";
import { Button, Modal, Tag } from "antd";
import React, { useState } from "react";
import ParseQueryForm from "./ParseQueryForm";

export default (props: CustomParseFieldProps) => {
  const t = useTranslation();
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (!props.setValue) return <Tag>Object</Tag>;

  return (
    <>
      <Button onClick={() => setEditModalOpen(true)}>
        {t(
          "openware:classes.OD3_Monitoring_ParseTableSensor.edit.query.button.label"
        )}
      </Button>
      <Modal
        width="80%"
        open={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
        onClose={() => setEditModalOpen(false)}
      >
        <ParseQueryForm {...props} />
      </Modal>
    </>
  );
};
