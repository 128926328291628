import { useDeepCompareMemo, useServiceStore } from "@opendash/core";
import React from "react";
import { DataFetchingOptionsInterface, useDataService } from "..";

export function useDataFetchingOptionsOverwrite(
  input: DataFetchingOptionsInterface = {}
): DataFetchingOptionsInterface {
  const DataService = useDataService();

  const overwrite = useServiceStore(
    DataService,
    React.useCallback((state) => state.overwriteFetchingOptions, [])
  );

  return useDeepCompareMemo(() => {
    if (!overwrite || !input?.historyType) {
      return input;
    }

    return {
      ...input,
      ...((overwrite as any) || {}),
    };
  }, [input, overwrite]);
}
