import {
  Boundary,
  createInternalComponent,
  SourceInterface,
  useServiceStore,
  useSource,
  useTranslation,
} from "@opendash/core";
import { Modal } from "antd";
import * as React from "react";
import { Explorer, useDashboards, useMonitoringService } from "..";

interface Props {
  open: boolean;
  close: () => void;
}

export const ExplorerDialog = createInternalComponent<Props>(
  function ExplorerDialog({ open, close }) {
    const t = useTranslation();
    const MonitoringService = useMonitoringService();

    const [source, setSource] = React.useState<SourceInterface | undefined>(
      undefined
    );
    const [name, setName] = React.useState<string | undefined>(undefined);

    const dashboards = useDashboards();

    const copyDashboardId = useServiceStore(
      MonitoringService,
      React.useCallback((state) => state.copyDashboardId, [])
    );

    const dashboard = dashboards.find(
      (dashboard) => dashboard.id === copyDashboardId
    );

    const [, , sources] = useSource();

    React.useEffect(() => {
      if (dashboard) {
        const src = sources.find((s) => s.id === dashboard.source);

        setName(dashboard.name);
        setSource(src);
      } else {
        setName(undefined);
        setSource(undefined);
      }
    }, [copyDashboardId]);

    return (
      <Modal
        open={open}
        title={t("opendash:monitoring.explorer.label")}
        okText={t("opendash:ui.create")}
        onOk={() => {
          close();
        }}
        cancelText={t("opendash:ui.cancel")}
        onCancel={(e) => {
          close();
        }}
        okButtonProps={{ style: { display: "hidden" } }}
        footer={false}
        centered
        width="90vw"
        styles={{ body: { backgroundColor: "rgba(250,250,250,0.4)" } }}
      >
        <Boundary>{open && <Explorer />}</Boundary>
      </Modal>
    );
  }
);
