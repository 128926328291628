import { useTranslation } from "@opendash/i18n";
import { createComponent } from "@opendash/state";
import { Spin } from "antd";
import React from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import type { ParseFilterState } from "../../state/ParseFilterState";
import { CanbanViewType } from "../../types";
import { ParseFields } from "../ParseFields";
import { ParseObjectCard } from "../ParseObjectCard";
import {
  BoardDiv,
  BoardInnerDiv,
  CardDiv,
  ColumnContentDiv,
  ColumnDiv,
  ColumnHeaderDiv,
} from "./CanbanView.layout";
import { CanbanState } from "./CanbanView.state";

interface Props {
  state: ParseFilterState;
  view: CanbanViewType;
}

export const CanbanView = createComponent<Props>(function CanbanView({
  state,
  view,
}) {
  const t = useTranslation();

  const canbanState = React.useMemo(() => new CanbanState(state), [state]);

  if (canbanState.loading) {
    return <Spin style={{ width: "100%", textAlign: "center" }} />;
  }

  canbanState.columnsArray;

  return (
    <BoardDiv>
      <DragDropContext onDragEnd={(result) => canbanState.onDragEnd(result)}>
        <Droppable droppableId="board" type="COLUMN" direction="horizontal">
          {(provided: DroppableProvided) => (
            <BoardInnerDiv ref={provided.innerRef} {...provided.droppableProps}>
              {canbanState.columnsArray.map((column, index) => (
                <Draggable
                  key={column.key}
                  draggableId={column.key}
                  index={index}
                >
                  {(
                    provided2: DraggableProvided,
                    snapshot2: DraggableStateSnapshot
                  ) => (
                    <ColumnDiv
                      ref={provided2.innerRef}
                      {...provided2.draggableProps}
                      isDragging={snapshot2.isDragging}
                    >
                      <ColumnHeaderDiv
                        {...provided2.dragHandleProps}
                        isDragging={snapshot2.isDragging}
                      >
                        {column.pointer ? (
                          <ParseFields
                            object={column.pointer}
                            fields={column.pointerTitleFields}
                          />
                        ) : (
                          column.label
                        )}
                      </ColumnHeaderDiv>

                      <Droppable
                        droppableId={column.key}
                        type="CARD"
                        direction="vertical"
                        ignoreContainerClipping={false}
                        isCombineEnabled={false}
                      >
                        {(provided3: DroppableProvided) => (
                          <ColumnContentDiv
                            ref={provided3.innerRef}
                            {...provided3.droppableProps}
                          >
                            {column.cards.map((card, index2) => (
                              <Draggable
                                key={card.key}
                                draggableId={card.key}
                                index={index2}
                              >
                                {(
                                  provided4: DraggableProvided,
                                  snapshot4: DraggableStateSnapshot
                                ) => (
                                  <CardDiv
                                    ref={provided4.innerRef}
                                    {...provided4.draggableProps}
                                    {...provided4.dragHandleProps}
                                    isDragging={snapshot4.isDragging}
                                  >
                                    <ParseObjectCard
                                      object={card.object}
                                      imageField={view.imageField}
                                      titleFields={
                                        view.titleFields ||
                                        state.config.titleFields
                                      }
                                      descriptionFields={
                                        view.descriptionFields ||
                                        state.config.displayFields
                                      }
                                      onChange={() => state.data.reload()}
                                    />
                                  </CardDiv>
                                )}
                              </Draggable>
                            ))}
                            {provided3.placeholder}
                          </ColumnContentDiv>
                        )}
                      </Droppable>
                    </ColumnDiv>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </BoardInnerDiv>
          )}
        </Droppable>
      </DragDropContext>
    </BoardDiv>
  );
});
