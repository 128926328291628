import { produce } from "@opendash/core";
import {
  AggregationOperationInterface,
  DataItemDimensionIdentifierInterface,
  DataItemDimensionValueInterface,
} from "@opendash/plugin-timeseries";
import { DataHierarchy, TreeArrayItem } from "../types";

export function trimEqualSuffixPrefixFromStrings(
  strings: (string | null)[],
  suffixBreak?: string,
  prefixBreak?: string
) {
  if (suffixBreak) suffixBreak = suffixBreak.split("").reverse().join("");
  const toCheck = strings.filter((str) => !!str);

  if (toCheck.length === 0) return [];
  if (toCheck.length === 1) return toCheck.map((str) => str || "Deleted/NA");
  // Finde den kürzesten String
  const shortest = toCheck.reduce((a: string, b: string) =>
    a.length <= b.length ? a : b
  );

  // Überprüfe den gemeinsamen Präfix
  let commonPrefix = "";
  let currentCharacter = "";
  for (let i = 0; i < shortest.length; i++) {
    currentCharacter = shortest[i];
    if (toCheck.some((str) => str[i] !== currentCharacter)) break;
    if (prefixBreak && currentCharacter === prefixBreak[0]) {
      if (shortest.substring(i, i + prefixBreak.length) === prefixBreak) break;
    }
    commonPrefix += currentCharacter;
  }

  // Überprüfe den gemeinsamen Suffix
  let commonSuffix = "";
  for (let i = 0; i < shortest.length; i++) {
    currentCharacter = shortest[shortest.length - 1 - i];
    if (
      toCheck.some(
        (str) =>
          !str[str.length - 1 - i] ||
          str[str.length - 1 - i] !== currentCharacter
      )
    )
      break;
    if (suffixBreak && currentCharacter === suffixBreak[0]) {
      if (shortest.substring(i - suffixBreak.length + 1, i + 1) === prefixBreak)
        break;
    }
    commonSuffix += currentCharacter;
  }
  // Entferne den gemeinsamen Präfix und Suffix aus jedem String im Array
  return strings.map((str) =>
    !str
      ? "Deleted/NA"
      : str.substring(commonPrefix.length, str.length - commonSuffix.length)
  );
}

export const extractDimensions = (
  hierachy: DataHierarchy[],
  items: Map<string, DataItemDimensionIdentifierInterface> = new Map()
) => {
  hierachy.forEach((h) => {
    h.values.forEach((v) => {
      items.set(`${v[0]}${v[1]}${v[2]}`, v);
    });
    extractDimensions(h.children, items);
  });

  return items;
};

export const getAllAggOpsForItem = (
  hierachies: DataHierarchy[],
  initial: Record<string, { params: any; ops: AggregationOperationInterface[] }>
) => {
  const result = initial || {};
  hierachies.forEach((h) => {
    h.values.forEach((v) => {
      const id = keySafeId(v);
      if (!initial[id])
        initial[id] = { params: h.valueAggregationParams[id], ops: [] };
      if (!initial[id].ops.includes(h.valueAggregationOperations[id])) {
        initial[id].ops.push(h.valueAggregationOperations[id]);
      }
    });
    getAllAggOpsForItem(h.children, result);
  });

  return result;
};

export const keySafeId = (id: (string | number)[]) => {
  return id.join("~").replaceAll(".", "_").replaceAll("$", "#");
};

export const getTreeArrayWithLeafValuesOnly = (
  valueHiearchy: DataHierarchy[],
  currentItems: TreeArrayItem[] = [],
  parentId: string = "",
  rootId: string = ""
) => {
  valueHiearchy.forEach((h, index) => {
    const id = rootId === "" ? "" + index : rootId;
    currentItems.push({
      id,
      parent: parentId,
      name: h.label,
    });
    h.values.forEach((v, index) => {
      const idv = `${id}.v${index}`;
      currentItems.push({
        id: idv,
        parent: id,
        value: [...v, h.valueAggregationOperations[keySafeId(v)]],
      });
    });
    h.children.forEach((c, index) => {
      getTreeArrayWithLeafValuesOnly([c], currentItems, id, `${id}.c${index}`);
    });
  });

  return currentItems;
};

export const getNodeValues = (
  valueHiearchy: DataHierarchy,
  data: Map<string, DataItemDimensionValueInterface[]>
) => {
  return produce(valueHiearchy, (draft) => {
    const values = [];
    draft.values.forEach((v) => {
      const value = data.get(
        keySafeId([
          ...v,
          valueHiearchy.valueAggregationOperations[keySafeId(v)],
        ])
      )?.[0] || { value: [0] };
      if (value) {
        values.push(value.value[0]);
      }
    });
    draft.children = draft.children.map((v) => {
      const newNode = getNodeValues(v, data);
      values.push(newNode.result);
      return newNode;
    });

    switch (draft.aggregateBy) {
      case "min": {
        draft.result = Math.min(...values);
        if (isNaN(draft.result)) draft.result = 0;
        break;
      }
      case "max": {
        draft.result = Math.max(...values);
        if (isNaN(draft.result)) draft.result = 0;
        break;
      }
      case "avg": {
        draft.result = values.reduce((a, b) => a + b, 0) / values.length;
        if (isNaN(draft.result)) draft.result = 0;
        break;
      }
      case "sum": {
        draft.result = values.reduce<number>((a, b) => a + b, 0);
        if (isNaN(draft.result)) draft.result = 0;
        break;
      }
      case "count": {
        draft.result = values.length;
        if (isNaN(draft.result)) draft.result = 0;
        break;
      }
      case "diffminmax": {
        draft.result = Math.max(...values) - Math.min(...values);
        if (isNaN(draft.result)) draft.result = 0;
        break;
      }
      default:
        throw Error("Invalid aggregation operation");
    }
    return draft;
  });
};
