import * as React from "react";

// types

type RecursivRecord<T> = Record<string, string | T>;

export type TranslationResolver = () => Promise<PossibleESModule<Translation>>;

export interface Translation extends RecursivRecord<Translation> {}
export type TranslationFlat = Record<string, string>;

type LanguageIdentifier = string;
type NamespaceIdentifier = string;

interface LanguageInterface {
  key: string;
  label: string;
  parent?: string;
  default?: boolean;
}

interface TranslationResolverInterface {
  language: string;
  namespace: string;
  resolver: TranslationResolver;
}

type PossibleESModule<T> = T | { default: T; __esModule: true };

type TranslateContext = Record<string, string | number> & {
  default?: string;
  count?: number;
  context?: string | number;
};

type LanguageChangeListenerCallback = (language: LanguageIdentifier) => void;

type TranslationChangeListenerCallback = (
  namespace: NamespaceIdentifier,
  language: LanguageIdentifier
) => void;

interface StateInterface {
  language: string;
  languages: Record<LanguageIdentifier, LanguageInterface>;
  translations: Record<
    LanguageIdentifier,
    Record<NamespaceIdentifier, TranslationFlat>
  >;
  namespacesInUse: Set<NamespaceIdentifier>;
  namespacesResolving: Record<NamespaceIdentifier, boolean>;
  translationResolver: TranslationResolverInterface[];
  languageChangeListener: Set<LanguageChangeListenerCallback>;
  translationChangeListener: Set<TranslationChangeListenerCallback>;
}

// global state

const STATE: StateInterface = {
  language: null,
  languages: {},
  translations: {},
  namespacesInUse: new Set(),
  namespacesResolving: {},
  translationResolver: [],
  languageChangeListener: new Set(),
  translationChangeListener: new Set(),
};

// functions

export async function changeLanguage(language: LanguageIdentifier) {
  if (!STATE.languages[language]) {
    return void console.warn(
      `@opendash/i18n: Can not change language to '${language}'. You first need to register the language.`
    );
  }

  STATE.language = language;

  try {
    await Promise.all(
      Array.from(STATE.namespacesInUse.values()).map((ns) =>
        resolveTranslation(ns)
      )
    );
  } catch (error) {
    console.error(`@opendash/i18n: Error while resolving translations:`);
    console.error(error);
  }

  for (const callback of Array.from(STATE.languageChangeListener)) {
    Promise.resolve()
      .then(() => callback(STATE.language))
      .catch((error) => {
        console.error(`@opendash/i18n: Error in language change listener:`);
        console.error(error);
      });
  }
}

export function onLanguageChange(callback: LanguageChangeListenerCallback) {
  STATE.languageChangeListener.add(callback);

  return () => {
    STATE.languageChangeListener.delete(callback);
  };
}

export function onTranslationChange(
  callback: TranslationChangeListenerCallback
) {
  STATE.translationChangeListener.add(callback);

  return () => {
    STATE.translationChangeListener.delete(callback);
  };
}

export function registerLanguage(
  key: LanguageIdentifier,
  label: string,
  parent: LanguageIdentifier = null,
  isDefault: boolean = false
) {
  if (key === parent) {
    throw new Error(
      `@opendash/i18n: registerLanguage Error key === parent is forbidden`
    );
  }

  STATE.languages[key] = { key, label, parent };
  STATE.translations[key] = {};
}

export function registerTranslationResolver(
  language: LanguageIdentifier,
  namespace: NamespaceIdentifier,
  resolver: TranslationResolver
) {
  STATE.translationResolver.push({ language, namespace, resolver });
}

async function runTranslationResolver(
  namespace: NamespaceIdentifier,
  language: LanguageIdentifier
): Promise<Record<string, string>> {
  const resolver = STATE.translationResolver.find(
    (r) => r.language === language && r.namespace === namespace
  );

  if (!resolver) {
    console.warn(
      `@opendash/i18n: No translation resolver found for language='${language}' and namespace='${namespace}'`
    );

    return {};
  }

  try {
    const translationResolved = await resolver.resolver();

    return flattenTranslation(
      "default" in translationResolved &&
        "__esModule" in translationResolved &&
        translationResolved.__esModule === true
        ? (translationResolved.default as Translation)
        : (translationResolved as Translation)
    );
  } catch (error) {
    console.error(
      `@opendash/i18n: Error in translation resolver found for language='${language}' and namespace='${namespace}'`,
      error
    );

    return {};
  }
}

async function resolveTranslation(
  namespace: NamespaceIdentifier,
  language: LanguageIdentifier = STATE.language
): Promise<void> {
  if (!(language in STATE.languages)) {
    return;
  }

  const parentLanguage = STATE.languages[language]?.parent;

  if (!namespace || STATE.translations[language][namespace]) {
    return void 0;
  }

  if (STATE.namespacesResolving[language + "~" + namespace]) {
    return new Promise((resolve) => {
      const cancel = onTranslationChange((ns, lng) => {
        if (ns === namespace && lng === language) {
          resolve();
          cancel();
        }
      });
    });
  }

  STATE.namespacesInUse.add(namespace);

  STATE.namespacesResolving[language + "~" + namespace] = true;

  if (parentLanguage) {
    await resolveTranslation(namespace, parentLanguage);
  }

  const translation = await runTranslationResolver(namespace, language);

  if (parentLanguage) {
    STATE.translations[language][namespace] = Object.assign(
      {},
      STATE.translations[parentLanguage][namespace],
      translation
    );
  } else {
    STATE.translations[language][namespace] = translation;
  }

  // console.log(
  //   `@opendash/i18n: resolved`,
  //   language,
  //   namespace,
  //   STATE.translations[language][namespace]
  // );

  STATE.namespacesResolving[language + "~" + namespace] = false;

  for (const callback of Array.from(STATE.translationChangeListener)) {
    Promise.resolve()
      .then(() => callback(namespace, language))
      .catch((error) => {
        console.error(`@opendash/i18n: Error in namespace change listener:`);
        console.error(error);
      });
  }
}

function flattenTranslation(
  pack: Translation,
  prefix: string = "",
  result: TranslationFlat = {},
  separator: string = "."
): TranslationFlat {
  for (const key of Object.keys(pack)) {
    if (Array.isArray(pack[key])) {
      console.warn(
        "@opendash/i18n: Arrays are not allowed in Translations",
        pack[key]
      );
    } else if (typeof pack[key] === "object" && pack[key] !== null) {
      flattenTranslation(
        pack[key] as Translation,
        prefix ? prefix + separator + key : key,
        result
      );
    } else {
      result[prefix ? prefix + separator + key : key] = pack[key] as string;
    }
  }

  return result;
}

function parseNamespaceAndKey(input: string | string[]): [string, string][] {
  const i = Array.isArray(input) ? input : [input];

  return i.filter(Boolean).map((x) => {
    if (x.includes(" ")) {
      return [null, x];
    }

    const split = x.split(":");

    if (split.length === 1) {
      // console.warn(`@opendash/i18n: Namespace missing in '${x}'`);

      return [null, x];
    }

    if (split.length > 2) {
      console.warn(
        `@opendash/i18n: Don't use : in your translation keys in '${x}'.`
      );
    }

    const [namespace, key] = split;

    return [namespace, key];
  });
}

export async function translate(
  input: string | string[],
  contextOrDefault?: string | TranslateContext
): Promise<string> {
  const ids = parseNamespaceAndKey(input);

  if (ids.length === 0) {
    return null;
  }

  await Promise.all(ids.map(([ns]) => resolveTranslation(ns)));

  return translateSync(input, contextOrDefault);
}

export function translateSync(
  input: string | string[],
  contextOrDefault?: string | TranslateContext
): string {
  const ids = parseNamespaceAndKey(input);

  if (ids.length === 0) {
    return null;
  }

  const context: TranslateContext =
    contextOrDefault === (contextOrDefault || "").toString()
      ? { default: contextOrDefault }
      : (contextOrDefault as TranslateContext) || {};

  let result = null;

  for (const [namespace, key] of ids) {
    if (!result && !namespace) {
      result = key;
    }

    if (namespace && !STATE.translations[STATE.language][namespace]) {
      // console.warn(`@opendash/i18n: Namespace not loaded yet '${namespace}'`);
      continue;
    }

    if (
      !result &&
      "count" in context === true &&
      "context" in context === true
    ) {
      result =
        STATE.translations[STATE.language][namespace][
          key + "_" + context.context + "_" + context?.count
        ];
    }

    if (
      !result &&
      "count" in context === true &&
      "context" in context === true
    ) {
      result =
        STATE.translations[STATE.language][namespace][
          key + "_" + context.context + "_" + context?.count
        ];
    }

    if (
      !result &&
      "count" in context === true &&
      "context" in context === true &&
      context.count !== 1
    ) {
      result =
        STATE.translations[STATE.language][namespace][
          key + "_" + context.context + "_plural"
        ];
    }

    if (!result && "context" in context === true) {
      result =
        STATE.translations[STATE.language][namespace][
          key + "_" + context.context
        ];
    }

    if (!result && "count" in context === true) {
      result =
        STATE.translations[STATE.language][namespace][
          key + "_" + context?.count
        ];
    }

    if (!result && "count" in context === true && context.count !== 1) {
      result = STATE.translations[STATE.language][namespace][key + "_plural"];
    }

    if (!result) {
      result = STATE.translations[STATE.language][namespace][key];
    }

    if (result) {
      break;
    }
  }

  // console.log(input, result, STATE);

  if (result || result === "") {
    for (const [key, value] of Object.entries(context)) {
      result = result.replace(`{{${key}}}`, value);
    }
  } else if ("default" in context) {
    result = context.default;
  } else {
    result = ids[0][1];
  }

  return result;
}

export function useTranslation(): (
  input: string | string[],
  contextOrDefault?: string | TranslateContext
) => string {
  const [state, setState] = React.useState(0);

  const nsInUseRef = React.useRef(new Set<string>());

  React.useEffect(() => {
    return onTranslationChange((namespace) => {
      // if (nsInUseRef.current.has(namespace)) {
      // trigger a render by setting a state
      setState((x) => x + 1);
      // }
    });
  }, []);

  React.useEffect(() => {
    return onLanguageChange((lang) => {
      // trigger a render by setting a state
      setState((x) => x + 1);
    });
  }, []);

  return function translateSyncReact(
    input: string | string[],
    contextOrDefault?: string | TranslateContext
  ) {
    const ids = parseNamespaceAndKey(input);

    for (const [namespace] of ids) {
      if (namespace && !nsInUseRef.current.has(namespace)) {
        nsInUseRef.current.add(namespace);

        resolveTranslation(namespace);
      }
    }

    return translateSync(input, contextOrDefault);
  };
}

export function getCurrentLanguageSync() {
  return STATE.language;
}

export function useCurrentLanguage(): string {
  const [state, setState] = React.useState<string>(getCurrentLanguageSync());

  React.useEffect(() => {
    return onLanguageChange((lang) => {
      setState(lang);
    });
  });

  return state;
}

export function __debug() {
  for (const resolver of STATE.translationResolver) {
    resolveTranslation(resolver.namespace, resolver.language);
  }

  return STATE;
}
