import {
  createInternalComponent,
  safeStringEncode,
  SourceIdentifierInterface,
  useServiceStore,
  useTranslation,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import {
  DataFetchingFilter,
  DataInput,
  DataItemDimensionIdentifierInterface,
  DataItemHistoryOptionsPicker,
  DataItemIdentifierInterface,
  DataSelect,
  useDataService,
} from "@opendash/plugin-timeseries";
import { Checkbox, Col, Collapse, Divider, Row, Tabs, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import { $monitoring, WidgetConfigInterface, WidgetContextInterface } from "..";
import {
  Container,
  SettingsHolder,
} from "./WidgetSettingsRenderWithSteps.layout";

const { Text } = Typography;
const { Panel } = Collapse;
//Fix Flickering https://github.com/ant-design/ant-design/issues/43541
const WrapperWorkaround = styled.div`
  & .ant-tabs-nav-operations {
    display: none !important;
  }
`;
export const WidgetSettingsRenderWithSteps = createInternalComponent<{
  context: WidgetContextInterface;
}>(function WidgetSettingsRenderWithSteps({ context }) {
  const t = useTranslation();
  const [mode, setMode] = React.useState(1);
  const [step, setStep] = React.useState(
    context.context.type?.dataItems || context.context.type?.dataFetching
      ? 0
      : 2
  );

  const SettingsComponent = React.useMemo(
    () =>
      context.context.type?.settingsComponent
        ? context.context.type.settingsComponent
        : null,
    [context.context?.type?.settingsComponent]
  );

  const currentDraft = useServiceStore(context.context, (state) => state.draft);

  const checkWidgetConfig = (objekt1, objekt2) => {
    if (typeof objekt1 !== "object" || typeof objekt2 !== "object") {
      return false;
    }
    for (let attribut in objekt1) {
      if (
        objekt1.hasOwnProperty(attribut) &&
        objekt2.hasOwnProperty(attribut)
      ) {
        if (objekt1[attribut] !== objekt2[attribut]) {
          return false;
        }
      }
    }
    return true;
  };
  let preset_label_buffer = "";
  let preset = context.context?.type?.presets.find((preset) =>
    checkWidgetConfig(preset.widget.config, context.context.widget.config)
  );

  //Backup
  if (!preset || preset?.label == "") {
    const preset_label = $monitoring.types.find(
      (preset) => preset.type === context.context.widget.type
    );

    preset_label_buffer = preset_label.presets[0].label;
  } else {
    preset_label_buffer = preset?.label;
  }

  return (
    <Container style={{ paddingTop: "10px" }}>
      {preset_label_buffer && (
        <div style={{ textAlign: "center", padding: "10px" }}>
          <Text strong style={{ fontSize: "17px" }}>
            {t(preset_label_buffer || "")}
          </Text>
        </div>
      )}
      <SettingsHolder>
        <WrapperWorkaround>
          <Tabs
            tabBarStyle={{ width: "100%" }}
            tabPosition="top"
            centered={true}
            activeKey={step.toString()}
            size="large"
            onChange={(key) =>
              setStep(parseInt(Array.isArray(key) ? key[0] : key))
            }
          >
            {(context.context.type?.dataItems ||
              context.context.type?.dataFetching) && (
              <Tabs.TabPane
                tab={t("opendash:monitoring.explorer.step_data_title")}
                key="0"
                style={{ backgroundColor: "var(--opendash-color-lightgray)" }}
              >
                <Collapse defaultActiveKey={["c1", "c2"]} ghost>
                  {context.context.type?.dataItems &&
                    !context.context.type?.dataFetching?.hideDataSelect && (
                      <Panel
                        header={t(
                          "opendash:monitoring.explorer.step_data_description"
                        )}
                        key="c1"
                        style={{ fontWeight: "bold" }}
                        extra={
                          <Icon
                            icon="fa:database"
                            style={{
                              color: window
                                .getComputedStyle(document.documentElement)
                                .getPropertyValue("--opendash-primary-color"),
                            }}
                          />
                        }
                      >
                        <Row
                          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                          align="middle"
                          style={{
                            width: "100%",
                            fontWeight: "normal",
                          }}
                        >
                          <Col xs={0} sm={3}>
                            <Icon
                              icon="fa:database"
                              style={{
                                textAlign: "center",
                                fontSize: "40px",
                                color: window
                                  .getComputedStyle(document.documentElement)
                                  .getPropertyValue("--opendash-primary-color"),
                              }}
                            />
                          </Col>
                          <Col xs={24} sm={21}>
                            {mode === 1 && (
                              <>
                                <DataInput
                                  options={context.context?.type?.dataItems}
                                  value={
                                    context.context?.type?.dataItems?.select ===
                                    "source"
                                      ? currentDraft._sources
                                      : context.context?.type?.dataItems
                                            ?.select === "item"
                                        ? currentDraft._items
                                        : context.context?.type?.dataItems
                                              ?.select === "dimension"
                                          ? currentDraft._dimensions
                                          : []
                                  }
                                  onChange={(nextValue) => {
                                    context.context.updateDraft((draft) => {
                                      if (
                                        context.context?.type?.dataItems
                                          ?.select === "source"
                                      ) {
                                        draft._sources =
                                          nextValue as SourceIdentifierInterface[];
                                        draft._items = [];
                                        draft._dimensions = [];
                                      }
                                      if (
                                        context.context?.type?.dataItems
                                          ?.select === "item"
                                      ) {
                                        draft._sources = [];
                                        draft._items =
                                          nextValue as DataItemIdentifierInterface[];
                                        draft._dimensions = [];
                                      }
                                      if (
                                        context.context?.type?.dataItems
                                          ?.select === "dimension"
                                      ) {
                                        draft._sources = [];
                                        draft._items = [];
                                        draft._dimensions =
                                          nextValue as DataItemDimensionIdentifierInterface[];
                                      }
                                    });
                                  }}
                                />
                                {/*
                        <Typography.Link
                          onClick={() => {
                            setMode(2);
                          }}
                          style={{ float: "right" }}
                        >
                          {t("opendash:ui.show_sensor_table")}
                        </Typography.Link>*/}
                              </>
                            )}
                            {mode === 2 && (
                              <>
                                <DataSelect
                                  showValue={true}
                                  showTimestamp={true}
                                  showSearch={true}
                                  selectionOptions={
                                    context.context?.type?.dataItems
                                  }
                                  selection={
                                    context.context?.type?.dataItems?.select ===
                                    "source"
                                      ? currentDraft._sources
                                      : context.context?.type?.dataItems
                                            ?.select === "item"
                                        ? currentDraft._items
                                        : context.context?.type?.dataItems
                                              ?.select === "dimension"
                                          ? currentDraft._dimensions
                                          : []
                                  }
                                  onSelection={(nextValue) => {
                                    context.context.updateDraft((draft) => {
                                      if (
                                        context.context?.type?.dataItems
                                          ?.select === "source"
                                      ) {
                                        draft._sources =
                                          nextValue as SourceIdentifierInterface[];
                                        draft._items = [];
                                        draft._dimensions = [];
                                      }
                                      if (
                                        context.context?.type?.dataItems
                                          ?.select === "item"
                                      ) {
                                        draft._sources = [];
                                        draft._items =
                                          nextValue as DataItemIdentifierInterface[];
                                        draft._dimensions = [];
                                      }
                                      if (
                                        context.context?.type?.dataItems
                                          ?.select === "dimension"
                                      ) {
                                        draft._sources = [];
                                        draft._items = [];
                                        draft._dimensions =
                                          nextValue as DataItemDimensionIdentifierInterface[];
                                      }
                                    });
                                  }}
                                />
                                {/*
                        <Typography.Link
                          onClick={() => {
                            setMode(1);
                          }}
                          style={{ float: "right" }}
                        >
                          {t("opendash:ui.show_sensor_search")}
                        </Typography.Link>*/}
                              </>
                            )}
                          </Col>
                        </Row>
                        {!context.context?.type?.hideDataFilter && (
                          <Checkbox
                            style={
                              window.innerWidth > 600
                                ? { marginLeft: 96, marginTop: 5 }
                                : { marginTop: 5 }
                            }
                            checked={!!currentDraft._history?.filter}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              context.context.updateDraft((draft) => {
                                if (checked) {
                                  if (!draft._history) {
                                    draft._history = {};
                                  }

                                  if (!draft._history.filter) {
                                    draft._history.filter = {};
                                  }
                                } else {
                                  if (draft._history?.filter) {
                                    delete draft._history.filter;
                                  }
                                }
                              });
                            }}
                            disabled={
                              (!currentDraft._items ||
                                !currentDraft._items.length) &&
                              (!currentDraft._dimensions ||
                                !currentDraft._dimensions.length)
                            }
                            children={t("opendash:data.filter.enable")}
                          />
                        )}

                        {!!currentDraft._history?.filter && (
                          <>
                            <DataFetchingFilterComp context={context} />
                          </>
                        )}
                      </Panel>
                    )}

                  {context.context.type?.dataFetching &&
                    !context.context.type?.dataFetching.hideDateSelect && (
                      <>
                        <Divider style={{ margin: "10px" }}> </Divider>
                        <Panel
                          header={t(
                            "opendash:monitoring.explorer.step_fetching_description"
                          )}
                          style={{ fontWeight: "bold" }}
                          key="c2"
                          extra={
                            <Icon
                              icon="fa:calendar"
                              style={{
                                color: window
                                  .getComputedStyle(document.documentElement)
                                  .getPropertyValue("--opendash-primary-color"),
                              }}
                            />
                          }
                        >
                          <DataItemHistoryOptionsPicker
                            options={context.context?.type?.dataFetching}
                            value={currentDraft?._history}
                            onChange={(nextValue) => {
                              context.context.updateDraft((draft) => {
                                draft._history = nextValue;
                              });
                            }}
                          />
                        </Panel>
                      </>
                    )}
                </Collapse>
              </Tabs.TabPane>
            )}

            {context.context.type?.settingsComponent && (
              <Tabs.TabPane
                tab={t("opendash:monitoring.explorer.step_settings_title")}
                key="2"
              >
                <SettingsComponent {...context} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </WrapperWorkaround>
        {/* <pre>{JSON.stringify(currentDraft, null, 2)}</pre> */}
      </SettingsHolder>
    </Container>
  );
});

const DataFetchingFilterComp = createInternalComponent<{
  context: WidgetContextInterface;
}>(function DataFetchingFilterComp({ context }) {
  const DataService = useDataService();

  const currentDraft = useServiceStore(context.context, (state) => state.draft);

  const mode = context.context?.type?.dataItems?.select;
  const value: WidgetConfigInterface<{}> = currentDraft;
  const onChange = (newDraft: WidgetConfigInterface<{}>) => {
    context.context.replaceDraft(newDraft);
  };

  React.useEffect(() => {
    const previous = value._history?.filter || {};

    if (value._items) {
      context.context.updateDraft((draft) => {
        if (!draft._history) {
          draft._history = {};
        }

        draft._history.filter = Object.fromEntries(
          value._items.map((identifier) => {
            const key = DataService.keyForIdentifier(identifier);

            return [key, previous[key] || undefined];
          })
        );
      });
    }

    if (value._dimensions) {
      context.context.updateDraft((draft) => {
        if (!draft._history) {
          draft._history = {};
        }

        draft._history.filter = Object.fromEntries(
          value._dimensions.map((identifier) => {
            const key = safeStringEncode(
              DataService.keyForIdentifier(identifier)
            );

            return [key, previous[key] || undefined];
          })
        );
      });
    }
  }, [value._items, value._dimensions]);

  if (!value._history?.filter) {
    return null;
  }

  if (mode === "item" && value._items) {
    return (
      <div>
        <Divider />
        {value._items.map((identifier, index) => {
          const key = safeStringEncode(
            DataService.keyForIdentifier(identifier)
          );

          return (
            <DataFetchingFilter
              mode="item"
              triggerItem={identifier}
              value={value._history.filter[key]}
              onChange={(x) =>
                context.context.updateDraft((draft) => {
                  draft._history.filter[key] = x;
                })
              }
            />
          );
        })}
      </div>
    );
  }

  if (mode === "dimension" && value._dimensions) {
    return (
      <div>
        <Divider />
        {value._dimensions.map((identifier, index) => {
          const key = safeStringEncode(
            DataService.keyForIdentifier(identifier)
          );

          return (
            <DataFetchingFilter
              mode="dimension"
              triggerItem={identifier}
              value={value._history.filter[key]}
              onChange={(x) =>
                context.context.updateDraft((draft) => {
                  draft._history.filter[key] = x;
                })
              }
            />
          );
        })}
      </div>
    );
  }

  return null;
});
