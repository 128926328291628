import { translateSync } from "@opendash/i18n";
import { makeAutoObservable } from "mobx";

import { $framework, AppInterface } from "..";

interface SidebarState {
  available: boolean;
  open: boolean;
}

export class UserInterfaceService {
  private app: AppInterface;

  public servicesLoading: boolean = true;

  public userDialogDisabled: boolean = false;
  public userDialogOpen: boolean = false;

  public notificationsDialogDisabled: boolean = false;
  public notificationsDialogOpen: boolean = false;

  public versionDialogDisabled: boolean = false;
  public versionDialogOpen: boolean = false;

  public languageDialogDisabled: boolean = false;
  public languageDialogOpen: boolean = false;

  public resetPWDialogOpen: boolean = false;

  public sidebarVisible: boolean = false;
  public sidebarOpen: boolean = false;

  public sidebar: SidebarState = {
    available: false,
    open: false,
  };

  public headerHeight: number = 0;
  public headerWidth: number = 0;

  public copyrightText = "";
  public copyrightLink = "";
  public imprint: string = "";
  public tos: string = "";
  public dataProtection: string = "";
  public imprintTenant: string = "";
  public tosTenant: string = "";
  public dataProtectionTenant: string = "";

  constructor(app: AppInterface) {
    makeAutoObservable(this);

    this.app = app;
  }

  get name() {
    return (this.app.config as any)?.manifest?.name || "?";
  }

  get version() {
    return "v" + ((this.app.config as any)?.manifest?.version || "0.0.0");
  }

  get primaryColor() {
    return this.app.ui.primaryColor;
  }

  public setServicesLoading(value: boolean) {
    this.servicesLoading = value;
  }

  public showUserDialog() {
    this.userDialogOpen = true;
  }

  public hideUserDialog() {
    this.userDialogOpen = false;
  }

  public showNotificationsDialog() {
    this.notificationsDialogOpen = true;
  }

  public hideNotificationsDialog() {
    this.notificationsDialogOpen = false;
  }

  public showVersionDialog() {
    this.versionDialogOpen = true;
  }

  public hideVersionDialog() {
    this.versionDialogOpen = false;
  }

  public showLanguageDialog() {
    this.languageDialogOpen = true;
    this.hideUserDialog();
  }

  public showResetPWDialog() {
    console.log("True");
    this.resetPWDialogOpen = true;
    this.hideUserDialog();
  }

  public hideResetPWDialog() {
    console.log("false");
    this.resetPWDialogOpen = false;
  }

  public hideLanguageDialog() {
    this.languageDialogOpen = false;
  }

  public updateSidebar(sidebar: Partial<SidebarState>) {
    Object.assign(this.sidebar, sidebar);
  }

  public setHeaderSize(height: number, width: number) {
    try {
      this.headerHeight = height;
      this.headerWidth = width;

      document.documentElement.style.setProperty(
        "--opendash-header-height",
        height + "px"
      );

      document.documentElement.style.setProperty(
        "--opendash-header-width",
        width + "px"
      );
    } catch (error) {
      console.error(error);
    }
  }

  public setLinks(
    links: Partial<{
      copyrightText: string;
      copyrightLink: string;
      imprint: string;
      tos: string;
      dataProtection: string;
      imprintTenant: string;
      tosTenant: string;
      dataProtectionTenant: string;
    }>
  ) {
    Object.assign(this, links);
  }

  public message(
    type: "info" | "success" | "warning" | "error",
    msg: string,
    timeout: number
  ) {
    msg = translateSync(msg);

    switch (type) {
      case "success":
        return $framework.antd_msg.success(msg, timeout);
      case "warning":
        return $framework.antd_msg.warning(msg, timeout);
      case "error":
        return $framework.antd_msg.error(msg, timeout);
      default:
      case "info":
        return $framework.antd_msg.info(msg, timeout);
    }
  }
}
