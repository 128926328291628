import { DeleteOutlined } from "@ant-design/icons";
import { produce, useTranslation } from "@opendash/core";
import { Button, Input, Select, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { ItemAttributes } from "..";
import { configProvider } from "../../..";

const { Option } = Select;
const { TabPane } = Tabs;

export default observer((props: { field; updateField }) => {
  const t = useTranslation();
  const [spinnerState, setSpinnerState] = React.useState({
    type: props.field.list ? 2 : 1,
    options: props.field.items || [],
    selectedList: props.field.list,
    lists: [],
  });
  React.useEffect(() => {
    init();
  }, []);
  const init = async () => {
    const x = new Parse.Query(configProvider.config.lists.table);
    x.limit(999999999);
    try {
      const result = await x.find();
      setSpinnerState(
        produce((draft) => {
          draft.lists = result.map((lItem) => {
            return lItem.toJSON();
          });
        })
      );
    } catch (e) {
      console.error(e);
    }
  };
  const options = props.field.items || [];
  const spinnersettings = (
    <div className="od_admin_bde_input">
      <Tabs
        defaultActiveKey={"" + spinnerState.type}
        onChange={(e) => {
          setSpinnerState(
            produce((draft) => {
              draft.type = parseInt(e);
              props.updateField((state) => {
                if (draft.type !== 2) {
                  state.list = void 0;
                } else {
                  state.list = spinnerState.selectedList;
                }
              });
            })
          );
        }}
      >
        <TabPane tab={t("bde:fields.label_customList")} key="1">
          <h3> {t("bde:fields.label_customList")} </h3>
          {options.map((element, index) => {
            return (
              <div key={index} style={{ display: "block" }}>
                <Input
                  style={{ display: "inline-block", width: "30%" }}
                  addonBefore={t("bde:fields.label_optionKey")}
                  value={element.value}
                  onChange={(e) => {
                    props.updateField((state) => {
                      state.items[index].value = e.target.value;
                    });
                  }}
                />
                <Input
                  style={{ display: "inline-block", width: "50%" }}
                  addonBefore={t("bde:fields.label_optionLabel")}
                  value={element.label}
                  onChange={(e) => {
                    props.updateField((state) => {
                      state.items[index].label = e.target.value;
                    });
                  }}
                />
                <Button
                  type="default"
                  style={{ display: "inline-block" }}
                  onClick={(e) => {
                    props.updateField((state) => {
                      state.items.splice(index, 1);
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            );
          })}
          <Button
            type="dashed"
            style={{ display: "block", marginTop: "10px" }}
            onClick={(e) => {
              props.updateField((state) => {
                const x = [...options];
                x.push({ value: "", label: "" });

                state.items = x;
              });
            }}
          >
            + {t("bde:fields.label_addOptionEnty")}
          </Button>
        </TabPane>
        <TabPane tab={t("bde:fields.label_existingList")} key="2">
          <Select
            style={{ width: "100%" }}
            placeholder={t("bde:fields.label_chooseExistingList")}
            value={spinnerState.selectedList}
            onChange={(value) => {
              setSpinnerState(
                produce((draft) => {
                  draft.selectedList = value;
                })
              );
              props.updateField((state) => {
                state.list = value;
              });
            }}
          >
            {spinnerState.lists.map((item) => {
              return <Option value={item.objectId}>{item.list}</Option>;
            })}
          </Select>
        </TabPane>
      </Tabs>
    </div>
  );
  return (
    <div>
      {spinnersettings}
      {ItemAttributes(props)}
    </div>
  );
});
