import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { useNavigate } from "@opendash/router";
import { Card, List, Tooltip } from "antd";
import dayjs from "dayjs";
import produce from "immer";
import { observer } from "mobx-react-lite";
import Parse from "parse";
import React, { useEffect, useState } from "react";
import { configProvider } from "../..";

const { Meta } = Card;

export default observer((props: any) => {
  const navigate = useNavigate();

  const t = useTranslation();

  const [formCount, setFormCount] = useState<
    Record<
      string,
      {
        number: number;
        lastUpdate: number;
      }
    >
  >({});

  const getItemCount = async (rid, user, id, source) => {
    const itemObj = Parse.Object.extend(configProvider.config.results.table);
    const query = new Parse.Query(itemObj);
    query.equalTo("RID", rid);
    if (id) {
      query.equalTo("result.reference", id);
    }
    query.equalTo("rueckmeldeUnit", source);
    const results = await query.count();
    const lastItem = await query.addDescending("updatedAt").first();
    setFormCount(
      produce((draft) => {
        if (!draft[rid]) {
          draft[rid] = {
            number: results,
            lastUpdate: lastItem?.get("updatedAt").getTime() || 0,
          };
        }
        draft[rid].number = results;
      })
    );
  };

  useEffect(() => {
    if (configProvider.config.results.table) {
      for (let i = 0; i < props.Unit.forms.length; i++) {
        getItemCount(
          props.Unit.forms[i].RID,
          Parse.User.current().id,
          props.Reference,
          props.Unit.source
        );
      }
    }
  }, []);

  if (!props.Unit.displayInOverview) return;

  return (
    <div
      style={{
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          backgroundColor: "var(--opendash-color-lightgray)",
          borderBottom: "3px solid var(--opendash-color-darkgray)",
          width: "100%",
          textAlign: "left",
          height: "39px",
          lineHeight: "39px",
          marginBottom: "10px",
        }}
      >
        <p
          style={{
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            height: "100%",
          }}
        >
          <b>
            {t("bde:app.formList.title")}{" "}
            <span style={{ color: "var(--opendash-color-yellow" }}>|</span>{" "}
            {props.Unit[configProvider.config.units.field]}
          </b>
        </p>
      </div>

      <List
        style={{}}
        dataSource={props.Unit.forms as any[]}
        renderItem={(item, index) => {
          return (
            <Card
              key={"key-" + index}
              style={{
                width: "100%",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              actions={[
                <Tooltip title="Einträge">
                  {formCount.hasOwnProperty(item.RID) ? (
                    <div
                      style={{
                        textAlign: "center",
                        color: "var(--ant-primary-color)",
                      }}
                    >
                      {formCount[item.RID].number + " "}
                      <Icon
                        icon="fa:database"
                        style={{
                          height: "80%",
                          color: "var(--ant-primary-color)",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        color: "var(--ant-primary-color)",
                      }}
                    />
                  )}
                </Tooltip>,
                <Tooltip title="Letzter Eintrag">
                  {formCount[item.RID]?.lastUpdate ? (
                    <div style={{ color: "var(--ant-primary-color)" }}>
                      {dayjs(formCount[item.RID].lastUpdate).fromNow()}
                    </div>
                  ) : (
                    <div style={{ color: "var(--ant-primary-color)" }}>
                      {t("bde:app.formList.noentry")}
                    </div>
                  )}
                </Tooltip>,
                // formCount.hasOwnProperty(item.RID) ? (
                //   <div
                //     style={{
                //       textAlign: "center",
                //       color: "var(--ant-primary-color)",
                //     }}
                //   >
                //     <Tooltip title="Historische Werte ansehen">
                //       <Button
                //         type="link"
                //         icon={
                //           <Icon
                //             icon="fa:history"
                //             onClick={(e) => {
                //               e.stopPropagation();
                //               navigate(
                //                 `/bde/history/${props.Unit.objectId}/${item.objectId}`
                //               );
                //             }}
                //           />
                //         }
                //       ></Button>
                //     </Tooltip>
                //   </div>
                // ) : (
                //   <div
                //     style={{
                //       textAlign: "center",
                //       color: "var(--ant-primary-color)",
                //     }}
                //   />
                // ),
              ]}
              onClick={() => {
                navigate(
                  "/bde/formshortcut/" +
                    props.Unit.objectId +
                    "/" +
                    item.objectId +
                    "/0" +
                    "?reference=" +
                    props.Reference
                );
              }}
            >
              <Meta
                avatar={
                  <Icon
                    icon="fa:clipboard-list-check"
                    style={{
                      height: "100%",
                      fontSize: "52px",
                      color: "var(--ant-primary-color)",
                    }}
                  />
                }
                title={item.name || t("bde:app.formList.notitle")}
                description={
                  item.description.replace(", ", " || ") ||
                  t("bde:app.formList.nodesc")
                }
              />
            </Card>
          );
        }}
      />
    </div>
  );
});
