import { useTranslation } from "@opendash/core";
import { Switch } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer((props: { field; updateField }) => {
  const t = useTranslation();
  // return <pre>{JSON.stringify(field, null, "  ")}</pre>;
  return (
    <div className="od_admin_bde_input">
      <div className="od_admin_bde_input">
        <h4> {t("bde:fields.label_displayOptions")} </h4>
        <Switch
          checked={props.field.options.allowMultiStart}
          onChange={(e) => {
            props.updateField((state) => {
              state.options.allowMultiStart = e;
            });
          }}
        />{" "}
        {t("bde:fields.label_allowParallelRecording")}
      </div>
    </div>
  );
});
