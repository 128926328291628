import { Tooltip, Typography } from "antd";
import React from "react";

import { ObjectEdit } from "./Object";

export interface ArrayDisplayProps {
  value: any[];
}

export const ArrayDisplay = React.memo<ArrayDisplayProps>(
  function ArrayDisplay({ value }) {
    return (
      <Tooltip title={JSON.stringify(value)}>
        <Typography.Text code>Array</Typography.Text>
      </Tooltip>
    );
  }
);

export interface ArrayEditProps {
  value: any[];
  setValue?: (v: any[]) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const ArrayEdit = React.memo<ArrayEditProps>(function ArrayEdit({
  value,
  setValue,
  isRequired,
  isNullable,
}) {
  return (
    <ObjectEdit
      value={value}
      setValue={setValue}
      isRequired={isRequired}
      isNullable={isNullable}
    />
  );
});
