import * as Parse from "parse";
import { DropResult } from "react-beautiful-dnd";

import { translateSync } from "@opendash/i18n";
import { makeAutoObservable } from "@opendash/state";

import type { ParseFilterState } from "../../state/ParseFilterState";
import {
  CanbanViewType,
  ParseClassConfig,
  ParseClassSchema,
  ParseFieldSchema,
} from "../../types";

import { $framework } from "@opendash/core";
import { $parse } from "../..";

interface CanbanBoardType {
  key: string;
  value: Parse.Object | string;
  pointer?: Parse.Object;
  pointerTitleFields?: string[];
  label: string;
  renderCounter: number;
  cards: {
    key: string;
    object: Parse.Object;
  }[];
}

export class CanbanState {
  public loading: boolean = true;
  public columnOrder: string[] = [];
  public cardOrder: string[] = [];

  private state: ParseFilterState;
  private renderCounter = 0;

  private view: CanbanViewType;
  private groupByField: string;
  private groupBySchema: ParseFieldSchema;
  private groupByPointer: string | null;
  private pointerSchema: ParseClassSchema | null;
  private pointerConfig: ParseClassConfig | null;
  private pointerSorted: boolean;
  private pointerData: Parse.Object[] = [];

  constructor(state: ParseFilterState) {
    makeAutoObservable(this);

    this.state = state;

    this.view = this.state.view as CanbanViewType;
    this.groupByField = this.view.groupByField;
    this.groupBySchema = this.state.schema.fields[this.groupByField];
    this.groupByPointer =
      this.groupBySchema.type === "Pointer"
        ? this.groupBySchema.targetClass
        : null;

    if (this.groupByPointer) {
      this.pointerConfig = $parse.ui.getClassConfig(this.groupByPointer);
      this.pointerSchema = $parse.ui.getClassSchema(this.groupByPointer);

      this.pointerSorted = !!this.pointerConfig?.orderField;

      this.fetchPointerData();
    } else {
      this.loading = false;
    }
  }

  rerender() {
    this.renderCounter += 1;
  }

  get columnsSortable() {
    return (
      this.pointerConfig?.orderField &&
      this.pointerSchema?.fields[this.pointerConfig.orderField]?.type ===
        "Number"
    );
  }

  get cardsSortable() {
    return (
      this.state.config.orderField &&
      this.state.schema.fields[this.state.config.orderField]?.type ===
        "Number" &&
      this.state.sortKey === this.state.config.orderField &&
      this.state.sortOrder === "ascend"
    );
  }

  get cardArray() {
    const result = this.state.data.result.slice();

    // if (this.cardsSortable) {
    //   result.sort(
    //     (a, b) =>
    //       a.get(this.state.config.orderField) -
    //       b.get(this.state.config.orderField)
    //   );
    // }

    return result;
  }

  get cardMap(): Record<string, Parse.Object> {
    return Object.fromEntries(this.cardArray.map((o) => [`card-${o.id}`, o]));
  }

  get columnsArray() {
    const array = Object.values(this.columnsMap);

    if (this.pointerSorted) {
      array.sort(
        (a, b) =>
          a.pointer?.get(this.pointerConfig.orderField) -
          b.pointer?.get(this.pointerConfig.orderField)
      );
    } else {
      array.sort((a, b) => a.key.localeCompare(b.key));
    }

    return array;
  }

  get columnsMap(): Record<string, CanbanBoardType> {
    const result: Record<string, CanbanBoardType> = {};

    const renderCounter = this.renderCounter;

    if (this.groupByPointer) {
      for (const pointer of this.pointerData) {
        const boardKey = `column-${pointer.id}`;

        result[boardKey] = {
          key: boardKey,
          renderCounter,
          value: pointer,
          pointer: pointer,
          pointerTitleFields: this.pointerConfig?.titleFields,
          label: boardKey,
          cards: [],
        };
      }

      for (const [key, object] of Object.entries(this.cardMap)) {
        const pointer: Parse.Object = object.get(this.groupByField);

        const boardKey = pointer ? `column-${pointer.id}` : "none";

        if (!result[boardKey]) {
          result[boardKey] = {
            key: boardKey,
            renderCounter,
            value: pointer,
            pointer: pointer,
            pointerTitleFields: this.pointerConfig?.titleFields,
            label: boardKey,
            cards: [],
          };
        }

        result[boardKey].cards.push({
          key,
          object,
        });
      }
    }

    if (this.cardsSortable) {
      for (const col of Object.values(result)) {
        col.cards.sort(
          (a, b) =>
            a.object.get(this.state.config.orderField) -
            b.object.get(this.state.config.orderField)
        );
      }
    }

    return result;
  }

  get columns(): CanbanBoardType[] {
    return Object.values(this.columnsMap);
  }

  getColumn(key: string) {
    return this.columnsMap[key];
  }

  getCard(key: string) {
    return this.cardMap[key];
  }

  private setPointerData(value: Parse.Object[]) {
    this.pointerData = value;
    this.loading = false;
  }

  private async fetchPointerData() {
    if (this.groupByPointer) {
      const query = new Parse.Query(this.groupByPointer);

      if (this.pointerConfig?.orderField) {
        query.ascending(this.pointerConfig.orderField);
      }

      const result = await query.find();

      this.setPointerData(result);
    }
  }

  private async reassignOrder(objects: Parse.Object[], field: string) {
    let i = 0;

    for (const object of objects) {
      object.set(field, i++);
    }

    this.rerender();

    await Parse.Object.saveAll(objects);
  }

  public onDragEnd(result: DropResult) {
    const view = this.state.view as CanbanViewType;

    const source = result.source;
    const destination = result.destination;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (result.type === "CARD") {
      const card = this.getCard(result.draggableId);
      const column = this.getColumn(destination.droppableId);

      if (source.droppableId !== destination.droppableId) {
        // assign new column

        card.set(view.groupByField, column.value);

        if (!this.cardsSortable) {
          this.rerender();

          card.save().then(
            () => {
              $framework.antd_msg.success(
                translateSync("parse-admin:canban.reassign-card-group-success")
              );
            },
            (error) => {
              console.error(error);

              $framework.antd_msg.error(
                translateSync("parse-admin:canban.reassign-card-group-error")
              );
            }
          );
        }
      }

      if (this.cardsSortable) {
        const keys = column.cards.map((card) => card.key);

        if (source.droppableId === destination.droppableId) {
          keys.splice(source.index, 1);
        }

        keys.splice(destination.index, 0, result.draggableId);

        const objects = keys.map((key) => this.getCard(key));

        this.reassignOrder(objects, this.state.config.orderField).then(
          () => {
            $framework.antd_msg.success(
              translateSync("parse-admin:canban.reassign-card-order-success")
            );
          },
          (error) => {
            console.error(error);

            $framework.antd_msg.error(
              translateSync("parse-admin:canban.reassign-card-order-error")
            );
          }
        );
      } else {
        if (source.droppableId === destination.droppableId) {
          $framework.antd_msg.info(
            translateSync("parse-admin:canban.reassign-card-order-disabled")
          );
        }
      }
    }

    if (result.type === "COLUMN") {
      if (this.columnsSortable) {
        const keys = this.columnsArray.map((col) => col.key);

        keys.splice(source.index, 1);
        keys.splice(destination.index, 0, result.draggableId);

        const objects = keys.map((key) => this.getColumn(key).pointer);

        this.reassignOrder(objects, this.pointerConfig.orderField).then(
          () => {
            $framework.antd_msg.success(
              translateSync("parse-admin:canban.reassign-column-order-success")
            );
          },
          (error) => {
            console.error(error);

            $framework.antd_msg.error(
              translateSync("parse-admin:canban.reassign-column-order-error")
            );
          }
        );
      } else {
        $framework.antd_msg.info(
          translateSync("parse-admin:canban.reassign-column-order-disabled")
        );
      }
    }
  }
}
