import React, { useState, useEffect } from "react";

import { parse, ParseResult } from "papaparse";

import { Modal, Upload, Alert, Button } from "antd";

import { useTranslation } from "@opendash/core";
import { RcFile } from "antd/lib/upload";

export default function GreisTranspondersImport({
  open,
  close,
  addTransponders,
  overwriteTransponders,
}) {
  const t = useTranslation();
  const [file, setFile] = useState<RcFile>();
  const [csv, setCsv] = useState<ParseResult<any>>();

  useEffect(() => {
    if (file) {
      parse(file, {
        header: true,
        skipEmptyLines: "greedy",
        complete: (result) => {
          setCsv(result);
        },
      });
    } else {
      setCsv(undefined);
    }
  }, [file]);

  const clearAndClose = () => {
    setFile(undefined);
    close();
  };

  return (
    <Modal
      title={t("greis:transponders.admin.import")}
      visible={open}
      onOk={(e) => clearAndClose()}
      onCancel={(e) => clearAndClose()}
      footer={[
        <Button key="back" onClick={(e) => clearAndClose()}>
          {t("greis:transponders.admin.import_action_cancel")}
        </Button>,
        <Button
          key="import_action_add"
          type="primary"
          disabled={!csv || csv.errors.length > 0}
          onClick={(e) => {
            addTransponders(
              csv.data.map((row) => ({
                id: row[csv.meta.fields[0]],
                misc: row[csv.meta.fields[2]],
                misc2: row[csv.meta.fields[3]],
                owner: row[csv.meta.fields[1]],
              }))
            );

            clearAndClose();
          }}
          children={t("greis:transponders.admin.import_action_add")}
        />,
        <Button
          key="import_action_overwrite"
          type="primary"
          disabled={!csv || csv.errors.length > 0}
          onClick={(e) => {
            overwriteTransponders(
              csv.data.map((row) => ({
                id: row[csv.meta.fields[0]],
                owner: row[csv.meta.fields[1]],
                misc: row[csv.meta.fields[2]],
                misc2: row[csv.meta.fields[3]],
              }))
            );

            clearAndClose();
          }}
          children={t("greis:transponders.admin.import_action_overwrite")}
        />,
      ]}
    >
      <Preview preview={csv} />

      <Upload.Dragger
        multiple={false}
        fileList={file ? [file] : []}
        beforeUpload={(file) => {
          setFile(file);
          return false;
        }}
        onRemove={(file) => {
          setFile(undefined);
        }}
      >
        <p className="ant-upload-text">
          {t("greis:transponders.admin.import_upload_text")}
        </p>
        <p className="ant-upload-hint">
          {t("greis:transponders.admin.import_upload_hint")}
        </p>
      </Upload.Dragger>
    </Modal>
  );
}

function Preview({ preview }) {
  const t = useTranslation();

  const style = { marginBottom: 24 };

  if (!preview) {
    return null;
  }

  if (preview.errors.length > 0) {
    return (
      <Alert
        type="error"
        style={style}
        message={t("greis:transponders.admin.import_bad_csv")}
      />
    );
  }

  return (
    <Alert
      type="info"
      style={style}
      message={t("greis:transponders.admin.import_success", {
        count: preview.data.length,
      })}
    />
  );
}
