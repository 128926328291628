import { AggregationComponentInterface } from "./AggregationComponentInterface";
import SourceAggregation from "./SourceAggregation/SourceAggregation";
const ServiceFactory = () => {
  const aggregations = {} as Record<string, AggregationComponentInterface>;

  return {
    getAggregations: (key) => {
      return aggregations[key];
    },
    registerAggregation: (aggregation: AggregationComponentInterface) => {
      aggregations[aggregation.type] = aggregation;
    },
  };
};

const AggregationService = ServiceFactory();
AggregationService.registerAggregation(SourceAggregation());

export default AggregationService;
