type SubscriptionCallback<T = any> = (value: T) => void;

export class Subscription<T = any> {
  #value: T = undefined;
  #callbacks = new Set<SubscriptionCallback<T>>();

  constructor(value: T) {
    this.#value = value;
  }

  get value() {
    return this.#value;
  }

  watch(callback: SubscriptionCallback<T>) {
    this.#callbacks.add(callback);

    return () => {
      this.#callbacks.delete(callback);
    };
  }

  async notify(value: T) {
    for (const callback of this.#callbacks.values()) {
      try {
        await callback(value);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
