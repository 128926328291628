import * as Parse from "parse";
import { AggregationOperation } from "../virtual-sensors/types";

export interface ReportAttributes {
  type: string;
  name: string;
  user: Parse.User;
  updatedAt: Date;
  creadtedAt: Date;
  description: string;
  title: string;
  options: any;
  items: {
    reportid: string;
    stages: AggregationOperation[];
  }[];
  masterData?: Record<string, any>;
  template: string;
  objectId: string;
  images: {
    reportid: string;
    image: { id: string; source: string } | any;
  }[];
}

export class Report extends Parse.Object {
  constructor(attributes: Partial<ReportAttributes>) {
    super("OD3_Report", attributes);

    const owner = Parse.User.current();

    const acl = new Parse.ACL();

    acl.setReadAccess(owner, true);
    acl.setWriteAccess(owner, true);

    this.setACL(acl);
    this.set("user", owner);
    if (!attributes.masterData) {
      this.set({});
    }
    if (!attributes.items) {
      this.set([]);
    }
    if (!attributes.options) {
      this.set({});
    }
  }

  download() {
    return new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
  }
}
//TODO
Parse.Object.registerSubclass("OD3_Report", Report);
