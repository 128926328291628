import Parse from "parse";

export interface PageAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  data?: any;
  pageID?: string;
}

export class Page extends Parse.Object<PageAttributes> {
  static className: string = "OD3_BDE_Page";

  constructor(data?: Partial<PageAttributes>) {
    super("OD3_BDE_Page", data as PageAttributes);
  }

  get data(): any | undefined {
    return super.get("data");
  }
  set data(value: any | undefined) {
    super.set("data", value);
  }
  get pageID(): string | undefined {
    return super.get("pageID");
  }
  set pageID(value: string | undefined) {
    super.set("pageID", value);
  }
}

Parse.Object.registerSubclass("OD3_BDE_Page", Page);
