import { Checkbox, Form } from "antd";
import React from "react";

const CheckboxGroup = Checkbox.Group;

function CheckBox(props) {
  let style = {};
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  const state = props.Data;
  const setValue = props.SetValue;
  const options = state.options || [];
  const items = state.items || [];
  const rules = [];

  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }

  const plainOptions = items;

  return (
    <Form.Item name={state.key} label={state.title} rules={rules}>
      <CheckboxGroup style={style} options={plainOptions} />
    </Form.Item>
  );
}
export default CheckBox;
