import { useTranslation } from "@opendash/core";
import { Button, Form, Input } from "antd";
import React from "react";
import FileInputComponent from "react-file-input-previews-base64";

function ImageCam(props) {
  const t = useTranslation();
  let style = { width: "100%", display: "inline-block" };
  if (props.elementStyle) {
    style = Object.assign(style, props.elementStyle);
  }
  const formData = props.FormData;

  const imageToDataUri = (imga) => {
    const img = new Image();
    img.src = imga;
    img.onload = () => {
      const elem = document.createElement("canvas");

      //scale 2000x1000
      let newScaleWidth = 0;
      let newScaleHeight = 0;
      if (img.width >= img.height) {
        if (img.width > 1920) {
          var scaler = 1920 / img.width;
          newScaleWidth = img.width * scaler;
          newScaleHeight = img.height * scaler;
        } else {
          newScaleWidth = img.width;
          newScaleHeight = img.height;
        }
      } else {
        if (img.height > 1920) {
          var scaler = 1920 / img.height;
          newScaleWidth = img.width * scaler;
          newScaleHeight = img.height * scaler;
        } else {
          newScaleWidth = img.width;
          newScaleHeight = img.height;
        }
      }

      elem.width = newScaleWidth;
      elem.height = newScaleHeight;

      const ctx = elem.getContext("2d");
      ctx.drawImage(img, 0, 0, newScaleWidth, newScaleHeight);
      const data = ctx.canvas.toDataURL("image/jpeg", 0.7);

      const newForm = {};
      newForm[state.key] = data;
      formData.setFieldsValue(newForm);
    };
  };
  const state = props.Data;

  const rules = [];
  const options = state.options || {};

  if (state["v_required"] && state["v_required"].value) {
    rules.push({
      required: t("bde:app.forms.ImageCam.validation"),
      message: t("bde:app.forms.ImageCam.validation"),
    });
  }

  if (options.validation) {
    rules.push(({ getFieldValue }) => ({
      validator(rule, value) {
        return Promise.resolve();
      },
    }));
  }

  return (
    <div>
      <Form.Item
        className="hidden_custom_form"
        name={state.key}
        label={<div>{state.title}</div>}
        rules={rules}
        style={{ width: "100%" }}
      >
        <Input hidden></Input>
      </Form.Item>
      <FileInputComponent
        labelText=""
        labelStyle={{ fontSize: 20 }}
        multiple={false}
        imageContainerStyle={{
          textAlign: "center",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          paddingBottom: "5px",
        }}
        buttonComponent={
          <Button
            style={{ width: "100%" }}
            //@ts-ignore
            type="file"
            accept="image/*;capture=camera"
          >
            {t("bde:" + "app.forms.ImageCam.capture")}
          </Button>
        }
        callbackFunction={(file_arr) => {
          imageToDataUri(file_arr.base64);
        }}
        accept="image/*"
      />
    </div>
  );
}
export default ImageCam;
