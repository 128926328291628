import { Icon } from "@opendash/icons";
import { Button } from "antd";
import * as React from "react";
import {
  $framework,
  useElementSize,
  useOpenDashApp,
  useTranslation,
} from "../..";
import { NotificationDialog } from "../../features/notifications";
import * as Layout from "./components";
import { GlobalStyle } from "./GlobalStyle";
import { HeaderNavigation } from "./HeaderNavigation";
import { HeaderSourcePicker } from "./HeaderSourcePicker";
import { LanguageDialog } from "./LanguageDialog";
import { UserPasswordChange } from "./UserPasswordChange";
import { VersionDialog } from "./VersionDialog";

export default function AppLayout({ children }) {
  const t = useTranslation();

  const app = useOpenDashApp();

  const headerRef = React.useRef<HTMLElement>();
  const headerSize = useElementSize(headerRef);

  React.useEffect(() => {
    app.ui2.setHeaderSize(headerSize.height, headerSize.width);
  }, [headerSize.height, headerSize.width]);

  return (
    <>
      <GlobalStyle />
      <LanguageDialog />
      <NotificationDialog />
      <UserPasswordChange />
      <VersionDialog />

      {!app.ui.disableHeader && (
        <Layout.Header id="opendash-header" ref={headerRef}>
          <HeaderNavigation />
          <HeaderSourcePicker mobile />
        </Layout.Header>
      )}

      <Layout.Layout id="opendash-layout" ref={app._refs.layout}>
        <Layout.Content id="opendash-content">
          <div
            id="opendash-ref-content"
            ref={app._refs.content}
            style={{ width: "100vw" }}
          />

          {children}
        </Layout.Content>

        {!app.ui.disableFooter && (
          <Layout.Footer id="opendash-footer">
            <div id="opendash-ref-footer" ref={app._refs.footer} />

            <Layout.FooterRowCopyright>
              {($framework.ui2.copyrightText || null) && (
                <Button
                  type="link"
                  href={$framework.ui2.copyrightLink}
                  target="_blank"
                  children={$framework.ui2.copyrightText}
                />
              )}

              {($framework.ui2.imprint || null) && (
                <Button
                  type="link"
                  href={$framework.ui2.imprint}
                  target="_blank"
                  children={t("opendash:ui.imprint")}
                />
              )}

              {($framework.ui2.tos || null) && (
                <Button
                  type="link"
                  href={$framework.ui2.tos}
                  target="_blank"
                  children={t("opendash:ui.tos")}
                />
              )}

              {($framework.ui2.dataProtection || null) && (
                <Button
                  type="link"
                  href={$framework.ui2.dataProtection}
                  target="_blank"
                  children={t("opendash:ui.data_protection")}
                />
              )}

              <Button
                type="text"
                children={app.ui2.version}
                onClick={() => app.ui2.showVersionDialog()}
              />

              {($framework.serviceWorker.updateAvailable || null) && (
                <Button
                  type="text"
                  onClick={() => $framework.serviceWorker.installUpdateDialog()}
                  children={<Icon icon="fa:sync-alt" color="#52C41A" />}
                />
              )}
            </Layout.FooterRowCopyright>

            <div
              id="opendash-ref-footer-after-copyright"
              ref={app._refs.footerAfterCopyright}
            />
          </Layout.Footer>
        )}
      </Layout.Layout>
    </>
  );
}
