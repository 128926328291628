import { RouterLayoutProps } from "@opendash/router";
import { AdminToolbar } from "@opendash/ui";
import * as React from "react";
import { $framework, AdminLayout, useTranslation } from "..";

export const RouterLayoutBasic = React.memo<
  RouterLayoutProps & {
    padding?: boolean;
    actions?: React.ReactNode | React.ReactNode[];
  }
>(function RouterLayoutBasic({ children, ctx, padding, actions }) {
  const t = useTranslation();

  const title = t(ctx.title);
  const description = t(ctx.description);

  React.useEffect(() => {
    window.document.title = `${title} | ${$framework.ui2.name}`;

    return () => {
      window.document.title = $framework.ui2.name;
    };
  }, [title]);

  return (
    <AdminLayout contentPadding={padding}>
      <AdminToolbar title={title} description={description} actions={actions} />
      <div className="od-page-main">{children}</div>
    </AdminLayout>
  );
});
