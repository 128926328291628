import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import * as React from "react";
import { Component } from "react";

export class StepOrderEditor extends Component<
  { Entries: any[]; setSteps: (action: any) => void },
  any
> {
  data: any;
  setSteps: any;
  columns: (
    | { title: string; dataIndex: string; key: string; render?: undefined }
    | {
        title: string;
        key: string;
        render: (text: any, form: any) => React.JSX.Element;
        dataIndex?: undefined;
      }
  )[];

  constructor(props) {
    super(props);

    this.data = props.Entries.map((page, index) => {
      return Object.assign({ index }, page);
    });
    this.setSteps = props.setSteps;

    this.columns = [
      { title: "Name", dataIndex: "name", key: "name" },
      { title: "Position", dataIndex: "index", key: "index" },
      {
        title: "",
        key: "sort",
        render: (text, form) => {
          return (
            <div>
              <Button
                onClick={(e) => {
                  const cIndex = text.index;
                  const formCopy = [...this.data];
                  const item = formCopy[cIndex];
                  formCopy.splice(cIndex, 1);
                  formCopy.splice(Math.max(cIndex - 1, 0), 0, item);
                  this.updateSteps(formCopy);
                }}
              >
                <CaretUpOutlined />
              </Button>
              <Button
                onClick={(e) => {
                  const cIndex = text.index;
                  const formCopy = [...this.data];
                  const item = formCopy[cIndex];
                  formCopy.splice(cIndex, 1);
                  formCopy.splice(
                    Math.min(cIndex + 1, formCopy.length),
                    0,
                    item
                  );
                  this.updateSteps(formCopy);
                }}
              >
                <CaretDownOutlined />
              </Button>
            </div>
          );
        },
      },
    ];
  }

  updateSteps = (newOrder) => {
    this.setSteps((cForm) => {
      cForm.pages = newOrder.map((page) => {
        return page.objectid;
      });
    });
  };
  render() {
    this.data = this.props.Entries.map((page, index) => {
      return Object.assign({ index }, page);
    });

    return (
      <div>
        <Table
          size="middle"
          rowKey={(row) => row.index}
          dataSource={this.data}
          columns={this.columns}
        />
      </div>
    );
  }
}

export default StepOrderEditor;
