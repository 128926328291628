import { produce, useFeedback, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { WidgetStatic } from "@opendash/plugin-monitoring";
import { DataItemInterface } from "@opendash/plugin-timeseries";
import {
  Collapse,
  Divider,
  InputNumber,
  Modal,
  Space,
  Switch,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import { getRequestServer } from "./DataCollectionOverview";
import { ScheduledDelete } from "./ScheduledDelete";
import AggregationComponent from "./aggregation/AggregationComponent";
import DataItemEditComponent from "./aggregation/DataItemEditComponent";

const { Text } = Typography;

const SensorDetailModal = (props) => {
  const [mState, setMState] = useState<{ item: DataItemInterface }>({
    item: props.item as DataItemInterface,
  });
  const { notification } = useFeedback();

  const close = props.onClose;
  const t = useTranslation();
  const requestServer = getRequestServer();
  const deleteData = async (source, id, start, end) => {
    const res = await requestServer.delete(
      `data/items/${source}/${id}/${start}/${end}`
    );

    if (
      res.status === 200 &&
      start == 0 &&
      new Date(end).getFullYear() === 3000
    ) {
      notification.success({
        message: t("openware:sensor.admin.delete.success"),
        description: id,
        placement: "topRight",
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const inlineMargin = {
    marginLeft: "10px",
    marginRight: "10px",
  };

  return (
    <Modal
      title={mState.item.name}
      width="75%"
      open={props.item}
      onOk={() => {
        props.onSave(mState.item.meta.id_source, mState.item);
      }}
      onCancel={close}
    >
      <Tabs
        items={[
          {
            key: "1",
            label:
              t("openware:sensor.admin.status.label") +
              " & " +
              t("openware:sensor.admin.details.label"),
            children: (
              <>
                <Space direction="horizontal" size={"large"}>
                  <Space direction="horizontal">
                    <Switch
                      checked={mState.item.meta.active}
                      onChange={(checked) => {
                        setMState(
                          produce(mState, (draft) => {
                            draft.item.meta.active = checked;
                          })
                        );
                      }}
                    ></Switch>

                    {t("openware:sensor.admin.status.store")}
                  </Space>
                  <Space direction="horizontal">
                    <Switch
                      checked={mState.item.meta.visible}
                      onChange={(checked) => {
                        setMState(
                          produce(mState, (draft) => {
                            draft.item.meta.visible = checked;
                          })
                        );
                      }}
                    ></Switch>

                    <Text>{t("openware:sensor.admin.status.show")}</Text>
                  </Space>
                  <Space direction="horizontal">
                    <Switch
                      checked={mState.item.meta.onChange}
                      onChange={(checked) => {
                        setMState(
                          produce(mState, (draft) => {
                            draft.item.meta.onChange = checked;
                          })
                        );
                      }}
                    ></Switch>

                    {t("openware:sensor.admin.status.storeOnChange")}
                  </Space>
                </Space>
                <Divider></Divider>
                <DataItemEditComponent
                  item={mState.item}
                  setItem={(item) => {
                    setMState(
                      produce((draft) => {
                        draft.item = item;
                      })
                    );
                  }}
                />
              </>
            ),
          },
          {
            key: "2",
            label: t("openware:sensor.admin.scale.label"),
            children: (
              <>
                <Space direction="horizontal">
                  <Switch
                    checked={mState.item.meta.scaler}
                    onChange={(checked) => {
                      setMState(
                        produce(mState, (draft) => {
                          if (checked) {
                            draft.item.meta.scaler = {};
                          } else {
                            delete draft.item.meta.scaler;
                          }
                        })
                      );
                    }}
                  ></Switch>
                  <Text>{t("openware:sensor.admin.scale.description")}</Text>
                </Space>
                {mState.item.meta.scaler && (
                  <div style={{ margin: "10px" }}>
                    <Collapse activeKey={Object.keys(mState.item.meta.scaler)}>
                      {mState.item.valueTypes.map((vType, index) => {
                        return (
                          <>
                            {vType.type.toLocaleLowerCase() !== "number" && (
                              <Collapse.Panel
                                showArrow={false}
                                header={vType?.name || "-/-"}
                                key={"" + index}
                                extra={[
                                  <Tooltip
                                    title={t(
                                      "openware:sensor.admin.scale.noScaling"
                                    )}
                                  >
                                    <Icon icon="fa:exclamation"></Icon>
                                  </Tooltip>,
                                ]}
                              />
                            )}
                            {vType.type.toLocaleLowerCase() === "number" && (
                              <Collapse.Panel
                                showArrow={false}
                                header={vType?.name || "-/-"}
                                key={"" + index}
                                extra={[
                                  <Switch
                                    style={{
                                      marginTop: "10px",
                                      marginLeft: "10px",
                                    }}
                                    checked={
                                      mState.item.meta.scaler["" + index] ||
                                      false
                                    }
                                    onChange={(checked) => {
                                      setMState(
                                        produce(mState, (draft) => {
                                          if (checked) {
                                            draft.item.meta.scaler["" + index] =
                                              {
                                                factor: 1,
                                                offset: 0,
                                              };
                                          } else {
                                            delete draft.item.meta.scaler[
                                              "" + index
                                            ];
                                          }
                                        })
                                      );
                                    }}
                                  ></Switch>,
                                ]}
                              >
                                <>
                                  {mState.item.meta.scaler["" + index] && (
                                    <div>
                                      <Text code>
                                        {t(
                                          "openware:sensor.admin.scale.newValue"
                                        )}
                                      </Text>{" "}
                                      =<strong>(</strong>
                                      <Text code>
                                        {t(
                                          "openware:sensor.admin.scale.recievedValue"
                                        )}
                                      </Text>
                                      <strong style={inlineMargin}>+</strong>{" "}
                                      <InputNumber
                                        style={inlineMargin}
                                        defaultValue={0}
                                        value={
                                          mState.item.meta.scaler["" + index]
                                            .offset
                                        }
                                        onChange={(offVall) => {
                                          setMState(
                                            produce((draft) => {
                                              draft.item.meta.scaler[
                                                "" + index
                                              ].offset = offVall;
                                            })
                                          );
                                        }}
                                      />
                                      <strong>)</strong>
                                      <strong style={inlineMargin}>x</strong>
                                      <InputNumber
                                        style={inlineMargin}
                                        defaultValue={1}
                                        value={
                                          mState.item.meta.scaler["" + index]
                                            .factor
                                        }
                                        onChange={(factorVal) => {
                                          setMState(
                                            produce((draft) => {
                                              draft.item.meta.scaler[
                                                "" + index
                                              ].factor = factorVal;
                                            })
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </>
                              </Collapse.Panel>
                            )}
                          </>
                        );
                      })}
                    </Collapse>
                  </div>
                )}
              </>
            ),
          },
          {
            key: "4",
            label: t("openware:sensor.aggregate.label"),
            children: <AggregationComponent item={mState.item} />,
          },
          {
            key: "5",
            label: t("openware:admin.sensor.delete.singleValues"),
            children: (
              <>
                {mState.item && (
                  <div
                    style={{
                      padding: "0 16",
                      margin: "16 0",
                      height: 500,
                      width: "100%",
                    }}
                  >
                    <WidgetStatic
                      type="opendash-widget-table"
                      config={{
                        _history: {
                          unit: "minute",
                          value: 1,
                          historyType: "relative",
                          live: false,
                        },
                        _sources: [],
                        _items: [],
                        _dimensions: mState.item.valueTypes.map(
                          (vType, index) => {
                            return [mState.item.source, mState.item.id, index];
                          }
                        ),
                        deleteAction: (value) => {
                          console.log("toDelete", value);
                          deleteData(
                            // For backwards compatibitity look for a user prop.
                            // @ts-ignore
                            mState.item.source || mState.item.user,
                            mState.item.id,
                            value.date,
                            value.date
                          );
                        },
                        showDeleteButton: true,
                      }}
                    />
                  </div>
                )}
              </>
            ),
          },
          {
            key: "6",
            label: t("openware:admin.sensor.delete.scheduleDelete"),
            children: <ScheduledDelete item={mState.item} />,
          },
        ]}
      />
    </Modal>
  );
};

export default SensorDetailModal;
