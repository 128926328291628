import { useSource } from "@opendash/core";
import * as React from "react";
import { DashboardInterface, useDashboards } from "..";

export function useDashboardsBySource(): DashboardInterface[] {
  const [source, , , , enabled] = useSource();

  const dashboards = useDashboards();

  if (!enabled) {
    return dashboards;
  }

  return React.useMemo(
    () => dashboards.filter((dashboard) => dashboard.source === source?.id),
    [dashboards, source?.id]
  );
}
