import { useTranslation } from "@opendash/i18n";
import { Button, Divider, Empty, Input, InputNumber } from "antd";
import React from "react";

interface RangeWithValuePickerProps {
  data: Array<{ [key: string]: any }>;
  keyField: string;
  valueField: string;
  keyLabel: string;
  valueLabel: string;
  onChange: (updatedData: Array<{ [key: string]: any }>) => void;
}

export const RangeWithValuePicker: React.FC<RangeWithValuePickerProps> = ({
  data,
  keyField,
  valueField,
  valueLabel,
  keyLabel,
  onChange,
}) => {
  const t = useTranslation();
  const handleKeyChange = (index: number, newKey: number) => {
    const updatedData = [...data];
    updatedData[index][keyField] = newKey;
    onChange(updatedData);
  };

  const handleValueChange = (index: number, newValue: any) => {
    const updatedData = [...data];
    updatedData[index][valueField] = newValue;
    onChange(updatedData);
  };

  return (
    <>
      {data.length === 0 && <Empty></Empty>}
      {data.map((item, index, all) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%",
              }}
            >
              <InputNumber
                addonBefore={t(keyLabel)}
                value={item[keyField]}
                decimalSeparator=","
                onChange={(value) => handleKeyChange(index, value as number)}
                style={{ width: "30%" }}
              />
              <Input
                addonBefore={t(valueLabel)}
                value={item[valueField]}
                onChange={(e) => handleValueChange(index, e.target.value)}
                style={{ flexGrow: 3 }}
              />
            </div>
            {index !== all.length - 1 && <Divider />}
          </>
        );
      })}

      <Button
        type="dashed"
        style={{ width: "100%", marginTop: 10 }}
        onClick={() => {
          onChange([...data, { [keyField]: 0, [valueField]: "" }]);
        }}
      >
        +
      </Button>
    </>
  );
};
