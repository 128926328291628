import Parse from "parse";
import React from "react";

import { Card, Tooltip } from "antd";

import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";

import { ParseFieldImage } from "./ParseFieldImage";
import { ParseFields } from "./ParseFields";
import { getObjectActions } from "./helper";

interface Props {
  object?: Parse.Object;
  imageField?: string;
  titleFields?: string[];
  descriptionFields?: string[];
  onChange?: () => void;
}

export const ParseObjectCard = React.memo<Props>(function ParseObjectCard({
  object,
  imageField,
  titleFields,
  descriptionFields,
  onChange,
}) {
  const t = useTranslation();

  if (!object) {
    return null;
  }
  console.log({ object, imageField, titleFields, descriptionFields, onChange });
  return (
    <Card
      hoverable
      cover={<ParseFieldImage object={object} field={imageField} />}
      actions={getObjectActions(object, onChange).map((a) => (
        <Tooltip key={a.key} title={t(a.title)}>
          <Icon icon={a.icon} onClick={a.onClick} />
        </Tooltip>
      ))}
      children={
        <Card.Meta
          title={<ParseFields object={object} fields={titleFields} isInline />}
          description={
            <ParseFields object={object} fields={descriptionFields} isInline />
          }
        />
      }
    />
  );
});
