/**
 * Catch errors from a promise and return them as an array or the result
 * @param promise A promise to catch errors from
 * @param errorsToCatch An array of error classes to catch. If not provided, all errors will be caught.
 * @returns The promise result or an array with the error
 */
export async function catchError<T, E extends new (Message?: string) => Error>(
  promise: Promise<T>,
  errorsToCatch?: E[]
): Promise<[undefined, T] | [InstanceType<E>]> {
  try {
    const data = await promise;
    return [undefined, data] as [undefined, T];
  } catch (error) {
    if (errorsToCatch === undefined) {
      return [error];
    }

    if (errorsToCatch.some((e) => error instanceof e)) {
      return [error];
    }
    throw error;
  }
}
