import React, { useState, useMemo } from "react";

import { unparse } from "papaparse";

import { Modal, Button, Radio } from "antd";

import { useTranslation } from "@opendash/core";

export default function GreisTranspondersExport({ open, close, transponders }) {
  const t = useTranslation();
  const [delimiter, setDelimiter] = useState(";");

  const csv = useMemo(() => {
    return unparse(
      transponders.map((row) => ({
        [t("greis:transponders.admin.cols.id")]: row.id,
        [t("greis:transponders.admin.cols.owner")]: row.owner,
        [t("greis:transponders.admin.cols.misc")]: row.misc,
        [t("greis:transponders.admin.cols.misc2")]: row.misc2,
      })),
      {
        delimiter,
        header: true,
      }
    );
  }, [delimiter, transponders]);

  return (
    <Modal
      title={t("greis:transponders.admin.export")}
      visible={open}
      onOk={(e) => close()}
      onCancel={(e) => close()}
      footer={[
        <Button key="back" onClick={(e) => close()}>
          {t("greis:transponders.admin.import_action_cancel")}
        </Button>,
        <Button
          key="export_action_ok"
          type="primary"
          //icon={<DownloadOutlined />}
          onClick={(e) => {
            downloadCsv("export.csv", csv);
            close();
          }}
          children={t("greis:transponders.admin.export_action_ok")}
        />,
      ]}
    >
      <label>
        <div>{t("greis:transponders.admin.csv_delimiter")}</div>
        <Radio.Group
          value={delimiter}
          onChange={(e) => setDelimiter(e.target.value)}
        >
          <Radio value={","}>
            {t("greis:transponders.admin.csv_delimiter_comma")}
          </Radio>
          <Radio value={";"}>
            {t("greis:transponders.admin.csv_delimiter_semicolon")}
          </Radio>
        </Radio.Group>
      </label>
    </Modal>
  );
}

function downloadCsv(filename, text) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
