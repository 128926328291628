import { Table, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import React from "react";

import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import { createComponent } from "@opendash/state";

import type { ParseFilterState } from "../../state/ParseFilterState";
import { TableViewType } from "../../types";
import { ParseField } from "../ParseField";
import { getObjectActions, translateClassField } from "../helper";
import { ParseTableFilter } from "./ParseTableFilter";
import { onRow } from "./ViewLayout";

interface Props {
  state: ParseFilterState;
  pagination: number;
  view: TableViewType;
  onRow?: onRow;
}

const expandableTableConfig = {
  childrenColumnName: "thisFieldNameDoesNeverEverExist",
};

export const TableView = createComponent<Props>(function TableView({
  state,
  pagination = 20,
  view,
  onRow,
}) {
  const t = useTranslation();
  const fields = view.fields || state.config.displayFields;
  const customCols = view.customCols;
  const columns = React.useMemo(() => {
    const columnsOut: ColumnProps<any>[] = [];

    for (const col of fields) {
      const fieldSchema = state.schema.fields[col];

      if (!fieldSchema) {
        continue;
      }

      const column: ColumnProps<any> = {
        title: () => <TableColTitle className={state.className} field={col} />,
        key: col,
        filtered: !!state.filter[col],
        filteredValue: state.filter[col] ? state.filter[col] : null,
        sorter: ["String", "Number", "Date"].includes(fieldSchema.type),
        sortOrder: state.sortKey === col ? state.sortOrder : undefined,
        dataIndex: ["attributes", col],
        ellipsis: false,
        render: (v, row) => {
          return <ParseField object={row} field={col} isInline={true} />;
        },
      };

      if (["String", "Boolean"].includes(fieldSchema.type)) {
        column.filterDropdown = (props) => (
          <ParseTableFilter type={fieldSchema.type} {...props} />
        );
      }

      columnsOut.push(column);
    }
    if (customCols && customCols.length > 0) {
      customCols.forEach((col) => {
        columnsOut.push(col);
      });
    }
    columnsOut.push({
      title: translateClassField(t, state.className, "id"),
      dataIndex: "id",
      key: "default-id",
      fixed: "right",
      render: (id, row) => (
        <div style={{ width: 130 }}>
          {getObjectActions(row, () => state.data.reload()).map((a) => (
            <Tooltip key={a.key} title={t(a.title)}>
              <Icon
                icon={a.icon}
                onClick={a.onClick}
                style={{
                  marginLeft: 10,
                  marginBottom: 5,
                  marginTop: 5,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          ))}
        </div>
      ),
    });

    return columnsOut;
  }, [state.sortKey, state.sortOrder, state.filter, state.config, fields]);

  if (state.data.error) {
    return <pre>{JSON.stringify(state.data.error)}</pre>;
  }

  if (pagination != 20) {
    state.paginationConfig.pageSize = pagination;
    state.paginationConfig.showSizeChanger = false;
    state.setPageSize(pagination || 20);
    state.pageSize = pagination || 20;
  }

  return (
    <Table
      rowKey={(row) => row.id}
      dataSource={state.data.result}
      size="middle"
      columns={columns}
      loading={state.data.loading}
      rowSelection={state.selectionConfig}
      pagination={state.paginationConfig}
      expandable={expandableTableConfig}
      onRow={onRow}
      onChange={(page, filter, sort) => {
        state.setFilter(filter);

        if (!Array.isArray(sort)) {
          state.setSortKey(sort.columnKey as string);
          state.setSortOrder(sort.order);
        }
      }}
    />
  );
});

function TableColTitle({
  className,
  field,
}: {
  className: string;
  field: string;
}) {
  const t = useTranslation();

  return <span>{translateClassField(t, className, field)}</span>;
}
