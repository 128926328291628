import Parse from "parse";
import React from "react";

import { Button, Divider, InputNumber } from "antd";

import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { $geo } from "@opendash/plugin-geo";

export interface GeoPointDisplayProps {
  value: Parse.GeoPoint;
}

export const GeoPointDisplay = React.memo<GeoPointDisplayProps>(
  function GeoPointDisplay({ value }) {
    const latitudeShort = Math.round(value.latitude * 10) / 10;
    const longitudeShort = Math.round(value.longitude * 10) / 10;

    if (!$geo.vectorStyle) {
      return (
        <a
          href={`http://www.google.com/maps/place/${value.latitude},${value.longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {latitudeShort}
          <Divider type="vertical" />
          {longitudeShort}
        </a>
      );
    }

    return (
      <Button
        size="small"
        onClick={() => {
          $geo.dialogs.showPointDialog({
            latitude: value.latitude,
            longitude: value.longitude,
          });
        }}
      >
        {latitudeShort}
        <Divider type="vertical" />
        {longitudeShort}
      </Button>
    );
  }
);

export interface GeoPointEditProps {
  value: Parse.GeoPoint;
  setValue?: (v: Parse.GeoPoint) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const GeoPointEdit = React.memo<GeoPointEditProps>(
  function GeoPointEdit({ value, setValue }) {
    const t = useTranslation();
    React.useEffect(() => {
      //TODO: FIX THIS
      setTimeout(() => {
        if (!value) {
          setValue(
            new Parse.GeoPoint({
              latitude: 50.8,
              longitude: 8.1,
            })
          );
        }
      }, 200);
    }, []);

    /*
    React.useEffect(() => {
      if (!value && $geo.location.latitude && $geo.location.longitude) {
        setValue(
          new Parse.GeoPoint({
            latitude: $geo.location.latitude,
            longitude: $geo.location.longitude,
          })
        );
      }
      if (!value && !($geo.location.latitude && $geo.location.longitude)) {
        setValue(
          new Parse.GeoPoint({
            latitude: 0,
            longitude: 0,
          })
        );
      }
    }, [value, $geo.location.latitude, $geo.location.longitude]);
*/
    const showPicker = !!$geo.vectorStyle;

    return (
      <div>
        <InputNumber
          placeholder={t("parse-admin:admin.inputs.latitude")}
          parser={(v) => parseFloat(v) || 0}
          value={value?.latitude || 0}
          onChange={(v: number) =>
            setValue(
              new Parse.GeoPoint({ latitude: v, longitude: value.longitude })
            )
          }
          style={{
            width: showPicker ? "calc(50% - 34px)" : "calc(50% - 8px)",
            marginRight: 8,
          }}
        />

        <InputNumber
          placeholder={t("parse-admin:admin.inputs.longitude")}
          parser={(v) => parseFloat(v) || 0}
          value={value?.longitude || 0}
          onChange={(v: number) =>
            setValue(
              new Parse.GeoPoint({ latitude: value.latitude, longitude: v })
            )
          }
          style={{
            width: showPicker ? "calc(50% - 34px)" : "calc(50% - 8px)",
            marginLeft: 8,
            marginRight: showPicker ? 16 : 0,
          }}
        />

        {showPicker && (
          <Button
            icon={<Icon icon="fa:thumbtack" />}
            onClick={(e) => {
              $geo.dialogs
                .editPointDialog({
                  latitude: value.latitude,
                  longitude: value.longitude,
                })
                .then((point) => {
                  if (point) {
                    setValue(
                      new Parse.GeoPoint({
                        latitude: point.latitude,
                        longitude: point.longitude,
                      })
                    );
                  }
                });
            }}
          />
        )}
      </div>
    );
  }
);
