import { $geo } from "@opendash/plugin-geo";
import { Button } from "antd";
import Parse from "parse";
import React from "react";
import { $parse } from "../../state";

import { ObjectEdit } from "./Object";
export interface PolygonDisplayProps {
  value: Parse.Polygon;
}

export const PolygonDisplay = React.memo<PolygonDisplayProps>(
  function PolygonDisplay({ value }) {
    return (
      <Button
        size="small"
        children={"Polygon"}
        onClick={() => {
          $geo.dialogs.showPolygonDialog(
            $parse.helper.convertParsePolygon(value)
          );
        }}
      />
    );
  }
);

export interface PolygonEditProps {
  value: Parse.Polygon;
  setValue?: (v: Parse.Polygon) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const PolygonEdit = React.memo<PolygonEditProps>(function PolygonEdit({
  value,
  setValue,
  isRequired,
  isNullable,
}) {
  return (
    <ObjectEdit
      value={value?.toJSON()?.coordinates || []}
      setValue={(value) => setValue(new Parse.Polygon(value))}
      isRequired={isRequired}
      isNullable={isNullable}
    />
  );
});
