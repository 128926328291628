import { useTranslation } from "@opendash/core";
import { Switch } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { ItemAttributes } from "..";

export default observer((props: { field; updateField }) => {
  // return <pre>{JSON.stringify(field, null, "  ")}</pre>;
  const t = useTranslation();
  if (!props.field.options.mode) {
    props.updateField((draft) => {
      draft.options.mode = "single";
      draft.options.unit = "ms";
      draft.od_type = "Number";
      draft.options.seconds = false;
    });
  }
  return (
    <div>
      <div className="od_admin_bde_input">
        <Switch
          checked={props.field.options.mode == "complete"}
          onChange={(e) => {
            props.updateField((state) => {
              state.options.mode = e ? "complete" : "single";
            });
          }}
        />{" "}
        {t("bde:fields.label_startStopAlternative")}
      </div>
      <div className="od_admin_bde_input">
        <Switch
          checked={props.field.options.seconds}
          onChange={(e) => {
            props.updateField((state) => {
              state.options.seconds = e;
            });
          }}
        />{" "}
        {t("bde:fields.label_askSeconds")}
      </div>
      {ItemAttributes(props)}
    </div>
  );
});
