import type { SourceAdapterInterface, SourceInterface } from "..";

import { BaseService } from "../classes/BaseService";
import type { FrameworkService } from "./FrameworkService";
import { SourceAdapterContext } from "./SourceAdapterContext";

export class SourceService extends BaseService {
  private framework: FrameworkService;
  private adapter: SourceAdapterInterface;
  private context: SourceAdapterContext;

  constructor(framework: FrameworkService) {
    super({
      initialState: {},
    });

    this.framework = framework;
    this.context = new SourceAdapterContext(this, framework);
  }

  public async init(adapter: SourceAdapterInterface) {
    this.adapter = adapter;
    this.initAdapter(adapter, this.context);
  }

  _getSync(id: string): SourceInterface {
    return this.framework.state.select((state) =>
      state.sources.all.find((s) => s.id === id)
    );
  }

  getCurrent(): SourceInterface {
    return this.framework.state.select((state) => state.sources.current);
  }

  getChildren(source: SourceInterface): SourceInterface[] {
    return this.framework.state.select((state) =>
      state.sources.all.filter((s) => s.parent === source.id)
    );
  }

  getDescendents(rootSource: SourceInterface): SourceInterface[] {
    const descendentsIds = this._getDescendentsIds(rootSource);

    return this.getAll().filter((source) => descendentsIds.includes(source.id));
  }

  _getDescendentsIds(rootSource: SourceInterface): string[] {
    let all = this.getAll()
      .filter((source) => source.parent && source.id !== rootSource.id)
      .map((source) => [source.id, source.parent]);

    const descendents: string[] = [];
    let added: string[] = [rootSource.id];
    let lastAdded: string[] = [];

    while (true) {
      lastAdded = added;
      added = [];

      for (const addedSourceId of lastAdded) {
        for (const [id, parent] of all) {
          if (parent === addedSourceId) {
            added.push(id);
          }
        }
      }

      if (added.length > 0) {
        descendents.push(...added);
        all = all.filter(([id]) => !added.includes(id));
      } else {
        break;
      }
    }

    return descendents;
  }

  getAll(): SourceInterface[] {
    return this.framework.state.select((state) => state.sources.all);
  }

  setCurrent(id: string) {
    this.framework.state.update((draft) => {
      draft.sources.current = draft.sources.all.find(
        (source) => source.id === id
      );
    });

    this.notifySubscribers();
  }
}
