import { useTranslation } from "@opendash/core";
import { DatePicker, Form, Input, Select, Space, TimePicker } from "antd";
import dayjs from "dayjs";
import produce from "immer";
import React, { useState } from "react";

const { Option } = Select;
const dateFormat = "DD.MM.YYYY";

export default (props) => {
  const t = useTranslation();
  const data = props.Data;
  const formData = props.FormData;
  const rules = [];
  if (data["v_required"] && data["v_required"].value) {
    rules.push({
      required: data["v_required"].value,
      message: data["v_required"].err,
    });
  }
  const start = dayjs();

  start.set("hours", 0);
  start.set("minutes", 0);
  start.set("seconds", 0);

  const [pickerState, setPickerState] = useState({
    date: start,
  });
  React.useEffect(() => {
    if (!formData) return;
    const x = {};

    x[data.key] = pickerState.date.valueOf();
    formData.setFieldsValue(x);
  }, [pickerState.date]);

  const getPicker = () => {
    const now = pickerState.date;
    if (!now) return "";

    const setDate = (e) => {
      setPickerState(
        produce((draft) => {
          const newNow = dayjs(e);
          draft.date = newNow;
        })
      );
    };
    return (
      <div>
        <DatePicker
          style={{
            width: "100%",
            textAlign: "center",
          }}
          onChange={setDate}
          defaultValue={start}
          format={dateFormat}
        />
      </div>
    );
  };

  const getTimePicker = () => {
    const setTime = (e) => {
      setPickerState(
        produce((draft) => {
          const newNow = dayjs(pickerState.date);
          newNow.set("hours", e.hour());
          newNow.set("minutes", e.minute());
          newNow.set("seconds", e.seconds());
          draft.date = newNow;
        })
      );
    };
    return (
      <TimePicker
        style={{
          width: "100%",
          textAlign: "center",
        }}
        onChange={setTime}
      />
    );
  };

  return (
    <div style={{ marginBottom: "24px" }}>
      <Form.Item
        className="hidden_custom_form"
        name={data.key}
        label={data.title}
        rules={rules}
        style={{ marginBottom: "0px" }}
      >
        <Input style={{ display: "none" }} />

        <div className="timepicker_drawer" style={{ textAlign: "center" }}>
          <div
            className="timespan_picker"
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              {getPicker()}
              {props.Time ? getTimePicker() : ""}
            </Space>
          </div>
        </div>
      </Form.Item>
    </div>
  );
};
