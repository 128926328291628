import dayjs from "dayjs";
const milliseconds = ["ms", "milliseconds"];
const seconds = ["s", "sec", "sek", "seconds", "sekunden"];
const minutes = ["min", "minutes", "minuten"];
const hours = ["std", "h", "hr", "hrs", "stunden", "hours"];
const days = ["days", "tage"];
const weeks = ["wochen", "weeks"];
const month = ["months", "monate"];
const years = ["jahre", "years"];

export enum TimeMode {
  DurationMode = 0,
  DateMode = 1,
}
export enum TimeDisplayMode {
  DurationMode = 0,
  DateMode = 1,
}
export const isDateUnit = (unit) => {
  unit = unit.toLowerCase();
  if (milliseconds.indexOf(unit) != -1) {
    return true;
  }
  if (seconds.indexOf(unit) != -1) {
    return true;
  }
  if (minutes.indexOf(unit) != -1) {
    return true;
  }
  if (hours.indexOf(unit) != -1) {
    return true;
  }
  if (days.indexOf(unit) != -1) {
    return true;
  }
  if (weeks.indexOf(unit) != -1) {
    return true;
  }
  if (month.indexOf(unit) != -1) {
    return true;
  }
  if (years.indexOf(unit) != -1) {
    return true;
  }
  return false;
};

export const getTimeString = (tMode, timeDisplay, unit, cValue) => {
  if (tMode === TimeMode.DurationMode) {
    let date;
    let unitToUse;
    if (years.indexOf(unit.toLowerCase()) != -1) {
      //@ts-expect-error
      date = dayjs.duration(cValue, "years");
      unitToUse = "years";
    }
    if (month.indexOf(unit.toLowerCase()) != -1) {
      //@ts-expect-error
      date = dayjs.duration(cValue, "months");
      unitToUse = "months";
    }
    if (weeks.indexOf(unit.toLowerCase()) != -1) {
      //@ts-expect-error
      date = dayjs.duration(cValue, "weeks");
      unitToUse = "weeks";
    }
    if (days.indexOf(unit.toLowerCase()) != -1) {
      //@ts-expect-error
      date = dayjs.duration(cValue, "days");
      unitToUse = "days";
    }
    if (hours.indexOf(unit.toLowerCase()) != -1) {
      //@ts-expect-error
      date = dayjs.duration(cValue, "hours");
      unitToUse = "hours";
    }
    if (minutes.indexOf(unit.toLowerCase()) != -1) {
      //@ts-expect-error
      date = dayjs.duration(cValue, "minutes");
      unitToUse = "minutes";
    }
    if (seconds.indexOf(unit.toLowerCase()) != -1) {
      //@ts-expect-error
      date = dayjs.duration(cValue, "seconds");
      unitToUse = "seconds";
    }
    if (milliseconds.indexOf(unit.toLowerCase()) != -1) {
      //@ts-expect-error
      date = dayjs.duration(cValue);
      unitToUse = "milliseconds";
    }
    if (timeDisplay === "full") {
      let string = "";
      string += date.years() ? date.years() + " Jahr(e) " : "";
      string += date.months() ? date.months() + " Monat(e) " : "";
      string += date.days() ? date.days() + " Tag(e) " : "";
      string += date.hours() ? date.hours() + " Stunde(n) " : "";
      string += date.minutes() ? date.minutes() + " Minute(n) " : "";
      string += date.seconds() ? date.seconds() + " Sekunde(n) " : "";

      return string;
    }
    if (timeDisplay === "unit")
      return date.as(unitToUse).toFixed(2) + " " + unit;
    if (timeDisplay === "humanize") return date.humanize();
    if (timeDisplay === "unix") return Math.floor(date.valueOf()) + " ms";
  } else {
    const date = dayjs(cValue);
    //["unix", "full", "date", "time", "humanize"];
    if (timeDisplay === "unix") return "" + date.valueOf();
    if (timeDisplay === "full") return date.format("DD.MM.YYYY @ HH:mm:ss");
    if (timeDisplay === "date") return date.format("DD.MM.YYYY");
    if (timeDisplay === "time") return date.format("HH:mm:ss");
    // @ts-expect-error
    if (timeDisplay === "humanize") return date.fromNow();
  }
};
export const isSecondsUnit = (unit) => {
  return seconds.indexOf(unit.toLowerCase()) != -1;
};
export const isMillisecondsUnit = (unit) => {
  return milliseconds.indexOf(unit.toLowerCase()) != -1;
};
export const isMinutesUnit = (unit) => {
  return minutes.indexOf(unit.toLowerCase()) != -1;
};
export const isHoursUnit = (unit) => {
  return hours.indexOf(unit.toLowerCase()) != -1;
};
export const isDaysUnit = (unit) => {
  return days.indexOf(unit.toLowerCase()) != -1;
};
export const isWeeksUnit = (unit) => {
  return weeks.indexOf(unit.toLowerCase()) != -1;
};
export const isMonthUnit = (unit) => {
  return month.indexOf(unit.toLowerCase()) != -1;
};
export const isYearsUnit = (unit) => {
  return years.indexOf(unit.toLowerCase()) != -1;
};
