import { Translate, equals } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button } from "antd";
import * as React from "react";
import styled from "styled-components";
import { $monitoring, WidgetContext, WidgetPresetInterface } from "..";

const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

const IconHolder = styled.div`
  font-size: 2em;
  padding-top: 7px;
  padding-bottom: 10px;
`;

const Type = styled.div`
  font-weight: bold;
  margin-bottom: 2px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Message = styled.div`
  margin-bottom: 10px;
`;

export class WidgetErrorBoundary extends React.Component<
  {
    context: WidgetContext;
    children: React.ReactNode;
  },
  { error: Error }
> {
  state = { error: undefined, presets: [] as WidgetPresetInterface[] };
  private unsubscribe;
  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.error &&
      !equals(
        prevProps.context?.widget?.config,
        this.props.context?.widget?.config
      )
    ) {
      this.setState(Object.assign(this.state, { error: null }));
    }
  }

  checkWidgetConfig = (objekt1, objekt2) => {
    if (typeof objekt1 !== "object" || typeof objekt2 !== "object") {
      return false;
    }
    for (let attribut in objekt1) {
      if (
        objekt1.hasOwnProperty(attribut) &&
        objekt2.hasOwnProperty(attribut)
      ) {
        if (
          JSON.stringify(objekt1[attribut]) !==
          JSON.stringify(objekt2[attribut])
        ) {
          return false;
        }
      }
    }
    return true;
  };

  getWidgetLabel = () => {
    const gefundenObjekt = this.props.context.type.presets.find((preset) =>
      this.checkWidgetConfig(
        preset.widget.config,
        this.props.context.widget.config
      )
    );

    let label = gefundenObjekt ? gefundenObjekt.label : "";

    //Backup:
    if (label == "") {
      const presets_backup = $monitoring.types;
      const gefundenObjekt_backup = presets_backup.find(
        (preset) => preset.type === this.props.context.widget.type
      );

      label = gefundenObjekt_backup
        ? gefundenObjekt_backup.presets[0].label
        : "";
    }

    return label;
  };

  render() {
    if (this.state.error) {
      if (this.state.error.widget_config) {
        return (
          <Container>
            <div>
              <Label>
                <Translate t={this.getWidgetLabel()} />
              </Label>
              <IconHolder>
                <Icon icon="fa:cog" />
              </IconHolder>
              <Type>
                <Translate t="opendash:monitoring.widgets.error.config" />
              </Type>
              <Message>
                <Translate t={this.state.error.message} />
              </Message>
              {this.props.context.openSettings && (
                <Button
                  type="primary"
                  onClick={() => {
                    this.props.context.openSettings();
                  }}
                >
                  <Translate t="opendash:widgets.settings" />
                </Button>
              )}
            </div>
          </Container>
        );
      }

      return (
        <Container>
          <div>
            <Label>
              <Translate t={this.getWidgetLabel()} />
            </Label>
            <IconHolder>
              <Icon icon="fa:exclamation-circle" />
            </IconHolder>
            <Type>
              <Translate t="opendash:monitoring.widgets.error.default" />
            </Type>
            <Message>
              <Translate t="opendash:monitoring.widgets.error.default_desc" />
            </Message>
            <Button
              type="primary"
              onClick={(e) => {
                this.props.context.refresh();
              }}
            >
              <Translate t="opendash:widgets.reload" />
            </Button>
          </div>
        </Container>
      );
    }

    return this.props.children;
  }
}
