import { Form, Radio, Select } from "antd";
import Parse from "parse";
import React from "react";

import { useTranslation } from "@opendash/core";
import { configProvider } from "../../..";

function SelectBox(props) {
  const t = useTranslation();
  let style = {};
  const [items, setItems] = React.useState(props.Data.items || []);
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  const state = props.Data;
  const setValue = props.SetValue;
  const options = state.options || [];
  const rules = [];

  React.useEffect(() => {
    if (props.Data.list) {
      initList();
    }
  }, [props.Data.list]);
  const initList = async () => {
    try {
      const listObj = new Parse.Object(configProvider.config.lists.table);
      listObj.id = state.list;
      const listOptions = await new Parse.Query(
        configProvider.config.lists.children
      )
        .equalTo("list", listObj)
        .limit(999999)
        .find();
      setItems(
        listOptions.map((parseOption) => {
          return {
            value: parseOption.get("key"),
            label: t("bde:" + parseOption.get("value")),
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  if (state["v_required"] && state["v_required"].value) {
    rules.push({
      required: t("bde:app.forms.common.required"),
      message: t("bde:app.forms.common.error"),
    });
  }

  const typeSelect = () => {
    if (options.type) {
      if (options.type == "button") {
        return (
          <Radio.Group
            style={style}
            options={items}
            optionType="button"
            buttonStyle="solid"
          />
        );
      }
    }
    return <Select style={style} options={items} />;
  };

  return (
    <Form.Item name={state.key} label={state.title} rules={rules}>
      {typeSelect()}
    </Form.Item>
  );
}
export default SelectBox;
