import ShelfSelect from "./app/custom_arfa/ShelfSelect";
import AutoComplete from "./app/forms/components/AutoComplete";
import CheckBox from "./app/forms/components/CheckBox";
import ImageCam from "./app/forms/components/ImageCam";
import InfoMessage from "./app/forms/components/InfoMessage";
import NumberInput from "./app/forms/components/NumberInput";
import SelectBox from "./app/forms/components/SelectBox";
import StartStop from "./app/forms/components/StartStop";
import TextInput from "./app/forms/components/TextInput";
import TimePicker from "./app/forms/components/TimePicker";
import TimeSelect from "./app/forms/components/TimeSelect";

import {
  AutoComplete as AutoCompleteEdit,
  CheckList as CheckListEdit,
  DurationPicker as DurationPickerEdit,
  ImageChooser as ImageChooserEdit,
  Label as LabelEdit,
  Spinner as SpinnerEdit,
  StartStop as StartStopEdit,
  TextItem as TextItemEdit,
  TimeSelect as TimeSelectEdit,
} from "./features/forms";

export type FormComponentProps = {
  Data: any;
  FormData: any;
  ShowNextButton: any;
};
export type FormField = {
  v_required: { value: boolean; err: string };
  od_type: "Numbber" | "Boolean" | "String" | "Geo" | "Object";
  options: { setsDate?: boolean } | any;
  hint: string;
  type: string;
  title: string;
  key: string;
  description: string;
  items?: any[];
};

export type FormEditComponentProps = {
  field: FormField;
  updateField: (stateUpdate: (FormField) => void) => void;
};
export type FormComponent = React.FC<FormComponentProps>;
export type FormEditComponent = React.FC<FormEditComponentProps>;
export type Form = {
  AppComponent: FormComponent;
  EditComponent: FormEditComponent | null;
  label: string;
  icon?: string;
};

export class FormManager {
  legacyTypes = {
    hint: "info",
  };

  forms = {} as Record<string, Form>;
  static me: FormManager = null;
  static getInstance() {
    if (!FormManager.me) {
      FormManager.me = new FormManager();
    }
    return FormManager.me;
  }
  register = (name: string, form: Form) => {
    this.forms[name.toLowerCase()] = form;
  };
  get = (name: string) => {
    let formType = name;
    if (this.legacyTypes[name]) {
      formType = this.legacyTypes[name];
    }
    return this.forms[formType.toLowerCase()];
  };
  delete = (name: string) => {
    delete this.forms[name];
  };
  list = () => {
    return Object.keys(this.forms).filter(
      (key) => this.forms[key].EditComponent !== null
    );
  };
  init = () => {
    this.forms["shelfselect"] = {
      AppComponent: ShelfSelect,
      label: "bde:fields.label_ShelfSelect",
      EditComponent: null,
    };
    this.forms["text"] = {
      icon: "fa:pen",
      label: "bde:fields.label_text",
      AppComponent: TextInput,
      EditComponent: TextItemEdit,
    };
    this.forms["number"] = {
      icon: "fa:hashtag",
      label: "bde:fields.label_number",
      AppComponent: NumberInput,
      EditComponent: TextItemEdit,
    };
    this.forms["info"] = {
      icon: "fa:info",
      label: "bde:fields.label_info",
      AppComponent: InfoMessage,
      EditComponent: LabelEdit,
    };
    this.forms["check_box"] = {
      icon: "fa:check-square",
      AppComponent: CheckBox,
      EditComponent: CheckListEdit,
      label: "bde:fields.label_check_box",
    };
    this.forms["select"] = {
      icon: "fa:caret-square-down",
      AppComponent: SelectBox,
      EditComponent: SpinnerEdit,
      label: "bde:fields.label_select",
    };
    this.forms["choose_image"] = {
      icon: "fa:image",
      AppComponent: ImageCam,
      EditComponent: ImageChooserEdit,
      label: "bde:fields.label_choose_image",
    };
    this.forms["autocomplete"] = {
      icon: "fa:spell-check",
      AppComponent: AutoComplete,
      EditComponent: AutoCompleteEdit,
      label: "bde:fields.label_autocomplete",
    };
    this.forms["duration"] = {
      icon: "fa:user-clock",
      AppComponent: TimePicker,
      EditComponent: DurationPickerEdit,
      label: "bde:fields.label_duration",
    };
    this.forms["date"] = {
      icon: "fa:calendar",
      AppComponent: TimeSelect,
      EditComponent: TimeSelectEdit,
      label: "bde:fields.label_date",
    };
    this.forms["startstop"] = {
      icon: "fa:stopwatch",
      AppComponent: StartStop,
      EditComponent: StartStopEdit,
      label: "bde:fields.label_startstop",
    };
  };
  private constructor() {
    FormManager.me = this;
    this.init();
  }
}
