import { AdminLayout, Loading, produce, useTranslation } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import { Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Options } from "../Plugin";

export const ArchiveProvider = observer((props: Partial<Options>) => {
  const t = useTranslation();
  const [state, setState] = useState<{
    dataList: DataType[];
    stillLoading: boolean;
  }>({
    dataList: [],
    stillLoading: true,
  });
  const locale = {
    emptyText: t("feedback:archive.empty"),
  };

  React.useEffect(() => {
    getIssues();
  }, []);

  const renderers = {
    p: (props) => <p style={{ margin: "0px" }}>{props.children}</p>,
  };

  const getIssues = async () => {
    try {
      const response = await fetch(props.feedbackURL);
      const issues = await response.json();
      let result: DataType[] = issues.result;
      result = result.filter(function (el) {
        return (
          el.state == "Duplicate" ||
          el.state == "Canceled" ||
          el.state == "Done"
        );
      });

      setState(
        produce((draft) => {
          draft.dataList = result;
          draft.stillLoading = false;
        })
      );
    } catch (e) {
      console.error("Error getting Issues");
      setState(
        produce((draft) => {
          draft.stillLoading = false;
        })
      );
    }
  };

  interface DataType {
    id: string;
    createdAt: string;
    updatedAt: string;
    state?: string;
    labels: string[];
    title: string;
    description: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: t("feedback:table.title"),
      key: "title",
      dataIndex: "title",
    },
    {
      title: t("feedback:table.createdate"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <>{dayjs(text).format("DD.MM.YYYY @ HH:mm:ss")}</>,
      sorter: (a, b) => {
        if (dayjs(a.createdAt) < dayjs(b.createdAt)) {
          return -1;
        } else if (dayjs(a.createdAt) > dayjs(b.createdAt)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: t("feedback:table.closedate"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <>{dayjs(text).format("DD.MM.YYYY @ HH:mm:ss")}</>,
      responsive: ["md"],
      sorter: (a, b) => {
        if (dayjs(a.updatedAt) < dayjs(b.updatedAt)) {
          return -1;
        } else if (dayjs(a.updatedAt) > dayjs(b.updatedAt)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: t("feedback:table.status"),
      dataIndex: "state",
      key: "state",
      render: (state) => (
        <>
          {state
            ? t(
                "feedback:overview.issue." +
                  state.toLowerCase().replace(" ", "")
              )
            : t("feedback:overview.issue.open")}
        </>
      ),
    },
    {
      title: t("feedback:table.user"),
      dataIndex: "description",
      key: "user",
      responsive: ["lg"],
      render: (user) => (
        <span style={{ margin: "5px !important" }}>
          <ReactMarkdown
            components={renderers}
            children={
              user.split("||||").length > 1
                ? user.split("||||")[user.split("||||").length - 1]
                : t("feedback:overview.nouser")
            }
          />
        </span>
      ),
    },
    {
      title: t("feedback:table.tags"),
      key: "labels",
      dataIndex: "labels",
      responsive: ["md"],
      render: (_, { labels }) => (
        <>
          {labels.map((tag) => {
            const color = tag.length > 6 ? "geekblue" : "red";
            return (
              <Tag color={color} key={tag}>
                {t("feedback:overview.tag." + tag.toLowerCase()).toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  return (
    <AdminLayout>
      <AdminToolbar
        title={t("feedback:archive.title")}
        description={t("feedback:archive.description")}
      />
      {state.stillLoading && <Loading message={""} />}
      {!state.stillLoading && (
        <div className="od-page-main">
          <Table
            size="middle"
            rowKey="id"
            locale={locale}
            columns={columns}
            style={{ width: "100%" }}
            expandable={{
              columnWidth: 1,
              expandedRowRender: (record) => (
                <ReactMarkdown
                  children={
                    record.description.split("||||").length > 1
                      ? record.description.split("||||")[0]
                      : record.description
                  }
                />
              ),
              rowExpandable: (record) =>
                record.description !== "Not Expandable",
            }}
            dataSource={state.dataList}
          />
        </div>
      )}
    </AdminLayout>
  );
});
