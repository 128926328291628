import * as React from "react";

import styled from "styled-components";

import { Icon, getIconPacks } from "@opendash/icons";

import { useTranslation } from "@opendash/i18n";
import { Input, Modal } from "antd";

const Searchbar = styled.div`
  width: 100%;
  padding: 50px 20px 20px 20px;
`;

const InputBar = styled.div`
  width: 100%;
  padding: 0px 20px 20px 20px;
`;

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  height: 50vh;
  overflow-y: auto;

  text-align: center;

  padding-bottom: 20px;
`;

const Card = styled.div`
  width: 100px;
  margin: 5px;
  background: white;
  border-radius: 2px;

  border: 1px solid transparent;

  cursor: pointer;
  user-select: none;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.1);
  }

  &.active {
    border: 1px solid #1890ff;
    background: #1890ff;
    color: white;
  }
`;

const CardIcon = styled.div`
  width: 100px;

  padding: 20px 0;

  font-size: 32px;
  text-align: center;
`;

const CardLabel = styled.div`
  width: 100%;

  text-align: center;
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
}
export const IconPicker = React.memo<Props>(function IconPicker({
  value,
  onChange,
  ...inputProps
}) {
  const t = useTranslation();

  const [nextValue, setNextValue] = React.useState<string | undefined>(
    undefined
  );
  const [visible, setVisible] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");

  React.useEffect(() => {
    if (visible) {
      setNextValue(value);
    }
  }, [value, visible]);

  const allIcons = React.useMemo(() => {
    const packs = getIconPacks();

    const setLength: Record<string, number> = {};

    for (const pack of packs) {
      if (!setLength[pack.set]) {
        setLength[pack.set] = 1;
      } else {
        setLength[pack.set] += 1;
      }
    }

    return packs.flatMap((pack) => {
      const isDefaultTheme = pack.defaultTheme || setLength[pack.set] === 1;

      return Object.keys(pack.icons).map((name) => {
        return {
          name,
          key: isDefaultTheme
            ? [pack.set, name].join(":")
            : [pack.set, pack.theme, name].join(":"),
        };
      });
    });
  }, []);

  const icons = React.useMemo(() => {
    if (!searchString) {
      return allIcons;
    }

    const searchKeywords = searchString.toLowerCase().split(" ");

    return allIcons.filter((icon) => {
      for (const keyword of searchKeywords) {
        if (icon.key.includes(keyword)) {
          return true;
        }
      }

      return false;
    });
  }, [searchString]);

  return (
    <>
      <Input
        {...inputProps}
        readOnly
        value={value}
        prefix={value ? <Icon icon={value} /> : undefined}
        onFocus={(e) => {
          e.target.blur();

          setVisible(true);
        }}
      />
      <Modal
        open={visible}
        // footer={false}
        styles={{ body: { padding: 0 } }}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          onChange(nextValue);
          setVisible(false);
        }}
      >
        <Searchbar>
          <Input.Search
            placeholder={t("opendash:ui.search_enter_placeholder")}
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e.target.value === "") {
                setSearchString("");
              }
            }}
            onSearch={setSearchString}
          />
        </Searchbar>

        <InputBar>
          <Input
            allowClear
            value={nextValue}
            prefix={nextValue ? <Icon icon={nextValue} /> : undefined}
            onChange={(e) => {
              setNextValue(e.target.value);
            }}
          />
        </InputBar>

        <List>
          {icons.length === 0 && <p>{t("opendash:ui.search_empty")}</p>}
          {icons.map((icon) => {
            return (
              <Card
                key={icon.key}
                className={icon.key === nextValue ? "active" : undefined}
                onClick={() => {
                  if (icon.key === nextValue) {
                    setNextValue(undefined);
                  } else {
                    setNextValue(icon.key);
                  }
                }}
              >
                <CardIcon>
                  <Icon icon={icon.key} />
                </CardIcon>
                <CardLabel>{icon.name}</CardLabel>
              </Card>
            );
          })}
        </List>
      </Modal>
    </>
  );
});
