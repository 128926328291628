import { $framework } from "@opendash/core";
import { useTranslation } from "@opendash/i18n";
import { createComponent } from "@opendash/state";
import { Button, Checkbox, Form, Input, Select, Typography } from "antd";

import React from "react";
import { $parse } from "../..";
import { AuthLayout } from "./AuthLayout";

export const AuthSignupRoute = createComponent(function AuthSignupRoute() {
  const t = useTranslation();

  if ($parse.auth.loading) return null;

  const tenant = $parse.auth.currentTenant;

  return (
    <AuthLayout>
      <Form
        layout="vertical"
        initialValues={$parse.auth.signupDefaults}
        requiredMark={false}
        onFinish={(data) => {
          $parse.auth.signup(data);
        }}
      >
        <Typography.Title
          level={3}
          children={t("parse-admin:auth.signup_title")}
        />

        <Typography.Paragraph
          children={t("parse-admin:auth.signup_description")}
        />

        {tenant && (
          <Typography.Paragraph>
            <span
              dangerouslySetInnerHTML={{
                __html: t("parse-admin:auth.signup_description_tenant", {
                  label: tenant.label,
                  imprint: tenant.imprintUrl,
                  dataprotection: tenant.dataProtectionUrl,
                }),
              }}
            />
          </Typography.Paragraph>
        )}
        <Form.Item
          name="tenant"
          label={t("parse-admin:auth.field_tenant_label")}
          required
          tooltip={t("parse-admin:auth.field_tenant_tooltip")}
          rules={[
            {
              required: true,
              message: t("parse-admin:auth.field_tenant_validation_required"),
            },
          ]}
          style={{
            display: $parse.auth.signupDefaults.tenant ? "none" : "block",
          }}
        >
          <Select
            placeholder={t("parse-admin:auth.field_tenant_placeholder")}
            options={$parse.auth.getTenantOptions()}
          />
        </Form.Item>

        <Form.Item
          name="name"
          label={t("parse-admin:auth.field_name_label")}
          required
          tooltip={t("parse-admin:auth.field_name_tooltip")}
          rules={[
            {
              required: true,
              message: t("parse-admin:auth.field_name_validation_required"),
            },
          ]}
        >
          <Input placeholder={t("parse-admin:auth.field_name_placeholder")} />
        </Form.Item>

        {($parse.server.config.AUTH_ALLOW_USERNAME || null) && (
          <Form.Item
            name="username"
            label={t("parse-admin:auth.field_username_label")}
            required
            tooltip={t("parse-admin:auth.field_username_tooltip")}
            rules={[
              {
                required: true,
                message: t(
                  "parse-admin:auth.field_username_validation_required"
                ),
              },
            ]}
          >
            <Input
              placeholder={t("parse-admin:auth.field_username_placeholder")}
            />
          </Form.Item>
        )}

        <Form.Item
          name="email"
          label={t("parse-admin:auth.field_email_label")}
          required
          tooltip={
            $parse.server.config.AUTH_VERIFY_EMAIL
              ? t("parse-admin:auth.field_email_tooltip_verification")
              : t("parse-admin:auth.field_email_tooltip")
          }
          rules={[
            {
              required: true,
              message: t("parse-admin:auth.field_email_validation_required"),
            },
          ]}
        >
          <Input placeholder={t("parse-admin:auth.field_email_placeholder")} />
        </Form.Item>

        <Form.Item
          name="password"
          label={t("parse-admin:auth.field_password_label")}
          required
          tooltip={t("parse-admin:auth.field_password_tooltip")}
          rules={[
            {
              required: true,
              message: t("parse-admin:auth.field_password_validation_required"),
            },
            {
              type: "string",
              min: $parse.server.config.AUTH_PASSWORD_MIN_LENGTH,
              message: t("parse-admin:auth.field_password_validation_length", {
                length: $parse.server.config.AUTH_PASSWORD_MIN_LENGTH,
              }),
            },
          ]}
        >
          <Input.Password
            placeholder={t("parse-admin:auth.field_password_placeholder")}
          />
        </Form.Item>

        {($parse.server.config.APP_TOS_URL || null) && (
          <Form.Item
            name="tosAccepted"
            valuePropName="checked"
            required
            rules={[
              {
                required: true,
                message: t("parse-admin:auth.field_tos_validation_required"),
              },
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(),
                message: t("parse-admin:auth.field_tos_validation_true"),
              },
            ]}
          >
            <Checkbox
              children={
                <>
                  {t("parse-admin:auth.field_tos_label")}{" "}
                  <Button
                    type="link"
                    onClick={() => {
                      window
                        .open($parse.server.config.APP_TOS_URL, "_blank")
                        .focus();
                    }}
                  >
                    {"(" + t("opendash:ui.tos") + ")"}
                  </Button>
                </>
              }
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            children={t("opendash:ui.submit")}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="link"
            style={{ width: "100%" }}
            children={t("parse-admin:auth.action_goto_login_from_signup")}
            onClick={() => {
              $framework.router.navigate("/auth/login", {
                keepQueryParams: true,
              });
            }}
          />
          <Button
            type="link"
            style={{ width: "100%" }}
            children={t(
              "parse-admin:auth.action_goto_request_email_verification"
            )}
            onClick={() => {
              $framework.router.navigate("/auth/account/verify-email-request");
            }}
          />
        </Form.Item>
      </Form>
    </AuthLayout>
  );
});
