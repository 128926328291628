import { useTranslation } from "@opendash/core";
import { createComponent } from "@opendash/state";
import { Button, Form, Input, Typography } from "antd";
import React from "react";
import { $parse } from "../..";
import { AuthLayout } from "./AuthLayout";

export const AuthLoginPasswordlessRoute = createComponent(
  function AuthLoginPasswordlessRoute() {
    const t = useTranslation();

    if ($parse.auth.loading) return null;

    return (
      <AuthLayout width={400}>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(data) => {
            $parse.auth.loginPasswordless(data);
          }}
        >
          <Typography.Title
            level={3}
            children={t("parse-admin:auth.login_title")}
          />

          <Typography.Paragraph
            children={t("parse-admin:auth.login_passwordless_description")}
          />

          <Form.Item
            name="email"
            label={t("parse-admin:auth.field_email_label")}
            required
            rules={[
              {
                required: true,
                message: t("parse-admin:auth.field_email_validation_required"),
              },
            ]}
          >
            <Input
              placeholder={t("parse-admin:auth.field_email_placeholder")}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              children={t("opendash:ui.submit")}
            />
          </Form.Item>
        </Form>
      </AuthLayout>
    );
  }
);
