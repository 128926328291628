import { produce, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { DataItemInterface } from "@opendash/plugin-timeseries";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Input,
  Switch,
  Typography,
} from "antd";
import React, { useState } from "react";

type Overwrites = {
  name?: string;
  meta: {
    active?: boolean;
    visible?: boolean;
    onChange?: boolean;
  };
};
type MassEditModalProps = {
  items: DataItemInterface[];
  onClose: () => void;
  onBeforeSave?: (
    items: DataItemInterface[],
    overwrites: Overwrites
  ) => boolean;
  onAfterSave: (items: DataItemInterface[], overwrites: Overwrites) => void;
  save: (
    idsource: string | null,
    item: DataItemInterface | null,
    deleteItem?: boolean | null,
    hideNotifcation?: boolean | null
  ) => Promise<any>;
};

export default function MassEditModal({
  items,
  onAfterSave,
  onBeforeSave,
  onClose,
  save,
}: MassEditModalProps) {
  const [statusInfo, setStatusInfo] = useState<string | null>(null);
  const [overwrites, setOverwrites] = useState<Overwrites>({
    meta: {},
  });
  const t = useTranslation();

  const handleSave = async (deleteItem: boolean) => {
    if (onBeforeSave && !onBeforeSave(items, overwrites)) return;
    for (const item of items) {
      const update = produce(item, (draft) => {
        if (overwrites.name && overwrites.name.length > 0) {
          draft.name = overwrites.name;
        }
        draft.meta = {
          ...{ source_source: item.source, id_source: item.id },
          ...item.meta,
          ...overwrites.meta,
        };
        return draft;
      });

      const res = await save(item.id, update, deleteItem, true);

      setStatusInfo(
        produce((draft) => {
          const value =
            (draft ? draft + "\n" : "") +
            (res.status === 200
              ? t(
                  "openware:admin.sensors.sensorconfig.saveSuccessDescription",
                  {
                    name: item.name,
                  }
                )
              : t("openware:admin.sensors.sensorconfig.saveErrorDescription", {
                  name: item.name,
                  error: res.data,
                }));
          return value;
        })
      );
    }
  };
  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  } as React.CSSProperties;
  return (
    <Drawer
      placement="right"
      width={"40%"}
      open={items.length > 0}
      onClose={onClose}
      extra={
        <Button
          icon={<Icon icon="fa:reset"></Icon>}
          onClick={() => {
            handleSave(true);
          }}
        >
          {t("openware:admin.sensors.massEdit.resetAll")}
        </Button>
      }
    >
      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Collapse>
            <Collapse.Panel
              key="1"
              header={t("openware:admin.sensors.massEdit.title", {
                count: items.length,
              })}
            >
              {items.map((item) => {
                return (
                  <div>
                    <Typography.Text>{item.name}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary">
                      {item.valueTypes
                        .map((type) => type.name)
                        .join(", ")
                        .substring(0, 100)}
                    </Typography.Text>
                  </div>
                );
              })}
            </Collapse.Panel>
          </Collapse>

          <Divider>
            {" "}
            <Typography.Text type="secondary">
              {t("openware:admin.sensors.massEdit.action")}
            </Typography.Text>
          </Divider>

          <div style={rowStyle}>
            <Checkbox
              checked={"name" in overwrites}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setOverwrites(
                    produce((draft) => {
                      draft.name = "";
                    })
                  );
                } else {
                  setOverwrites(
                    produce((draft) => {
                      delete draft.name;
                    })
                  );
                }
              }}
            />
            <Input
              addonBefore={t("openware:admin.sensors.sensorconfig.nameLabel")}
              value={overwrites.name}
              placeholder="..."
              onChange={(e) => {
                const value = e.target.value;
                setOverwrites(
                  produce((draft) => {
                    draft.name = value;
                  })
                );
              }}
              disabled={!("name" in overwrites)}
            ></Input>
          </div>
          <div style={rowStyle}>
            <Checkbox
              checked={"active" in overwrites.meta}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setOverwrites(
                    produce((draft) => {
                      draft.meta.active = true;
                    })
                  );
                } else {
                  setOverwrites(
                    produce((draft) => {
                      delete draft.meta.active;
                    })
                  );
                }
              }}
            />
            <Switch
              disabled={!("active" in overwrites.meta)}
              checked={overwrites.meta.active}
              onChange={(e) => {
                setOverwrites(
                  produce((draft) => {
                    draft.meta.active = e;
                  })
                );
              }}
            ></Switch>{" "}
            {t("openware:admin.sensors.sensorconfig.activeLabel")}
          </div>
          <div style={rowStyle}>
            <Checkbox
              checked={"onChange" in overwrites.meta}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setOverwrites(
                    produce((draft) => {
                      draft.meta.onChange = false;
                    })
                  );
                } else {
                  setOverwrites(
                    produce((draft) => {
                      delete draft.meta.onChange;
                    })
                  );
                }
              }}
            />
            <Switch
              disabled={!("onChange" in overwrites.meta)}
              checked={overwrites.meta.onChange}
              onChange={(e) => {
                setOverwrites(
                  produce((draft) => {
                    draft.meta.onChange = e;
                  })
                );
              }}
            ></Switch>{" "}
            {t("openware:admin.sensors.sensorconfig.onChangeLabel")}
          </div>
          <div style={rowStyle}>
            <Checkbox
              checked={"visible" in overwrites.meta}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setOverwrites(
                    produce((draft) => {
                      draft.meta.visible = true;
                    })
                  );
                } else {
                  setOverwrites(
                    produce((draft) => {
                      delete draft.meta.visible;
                    })
                  );
                }
              }}
            />
            <Switch
              disabled={!("visible" in overwrites.meta)}
              checked={overwrites.meta.visible}
              onChange={(e) => {
                setOverwrites(
                  produce((draft) => {
                    draft.meta.visible = e;
                  })
                );
              }}
            ></Switch>{" "}
            {t("openware:admin.sensors.sensorconfig.visibleLabel")}
          </div>
          {statusInfo && (
            <pre
              style={{
                border: "1px solid grey",
                backgroundColor: "rgba(0,0,0,0.2)",
                padding: "8px",
              }}
            >
              {statusInfo}
            </pre>
          )}
          <Button
            type="primary"
            onClick={() => {
              handleSave(false);
            }}
          >
            {t("opendash:ui.save")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
