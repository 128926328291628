import {
  createInternalComponent,
  Suspense,
  useServiceStore,
} from "@opendash/core";
import { Spin } from "antd";
import * as React from "react";
import {
  useWidgetBaseContextSetup,
  WidgetComponentLayout,
  WidgetComponentRender,
  WidgetDeletionModal,
  WidgetErrorBoundary,
  WidgetReactContext,
  WidgetRenamingModal,
  WidgetSettingsModal,
  WidgetSharingModal,
} from "..";

interface Props {
  id: string;
  fullscreen?: boolean;
  borderless?: boolean;
}

export const WidgetComponent = createInternalComponent<Props>(
  function WidgetComponent({ id, fullscreen, borderless }) {
    const context = useWidgetBaseContextSetup(id, fullscreen);

    const key = useServiceStore(context, (state) => state.key);
    const loading = useServiceStore(context, (state) => state.loading);
    const rename = useServiceStore(context, (state) => state.rename);
    const del = useServiceStore(context, (state) => state.delete);
    const settings = useServiceStore(context, (state) => state.settings);

    if (!context) {
      return null;
    }

    return (
      <WidgetReactContext.Provider value={context}>
        <WidgetComponentLayout
          key={key}
          layout={
            borderless ? "borderless" : fullscreen ? "fullscreen" : "default"
          }
          context={context}
        >
          <WidgetErrorBoundary context={context}>
            <Suspense>
              <WidgetComponentRender baseContext={context} />
              {loading && (
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    top: "0px",
                    width: "100%",
                    height: "100%",
                    verticalAlign: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    zIndex: 99,
                  }}
                >
                  <Spin spinning={loading} style={{ flexGrow: 1 }}></Spin>
                </div>
              )}
            </Suspense>
          </WidgetErrorBoundary>
        </WidgetComponentLayout>

        <WidgetRenamingModal
          id={id}
          open={rename}
          close={() => {
            context.store.update((state) => {
              state.rename = false;
            });
          }}
        />

        <WidgetSharingModal context={context} />

        <WidgetDeletionModal
          id={id}
          open={del}
          close={() => {
            context.store.update((state) => {
              state.delete = false;
            });
          }}
        />

        <WidgetSettingsModal id={id} open={settings} />
      </WidgetReactContext.Provider>
    );
  }
);
