import {
  HeaderMenuItem,
  createInternalComponent,
  useServiceStore,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import {
  DataFetchingOptionsInterface,
  useDataService,
} from "@opendash/plugin-timeseries";
import { FloatButton } from "antd";
import * as React from "react";
import {
  AlarmModal,
  DashboardAssistantModal,
  DashboardCopyModal,
  DashboardCreationModal,
  DashboardDeletionModal,
  DashboardRenamingModal,
  DashboardTimerangeModal,
  DataSidebar,
  ExplorerDialog,
  WidgetCreationModal,
  useAlarmModal,
  useDashboardCurrent,
  useMonitoringService,
} from "..";

type Props = React.PropsWithChildren<{}>;

export const MonitoringGlobals = createInternalComponent<Props>(
  function MonitoringGlobals({ children }) {
    const t = useTranslation();
    const MonitoringService = useMonitoringService();

    const [dashboard, setDashboard] = useDashboardCurrent();

    const [datasidebar, setDatasidebar] = useUrlParam("data_sidebar", false);
    const [explorer, setExplorer] = useUrlParam("explorer", false);
    const [timerange, setTimerange] = useUrlParam("data_timerange", false);
    const [create, setCreate] = useUrlParam("db_create", false);
    const [addWidgets, setAddWidgets] = useUrlParam("db_add_widgets", false);
    const [dbAssist, setDashboardAssist] = useUrlParam("db_assist", false);
    const [rename, setRename] = useUrlParam<string>("db_rename", null);
    const [remove, setRemove] = useUrlParam<string>("db_delete", null);
    const [[alarmItem, alarmDimension], setAddAlarm] = useAlarmModal();
    const [fabOpen, setFabOpen] = React.useState(false);
    const DataService = useDataService();

    const overwriteOptions = useServiceStore(DataService, (draft) => {
      return draft.overwriteFetchingOptions;
    });

    const [timeOverwrite, setTimeOverwrite] =
      useUrlParam<DataFetchingOptionsInterface>(
        "timeOverwrite",
        overwriteOptions,
        "json"
      );

    React.useEffect(() => {
      if (timeOverwrite && !overwriteOptions) {
        DataService.store.update((draft) => {
          draft.overwriteFetchingOptions = timeOverwrite;
        });
      }
    }, [timeOverwrite]);

    const timeIsOverWritten = !!overwriteOptions;

    return (
      <React.Fragment>
        {children}

        <HeaderMenuItem
          icon="fa:table"
          tooltip={t("opendash:monitoring.data_sidebar.action")}
          onClick={() => {
            setDatasidebar(true);
          }}
        />

        {window.innerWidth > 765 && (
          <HeaderMenuItem
            icon="fa:clock"
            active={timeIsOverWritten}
            tooltip={t("opendash:dashboards.timerange_modal_title")}
            onClick={() => {
              setTimerange(!timerange);
            }}
          />
        )}
        {window.innerWidth <= 765 && (
          <FloatButton.Group
            //open={fabOpen}
            // onClick={() => {
            //   setFabOpen(!fabOpen);
            // }}
            trigger="click"
            style={{ right: 24 }}
            icon={<Icon icon="fa:ellipsis-h" />}
          >
            <FloatButton
              icon={<Icon icon="fa:clock"></Icon>}
              onClick={() => setTimerange(!timerange)}
            />
          </FloatButton.Group>
        )}

        {/* Modals */}
        <DataSidebar open={datasidebar} close={() => setDatasidebar(false)} />

        <WidgetCreationModal
          open={addWidgets}
          close={() => setAddWidgets(false)}
          onSave={(presets) => {
            MonitoringService.addPresetsToDashboard(dashboard, presets);
          }}
        />

        <DashboardAssistantModal
          open={dbAssist}
          close={() => setDashboardAssist(false)}
          onSave={(presets) => {
            MonitoringService.addPresetsToDashboard(dashboard, presets);
          }}
        ></DashboardAssistantModal>

        <DashboardCreationModal
          open={create}
          close={() => setCreate(false)}
          onSave={(dashboardId) => {
            // TODO:
            // @ts-ignore
            setDashboard({ id: dashboardId });
          }}
        />

        <DashboardRenamingModal
          id={rename}
          open={!!rename}
          close={() => setRename(null)}
        />

        <DashboardDeletionModal
          id={remove}
          open={!!remove}
          close={() => setRemove(null)}
        />

        <DashboardCopyModal />
        <ExplorerDialog open={explorer} close={() => setExplorer(false)} />

        <DashboardTimerangeModal
          open={!!timerange}
          close={() => setTimerange(null)}
        />

        <AlarmModal
          key={JSON.stringify(alarmItem)}
          item={alarmItem}
          dimension={alarmDimension}
          open={!!alarmItem}
          close={() => setAddAlarm(undefined)}
        />
      </React.Fragment>
    );
  }
);
