import Parse from "parse";
import React from "react";

import { useFeedback } from "@opendash/core";
import { useTranslation } from "@opendash/i18n";
import { Modal, Transfer } from "antd";
import { useParseQuery } from "parse-hooks";
import { $parse } from "../../state";
import { getObjectTitle } from "../helper";

interface Props {
  object: Parse.Object;
  field: string;
  onClose: () => void;
}

export const RelationDialog = React.memo<Props>(function RelationDialog({
  object,
  field,
  onClose,
}) {
  const t = useTranslation();
  const { message } = useFeedback();

  const objectClassName = object.className;
  const objectClassSchema = $parse.ui.getClassSchema(objectClassName);
  const objectClassConfig = $parse.ui.getClassConfig(objectClassName);

  const targetClassName = objectClassSchema.fields[field].targetClass;
  const targetClassSchema = $parse.ui.getClassSchema(targetClassName);
  const targetClassConfig = $parse.ui.getClassConfig(targetClassName);

  const relationQuery = React.useMemo(() => {
    return object.relation(field).query();
  }, [object.id, field]);

  const targetClassQuery = React.useMemo(() => {
    return new Parse.Query(targetClassName);
  }, [targetClassName]);

  const relation = useParseQuery(relationQuery);
  const target = useParseQuery(targetClassQuery);

  const [selection, setSelection] = React.useState([]);

  const dataSource = React.useMemo(() => {
    return target.result.map((obj) => ({
      key: obj.id,
      title: getObjectTitle(obj, targetClassConfig),
    }));
  }, [target.result]);

  React.useEffect(() => {
    setSelection(relation.result.map((obj) => obj.id));
  }, [relation.result]);

  const onSave = async () => {
    try {
      const rel = object.relation(field);

      for (const obj of relation.result) {
        if (!selection.includes(obj.id)) {
          rel.remove(obj);
        }
      }

      for (const obj of target.result) {
        if (selection.includes(obj.id)) {
          rel.add(obj);
        }
      }

      await object.save();

      message.success(
        t("parse-admin:admin.relations.add_success", {
          count: selection.length,
        })
      );

      onClose();
    } catch (error) {
      console.error(error);
      message.error(t("parse-admin:admin.relations.add_error"));
    }
  };

  return (
    <Modal
      open={true}
      title={t("parse-admin:admin.relations.modal_title")}
      okText={t("opendash:ui.save")}
      onOk={onSave}
      onCancel={onClose}
      width={800}
    >
      <Transfer
        dataSource={dataSource}
        targetKeys={selection}
        onChange={(newSelection) => {
          setSelection(newSelection);
        }}
        render={(obj) => {
          return obj.title;
        }}
        rowKey={(obj) => obj.key}
        pagination
        listStyle={{
          width: "100%",
          height: 600,
        }}
      />
    </Modal>
  );
});
