import styled from "styled-components";

export const WidgetContainer = styled.div<{ color?: string; border?: string }>`
  display: flex;
  align-self: stretch;
  width: 100%;
  height: 100%;
  position: relative;
  background: ${(props) => (props.color ? props.color : "white")};
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-bottom: ${(props) =>
    props.border ? props.border : "1px solid #d9d9d9"};
`;

export const WidgetActions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 1px 1px;
  border-radius: 2px;
  z-index: 1000;
`;

export const WidgetContent = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
