import { useTranslation } from "@opendash/core";
import { Input } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";

const { TextArea } = Input;

export default observer((props: { field; updateField }) => {
  // return <pre>{JSON.stringify(field, null, "  ")}</pre>;
  const t = useTranslation();
  return (
    <div className="od_admin_bde_input">
      {t("bde:fields.label_info")}
      <TextArea
        style={{ display: "block" }}
        rows={4}
        value={props.field.hint}
        onChange={(e) => {
          props.updateField((state) => {
            state.hint = e.target.value;
          });
        }}
      />
    </div>
  );
});
