import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  FontSizeOutlined,
  PlusCircleOutlined,
  UpOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { produce, useFeedback, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Description } from "@opendash/ui";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Empty,
  Input,
  List,
  Menu,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Steps,
  Switch,
  Tabs,
  Tag,
  Typography,
} from "antd";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
  AutoComplete,
  CheckList,
  CreateNewFormModalProps,
  DurationPicker,
  ImageChooser,
  Label,
  Spinner,
  StartStop,
  TextItem,
  TimeSelect,
} from "..";
import { configProvider } from "../../..";
import { FormManager } from "../../../FormManager";
import { Form, Page, Unit } from "../../../parse";

const { Option } = Select;
const { Title } = Typography;
const userQuery = new Parse.Query(Parse.User).ascending("name").limit(1000);
const roleQuery = new Parse.Query(Parse.Role).ascending("name").limit(1000);

export default memo<CreateNewFormModalProps>(
  ({
    hideRoles = false,
    hideUsers = false,
    hideSelf = false,
    hideRead = false,
    hideWrite = false,
    readLabel = "parse-admin:admin.edit_permission_read",
    writeLabel = "parse-admin:admin.edit_permission_write",
    onClose,
  }) => {
    const [state, setState] = useState({
      showModal: false,
      step: 0,
      addPageExtra: false,
      loadingSources: true,
      sources: [],
      units: [],
      pages: [],
      tempItem: {
        name: "",
        userinfo: false,
        pages: [],
        source: [],
        unit: [],
        rid: "form" + new Date().getTime(),
        json: {} as any,
      },
      selectedPage: "",
      tempPage: {
        pageID: "",
        data: {
          title: "",
          description: "",
          fields: [],
        },
      },
    });

    const { message } = useFeedback();
    const [setStyle, setSetStyle] = useState(-1);
    const [sourceMode, setSourceMode] = useState(false);
    const [selection, setSelection] = useState([]);
    const [selectionUnit, setSelectionUnit] = useState([]);
    const [fieldKey, setFieldKey] = useState(0);
    const [searchString, setSearchString] = useState("");
    const [userPermissonChanges, setUserPermissionChanges] = useState(0);
    const [rolePermissonChanges, setRolePermissionChanges] = useState(0);
    const [currentTab, setCurrentTab] = useState("roles");
    const users = useParseQuery(userQuery);
    const roles = useParseQuery(roleQuery);

    const [acl, setAcl] = useState(new Parse.ACL());
    const formManager = FormManager.getInstance();
    const t = useTranslation();
    useEffect(() => {
      retrieveFormsAndUpdateState();
    }, []);

    const onClick = (e) => {
      setState(
        produce((draft) => {
          draft.showModal = true;
        })
      );
    };

    function getUserName(user: Parse.User) {
      return [user.get("name"), user.getUsername(), user.getEmail()]
        .filter(Boolean)
        .join(", ");
    }

    function getRoleName(role: Parse.Role) {
      return role.get("label") || role.getName();
    }
    const usersFiltered = useMemo(() => {
      let filteredBySearch = users.result.filter((user) =>
        getUserName(user).toLowerCase().includes(searchString.toLowerCase())
      );

      if (hideSelf) {
        filteredBySearch = filteredBySearch.filter(
          (user) => user.id !== Parse.User.current()?.id
        );
      }

      const hasWritePermissions = filteredBySearch.filter((user) =>
        acl.getWriteAccess(user)
      );

      const hasReadPermissions = filteredBySearch.filter(
        (user) => acl.getReadAccess(user) && !acl.getWriteAccess(user)
      );

      const hasNoPermissions = filteredBySearch.filter(
        (user) => !acl.getReadAccess(user)
      );

      return hasWritePermissions.concat(hasReadPermissions, hasNoPermissions);
    }, [users.result, searchString, acl, userPermissonChanges, hideSelf]);

    const rolesFiltered = React.useMemo(() => {
      const filteredBySearch = roles.result.filter((role) =>
        getRoleName(role).toLowerCase().includes(searchString.toLowerCase())
      );

      const hasWritePermissions = filteredBySearch.filter((role) =>
        acl.getRoleWriteAccess(role)
      );

      const hasReadPermissions = filteredBySearch.filter(
        (role) => acl.getRoleReadAccess(role) && !acl.getRoleWriteAccess(role)
      );

      const hasNoPermissions = filteredBySearch.filter(
        (role) => !acl.getRoleReadAccess(role)
      );

      return hasWritePermissions.concat(hasReadPermissions, hasNoPermissions);
    }, [roles.result, searchString, acl, rolePermissonChanges]);

    React.useEffect(() => {
      if (
        !!searchString &&
        currentTab === "roles" &&
        rolesFiltered.length === 0 &&
        usersFiltered.length > 0
      ) {
        setCurrentTab("users");
      }

      if (
        !!searchString &&
        currentTab === "users" &&
        usersFiltered.length === 0 &&
        rolesFiltered.length > 0
      ) {
        setCurrentTab("roles");
      }
    }, [usersFiltered, rolesFiltered, currentTab, !searchString]);

    const retrieveFormsAndUpdateState = async () => {
      try {
        const query = new Parse.Query(Page);
        const results = await query.find();

        setState(
          produce((draft) => {
            draft.pages = results.map((eintrag) => {
              return {
                objectid: eintrag.id,
                name: eintrag.get("data").title || "",
                pid: eintrag.get("pageID"),
                data: eintrag.get("data"),
              };
            });
          })
        );
      } catch (error) {
        console.error(error);
      }
    };

    const handleCancel = (e) => {
      setSelection([]);
      setSelectionUnit([]);
      setState(
        produce((draft) => {
          draft.showModal = false;
          draft.step = 0;
          draft.addPageExtra = false;
          draft.loadingSources = true;
          draft.tempItem = {
            name: "",
            userinfo: false,
            pages: [],
            unit: [],
            source: [],
            rid: "form" + new Date().getTime(),
            json: {} as any,
          };
          draft.selectedPage = "";
          draft.tempPage = {
            pageID: "",
            data: {
              title: "",
              description: "",
              fields: [],
            },
          };
        })
      );
    };
    const handleOk = (e) => {
      if (state.step < 3) {
        if (state.step == 0) {
          if (!(state.tempItem.name && state.tempItem.name !== "")) {
            message.error(t("bde:label_pleaseFillAllFields"));
            return;
          }
        } else if (state.step == 1) {
          if (state.tempItem.pages.length < 1) {
            message.error(t("bde:app.createitem.nopage"));
            return;
          }
          loadSources();
        } else if (state.step == 2) {
          acl.setReadAccess(Parse.User.current(), true);
          acl.setWriteAccess(Parse.User.current(), true);
        }

        setState(
          produce((draft) => {
            draft.step = state.step + 1;
          })
        );
      } else {
        saveTempForm();
        handleCancel(e);
      }
    };

    const onChangeName = (e) => {
      setState(
        produce((draft) => {
          draft.tempItem.name = e.target.value;
        })
      );
    };
    const onChangeUserInfo = (e) => {
      setState(
        produce((draft) => {
          draft.tempItem.userinfo = e.target.checked;
        })
      );
    };

    const onChangeID = (e) => {
      setSelection(e);
      setState(
        produce((draft) => {
          draft.tempItem.source = e.map(
            (index) => state.sources[index].sourceTag
          );
        })
      );
    };

    const onChangeIDUnit = (e) => {
      setSelectionUnit(e);
      setState(
        produce((draft) => {
          draft.tempItem.unit = e.map((index) => state.units[index]);
        })
      );
    };

    const onChangePageTitle = (e) => {
      setState(
        produce((draft) => {
          draft.tempPage.data.title = e.target.value;
        })
      );
    };

    const onChangePageDesc = (e) => {
      setState(
        produce((draft) => {
          draft.tempPage.data.description = e.target.value;
        })
      );
    };

    const togglePageID = (pageID) => {
      if (state.tempItem.pages.includes(pageID)) {
        setState(
          produce((draft) => {
            draft.tempItem.pages = state.tempItem.pages.filter(
              (e) => e !== pageID
            );
          })
        );
      } else {
        setState(
          produce((draft) => {
            draft.tempItem.pages.push(pageID);
          })
        );
      }
    };

    const toggleCreatePageExtra = () => {
      setState(
        produce((draft) => {
          (draft.tempPage.pageID = "page" + new Date().getTime()),
            (draft.addPageExtra = !state.addPageExtra);
        })
      );
    };

    const getPageName = (pageID) => {
      for (let i = 0; i < state.pages.length; i++) {
        if (state.pages[i]["objectid"] === pageID) {
          return state.pages[i].name;
        }
      }
      return null;
    };

    const updateField = (action) => {
      setState(
        produce((draft) => {
          action(draft.tempPage.data.fields[fieldKey]);
        })
      );
    };

    const getFieldInfos = (fieldKey1) => {
      let fieldKey = fieldKey1 || 0;
      fieldKey = Math.min(fieldKey, state.tempPage.data.fields.length - 1);
      if (state.tempPage.data.fields.length < 1) {
        return (
          <Empty
            description={
              <div>
                <span style={{ display: "block" }}>
                  {t("bde:fields.label_noFieldSelected")}
                </span>
                <Dropdown overlay={addFields}>
                  <Button type="dashed">
                    {t("bde:fields.label_addField")}
                  </Button>
                </Dropdown>
              </div>
            }
          />
        );
      }
      return (
        <div>
          <Row>
            <Col span={8}>
              <Title level={5}>{t("bde:app.createitem.preview")}</Title>
              <div
                style={{
                  border: "1px solid black",
                  width: "100%",
                  maxWidth: "500px",
                  minHeight: "800px",
                  padding: "10px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
              >
                {state.tempPage.data.fields.map((field, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        width: "100%",
                        border:
                          fieldKey == i
                            ? "2px solid #bae0ff"
                            : "1px solid lightgray",
                        height: "200px",
                        padding: "10px",
                        color: "darkgray",
                        cursor: "pointer",
                        backgroundColor:
                          setStyle == i
                            ? fieldKey == i
                              ? "rgba(0,0,0,0)"
                              : "#fafafa"
                            : "rgba(0,0,0,0)",
                      }}
                      onMouseEnter={() => {
                        setSetStyle(i);
                      }}
                      onMouseLeave={() => {
                        setSetStyle(-1);
                      }}
                      onClick={(e) => {
                        setFieldKey(i);
                      }}
                    >
                      <b>{i + 1 + ": " + field.title.slice(0, 20)}</b>{" "}
                      {" (" + getTypeName(field.type) + ")"}
                      <Button
                        danger
                        style={{ display: "inline-block", float: "right" }}
                        onClick={(e) => {
                          if (fieldKey == i) {
                            setFieldKey(0);
                          }

                          setState(
                            produce((draft) => {
                              draft.tempPage.data.fields.splice(i, 1);
                            })
                          );
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                      <Skeleton
                        style={{ marginTop: "20px" }}
                        title={false}
                        paragraph={{ rows: 4 }}
                      ></Skeleton>
                    </div>
                  );
                })}
                <div
                  style={{
                    marginTop: "8px",
                    marginBottom: "8px",
                    width: "100%",
                    height: "100px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Dropdown overlay={addFields}>
                    <Button key="addMenuBtn">
                      + {t("bde:fields.label_addField")}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Col>
            <Col span={16}>
              <Title level={5}>{t("bde:app.createitem.edit")}</Title>
              <div
                style={{
                  paddingTop: "1px",
                  height: "100%",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "8px",
                    width: "100%",
                    padding: "10px",
                    marginTop: 8, //+ fieldKey * 200,
                    border: "1px solid lightgray",
                  }}
                >
                  <div
                    style={{
                      padding: "20px",
                    }}
                  >
                    <b style={{ display: "inline-block" }}>
                      {t("bde:fields.label_editField") + ": "}{" "}
                      <i>{state.tempPage.data.fields[fieldKey]?.title || ""}</i>
                    </b>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <b>{t("bde:fields.label_chooseFieldname")}</b>
                    <div>
                      <Input
                        className="od_admin_bde_input"
                        style={{
                          display: "block",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                        placeholder={t("bde:fields.label_fieldname")}
                        value={state.tempPage.data.fields[fieldKey].title}
                        onChange={(e) => {
                          setState(
                            produce((draft) => {
                              draft.tempPage.data.fields[fieldKey].title =
                                e.target.value;
                            })
                          );
                        }}
                      />
                    </div>
                    <b>{t("bde:fields.label_editFieldDetails")}</b>
                    <FormItem
                      field={state.tempPage.data.fields[fieldKey]}
                      updateField={updateField}
                    />

                    <b style={{ display: "block", marginTop: "20px" }}>
                      <b>{t("bde:app.createitem.order")}</b>
                    </b>

                    <Button
                      style={{ marginLeft: "10px" }}
                      icon={<UpOutlined />}
                      onClick={() => {
                        setState(
                          produce((draft) => {
                            draft.tempPage.data.fields.splice(fieldKey, 1);
                            draft.tempPage.data.fields.splice(
                              Math.max(0, fieldKey - 1),
                              0,
                              state.tempPage.data.fields[fieldKey]
                            );
                          })
                        );

                        setFieldKey(Math.max(0, fieldKey - 1));
                      }}
                    />
                    <Button
                      style={{ marginLeft: "10px" }}
                      icon={<DownOutlined />}
                      onClick={() => {
                        setState(
                          produce((draft) => {
                            draft.tempPage.data.fields.splice(fieldKey, 1);
                            draft.tempPage.data.fields.splice(
                              Math.min(
                                draft.tempPage.data.fields.length,
                                fieldKey + 1
                              ),
                              0,
                              state.tempPage.data.fields[fieldKey]
                            );
                          })
                        );
                        setFieldKey(
                          Math.min(
                            state.tempPage.data.fields.length,
                            fieldKey + 1
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    const getTypeName = (type) => {
      const form = FormManager.getInstance().get(type);
      return t(form.label);
    };

    const saveUnit = (formToSave, unit) => {
      const toSave = new Unit({ id: unit.objectid });
      toSave.fetch().then(
        (res) => {
          const rel = res.relation("forms");

          unit.forms.forEach((form) => {
            rel.add(Form.createWithoutData(form));
          });

          res.name = unit.name;

          res.save().then(
            (res) => {
              message.success(
                t("bde:app.createitem.delsuccess") + res.get("name")
              );
            },
            (error) => {
              message.error(t("bde:app.createitem.delerror") + error.message);
            }
          );
        },
        (error) => {
          console.error(error);
          message.error(t("bde:app.createitem.delerror") + error.message);
        }
      );
    };

    const saveTempForm = async () => {
      const toSave = new Form();
      toSave.set("RID", state.tempItem.rid);
      toSave.set("storeUserInfo", state.tempItem.userinfo);
      toSave.set("name", state.tempItem.name);
      toSave.set("form", state.tempItem.json);
      toSave.set("entries", state.tempItem.pages);
      toSave.setACL(acl);
      if (sourceMode) {
        try {
          await toSave.save().then(async (response) => {
            await state.tempItem.unit.forEach(async (element) => {
              try {
                const unitToChange = new Unit({ id: element.id });
                await unitToChange.fetch();
                const rel = unitToChange.relation("forms");
                rel.add(response);

                unitToChange.save();

                message.success(
                  t("bde:app.createitem.delsuccess") + response.get("name")
                );
              } catch (error) {
                console.error(error);
                message.error(t("bde:app.createitem.delerror") + error.message);
              }
            });
          });
          onClose(true);
        } catch (error) {
          message.error(t("bde:saveEntryError") + ": " + error.message);
          onClose(false);
        }
      } else {
        try {
          await toSave.save().then(async (response) => {
            await state.tempItem.source.forEach((element) => {
              const toSaveUnit = new Unit();
              toSaveUnit.set("name", state.tempItem.name);
              toSaveUnit.set("source", element);
              const formRelation = toSaveUnit.relation("forms");
              formRelation.add(response);
              toSaveUnit.setACL(acl);
              toSaveUnit.save();
            });
            message.success(t("bde:saveEntrySuccess"));
            onClose(true);
          });
        } catch (error) {
          message.error(t("bde:saveEntryError") + ": " + error.message);
          onClose(false);
        }
      }
    };

    const saveTempPage = async () => {
      const toSave = new Page();
      toSave.set("pageID", state.tempPage.pageID);
      toSave.set("data", state.tempPage.data);
      const acl = new Parse.ACL(Parse.User.current());
      toSave.setACL(acl);
      try {
        await toSave.save();
        message.success(
          t("bde:saveEntrySuccess") + ": " + toSave.get("data").title
        );
      } catch (error) {
        message.error(t("bde:saveEntryError") + ": " + error.message);
      }

      cancelPage();
      retrieveFormsAndUpdateState();
    };

    const cancelPage = () => {
      setState(
        produce((draft) => {
          draft.addPageExtra = false;
          draft.tempPage = {
            pageID: "",
            data: {
              title: "",
              description: "",
              fields: [],
            },
          };
        })
      );
    };

    const loadSources = async () => {
      try {
        const sources = (await getSources()) || [];
        const units = (await getUnits()) || [];
        setState(
          produce((x) => {
            x.loadingSources = false;
            x.units = units;
            x.sources = sources
              .filter((firstloc) => {
                return !!firstloc.get("tag");
              })
              .map((loc) => {
                return {
                  name: loc.get("name") + " (" + loc.get("tag") + ")",
                  sourceTag: loc.get("tag"),
                };
              });
            x.tempItem.source = selection.map((index) => x.sources[index]);
          })
        );
      } catch (err) {
        message.error(t("bde:units.error") + err.message);
      }
    };

    async function getSources() {
      const query = new Parse.Query(configProvider.config.sources.table).limit(
        9999
      );
      const results = await query.find();
      return results;
    }

    async function getUnits() {
      const query = new Parse.Query(configProvider.config.units.table).limit(
        9999
      );
      const results = await query.find();
      return results;
    }

    const SourceSelect = (props) => {
      const IsLoading = props.IsLoading;
      const sources = props.Sources;
      if (IsLoading) {
        return (
          <Select style={{ display: "block", width: "100%" }} loading></Select>
        );
      }
      return (
        <Select
          mode="multiple"
          style={{ display: "block", width: "100%" }}
          placeholder={t("bde:units.label_source")}
          className="od_admin_bde_input"
          value={props.Selected}
          onChange={(e) => {
            props.OnChange(e);
          }}
        >
          {sources.map((source, i) => {
            return <Option value={i}>{source.name}</Option>;
          })}
        </Select>
      );
    };

    const UnitSelect = (props) => {
      const IsLoading = props.IsLoading;
      const units = state.units;
      if (IsLoading) {
        return (
          <Select style={{ display: "block", width: "100%" }} loading></Select>
        );
      }
      return (
        <Select
          mode="multiple"
          style={{ display: "block", width: "100%" }}
          placeholder={t("bde:units.label_source")}
          className="od_admin_bde_input"
          value={props.Selected}
          onChange={(e) => {
            props.OnChangeUnit(e);
          }}
        >
          {units.map((unit, i) => {
            return (
              <Option value={i}>{unit.name + " (" + unit.source + ")"}</Option>
            );
          })}
        </Select>
      );
    };

    const createField = (type) => {
      const key = "field" + new Date().getTime();
      const toReturn = {
        title: t("bde:fields.defaultTitle"),
        description: "",
        type: type.key,
        key: key,
        options: {},
        v_required: {
          value: false,
          err: t("bde:app.createitem.entervalue"),
        },
        od_type: type.key === "number" ? "Number" : "String",
      };

      setState(
        produce((draft) => {
          draft.tempPage.data.fields[state.tempPage.data.fields.length] =
            toReturn;
        })
      );
    };

    const addFields = (
      <Menu
        onClick={(e) => {
          createField(e);
        }}
      >
        {formManager.list().map((type, i) => {
          const form = formManager.get(type);
          return (
            <Menu.Item key={type}>
              <Icon icon={form.icon || "fa:edit"} />
              {" " + t(form.label)}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const gridStylePlus: React.CSSProperties = {
      width: "25%",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    const gridStyleCard: React.CSSProperties = {
      width: "25%",
    };

    const gridStyle: React.CSSProperties = {
      width: "25%",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "lightgray",
    };

    const gridStyleCheck: React.CSSProperties = {
      width: "25%",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#4186c6",
    };

    return (
      <div>
        <Button type="primary" onClick={onClick}>
          {t("bde:forms.newForm")}
        </Button>
        <Modal
          width={"80%"}
          title={t("bde:forms.newForm")}
          open={state.showModal}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {t("bde:cancel")}
            </Button>,
            <Button
              key="ok"
              type="primary"
              onClick={handleOk}
              disabled={state.addPageExtra}
            >
              {state.step < 3
                ? state.addPageExtra
                  ? t("bde:app.createitem.errorpage")
                  : t("bde:app.createitem.next")
                : t("bde:app.createitem.createbutton")}
            </Button>,
          ]}
        >
          <Steps
            current={state.step}
            items={[
              {
                title: t("bde:app.createitem.steps.datatitle"),
                description: t("bde:app.createitem.steps.datadesc"),
              },
              {
                title: t("bde:app.createitem.steps.sitetitle"),
                description: t("bde:app.createitem.steps.sitedesc"),
              },
              {
                title: t("bde:app.createitem.steps.assignmenttitle"),
                description: t("bde:app.createitem.steps.assignmentdesc"),
              },
              {
                title: t("bde:app.createitem.steps.opttitle"),
                description: t("bde:app.createitem.steps.optdesc"),
              },
            ]}
          />
          <div style={{ width: "100%", height: "10px" }}></div>
          {state.step == 0 && ( //Titel und Nutzerinformationen Seite 1
            <div>
              <Card
                style={{ marginBottom: "10px" }}
                title={t("bde:forms.label")}
              >
                {t("bde:forms.form_creation_info")}
              </Card>
              <Input
                prefix={
                  <FontSizeOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={t("bde:forms.label_input_formname")}
                style={{ display: "flex", width: "100%" }}
                value={state.tempItem.name}
                onChange={onChangeName}
              />
              <div style={{ width: "100%", height: "15px" }}></div>
              <Checkbox onChange={onChangeUserInfo}>
                {t("bde:app.createitem.getuserinfo")}
              </Checkbox>
            </div>
          )}
          {state.step == 1 && //Seiten
            !state.addPageExtra && (
              <div>
                <Card
                  style={{ marginBottom: "10px" }}
                  title={t("bde:forms.title")}
                >
                  {t("bde:forms.form_select")}
                  <br />
                  <br />

                  <b>{t("bde:app.createitem.addedpages")}</b>
                  <br />
                  <Space size={[0, 8]} wrap>
                    {state.tempItem.pages.map(function (page, index) {
                      return <Tag>{getPageName(page)}</Tag>;
                    })}
                  </Space>
                </Card>
                <Row gutter={16} style={{ cursor: "pointer" }}>
                  <Card
                    hoverable
                    style={gridStylePlus}
                    onClick={() => {
                      toggleCreatePageExtra();
                    }}
                  >
                    {<Icon icon="fa:plus" style={{ fontSize: "30px" }} />}
                  </Card>
                  {state.pages.map(function (page) {
                    return (
                      <Card
                        hoverable
                        style={gridStyleCard}
                        onClick={() => {
                          togglePageID(page.objectid);
                        }}
                      >
                        <Row style={{ height: "100%" }}>
                          <Col
                            flex={16}
                            style={
                              state.tempItem.pages.includes(page.objectid)
                                ? gridStyleCheck
                                : gridStyle
                            }
                          >
                            {state.tempItem.pages.includes(page.objectid) ? (
                              <CheckCircleOutlined
                                style={{ fontSize: "25px" }}
                              />
                            ) : (
                              <PlusCircleOutlined
                                style={{ fontSize: "25px", marginTop: "5px" }}
                              />
                            )}
                          </Col>
                        </Row>
                        <div style={{ width: "100%", height: "10px" }}></div>
                        <Row gutter={16}>
                          <Col flex={16}>
                            <b>{page.name}</b>
                            <Description style={{ textAlign: "left" }}>
                              {page.data.description}
                            </Description>
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}
                </Row>
              </div>
            )}
          {state.step == 1 &&
            state.addPageExtra && ( //Seiten Extra erstellen
              <div>
                <Divider></Divider>
                <Row
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    danger
                    onClick={() => {
                      cancelPage();
                    }}
                  >
                    {" "}
                    {t("bde:app.createitem.cancelPage")}
                  </Button>
                  <div style={{ height: "1px", width: "20px" }}></div>
                  <Button
                    type="primary"
                    onClick={() => {
                      saveTempPage();
                    }}
                  >
                    {t("bde:app.createitem.savePage")}
                  </Button>
                </Row>
                {t("bde:app.createitem.pagename")}

                <Input
                  prefix={
                    <FontSizeOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder={t("bde:forms.label_input_formname")}
                  style={{ display: "flex", width: "80%" }}
                  value={state.tempPage.data.title}
                  onChange={onChangePageTitle}
                ></Input>
                {t("bde:app.createitem.description")}

                <Input
                  prefix={
                    <FontSizeOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder={t("bde:entries.label_pagedescr_placholder")}
                  style={{ display: "flex", width: "80%" }}
                  value={state.tempPage.data.description}
                  onChange={onChangePageDesc}
                ></Input>
                {t("bde:app.createitem.addfield")}

                {getFieldInfos(fieldKey || 0)}
              </div>
            )}
          {state.step == 2 && (
            <div>
              <Card
                style={{ marginBottom: "10px" }}
                title={t("bde:app.createitem.assign")}
              >
                {t("bde:app.createitem.dataasign")}
                {state.units.length > 0 && (
                  <>
                    <br />
                    <Switch
                      unCheckedChildren={t("bde:admin.form.create.source")}
                      checkedChildren={t("bde:admin.form.create.unit")}
                      checked={sourceMode}
                      onChange={(nextValue: boolean) => {
                        setSourceMode(nextValue);
                      }}
                    />
                  </>
                )}
              </Card>
              {sourceMode && state.units.length > 0 ? (
                <UnitSelect
                  IsLoading={state.loadingSources}
                  Sources={state.units}
                  OnChangeUnit={onChangeIDUnit}
                  Selected={selectionUnit}
                />
              ) : (
                <SourceSelect
                  IsLoading={state.loadingSources}
                  Sources={state.sources}
                  OnChange={onChangeID}
                  Selected={selection}
                />
              )}
            </div>
          )}{" "}
          {state.step == 3 && (
            <div>
              <Card style={{ marginBottom: "10px" }} title="Zuordnung">
                {t("bde:app.createitem.dataaccess")}
              </Card>

              <Input.Search
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                placeholder={t("parse-admin:admin.edit_permission_filter")}
              />
              <Divider />

              <Tabs
                tabBarStyle={{ width: "100%" }}
                tabPosition="top"
                centered={true}
                size="large"
                activeKey={currentTab}
                onChange={(tab) => {
                  setCurrentTab(tab);
                }}
              >
                {!hideRoles && (
                  <Tabs.TabPane
                    tab={t("parse-admin:admin.edit_permission_roles_tab")}
                    key="roles"
                  >
                    <List
                      loading={roles.loading}
                      itemLayout="horizontal"
                      dataSource={rolesFiltered}
                      rowKey={(role) => role.id}
                      renderItem={(role) => {
                        const readAccess = acl.getRoleReadAccess(role);
                        const writeAccess = acl.getRoleWriteAccess(role);

                        return (
                          <List.Item
                            actions={[
                              <>
                                {!hideRead && (
                                  <Checkbox
                                    key="read"
                                    children={t(readLabel)}
                                    checked={readAccess}
                                    onChange={() => {
                                      acl.setRoleReadAccess(role, !readAccess);

                                      if (readAccess) {
                                        acl.setRoleWriteAccess(
                                          role,
                                          !readAccess
                                        );
                                      }

                                      setRolePermissionChanges((i) => i + 1);
                                    }}
                                  />
                                )}
                                {!hideWrite && (
                                  <Checkbox
                                    key="write"
                                    children={t(writeLabel)}
                                    checked={writeAccess}
                                    onChange={() => {
                                      acl.setRoleReadAccess(role, !writeAccess);
                                      acl.setRoleWriteAccess(
                                        role,
                                        !writeAccess
                                      );

                                      setRolePermissionChanges((i) => i + 1);
                                    }}
                                  />
                                )}
                              </>,
                            ]}
                          >
                            {getRoleName(role)}
                          </List.Item>
                        );
                      }}
                    />
                  </Tabs.TabPane>
                )}
                {!hideUsers && (
                  <Tabs.TabPane
                    tab={t("parse-admin:admin.edit_permission_users_tab")}
                    key="users"
                  >
                    <List
                      loading={users.loading}
                      itemLayout="horizontal"
                      dataSource={usersFiltered}
                      rowKey={(user) => user.id}
                      renderItem={(user) => {
                        const readAccess = acl.getReadAccess(user);
                        const writeAccess = acl.getWriteAccess(user);

                        return (
                          <List.Item
                            actions={[
                              <>
                                <Checkbox
                                  key="read"
                                  children={t(
                                    "parse-admin:admin.edit_permission_read"
                                  )}
                                  checked={readAccess}
                                  onChange={() => {
                                    acl.setReadAccess(user, !readAccess);

                                    if (readAccess) {
                                      acl.setWriteAccess(user, !readAccess);
                                    }

                                    setUserPermissionChanges((i) => i + 1);
                                  }}
                                />
                                <Checkbox
                                  key="write"
                                  children={t(
                                    "parse-admin:admin.edit_permission_write"
                                  )}
                                  checked={writeAccess}
                                  onChange={() => {
                                    acl.setReadAccess(user, !writeAccess);
                                    acl.setWriteAccess(user, !writeAccess);

                                    setUserPermissionChanges((i) => i + 1);
                                  }}
                                />
                              </>,
                            ]}
                          >
                            {getUserName(user)}
                          </List.Item>
                        );
                      }}
                    />
                  </Tabs.TabPane>
                )}
              </Tabs>
            </div>
          )}
        </Modal>
      </div>
    );
  }
);

function FormItem({ field, updateField }) {
  const t = useTranslation();
  if (field && field.type && field.type === "text") {
    return <TextItem field={field} updateField={updateField} />;
  }
  if (field && field.type && field.type === "number") {
    return <TextItem field={field} updateField={updateField} />;
  }
  if (field && field.type && field.type === "select") {
    return <Spinner field={field} updateField={updateField} />;
  }
  if (field && field.type && field.type === "info") {
    return <Label field={field} updateField={updateField} />;
  }
  if (field && field.type && field.type === "choose_image") {
    return <ImageChooser field={field} updateField={updateField} />;
  }
  if (field && field.type && field.type === "check_box") {
    return <CheckList field={field} updateField={updateField} />;
  }
  if (field && field.type && field.type === "autocomplete") {
    return <AutoComplete field={field} updateField={updateField} />;
  }
  if (field && field.type && field.type === "duration") {
    return <DurationPicker field={field} updateField={updateField} />;
  }
  if (field && field.type && field.type === "startstop") {
    return <StartStop field={field} updateField={updateField} />;
  }
  if (field && field.type && field.type === "date") {
    return <TimeSelect field={field} updateField={updateField} />;
  }

  return (
    <div style={{ marginTop: "20px", textAlign: "center" }}>
      <strong>{t("bde:fields.label_noFieldSelected")}</strong>
      <br />
      <WarningOutlined
        style={{ fontSize: "5em", color: "rgba(0, 0, 0, 0.25)" }}
      />
      <br />
      {t("bde:fields.label_addField")}
    </div>
  );
}
