import React from "react";

import { Table, Input, Space, Checkbox } from "antd";
import { Icon } from "@opendash/icons";

import { useTranslation, compare } from "@opendash/core";

export default function GreisTranspondersTable({
  transponders,
  updateTransponder,
  loading,
  admin,
}) {
  const t = useTranslation();

  const columns = [
    {
      title: t("greis:transponders.admin.cols.id"),
      dataIndex: "id",
      key: "id",
      sorter: compare((row) => row["id"]),
      render: (id, row) => (
        <span
          style={{ textDecoration: row._deleted ? "line-through" : undefined }}
        >
          {id}
        </span>
      ),
    },
    {
      title: t("greis:transponders.admin.cols.owner"),
      dataIndex: "owner",
      align: "center",
      key: "owner",
      sorter: compare((row) => row["owner"]),
      render: (v, row) => (
        <Input
          value={row.owner}
          onChange={(e) => updateTransponder(row.id, "owner", e.target.value)}
        />
      ),
    },
    {
      title: t("greis:transponders.admin.cols.misc"),
      dataIndex: "misc",
      align: "center",
      key: "misc",
      sorter: compare((row) => row["misc"]),
      render: (v, row) => (
        <Input
          value={row.misc}
          onChange={(e) => updateTransponder(row.id, "misc", e.target.value)}
        />
      ),
    },
    {
      title: t("greis:transponders.admin.cols.misc2"),
      dataIndex: "misc2",
      align: "center",
      key: "misc2",
      sorter: compare((row) => row["misc2"]),
      render: (v, row) => (
        <Input
          value={row.misc2}
          onChange={(e) => updateTransponder(row.id, "misc2", e.target.value)}
        />
      ),
    },
    {
      title: t("greis:transponders.admin.cols.active"),
      dataIndex: "id",
      align: "center",
      key: "active",
      render: (id, row) => (
        <Checkbox
          checked={row.active || row.active === undefined}
          onClick={(e) =>
            updateTransponder(
              row.id,
              "active",
              !(row.active || row.active === undefined)
            )
          }
        />
      ),
    },
  ];

  if (admin) {
    columns.push({
      title: " ",
      dataIndex: "id",
      align: "center",
      key: "actions",
      render: (id, row) => (
        <Space>
          {!row._deleted && (
            <a onClick={(e) => updateTransponder(row.id, "_deleted", true)}>
              <Icon icon="fa:trash" />
            </a>
          )}
          {row._deleted && (
            <a
              onClick={(e) => updateTransponder(row.id, "_deleted", undefined)}
            >
              <Icon icon="fa:undo" />
            </a>
          )}
        </Space>
      ),
    });
  }

  return (
    <Table
      rowKey={(row) => row.id}
      dataSource={transponders}
      columns={columns}
      loading={loading}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "50", "100"],
      }}
    />
  );
}
