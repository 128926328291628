import { AdminLayout, useFeedback, useTranslation } from "@opendash/core";
import {
  DataItemDimensionIdentifierInterface,
  DataSelect,
} from "@opendash/plugin-timeseries";
import { AdminToolbar } from "@opendash/ui";
import { Button, DatePicker, Spin } from "antd";
import dayjs, { Dayjs } from "dayjs";
import * as Parse from "parse";
import React from "react";

import {
  OpenWareProvider,
  OpenWareProviderProps,
} from "../common/OpenWareContext";

import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

export default function ReportingExportRoute({
  config,
}: OpenWareProviderProps) {
  return (
    <OpenWareProvider config={config}>
      <AdminLayout>
        <ReportingExport config={config} />
      </AdminLayout>
    </OpenWareProvider>
  );
}

function ReportingExport(config) {
  const t = useTranslation();
  const { notification } = useFeedback();

  const secure = config.config.secure ? "s" : "";
  const host = config.config.host;

  const [items, updateitems] = React.useState(
    [].map((element) => {
      return [
        element.params.source,
        element.params.id,
        element.params.dimension,
      ] as DataItemDimensionIdentifierInterface;
    }) || []
  );

  //const [seperator, setSeperator] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentNumber, setCurrentNumber] = React.useState<number>(1);

  const [dates, setDates] = React.useState<[Dayjs | null, Dayjs | null]>([
    dayjs().subtract(1, "week").startOf("day"),
    dayjs().endOf("day"),
  ]);

  React.useEffect(() => {}, []);
  const fetchData = async () => {
    if (items.length < 1) {
      notification.error({
        message: t("openware:reporting.export.noitem"),
        description: t("openware:reporting.export.noitemdesc"),
      });
      return;
    }

    setLoading(true);
    const user = await Parse.User.currentAsync();
    const diffTime =
      (dates[1].toDate().getTime() - dates[0].toDate().getTime()) / 4;
    for (let index = 0; index < items.length; index++) {
      setCurrentNumber(index + 1);
      const item = items[index];

      const source = item[0];
      const id = item[1];
      const dimension = item[2];
      try {
        let meta = [];
        let data = [];
        const headers = {
          "OD-Session": user.getSessionToken(),
          "Access-Control-Allow-Origin": "*",
        };
        let starterTime = Math.floor(dates[0].toDate().getTime());
        let timePuffer = Math.floor(dates[0].toDate().getTime() + diffTime);
        let fileName = id;
        while (timePuffer <= dates[1].toDate().getTime()) {
          const response = await fetch(
            `http${secure}://${host}/api/data/historical/${source}/${id}/${starterTime}/${timePuffer}?dimension=${dimension}`,
            { headers }
          );
          starterTime = Math.floor(timePuffer + 1);
          timePuffer = Math.floor(timePuffer + diffTime);

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const respData = await response.json();
          const [newMeta, newData] = makeCSV(respData, meta, data);
          meta = newMeta;
          data = newData;

          try {
            fileName = `${respData.name}-${respData.valueTypes[0].name}`;
          } catch (e) {
            console.error("Naming error...", e);
          }
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Eine Sekunde warten
        }
        writeFile(fileName, meta, data);
      } catch (error) {
        console.error("Error fetching data:", error);
        notification.error({
          message: t("openware:reporting.export.error"),
          description: error,
        });
      }
    }
    setLoading(false);
  };

  const makeCSV = (jsonData, meta, data) => {
    if (meta.length < 1) {
      meta.push(jsonData.id);
      meta.push(jsonData.name);
      meta.push(JSON.stringify(jsonData.valueTypes[0]));
    }
    if (jsonData.values.length > 0) {
      for (const valueEntry of jsonData.values) {
        const date = new Date(valueEntry.date);
        const dates = dayjs(date).format("DD.MM.YYYY HH:mm:ss");
        const value = valueEntry.value[0].toLocaleString("de-DE");
        data.push([valueEntry.date, dates, value]);
      }
    }
    return [meta, data];
  };

  const writeFile = (name, meta, data) => {
    console.log("Writing File..." + name + ".csv");
    data.sort((a, b) => b[0] - a[0]);
    const additionalHeader = ["TimeStamp", "Date", "Value"];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      meta +
      "\n" +
      [additionalHeader]
        .concat(data)
        .map((row) =>
          row
            .map((item) => (typeof item === "string" ? item.trim() : item))
            .join(";")
        )
        .join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", name + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <AdminToolbar
        title={t("openware:reporting.export.title")}
        description={t("openware:reporting.export.description")}
        actions={[]}
      />
      {loading && (
        <>
          <div
            style={{
              display: "flex",
              top: "0px",
              width: "100%",
              height: "45%",
              verticalAlign: "center",
              alignItems: "end",
              backgroundColor: "white",
              zIndex: 999,
            }}
          >
            <Spin spinning={loading} style={{ flexGrow: 1 }}></Spin>
          </div>
          <div
            style={{
              top: "0px",
              width: "100%",
              height: "55%",
              verticalAlign: "center",
              padding: "15px",
              alignItems: "baseline",
              backgroundColor: "white",
              zIndex: 999,
            }}
          >
            <p style={{ width: "100%", textAlign: "center" }}>
              {t("openware:reporting.export.file")} {currentNumber}{" "}
              {t("openware:reporting.export.of")} {items.length}
            </p>
            <p style={{ width: "100%", textAlign: "center" }}>
              {t("openware:reporting.export.taketime")}
            </p>
          </div>
        </>
      )}
      {!loading && (
        <>
          <div
            style={{
              width: "100%",
              background: "white",
              padding: "24px",
            }}
          >
            <DataSelect
              showSearch={true}
              showValue={true}
              showActions={false}
              showType={false}
              showTimestamp={true}
              selectionOptions={{
                select: "dimension",
                min: 1,
              }}
              selection={items}
              onSelection={(nextValue) => {
                updateitems(
                  nextValue as DataItemDimensionIdentifierInterface[]
                );
              }}
            />
            <RangePicker
              value={dates}
              style={{ width: "100%" }}
              onChange={(date) => {
                setDates(date);
              }}
              format="DD.MM.YYYY"
            />
            <div style={{ width: "100%", paddingTop: "20px" }}>
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  fetchData();
                }}
              >
                {t("openware:reporting.export.download")}
              </Button>
              {/*<Switch
          style={{ width: "50%" }}
          checkedChildren={t("openware:reporting.export.comma")}
          unCheckedChildren={t("openware:reporting.export.dot")}
          checked={seperator}
          onChange={(e) => {
            setSeperator(e);
          }}
        />*/}
            </div>
          </div>
        </>
      )}
    </>
  );
}
