import {
  createInternalComponent,
  produce,
  useTranslation,
  useUserService,
} from "@opendash/core";
import { Input, InputNumber, Select } from "antd";
import * as React from "react";
import { DataFetchingFilterInterface, useDataService } from "..";

const WIDTH_100 = { width: "100%" };

type Condition = DataFetchingFilterInterface;

interface Props {
  value?: Condition;
  onChange(value: Condition): void;
  // triggerItem:
  //   | DataItemIdentifierInterface
  //   | DataItemDimensionIdentifierInterface;
  itemSelection?: "item" | "dimension";
  labelFormat?(value: Condition): string;
}

export const DataFetchingFilterRule = createInternalComponent<Props>(
  function DataFetchingFilterRule({
    value: currentCondition,
    onChange,
    //triggerItem: [source],
    itemSelection = "dimension",
    labelFormat,
  }) {
    if (currentCondition.type !== "rule") {
      throw new Error("DataFetchingFilterRule bad value");
    }

    const t = useTranslation();

    const DataService = useDataService();
    const UserService = useUserService();

    const item = DataService._getOrUndefinedSync(
      currentCondition.source,
      currentCondition.id
    );

    const items = DataService._listOrThrowSync();
    //.filter(
    //   (item) => item.source === source
    // );

    const type = item?.valueTypes[currentCondition.dimension].type;

    const alarmRuleOptions = React.useMemo(
      () =>
        DataService.filterRuleTypes
          .filter((option) => option.value.startsWith(type?.toLowerCase()))
          .map((option) => {
            return {
              ...option,
              label: t(option.label),
            };
          }),
      [type]
    );

    const itemOptions = React.useMemo(
      () =>
        itemSelection === "item"
          ? items.flatMap((item) => {
              return item.valueTypes.map((vt, dimension) => {
                return {
                  label: labelFormat
                    ? labelFormat(currentCondition)
                    : DataService.getItemName(item, dimension),
                  value: DataService.keyForIdentifier([
                    item.source,
                    item.id,
                    dimension,
                  ]),
                };
              });
            })
          : itemSelection === "dimension"
            ? item.valueTypes.map((vt, dimension) => {
                return {
                  label: labelFormat
                    ? labelFormat(currentCondition)
                    : DataService.getItemName(item, dimension),
                  value: DataService.keyForIdentifier([
                    item.source,
                    item.id,
                    dimension,
                  ]),
                };
              })
            : [],
      [type]
    );

    React.useEffect(() => {
      if (!type) {
        onChange(
          produce(currentCondition, (draft) => {
            draft.rule = undefined;
          })
        );
      }

      if (!currentCondition.rule?.type?.startsWith(type.toLowerCase())) {
        onChange(
          produce(currentCondition, (draft) => {
            draft.rule = {
              // @ts-ignore
              type: alarmRuleOptions[0].value,
            };
          })
        );
      }
    }, [type, currentCondition.rule?.type]);

    return (
      <>
        <label>
          {t("opendash:data.filter.select_item_dimension_placeholder")}

          <Select
            value={DataService.keyForIdentifier([
              currentCondition.source,
              currentCondition.id,
              currentCondition.dimension,
            ])}
            onChange={(v) => {
              const [source, id, dimension] = JSON.parse(v);

              onChange(
                produce(currentCondition, (draft) => {
                  draft.source = source;
                  draft.id = id;
                  draft.dimension = dimension;
                })
              );
            }}
            options={itemOptions}
            style={WIDTH_100}
          />
        </label>

        <label>
          {t("opendash:data.trigger.select_type_placeholder")}

          <Select
            value={currentCondition.rule?.type}
            onChange={(v) => {
              onChange(
                produce(currentCondition, (draft) => {
                  draft.rule.type = v;
                })
              );
            }}
            options={alarmRuleOptions}
            style={WIDTH_100}
          />
        </label>

        {(currentCondition.rule.type === "number_equals" ||
          currentCondition.rule.type === "number_equals_not" ||
          currentCondition.rule.type === "number_gt" ||
          currentCondition.rule.type === "number_lt") && (
          <label>
            {t("opendash:data.trigger.select_value_placeholder")}

            <InputNumber
              value={currentCondition.rule.value}
              onChange={(v) => {
                onChange(
                  produce(currentCondition, (draft) => {
                    // @ts-ignore
                    draft.rule.value = v;
                  })
                );
              }}
              style={WIDTH_100}
            />
          </label>
        )}

        {(currentCondition.rule.type === "number_in_range" ||
          currentCondition.rule.type === "number_out_of_range") && (
          <label>
            {t("opendash:data.trigger.select_min_placeholder")}

            <InputNumber
              value={currentCondition.rule.min}
              onChange={(v) => {
                onChange(
                  produce(currentCondition, (draft) => {
                    // @ts-ignore
                    draft.rule.min = v;
                  })
                );
              }}
              style={WIDTH_100}
            />
          </label>
        )}

        {(currentCondition.rule.type === "number_in_range" ||
          currentCondition.rule.type === "number_out_of_range") && (
          <label>
            {t("opendash:data.trigger.select_max_placeholder")}

            <InputNumber
              value={currentCondition.rule.max}
              onChange={(v) => {
                onChange(
                  produce(currentCondition, (draft) => {
                    // @ts-ignore
                    draft.rule.max = v;
                  })
                );
              }}
              style={WIDTH_100}
            />
          </label>
        )}

        {(currentCondition.rule.type === "string_equals" ||
          currentCondition.rule.type === "string_equals_not" ||
          currentCondition.rule.type === "string_includes" ||
          currentCondition.rule.type === "string_includes_not" ||
          currentCondition.rule.type === "string_starts_with" ||
          currentCondition.rule.type === "string_starts_with_not" ||
          currentCondition.rule.type === "string_ends_with" ||
          currentCondition.rule.type === "string_ends_with_not") && (
          <label>
            {t("opendash:data.trigger.select_string_placeholder")}

            <Input
              value={currentCondition.rule.string}
              onChange={(e) => {
                const v = e.target.value;

                onChange(
                  produce(currentCondition, (draft) => {
                    // @ts-ignore
                    draft.rule.string = v;
                  })
                );
              }}
              style={WIDTH_100}
            />
          </label>
        )}
      </>
    );
  }
);
