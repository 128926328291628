import { Loading, useOpenDashServices } from "@opendash/core";
import {
  DataFetchingOptionsInterface,
  DataItemDimensionValueInterface,
  useDataService,
} from "@opendash/plugin-timeseries";
import * as React from "react";

import { WidgetStatic } from "@opendash/plugin-monitoring";
import { JSONEditor } from "@opendash/ui";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Drawer,
  Empty,
  Input,
  List,
  Row,
  Segmented,
  Typography,
} from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { HighchartsChart } from "./components/HighchartsChart";

export default () => {
  const [answer, setAnswer] = React.useState(null);
  const [options, setOptions] = React.useState(null);
  const [data, setData] = React.useState<DataItemDimensionValueInterface[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [timeFrame, setTimeFrame] = React.useState<1 | 2 | 3>(1);
  const [dataOpen, setDataOpen] = React.useState(false);
  const [messages, setMessages] = React.useState<
    { role: string; content: string }[]
  >([]);
  const [queryContent, setQueryContent] = React.useState<
    { role: string; content: string }[]
  >([]);
  const [promptText, setPromptText] = React.useState<string>(
    //"I want to have an line chart. The color of the line should be blue and the title is 'awesome chart'."
    "Ich möchte ein Liniendiagramm erstellen. Die Farbe der Linie soll blau sein und der Titel ist 'awesome chart'."
  );

  const DataService = useDataService();
  const { SourceService } = useOpenDashServices();
  const source = SourceService.getCurrent();
  if (source.id !== "e3AQKkfZOx") {
    SourceService.setCurrent("e3AQKkfZOx");
  }
  React.useEffect(() => {
    setQueryContent([...messages].reverse());
  }, [messages.length]);
  const promptGPT = async () => {
    setLoading(true);
    const messagesNew = [{ role: "user", content: promptText }, ...messages];
    setMessages(messagesNew);
    const query = fetch("http://127.0.0.1:3000/chart", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: [...messagesNew].reverse() }),
    });
    setPromptText("");
    try {
      const answer = await (await query).text();
      setAnswer(answer);
      const queryContent2 = [
        { role: "assistant", content: answer },
        ...messagesNew,
      ];
      setMessages(queryContent2);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getData = async () => {
    const item = DataService._getOrThrowSync(
      "demo@sustainkmu.de",
      "demo.sustainkmu.nshvone"
    );
    const fetchOptions: DataFetchingOptionsInterface = {
      historyType: "relative",
      unit: "day",
      value: 1,
    };

    if (timeFrame === 2) {
      fetchOptions.unit = "week";
    }
    if (timeFrame === 3) {
      fetchOptions.unit = "month";
    }

    const values = await DataService.fetchDimensionValues(
      item,
      0,
      fetchOptions
    );
    setData(values);
  };

  React.useEffect(() => {
    getData();
  }, [timeFrame]);

  React.useEffect(() => {
    if (!answer) return;
    let func;
    let toUse = answer;
    try {
      let lastIndex = -1;
      if (toUse.startsWith("const") || toUse.startsWith("let")) {
        lastIndex = toUse.lastIndexOf("};");
        if (lastIndex === -1) {
          toUse = toUse.substring(toUse.indexOf("=") + 1);
        } else {
          toUse = toUse.substring(toUse.indexOf("=") + 1, lastIndex + 2);
        }
      }

      func = eval(toUse);
    } catch (error) {
      console.error("Invalid JS:", toUse);
      let newTry = answer.substring(
        answer.indexOf("```") + 14,
        answer.lastIndexOf("```")
      );
      if (newTry.startsWith("const") || newTry.startsWith("let")) {
        newTry = newTry.substring(newTry.indexOf("=") + 1);
      }
      try {
        func = eval(newTry);
      } catch (error) {
        console.error(error);
      }
    }
    if (func) {
      const chartOpts = func(data);
      console.log({ chartOpts });
      setOptions(chartOpts);
    }
  }, [answer, data]);
  const formatMessage = (message) => {
    const startIndex = message.indexOf("```");
    const endIndex = message.lastIndexOf("```");
    const code = message.substring(startIndex, endIndex);
    if (startIndex === -1) return message;
    return (
      <>
        <Typography.Text>{message.substring(0, startIndex)}</Typography.Text>
        <br />
        <br />
        <Typography.Text code>
          {message.substring(startIndex + 14, endIndex)}
        </Typography.Text>
        <br />
        <br />
        <Typography.Text>
          {message.substring(endIndex + 3, message.length)}
        </Typography.Text>
      </>
    );
  };

  return (
    <>
      <Row gutter={16} style={{ padding: "16px" }}>
        <Col span={16} offset={8}>
          <img
            src="https://freesvg.org/img/1538298822.png"
            style={{ width: 150, display: "inline-block" }}
          />
          <Typography.Title style={{ display: "inline-block" }}>
            Dein Datenassistent
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={16} style={{ padding: "16px" }}>
        <Col span={24}>
          <Card title="Datenbeispiel" bordered={false}>
            <p>
              Dies ist ein Beispiel, wie neue Werkzeuge wie Large Language
              Modelle (bspw. ChatGPT) genutzt werden können, um Daten einfach zu
              analysieren. Hierzu haben wir einen Beispieldatensatz zur
              Verfügung gestellt, der Stromverbäuche enthält.
              <br />
              <br /> Untenstehend können Sie den Zeitraum auswählen und die
              Daten anschauen, um diese besser zu verstehen.
            </p>
            <Segmented
              block
              value={timeFrame}
              onChange={(val) => {
                setTimeFrame(val.valueOf() as 1 | 2 | 3);
              }}
              options={[
                { label: "Ein Tag", value: 1 },
                { label: "Eine Woche", value: 2 },
                { label: "Ein Monat", value: 3 },
              ]}
            />
            <p style={{ textAlign: "center", padding: "16px" }}>
              <Button
                type="link"
                onClick={() => {
                  setDataOpen(true);
                }}
                style={{ margin: "auto" }}
              >
                Wie sehen die Daten aus?
              </Button>

              <Drawer
                height={"75%"}
                placement="bottom"
                open={dataOpen}
                onClose={() => setDataOpen(false)}
              >
                <WidgetStatic
                  type="opendash-widget-table"
                  config={{
                    _history: {
                      unit:
                        timeFrame === 1
                          ? "day"
                          : timeFrame === 2
                            ? "week"
                            : "month",
                      value: 1,
                      historyType: "relative",
                      live: true,
                    },
                    _sources: [],
                    _items: [],
                    _dimensions: [
                      ["demo@sustainkmu.de", "demo.sustainkmu.nshvone", 0],
                    ],
                  }}
                ></WidgetStatic>
              </Drawer>
            </p>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ padding: 16, maxHeight: "100%" }}>
        <Col span={8} style={{ maxHeight: "90vh", overflowY: "scroll" }}>
          <div>
            <Input.TextArea
              value={promptText}
              rows={3}
              onChange={(e) => {
                const val = e.target.value;
                setPromptText(val);
              }}
            />
            <Button
              style={{ marginTop: 10, width: "100%" }}
              type="primary"
              disabled={loading}
              onClick={() => {
                promptGPT();
              }}
              children="Senden"
            />
          </div>
          <List
            dataSource={messages}
            renderItem={(message, index) => {
              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={
                          message.role === "user"
                            ? "https://xsgames.co/randomusers/avatar.php?g=pixel&key=0"
                            : "https://freesvg.org/img/1538298822.png"
                        }
                      />
                    }
                    title={
                      <a href="https://digitalzentrum-lr.de/">
                        {message.role === "assistant"
                          ? "Mittelstand-Digital Datenassistent"
                          : "Sie"}
                      </a>
                    }
                    description={formatMessage(message.content)}
                  />
                </List.Item>
              );
            }}
          ></List>
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: "89vh" }} />
        </Col>
        <Col span={15}>
          {options && data && !loading && (
            <div style={{ margin: "auto" }}>
              <HighchartsChart options={options}></HighchartsChart>
              <Collapse>
                <CollapsePanel header="JSON" key={0}>
                  <JSONEditor
                    mode="editor"
                    src={options}
                    onEdit={(edit) => {
                      setOptions(edit.updated_src);
                    }}
                    onAdd={(add) => {
                      setOptions(add.updated_src);
                    }}
                  />
                </CollapsePanel>
              </Collapse>
            </div>
          )}
          {!(options && data) && !loading && (
            <Empty
              description={
                <>
                  <span>
                    Einfach mit mir chatten und schon helfe ich dir bei der
                    Datenvisualisierung
                  </span>
                  <span>&#128521;</span>
                </>
              }
            ></Empty>
          )}
          {loading && <Loading message={"Loading..."}></Loading>}
        </Col>
      </Row>
    </>
  );
};
