import { $framework, SourceInterface, UserInterface } from "@opendash/core";
import {
  $monitoring,
  AlarmActionInterface,
  AlarmInterface,
  AlarmWebhookInterface,
  DashboardInterface,
  MonitoringAdapterContext,
  MonitoringAdapterInterface,
  WidgetInterface,
  WidgetPresetInterface,
} from "@opendash/plugin-monitoring";
import { $parse } from "@opendash/plugin-parse";
import {
  Alarm,
  AlarmAction,
  AlarmWebhook,
  Dashboard,
  Source,
  Widget,
  WidgetPreset,
  _User,
} from "@opendash/plugin-parse-schema";
import * as Parse from "parse";
import type { AdapterConfig } from ".";
import { fetchParse } from "./helper";

export class ParseMonitoringAdapter implements MonitoringAdapterInterface {
  private context: MonitoringAdapterContext;

  private config: AdapterConfig;

  private alarmActionMap = new Map<string, AlarmAction>();
  private cSource: SourceInterface;
  private sourceDebounce: any;
  constructor(config: AdapterConfig) {
    this.config = config;
    this.cSource = $framework.services.SourceService.getCurrent();
  }

  async onContext(context: MonitoringAdapterContext) {
    this.context = context;
    $framework.services.SourceService.subscribe(() => {
      this.cSource = $framework.services.SourceService.getCurrent();
      this.onSource(this.cSource);
    });

    await this.init();
  }
  async onSource(source: SourceInterface) {
    if (this.sourceDebounce) clearTimeout(this.sourceDebounce);
    this.sourceDebounce = setTimeout(() => {
      $monitoring.clearWidgetContextCache();
      this.init();
    }, 200);
  }

  async onUser(user: UserInterface) {
    await this.init();
  }

  private async init() {
    if (!Parse.User.current<_User>()) {
      this.context.setLoading(false);
      return;
    }
    await this.fetchWidgets();
    await this.fetchDashboards();

    await this.fetchWidgetPresets();
    await this.fetchAlarms();
    //await Promise.all([this.fetchWidgetPresets(), this.fetchAlarms()]);

    this.context.setLoading(false);
  }

  async createDashboard({ source, name, layout, widgets }: DashboardInterface) {
    try {
      const dashboard = new Dashboard();

      dashboard.set("name", name);
      dashboard.set("widgets", widgets || []);
      dashboard.set("layout", layout || []);
      dashboard.set("user", Parse.User.current());
      if (source) {
        dashboard.set("source", new Source({ id: source }));
      }

      dashboard.setACL(new Parse.ACL(Parse.User.current<_User>()));

      await dashboard.save();

      // TODO: delete this?
      // await this.init();

      await this.context.updateDashboard(
        dashboard.id,
        this.mapDashboards(dashboard)
      );

      return dashboard.id;
    } catch (error) {
      console.error(error);
    }
  }

  async updateDashboard({
    id,
    source,
    type,
    name,
    layout,
    widgets,
    heroWidget,
  }: DashboardInterface) {
    try {
      const dashboard = new Dashboard({
        id,
      });

      dashboard.set("name", name);
      dashboard.set("widgets", widgets || []);
      dashboard.set("layout", layout || []);
      dashboard.set("type", type || "grid");
      dashboard.set("heroWidget", heroWidget || null);

      if (source) {
        dashboard.set("source", new Source({ id: source }));
      }

      await dashboard.save();
      await this.context.updateDashboard(
        dashboard.id,
        this.mapDashboards(dashboard)
      );
    } catch (error) {
      console.error(error);
    }
  }

  async deleteDashboard(input: DashboardInterface): Promise<void> {
    try {
      const dashboard = new Dashboard({
        id: input.id,
      });

      await dashboard.destroy();

      this.context.updateDashboard(dashboard.id, undefined);
    } catch (error) {
      console.error(error);
    }
  }

  async openDashboardSharingDialog(input: DashboardInterface) {
    return await $parse.ui.editObjectACL(Dashboard.className, input.id, {
      hideSelf: true,
      // hideRoles: true,
    });
  }

  async createWidget(input: WidgetInterface) {
    try {
      const widget = new Widget();

      widget.set("name", input.name);
      widget.set("type", input.type);
      widget.set("config", input.config || {});
      widget.set("user", Parse.User.current<_User>());

      widget.setACL(new Parse.ACL(Parse.User.current<_User>()));

      await widget.save();
      await this.init();

      this.context.updateWidget(widget.id, this.mapWidgets(widget));

      return widget.id;
    } catch (error) {
      console.error(error);
    }
  }

  async updateWidget({ id, name, type, config }: WidgetInterface) {
    try {
      const widget = new Widget({ id });

      widget.set("name", name);
      widget.set("type", type);
      widget.set("config", config || {});

      await widget.save();

      this.context.updateWidget(widget.id, this.mapWidgets(widget));
    } catch (error) {
      console.error(error);
    }
  }

  async deleteWidget(input: WidgetInterface): Promise<void> {
    try {
      const widget = new Widget({
        id: input.id,
      });

      await widget.destroy();

      this.context.updateWidget(widget.id, undefined);
    } catch (error) {
      console.error(error);
    }
  }

  async openWidgetSharingDialog(input: WidgetInterface) {
    return await $parse.ui.editObjectACL(Widget.className, input.id, {
      hideSelf: true,
      // hideRoles: true,
    });
  }

  async createWidgetPreset(input: WidgetPresetInterface) {
    try {
      const widget = new WidgetPreset();

      widget.set("label", input.label);
      widget.set("description", input.description);
      widget.set("imageLink", input.imageLink);
      widget.set("tags", input.tags || []);
      widget.set("layout", input.layout || [4, 4]);
      widget.set("type", input.widget?.type || "not-set");
      widget.set("config", input.widget?.config || {});

      widget.setACL(new Parse.ACL(Parse.User.current<_User>()));

      await widget.save();
      await this.init();

      this.context.updateWidgetPreset(widget.id, this.mapWidgetPreset(widget));

      return widget.id;
    } catch (error) {
      console.error(error);
    }
  }

  async updateWidgetPreset({ id, ...input }: WidgetPresetInterface) {
    try {
      const widget = new WidgetPreset({ id });

      widget.set("label", input.label);
      widget.set("description", input.description);
      widget.set("imageLink", input.imageLink);
      widget.set("tags", input.tags || []);
      widget.set("layout", input.layout || [4, 4]);
      widget.set("type", input.widget?.type || "not-set");
      widget.set("config", input.widget?.config || {});

      await widget.save();

      this.context.updateWidgetPreset(widget.id, this.mapWidgetPreset(widget));
    } catch (error) {
      console.error(error);
    }
  }

  async deleteWidgetPreset(input: WidgetPresetInterface): Promise<void> {
    try {
      const widget = new WidgetPreset({
        id: input.id,
      });

      await widget.destroy();

      this.context.updateWidgetPreset(widget.id, undefined);
    } catch (error) {
      console.error(error);
    }
  }

  async openWidgetPresetSharingDialog(input: WidgetPresetInterface) {
    return await $parse.ui.editObjectACL(WidgetPreset.className, input.id, {
      hideSelf: true,
      // hideRoles: true,
    });
  }

  private async fetchDashboards() {
    if (!Dashboard) {
      return;
    }

    await fetchParse(
      new Parse.Query(Dashboard).ascending("name").limit(999999),
      (result) => {
        this.context.setDashboards(
          result.map((value) => this.mapDashboards(value))
        );
      },
      (key, value) => {
        this.context.updateDashboard(key, this.mapDashboards(value));
      },
      this.config.liveQueries
    );
  }

  private async fetchWidgets() {
    if (!this.cSource) {
      return;
    }
    if (!Widget) {
      return;
    }
    const dashboards = await new Parse.Query(Dashboard)
      .ascending("name")
      .limit(999999)
      .equalTo("source", new Source({ id: this.cSource.id }))
      .find();

    const widgetsToFetch = dashboards
      .filter((db) => db.source.id === this.cSource.id)
      .map((db) => {
        const dbWidgets = [...db.widgets];
        try {
          if (db.heroWidget) {
            const hero = JSON.parse(db.heroWidget);
            if (hero.top) {
              dbWidgets.push(hero.top.widget);
            }
            if (hero.left) {
              dbWidgets.push(hero.left.widget);
            }
          }
        } catch (e) {}
        return dbWidgets;
      })
      .flat();

    await fetchParse(
      new Parse.Query(Widget)
        .ascending("name")
        .containedIn("objectId", widgetsToFetch)
        .limit(999999),
      (result) => {
        this.context.setWidgets(result.map((value) => this.mapWidgets(value)));
      },
      (key, value) => {
        this.context.updateWidget(key, this.mapWidgets(value));
      },
      this.config.liveQueries
    );
  }

  private async fetchWidgetPresets() {
    if (!WidgetPreset) {
      return;
    }

    await fetchParse(
      new Parse.Query(WidgetPreset).ascending("label").limit(999999),
      (result) => {
        this.context.setWidgetPresets(
          result.map((value) => this.mapWidgetPreset(value))
        );
      },
      (key, value) => {
        this.context.updateWidgetPreset(key, this.mapWidgetPreset(value));
      },
      this.config.liveQueries
    );
  }

  private async fetchAlarms() {
    if (!Alarm) {
      return;
    }

    await fetchParse(
      new Parse.Query(Alarm).ascending("name").limit(999999),
      (result) => {
        this.context.setAlarms(result.map((value) => this.mapAlarms(value)));
      },
      (key, value) => {
        this.context.updateAlarm(key, this.mapAlarms(value));
      },
      this.config.liveQueries
    );

    await fetchParse(
      new Parse.Query(AlarmAction).ascending("label").limit(999999),
      (result) => {
        this.context.setAlarmActions(
          result.map((value) => this.mapAlarmActions(value))
        );

        for (const alarm of result) {
          this.alarmActionMap.set(alarm.id, alarm);
        }
      },
      undefined,
      this.config.liveQueries
    );

    await fetchParse(
      new Parse.Query(AlarmWebhook).ascending("name").limit(999999),
      (result) => {
        this.context.setAlarmWebhooks(
          result.map((value) => this.mapAlarmWebhooks(value))
        );
      },
      undefined,
      this.config.liveQueries
    );
  }

  private mapDashboards(input: Parse.Object): DashboardInterface {
    if (!input) {
      return undefined;
    }

    return {
      id: input.id,
      name: input.get("name"),
      source: input.get("source")?.id,
      widgets: input.get("widgets"),
      layout: input.get("layout"),
      heroWidget: input.get("heroWidget"),
      type: input.get("type") || "grid",

      ...this.getSharingInfo(input),
    };
  }

  private mapWidgets(input: Parse.Object): WidgetInterface {
    if (!input) {
      return undefined;
    }

    return {
      id: input.id,
      name: input.get("name"),
      type: input.get("type"),
      config: input.get("config"),

      ...this.getSharingInfo(input),
    };
  }

  private mapWidgetPreset(input: WidgetPreset): WidgetPresetInterface {
    if (!input) {
      return undefined;
    }

    return {
      id: input.id,
      label: input.get("label"),
      description: input.get("description"),
      imageLink: input.get("imageLink"),
      tags: input.get("tags") as string[],
      layout: input.get("layout") as [number, number],
      widget: {
        type: input.get("type"),
        config: input.get("config"),
      },

      ...this.getSharingInfo(input),
    };
  }

  private getSharingInfo(obj: Parse.Object) {
    const acl = obj.getACL() || new Parse.ACL();
    const userId = Parse.User.current()?.id;
    const ownerId = obj.get("user")?.id;

    const isOwner = ownerId === userId;
    const isShared = Object.keys(acl.toJSON()).length > 1;
    const isReadOnly = !isOwner && !acl.getWriteAccess(userId);

    return { isOwner, isShared, isReadOnly };
  }

  async createAlarm(input: AlarmInterface) {
    try {
      const alarm = new Alarm();

      alarm.set("item_source", input.item[0]);
      alarm.set("item_id", input.item[1]);
      alarm.set("item_dimension", input.item[2]);
      alarm.set("trigger", input.trigger || {});
      alarm.set("condition", input.condition || null);
      alarm.set("action", this.mapAlarmActionsReverse(input.action));

      alarm.setACL(new Parse.ACL(Parse.User.current<_User>()));

      await alarm.save();

      this.context.updateAlarm(alarm.id, this.mapAlarms(alarm));

      return alarm.id;
    } catch (error) {
      console.error(error);
    }
  }

  async updateAlarm(input: AlarmInterface) {
    try {
      const alarm = new Alarm({
        id: input.id,
      });

      // @ts-ignore
      alarm.set("item_source", input.item[0]);
      // @ts-ignore
      alarm.set("item_id", input.item[1]);
      // @ts-ignore
      alarm.set("item_dimension", input.item[2]);
      // @ts-ignore
      alarm.set("trigger", input.trigger || {});
      // @ts-ignore
      alarm.set("condition", input.condition || null);
      // @ts-ignore
      alarm.set("action", this.mapAlarmActionsReverse(input.action));

      await alarm.save();

      this.context.updateAlarm(alarm.id, this.mapAlarms(alarm));
    } catch (error) {
      console.error(error);
    }
  }

  async deleteAlarm(input: AlarmInterface): Promise<void> {
    try {
      const alarm = new Alarm({
        id: input.id,
      });

      await alarm.destroy();

      this.context.updateAlarm(alarm.id, undefined);
    } catch (error) {
      console.error(error);
    }
  }

  private mapAlarms(input: Alarm): AlarmInterface {
    if (!input) {
      return undefined;
    }

    return {
      id: input.id,
      item: [
        input.get("item_source"),
        input.get("item_id"),
        input.get("item_dimension"),
      ],
      trigger: input.get("trigger"),
      condition: input.get("condition"),
      action: input.get("action"),
    };
  }

  private mapAlarmWebhooks(input: AlarmWebhook): AlarmWebhookInterface {
    if (!input) {
      return undefined;
    }

    return {
      id: input.id,
      name: input.get("name"),
    };
  }

  private mapAlarmActions(input: AlarmAction): AlarmActionInterface {
    if (!input) {
      return undefined;
    }

    return {
      id: input.id,
      label: input.get("label"),
      formFields: input.get("formFields"),
      supportedTypes: input.get(
        "supportedTypes"
      ) as AlarmActionInterface["supportedTypes"],
    };
  }

  private mapAlarmActionsReverse(input: any): any {
    if (!input) {
      return {};
    }

    if (input.objectId) {
      input.actionId = input.objectId;
    }

    if (!input.actionId) {
      return input;
    }

    const action = this.alarmActionMap.get(input.actionId);
    const actionJson = action?.toJSON();

    return {
      ...actionJson,
      ...input,
      objectId: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      ACL: undefined,
      formFields: undefined,
    };
  }
}
