import { createWidget } from "@opendash/plugin-monitoring";
import React from "react";
import ImageLink from "./image";
import { ConfigInterface } from "./types";

export default createWidget<ConfigInterface>({
  type: "opendash-widget-hc-felix_test",
  meta: {},
  displayComponent: React.lazy(() => import("./component")),
  settingsComponent: React.lazy(() => import("./settings")),

  dataItems: {
    select: "dimension",
    min: 2,
    types: ["Number"],
  },

  dataFetching: {
    live: true,
    history: true,
  },

  presets: [
    {
      label: "highcharts:explorer.felix_test.title",
      description: "highcharts:explorer.felix_test.description",
      tags: ["kpi"],
      imageLink: ImageLink,
      widget: {
        config: {
          icon: "fa:info",
        },
      },
    },
  ],
});
