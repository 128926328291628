import * as Parse from "parse";

import { makeAutoObservable } from "@opendash/state";

interface Options {
  count?: boolean;
}

export class ParseQueryState<T extends Parse.Object> {
  result: T[] = [];
  loading: boolean = false;
  count: number = 0;
  error: Error | Parse.Error | null = null;

  private query: Parse.Query<T>;
  private options: Options;

  constructor(query: Parse.Query<T>, options?: Options) {
    makeAutoObservable(this);

    this.query = query;
    this.options = options || {};

    this.reload();
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  setResult(value: T[], count: number) {
    this.result = value;
    this.count = count;
    this.error = null;
    this.loading = false;
  }

  setError(value: Error | Parse.Error | null) {
    this.error = value;
    this.loading = false;
  }

  async reload() {
    try {
      this.setLoading(true);

      const result = await this.query.find();

      if (this.options.count) {
        const count = await this.query.count();

        this.setResult(result, count);
      } else {
        this.setResult(result, 0);
      }
    } catch (error) {
      this.setError(error);
    }
  }
}
