import { useTranslation } from "@opendash/core";
import { Input, Switch } from "antd";
import React from "react";

export function ItemAttributes({ field, updateField }) {
  const t = useTranslation();
  const v_required = {
    value: false,
    err: "Wert muss eingeben werden",
  };

  let unitForm = null;

  if (field["type"] === "number") {
    unitForm = (
      <div className="od_admin_bde_input">
        <Input
          style={{ display: "inline-block" }}
          addonBefore={t("bde:label_unit")}
          value={field.options.unit}
          onChange={(e) => {
            updateField((state) => {
              state.options.unit = e.target.value;
            });
          }}
        />
      </div>
    );
  }
  if (field["type"] === "select") {
    unitForm = (
      <div>
        <div className="od_admin_bde_input">
          <h4> {t("bde:fields.label_displayOptions")} </h4>
          <Switch
            checked={field.options.type === "button"}
            onChange={(e) => {
              updateField((state) => {
                state.options.type = e ? "button" : "dropdown";
              });
            }}
          />{" "}
          {t("bde:fields.label_displayAsButtons")}
        </div>
        <div className="od_admin_bde_input">
          <h4> {t("bde:fields.label_dataOptions")} </h4>
          <Switch
            checked={field["od_type"].toLowerCase() === "number"}
            onChange={(e) => {
              updateField((state) => {
                state["od_type"] = e ? "number" : "string";
              });
            }}
          />{" "}
          {t("bde:fields.label_treatAsNumber")}
        </div>
        {field["od_type"].toLowerCase() === "number" ? (
          <div style={{ marginTop: "10px" }} className="od_admin_bde_input">
            <Input
              style={{ display: "inline-block" }}
              addonBefore={t("bde:label_unit")}
              value={field.options.unit}
              onChange={(e) => {
                updateField((state) => {
                  state.options.unit = e.target.value;
                });
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "block",
      }}
    >
      <div
        style={{
          display: "block",
          marginRight: "20px",
          marginTop: "20px",
        }}
      >
        <h3> {t("bde:fields.label_attributes")} </h3>
        <Switch
          onChange={(e) => {
            updateField((state) => {
              if (!state["v_required"]) {
                state["v_required"] = v_required;
              }
              state["v_required"].value = e;
            });
          }}
          checked={field["v_required"] && field["v_required"].value}
        />{" "}
        {t("bde:fields.label_required")}
      </div>
      <div
        style={{
          display: "block",
          marginRight: "20px",
          marginTop: "20px",
        }}
      ></div>
      {unitForm}
    </div>
  );
}
