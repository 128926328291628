import Parse from "parse";

import {
  SourceAdapterContext,
  SourceAdapterInterface,
  SourceInterface,
  UserInterface,
} from "@opendash/core";

import { AdapterConfig } from "../types";
import { _User, Source } from "../types-generated";
import { fetchParse } from "./helper";

export class ParseSourceAdapter implements SourceAdapterInterface {
  private context: SourceAdapterContext;

  private config: AdapterConfig;

  constructor(config: AdapterConfig) {
    this.config = config;
  }

  onContext(context: SourceAdapterContext) {
    this.context = context;

    this.init();
  }

  onUser(user: UserInterface) {
    this.init();
  }

  private async init() {
    if (!(await Parse.User.currentAsync<_User>())) {
      return;
    }

    await this.fetchSources();

    this.context.setLoading(false);
  }

  private async fetchSources() {
    if (!Source) {
      return;
    }

    if (!navigator.onLine) {
      // TODO: BETTER OFFLINE SUPPORT
      return;
    }

    await fetchParse(
      new Parse.Query(Source).ascending("name").limit(999999),
      async (result) => {
        this.context.setSources(result.map((value) => this.mapSources(value)));
      },
      async (key, value) => {
        this.context.updateSources(key, this.mapSources(value));
      },
      this.config.liveQueries
    );
  }
  private mapSources(input: Parse.Object): SourceInterface {
    if (!input) {
      return undefined;
    }

    return {
      id: input.id,
      parent: input.get("parent")?.id,
      name: input.get("name"),
      tag: input.get("tag"),
      meta: {},
    };
  }
}
