import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "@opendash/core";
import { Button, Input } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer((props: { field; updateField }) => {
  const t = useTranslation();
  const options = props.field.items || [];
  const checkerSettings = (
    <div className="od_admin_bde_input">
      <h3> {t("bde:fields.label_manageChecklists")} </h3>
      {options.map((element, index) => {
        return (
          <div key={index} style={{ display: "block" }}>
            <Input
              style={{ display: "inline-block", width: "30%" }}
              addonBefore={t("bde:fields.label_optionKey")}
              value={element.value}
              onChange={(e) => {
                props.updateField((state) => {
                  state.items[index].value = e.target.value;
                });
              }}
            />
            <Input
              style={{ display: "inline-block", width: "50%" }}
              addonBefore={t("bde:fields.label_optionLabel")}
              value={element.label}
              onChange={(e) => {
                props.updateField((state) => {
                  state.items[index].label = e.target.value;
                });
              }}
            />
            <Button
              type="default"
              style={{ display: "inline-block" }}
              onClick={(e) => {
                props.updateField((state) => {
                  state.items.splice(index, 1);
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      })}
      <Button
        type="dashed"
        style={{ display: "block", marginTop: "10px" }}
        onClick={(e) => {
          props.updateField((state) => {
            const x = [...options];
            x.push({ value: "", label: "" });
            state.items = x;
          });
        }}
      >
        + {t("bde:fields.label_addOptionEnty")}
      </Button>
    </div>
  );
  return <div>{checkerSettings}</div>;
});
