import Parse from "parse";

import type { Documentation_Category } from "./Documentation_Category";
import type { _User } from "./_User";

export interface Documentation_DocumentAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  category: Documentation_Category;
  content: string;
  createdBy: _User;
  tags: any[];
  title: string;
  updatedBy: _User;
}

export class Documentation_Document extends Parse.Object<Documentation_DocumentAttributes> {
  static className: string = "OD3_Documentation_Document";

  constructor(data?: Partial<Documentation_DocumentAttributes>) {
    super("OD3_Documentation_Document", data as Documentation_DocumentAttributes);
  }

  get category(): Documentation_Category {
    return super.get("category");
  }
  set category(value: Documentation_Category) {
    super.set("category", value);
  }
  get content(): string {
    return super.get("content");
  }
  set content(value: string) {
    super.set("content", value);
  }
  get createdBy(): _User {
    return super.get("createdBy");
  }
  set createdBy(value: _User) {
    super.set("createdBy", value);
  }
  get tags(): any[] {
    return super.get("tags");
  }
  set tags(value: any[]) {
    super.set("tags", value);
  }
  get title(): string {
    return super.get("title");
  }
  set title(value: string) {
    super.set("title", value);
  }
  get updatedBy(): _User {
    return super.get("updatedBy");
  }
  set updatedBy(value: _User) {
    super.set("updatedBy", value);
  }
}

Parse.Object.registerSubclass("OD3_Documentation_Document", Documentation_Document);
