import { produce, useSource, useUrlParam } from "@opendash/core";
import { WidgetStatic } from "@opendash/plugin-monitoring";
import { useParams } from "@opendash/router";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import { ReportImage } from "./ReportImageEditor";
type props = {};
const ReportImagePrint = (props: props) => {
  const { source, id } = useParams();
  const [cSource, setSource] = useSource();
  const [optionsOverwrites] = useUrlParam("overwrite", null, "json");
  const [typeOverwrite] = useUrlParam("wType", null, "string");
  if (source !== cSource.id) {
    setSource(source);
  }

  const q = React.useMemo(() => {
    return new Parse.Query("OD3_Monitoring_ReportImage").equalTo(
      "objectId",
      id
    );
  }, [id]);
  const { result } = useParseQuery(q);
  let reportImage = null as ReportImage | null;
  if (result && result[0]) {
    reportImage = result[0].toJSON() as unknown as ReportImage;
  }
  if (reportImage) {
    if (optionsOverwrites || typeOverwrite) {
      reportImage = produce(reportImage, (draft) => {
        if (optionsOverwrites) {
          Object.keys(optionsOverwrites).forEach((key) => {
            draft.config[key] = optionsOverwrites[key];
          });
        }
        if (typeOverwrite) {
          draft.type = typeOverwrite;
        }
      });
    }
  }
  console.log({ source, id, reportImage });
  return (
    <>
      {!reportImage && <div>Report Image not found</div>}
      {reportImage && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            overflow: "hidden",
            backgroundColor: "#fff",
          }}
        >
          <WidgetStatic type={reportImage.type} config={reportImage.config} />
        </div>
      )}
    </>
  );
};

export default ReportImagePrint;
