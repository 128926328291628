import { Button, Flex, Form } from "antd";
import React from "react";
import OperationItem from "./OperationItem";

type Props = {};

export default (props: Props) => {
  return (
    <Form.List name="operations">
      {(fields, operation, meta) => {
        return (
          <Flex gap={16} vertical justify="center">
            {fields.map((field) => (
              <OperationItem
                key={field.key}
                index={field.name}
                onDelete={() => operation.remove(field.name)}
              />
            ))}
            <Button onClick={() => operation.add()}>Add</Button>
          </Flex>
        );
      }}
    </Form.List>
  );
};
