import React, { useMemo } from "react";

import { Parse, useParseQueryFirst } from "parse-hooks";
import {
  Loading,
  useSource,
  usePermission,
  useTranslation,
} from "@opendash/core";

import GreisTransponderConfig from "./GreisTransponderConfig";
import { Divider } from "antd";

const GreisDevice = "HG_Device";

export default function GreisTransponders() {
  const [source] = useSource();
  const t = useTranslation();

  const cardsQuery = useMemo(
    () =>
      source?.tag
        ? new Parse.Query(GreisDevice).equalTo("spsid", source.tag)
        : null,
    [source]
  );

  const transponderConfig = cardsQuery
    ? useParseQueryFirst(cardsQuery)
    : { loading: false };

  const isAdmin = usePermission("hg:transponders");

  if (transponderConfig.loading) {
    return <Loading message="" />;
  }

  return (
    <GreisTransponderConfig
      spsid={source ? source.tag : undefined}
      transponderConfig={transponderConfig}
      admin={isAdmin}
    />
  );
}
