import {
  compare,
  SourceIdentifierInterface,
  SourceInterface,
  useOpenDashServices,
  useSource,
  useTranslation,
} from "@opendash/core";
import { Button, Divider, TreeSelect } from "antd";
import * as React from "react";
import {
  DataItemDimensionIdentifierInterface,
  DataItemDimensionSelectionInterface,
  DataItemIdentifierInterface,
  DataItemInterface,
  DataItemSelectionInterface,
  DataItemValueTypeInterface,
  DataSelectionInterface,
  DataSourceSelectionInterface,
  useDataItems,
  useDataService,
} from "..";

type Props = {
  style?: React.CSSProperties;
} & (
  | {
      options?: DataSelectionInterface;
      value?: any[];
      onChange?: (itentifier: any[]) => void;
    }
  | {
      options?: DataSourceSelectionInterface;
      value?: SourceIdentifierInterface[];
      onChange?: (itentifier: SourceIdentifierInterface[]) => void;
    }
  | {
      options?: DataItemSelectionInterface;
      value?: DataItemIdentifierInterface[];
      onChange?: (itentifier: DataItemIdentifierInterface[]) => void;
    }
  | {
      options?: DataItemDimensionSelectionInterface;
      value?: DataItemDimensionIdentifierInterface[];
      onChange?: (itentifier: DataItemDimensionIdentifierInterface[]) => void;
    }
);

type DataType = {
  id: string;
  parent: string | undefined;
  title: string;
  searchMatch: boolean;
  selectable: boolean;
} & (
  | {
      type: "source";
      source: SourceInterface;
    }
  | {
      type: "item";
      item: DataItemInterface;
    }
  | {
      type: "dimension";
      item: DataItemInterface;
      dimension: number;
      valueType: DataItemValueTypeInterface;
    }
);

type TreeDataType = DataType & {
  selectable: boolean;
  checkable: boolean;
};

const treeDataSimpleMode = { id: "id", pId: "parent" };

export const DataInput = React.memo<Props>(function DataInput({
  options,
  value: selection = [],
  onChange: onSelection = () => {},
}) {
  const t = useTranslation();
  const DataService = useDataService();
  const { SourceService } = useOpenDashServices();

  const items = useDataItems();

  const [rootSource, , sources] = useSource();
  const [dropDownOpen, setDropDownOpen] = React.useState(false);
  const isMultiSelect = options.max !== 1;
  const value = React.useMemo(() => {
    if (isMultiSelect) {
      return selection.map((id) => DataService.keyForIdentifier(id));
    }

    if (!selection || selection.length === 0) {
      return undefined;
    } else {
      return DataService.keyForIdentifier(selection[0]);
    }
  }, [selection, isMultiSelect]);
  const onChange = React.useCallback(
    (keyOrKeys: string | string[]) => {
      let keys = Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys];

      keys = keys.filter(Boolean);

      if (options.max && keys.length > options.max) {
        keys = keys.slice(keys.length - options.max);
      }

      onSelection(keys.map((key) => JSON.parse(key)));
    },
    [onSelection]
  );

  const data = React.useMemo(() => {
    const result: Record<string, DataType> = {};

    const relevantSources = [
      rootSource,
      ...SourceService.getDescendents(rootSource),
    ];

    for (const source of relevantSources) {
      const id = DataService.keyForIdentifier(source.id);

      result[id] = {
        type: "source",
        source,

        id: id,
        title: source.name,
        parent: DataService.keyForIdentifier(source.parent),

        searchMatch: true,
        selectable: options?.select === "source",
      };
    }

    if (options.select === "item" || options.select === "dimension") {
      for (const item of items) {
        const id = DataService.keyForIdentifier([item.source, item.id]);

        const source = relevantSources.find(
          (source) => source.tag === item.source
        );

        if (!source) {
          continue;
        }

        result[id] = {
          type: "item",
          item,

          id: id,
          title: DataService.getItemName(item) + ` (${item.valueTypes.length})`,
          parent: DataService.keyForIdentifier(source.id),

          searchMatch: true,
          selectable: options?.select === "item",
        };

        if (options.select === "dimension") {
          for (
            let dimension = 0;
            dimension < item.valueTypes.length;
            dimension++
          ) {
            const valueType = item.valueTypes[dimension];

            let selectable = false;
            let typeMatch = true;

            if (options && options.select === "dimension") {
              selectable = true;

              if (options.types) {
                selectable = options.types.includes(valueType.type);
                typeMatch = selectable;
              }
            }

            if (typeMatch) {
              const dimensionKey = DataService.keyForIdentifier([
                item.source,
                item.id,
                dimension,
              ]);

              result[dimensionKey] = {
                type: "dimension",
                item,
                dimension,
                valueType,

                id: dimensionKey,
                title:
                  DataService.getItemName(item, dimension, true) +
                  ` (${DataService.getItemName(item)})`,
                parent: id,

                searchMatch: true,
                selectable,
              };
            }
          }
        }
      }
    }

    return Object.values(result).sort(compare((x) => x.title.toLowerCase()));
  }, [rootSource?.id, sources, items, options?.select]);

  const treeData: TreeDataType[] = React.useMemo(() => {
    const hasChildren = {};
    return data
      .map((row) => {
        if (row.parent) {
          hasChildren[row.parent] = true;
        }

        return {
          ...row,

          key: row.id,
          value: row.id,
          checkable: row.selectable,
        };
      })
      .filter((row) => row.selectable || hasChildren[row.id]);
  }, [data, options?.select]);

  return (
    <>
      <TreeSelect
        size="large"
        allowClear={true}
        autoClearSearchValue={false}
        onClear={() => {
          onChange([]);
        }}
        onDropdownVisibleChange={(open) => {
          setDropDownOpen(open);
        }}
        open={dropDownOpen}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Button
                type="primary"
                children={t("opendash:ui.close")}
                style={{ width: "100%", padding: "0 8px 4px" }}
                onClick={() => {
                  setDropDownOpen(false);
                }}
              ></Button>
            </>
          );
        }}
        key={rootSource.id}
        style={{ width: "100%" }}
        treeCheckable={isMultiSelect}
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        treeDefaultExpandAll={true}
        placeholder={t("opendash:ui.select_data_item")}
        treeData={treeData}
        treeDataSimpleMode={treeDataSimpleMode}
        value={value}
        onChange={onChange}
        showSearch={true}
        filterTreeNode={(searchString, node: TreeDataType) => {
          if (node.type === "item") {
            return DataService.evaluateSearchForItem(searchString, node.item);
          }

          if (node.type === "dimension") {
            return DataService.evaluateSearchForItem(
              searchString,
              node.item,
              node.dimension
            );
          }

          return node.title.toLowerCase().includes(searchString.toLowerCase());
        }}
      />
    </>
  );
});
