import dayjs, { Dayjs } from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import duration from "dayjs/plugin/duration";
import objectSupport from "dayjs/plugin/objectSupport";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekday from "dayjs/plugin/weekday";
import { makeAutoObservable } from "mobx";
import { QuartzObject, RegexGroups } from "..";

dayjs.extend(objectSupport);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(duration);

export class CronState {
  public timeSeries: number = 0;
  public startdate: Dayjs = dayjs();
  public onlyWeeks: boolean = false;
  public quartzobject: QuartzObject;
  public diffunit: dayjs.ManipulateType = "day";
  public fittingdates: Array<Dayjs> = [];

  private _cronstring: string = "";
  private _cronstringcopied: boolean = false;
  private _selectedMonthInCalendar: number = dayjs().get("month");
  private _selectedYearInCalendar: number = dayjs().get("year");
  private _currentEnddate: Dayjs | undefined = undefined;
  private _monthlySwitcher: boolean = false;
  private _selectedWeekdays: Array<number> = [dayjs().weekday()];

  /**
   * @name CronState
   * @author Nico Vitt
   * @description The format referred to is the one by Quarts Scheduler. For further information follow these links.
   * http://www.quartz-scheduler.org/documentation/quartz-2.3.0/tutorials/crontrigger.html and
   * https://www.freeformatter.com/cron-expression-generator-quartz.html
   */
  constructor() {
    makeAutoObservable(this);

    if (typeof this.quartzobject === "undefined") {
      this.quartzobject = new QuartzObject(this.startdate);
    }
  }

  public get cronstring(): string {
    return this._cronstring;
  }

  public set cronstring(v: string) {
    this._cronstring = v;
  }

  public get cronstringcopied(): boolean {
    return this._cronstringcopied;
  }

  public set cronstringcopied(v: boolean) {
    this._cronstringcopied = v;
  }

  public get selectedMonthInCalendar(): number {
    return this._selectedMonthInCalendar;
  }

  public set selectedMonthInCalendar(v: number) {
    this._selectedMonthInCalendar = v;
  }

  public get selectedYearInCalendar(): number {
    return this._selectedYearInCalendar;
  }

  public set selectedYearInCalendar(v: number) {
    this._selectedYearInCalendar = v;
  }

  public get currentEnddate(): Dayjs | undefined {
    return this._currentEnddate;
  }

  public set currentEnddate(v: Dayjs | undefined) {
    this._currentEnddate = v;
  }

  public get monthlySwitcher(): boolean {
    return this._monthlySwitcher;
  }

  public set monthlySwitcher(v: boolean) {
    if (v) {
      this.quartzobject.dayofmonth = "?";
    } else {
      this.quartzobject.dayofweek = "?";
    }
    this._monthlySwitcher = v;
  }

  public get selectedWeekdays(): Array<number> {
    return this._selectedWeekdays;
  }

  public set selectedWeekdays(v: Array<number>) {
    this._selectedWeekdays = v;
    if (v.length === 0) {
      this.quartzobject.dayofweek = "?";
    }
  }

  /**
   * @description Provides a string representation of the cron object.
   * @returns string
   */
  objectToString(): string {
    const stringexprofcron =
      this.quartzobject.seconds +
      " " +
      this.quartzobject.minutes +
      " " +
      this.quartzobject.hours +
      " " +
      this.quartzobject.dayofmonth +
      " " +
      this.quartzobject.month +
      " " +
      this.quartzobject.dayofweek +
      " " +
      this.quartzobject.year;

    this._cronstring = stringexprofcron;
    this._cronstringcopied = false;
    return stringexprofcron;
  }

  /**
   * @description Checks whether the passed date fits the passed cron string.
   * @param date
   * @returns boolean
   */
  checkDateAgainstCronString(cronstring: string, date: Dayjs): boolean {
    this.cronStringToState(cronstring, date.get("month"));
    return this.fitsDate(date);
  }

  /**
   * @description Checks whether the passed date fits the current state (cron object).
   * @param value
   * @returns
   */
  fitsDate(value: Dayjs): boolean {
    // Only for visible dates
    if (
      this.selectedMonthInCalendar !== value.get("month") &&
      this.selectedYearInCalendar === value.get("year") &&
      // Not before start date
      !this.startdate.isBefore(dayjs(value).add(1, "day"), "day") &&
      // Not after end date
      this.currentEnddate &&
      value.isAfter(this.currentEnddate, "day")
    ) {
      return false;
    }

    // Single Date
    if (this.timeSeries === 0) {
      return value.isSame(this.startdate, "days");
    }

    //Check if value is in this.fittingsdates
    if (
      this.fittingdates.some((fittingdate) => fittingdate.isSame(value, "day"))
    ) {
      return true;
    }
    return false;
  }

  /**
   * @description Fills the state with the values from the cron string.
   * @param cronstring
   * @param date
   */
  cronStringToState(cronstring: string, month?: number): boolean {
    if (typeof month === "undefined") {
      month = dayjs().month();
    }

    const regex = new RegExp(
      // eslint-disable-next-line
      /^\s*($|#|\w+\s*=|(?<SECOND>(?:\?|\*|(?:(?<START_SEC>[0-5]?\d)(?:(?<DELIMITER_SEC>\-|\/|\,)(?<END_SEC>[0-6]?\d))*)))\s+(?<MINUTE>(?:\?|\*|(?:(?<START_MIN>[0-5]?\d)(?:(?<DELIMITER_MIN>\-|\/|\,)(?<END_MIN>[0-6]?\d))*)))\s+(?<HOUR>(?:\?|\*|(?:(?<START_HR>[0-2]?\d)(?:(?<DELIMITER_HR>\-|\/|\,)(?<END_HR>[0-2]?\d))*)))\s+(?<DAYOFMONTH>\?|\*|(?:(?<START_DOM>L|W|LW|[1-3]?\dW?)(?:(?<DELIMITER_DOM>\-|\/|\,)(?<END_DOM>[1-3]?\d?W?))*))\s+(?<MONTH>\*|(?:(?<START_MON>[1-9]|1[012]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?<DELIMITER_MON>\-|\/|\,)(?<END_MON>[1-9]|1[012]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))*))\s+(?<DAYOFWEEK>\?|\*|(?:(?<START_DOW>[1-7]|SUN|MON|TUE|WED|THU|FRI|SAT)(?:(?:(?:(?<DELIMITER_DOW>\/|\,|\#)(?<END_DOW>[1-7]|SUN|MON|TUE|WED|THU|FRI|SAT))*)L?)*))(?:|\s)+(?<YEAR>(?:\*|(?:(?<START_YR>\b(?:[1-2](?:9|0)\d\d)\b)(?:(?<DELIMITER_YR>\-|\/|\,)(?<END_YR>\b(?:[1-9]|[1-9][0-9]|1[0-8][0-9]|19[0-2])\b|\b(?:[1-2](?:9|0)\d\d)\b))*))))$/
    );

    const cronregex = cronstring.match(regex);
    if (cronregex === null) {
      return false;
    }
    const regexgroups = cronregex.groups as RegexGroups;

    //Fill the state values
    this._cronstring = cronstring;
    this._cronstringcopied = false;
    this._currentEnddate = undefined;
    this._monthlySwitcher = false;
    this._selectedMonthInCalendar = month;

    this.quartzobject.dayofmonth = regexgroups.DAYOFMONTH;
    this.quartzobject.dayofweek = regexgroups.DAYOFWEEK;
    this.quartzobject.seconds = regexgroups.SECOND;
    this.quartzobject.minutes = regexgroups.MINUTE;
    this.quartzobject.hours = regexgroups.HOUR;
    this.quartzobject.month = regexgroups.MONTH;
    this.quartzobject.year = regexgroups.YEAR;
    this.quartzobject.DELIMITER_DOM = regexgroups.DELIMITER_DOM;
    this.quartzobject.DELIMITER_DOW = regexgroups.DELIMITER_DOW;
    this.quartzobject.DELIMITER_HR = regexgroups.DELIMITER_HR;
    this.quartzobject.DELIMITER_MIN = regexgroups.DELIMITER_MIN;
    this.quartzobject.DELIMITER_MON = regexgroups.DELIMITER_MON;
    this.quartzobject.DELIMITER_SEC = regexgroups.DELIMITER_SEC;
    this.quartzobject.DELIMITER_YR = regexgroups.DELIMITER_YR;
    this.quartzobject.END_DOM = regexgroups.END_DOM;
    this.quartzobject.END_DOW = regexgroups.END_DOW;
    this.quartzobject.END_HR = regexgroups.END_HR;
    this.quartzobject.END_MIN = regexgroups.END_MIN;
    this.quartzobject.END_MON = regexgroups.END_MON;
    this.quartzobject.END_SEC = regexgroups.END_SEC;
    this.quartzobject.END_YR = regexgroups.END_YR;
    this.quartzobject.START_DOM = regexgroups.START_DOM;
    this.quartzobject.START_DOW = regexgroups.START_DOW;
    this.quartzobject.START_HR = regexgroups.START_HR;
    this.quartzobject.START_MIN = regexgroups.START_MIN;
    this.quartzobject.START_MON = regexgroups.START_MON;
    this.quartzobject.START_SEC = regexgroups.START_SEC;
    this.quartzobject.START_YR = regexgroups.START_YR;

    return true;
  }
}
