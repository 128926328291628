// import * as React from "react";
// import * as ReactDOM from "react-dom";

// import { OpenDashApp } from "..";

// export function render(app, element) {
//   ReactDOM.render(React.createElement(OpenDashApp, { app }), element);
// }

/*
Version 18
*/

import * as React from "react";
import { createRoot } from "react-dom/client";

import { OpenDashApp } from "..";

export function render(app, element) {
  const root = createRoot(element);
  const node = React.createElement(OpenDashApp, { app });

  root.render(node);
}
