import {
  $timeseries,
  DataItemDimensionIdentifierInterface,
  DataItemDimensionValueInterface,
  DataItemValueInterface,
} from "@opendash/plugin-timeseries";
import { AlarmInterface } from "../types/AlarmInterface";
export type FilterResult = "passed" | "not-passed" | "not-found";
export type Condition = AlarmInterface["condition"];

function filterRecursiv(
  filter: Condition,
  v: DataItemValueInterface | DataItemDimensionValueInterface,
  refValue: any = undefined
): boolean {
  if (!filter) return true;
  try {
    if (filter.type === "and") {
      return filter.children.every((childFilter) =>
        filterRecursiv(childFilter, v, refValue)
      );
    }

    if (filter.type === "or") {
      return filter.children.some((childFilter) =>
        filterRecursiv(childFilter, v, refValue)
      );
    }

    if (filter.type === "rule") {
      const value = Array.isArray(v.value)
        ? v.value[filter.dimension]
        : v.value;
      const stringValue = value as string;
      const numberValue = value as number;
      const booleanValue = value as boolean;

      switch (filter.rule.type) {
        case "string_equals":
          return stringValue === filter.rule.string;
        case "string_equals_not":
          return stringValue !== filter.rule.string;
        case "string_includes":
          return stringValue.includes(filter.rule.string);
        case "string_includes_not":
          return !stringValue.includes(filter.rule.string);
        case "string_starts_with":
          return stringValue.startsWith(filter.rule.string);
        case "string_starts_with_not":
          return !stringValue.startsWith(filter.rule.string);
        case "string_ends_with":
          return stringValue.endsWith(filter.rule.string);
        case "string_ends_with_not":
          return !stringValue.endsWith(filter.rule.string);
        case "boolean_true":
          return booleanValue === true;
        case "boolean_false":
          return booleanValue === false;
        case "number_equals":
          return numberValue === filter.rule.value;
        case "number_equals_not":
          return numberValue !== filter.rule.value;
        case "number_gt":
          return numberValue > filter.rule.value;
        case "number_lt":
          return numberValue < filter.rule.value;
        case "number_in_range":
          return numberValue < filter.rule.max && numberValue > filter.rule.min;
        case "number_out_of_range":
          return numberValue > filter.rule.max && numberValue < filter.rule.min;

        default:
          throw new Error("Rule not implemented.");
      }
    }

    return false;
  } catch (error) {
    return false;
  }
}

function _getAllDimensionUsedInRule(
  cond: Condition
): DataItemDimensionIdentifierInterface[] {
  if (!cond) return [];
  if (cond.type === "rule") {
    return [[cond.source, cond.id, cond.dimension]];
  }

  return [...cond.children.flatMap((c) => _getAllDimensionUsedInRule(c))];
}
function getAllDimensionUsedInRule(
  conds: Condition | Condition[]
): DataItemDimensionIdentifierInterface[] {
  if (!(conds instanceof Array)) {
    conds = [conds];
  }
  const res = conds.flatMap((cond) => _getAllDimensionUsedInRule(cond));
  const resSet = new Set(res.map((dim) => dim.join("~~~")));
  return [...resSet].map(
    (dim) =>
      dim.split("~~~").map((_, idx) => {
        if (idx === 2) return parseInt(_);
        return _;
      }) as DataItemDimensionIdentifierInterface
  );
}

function evaluateRuleLive(rule: Condition): FilterResult {
  if (!rule) return "passed";
  let items = null;
  if (rule.type === "rule") {
    try {
      items = $timeseries._listOrThrowSync();
    } catch (error) {
      console.warn("No items found when evaluating", error);
      return "not-found";
    }

    try {
      const item = items.find(
        (item) => item.id === rule.id && item.source === rule.source
      );
      if (!item) {
        return "not-found";
      }
      const value = $timeseries._getValueOrThrowSync(item);
      return filterRecursiv(rule, value) ? "passed" : "not-passed";
    } catch (error) {
      console.error("No value evaluation error", rule, error);
      return "not-passed";
    }
  } else {
    //@ts-expect-error
    const childResults = Object.groupBy(rule.children, (innerRule) => {
      return evaluateRuleLive(innerRule);
    });
    if (childResults["not-found"]) return "not-found";
    if (childResults["not-passed"]) return "not-passed";
    return "passed";
  }
}

export const RuleConditionEvaluation = {
  filterRecursiv,
  evaluateRuleLive,
  getAllDimensionUsedInRule,
};
