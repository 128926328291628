import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import {
  Alert,
  Button,
  Calendar,
  Col,
  CollapseProps,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { CalendarMode } from "antd/es/calendar/generateCalendar";
import dayjs, { Dayjs } from "dayjs";
import { runInAction } from "mobx";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  cronIterator,
  CronState,
  ScheduleCronObjectType,
  TimeSeriesDaily,
  TimeSeriesMonthlyDate,
  TimeSeriesMonthlyDay,
  TimeSeriesMonthlyInput,
  TimeSeriesWeeklyInput,
  TimeSeriesYearlyInput,
  WeekdaysSelectMultiple,
} from "..";
import { StateProvider } from "../services/StateProvider";

const ActiveCell = styled.div`
  margin: 2px;
  padding: 2px;
  background-color: #ffffff;
  white-space: nowrap;

  .ant-alert {
    margin: 0;
    padding: 0;
  }
`;

const InactiveCurrentMonthCell = styled.div`
  margin: 2px;
  background-color: #eeeeee;
  padding: 2px;
  border: 1px solid #eeeeee;
  white-space: nowrap;
`;

const InactiveCell = styled.div`
  margin: 2px;
  background-color: #ffffff;
  padding: 2px;
  border: 1px solid #eeeeee;
  white-space: nowrap;
`;

export function useEditorItems(props: {
  value: ScheduleCronObjectType;
  onChange?: (value: ScheduleCronObjectType) => void;
  showCalendar: boolean;
  showCron: boolean;
  reset: (fields: string[]) => Boolean;
}): [CronState, CollapseProps["items"]] {
  const t = useTranslation();
  const [reloadString, setReloadString] = useState<number>(0);
  const state = useMemo(() => StateProvider.getState(), []);

  // const iterator = useGenerator<Dayjs>(state, cronIterator);
  const iter = cronIterator(state);
  const [items, setItems] = useState<CollapseProps["items"]>([]);

  //#region Initial State
  useEffect(() => {
    runInAction(() => {
      if (typeof props.value !== "undefined") {
        state.startdate =
          typeof props.value.run_startdate !== "undefined"
            ? dayjs(props.value.run_startdate)
            : dayjs();
        state.onlyWeeks = props.value.is_week ?? false;

        if (typeof props.value.run_cron !== "undefined") {
          state.cronStringToState(props.value.run_cron);
        }
      }
    });
  }, []);

  useEffect(() => {
    // Recalculate the fitting dates when a value changes
    state.fittingdates = [];
    while (true) {
      const result = iter.next();
      state.fittingdates.push(result.value);
      if (result.done) {
        break;
      }
    }
    // iterator.refetch();
    // state.fittingdates = iterator.data;

    // Recalculate the crontab string when a value changes
    state.objectToString();

    if (props.onChange instanceof Function) {
      props.onChange({
        run_cron: state.cronstring,
        run_startdate: new Date(state.startdate.toISOString()),
        run_enddate:
          state.timeSeries === 0
            ? new Date(state.startdate.toISOString())
            : state.currentEnddate
              ? new Date(state.currentEnddate.toISOString())
              : undefined,
        is_week: state.onlyWeeks,
      });
    }
  }, [reloadString]);

  useEffect(() => {
    if (typeof state.quartzobject.START_DOM !== "undefined") {
      state.quartzobject.START_DOM = state.startdate.date().toString();
    }

    if (state.timeSeries === 2) {
      state.quartzobject.dayofweek = state.quartzobject.dayofweek =
        state.quartzobject.getDayOfWeekAbbreviation(
          state.selectedWeekdays,
          "string",
          undefined,
          ","
        );
    } else if (state.timeSeries === 3) {
      state.quartzobject.START_DOW =
        state.quartzobject.getDayOfWeekAbbreviation(
          state.selectedWeekdays,
          "number",
          undefined,
          ","
        );
    }

    setReloadString((curr) => curr + 1);
  }, [
    state.startdate,
    state.currentEnddate,
    state.timeSeries,
    state.monthlySwitcher,
    state.selectedWeekdays,
    state.onlyWeeks,
  ]);
  //#endregion

  function resetConfig() {
    state.quartzobject.reset();
    switch (state.timeSeries) {
      case 1:
        state.currentEnddate = state.startdate;
        props.reset(["daily", "enddate"]);
        break;
      case 2:
        state.selectedWeekdays = [dayjs().weekday()];
        props.reset(["weekly", "weekday-select"]);
        break;
      case 3:
        state.selectedWeekdays = [dayjs().weekday()];
        props.reset(["monthly-date", "monthly-day", "monthly-series"]);
        break;
      case 4:
        props.reset(["yearly"]);
        break;
      default:
        setReloadString((curr) => curr + 1);
        break;
    }
  }

  //#region OnChange Functions
  // Functions for changes in every configuration (daily, weekly, monthlyDate, monthlyDay, yearly)
  // These functions will change the cron object and the reload string to recalculate the cron string
  function dailyOnChange(v: number) {
    if (v > 1) {
      state.quartzobject.dayofmonth = state.startdate.get("date") + "/" + v;
    } else {
      state.quartzobject.dayofmonth = "?";
      state.quartzobject.dayofweek = "*";
    }
    setReloadString((curr) => curr + 1);
  }

  function weeklyOnChange(v: number) {
    // const monthrest = (v * 7) % 4;
    // const month = 1 + "/" + (Math.floor(v / 4) + 1);

    // state.quartzobject.week = state.startdate.week() + "/" + v;
    // state.quartzobject.dayofmonth = state.startdate.get("date").toString() + "/" + v * 7;
    // state.quartzobject.month = month;

    state.quartzobject.dayofweek = state.quartzobject.getDayOfWeekAbbreviation(
      state.selectedWeekdays,
      "string",
      undefined,
      ","
    );

    setReloadString((curr) => curr + 1);
  }

  function monthlyDateOnChange(v: number) {
    state.quartzobject.dayofweek = "?";
    state.quartzobject.dayofmonth = v + "";
    setReloadString((curr) => curr + 1);
  }

  function monthlyDayOnChange(v: number) {
    const weekdaystring = state.quartzobject.getDayOfWeekAbbreviation(
      state.selectedWeekdays,
      "number",
      undefined,
      ","
    );

    state.quartzobject.dayofmonth = "?";
    state.quartzobject.dayofweek = weekdaystring + "#" + (v + 1);
    setReloadString((curr) => curr + 1);
  }

  function monthlySeriesOnChange(v: number) {
    state.quartzobject.month = 1 + "/" + v;
    setReloadString((curr) => curr + 1);
  }

  function yearlyOnChange(v: any) {
    state.quartzobject.dayofweek = "?";
    state.quartzobject.dayofmonth = state.startdate.get("date").toString();
    state.quartzobject.month = state.quartzobject.getMonthAbbreviation(
      [state.startdate.month()],
      "string",
      undefined,
      ","
    );

    let years = "";
    if (state.currentEnddate && v > 1) {
      const yearDiff = state.currentEnddate.diff(state.startdate, "years");
      let i = 0;
      while (i < yearDiff) {
        if (i % v === 0) {
          years = years.concat(
            dayjs(state.startdate).add(i, "year").get("year").toString()
          );
          if (i + v < yearDiff) {
            years = years.concat(",");
          }
        }
        i++;
      }
    } else if (state.currentEnddate) {
      years = `${state.startdate.get("year")}-${state.currentEnddate.get(
        "year"
      )}`;
    } else {
      years = `${state.startdate.get("year").toString()}/${v}`;
    }
    state.quartzobject.year = years;
    setReloadString((curr) => curr + 1);
  }
  //#endregion

  function getTimeSeries() {
    switch (state.timeSeries) {
      case 0:
        props.reset([]);
        return null;
      case 1:
        return (
          <Form.Item
            name="daily"
            initialValue={state.quartzobject.getInitialFormValue("daily")}
          >
            <TimeSeriesDaily onChange={dailyOnChange} />
          </Form.Item>
        );
      case 2:
        return (
          <>
            <Form.Item
              name="weekday-select"
              initialValue={state.quartzobject.getInitialFormValue(
                "weekday-select"
              )}
            >
              <WeekdaysSelectMultiple
                onChange={(v) => (state.selectedWeekdays = v)}
              />
            </Form.Item>
            <Form.Item
              name="weekly"
              initialValue={state.quartzobject.getInitialFormValue("daily")}
            >
              <TimeSeriesWeeklyInput onChange={weeklyOnChange} />
            </Form.Item>
          </>
        );
      case 3:
        return (
          <>
            <Row gutter={[16, 16]} align="middle">
              <Col span={10}>
                <Form.Item
                  name="monthly-date"
                  initialValue={state.quartzobject.getInitialFormValue(
                    "monthly-date"
                  )}
                >
                  <TimeSeriesMonthlyDate onChange={monthlyDateOnChange} />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "center" }}>
                <Switch
                  size="default"
                  style={{ marginBottom: 24 }}
                  onChange={(v) => (state.monthlySwitcher = v)}
                  checkedChildren={t(
                    "maintenance:schedule.create.form.seriespattern.monthly.checkedChildren"
                  )}
                  unCheckedChildren={t(
                    "maintenance:schedule.create.form.seriespattern.monthly.uncheckedChildren"
                  )}
                />
              </Col>
              <Col span={10}>
                <Row justify="space-between" align="middle">
                  <Col span={11}>
                    <Form.Item
                      name="monthly-day"
                      initialValue={state.quartzobject.getInitialFormValue(
                        "monthly-day"
                      )}
                    >
                      <TimeSeriesMonthlyDay onChange={monthlyDayOnChange} />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="weekday-select"
                      initialValue={state.quartzobject.getInitialFormValue(
                        "weekday-select"
                      )}
                    >
                      <WeekdaysSelectMultiple
                        onChange={(v) => (state.selectedWeekdays = v)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Form.Item
              name="monthly-series"
              initialValue={state.quartzobject.getInitialFormValue(
                "monthly-series"
              )}
            >
              <TimeSeriesMonthlyInput onChange={monthlySeriesOnChange} />
            </Form.Item>
          </>
        );
      case 4:
        return (
          <Form.Item
            name="yearly"
            initialValue={state.quartzobject.getInitialFormValue("yearly")}
          >
            <TimeSeriesYearlyInput onChange={yearlyOnChange} />
          </Form.Item>
        );
      default:
        state.quartzobject.reset();
        setReloadString((curr) => curr + 1);
        break;
    }
  }

  useEffect(() => {
    const collapseitems: CollapseProps["items"] = [
      {
        key: 1,
        label: t("maintenance:schedule.create.form.collapse.configuration"),
        children:
          state.timeSeries !== 0 ? (
            getTimeSeries()
          ) : (
            <p>
              {t("maintenance:schedule.create.form.collapse.noconfiguration")}
            </p>
          ),
        extra: (
          <Tooltip
            title={t(
              "maintenance:schedule.create.form.seriespattern.button.reset"
            )}
          >
            <Button
              icon={<Icon icon="fa:undo" />}
              type="text"
              onClick={(event) => {
                event.stopPropagation();
                resetConfig();
              }}
            ></Button>
          </Tooltip>
        ),
      },
    ];

    if (props.showCalendar) {
      collapseitems.push({
        key: 2,
        label: t("maintenance:schedule.create.form.collapse.calendar"),
        children: (
          <Calendar
            fullCellRender={(value: Dayjs) => {
              if (state.fitsDate(value)) {
                return (
                  <ActiveCell>
                    <Alert type="success" message={value.format("DD.MM.")} />
                  </ActiveCell>
                );
              }

              if (value.get("month") === state.selectedMonthInCalendar) {
                return <InactiveCell>{value.format("DD.MM.")}</InactiveCell>;
              }

              return (
                <InactiveCurrentMonthCell>
                  {value.format("DD.MM.")}
                </InactiveCurrentMonthCell>
              );
            }}
            validRange={[
              dayjs({
                second: 0,
                minute: 0,
                hour: 0,
                day: 1,
                month: 0,
                year: 1970,
              }),
              //This is just the maximum date that can be displayed in the calendar
              //Converted from unix time
              dayjs(new Date(8640000000000000)),
            ]}
            onPanelChange={(value: Dayjs, mode: CalendarMode) => {
              state.selectedMonthInCalendar = value.get("month");
              state.selectedYearInCalendar = value.get("year");
              setReloadString((curr) => curr + 1);
            }}
            fullscreen={false}
          />
        ),
      });
    }

    if (props.showCron) {
      collapseitems.push({
        key: 3,
        label: t("maintenance:schedule.create.form.collapse.cron"),
        children: (
          <Row align={"middle"} justify={"center"}>
            <Col>
              <Space.Compact>
                <Input
                  addonBefore={<Icon icon="fa:clock" />}
                  value={state.cronstring}
                  disabled
                />
              </Space.Compact>
            </Col>
            <Col>
              <Tooltip title={state.cronstringcopied ? "Kopiert" : "Kopieren"}>
                <Button
                  icon={<Icon icon="fa:copy" />}
                  onClick={() => {
                    navigator.clipboard.writeText(state.cronstring);
                    state.cronstringcopied = true;
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        ),
      });
    }

    setItems(collapseitems);
  }, [reloadString]);

  return [state, items];
}
