import { useTranslation } from "@opendash/core";
import { Select, Tag } from "antd";
import React from "react";

export interface ArraySelectDisplayProps {
  value: string[];
  options: [any, string][];
}

export const ArraySelectDisplay = React.memo<ArraySelectDisplayProps>(
  function ArraySelectDisplay({ value, options }) {
    const t = useTranslation();

    const labels = Object.fromEntries(options);

    return (
      <>
        {value.map((v, i) => (
          <Tag key={`${i}-${v}`}>{t(labels[v])}</Tag>
        ))}
      </>
    );
  }
);

export interface ArraySelectEditProps {
  value: string[];
  setValue?: (v: string[]) => void;
  options: [any, string][];
  isRequired: boolean;
  isNullable: boolean;
}

export const ArraySelectEdit = React.memo<ArraySelectEditProps>(
  function ArraySelectEdit({ value, setValue, options }) {
    const t = useTranslation();

    return (
      <Select
        mode="multiple"
        allowClear
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
      >
        {options.map(([value, label], i) => {
          return (
            <Select.Option key={`${i}-${value}`} value={value}>
              {t(label)}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
);
