import { Icon } from "@opendash/icons";
import { useNavigate } from "@opendash/router";
import { HeaderMenuItem as MenuItem } from "@opendash/ui";
import { Badge, Dropdown, MenuProps, Spin } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";
import {
  OpenDashLogo,
  useCurrentUser,
  useOpenDashApp,
  useOpenDashServices,
  useTranslation,
} from "../..";
import { useNotifications } from "../../features/notifications";
import { Hamburger } from "./Hamburger";
import { HeaderSourcePicker, breakpoint } from "./HeaderSourcePicker";
import * as Layout from "./components";

const MainMenu = styled.div`
  position: relative;
  display: flex;
  /* padding: 0 24px; */
  padding: 0;
  height: var(--opendash-header-row-height);
`;

const MainMenuLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const MainMenuRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MenuRef = styled.div`
  float: left;
  height: var(--opendash-header-row-height);
  line-height: var(--opendash-header-row-height);
`;

export const HeaderNavigation = observer(function HeaderNavigation() {
  const user = useCurrentUser();
  const app = useOpenDashApp();
  const t = useTranslation();
  const navigate = useNavigate();
  const services = useOpenDashServices();
  const [, , unreadNotificationsCount] = useNotifications();
  let menuItems: MenuProps["items"] = [];

  if (user) {
    menuItems = [
      {
        key: "1",
        onClick: () => {},
        label: (
          <b>
            {t("opendash:auth.greeting_dropdown") + " " + user.name ||
              user.username ||
              user.email}
          </b>
        ),
        style: { padding: "12px 24px" },
      },
      {
        key: "2",
        onClick: () => app.ui2.showNotificationsDialog(),
        label: t("opendash:notification.label"),
        icon:
          unreadNotificationsCount > 0 ? (
            <Badge
              showZero={false}
              size="small"
              overflowCount={99}
              count={unreadNotificationsCount}
            />
          ) : (
            <Icon icon="fa:comment" />
          ),
        style: { padding: "12px 24px" },
      },
      {
        key: "3",
        onClick: () => app.ui2.showLanguageDialog(),
        label: t("opendash:account.language.label"),
        icon: <Icon icon="fa:globe" />,
        disabled: app.ui2.languageDialogDisabled,
        style: { padding: "12px 24px" },
      },
      {
        key: "4",
        onClick: () => app.ui2.showResetPWDialog(),
        label: t("opendash:account.resetpw.label"),
        icon: <Icon icon="fa:lock" />,
        style: { padding: "12px 24px" },
      },
      {
        key: "5",
        onClick: () => services.UserService.logout(),
        label: t("opendash:auth.logout"),
        icon: <Icon icon="fa:sign-out" />,
        style: { padding: "12px 24px" },
      },
    ];
  } else {
    menuItems = [
      { key: "1", icon: <Spin />, onClick: () => navigate("/auth/login") },
    ];
  }

  return (
    <Layout.HeaderRow>
      <MainMenu>
        {user && (
          <MainMenuLeft>
            <MenuRef
              key="headerBeforeMenuLeft"
              ref={app._refs.headerBeforeMenuLeft}
            />
            <MenuRef key="headerAfterLogo1" ref={app._refs.headerAfterLogo} />

            {app.ui2.sidebar.available && (
              <MenuItem
                key="apps"
                onClick={() => {
                  app.ui2.updateSidebar({ open: !app.ui2.sidebar.open });
                }}
              >
                <Hamburger
                  open={app.ui2.sidebar.open}
                  setOpen={(isOpen) => {}}
                />
              </MenuItem>
            )}

            <MenuRef key="headerBeforeLogo" ref={app._refs.headerBeforeLogo} />
            <OpenDashLogo
              style={{
                display: "block",
                height: "100%",
                width: "auto",
                padding: "8px",
              }}
            />
            <MenuRef key="headerAfterLogo2" ref={app._refs.headerBeforeLogo} />
          </MainMenuLeft>
        )}

        {user && (
          <MainMenuRight>
            {!app.ui.disableHeaderSourcePicker &&
              app.ui2.headerWidth >= breakpoint && (
                <MenuItem key="source">
                  <HeaderSourcePicker
                    mobile={false}
                    style={{ width: "20vw" }}
                  />
                </MenuItem>
              )}
            <MenuRef
              key="headerAfterMenuLeft"
              ref={app._refs.headerAfterMenuLeft}
            />
            <MenuRef
              key="headerBeforeMenuRight"
              ref={app._refs.headerBeforeMenuRight}
            />
            <Dropdown
              open={app.ui2.userDialogOpen}
              onOpenChange={(open) => {
                if (open) {
                  app.ui2.showUserDialog();
                } else {
                  app.ui2.hideUserDialog();
                }
              }}
              trigger={["click"]}
              placement="bottomRight"
              menu={{
                items: menuItems,
              }}
              children={
                <MenuItem>
                  <Badge
                    showZero={false}
                    size="small"
                    overflowCount={99}
                    count={unreadNotificationsCount}
                  >
                    <Icon icon="fa:user" />
                  </Badge>
                </MenuItem>
              }
            />
            <MenuRef
              key="headerAfterMenuRight"
              ref={app._refs.headerAfterMenuRight}
            />
          </MainMenuRight>
        )}
      </MainMenu>
    </Layout.HeaderRow>
  );
});
