import * as React from "react";

import { AntDesignProvider, OpenDashContext } from "..";

type NestedProvidersProps = React.PropsWithChildren<{
  providers: [React.ComponentType, any][];
}>;

const NestedProviders = React.memo<NestedProvidersProps>(
  function NestedProviders({ providers, children }) {
    if (providers.length === 0) {
      return <>{children}</>;
    }

    const [Provider, props] = providers[0];

    return (
      <Provider {...props}>
        <NestedProviders providers={providers.slice(1)} children={children} />
      </Provider>
    );
  }
);

export function OpenDashProvider({ app, children }) {
  const providers = app._internal.providerComponents;

  return (
    <OpenDashContext.Provider value={app}>
      <AntDesignProvider>
        <NestedProviders providers={providers} children={children} />
      </AntDesignProvider>
    </OpenDashContext.Provider>
  );
}
