// from https://github.com/kentcdodds/use-deep-compare-effect

import * as React from "react";

import { useDeepCompareMemoize } from "..";

export function useDeepCompareMemo<T>(
  factory: () => T,
  dependencies: React.DependencyList
) {
  // type cast to prevent issue with new @types/react version
  return React.useMemo(
    factory as () => Exclude<T, void>,
    useDeepCompareMemoize(dependencies)
  );
}
