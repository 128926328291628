import { $framework } from "@opendash/core";
import { useTranslation } from "@opendash/i18n";
import { createComponent } from "@opendash/state";
import { Button, Form, Input, Typography } from "antd";
import React from "react";
import { $parse, AdapterOptions } from "../..";
import { AuthLayout } from "./AuthLayout";

export const AuthLoginRoute = createComponent(function AuthLoginRoute(
  cfg: AdapterOptions
) {
  const t = useTranslation();

  return (
    <AuthLayout width={400}>
      <Form
        layout="vertical"
        initialValues={$parse.auth.loginDefaults}
        requiredMark={false}
        onFinish={(data) => {
          $parse.auth.login(data);
        }}
      >
        <Typography.Title
          level={3}
          children={t("parse-admin:auth.login_title")}
        />
        {/* 
        <Typography.Paragraph
          children={t("parse-admin:auth.login_description")}
        /> */}

        <Form.Item
          name="login"
          label={
            $parse.server.config.AUTH_ALLOW_USERNAME
              ? t("parse-admin:auth.field_login_label")
              : t("parse-admin:auth.field_email_label")
          }
          required
          rules={[
            {
              required: true,
              message: $parse.server.config.AUTH_ALLOW_USERNAME
                ? t("parse-admin:auth.field_login_validation_required")
                : t("parse-admin:auth.field_email_validation_required"),
            },
          ]}
        >
          <Input
            placeholder={
              $parse.server.config.AUTH_ALLOW_USERNAME
                ? t("parse-admin:auth.field_login_placeholder")
                : t("parse-admin:auth.field_email_placeholder")
            }
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={t("parse-admin:auth.field_password_label")}
          required
          rules={[
            {
              required: true,
              message: t("parse-admin:auth.field_password_validation_required"),
            },
          ]}
        >
          <Input.Password
            placeholder={t("parse-admin:auth.field_password_placeholder")}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            children={t("parse-admin:auth.action_submit")}
          />
        </Form.Item>

        {(cfg.createAccount || cfg.lostPassword) && (
          <Form.Item>
            {cfg.createAccount && (
              <Button
                type="link"
                style={{ width: "100%" }}
                children={t("parse-admin:auth.action_goto_signup_from_login")}
                onClick={() => {
                  $framework.router.navigate("/auth/signup", {
                    keepQueryParams: true,
                  });
                }}
              />
            )}
            {cfg.lostPassword && (
              <Button
                type="link"
                style={{ width: "100%" }}
                children={t(
                  "parse-admin:auth.action_goto_request_password_reset"
                )}
                onClick={() => {
                  $framework.router.navigate(
                    "/auth/account/password-reset-request"
                  );
                }}
              />
            )}
          </Form.Item>
        )}
      </Form>
    </AuthLayout>
  );
});
