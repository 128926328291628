import { Form, Select } from "antd";
import React from "react";
import { useMappedParseFields } from "../hooks/useMappedParseFields";

type Props = {};
export default (props: Props) => {
  const fields = useMappedParseFields(true);

  return (
    <Form.Item name="keys" label="Datenfelder der Abfrage wählen">
      <Select
        mode="multiple"
        options={fields.map((f) => ({
          label: f.field,
          value: f.field,
          type: f.type,
        }))}
      />
    </Form.Item>
  );
};
