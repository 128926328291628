import {
  createInternalComponent,
  produce,
  useTranslation,
  useUserService,
} from "@opendash/core";
import {
  DataItemDimensionIdentifierInterface,
  DataItemInterface,
  useDataService,
} from "@opendash/plugin-timeseries";
import { Input, InputNumber, Select } from "antd";
import * as React from "react";
import { AlarmInterface, useMonitoringService } from "..";

const WIDTH_100 = { width: "100%" };

type Condition = AlarmInterface["condition"];

interface Props {
  value?: Condition;
  onChange(value: Condition): void;
  triggerItem: DataItemDimensionIdentifierInterface;
  customItems?(
    condition: Condition,
    items: DataItemInterface[]
  ): { label: string; value: any }[];
}

export const AlarmConditionRule = createInternalComponent<Props>(
  function AlarmConditionRule({
    value: currentCondition,
    onChange,
    triggerItem: [source],
    customItems,
  }) {
    if (currentCondition.type !== "rule") {
      throw new Error("AlarmConditionRule bad value");
    }

    const t = useTranslation();
    const [filter, setFilter] = React.useState("");
    const DataService = useDataService();
    const MonitoringService = useMonitoringService();
    const UserService = useUserService();

    const item = DataService._getOrUndefinedSync(
      currentCondition.source,
      currentCondition.id
    );

    //TODO: Test why this was filtering items
    const items = DataService._listOrThrowSync();
    // .filter(
    //   (item) => item.source === source
    // );

    const type = item?.valueTypes[currentCondition.dimension].type;

    const alarmRuleOptions = React.useMemo(
      () =>
        MonitoringService.alarmRuleTypes
          .filter((option) => option.value.startsWith(type?.toLowerCase()))
          .map((option) => {
            return {
              ...option,
              label: t(option.label),
            };
          }),
      [type]
    );
    console.log({ alarmRuleOptions, item, type, currentCondition });
    React.useEffect(() => {
      if (!type) {
        onChange(
          produce(currentCondition, (draft) => {
            draft.rule = undefined;
          })
        );
      }

      if (!currentCondition.rule?.type?.startsWith(type.toLowerCase())) {
        onChange(
          produce(currentCondition, (draft) => {
            console.log({ alarmRuleOptions, currentCondition });
            draft.rule = {
              // @ts-ignore

              type: alarmRuleOptions[0].value,
            };
          })
        );
      }
    }, [type, currentCondition.rule?.type]);

    return (
      <>
        <label>
          {t("opendash:monitoring.alarms.condition.select_item_placeholder")}

          <Select
            value={DataService.keyForIdentifier([
              currentCondition.source,
              currentCondition.id,
              currentCondition.dimension,
            ])}
            onChange={(v) => {
              const [source, id, dimension] = JSON.parse(v);

              onChange(
                produce(currentCondition, (draft) => {
                  draft.source = source;
                  draft.id = id;
                  draft.dimension = dimension;
                })
              );
            }}
            options={
              customItems
                ? customItems(currentCondition, items)
                : items.flatMap((item) => {
                    return item.valueTypes.map((vt, dimension) => {
                      return {
                        label: DataService.getItemName(item, dimension),
                        value: DataService.keyForIdentifier([
                          item.source,
                          item.id,
                          dimension,
                        ]),
                      };
                    });
                  })
            }
            style={WIDTH_100}
            showSearch={true}
            onSearch={(str) => {
              setFilter(str.toLocaleLowerCase());
            }}
            filterOption={(input, option) => {
              return (
                option.label
                  .toLocaleLowerCase()
                  .indexOf(input.toLocaleLowerCase()) > -1
              );
            }}
          />
        </label>

        <label>
          {t("opendash:data.trigger.select_type_placeholder")}

          <Select
            value={currentCondition.rule?.type}
            onChange={(v) => {
              onChange(
                produce(currentCondition, (draft) => {
                  draft.rule.type = v;
                })
              );
            }}
            options={alarmRuleOptions}
            style={WIDTH_100}
          />
        </label>

        {(currentCondition.rule.type === "number_equals" ||
          currentCondition.rule.type === "number_equals_not" ||
          currentCondition.rule.type === "number_gt" ||
          currentCondition.rule.type === "number_lt") && (
          <label>
            {t("opendash:data.trigger.select_value_placeholder")}

            <InputNumber
              value={currentCondition.rule.value}
              onChange={(v) => {
                onChange(
                  produce(currentCondition, (draft) => {
                    // @ts-ignore
                    draft.rule.value = v;
                  })
                );
              }}
              style={WIDTH_100}
            />
          </label>
        )}

        {(currentCondition.rule.type === "number_in_range" ||
          currentCondition.rule.type === "number_out_of_range") && (
          <label>
            {t("opendash:data.trigger.select_min_placeholder")}

            <InputNumber
              value={currentCondition.rule.min}
              onChange={(v) => {
                onChange(
                  produce(currentCondition, (draft) => {
                    // @ts-ignore
                    draft.rule.min = v;
                  })
                );
              }}
              style={WIDTH_100}
            />
          </label>
        )}

        {(currentCondition.rule.type === "number_in_range" ||
          currentCondition.rule.type === "number_out_of_range") && (
          <label>
            {t("opendash:data.trigger.select_max_placeholder")}

            <InputNumber
              value={currentCondition.rule.max}
              onChange={(v) => {
                onChange(
                  produce(currentCondition, (draft) => {
                    // @ts-ignore
                    draft.rule.max = v;
                  })
                );
              }}
              style={WIDTH_100}
            />
          </label>
        )}

        {(currentCondition.rule.type === "string_equals" ||
          currentCondition.rule.type === "string_equals_not" ||
          currentCondition.rule.type === "string_includes" ||
          currentCondition.rule.type === "string_includes_not" ||
          currentCondition.rule.type === "string_starts_with" ||
          currentCondition.rule.type === "string_starts_with_not" ||
          currentCondition.rule.type === "string_ends_with" ||
          currentCondition.rule.type === "string_ends_with_not") && (
          <label>
            {t("opendash:data.trigger.select_string_placeholder")}

            <Input
              value={currentCondition.rule.string}
              onChange={(e) => {
                const v = e.target.value;

                onChange(
                  produce(currentCondition, (draft) => {
                    // @ts-ignore
                    draft.rule.string = v;
                  })
                );
              }}
              style={WIDTH_100}
            />
          </label>
        )}
      </>
    );
  }
);
