import Parse from "parse";

import { makeAutoObservable } from "@opendash/state";

export class HelperService {
  constructor() {
    makeAutoObservable(this);
  }

  convertParsePolygon(polygon: Parse.Polygon) {
    const coordinates = polygon.toJSON().coordinates;

    return coordinates.map(([lat, long]) => [long, lat]);
  }
}
