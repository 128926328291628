import { Icon } from "@opendash/icons";
import { CustomParseFieldProps } from "@opendash/plugin-parse";
import { Button, Drawer, Tag } from "antd";
import React from "react";
import { DataHierarchy } from "../types";
import HierachyBuilder from "./HierachyBuilder";

const HierarchyParseField = ({
  parseObject,
  value,
  fieldname,
  props,
  setValue,
}: CustomParseFieldProps) => {
  const [modalItem, setModalItem] = React.useState(null);
  if (!setValue) {
    console.log({ value });
    if (value.length === 0) {
      return <Icon icon="fa:empty-set" />;
    }
    return (value as Array<DataHierarchy>).map((item) => {
      return <Tag>{item.label}</Tag>;
    });
  }
  return (
    <>
      <Button
        onClick={() => {
          setModalItem(value);
        }}
        icon={<Icon icon="fa:edit" />}
      />

      {modalItem && (
        <Drawer
          open={!!modalItem}
          placement="bottom"
          height="60%"
          title={parseObject.get("name") || "No Template"}
          onClose={() => {
            setModalItem(null);
          }}
        >
          <HierachyBuilder value={value} setValue={setValue} readonly={false} />
        </Drawer>
      )}
    </>
  );
};
export { HierarchyParseField };
