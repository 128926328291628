import React, { useMemo } from "react";
import styled from "styled-components";
import { Icon } from "@opendash/icons";

import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";

import { Alert, Button } from "antd";

import { useTranslation, copy } from "@opendash/core";

import GreisTransponderTable from "./GreisTransponderTable";
import GreisTransponderImport from "./GreisTransponderImport";
import GreisTransponderExport from "./GreisTransponderExport";
import GreisTransponderAdd from "./GreisTransponderAdd";

const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;

  > button {
    margin-left: 8px;
  }
`;

export default function GreisTransponderConfig({
  spsid,
  transponderConfig,
  admin,
}) {
  const { result, loading, error } = transponderConfig;

  const t = useTranslation();
  const [transponders, setTransponders] = React.useState([]);
  const [importModal, setImportModal] = React.useState(false);
  const [exportModal, setExportModal] = React.useState(false);
  const [addModal, setAddModal] = React.useState(false);

  React.useEffect(() => {
    if (result) {
      setTransponders(copy(result.get("cardIDs")));
    } else {
      setTransponders([]);
    }
  }, [result]);

  const changes = useMemo(() => {
    let count = 0;
    let countNew = 0;
    let countDeleted = 0;

    for (const t of transponders) {
      if (t._deleted) {
        count++;
        countDeleted++;
        continue;
      }

      const oldVersion = result.get("cardIDs").find((r) => r.id === t.id);

      if (!oldVersion) {
        count++;
        countNew++;
        continue;
      }

      const diff = JSON.stringify(t) !== JSON.stringify(oldVersion);

      if (diff) {
        count++;
      }
    }

    return { count, countNew, countDeleted };
  }, [transponders]);

  const saveTransponders = () => {
    const toSave = transponders
      .filter((x) => !x._deleted)
      .map((x) => ({
        id: x.id,
        misc: x.misc,
        misc2: x.misc2,
        owner: x.owner,
        active: x.active,
      }));

    result
      .set("cardIDs", toSave)
      .save()
      .then(() => {
        setTransponders(copy(result.get("cardIDs")));
      });
  };

  const addTransponders = (arr) => {
    setTransponders([...transponders, ...arr]);
  };

  const overwriteTransponders = (arr) => {
    setTransponders([...arr]);
  };

  const updateTransponder = (id, key, value) => {
    setTransponders(
      transponders.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [key]: value,
          };
        }

        return row;
      })
    );
  };

  if (!spsid) {
    return (
      <Alert
        type="info"
        message={t("greis:transponders.admin.select_location_first")}
      />
    );
  }

  if (!transponderConfig.loading && !transponderConfig.result) {
    return (
      <Alert
        type="error"
        message={t("greis:transponders.admin.location_not_supported")}
      />
    );
  }

  const getAction = (isAdmin) => {
    if (isAdmin) {
      return [
        <Button
          type="primary"
          disabled={changes.count === 0}
          onClick={(e) => {
            saveTransponders();
          }}
          children={t("greis:transponders.admin.save")}
        />,

        <Button
          icon={<Icon icon="fa:download" />}
          disabled={loading}
          onClick={(e) => {
            setImportModal(true);
          }}
          children={t("greis:transponders.admin.import_button")}
        />,
        <Button
          icon={<Icon icon="fa:download" />}
          disabled={loading}
          onClick={(e) => {
            setExportModal(true);
          }}
          children={t("greis:transponders.admin.export_button")}
        />,
        <Button
          icon={<Icon icon="fa:plus" />}
          disabled={loading}
          onClick={(e) => {
            setAddModal(true);
          }}
          children={t("greis:transponders.admin.add_button")}
        />,
      ];
    } else {
      return [
        <Button
          type="primary"
          disabled={changes.count === 0}
          onClick={(e) => {
            saveTransponders();
          }}
          children={t("greis:transponders.admin.save")}
        />,
      ];
    }
  };

  return (
    <AdminLayout>
      <AdminToolbar
        title={t("greis:transponders.admin.select_location")}
        description={t("greis:transponders.admin.trans_description")}
        actions={getAction(admin)}
      />
      <div className="od-page-main">
        {/* 
    <LinkedSourcePicker
      style={{
        width: "100%",
      }}
    
        <ButtonGroup>
          <Button
            type="primary"
            disabled={changes.count === 0}
            onClick={(e) => {
              saveTransponders();
            }}
            children={t("greis:transponders.admin.save")}
          />
          {admin && (
            <Button
              // type="primary"
              //icon={<UploadOutlined />}
              disabled={loading}
              onClick={(e) => {
                setImportModal(true);
              }}
              children={t("greis:transponders.admin.import_button")}
            />
          )}
          {admin && (
            <Button
              // type="primary"
              //icon={<DownloadOutlined />}
              disabled={loading}
              onClick={(e) => {
                setExportModal(true);
              }}
              children={t("greis:transponders.admin.export_button")}
            />
          )}
          {admin && (
            <Button
              // type="primary"
              //icon={<PlusSquareOutlined />}
              disabled={loading}
              onClick={(e) => {
                setAddModal(true);
              }}
              children={t("greis:transponders.admin.add_button")}
            />
          )}
        </ButtonGroup>/>*/}
        {changes.count > 0 && (
          <Alert
            type="info"
            message={t("greis:transponders.admin.changes", changes)}
            style={{ marginBottom: 24 }}
          />
        )}

        <GreisTransponderImport
          open={importModal}
          close={() => {
            setImportModal(false);
          }}
          addTransponders={addTransponders}
          overwriteTransponders={overwriteTransponders}
        />

        <GreisTransponderExport
          open={exportModal}
          close={() => {
            setExportModal(false);
          }}
          transponders={transponders}
        />

        <GreisTransponderAdd
          open={addModal}
          close={() => {
            setAddModal(false);
          }}
          addTransponders={addTransponders}
        />

        <GreisTransponderTable
          {...{ transponders, updateTransponder, loading, admin }}
        />
      </div>
    </AdminLayout>
  );
}
