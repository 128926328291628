import {
  createInternalComponent,
  useServiceStore,
  useTranslation,
} from "@opendash/core";
import { Button } from "antd";
import * as React from "react";
import {
  WidgetComponentRender,
  WidgetConfigInterface,
  WidgetErrorBoundary,
  WidgetSettingsRender,
  useWidgetBaseContextDraftSetup,
  useWidgetContextSetup,
} from "..";

interface Props {
  type: string;
  config: WidgetConfigInterface<any>;
  edit?: boolean;
  openSettings?: () => Promise<void>;
  style?: React.CSSProperties;
  onSave?: (config: any) => void;
  onChange?: (config: any) => void;
}

export const WidgetStatic = createInternalComponent<Props>(
  function WidgetStatic({
    type,
    config,
    edit = false,
    openSettings,
    style = {},
    onChange,
    onSave,
  }) {
    const t = useTranslation();
    const widgetBaseContext = useWidgetBaseContextDraftSetup(type, config);
    const widgetContext = useWidgetContextSetup(widgetBaseContext);

    widgetBaseContext.openSettings = openSettings;
    const currentDraft = useServiceStore(
      widgetContext.context,
      (state) => state.draft
    );

    const unsubscribe = widgetBaseContext.store.subscribeSelection(
      (state) => {
        return [state.draft, state.config];
      },
      (newDraft) => {
        if (onChange) {
          onChange(newDraft);
        }
      }
    );

    React.useEffect(() => {
      return () => {
        unsubscribe();
      };
    }, []);
    return (
      <div
        ref={widgetBaseContext.containerRef}
        style={{ height: "100%", width: "100%", ...style }}
      >
        <WidgetErrorBoundary context={widgetBaseContext}>
          {!edit && (
            <WidgetComponentRender
              key={`static-widget-${type}`}
              baseContext={widgetBaseContext}
            />
          )}
        </WidgetErrorBoundary>
        {edit && (
          <div
            style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          >
            {onSave && (
              <Button
                style={{ alignSelf: "end" }}
                type="default"
                disabled={!widgetContext.unsaved}
                onClick={() => {
                  onSave(currentDraft);
                  widgetContext.saveDraft();
                }}
              >
                {t("opendash:ui.save")}
              </Button>
            )}
            <WidgetSettingsRender context={widgetContext} />
          </div>
        )}
      </div>
    );
  }
);
