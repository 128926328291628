import { List } from "antd";
import React from "react";

import { Link } from "@opendash/router";

import { Icon } from "@opendash/icons";
import { createComponent } from "@opendash/state";

import { $parse } from "../../state";
import type { ParseFilterState } from "../../state/ParseFilterState";
import { ListViewType } from "../../types";
import { ParseFields } from "../ParseFields";

interface Props {
  state: ParseFilterState;
  view: ListViewType;
}

export const ListView = createComponent<Props>(function ListView({
  state,
  view,
}) {
  return (
    <List
      rowKey={(row) => row.id}
      dataSource={state.data.result}
      loading={state.data.loading}
      pagination={state.paginationConfig}
      renderItem={(object) => {
        const link =
          typeof view.onClick === "function"
            ? view.onClick(object)
            : `/admin/parse/${state.className}/${object.id}`;

        return (
          <Link
            to={link}
            onClick={(e) => {
              if (view.onClick === "modal") {
                e.preventDefault();

                $parse.ui.openObject(object);
              }
            }}
          >
            <List.Item actions={[<Icon key="icon" icon="fa:chevron-right" />]}>
              <List.Item.Meta
                title={
                  <ParseFields
                    object={object}
                    fields={view.titleFields || state.config.titleFields}
                    isInline
                  />
                }
                description={
                  <ParseFields
                    object={object}
                    fields={
                      view.descriptionFields || state.config.displayFields
                    }
                    isInline
                  />
                }
                // avatar={
                //   view.iconField && (
                //     <ParseField
                //       key={view.iconField}
                //       object={row}
                //       field={view.iconField}
                //       isInline
                //     />
                //   )
                // }
              />
            </List.Item>
          </Link>
        );
      }}
    />
  );
});
