import {
  produce,
  useFeedback,
  useLocalStorage,
  useTranslation,
} from "@opendash/core";
import {
  DataItemDimensionIdentifierInterface,
  DataItemIdentifierInterface,
  DataItemInterface,
  DataSelect,
  useDataService,
} from "@opendash/plugin-timeseries";
import { Description } from "@opendash/ui";
import { Button, Divider, Drawer, Input, Steps } from "antd";
import cronstrue from "cronstrue/i18n";
import React, { useEffect, useState } from "react";
import { Cron } from "react-js-cron";
import styled from "styled-components";
import { getRequestServer } from "../DataCollectionOverview";
import { AggregationModalPropsInterface } from "./AggregationModalPropsInterface";
import { StageInterface } from "./AggregationSettingsComponentType";
import AggregationService from "./Aggregations";
import GermanLocale from "./CronLocaleDE";
import DataItemEditComponent from "./DataItemEditComponent";

const Container = styled.div`
   {
    margin-left: auto;
  }
  & .react-js-cron {
     {
      text-align: center;
    }

    & > div {
      display: block;
      margin: 10px;
    }
    & .react-js-cron-field {
      margin: 10px 5px;
      display: inline;
      & .ant-select {
        margin: 0px 2px;
        width: 200px;
      }
    }
    & .react-js-cron-period {
      display: block;
      margin-bottom: 10px;
    }
  }
`;

export default (props: AggregationModalPropsInterface) => {
  const { message } = useFeedback();
  const [operation, setOperation] = useState(props.operation || "sum");
  const [interval, setInterval] = useState(props.interval || "0 * * * * ? *");
  const [errorState, setErrorState] = useState(false);
  const [dimension, setDimension] = useState(null);
  const [firstOperation, setFirstOperation] = useState("source_aggregation");
  const [stage, setStage] = useState<StageInterface>(null);
  const [step, setStep] = useState(0);
  const [templateItem, setTemplateItem] = useState<DataItemInterface>(null);
  const [selectedItems, setSelectedItems] = useState<
    DataItemIdentifierInterface[] | DataItemDimensionIdentifierInterface[]
  >(props.selection || []);
  const [aggregationName, setAggregationName] = useState<string>("");
  const t = useTranslation();
  const DataService = useDataService();
  const localeString =
    (useLocalStorage("opendash:language")[0] as string) || "de";
  const aggregationOp = AggregationService.getAggregations(firstOperation);
  const { settingsComponent: Settings, items } = aggregationOp;
  const requestServer = getRequestServer();
  const scheduleAggregation = async () => {
    const payload = {
      jobname: aggregationName,
      interval: {
        type: "cron",
        params: interval
          .split(" ")
          .map((elem, index, array) => {
            if (index === 5 && elem === "*") return "?";
            return elem;
          })
          .join(" "),
      },
      params: {
        stages: [
          stage,
          { action: "template", params: { template: templateItem } },
        ],
      },
    };
    const res = await requestServer.post("schedule/aggregation", payload);
    return res;
  };

  useEffect(() => {
    setSelectedItems(props.selection);
  }, [props.selection]);

  useEffect(() => {
    const cron = interval.split(" ");
    if (cron[3] !== "*" && cron[5] !== "*") {
      message.error(t("openware:sensor.aggregate.intervalErrorDoWandDoM"));
      cron[3] = "*";
      setInterval(cron.join(" "));
    }
  }, [interval]);

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      const item = DataService._getOrThrowSync(
        selectedItems[0][0],
        selectedItems[0][1]
      );
      setTemplateItem(
        produce(item, (draft) => {
          draft.id = draft.id.substring(0, 3) + new Date().getTime();
        })
      );
    }
  }, [selectedItems]);
  const enableButton = () => {
    if (step === 0) {
      return (
        (selectedItems || []).length >= (items.min || 0) &&
        (selectedItems || []).length <= (items.max || Number.MAX_VALUE)
      );
    }
    if (step === 1) {
      try {
        cronstrue.toString(interval);
        return true;
      } catch (e) {
        return false;
      }
    }
    if (step === 2) {
      return !!stage;
    }
    if (step === 3) {
      return (
        templateItem.id &&
        templateItem.source &&
        templateItem.name &&
        templateItem.valueTypes.length > 0
      );
    }
    if (step === 4) {
      return (aggregationName || "").length > 0;
    }
  };

  aggregationOp.onValueTypes((vTypes) => {
    setTemplateItem(
      produce((draft) => {
        draft.valueTypes = vTypes;
      })
    );
  });

  return (
    <Drawer
      title={t("openware:sensor.aggregate.create")}
      placement="bottom"
      height={"66%"}
      open={props.open}
      onClose={() => {
        setStep(0);
        setOperation("sum");
        setInterval("0 * * * * ? *");
        props.onClose();
      }}
      footer={
        <>
          {step > 0 && (
            <Button
              style={{ float: "left" }}
              type="primary"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              {t("opendash:ui.back")}
            </Button>
          )}

          <Button
            style={{ float: "right" }}
            type="primary"
            onClick={async () => {
              if (step === 4) {
                await scheduleAggregation();
                props.onClose();
                return;
              }
              setStep(step + 1);
            }}
            disabled={!enableButton()}
          >
            {step !== 4 ? t("opendash:ui.next") : t("opendash:ui.save")}
          </Button>
        </>
      }
    >
      <Steps current={step}>
        <Steps.Step
          key={0}
          title={t("openware:sensor.aggregate.chooseDimension")}
          description={t("openware:sensor.aggregate.chooseDimensionDescr")}
        />

        <Steps.Step
          key={1}
          title={t("openware:sensor.aggregate.chooseInterval")}
          description={t("openware:sensor.aggregate.chooseIntervalDescr")}
        />

        <Steps.Step
          key={2}
          title={t("openware:sensor.aggregate.chooseOperation")}
          description={t("openware:sensor.aggregate.chooseOperationDescr")}
        />

        <Steps.Step
          key={3}
          title={t("openware:sensor.aggregate.editTemplate")}
          description={t("openware:sensor.aggregate.editTemplateDescr")}
        />
        <Steps.Step
          key={4}
          title={t("openware:sensor.aggregate.setName")}
          description={t("openware:sensor.aggregate.setAggregationName")}
        />
      </Steps>
      <Divider />
      {/* Step 0 */}
      {step === 0 && (
        <DataSelect
          showValue={true}
          showTimestamp={true}
          showSearch={true}
          selectionOptions={items}
          selection={selectedItems || []}
          onSelection={(nextValue) => {
            console.log(nextValue);
            setSelectedItems(nextValue);
          }}
        />
      )}

      {/* Step 1 */}
      {step === 1 && (
        <div style={{ display: "inline", textAlign: "center" }}>
          <h2>{cronstrue.toString(interval, { locale: localeString })}</h2>
          {<Description>{interval}</Description>}
          <Container>
            <Cron
              locale={GermanLocale}
              value={interval.substring(2).substring(-2)}
              setValue={(newValue: string) => {
                setInterval("0 " + newValue + " *");
                setErrorState(false);
              }}
              onError={(e) => {
                setErrorState(!!e);
                console.log("Fehler", e);
              }}
              clearButton={false}
            />
          </Container>

          {/* {errorState && (
          <Typography.Text type="danger">
            {t("opendash:error.data.value_invalid", { value: "Feld" })}
          </Typography.Text>
        )} */}
        </div>
      )}

      {/* Step 2 */}
      {step === 2 && (
        <Settings
          stage={stage}
          setStage={(stage) => {
            setStage(stage);
          }}
          items={selectedItems}
        />
      )}

      {/* Step 3 */}
      {step === 3 && (
        <DataItemEditComponent
          item={templateItem}
          setItem={(item) => {
            setTemplateItem(item);
          }}
          allowVTypAddition={false}
        />
      )}
      {step === 4 && (
        <>
          <Description>
            {t("openware:sensor.aggregate.setAggregationName")}
          </Description>
          <Input
            value={aggregationName}
            onChange={(e) => setAggregationName(e.target.value)}
          ></Input>
        </>
      )}
    </Drawer>
  );
};
