import React from "react";
import {
  AggregationComponentInterface,
  vtFunction,
} from "../AggregationComponentInterface";
import { AggregationSettingsComponentType } from "../AggregationSettingsComponentType";
import SettingsComponent from "./SettingsComponent";

const SourceAggregation = (): AggregationComponentInterface => {
  let vtcallback = null;
  return {
    items: {
      select: "dimension",
      min: 1,
      max: 1,
      types: ["Number"],
    },
    type: "source_aggregation",
    settingsComponent: (props: AggregationSettingsComponentType) => {
      return (
        <SettingsComponent
          {...props}
          setValueTypes={(vtypes) => {
            if (vtcallback) vtcallback(vtypes);
          }}
        />
      );
    },
    name: "Desktiptive Aggregation",
    onValueTypes: (cb: vtFunction) => {
      vtcallback = cb;
    },
  };
};

export default SourceAggregation;
