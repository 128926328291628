import React from "react";

import { Alert, Modal } from "antd";

import {
  AdminLayout,
  Loading,
  useBreadcrumb,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";

import {
  OpenWareProvider,
  OpenWareProviderProps,
} from "../common/OpenWareContext";
import { ReportingProvider, useReportingContext } from "./ReportingContext";
import { ReportCardList } from "./layout";
import reportTypes from "./reports";
import { useReportApi } from "./useReportApi";

export default function ReportingRoute({ config }: OpenWareProviderProps) {
  return (
    <OpenWareProvider config={config}>
      <ReportingProvider>
        <AdminLayout>
          <ReportingOverview />
        </AdminLayout>
      </ReportingProvider>
    </OpenWareProvider>
  );
}

function ReportingOverview() {
  const t = useTranslation();

  const context = useReportingContext();
  const api = useReportApi();

  const stateRef = React.useRef(null);
  const [create, setCreate] = useUrlParam("create", false, "json");

  const [selectedType, setType] = React.useState("basic");

  const reportType = reportTypes.find((t) => t.type === selectedType);

  useBreadcrumb("openware:reporting.label");

  if (!context.source) {
    return (
      <>
        <Loading message="..." />
        <Alert
          type="info"
          message={t("openware:reporting.missing_location_message")}
          description={t("openware:reporting.missing_location_description")}
          style={{ marginBottom: 24 }}
        />
      </>
    );
  }

  return (
    <>
      <AdminToolbar
        title={t("openware:reporting.title")}
        description={t("openware:reporting.description")}
        search={context.searchString}
        onSearch={context.setSearchString}

        /* actions={[
          <Button key="create" type="primary" onClick={() => setCreate(true)}>
            {t("openware:reporting.create.action")}
          </Button>,
        ]}*/
      />
      <div
        style={{
          width: "100%",
          background: "white",
          paddingTop: "24px",
        }}
      >
        <ReportCardList
          setCreate={setCreate}
          reports={context.reports}
          onUpdate={(updates) => {
            context.reload();
          }}
        />

        <Modal
          title={t("openware:reporting.create.title")}
          open={create}
          onCancel={() => setCreate(false)}
          width={800}
          okButtonProps={{ disabled: !stateRef.current }}
          onOk={() => {
            api.create({
              type: selectedType,
              source: context.source.tag,
              params: stateRef.current(),
            });
          }}
        >
          <reportType.Settings
            key={selectedType}
            onSave={(cb) => void (stateRef.current = cb)}
          />
        </Modal>
      </div>
    </>
  );
}
