import styled from "styled-components";

export const BoardDiv = styled.div`
  overflow-x: auto;
  padding-bottom: 10px;
  width: 100%;
`;

export const BoardInnerDiv = styled.div`
  display: flex;
  gap: 10px;
`;

export const ColumnDiv = styled.div<{ isDragging: boolean }>`
  background: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
`;

export const ColumnHeaderDiv = styled.div<{ isDragging: boolean }>`
  border-bottom: 1px solid var(--ant-primary-color-outline);
`;

export const ColumnContentDiv = styled.div`
  width: 300px;
  height: calc(100% - 20px);
  padding-bottom: 40px;
`;

export const CardDiv = styled.div<{ isDragging: boolean }>`
  margin-top: 10px;
  user-select: none;
`;
