import {
  createInternalComponent,
  useServiceStore,
  useTranslation,
} from "@opendash/core";
import { Checkbox, Divider, Form, Input, Modal, Radio } from "antd";
import * as React from "react";
import { WidgetContext } from "..";

interface Props {
  context: WidgetContext;
}

export const WidgetSharingModal = createInternalComponent<Props>(
  function WidgetSharingModal({ context }) {
    const t = useTranslation();

    const share = useServiceStore(context, (state) => state.share);

    const [type, setType] = React.useState("screenshot");
    const [data, setData] = React.useState({
      label: "",
      description: "",
      includeDataConfig: true,
      includeFetchingOptionsConfig: true,
      includeScreenshot: true,
    });

    return (
      <Modal
        open={share}
        title={t("opendash:widgets.share_modal_title")}
        okText={
          type === "screenshot"
            ? t("opendash:ui.download")
            : t("opendash:ui.share")
        }
        onOk={() => {
          context.store.update((state) => {
            state.share = false;
          });

          if (type === "screenshot") {
            context.shareScreenshot();
          }

          if (type === "preset") {
            context.sharePreset(data);
          }
        }}
        cancelText={t("opendash:ui.cancel")}
        onCancel={(e) => {
          context.store.update((state) => {
            state.share = false;
          });
        }}
      >
        <p>{t("opendash:widgets.share_modal_description")}</p>

        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          value={type}
          onChange={(e) => setType(e.target.value)}
          options={[
            {
              label: t("opendash:widgets.share.type_screenshot"),
              value: "screenshot",
            },
            {
              label: t("opendash:widgets.share.type_preset"),
              value: "preset",
            },
            // {
            //   label: "opendash:widgets.share.type_preset_file",
            //   value: "preset_file",
            // },
          ]}
        />

        <Divider />

        {type === "preset" && (
          <Form
            layout="vertical"
            autoComplete="off"
            initialValues={data}
            onValuesChange={(changedValue, values) => {
              setData(values);
            }}
          >
            <Form.Item
              label={t("opendash:widgets.share.label_label")}
              name="label"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("opendash:widgets.share.description_label")}
              name="description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label={t("opendash:widgets.share.include_data_options_label")}
              name="includeDataConfig"
              valuePropName="checked"
            >
              <Checkbox>
                {t("opendash:widgets.share.include_data_options_description")}
              </Checkbox>
            </Form.Item>

            <Form.Item
              label={t("opendash:widgets.share.include_fetching_options_label")}
              name="includeFetchingOptionsConfig"
              valuePropName="checked"
            >
              <Checkbox>
                {t(
                  "opendash:widgets.share.include_fetching_options_description"
                )}
              </Checkbox>
            </Form.Item>

            <Form.Item
              label={t("opendash:widgets.share.include_screenshot_label")}
              name="includeScreenshot"
              valuePropName="checked"
            >
              <Checkbox>
                {t("opendash:widgets.share.include_screenshot_description")}
              </Checkbox>
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
);
