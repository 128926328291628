import { AdminLayout, useFeedback, useTranslation } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { Route, Routes, useParams } from "@opendash/router";
import React from "react";
import SlideShowPlayer from "./SlideShowComponent";

import { Icon } from "@opendash/icons";
import { useDashboards } from "@opendash/plugin-monitoring";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { Input, Typography } from "antd";
import { ColumnProps } from "antd/lib/table";

const { Search } = Input;
const { Text } = Typography;
export default (props) => {
  const innerParams = useParams();
  const t = useTranslation();
  const { message } = useFeedback();
  const allDashboards = useDashboards();
  const adminComponent = (
    <AdminLayout>
      <div style={{ padding: "24px" }}>
        <ParseSetView
          className="OD3_Monitoring_Slideshow"
          view={
            {
              type: "table",
              fields: ["name", "interval", "dashboards"],
              customCols: [
                {
                  title: t("openware:admin.slideshows.label"),
                  key: "slideshowActions",
                  //dataIndex: ["dashboards"],
                  render: (v, row) => {
                    const newUrl =
                      window.location.origin + "/openware/slideshow/" + v.id;
                    return (
                      <div>
                        <Icon
                          icon="fa:copy"
                          onClick={() => {
                            const data = [
                              new ClipboardItem({
                                "text/plain": new Blob([newUrl], {
                                  type: "text/plain",
                                }),
                              }),
                            ];
                            navigator.clipboard.write(data).then(
                              function () {
                                message.success(
                                  t("opendash:dashboards.link.action_success")
                                );
                              },
                              function () {
                                console.error(
                                  t("opendash:dashboards.link.action_failed")
                                );
                              }
                            );
                          }}
                        />
                        <Icon
                          icon="fa:play"
                          onClick={() => {
                            window.open(newUrl, "_blank").focus();
                          }}
                        />
                      </div>
                    );
                  },
                } as ColumnProps<any>,
                ,
              ],
            } as TableViewType
          }
        />
      </div>
    </AdminLayout>
  );
  const slideShowComponent = <SlideShowPlayer />;

  return (
    <Routes>
      <Route path="*" element={adminComponent} />
      <Route path=":slideShow" element={slideShowComponent} />
    </Routes>
  );
};

//export default SensorConfiguration;
