import { produce, useSource, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { EditorComponents } from "@opendash/plugin-parse";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import AggregateTemplatePicker from "../../../../common/AggregateTemplatePicker";

import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import { Report, ReportAttributes } from "../../../Report";

const editor = (props: {
  Report: Report;
  onUpdate: (report: Report) => void;
}) => {
  const t = useTranslation();
  const edit = true;
  const [source] = useSource();
  const [report, setReport] = React.useState<ReportAttributes>(
    props.Report.toJSON() as unknown as ReportAttributes
  );
  const [showModal, setShowModal] = React.useState(false);
  const [initFire, setInitFire] = React.useState(false);

  React.useEffect(() => {
    if (initFire) {
      saveReportItems();
      saveReportImages();
    } else {
      setInitFire(true);
    }
  }, [report]);

  const [saveStages, setSaveStages] = React.useState(false);

  const saveKey = async (key: string) => {
    props.Report.set(key, report[key]);
    const updatedReport = await props.Report.save();
    props.onUpdate(updatedReport);
  };

  const imgQuery = React.useMemo(() => {
    const q = new Parse.Query("OD3_Monitoring_ReportImage");
    return q;
  }, [source.id]);
  const { result: images, loading, error, reload } = useParseQuery(imgQuery);

  const saveReportItems = async () => {
    props.Report.set("items", report.items);
    const updatedReport = await props.Report.save();
    props.onUpdate(updatedReport);
  };
  const saveReportImages = async () => {
    props.Report.set("images", report.images);
    const updatedReport = await props.Report.save();
    props.onUpdate(updatedReport);
  };

  React.useEffect(() => {
    if (saveStages) {
      saveReportItems();
    }
    setSaveStages(false);
  }, [saveStages]);

  const titleTab = (
    <Tabs.TabPane
      tab={t("openware:reporting.settings.tabs.title.label")}
      key={"title"}
    >
      <div>
        <Descriptions title={""} bordered size="small">
          <Descriptions.Item
            label={t("openware:reporting.fields.name.label")}
            span={4}
          >
            <Input.Group compact>
              <Input
                style={{ width: "calc(100% - 32px)" }}
                value={report.name}
                onChange={(e) => {
                  const value = e.target.value;
                  setReport(
                    produce((draft) => {
                      draft.name = value;
                    })
                  );
                }}
              />
              <Button
                icon={<Icon icon="fa:save" />}
                disabled={props.Report.get("name") === report.name}
                onClick={(e) => saveKey("name")}
              />
            </Input.Group>
          </Descriptions.Item>

          <Descriptions.Item
            label={t("openware:reporting.fields.subname.label")}
            span={4}
          >
            <Input.Group compact>
              <Input
                style={{ width: "calc(100% - 32px)" }}
                value={report.title}
                onChange={(e) => {
                  const value = e.target.value;
                  setReport(
                    produce((draft) => {
                      draft.title = value;
                    })
                  );
                }}
              />
              <Button
                icon={<Icon icon="fa:save" />}
                disabled={props.Report.get("title") === report.title}
                onClick={(e) => saveKey("title")}
              />
            </Input.Group>
          </Descriptions.Item>

          <Descriptions.Item
            label={t("openware:reporting.fields.description.label")}
            span={4}
          >
            <Input.Group compact>
              <Input.TextArea
                rows={6}
                style={{ width: "calc(100% - 32px)" }}
                value={report.description}
                onChange={(e) => {
                  const value = e.target.value;
                  setReport(
                    produce((draft) => {
                      draft.description = value;
                    })
                  );
                }}
              />
              <Button
                icon={<Icon icon="fa:save" />}
                disabled={
                  props.Report.get("description") === report.description
                }
                onClick={(e) => saveKey("description")}
              />
            </Input.Group>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Tabs.TabPane>
  );
  const dataTab = (
    <Tabs.TabPane
      key={"data"}
      tab={t("openware:reporting.settings.tabs.data.label")}
    >
      {report.items.map((item, idx) => {
        return (
          <>
            <Row style={{ marginBottom: 10 }}>
              {/* 
              <Col offset={8} span={4} style={{ textAlign: "center" }}>
                <Typography.Text strong>{item.reportid}</Typography.Text>
                <br />
                <Typography.Text copyable={true} type="secondary">
                  {`$data.${item.reportid}[DIMENSION].value()`}
                </Typography.Text>
              </Col>*/}
            </Row>
            <Row>
              <Col span={6}>
                {/* Namen Eingabe */}
                <Descriptions>
                  <Descriptions.Item
                    label={t(
                      "openware:reporting.fields.description.data.item.label"
                    )}
                    span={4}
                  >
                    <Input.Group compact>
                      <Input
                        style={{ width: "calc(100% - 32px)" }}
                        value={item.reportid}
                        onChange={(e) => {
                          const value = e.target.value;
                          setReport(
                            produce((draft) => {
                              draft.items[idx].reportid = value;
                            })
                          );
                        }}
                      ></Input>
                      <Button
                        icon={<Icon icon="fa:save" />}
                        disabled={
                          report.items[idx].reportid ===
                          props.Report?.get("items")[idx]?.reportid
                        }
                        onClick={(e) => {
                          saveReportItems();
                        }}
                      />
                    </Input.Group>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col offset={2} span={14}>
                {/* JSON Eingabe */}
                <Descriptions>
                  <Descriptions.Item
                    label={t(
                      "openware:reporting.fields.description.data.item.pipe"
                    )}
                    span={4}
                  >
                    <Input.Group compact>
                      <EditorComponents.ObjectEdit
                        collapsed={3}
                        value={item.stages}
                        isRequired={false}
                        isNullable={false}
                        setValue={(e) => {
                          const value = e;
                          setReport(
                            produce((draft) => {
                              draft.items[idx].stages = value;
                              setSaveStages(true);
                            })
                          );
                        }}
                      />
                    </Input.Group>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={2}>
                <Button
                  style={{
                    float: "right",
                    padding: "0px",
                    margin: "-7px",
                    marginRight: "4px",
                  }}
                  icon={<Icon color="red" icon="fa:trash" />}
                  shape="circle"
                  type="text"
                  onClick={() => {
                    Modal.confirm({
                      title: t("openware:reporting.settings.delete.title"),
                      content: t("openware:reporting.settings.delete.content"),
                      okText: t("openware:reporting.settings.delete.yes"),
                      okType: "danger",
                      onOk: async () => {
                        const items = props.Report.get("items");
                        items.splice(idx, 1);
                        props.Report.set("items", items);
                        const update = await props.Report.save();
                        props.onUpdate(update);
                        setReport(
                          produce((draft) => {
                            draft.items.splice(idx, 1);
                          })
                        );
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <Divider />
          </>
        );
      })}
      <Row>
        <Col offset={1} span={22}>
          <Button
            style={{ width: "100%" }}
            icon={<Icon icon="fa:plus" />}
            type="dashed"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </Col>
      </Row>
      <AggregateTemplatePicker
        open={showModal}
        mode="modal"
        onCancel={() => {
          setShowModal(false);
        }}
        onOk={(element) => {
          setShowModal(false);
          console.log(element);
          setReport(
            produce((draft) => {
              draft.items.push({
                reportid:
                  "item_" +
                  element.action +
                  "_" +
                  element.params.source +
                  "_" +
                  element.params.id,
                stages: [element],
              });
            })
          );
        }}
        value={null}
      ></AggregateTemplatePicker>
    </Tabs.TabPane>
  );
  const imageTab = (
    <Tabs.TabPane
      key={"images"}
      tab={t("openware:reporting.settings.tabs.images.label")}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <Typography.Text type="secondary">
              {t("openware:reporting.settings.tabs.images.reportid")}
            </Typography.Text>
          </Col>
          <Col offset={1} span={15}>
            <Typography.Text type="secondary">
              {t("openware:reporting.settings.tabs.images.image")}
            </Typography.Text>
          </Col>
        </Row>
        {report.images.map((image, idx) => {
          const exists =
            !!images.find(
              (existingImage) => image.image.id === existingImage.id
            ) || !image.image.id;
          if (!exists) {
            return (
              <Row style={{ textAlign: "center" }}>
                <Col span={8}>
                  <Typography.Text type="secondary" italic={true}>
                    {t(
                      "openware:reporting.settings.tabs.images.imageDoesNotExist"
                    )}
                  </Typography.Text>
                </Col>
                <Col offset={1} span={15}>
                  <Button
                    icon={
                      <Icon
                        icon="fa:trash"
                        onClick={() => {
                          setReport(
                            produce((draft) => {
                              draft.images.splice(idx, 1);
                            })
                          );
                        }}
                      ></Icon>
                    }
                  ></Button>
                </Col>
              </Row>
            );
          }
          return (
            <Row>
              <Col span={8}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Input
                    style={{ flexGrow: 1 }}
                    value={image.reportid}
                    onChange={(e) => {
                      const value = e.target.value;
                      setReport(
                        produce((draft) => {
                          draft.images[idx].reportid = value;
                        })
                      );
                    }}
                  />
                  <Button
                    icon={<Icon icon="fa:save" />}
                    disabled={
                      report.images[idx].reportid ===
                      props.Report?.get("images")[idx]?.reportid
                    }
                    onClick={(e) => {
                      saveReportImages();
                    }}
                  />
                </div>
              </Col>
              <Col offset={1} span={14}>
                <Select
                  style={{ width: "100%" }}
                  value={image.image.id}
                  options={images.map((image) => {
                    return {
                      label: `${image.get("name")}`,
                      value: image.id,
                    };
                  })}
                  onChange={(selected) => {
                    const selImage = images.find((img) => img.id === selected);
                    if (!selImage) return;
                    setReport(
                      produce((draft) => {
                        draft.images[idx].image = {
                          id: selImage.id,
                          source: selImage.get("source").id,
                        };
                      })
                    );
                  }}
                ></Select>
              </Col>
              <Col span={1}>
                <Button
                  icon={
                    <Icon
                      icon="fa:trash"
                      onClick={() => {
                        setReport(
                          produce((draft) => {
                            draft.images.splice(idx, 1);
                          })
                        );
                      }}
                    ></Icon>
                  }
                ></Button>
              </Col>
            </Row>
          );
        })}
        <Button
          style={{ alignSelf: "end" }}
          type="link"
          onClick={() => {
            window.open(
              window.location.origin + "/openware/reporting/images",
              "_blank"
            );
          }}
        >
          {t("openware:reporting.settings.tabs.images.createHint")}
        </Button>
      </div>

      {report.images.length > 0 && <Divider></Divider>}
      <Button
        type="dashed"
        icon={<Icon icon="fa:plus" />}
        style={{ width: "100%" }}
        onClick={() => {
          setReport(
            produce((draft) => {
              draft.images.push({
                reportid: "image_" + Math.floor(Math.random() * 1000),
                image: {
                  id: images[0]?.id || null,
                  source: images[0]?.get("source") || null,
                },
              });
            })
          );
        }}
      />
    </Tabs.TabPane>
  );

  return (
    <Tabs>
      {titleTab}
      {dataTab}
      {imageTab}
    </Tabs>
  );
};

const asDrawer = (props: {
  report: Report;
  open: boolean;
  onClose: (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void;
  onUpdate: (report: Report) => void;
}) => {
  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      placement="bottom"
      height={"60%"}
    >
      {editor({ Report: props.report, onUpdate: props.onUpdate })}
    </Drawer>
  );
};

export { asDrawer, editor };
