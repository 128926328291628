import { SourceInterface, UserInterface, equals } from "@opendash/core";
import {
  DataAdapterContext,
  DataAdapterInterface,
  DataFetchingEvaluatedOptionsInterface,
  DataItemDimensionValueInterface,
  DataItemInterface,
  DataItemValueInterface,
} from "@opendash/plugin-timeseries";

import Parse from "parse";
import { Options } from "./types";

function setResolution(
  start: number,
  end: number,
  mode: "minmax",
  maxValues: number,
  values: { date: number; value: number }[]
) {
  const result: { date: number; value: number }[] = [];

  if (values.length <= maxValues) {
    return values;
  }

  const bucketSize = Math.ceil(values.length / (maxValues / 2));

  if (mode === "minmax") {
    let count = 0;
    let maxVal = Number.NEGATIVE_INFINITY;
    let minVal = Number.POSITIVE_INFINITY;
    let max = null;
    let min = null;

    for (const val of values) {
      if (count == bucketSize) {
        if (max && min) {
          if (max.date > min.date) {
            result.push(min);
            result.push(max);
          } else {
            result.push(max);
            result.push(min);
          }
        }
        count = 0;
        maxVal = Number.NEGATIVE_INFINITY;
        minVal = Number.POSITIVE_INFINITY;
        max = null;
        min = null;
      }

      if (val.value > maxVal) {
        max = val;
        maxVal = val.value;
      }
      if (val.value <= minVal) {
        min = val;
        minVal = val.value;
      }
      count++;
    }
  }

  return result;
}

export class DataAdapter implements DataAdapterInterface {
  private host: string = "localhost:4567";
  private secure: string = "";

  private filter: number = -1;

  private websocket: WebSocket = null;
  private loaded: boolean = false;

  private context: DataAdapterContext;
  private user: UserInterface;
  private tags: string[] = [];

  private allowedIds = new Set<string>();
  private stringValueMap = new Map<string, string>();

  private cacheMeta = new Map<string, { start: number; end: number }>();
  private cacheMetaLiveValues = new Map<string, number>();
  private cacheValues = new Map<string, { date: number; values: any[] }[]>();

  constructor(config: Options) {
    this.host = config.host || this.host;
    this.secure = config.secure ? "s" : "";
    this.filter = config.filterValuesOlderThanMS || -1;
  }

  async onContext(context: DataAdapterContext) {
    this.context = context;

    try {
      const strings = await new Parse.Query("OD3_BDE_ListEntry").find();
      strings.forEach((entry) => {
        this.stringValueMap.set(entry.get("key"), entry.get("value"));
      });
    } catch (error) {
      // expect error when bde plugin is not available
    }
  }

  onUser(user: UserInterface) {
    if (!equals(this.user, user)) {
      this.user = user;
      this.init();
    }
  }

  onSource(source: SourceInterface, descendents: SourceInterface[]) {
    const tags = [source.tag, ...descendents.map((src) => src.tag)]
      .filter(Boolean)
      .filter((v, i, a) => a.indexOf(v) === i);

    if (!equals(tags, this.tags)) {
      this.tags = tags;
      this.init();
    }
  }

  private async _readCache(
    source: string,
    id: string,
    start: number,
    end: number
  ) {
    const key = JSON.stringify([source, id]);

    if (this.cacheMeta.has(key)) {
      const { start: cacheStart, end: cacheEnd } = this.cacheMeta.get(key);
      const liveCacheFrom = this.cacheMetaLiveValues.get(key);

      if (start < cacheStart) {
        await this._createCache(source, id, start, cacheStart, true);
      }

      if (end > cacheEnd && liveCacheFrom > cacheEnd) {
        await this._createCache(
          source,
          id,
          cacheEnd,
          Math.min(end, liveCacheFrom)
        );
      }
    } else {
      await this._createCache(source, id, start, end);
    }

    const cache = this.cacheValues.get(key);
    const result = [];

    let matches = 0;

    for (let i = 0; i < cache.length; i++) {
      if (start <= cache[i].date && cache[i].date <= end) {
        result[matches++] = cache[i];
      }
    }

    return result;
  }

  private async _createCache(
    source: string,
    id: string,
    start: number,
    end: number,
    insertAtStart: boolean = false
  ) {
    const key = JSON.stringify([source, id]);

    id = encodeURIComponent(id);
    source = encodeURIComponent(source);

    const url = `/api/data/historical/${source}/${id}/${start}/${end}`;

    const item = await this.fetch(url);
    const hasStringDim = item.valueTypes
      .map((vType, index) => {
        vType.index = index;
        return vType;
      })
      .filter((vType) => {
        return vType.type.toLowerCase() === "string";
      });

    let values = item.values;
    if (hasStringDim.length > 0) {
      values = values.map((cValue) => {
        for (const dim of hasStringDim) {
          cValue.value[dim.index] =
            this.stringValueMap.get(cValue.value[dim.index]) ||
            cValue.value[dim.index];
        }
        return cValue;
      });
    }

    if (this.cacheMeta.has(key)) {
      const meta = this.cacheMeta.get(key);

      this.cacheMeta.set(key, {
        start: Math.min(start, meta.start),
        end: Math.max(end, meta.end),
      });
    } else {
      this.cacheMeta.set(key, { start, end });
    }

    if (this.cacheValues.has(key)) {
      const cache = this.cacheValues.get(key);

      if (insertAtStart) {
        this.cacheValues.set(
          key,
          [...values, ...cache].sort((a, b) => a.date - b.date)
        );
      } else {
        this.cacheValues.set(
          key,
          [...cache, ...values].sort((a, b) => a.date - b.date)
        );
      }
    } else {
      this.cacheValues.set(key, values);
    }
  }

  async fetchValues(
    item: DataItemInterface,
    options: DataFetchingEvaluatedOptionsInterface
  ): Promise<DataItemValueInterface[]> {
    if (options?.aggregation || options?.aggregationSplits) {
      const aggregation = await this.aggregate(options.aggregationPipe);
      return aggregation[1];

      //throw new Error(
      //  "DataAdapter.fetchValues() does not support aggregation"
      //);asdf
    }
    try {
      /*
      

      return await this._readCache(
        item.source,
        item.id,
        options.start,
        options.end
      );
        */
      const id = encodeURIComponent(item.id);
      const source = encodeURIComponent(item.source);

      const start = options.start || 0;
      const end = options.end || Date.now();

      let result = [];

      const qs = [];

      if (
        options?.resolution &&
        options?.resolutionMaxValues &&
        options?.resolutionMode
      ) {
        qs.push(
          new URLSearchParams({
            maxValues: options.resolutionMaxValues.toString(),
            mode: options.resolutionMode,
          }).toString()
        );
      }

      if (options.reference) {
        qs.push(options.reference);
      }
      const url =
        `/api/data/historical/${source}/${id}/${start}/${end}?` + qs.join("&");
      const data = await this.fetch(url);

      if (!Array.isArray(data.values)) {
        throw new Error("response.data.values must be an Array");
      }

      const hasStringDim = item.valueTypes
        .map((vType, index) => {
          return {
            type: vType.type,
            index,
          };
        })
        .filter((vType) => {
          return vType.type.toLowerCase() === "string";
        });

      let values = data.values;
      if (hasStringDim.length > 0) {
        values = values.map((cValue) => {
          for (const dim of hasStringDim) {
            cValue.value[dim.index] =
              this.stringValueMap.get(cValue.value[dim.index]) ||
              cValue.value[dim.index];
          }
          return cValue;
        });
      }

      result = data.values.sort((a, b) => {
        return a.date - b.date;
      });

      if (options.limit && options.limit < result.length) {
        return result.slice(0, options.limit);
      }

      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async fetchDimensionValues(
    item: DataItemInterface,
    dimension: number,
    options: DataFetchingEvaluatedOptionsInterface
  ): Promise<DataItemDimensionValueInterface[]> {
    try {
      let result;
      /*
      const cache = await this._readCache(
        item.source,
        item.id,
        options.start,
        options.end
      );

      if (options.limit && options.limit < cache.length) {
        cache.splice(0, cache.length - options.limit);
      }

      result = cache.map((x: DataItemValueInterface) => ({
        date: x.date,
        value: x.value[dimension],
      }));

      if (options.resolution) {
        result = setResolution(
          options.start,
          options.end,
          "minmax",
          options.resolutionMaxValues,
          result
        );
      }

      if (options.aggregation) {
        result = Aggregation.aggregate(
          options.aggregationOperation,
          options.aggregationPots,
          result
        );
      }

      return result; 
*/
      const id = encodeURIComponent(item.id);
      const source = encodeURIComponent(item.source);

      const start = options.start || 0;
      const end = options.end || Date.now();

      if (
        options?.aggregation &&
        options?.aggregationOperation &&
        (options?.aggregationSplits || options?.aggregationDateUnit)
      ) {
        const qs = new URLSearchParams({
          dimension: dimension.toString(),
          splits: options?.aggregationSplits?.toString() || "1",
          operation: options?.aggregationOperation,
        });
        if (options.aggregationDateUnit) {
          qs.delete("splits");
          qs.set("timeinterval", options.aggregationDateUnit);
        }
        const url = `/api/transform/source_aggregation/${source}/${id}/${start}/${end}?${qs.toString()}`;
        const data = await this.fetch(url);

        if (!Array.isArray(data?.result?.values)) {
          throw new Error("response.data.result.values must be an Array");
        }

        result = data?.result?.values.map((x: DataItemValueInterface) => ({
          date: x.date,
          value: x.value[0],
        }));
      } else if (
        options?.resolution &&
        options?.resolutionMaxValues &&
        options?.resolutionMode
      ) {
        const qs = new URLSearchParams({
          dimension: dimension.toString(),
          maxValues: options.resolutionMaxValues.toString(),
          mode: options.resolutionMode,
        });

        const url = `/api/data/historical/${source}/${id}/${start}/${end}?${qs.toString()}`;
        const data = await this.fetch(url);
        if (!Array.isArray(data.values)) {
          throw new Error("response.data.values must be an Array");
        }

        if (options.includeLowerBound) {
          try{
            const lowerBoundUrl = `/api/data/live/${source}/${id}?at=${start}&values=1`;
            const lowerBound = await this.fetch(lowerBoundUrl);
  
            if (lowerBound && lowerBound.values && lowerBound.values[0]) {
              lowerBound.values[0].date = start;
              data.values.unshift(lowerBound.values[0]);
            }
          }catch(e){
            console.warn("Could not fetch lower bound", e);
          }
          
        }
        if (options.includeUpperBound) {
          const lastVal = data.values.at(-1);
          if (lastVal) {
            data.values.push({ ...lastVal, date: end });
          }
        }

        const hasStringDim = item.valueTypes
          .map((vType, index) => {
            return {
              type: vType.type,
              index,
            };
          })
          .filter((vType) => {
            return vType.type.toLowerCase() === "string";
          })
          .map((vType) => {
            return vType.index;
          });
        /*
        let values = data.values;
        if (hasStringDim.length > 0) {
          values = values.map((cValue) => {
            for (let dim of hasStringDim) {
              cValue.value[dim] =
                this.stringValueMap.get(cValue.value[dim]) || cValue.value[dim];
            }
            return cValue;
          });
        }
*/
        result = data.values
          .map((x: DataItemValueInterface) => {
            const value = {
              date: x.date,
              value: x.value[dimension],
            };
            if (
              hasStringDim.length > 0 &&
              hasStringDim.indexOf(dimension) != -1
            ) {
              value.value = this.stringValueMap.get(value.value) || value.value;
            }
            return value;
          })
          .sort((a, b) => {
            return a.date - b.date;
          });
      } else {
        const url = `/api/data/historical/${source}/${id}/${start}/${end}`;
        const data = await this.fetch(url);

        if (!Array.isArray(data.values)) {
          throw new Error("response.data.values must be an Array");
        }
        if (options.includeLowerBound) {
          const lowerBoundUrl = `/api/data/live/${source}/${id}?at=${start}&values=1`;
          try {
            const lowerBound = await this.fetch(lowerBoundUrl);
            if (lowerBound.values[0]) {
              lowerBound.values[0].date = start;
              data.values.unshift(lowerBound.values[0]);
            }
          } catch (e) {
            
              console.warn("Could not fetch lower bound", e);
            
          }
        }
        result = data.values
          .map((x: DataItemValueInterface) => ({
            date: x.date,
            value: x.value[dimension],
          }))
          .sort((a, b) => {
            return a.date - b.date;
          });
      }

      if (options.limit && options.limit < result.length) {
        return result.slice(result.length - options.limit, result.length);
      }

      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async update(item: DataItemInterface) {}

  private async init(): Promise<void> {
    try {
      if (!this.context || !this.user || this.tags.length === 0) {
        return;
      }

      const tags = [this.user.username, ...this.tags].filter(
        (v, i, a) => a.indexOf(v) === i
      );

      if (this.loaded) {
        this.context.reset();
        this.allowedIds.clear();
      } else {
        this.loaded = true;
      }

      // for (const tag of tags) {
      //   const url = `/api/data/items/${this.user.username}/${tag}`;
      //   try {
      //     const data = await this.fetch(url);
      //     await this.handleItems(data);
      //   } catch (error) {
      //     console.error("Could not retrieve data of " + tag, error);
      //   }
      // }
      try {
        const data = await this.post(`/api/data/items/`, tags);
        await this.handleItems(data);
      } catch (error) {
        console.error("Could not retrieve data of " + tags, error);
      }
      this.context.setLoading(false);

      await this.initWebsocket();
    } catch (error) {
      console.error(error);
    }
  }

  private async initWebsocket(reconnect = false) {
    if (this.websocket) {
      this.websocket.close();
    }

    this.websocket = new WebSocket(
      `ws${this.secure}://${this.host}/subscription`
    );
    const terminationEvent = "onpagehide" in self ? "pagehide" : "unload";
    window.addEventListener(terminationEvent, (event) => {
      if (
        this.websocket.readyState === this.websocket.OPEN ||
        this.websocket.readyState === this.websocket.CONNECTING
      ) {
        console.log("Closing websocket gracefully...");
        this.websocket.close();
        console.log("Closed.");
      }
    });

    this.websocket.onopen = () => {
      this.websocket.send(
        JSON.stringify({
          action: "subscribe",
          session: this.user.session,
          sources: this.tags,
        })
      );

      this.websocket.onmessage = (msg) => {
        try {
          const message = JSON.parse(msg.data.replaceAll("'", '"'));

          if (this.tags.includes(message.user)) {
            this.handleItems([message]).then(
              (ok) => {
                console.info("ws -> data");
              },
              (error) => {
                console.error("ws -> data error:", error);
              }
            );
          }
        } catch (e) {
          console.error(e);
        }
      };

      this.websocket.onclose = () => {
        console.log(
          "[open.WARE SDK] WS: Disconnected, attempt reconnect in 3 seconds"
        );

        setTimeout(() => {
          if (
            this.websocket?.readyState === WebSocket.OPEN ||
            this.websocket?.readyState === WebSocket.CONNECTING
          ) {
            console.log(
              "[open.WARE SDK] WS: Skipping reconnect, already connected"
            );
          } else {
            this.initWebsocket(true);
          }
        }, 3000);
      };

      this.websocket.onerror = (event) => {
        console.error("[open.WARE SDK] WS: Error", event);
        this.websocket.close();
      };
    };

    window.addEventListener("focus", (e) => {
      if (
        !(
          this.websocket.readyState === WebSocket.OPEN ||
          this.websocket.readyState === WebSocket.CONNECTING
        )
      ) {
        this.initWebsocket(true);
      }
    });
  }

  private async handleItems(data: any[]) {
    const items = data
      .map((item) => {
        if (item.values.length > 0) {
          if (
            this.filter != -1 &&
            item.values[0].date < new Date().getTime() - this.filter
          ) {
            console.log("Older than 6 Months - Ignore: " + item.name);
            return null;
          } else {
            for (const typeItem of item.valueTypes) {
              typeItem.name = typeItem.name || "no name";
            }
            if (item.meta.visible === false) {
              return null;
            }
            return {
              id: item.id,
              name: item.name || "no name",
              source: item.user,
              valueTypes: item.valueTypes,
              meta: item.meta,
            };
          }
        }
      })
      .filter((item) => item !== null)
      .filter((item) => item !== undefined);

    await this.context.setItems(items);

    for (const item of data) {
      if (item?.id && item?.user && item.values?.[0]) {
        const cacheKey = JSON.stringify([item.user, item.id]);

        if (!this.cacheMetaLiveValues.has(cacheKey)) {
          this.cacheMetaLiveValues.set(cacheKey, item.values[0].date);
        }

        if (!this.cacheValues.has(cacheKey)) {
          this.cacheValues.set(cacheKey, []);
        }

        this.cacheValues.get(cacheKey).push(item.values[0]);

        this.context.setValue(
          // @ts-ignore
          { source: item.user, id: item.id },
          item.values[0]
        );
      }
    }
  }
  async aggregate(
    pipe
  ): Promise<[DataItemInterface, DataItemDimensionValueInterface[]]> {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "OD-SESSION": this.user.session,
      },
      body: JSON.stringify(pipe),
    };
    const response = await fetch(
      `http${this.secure}://${this.host}/api/transform/pipe`,
      requestOptions
    );
    const data = (await response.json()).result;
    if (!data) {
      return [null, []];
    }
    const values = data.values;
    delete data.values;
    return [data, values];
  }

  private async fetch(url): Promise<any> {
    const response = await fetch(`http${this.secure}://${this.host}${url}`, {
      headers: {
        "OD-SESSION": this.user.session,
      },
    });

    const data = await response.json();

    return data;
  }

  private async post(url, body): Promise<any> {
    const response = await fetch(`http${this.secure}://${this.host}${url}`, {
      headers: {
        "OD-SESSION": this.user.session,
      },
      method: "POST",
      body: JSON.stringify(body),
    });

    const data = await response.json();

    return data;
  }
}
