import { InboxOutlined } from "@ant-design/icons";
import {
  $framework,
  createInternalComponent,
  useFeedback,
  useTranslation,
} from "@opendash/core";
import {
  DataItemDimensionIdentifierInterface,
  DataItemHistoryOptionsPicker,
  DataItemInterface,
  DataItemValueInterface,
  DataItemValueTypeInterface,
  useDataService,
  validateDataFetchingSelection,
} from "@opendash/plugin-timeseries";
import { IconSelect } from "@opendash/ui";
import type { UploadProps } from "antd";
import { Alert, Button, Divider, Space, Steps, Tabs, Upload } from "antd";
import dayjs from "dayjs";
import produce from "immer";
import * as React from "react";
import { usePapaParse } from "react-papaparse";
import {
  ExplorerStateInterface,
  WidgetComponentRender,
  WidgetSettingsRenderWithoutSteps,
  useMonitoringService,
  useWidgetBaseContextDraftSetup,
  useWidgetContextSetup,
  useWidgetTypes,
} from "..";
import {
  Container,
  Description,
  SettingsHolder,
  StepNav,
} from "./Explorer.layout";

const { Dragger } = Upload;
const { readString } = usePapaParse();

export const CSVExplorer = createInternalComponent(function CSVExplorer() {
  const t = useTranslation();
  const MonitoringService = useMonitoringService();
  const DataService = useDataService();
  const widgets = useWidgetTypes();
  const { message } = useFeedback();

  const [csvFile, setCSVFile] = React.useState<File>(null);
  const [csvFullContent, setCSVFullContent] = React.useState<string>("");
  const [csvContent, setCSVContent] = React.useState<string>("");
  const [headerRow, setHeaderRow] = React.useState<number | null>(null);
  const [csvFormat, setCSVFormat] = React.useState<object[]>([]);
  const [timeSplit, setTimeSplit] = React.useState<string>("");
  const [selectedData, setSelectedData] = React.useState<string>("");

  const [state, setState] = React.useState<ExplorerStateInterface>({
    step: 0,
    dataType: "dimension",
    itemDimensions: [],
    fetchingOptions: {},
    visualisation: "opendash-widget-hc-timeseries",
  });

  const visualisations = React.useMemo(() => {
    const valueTypes = ["Number"];

    return widgets
      .filter(
        (widget) =>
          widget.dataExplorer && widget.dataItems?.select === state.dataType
      )
      .map((widget) => {
        let disabled = false;
        let tooltip = null;
        if (
          widget.dataItems.select === "dimension" &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          valueTypes.some((type) => !widget.dataItems.types?.includes(type))
        ) {
          disabled = true;
          tooltip = t("opendash:error.data.items_type");
        } else if (widget.dataItems.min > state.itemDimensions.length) {
          disabled = true;
          tooltip = t("opendash:error.data.items_min");
        } else if (widget.dataItems.max < state.itemDimensions.length) {
          disabled = true;
          tooltip = t("opendash:error.data.items_max");
        }

        if (!disabled) {
          const [valid, hint] = validateDataFetchingSelection(
            widget.dataFetching || {},
            state.fetchingOptions
          );

          if (!disabled && !valid) {
            disabled = true;
            tooltip = t(hint);
          }
        }

        return {
          value: widget.type,
          label: widget.dataExplorer.title,
          icon: widget.dataExplorer.icon,
          tooltip: tooltip || widget.dataExplorer.description,
          disabled,
        };
      });
  }, [state.itemDimensions]);

  const widgetConfig = React.useMemo(() => {
    return {
      _dimensions: state.itemDimensions,
      _history: state.fetchingOptions,
      ...(widgets.find((w) => w.type === state.visualisation)?.dataExplorer
        ?.config || {}),
    };
  }, [state.visualisation, state.itemDimensions, state.fetchingOptions]);

  const widgetBaseContext = useWidgetBaseContextDraftSetup(
    state.visualisation,
    widgetConfig
  );

  const widgetContext = useWidgetContextSetup(widgetBaseContext);

  const handleHeaderRowChange = (event) => {
    const value = event.target.value;
    setHeaderRow(value === "-1" ? null : parseInt(value));
  };

  const handleTimeSplitChange = (event) => {
    const value = event.target.value;
    setTimeSplit(value === "-1" ? "1" : value);
  };

  const handleselectedDataChange = async (event) => {
    const value = event.target.value;
    setSelectedData(value === "-1" ? "" : value);

    const items = {
      id: "csv-import." + encodeURIComponent(value),
      name: "CSV-Parser",
      source: "demo@sustainkmu.de",
      valueTypes: [
        {
          type: "Number",
          unit: "",
          name: value,
        } as DataItemValueTypeInterface,
      ],
      meta: { status: "csv-import" },
    };

    await DataService.setItem(items as unknown as DataItemInterface);

    DataService.setValue(
      // @ts-ignore
      {
        source: "demo@sustainkmu.de",
        id: "csv-import." + encodeURIComponent(value),
      },
      { date: new Date().getTime(), value: [0] } as DataItemValueInterface
    ).then(() => {
      console.log(items);
      console.log(
        DataService._getOrThrowSync(
          "demo@sustainkmu.de",
          "csv-import." + encodeURIComponent(value)
        )
      );
    });
  };

  function countCharactersBeforeNthNewline(n, text) {
    let count = 0;
    let index = -1;

    for (let i = 0; i < n; i++) {
      index = text.indexOf("\n", index + 1);
      if (index === -1) {
        return text.length;
      }
      count = index + 1;
    }

    return count;
  }

  React.useEffect(() => {
    formatCSV();
  }, [headerRow, csvFullContent]);

  /*
  React.useEffect(() => {
    const dates = csvFormat.map((obj) =>
      parseTimestamp(obj[timeSplit]).getTime()
    );
    const lowestDate = new Date(Math.min(...dates));
    const lastDate = new Date(Math.max(...dates));
    
  }, [timeSplit]);
  */

  React.useEffect(() => {
    const itemDim = [["csv", selectedData, 0]];
    setState(
      produce((draft) => {
        draft.itemDimensions =
          itemDim as DataItemDimensionIdentifierInterface[];
      })
    );
  }, [selectedData]);

  const parseTimestamp = (timestampString) => {
    const formats = [
      "YYYY-MM-DDTHH:mm:ssZ", // ISO 8601-Format
      "YYYY-MM-DD HH:mm:ss", // ISO 8601-Format Variante
      "MM/DD/YYYY HH:mm:ss", // Amerikanisches Datum und Uhrzeitformat
      "DD.MM.YYYY HH:mm:ss", // Europäisches Datum und Uhrzeitformat
      "ddd, DD MMM YYYY HH:mm:ss ZZ", // RFC 2822-Format
      "X", // Unix-Zeitstempel (Sekunden)
      "x", // Unix-Zeitstempel (Millisekunden)
      "YYYY-MM-DD", // Datum ohne Uhrzeit
      "MMM DD HH:mm:ss", // Datum und Uhrzeit ohne Jahr
      "DD MMM", // Datum ohne Uhrzeit und Jahr
    ];

    for (const format of formats) {
      const parsedDate = dayjs(timestampString, format);
      if (parsedDate.isValid()) {
        return parsedDate.toDate();
      }
    }

    return null;
  };

  const formatCSV = () => {
    const finalText =
      headerRow != null && headerRow > 1
        ? csvFullContent.slice(
            countCharactersBeforeNthNewline(headerRow, csvFullContent)
          )
        : csvFullContent;

    readString(finalText, {
      worker: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      header: headerRow != null ? true : false,
      complete: (results) => {
        console.log("---------------------------");
        console.log(results);
        console.log("---------------------------");
        setCSVFormat(results.data);
      },
    });
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error(
          `${file.name} ${t("opendash:monitoring.csv.explorer.file_upload_no_csv")}`
        );
      }
      return false;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        message.success(
          `${info.file.name} ${t("opendash:monitoring.csv.explorer.file_upload_successfully")}`
        );
        const file = info.fileList[0].originFileObj;
        setCSVFile(file);

        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result as string;
          setCSVFullContent(content);
          formatCSV();
          const lines = content.split("\n");
          const first10Lines = lines.slice(0, 10).join("\n");
          setCSVContent(first10Lines);
        };
        reader.readAsText(file);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Container>
      <StepNav>
        <Steps
          current={state.step}
          onChange={(step) =>
            setState(
              produce((draft) => {
                draft.step = step;
              })
            )
          }
        >
          <Steps.Step
            title={t("opendash:monitoring.explorer.step_data_title")}
            description={t("opendash:monitoring.explorer.step_data_subtitle", {
              count: Math.max(state.itemDimensions?.length, 0),
            })}
          />
          {/* 
          <Steps.Step
            title={t("opendash:monitoring.explorer.step_fetching_title")}
            description={t(
              "opendash:monitoring.explorer.step_fetching_subtitle",
              {
                number:
                  state.fetchingOptions.historyType == "relative"
                    ? state.fetchingOptions.value
                    : state.fetchingOptions.historyType == "absolute"
                      ? t(
                          "opendash:monitoring.explorer.step_fetching_time_absolute"
                        )
                      : t("opendash:monitoring.history_options.type_live"),
                type:
                  state.fetchingOptions.historyType == "relative"
                    ? state.fetchingOptions.value > 1
                      ? t(
                          "opendash:ui." +
                            state.fetchingOptions.unit +
                            "_plural"
                        )
                      : t("opendash:ui." + state.fetchingOptions.unit)
                    : "",
              }
            )}
          />*/}

          <Steps.Step
            title={t("opendash:monitoring.explorer.step_vis_title")}
            description={t("opendash:monitoring.explorer.step_vis_subtitle", {
              count: visualisations.filter((v) => !v.disabled).length,
            })}
          />

          <Steps.Step
            title={t("opendash:monitoring.explorer.step_settings_title")}
            description={t(
              "opendash:monitoring.explorer.step_settings_subtitle",
              {
                status: t("opendash:monitoring.explorer.step_settings_desc"),
              }
            )}
          />

          <Steps.Step
            title={t("opendash:monitoring.explorer.step_preview_title")}
            description={t(
              "opendash:monitoring.explorer.step_preview_subtitle",
              {
                status:
                  Math.max(state.itemDimensions?.length, 0) > 0 &&
                  visualisations.filter((v) => !v.disabled).length > 0
                    ? t("opendash:monitoring.explorer.step_preview_desc")
                    : "N/A",
              }
            )}
          />
        </Steps>
      </StepNav>

      <div style={{ width: "100%", height: "20px" }}></div>
      <div
        style={{
          backgroundColor: "var(--opendash-color-white)",
          padding: "2px",
          paddingTop: "10px",
        }}
      >
        <SettingsHolder>
          <Tabs
            activeKey={state.step.toString()}
            renderTabBar={() => <React.Fragment />}
          >
            <Tabs.TabPane
              tab={t("opendash:monitoring.explorer.step_data_title")}
              key="0"
            >
              <Description
                children={t(
                  "opendash:monitoring.explorer.step_data_description"
                )}
              />

              {csvContent && (
                <>
                  <pre>{csvContent}</pre>
                  <label>
                    Select Header Row:
                    <select
                      value={headerRow !== null ? headerRow.toString() : "-1"}
                      onChange={handleHeaderRowChange}
                    >
                      <option value="-1">No Header</option>
                      {Array.from(Array(20).keys()).map((index) => (
                        <option key={index} value={index.toString()}>
                          Row {index + 1}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Select Time Row:
                    <select
                      value={timeSplit !== "" ? timeSplit.toString() : "1"}
                      onChange={handleTimeSplitChange}
                    >
                      <option value="-1">No Time</option>
                      {csvFormat.length > 0 &&
                        !Array.isArray(csvFormat[0]) &&
                        Object.keys(csvFormat[0]).map((element, index) => (
                          <option key={index} value={element}>
                            {element}
                          </option>
                        ))}
                    </select>
                  </label>
                  <label>
                    Select Value Row:
                    <select
                      value={
                        selectedData !== "" ? selectedData.toString() : "1"
                      }
                      onChange={handleselectedDataChange}
                    >
                      <option value="-1">No Time</option>
                      {csvFormat.length > 0 &&
                        !Array.isArray(csvFormat[0]) &&
                        Object.keys(csvFormat[0]).map((element, index) => (
                          <option key={index} value={element}>
                            {element}
                          </option>
                        ))}
                    </select>
                  </label>
                </>
              )}

              <div style={{ padding: "20px" }}>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {t("opendash:monitoring.csv.explorer.drag_and_drop")}
                  </p>
                  <p className="ant-upload-hint">
                    {t(
                      "opendash:monitoring.csv.explorer.drag_and_drop_description"
                    )}
                  </p>
                </Dragger>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t("opendash:monitoring.explorer.step_fetching_title")}
              key="99"
            >
              <Description
                children={t(
                  "opendash:monitoring.explorer.step_fetching_description"
                )}
              />
              <DataItemHistoryOptionsPicker
                options={{ live: true, history: true, aggregation: false }}
                value={state.fetchingOptions}
                onChange={(nextValue) => {
                  setState(
                    produce((draft) => {
                      draft.fetchingOptions = nextValue;
                    })
                  );
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t("opendash:monitoring.explorer.step_vis_title")}
              key="1"
            >
              <Description
                children={t(
                  "opendash:monitoring.explorer.step_vis_description"
                )}
              />
              {visualisations.filter((v) => !v.disabled).length === 0 && (
                <Alert
                  type="error"
                  style={{ marginBottom: 24 }}
                  message={t(
                    "opendash:monitoring.explorer.step_vis_none_available"
                  )}
                />
              )}

              <IconSelect
                options={visualisations}
                value={state.visualisation}
                size={5}
                onChange={(nextValue) => {
                  setState(
                    produce((draft) => {
                      draft.visualisation = nextValue;
                    })
                  );
                }}
              />
              {/* <SettingsComponent {...context} /> */}
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={t("opendash:monitoring.explorer.step_settings_title")}
              key="2"
            >
              <Description
                children={t(
                  "opendash:monitoring.explorer.step_settings_description"
                )}
              />

              {state.visualisation &&
                !widgetBaseContext?.type?.settingsComponent && (
                  <Alert
                    type="info"
                    message={t(
                      "opendash:monitoring.explorer.step_settings_no_settings"
                    )}
                  />
                )}

              {!state.visualisation && (
                <Alert
                  type="error"
                  message={t(
                    "opendash:monitoring.explorer.visualisation_missing"
                  )}
                />
              )}

              {state.visualisation && state.step === 2 && (
                <WidgetSettingsRenderWithoutSteps
                  key={state.visualisation + "~" + state.step}
                  context={widgetContext}
                />
              )}
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={t("opendash:monitoring.explorer.step_preview_title")}
              key="3"
            >
              <Description
                children={t(
                  "opendash:monitoring.explorer.step_preview_description"
                )}
              />

              {!state.visualisation && (
                <Alert
                  type="error"
                  message={t(
                    "opendash:monitoring.explorer.visualisation_missing"
                  )}
                />
              )}

              {state.visualisation && state.step === 3 && (
                <div
                  ref={widgetBaseContext.containerRef}
                  style={{ height: 500 }}
                >
                  <WidgetComponentRender
                    key={state.visualisation + "~" + state.step}
                    baseContext={widgetBaseContext}
                  />
                </div>
              )}
            </Tabs.TabPane>
          </Tabs>
        </SettingsHolder>
      </div>
      <Divider />

      <div style={{ height: "20px", textAlign: "right" }}>
        <Space>
          {state.step > 0 && (
            <Button
              style={{ float: "right", left: "20", bottom: "10" }}
              children={t("opendash:ui.back")}
              onClick={() => {
                setState(
                  produce((draft) => {
                    draft.step -= 1;
                  })
                );
              }}
            />
          )}
          {state.step < 3 && (
            <Button
              style={{ float: "right", right: "20", bottom: "10" }}
              type="primary"
              children={t("opendash:ui.next")}
              onClick={() => {
                setState(
                  produce((draft) => {
                    draft.step += 1;
                  })
                );
              }}
            />
          )}
          {state.step === 3 && (
            <Button
              style={{ float: "right", right: "20", bottom: "10" }}
              type="primary"
              disabled={!state.visualisation}
              children={t("opendash:monitoring.explorer.save_to_dashboard")}
              onClick={() => {
                MonitoringService.addPresetsToDashboard(
                  MonitoringService.getCurrentDashboard(),
                  [
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    {
                      widget: {
                        type: state.visualisation,
                        config: widgetContext.config,
                      },
                    },
                  ]
                )
                  .then(() => {
                    message.success(
                      t(
                        "opendash:monitoring.explorer.save_to_dashboard_success"
                      )
                    );

                    $framework.router.navigate("/monitoring/dashboards");
                  })
                  .catch(() => {
                    message.error(
                      t("opendash:monitoring.explorer.save_to_dashboard_error")
                    );
                  });
              }}
            />
          )}
        </Space>
      </div>
    </Container>
  );
});
