import { Icon } from "@opendash/icons";
import { Button, Drawer, List, TreeSelect, Typography } from "antd";
import * as React from "react";
import { SourceInterface, useSource, useTranslation } from "..";

interface Props {
  value: SourceInterface;
  onChange(source: SourceInterface): void;
  style?: React.CSSProperties;
  asButton?: boolean;
  allowClear?: boolean;
  showLine?: boolean;
  defaultExpanded?: boolean;
}

export function SourcePicker({
  value,
  onChange,
  style,
  asButton = false,
  allowClear = false,
  showLine = false,
  defaultExpanded = true,
}: Props) {
  const [, , sources] = useSource();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const treeData = React.useMemo(() => mapSources(sources), [sources]);
  const t = useTranslation();
  return (
    <>
      {!asButton && (
        <TreeSelect
          style={style}
          disabled={sources.length < 2}
          value={value?.id}
          onChange={(id) =>
            onChange(sources.find((source) => source.id === id))
          }
          treeData={treeData}
          treeDefaultExpandAll={defaultExpanded}
          treeNodeFilterProp="title"
          showSearch={true}
          allowClear={allowClear}
          treeLine={showLine}
        />
      )}
      {asButton && (
        <>
          <Button
            style={{ ...style, color: "var(--opendash-color-gray)" }}
            ghost
            icon={<Icon icon="fa:angle-down" />}
            onClick={() => {
              setShowDrawer(true);
            }}
          >
            <Typography.Text ellipsis={true}>{value?.name}</Typography.Text>
          </Button>
          <Drawer
            open={showDrawer}
            closable={true}
            title={t("opendash:sources.label_plural")}
            placement="top"
            height={"70%"}
            onClose={() => {
              setShowDrawer(false);
            }}
          >
            <List
              size="large"
              dataSource={treeData}
              renderItem={(item) => {
                return renderItem(item, 0, (id) => {
                  onChange(sources.find((source) => source.id === id));
                  setShowDrawer(false);
                });
              }}
            />
          </Drawer>
        </>
      )}
    </>
  );
}
function renderItem(item, level, select) {
  return (
    <>
      <List.Item
        onClick={() => {
          select(item.value);
        }}
        style={{ marginLeft: "" + level * 20 + "px" }}
      >
        {item.title}
        <span style={{ float: "right" }}>
          <Icon icon="fa:chevron-right"></Icon>
        </span>
      </List.Item>
      {item.children.map((child) => {
        return renderItem(child, level + 1, select);
      })}
    </>
  );
}
function mapSources(
  sources: SourceInterface[],
  parent: string = null,
  inTree: string[] = []
) {
  return sources
    .filter((source) => {
      if (inTree.includes(source.id)) {
        return false;
      }

      if (!parent) {
        return !source.parent;
      } else {
        return source.parent === parent;
      }
    })
    .map((source) => {
      return {
        title: source.name,
        value: source.id,
        children: mapSources(sources, source.id, [...inTree, source.id]),
      };
    });
}
