import Parse from "parse";
import React from "react";

import { Form } from "antd";

import { useTranslation } from "@opendash/core";

import { $parse } from "../state";
import { translateClassField, translateClassFieldDescription } from "./helper";
import { ParseField } from "./ParseField";

interface Props {
  className: string;
  fields: string[];
  state: Record<string, any>;
  setState: (state: Record<string, any>) => void;
  onSave: () => void;
}

export const ParseObjectForm = React.memo<Props>(function ParseObjectForm({
  className,
  fields,
  state,
  setState,
  onSave,
}) {
  const t = useTranslation();

  const fieldsSchema = $parse.ui.schemaMap[className]?.fields;

  const object = React.useMemo(
    () => new Parse.Object(className, state),
    [className, state]
  );

  return (
    <Form layout="vertical" onFinish={onSave}>
      {fields.map((field) => {
        const schema = fieldsSchema[field];

        if (!schema) {
          return null;
        }

        const isRequired = !!schema.required && !schema.defaultValue;
        const isNullable = !isRequired;

        return (
          <Form.Item
            key={field}
            label={translateClassField(t, className, field)}
            help={translateClassFieldDescription(t, className, field)}
          >
            <ParseField
              object={object}
              field={field}
              onChange={(v) => {
                setState((currentState) => ({ ...currentState, [field]: v }));
              }}
            />
          </Form.Item>
        );
      })}
    </Form>
  );
});
