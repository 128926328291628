import { Icon } from "@opendash/icons";
import { Button, Flex, Form, Input, Select } from "antd";
import { Rule } from "antd/es/form";
import React from "react";
import { CSSProperties } from "styled-components";
import { useOperationOptions } from "../hooks/useOperationOptions";
import { FormValues } from "../types/FormValues";

type Props = {
  index: number;
  onDelete: () => void;
};
export default (props: Props) => {
  const { fieldOptions, operationOptions } = useOperationOptions(props.index);
  const form = Form.useFormInstance<FormValues>();

  const styles: CSSProperties = { flex: 1, flexBasis: "50%", marginBottom: 32 };

  const rule: Rule = {
    required: true,
    message: "Please fill out this field.",
  };

  return (
    <Flex gap={8} align="center">
      <Form.Item
        label="Datenfeld"
        name={[props.index, "field"]}
        rules={[rule]}
        style={styles}
      >
        <Select
          options={fieldOptions}
          onChange={(value) =>
            form.setFieldValue(
              ["operations", props.index, "field"],
              fieldOptions.find((field) => field.value === value)
            )
          }
          value={form.getFieldValue(["operations", props.index, "field"])}
        />
      </Form.Item>
      <Form.Item
        label="Operator"
        name={[props.index, "operator"]}
        rules={[rule]}
        style={styles}
      >
        <Select options={operationOptions} />
      </Form.Item>
      <Form.Item
        label="Wert"
        name={[props.index, "value"]}
        rules={[rule]}
        style={styles}
      >
        <Input />
      </Form.Item>
      <Button onClick={props.onDelete} danger>
        <Icon icon="fa:trash" />
      </Button>
    </Flex>
  );
};
