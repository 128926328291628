import { useTranslation } from "@opendash/i18n";
import { Select } from "antd";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import React, { useEffect, useState } from "react";

type CustomFormItemProps = {
  value?: number[];
  onChange: (value: number[]) => void;
};

dayjs.extend(weekday);

export const WeekdaysSelectMultiple: React.FC<CustomFormItemProps> = ({
  value,
  onChange,
}) => {
  const t = useTranslation();
  const [currentValue, setCurrentValue] = useState<number[]>(value);
  const days = [
    {
      label: t("maintenance:global.days.monday"),
      value: 0,
    },
    {
      label: t("maintenance:global.days.tuesday"),
      value: 1,
    },
    {
      label: t("maintenance:global.days.wednesday"),
      value: 2,
    },
    {
      label: t("maintenance:global.days.thursday"),
      value: 3,
    },
    {
      label: t("maintenance:global.days.friday"),
      value: 4,
    },
    {
      label: t("maintenance:global.days.saturday"),
      value: 5,
    },
    {
      label: t("maintenance:global.days.sunday"),
      value: 6,
    },
  ];

  useEffect(() => {
    //Propagate the current Value on component activation/when it get's visible
    onChange(currentValue);
  }, []);

  return (
    <Select
      mode="multiple"
      allowClear
      style={{ width: "100%", marginBottom: 24 }}
      defaultValue={currentValue}
      onChange={(v) => {
        onChange(v);
        setCurrentValue(v);
      }}
      options={days}
    />
  );
};
