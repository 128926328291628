import Parse from "parse";
import React from "react";
import styled from "styled-components";

import { Affix, Button, Drawer, Modal } from "antd";

import { useFeedback, useTranslation } from "@opendash/core";

import { Icon } from "@opendash/icons";
import { ParseObjectForm } from "../ParseObjectForm";
import { translateClassDialog } from "../helper";

const AffixContainer = styled.div`
  background: white;
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  // margin: 0 -20px;
  // width: calc(100% + 40px);
  // padding: 20px;
  // box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
`;

export const ButtonContainer = React.memo<
  React.PropsWithChildren<{ hasChanges: boolean }>
>(function ButtonContainer({ children, hasChanges }) {
  const [isAffix, setAffix] = React.useState(false);

  if (!hasChanges) {
    return (
      <AffixContainer style={{ border: "none" }}>{children}</AffixContainer>
    );
  }

  return (
    <Affix offsetBottom={0} onChange={setAffix}>
      <AffixContainer style={!isAffix ? { border: "none" } : {}}>
        {children}
      </AffixContainer>
    </Affix>
  );
});

interface Props {
  className: string;
  object: Parse.Object;
  fields: string[];
  onClose: (objectId: string | null) => void;
  type?: "modal" | "drawer" | "inline";
}

export const ObjectDialog = React.memo<Props>(function ObjectDialog({
  className,
  fields,
  object,
  onClose,
  type,
}) {
  const t = useTranslation();
  const { message } = useFeedback();

  const mode = object?.id ? "edit" : "create";

  const [changes, setChanges] = React.useState({});

  React.useEffect(() => {
    setChanges(
      Object.fromEntries(fields.map((field) => [field, object.get(field)]))
    );
  }, [object, fields]);

  const onSave = () => {
    for (const field of fields) {
      const value = changes[field];

      // const type = store.schemaMap[className].fields[field].type;

      // if (type === "GeoPoint") {
      //   object.set(field, new Parse.GeoPoint(value));
      //   return;
      // }

      object.set(field, value);
    }

    object.save().then(
      () => {
        message.success(t(`parse-admin:admin.${mode}.success`));

        onClose(object.id);
      },
      (error) => {
        message.error(t(`parse-admin:admin.${mode}.error`));
      }
    );
  };

  if (type === "inline") {
    if (!(className && object)) {
      return null;
    }

    return (
      <>
        <ParseObjectForm
          className={className}
          fields={fields}
          state={changes}
          setState={setChanges}
          onSave={onSave}
        />

        <ButtonContainer hasChanges={Object.keys(changes).length > 0}>
          <Button
            type="primary"
            icon={<Icon icon="fa:check" />}
            children={t(`parse-admin:admin.${mode}.save`)}
            onClick={onSave}
          />
        </ButtonContainer>
      </>
    );
  }

  if (type === "drawer") {
    return (
      <Drawer
        open={!!(className && object)}
        title={translateClassDialog(t, className, mode)}
        width={520}
        onClose={(e) => onClose(null)}
        children={
          <>
            <ParseObjectForm
              className={className}
              fields={fields}
              state={changes}
              setState={setChanges}
              onSave={onSave}
            />
            <ButtonContainer hasChanges={Object.keys(changes).length > 0}>
              <Button
                type="primary"
                icon={<Icon icon="fa:check" />}
                children={t(`parse-admin:admin.${mode}.save`)}
                onClick={onSave}
              />
            </ButtonContainer>
          </>
        }
      />
    );
  }

  return (
    <Modal
      open={!!(className && object)}
      title={translateClassDialog(t, className, mode)}
      onCancel={(e) => onClose(null)}
      onOk={onSave}
      okText={t(`parse-admin:admin.${mode}.save`)}
      styles={{ body: { maxHeight: "70vh", overflow: "auto" } }}
      children={
        <ParseObjectForm
          className={className}
          fields={fields}
          state={changes}
          setState={setChanges}
          onSave={onSave}
        />
      }
    />
  );
});
