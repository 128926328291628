import { useFeedback, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { DataItemInterface } from "@opendash/plugin-timeseries";
import {
  Button,
  Card,
  Divider,
  InputNumber,
  Popconfirm,
  Result,
  Select,
  Typography,
} from "antd";
import React from "react";
import { useOpenWareContext } from "../common/OpenWareContext";

import dayjs from "dayjs";
const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
type ScheduledDeleteProps = {
  item: DataItemInterface;
};
type Schedule = { name: string; secondsToExpire: number };
const ScheduledDelete = ({ item }: ScheduledDeleteProps) => {
  const [scheduled, setScheduled] = React.useState<Schedule | null>(null);
  const [originalSchedule, setOriginalSchedule] =
    React.useState<Schedule | null>(null);
  const [editInterval, setEditInterval] = React.useState<{
    amount: number;
    unit: dayjs.UnitType;
  } | null>(null);
  const t = useTranslation();
  const ow = useOpenWareContext();
  const { message } = useFeedback();

  const init = async () => {
    const res = await ow.fetch(`/api/data/deletes/${item.source}/${item.id}`);
    const data = await res.json();
    setScheduled(data.result[0]);
    setOriginalSchedule(data.result[0]);
    setEditInterval(null);
  };
  React.useEffect(() => {
    init();
  }, []);

  const addSchedule = () => {
    setScheduled({ name: "updatedAt_1", secondsToExpire: 60 * 60 * 24 * 365 });
    setEditInterval({ amount: 1, unit: "year" });
  };
  const removeSchedule = async () => {
    const res = await ow.fetch(`/api/data/deletes/${item.source}/${item.id}`, {
      method: "DELETE",
    });
    if (res.status === 200) {
      message.success(t("openware:admin.sensor.delete.notScheduled"));
      init();
    } else {
      message.error(t("openware:admin.sensor.delete.scheduleSuccess"));
    }
  };
  const saveSchedule = async () => {
    const schedule = {
      secondsToLive:
        //@ts-ignore
        dayjs.duration(editInterval.amount, editInterval.unit).asSeconds(),
    };
    const res = await ow.fetch(
      `/api/data/deletes/${encodeURIComponent(
        item.source
      )}/${encodeURIComponent(item.id)}`,
      {
        method: "POST",
        body: JSON.stringify(schedule),
      }
    );
    if (res.status === 200) {
      message.success(t("openware:admin.sensor.delete.scheduleSuccess"));
      init();
    } else {
      message.error(t("openware:admin.sensor.delete.notScheduled"));
    }
  };

  return (
    <>
      {scheduled && (
        <div>
          <Card
            title={t("openware:admin.sensor.delete.scheduled")}
            extra={
              !editInterval ? (
                <Button
                  type="link"
                  icon={<Icon icon="fa:pen" />}
                  onClick={() => {
                    setEditInterval({ amount: 1, unit: "year" });
                  }}
                />
              ) : (
                <>
                  <Button
                    type="link"
                    icon={
                      <Icon
                        icon="fa:times"
                        onClick={() => {
                          setScheduled(originalSchedule);
                          setEditInterval(null);
                        }}
                      />
                    }
                  />
                  <Button
                    type="link"
                    icon={
                      <Icon
                        icon="fa:save"
                        onClick={() => {
                          saveSchedule();
                        }}
                      />
                    }
                  />
                  <Divider type="vertical" />
                  <Popconfirm
                    title={t("opendash:ui.delete") + "?"}
                    onConfirm={removeSchedule}
                  >
                    <Button type="link" icon={<Icon icon="fa:trash" />} />
                  </Popconfirm>
                </>
              )
            }
          >
            <Typography.Text type="secondary">
              {`${t("openware:admin.sensor.delete.scheduledDescription", {
                name: item.name,
              })} `}
            </Typography.Text>
            {!editInterval && (
              <Typography.Text>
                {dayjs
                  //@ts-ignore
                  .duration(scheduled.secondsToExpire * 1000)
                  .humanize()}
              </Typography.Text>
            )}
            {editInterval && (
              <div style={{ display: "flex", gap: 8, flexDirection: "row" }}>
                <InputNumber
                  min={1}
                  step={1}
                  value={editInterval.amount}
                  onChange={(amount) => {
                    setEditInterval({
                      ...editInterval,
                      amount,
                    });
                  }}
                ></InputNumber>
                <Select
                  value={editInterval.unit}
                  style={{ width: 200 }}
                  onChange={(unit) => {
                    setEditInterval({
                      ...editInterval,
                      unit,
                    });
                  }}
                >
                  <Select.Option value={"hour"} label={t("opendash:ui.hour")}>
                    {t(
                      "opendash:ui.hour" +
                        (editInterval.amount > 1 ? "_plural" : "")
                    )}
                  </Select.Option>
                  <Select.Option value={"day"} label={t("opendash:ui.day")}>
                    {t(
                      "opendash:ui.day" +
                        (editInterval.amount > 1 ? "_plural" : "")
                    )}
                  </Select.Option>
                  <Select.Option value={"week"} label={t("opendash:ui.week")}>
                    {t(
                      "opendash:ui.week" +
                        (editInterval.amount > 1 ? "_plural" : "")
                    )}
                  </Select.Option>
                  <Select.Option value={"year"} label={t("opendash:ui.year")}>
                    {t(
                      "opendash:ui.year" +
                        (editInterval.amount > 1 ? "_plural" : "")
                    )}
                  </Select.Option>
                </Select>
              </div>
            )}
          </Card>
        </div>
      )}
      {!scheduled && (
        <Result
          title={t("openware:admin.sensor.delete.notScheduled")}
          icon={<Icon icon="fa:trash" />}
          extra={
            <Button type="primary" onClick={addSchedule}>
              {t("openware:admin.sensor.delete.activateSchedule")}
            </Button>
          }
        ></Result>
      )}
    </>
  );
};

export { ScheduledDelete };
