import Parse from "parse";

import {
  AuthError,
  AuthPayloadInterface,
  copy,
  UserAdapterContext,
  UserAdapterInterface,
  UserInterface,
} from "@opendash/core";

import { $parse } from "..";
import { AdapterConfig } from "../types";
import { _User, Config, Permission, Push } from "../types-generated";

export class ParseUserAdapter implements UserAdapterInterface {
  private context: UserAdapterContext;
  private config: AdapterConfig;

  constructor(config: AdapterConfig) {
    this.config = config;

    $parse.server.subscription.watch(async () => await this.init());
  }

  async onContext(context: UserAdapterContext) {
    this.context = context;

    await this.init();
  }

  private async init() {
    await $parse.server.lock.wait();

    if (!this.context) {
      return void console.log("Init called before context is available.");
    }

    try {
      await this.parseUrlParams();

      this.context.setCurrentUser($parse.server.user);

      if ($parse.server.user) {
        await this.fetchConfig();
        await this.fetchPermissions();
      }

      this.context.setLoading(false);
      this.context.setValidated(true);
      this.context.setOffline(false);
    } catch (error) {
      console.error(error);

      this.context.setCurrentUser(undefined);
      this.context.setLoading(false);
      this.context.setValidated(false);
      this.context.setOffline(false);

      this.handleError(error, "UNKNOWN_ERROR");
    }
  }

  async login(payload: AuthPayloadInterface) {
    try {
      try {
        await Parse.User.logIn(
          payload.username.toLowerCase(),
          payload.password
        );
      } catch (error) {
        if (this.config.ldap.active) {
          await this.loginWithCloudCode(
            payload.username.toLowerCase(),
            payload.password
          );
        } else {
          throw error;
        }
      }
    } catch (error) {
      console.error(error);
      this.handleError(error, "LOGIN_BAD_USER_PASSWORD");
    }

    await this.init();
  }

  private async loginWithCloudCode(username: string, password: string) {
    const user = await Parse.Cloud.run(this.config.ldap.functionName, {
      username,
      password,
    });

    if (!user && !user.session) {
      throw new AuthError("UNKNOWN_ERROR");
    }

    await Parse.User.become(user.session);
  }

  async register(payload: AuthPayloadInterface) {
    try {
      const user = new Parse.User();

      payload = copy(payload);

      payload.email = payload.email?.toLowerCase();
      payload.username = payload.username?.toLowerCase();

      if (this.config.auth.payloadMapper) {
        payload = await Promise.resolve(
          this.config.auth.payloadMapper(payload)
        );
      }

      await user.signUp(payload);
      await this.init();
    } catch (error) {
      this.handleError(error, "SIGNUP_UNKNOWN_ERROR");
    }
  }

  async logout() {
    await $parse.auth.logout();
  }

  async changePassword(data) {
    $parse.auth.changePassword({ password: data.password });
  }

  private async validateSession(): Promise<UserInterface> {
    const user = await Parse.User.currentAsync<_User>();

    if (user && !navigator.onLine) {
      const result: UserInterface = {
        id: user.id,
        name: user.get("name") as string,
        email: user.getEmail(),
        username: user.getUsername(),
        session: user.getSessionToken(),
      };

      return result;
    }

    if (!user) {
      throw new AuthError("SESSION_UNKNOWN_ERROR");
    }

    try {
      await user.fetchWithInclude(["tenant"]);

      const result: UserInterface = {
        id: user.id,
        name: user.get("name") as string,
        email: user.getEmail(),
        username: user.getUsername(),
        session: user.getSessionToken(),
      };

      return result;
    } catch (error) {
      await this.logout();

      throw new AuthError("SESSION_EXPIRED");
    }
  }

  private async parseUrlParams() {
    try {
      let url = new URL(window.location.href);

      const urlSessionToken = url.searchParams.get("session");

      if (urlSessionToken) {
        await Parse.User.become(urlSessionToken);

        // just in case the url changes while calling Parse.User.become()
        url = new URL(window.location.href);

        url.searchParams.delete("session");

        window.history.replaceState(null, "", url.href);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async fetchConfig(): Promise<void> {
    if (!Config) {
      return;
    }

    if (!navigator.onLine) {
      return;
    }

    const user = await Parse.User.currentAsync<_User>();

    if (!user) {
      throw new Error("No current user");
    }

    try {
      const query = new Parse.Query(Config);

      const result = await query.descending("priority").limit(999999).find();

      const config: Record<string, string> = {};

      for (const row of result) {
        if (!(row.get("key") in config)) {
          config[row.get("key")] = row.get("value");
        }
      }

      this.context.setConfig(config);
    } catch (error) {
      console.log("Config fetching skipped.");
    }
  }

  private async fetchPermissions(): Promise<void> {
    if (!Permission) {
      return;
    }

    if (!navigator.onLine) {
      return;
    }

    const user = await Parse.User.currentAsync<_User>();

    if (!user) {
      throw new Error("No current user");
    }

    try {
      const query = new Parse.Query(Permission).limit(999999);

      const result = await query.find();

      const permissions = result.map((obj) => obj.get("key"));

      this.context.setPermissions(permissions);
    } catch (error) {
      console.log("Permission fetching skipped.");
    }
  }

  public async handlePushSubscription(
    id: string | undefined,
    type: string,
    data: any
  ) {
    const label = navigator.userAgent;

    const obj = id ? await new Parse.Query(Push).get(id) : new Push();

    obj.set("user", await Parse.User.currentAsync<_User>());
    obj.set("label", label);
    obj.set("type", type);
    obj.set("data", data);

    await obj.save();

    return obj.id;
  }

  private handleError(
    error: unknown,
    defaultErrorCode: typeof AuthError.prototype.type
  ) {
    if (error instanceof AuthError) {
      throw error;
    }

    if (error instanceof Parse.Error) {
      if (error.message === "OPENDASH_ACCOUNT_TENANT_VERIFICATION") {
        throw new AuthError("LOGIN_MISSING_VERIFICATION");
      }

      if (error.message === "OPENDASH_ACCOUNT_EMAIL_VERIFICATION") {
        throw new AuthError("LOGIN_MISSING_EMAIL_VERIFICATION");
      }

      if (error.message === "OPENDASH_ACCOUNT_BANNED") {
        throw new AuthError("LOGIN_BANNED");
      }

      switch (error.code) {
        case Parse.Error.INVALID_SESSION_TOKEN:
        case Parse.Error.SESSION_MISSING:
        case Parse.Error.INVALID_LINKED_SESSION:
          throw new AuthError("SESSION_UNKNOWN_ERROR");
        case Parse.Error.VALIDATION_ERROR:
        case Parse.Error.USERNAME_MISSING:
        case Parse.Error.PASSWORD_MISSING:
          throw new AuthError("SIGNUP_VALIDATION_ERROR");
        case Parse.Error.USERNAME_TAKEN:
        case Parse.Error.INVALID_EMAIL_ADDRESS:
        case Parse.Error.EMAIL_TAKEN:
        case Parse.Error.EMAIL_MISSING:
        case Parse.Error.EMAIL_NOT_FOUND:
        case Parse.Error.MUST_CREATE_USER_THROUGH_SIGNUP:
        case Parse.Error.ACCOUNT_ALREADY_LINKED:
          throw new AuthError("SIGNUP_UNKNOWN_ERROR");
        case Parse.Error.OBJECT_NOT_FOUND:
          if (error.message === "Invalid username/password.") {
            throw new AuthError("LOGIN_BAD_USER_PASSWORD");
          }
      }
    }

    throw new AuthError(defaultErrorCode);
  }
}
