import { Icon } from "@opendash/icons";
import { useNavigate } from "@opendash/router";
import {
  Card,
  Checkbox,
  Empty,
  Modal,
  Pagination,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNotifications } from "..";
import { useOpenDashApp, useTranslation } from "../../..";

export const NotificationDialog = observer(function NotificationDialog() {
  const t = useTranslation();
  const app = useOpenDashApp();
  const navigate = useNavigate();
  const { Title } = Typography;
  const [
    notifications,
    notificationsCount,
    ,
    showRead,
    setShowRead,
    markAsRead,
    loadmore,
    maxpagesize,
    loading,
  ] = useNotifications();

  return (
    <Modal
      title={t("opendash:notification.label")}
      cancelText={t("opendash:ui.close")}
      open={app.ui2.notificationsDialogOpen}
      onOk={() => app.ui2.hideNotificationsDialog()}
      onCancel={() => app.ui2.hideNotificationsDialog()}
      okButtonProps={{ style: { display: "none" } }}
      footer={[
        <Checkbox
          key="showread"
          checked={showRead}
          onChange={(e) => setShowRead(e.target.checked)}
        >
          {t("opendash:notification.showread")}
        </Checkbox>,
      ]}
    >
      {notificationsCount === 0 && !loading ? (
        <Empty description={t("opendash:notification.empty")} />
      ) : null}

      {notifications.map((notification) => {
        return (
          <Card
            key={notification.id}
            size="small"
            title={dayjs(notification.get("createdAt")).format(
              "DD.MM.YYYY @ HH:mm:ss"
            )}
            style={{ width: "100%", marginBottom: "30px" }}
            actions={[
              notification.get("read") ? (
                <Tooltip
                  title={t("opendash:notification.markasunread")}
                  key="markasunread"
                >
                  <Icon
                    icon="fa:envelope-open"
                    onClick={() => markAsRead(notification, false)}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={t("opendash:notification.markasread")}
                  key="markasread"
                >
                  <Icon
                    icon="fa:solid:envelope"
                    onClick={() => markAsRead(notification, true)}
                  />
                </Tooltip>
              ),
              notification.data && notification.data.ticketid && (
                <Tooltip
                  title={t("opendash:notification.showmore")}
                  key="moreinfo"
                >
                  <Icon
                    icon="fa:external-link-alt"
                    onClick={() => {
                      app.ui2.hideNotificationsDialog();
                      navigate(
                        "/openservice/ticket/" + notification.data.ticketid
                      );
                    }}
                  />
                </Tooltip>
              ),
            ]}
          >
            <Title level={5}>{notification.get("title")}</Title>
            <p>{notification.get("description")}</p>
          </Card>
        );
      })}
      {!loading && notifications.length > 0 ? (
        <Pagination
          onChange={loadmore}
          total={notificationsCount}
          defaultPageSize={maxpagesize}
        />
      ) : null}
    </Modal>
  );
});
