import { CustomParseFieldProps } from "@opendash/plugin-parse";
import { Button, Form, Tag } from "antd";
import Parse from "parse";
import React from "react";
import FormContentWrapper from "./FormContentWrapper";
import { FormValues, Operation } from "./types/FormValues";

type Props = CustomParseFieldProps & {};

export default (props: Props) => {
  const [form] = Form.useForm();

  if (!props.setValue) return <Tag>Object</Tag>;

  function onSubmit(values: FormValues) {
    console.log(values);
    const query = new Parse.Query(values.className).select(values.keys);
    for (const key in values.operations) {
      const operation = values.operations[key];
      addOperationToQuery(query, operation);
    }

    // TODO: Noch nicht getestet ob operations funktionieren.
    console.log(query.toJSON());
  }

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <FormContentWrapper />
      <Button htmlType="submit">Show Form Values</Button>
    </Form>
  );
};

function addOperationToQuery(query: Parse.Query, operation: Operation) {
  const typedValue =
    operation.field.type === "number"
      ? Number(operation.value)
      : operation.value;

  switch (operation.operator) {
    case "equalTo":
      query.equalTo(operation.field.value, typedValue);
      break;
    case "notEqualTo":
      query.notEqualTo(operation.field.value, typedValue);
      break;
    case "lessThan":
      query.lessThan(operation.field.value, typedValue);
      break;
    case "lessThanOrEqualTo":
      query.lessThanOrEqualTo(operation.field.value, typedValue);
      break;
    case "greaterThan":
      query.greaterThan(operation.field.value, typedValue);
      break;
    case "greaterThanOrEqualTo":
      query.greaterThanOrEqualTo(operation.field.value, typedValue);
      break;
  }
}
