import { translateSync } from "@opendash/i18n";
import { Modal } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import { Workbox } from "workbox-window";
import type { FrameworkService } from "..";

export class ServiceWorkerService {
  private framework: FrameworkService;
  private wb = null;
  private updatePromise = null;
  public updateResolver = null;
  public updateForce = false;
  public updateAvailable = false;
  public updateLoading = false;

  constructor(framework) {
    makeAutoObservable(this);
    this.framework = framework;
  }
  init(active) {
    if (active) {
      this.registerServiceWorker();
    } else {
      this.removeServiceWorkers();
    }
  }
  registerServiceWorker(path = "/service-worker.js") {
    try {
      if (this.wb) {
        return void console.error(
          `[@opendash/core] $framework.serviceWorker.registerServiceWorker() was called twice.`
        );
      }
      if ("serviceWorker" in navigator) {
        this.wb = new Workbox(path);
        this.wb.addEventListener("waiting", (event) => {
          runInAction(() => {
            this.updateAvailable = true;
          });
        });
        this.wb.addEventListener("controlling", (event) => {
          runInAction(() => {
            this.updateLoading = false;
          });
          if (this.updateResolver) {
            this.updateResolver();
          }
          this.updatePromise = null;
          this.updateResolver = null;
          if (this.updateForce) {
            window.location.reload();
          } else {
            this.confirmUpdateDialog();
          }
        });
        this.wb.register();
      }
    } catch (error) {
      console.error("No service worker", error);
    }
  }

  async installUpdate(force?: boolean) {
    if (this.updatePromise) {
      return this.updatePromise;
    }
    this.updateForce = !!force;
    this.updatePromise = new Promise<void>((resolve) => {
      runInAction(() => {
        this.updateResolver = resolve;
        this.updateLoading = true;
      });
      if (this.wb && this.updateAvailable) {
        this.wb.messageSW({ type: "SKIP_WAITING" });
      } else {
        resolve();
      }
    });
    return this.updatePromise;
  }

  installUpdateDialog() {
    Modal.confirm({
      title: translateSync("opendash:ui.version_update_dialog_title"),
      content: translateSync("opendash:ui.version_update_dialog_description"),
      icon: false,
      okText: translateSync("opendash:ui.install"),
      cancelText: translateSync("opendash:ui.cancel"),
      onOk: () => this.installUpdate(),
    });
  }

  confirmUpdateDialog() {
    Modal.confirm({
      title: translateSync("opendash:ui.version_install_dialog_title"),
      content: translateSync("opendash:ui.version_install_dialog_description"),
      icon: false,
      okText: translateSync("opendash:ui.ok"),
      cancelText: translateSync("opendash:ui.cancel"),
      onOk: () => void window.location.reload(),
    });
  }

  removeServiceWorkers() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }
  }
}
