import Parse from "parse";

export function getErrorMessage(error: Parse.Error) {
  const code: number = error.code;
  const message: string = error.message;

  if (message && message.startsWith("t:")) {
    return message.replace("t:", "parse-admin:errors_custom.");
  }

  switch (code) {
    case Parse.Error.OTHER_CAUSE:
      return "parse-admin:errors.OTHER_CAUSE";
    case Parse.Error.INTERNAL_SERVER_ERROR:
      return "parse-admin:errors.INTERNAL_SERVER_ERROR";
    case Parse.Error.CONNECTION_FAILED:
      return "parse-admin:errors.CONNECTION_FAILED";
    case Parse.Error.OBJECT_NOT_FOUND:
      return "parse-admin:errors.OBJECT_NOT_FOUND";
    case Parse.Error.INVALID_QUERY:
      return "parse-admin:errors.INVALID_QUERY";
    case Parse.Error.INVALID_CLASS_NAME:
      return "parse-admin:errors.INVALID_CLASS_NAME";
    case Parse.Error.MISSING_OBJECT_ID:
      return "parse-admin:errors.MISSING_OBJECT_ID";
    case Parse.Error.INVALID_KEY_NAME:
      return "parse-admin:errors.INVALID_KEY_NAME";
    case Parse.Error.INVALID_POINTER:
      return "parse-admin:errors.INVALID_POINTER";
    case Parse.Error.INVALID_JSON:
      return "parse-admin:errors.INVALID_JSON";
    case Parse.Error.COMMAND_UNAVAILABLE:
      return "parse-admin:errors.COMMAND_UNAVAILABLE";
    case Parse.Error.NOT_INITIALIZED:
      return "parse-admin:errors.NOT_INITIALIZED";
    case Parse.Error.INCORRECT_TYPE:
      return "parse-admin:errors.INCORRECT_TYPE";
    case Parse.Error.INVALID_CHANNEL_NAME:
      return "parse-admin:errors.INVALID_CHANNEL_NAME";
    case Parse.Error.PUSH_MISCONFIGURED:
      return "parse-admin:errors.PUSH_MISCONFIGURED";
    case Parse.Error.OBJECT_TOO_LARGE:
      return "parse-admin:errors.OBJECT_TOO_LARGE";
    case Parse.Error.OPERATION_FORBIDDEN:
      return "parse-admin:errors.OPERATION_FORBIDDEN";
    case Parse.Error.CACHE_MISS:
      return "parse-admin:errors.CACHE_MISS";
    case Parse.Error.INVALID_NESTED_KEY:
      return "parse-admin:errors.INVALID_NESTED_KEY";
    case Parse.Error.INVALID_FILE_NAME:
      return "parse-admin:errors.INVALID_FILE_NAME";
    case Parse.Error.INVALID_ACL:
      return "parse-admin:errors.INVALID_ACL";
    case Parse.Error.TIMEOUT:
      return "parse-admin:errors.TIMEOUT";
    case Parse.Error.INVALID_EMAIL_ADDRESS:
      return "parse-admin:errors.INVALID_EMAIL_ADDRESS";
    case Parse.Error.MISSING_CONTENT_TYPE:
      return "parse-admin:errors.MISSING_CONTENT_TYPE";
    case Parse.Error.MISSING_CONTENT_LENGTH:
      return "parse-admin:errors.MISSING_CONTENT_LENGTH";
    case Parse.Error.INVALID_CONTENT_LENGTH:
      return "parse-admin:errors.INVALID_CONTENT_LENGTH";
    case Parse.Error.FILE_TOO_LARGE:
      return "parse-admin:errors.FILE_TOO_LARGE";
    case Parse.Error.FILE_SAVE_ERROR:
      return "parse-admin:errors.FILE_SAVE_ERROR";
    case Parse.Error.DUPLICATE_VALUE:
      return "parse-admin:errors.DUPLICATE_VALUE";
    case Parse.Error.INVALID_ROLE_NAME:
      return "parse-admin:errors.INVALID_ROLE_NAME";
    case Parse.Error.EXCEEDED_QUOTA:
      return "parse-admin:errors.EXCEEDED_QUOTA";
    case Parse.Error.SCRIPT_FAILED:
      return "parse-admin:errors.SCRIPT_FAILED";
    case Parse.Error.VALIDATION_ERROR:
      return "parse-admin:errors.VALIDATION_ERROR";
    case Parse.Error.INVALID_IMAGE_DATA:
      return "parse-admin:errors.INVALID_IMAGE_DATA";
    case Parse.Error.UNSAVED_FILE_ERROR:
      return "parse-admin:errors.UNSAVED_FILE_ERROR";
    case Parse.Error.INVALID_PUSH_TIME_ERROR:
      return "parse-admin:errors.INVALID_PUSH_TIME_ERROR";
    case Parse.Error.FILE_DELETE_ERROR:
      return "parse-admin:errors.FILE_DELETE_ERROR";
    case Parse.Error.REQUEST_LIMIT_EXCEEDED:
      return "parse-admin:errors.REQUEST_LIMIT_EXCEEDED";
    case Parse.Error.INVALID_EVENT_NAME:
      return "parse-admin:errors.INVALID_EVENT_NAME";
    case Parse.Error.USERNAME_MISSING:
      return "parse-admin:errors.USERNAME_MISSING";
    case Parse.Error.PASSWORD_MISSING:
      return "parse-admin:errors.PASSWORD_MISSING";
    case Parse.Error.USERNAME_TAKEN:
      return "parse-admin:errors.USERNAME_TAKEN";
    case Parse.Error.EMAIL_TAKEN:
      return "parse-admin:errors.EMAIL_TAKEN";
    case Parse.Error.EMAIL_MISSING:
      return "parse-admin:errors.EMAIL_MISSING";
    case Parse.Error.EMAIL_NOT_FOUND:
      return "parse-admin:errors.EMAIL_NOT_FOUND";
    case Parse.Error.SESSION_MISSING:
      return "parse-admin:errors.SESSION_MISSING";
    case Parse.Error.MUST_CREATE_USER_THROUGH_SIGNUP:
      return "parse-admin:errors.MUST_CREATE_USER_THROUGH_SIGNUP";
    case Parse.Error.ACCOUNT_ALREADY_LINKED:
      return "parse-admin:errors.ACCOUNT_ALREADY_LINKED";
    case Parse.Error.INVALID_SESSION_TOKEN:
      return "parse-admin:errors.INVALID_SESSION_TOKEN";
    case Parse.Error.LINKED_ID_MISSING:
      return "parse-admin:errors.LINKED_ID_MISSING";
    case Parse.Error.INVALID_LINKED_SESSION:
      return "parse-admin:errors.INVALID_LINKED_SESSION";
    case Parse.Error.UNSUPPORTED_SERVICE:
      return "parse-admin:errors.UNSUPPORTED_SERVICE";
    case Parse.Error.AGGREGATE_ERROR:
      return "parse-admin:errors.AGGREGATE_ERROR";
    case Parse.Error.FILE_READ_ERROR:
      return "parse-admin:errors.FILE_READ_ERROR";
    case Parse.Error.X_DOMAIN_REQUEST:
      return "parse-admin:errors.X_DOMAIN_REQUEST";
    default:
      return "parse-admin:errors.OTHER_CAUSE";
  }
}
