import Parse from "parse";
import { ParseServerConfigResponse, PermissionInterface } from ".";

export async function getAdminConfig(): Promise<any> {
  return await Parse.Cloud.run("getAdminConfig");
}

export async function getSchemaForAdmin(): Promise<any> {
  return await Parse.Cloud.run("getSchemaForAdmin");
}

export async function opendashGeoFeatures(params: { example: string }): Promise<
  Array<{
    example: string;
  }>
> {
  return await Parse.Cloud.run("openinc-geo-features", params);
}

export async function runConfig(): Promise<ParseServerConfigResponse> {
  return await Parse.Cloud.run("openinc-config");
}

export async function runAuthConfirmEmail(params: { example: string }): Promise<
  Array<{
    example: string;
  }>
> {
  return await Parse.Cloud.run("openinc-auth-confirm-email", params);
}

export async function runAuthLogin(params: {
  login: string;
  password: string;
}): Promise<{
  sessionToken: string;
}> {
  return await Parse.Cloud.run("openinc-auth-login", params);
}

export async function runAuthLoginPasswordless(params: {
  email: string;
  url: string;
}): Promise<{
  success: true;
}> {
  return await Parse.Cloud.run("openinc-auth-login-passwordless", params);
}

export async function runAuthLoginAdmin(params: {
  userId?: string;
  email?: string;
  username?: string;
}): Promise<{
  sessionToken: string;
}> {
  return await Parse.Cloud.run("openinc-auth-login-admin", params);
}

export async function runAuthSignup(params: {
  email: string;
  password: string;
  name: string;
  tenant?: string;
  username?: string;
  setupToken?: string;
  tosAccepted: boolean;
}): Promise<{
  success: true;
  sessionToken?: string;
}> {
  return await Parse.Cloud.run("openinc-auth-signup", params);
}

export async function runAuthValidateToken(params: {
  token: string;
}): Promise<{ valid: boolean; expired: boolean }> {
  return await Parse.Cloud.run("openinc-auth-validate-token", params);
}

export async function runAuthChangePassword(params: {
  password: string;
  userId: string;
}): Promise<{ success: boolean }> {
  return await Parse.Cloud.run("openinc-auth-change-password", params);
}

export async function runAuthEmailVerificationRequest(params: {
  url: string;
  email: string;
}): Promise<{ sucess: true }> {
  return await Parse.Cloud.run(
    "openinc-auth-email-confirmation-request",
    params
  );
}

export async function runAuthEmailVerification(params: {
  token: string;
}): Promise<{ sucess: true }> {
  return await Parse.Cloud.run("openinc-auth-email-confirmation", params);
}

export async function runAuthPasswordResetRequest(params: {
  url: string;
  email: string;
}): Promise<{ sucess: true }> {
  return await Parse.Cloud.run("openinc-auth-password-reset-request", params);
}

export async function runAuthPasswordReset(params: {
  token: string;
  password: string;
}): Promise<{}> {
  return await Parse.Cloud.run("openinc-auth-password-reset", params);
}

export async function getUserRoles(): Promise<string[]> {
  return (await Parse.Cloud.run("openinc-user-roles")) || [];
}

export async function runGeoGoogleAutocomplete(params: {
  example: string;
}): Promise<
  Array<{
    example: string;
  }>
> {
  return await Parse.Cloud.run("openinc-geo-google-autocomplete", params);
}

export async function runGeoGraphhopperGeocodeReverse(params: {
  example: string;
}): Promise<
  Array<{
    example: string;
  }>
> {
  return await Parse.Cloud.run(
    "openinc-geo-graphhopper-geocode-reverse",
    params
  );
}

export async function runGeoGraphhopperGeocode(params: {
  example: string;
}): Promise<
  Array<{
    example: string;
  }>
> {
  return await Parse.Cloud.run("openinc-geo-graphhopper-geocode", params);
}

export async function runGeoGraphhopperRouteSimple(params: {
  example: string;
}): Promise<
  Array<{
    example: string;
  }>
> {
  return await Parse.Cloud.run("openinc-geo-graphhopper-route-simple", params);
}

export async function runRegisterPermission(params: {
  permission: PermissionInterface;
}): Promise<{ sucess: true }> {
  return await Parse.Cloud.run("openinc-register-permission", params);
}

export async function runDeregisterPermission(params: {
  permissionKey: string;
}): Promise<{ sucess: true }> {
  return await Parse.Cloud.run("openinc-deregister-permission", params);
}
