import { observer } from "mobx-react-lite";
import * as React from "react";
import {
  $framework,
  LinkedSourcePicker,
  useOpenDashApp,
  useSource,
} from "../..";
import * as Layout from "./components";

interface Props {
  mobile: boolean;
  style?: React.CSSProperties;
}

export const breakpoint = 600;

export const HeaderSourcePicker = observer<Props>(function HeaderSourcePicker({
  mobile: isMobile,
  style,
}) {
  const app = useOpenDashApp();
  const [source, setSource, sources] = useSource();

  if (!sources || sources.length === 0) {
    return null;
  }

  if (isMobile) {
    if (app.ui2.headerWidth >= breakpoint) {
      return null;
    }

    return (
      <Layout.HeaderRow>
        <LinkedSourcePicker
          asButton={true}
          style={{ width: "100%", height: "var(--opendash-header-row-height)" }}
        />
      </Layout.HeaderRow>
    );
  }

  if (source && sources.length === 1) {
    return (
      <span
        style={{ color: "var(--opendash-color-darkgray)", userSelect: "none" }}
      >
        {source.name}
      </span>
    );
  }

  return (
    <LinkedSourcePicker
      asButton={false}
      style={style}
      {...$framework.ui.headerSourcePickerProps}
    />
  );
});
