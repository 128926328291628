import { produce } from "@opendash/core";
import { useTranslation } from "@opendash/i18n";
import { Description, IconSelect } from "@opendash/ui";
import { InputNumber, Select } from "antd";
import React, { useEffect } from "react";
import {
  DiffFirstLastAggregation,
  ExtendedIntervalOptions,
  HistoricalFetchAggregation,
  SourceAggregation,
  SourceAggregationOperation,
} from "../types";
import {
  AggregationOperationInterfaceComponentProps,
  AggregationSettingInterface,
} from "./AggregationSettingInterface";

type AggregationType =
  | SourceAggregation
  | DiffFirstLastAggregation
  | HistoricalFetchAggregation;

type ComponentProps = {
  operationAsButton?: boolean;
  intervalAsButton?: boolean;
  allowNoAggregation?: boolean;
  hideInterval?: boolean;
};
export class SourceAggregationSettings
  implements AggregationSettingInterface<AggregationType, ComponentProps>
{
  label: string;
  description: string;

  ViewComponent: React.FunctionComponent<
    AggregationOperationInterfaceComponentProps<AggregationType, ComponentProps>
  >;

  ExtendedComponent: React.FunctionComponent<
    AggregationOperationInterfaceComponentProps<AggregationType, ComponentProps>
  >;

  moreSettingsAvailable?: (
    value: AggregationType,
    props: ComponentProps
  ) => boolean;
  create: () => SourceAggregationSettings;

  constructor(
    moreSettingsAvailable?: (
      value: AggregationType,
      props: ComponentProps
    ) => boolean
  ) {
    this.label = "openware:aggregations.source_aggregation.label";
    this.description = "openware:aggregations.source_aggregation.description";

    this.moreSettingsAvailable = moreSettingsAvailable;
    this.create = () =>
      new SourceAggregationSettings(this.moreSettingsAvailable);
    this.ExtendedComponent = ({ value, onChange, props, disabled }) => {
      const t = useTranslation();
      return (
        <div>
          {value.action === "diffFirstLast" && (
            <>
              <InputNumber
                disabled={disabled}
                onChange={(number) => {
                  onChange(
                    produce(value, (draft) => {
                      if (draft.action === "diffFirstLast") {
                        draft.params.offset = number * 1000;
                      }
                    })
                  );
                }}
                value={
                  ((value as DiffFirstLastAggregation).params.offset || 0) /
                  1000
                }
                step={1}
                addonAfter={t("opendash:ui.second_plural")}
              />
              <Description>
                {t("openware:aggregate.diffFirstLast.offset")}
              </Description>
            </>
          )}
        </div>
      );
    };

    this.ViewComponent = (props) => {
      const t = useTranslation();
      const { value, onChange, inline } = props;
      const initialOp =
        value.action === "historical"
          ? "none"
          : value.action === "source_aggregation"
            ? value.params.operation || "sum"
            : "diffFirstLast";
      const [aggConfig, setAggConfig] =
        React.useState<SourceAggregationOperation>(initialOp);

      const initialInterval =
        value.action === "source_aggregation"
          ? value.params.mode === "splits"
            ? "interval"
            : value.params.timeinterval
          : value.action === "diffFirstLast"
            ? value.params.timeinterval
            : "hour";
      const [interval, setInterval] =
        React.useState<ExtendedIntervalOptions>(initialInterval);

      useEffect(() => {
        if (aggConfig !== "none") {
          if (aggConfig === "diffFirstLast") {
            const pipe = produce(value, (draft) => {
              draft.action = "diffFirstLast";
              if (draft.action === "diffFirstLast") {
                draft.params.dimension = draft.params.dimension || 0;
                draft.params.timeinterval = interval;
              }
            });
            onChange(pipe);
            return;
          } else {
            const pipe = produce(value, (draft) => {
              return {
                action: "source_aggregation",
                params: {
                  operation: aggConfig,
                  source: draft.params.source,
                  id: draft.params.id,
                  dimension: (draft as SourceAggregation).params.dimension,
                  mode: "timeinterval",
                  timeinterval: interval,
                },
              } as SourceAggregation;
            });
            onChange(pipe);
          }
        } else {
          const pipe = produce(value, (draft) => {
            return {
              action: "historical",
              params: {
                source: draft.params.source,
                id: draft.params.id,
              },
            };
            //return {draft.action = "historical";
          });
          onChange(pipe);
          return;
        }
      }, [aggConfig, interval]);

      const options = [
        {
          label: t("opendash:monitoring.history_options.aggregation_none"),
          icon: null,
          tooltip: null,
          value: "none",
          disabled: false,
        },
        {
          label: t("opendash:monitoring.history_options.aggregation_sum"),
          icon: null,
          tooltip: null,
          value: "sum",
          disabled: false,
        },
        {
          label: t("opendash:monitoring.history_options.aggregation_mean"),

          icon: null,
          tooltip: null,
          value: "avg",
          disabled: false,
        },
        {
          label: t("opendash:monitoring.history_options.aggregation_min"),

          icon: null,
          tooltip: null,
          value: "min",
          disabled: false,
        },
        {
          label: t("opendash:monitoring.history_options.aggregation_max"),

          icon: null,
          tooltip: null,
          value: "max",
          disabled: false,
        },
        {
          label: t("opendash:monitoring.history_options.aggregation_first"),

          icon: null,
          tooltip: null,
          value: "first",
          disabled: false,
        },
        {
          label: t("opendash:monitoring.history_options.aggregation_last"),

          icon: null,
          tooltip: null,
          value: "last",
          disabled: false,
        },
        {
          label: t("opendash:monitoring.history_options.aggregation_count"),

          icon: null,
          tooltip: null,
          value: "count",
          disabled: false,
        },
        {
          label: t(
            "opendash:monitoring.history_options.aggregation_diffminmax"
          ),

          icon: null,
          tooltip: null,
          value: "diffminmax",
          disabled: false,
        },
        {
          label: t(
            "opendash:monitoring.history_options.aggregation_diffFirstLast"
          ),

          icon: null,
          tooltip: null,
          value: "diffFirstLast",
          disabled: false,
        },
      ];
      const intervalOptions = [
        {
          label: t("opendash:ui.minute"),
          icon: null,
          tooltip: null,
          value: "minute",
          disabled: false,
        },
        {
          label: t("opendash:ui.hour"),
          icon: null,
          tooltip: null,
          value: "hour",
          disabled: false,
        },
        {
          label: t("opendash:ui.day"),
          icon: null,
          tooltip: null,
          value: "day",
          disabled: false,
        },
        {
          label: t("opendash:ui.week"),
          icon: null,
          tooltip: null,
          value: "week",
          disabled: false,
        },
        {
          label: t("opendash:ui.month"),
          icon: null,
          tooltip: null,
          value: "month",
          disabled: false,
        },
        {
          label: t("opendash:ui.year"),
          icon: null,
          tooltip: null,
          value: "year",
          disabled: false,
        },
        {
          label: t("opendash:ui.interval"),
          icon: null,
          tooltip: null,
          value: "interval",
          disabled: false,
        },
      ];
      const [OpSelectComponent, selectOpProps] =
        props.props?.operationAsButton && !inline
          ? [
              IconSelect,
              {
                size: 4,
              },
            ]
          : [
              Select,
              {
                style: { width: "100%" },
              },
            ];
      const [IntervalSelectComponent, selectIntervalProps] =
        props.props?.intervalAsButton && !inline
          ? [
              IconSelect,
              {
                size: 4,
              },
            ]
          : [
              Select,
              {
                style: { width: "100%" },
              },
            ];

      return (
        <div
          style={{
            display: "flex",
            flexDirection: inline ? "row" : "column",
            gap: "8px",
          }}
        >
          {!inline && (
            <Description
              children={t(
                "opendash:monitoring.history_options.aggregation_description"
              )}
            ></Description>
          )}
          {/*@ts-ignore*/}
          <OpSelectComponent
            options={options.filter((option) => {
              return props.props.allowNoAggregation || option.value !== "none";
            })}
            value={aggConfig}
            onChange={(aggConfigNew) => {
              setAggConfig(aggConfigNew);
            }}
            {...selectOpProps}
          />
          {!inline && (
            <Description
              children={t(
                "opendash:monitoring.history_options.aggregation_description"
              )}
            ></Description>
          )}

          {!props.props?.hideInterval && (
            <>
              {/*@ts-ignore*/}
              <IntervalSelectComponent
                options={intervalOptions}
                value={interval}
                onChange={(selection) => {
                  setInterval(selection);
                  // onChange(
                  //   produce(value, (draft) => {
                  //     if (draft.action === "source_aggregation") {
                  //       if (selection === "none") {
                  //         return {
                  //           action: "historical",
                  //           params: {
                  //             source: draft.params.source,
                  //             id: draft.params.id,
                  //             dimension: draft.params.dimension,
                  //           },
                  //         } as HistoricalFetchAggregation;
                  //       }
                  //       if (selection === "interval") {
                  //         return {
                  //           action: "source_aggregation",
                  //           params: {
                  //             operation: draft.params.operation,
                  //             source: draft.params.source,
                  //             id: draft.params.id,
                  //             dimension: draft.params.dimension,
                  //             mode: "splits",
                  //             splits: 1,
                  //           },
                  //         } as SourceAggregation;
                  //       } else {
                  //         return {
                  //           action: "source_aggregation",
                  //           params: {
                  //             operation: draft.params.operation,
                  //             source: draft.params.source,
                  //             id: draft.params.id,
                  //             dimension: draft.params.dimension,
                  //             mode: "timeinterval",
                  //             timeinterval: selection as ExtendedIntervalOptions,
                  //           },
                  //         } as SourceAggregation;
                  //       }
                  //     }
                  //   })
                  // );
                }}
                {...selectIntervalProps}
              />
              {!inline && (
                <Description
                  children={t("highcharts:aggregate.multi.interval")}
                ></Description>
              )}
            </>
          )}
        </div>
      );
    };
  }
}

export default name;
