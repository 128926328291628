import { Button } from "antd";
import React from "react";

import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import { createComponent } from "@opendash/state";
import { AdminToolbar } from "@opendash/ui";

import { $parse } from "../../state";
import type { ParseFilterState } from "../../state/ParseFilterState";
import { AnyViewType, ParseSearchFilterType } from "../../types";
import { translateClassDescription, translateClassName } from "../helper";
export type onRow = (
  entry: Parse.Object,
  index: number
) => {
  onClick: (e: React.MouseEvent) => void;
  onDoubleClick: (e: React.MouseEvent) => void;
  onMouseEnter: (e: React.MouseEvent) => void;
  onMouseLeave: (e: React.MouseEvent) => void;
  onContextMenu: (e: React.MouseEvent) => void;
};

type Props = React.PropsWithChildren<{
  state: ParseFilterState;
  view: AnyViewType;
  beforeCreate?: () => Parse.Object | null;
  afterCreate?: (classname: string, objectId: string) => void;
  beforeDelete?: (classname: string, objectId: string[]) => boolean;
  afterDelete?: (classname: string, objectId: string[]) => void;
  hideCreate?: boolean;
  headerExtra?: React.ReactNode;
  onRow?: onRow;
}>;

export const ViewLayout = createComponent<Props>(function ViewLayout({
  state,
  view,
  children,
  beforeCreate,
  beforeDelete,
  afterCreate,
  afterDelete,
  hideCreate,
  headerExtra,
  onRow,
}) {
  const t = useTranslation();

  return (
    <>
      <AdminToolbar
        title={translateClassName(t, state.className, 2)}
        description={translateClassDescription(t, state.className, 2)}
        onSearch={
          view.showSearch
            ? (value) => {
                if (value === "") {
                  state.setSearch({});
                  return;
                }

                const search: ParseSearchFilterType = {};

                for (const col of state.config.displayFields) {
                  const fieldSchema = state.schema.fields[col];

                  if (!fieldSchema) {
                    continue;
                  }

                  if (fieldSchema.type === "String") {
                    search[col] = value;
                  }
                }

                state.setSearch(search);
              }
            : undefined
        }
        searchInitial={""}
        actions={
          <>
            {!hideCreate &&
              $parse.ui.getClassPermission(state.className, "create") && (
                <Button
                  key="create"
                  type="primary"
                  icon={<Icon icon="fa:plus" />}
                  onClick={(e) => {
                    const initial = beforeCreate ? beforeCreate() : null;
                    if (beforeCreate && !initial) return;
                    $parse.ui
                      .createObject(state.className, initial)
                      .then((id) => {
                        if (id) {
                          if (afterCreate) afterCreate(state.className, id);
                          state.data.reload();
                        }
                      });
                  }}
                  children={t("parse-admin:admin.create.action")}
                />
              )}

            <Button
              key="reload"
              type="default"
              icon={<Icon icon="fa:redo" />}
              loading={state.data.loading}
              onClick={(e) => {
                state.data.reload();
              }}
              children={t("parse-admin:admin.reload_action")}
            />

            {view.type === "table" &&
              $parse.ui.getClassPermission(state.className, "delete") && (
                <Button
                  key="delete"
                  danger={true}
                  icon={<Icon icon="fa:trash" />}
                  disabled={state.selection.length === 0}
                  onClick={(e) => {
                    if (
                      beforeDelete &&
                      !beforeDelete(state.className, state.selection)
                    )
                      return;
                    $parse.ui
                      .deleteObject(state.className, state.selection)
                      .then(() => {
                        if (afterDelete)
                          afterDelete(state.className, state.selection);
                        state.data.reload();
                      });
                  }}
                  children={t("parse-admin:admin.delete.action")}
                />
              )}
          </>
        }
      >
        {headerExtra}
      </AdminToolbar>
      <div className="od-page-main">{children}</div>
    </>
  );
});
