import Parse from "parse";
import React from "react";

import { Icon } from "@opendash/icons";

import { $parse } from "../state";
import { ParseClassConfig } from "../types";

interface Props {
  object: Parse.Object;
  field: string;

  isInline?: boolean;

  config?: ParseClassConfig;

  onChange?(value: any): void;
}

export const ParseFieldImage = React.memo<Props>(function ParseFieldImage({
  object,
  field,
}) {
  if (!field) {
    return null;
  }

  const className = object.className;
  const classSchema = $parse.ui.getClassSchema(className);

  if (!classSchema) {
    return null;
  }

  const schema = classSchema.fields[field];

  if (!schema) {
    return null;
  }

  const value = object.get(field);

  if (!value) {
    return null;
  }

  switch (schema.type) {
    case "StringIcon":
      return <Icon icon={value} />;
    case "File":
      return <img alt="" src={(value as Parse.File).url()} />;
    default:
      return <Icon icon="fa:question" />;
  }
});
