import { changeLanguage } from "@opendash/i18n";
import { Modal, Select } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocalStorage, useOpenDashApp, useTranslation } from "../..";

export const LanguageDialog = observer(function LanguageDialog() {
  const t = useTranslation();

  const app = useOpenDashApp();

  const [lang, setLang] = useLocalStorage("opendash:language");

  return (
    <Modal
      title={t("opendash:account.language.label")}
      cancelText={t("opendash:ui.close")}
      open={app.ui2.languageDialogOpen}
      onOk={() => app.ui2.hideLanguageDialog()}
      onCancel={() => app.ui2.hideLanguageDialog()}
      okButtonProps={{ style: { display: "none" } }}
    >
      <p>{t("opendash:account.language.info")}</p>
      <Select
        placeholder={t("opendash:account.language.placeholder")}
        defaultValue={lang}
        onChange={(nextLang: string) => {
          changeLanguage(nextLang);
          setLang(nextLang);
        }}
        style={{ width: "100%" }}
      >
        {app.ui.languages.map(({ key, label }) => (
          <Select.Option key={key} value={key}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
});
