import React from "react";

import { createComponent } from "@opendash/state";

import { $framework } from "@opendash/core";
import { $parse } from "../state";

export const ParseCommands = createComponent(function ParseCommands() {
  React.useEffect(() => {
    const actions = [];

    actions.push({
      id: "parse:users",
      title: "Users",
    });

    for (const user of $parse.server.users) {
      const id = `parse:users:${user.id}`;
      const name = [user.getEmail(), user.getUsername(), user.get("name")]
        .filter(Boolean)
        .join(" / ");

      actions.push({
        id,
        parent: "parse:users",
        title: name,
      });

      actions.push({
        id: id + ":update",
        parent: id,
        title: "Update User",
        handler: () => {
          $parse.ui.updateObject(user.className, user.id);
        },
      });

      if ($parse.server.roles.includes("od-admin")) {
        actions.push({
          id: id + ":simulate",
          parent: id,
          title: "Simulate User",
          handler: () => {
            $parse.auth.simulateUser({ userId: user.id });
          },
        });
      }
    }

    if ($parse.auth.simulation) {
      actions.push({
        id: "parse:admin:simulation-stop",
        title: "Stop User Simulation",
        handler: () => {
          $parse.auth.simulateStop();
        },
      });
    }

    return $framework.commands.registerCommands(actions);
  }, [$parse.server.userId]);

  React.useEffect(() => {
    if ($parse.auth.simulation) {
      return $framework.commands.registerCommand({
        id: "parse:admin:simulation-stop",
        title: "Stop User Simulation",
        handler: () => {
          $parse.auth.simulateStop();
        },
      });
    }
  }, [$parse.auth.simulation]);

  return null;
});
