import { Checkbox, DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect } from "react";

type CustomFormItemProps = {
  value?: Dayjs;
  onChange?: (date: Dayjs) => void;
  onlyWeeks?: boolean;
  setOnlyWeeks(value: boolean): void;
};

export const StartDatePicker: React.FC<CustomFormItemProps> = ({
  value,
  onChange,
  onlyWeeks,
  setOnlyWeeks,
}) => {
  useEffect(() => {
    // Propagate the current set date to oChange so that it can be used by EndDatePicker.
    // Otherwise it might be undefined.
    onChange(value ? value : dayjs());
  }, []);

  return (
    <DatePicker
      onChange={(date, dateString) => {
        if (date !== null) {
          onChange(date);
        }
      }}
      renderExtraFooter={() => {
        //Add a Checkbox for week selection
        return (
          <Checkbox
            defaultChecked={onlyWeeks}
            onChange={(e) => {
              setOnlyWeeks(e.target.checked);
              onChange(value);
            }}
          >
            Auswahl von Kalenderwochen
          </Checkbox>
        );
      }}
      picker={onlyWeeks ? "week" : "date"}
      value={value}
      defaultValue={dayjs()}
      style={{ width: "100%" }}
      showTime={{ format: "HH:mm" }}
      format={onlyWeeks ? "[KW] WW in YYYY" : "DD.MM.YYYY @ HH:mm"}
    />
  );
};
