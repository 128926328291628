import { Action } from "kbar";
import { makeAutoObservable, runInAction } from "mobx";
import type { FrameworkService } from "./FrameworkService";

type CommandType = {
  id: string;
  parent?: string;
  title: string;
  description?: string;
  icon?: string;
  shortcut?: string[];
  handler?: () => void;
};

export class CommandBarService {
  private framework: FrameworkService;
  private commandMap = new Map<string, CommandType>();

  constructor(framework: FrameworkService) {
    makeAutoObservable(this);

    this.framework = framework;
  }

  public get actions(): Action[] {
    return Array.from(this.commandMap.values()).map((command) => ({
      id: command.id,
      parent: command.parent,
      name: command.title,
      subtitle: command.description,
      icon: command.icon,
      shortcut: command.shortcut,
      perform:
        typeof command.handler === "function"
          ? () => command.handler()
          : undefined,
    }));
  }

  public registerCommand(command: CommandType) {
    const id = command.id;

    this.commandMap.set(id, command);

    return () => {
      runInAction(() => {
        this.commandMap.delete(id);
      });
    };
  }

  public registerCommands(commands: CommandType[]) {
    const remover = commands.map((command) => this.registerCommand(command));

    return () => {
      for (const remove of remover) {
        remove();
      }
    };
  }
}
