import {
  $framework,
  AppConfigRouteInterface,
  AppFactoryLockedError,
  AppInterface,
  AppPluginInterface,
  DebugTranslationsRoute,
  NavigationAdapterInterface,
  NavigationGroupInterface,
  NavigationItemInterface,
  OpenDashFrontpage,
  ServiceInterface,
  SourceAdapterInterface,
  StorageAdapterInterface,
  TranslationResolverInterface,
  UserAdapterInterface,
} from "..";

import {
  changeLanguage,
  registerLanguage,
  registerTranslationResolver,
} from "@opendash/i18n";
import {
  RouteStateInterface,
  StatefullSyncRoute,
  TestRoute,
  TestRouteState,
} from "@opendash/router";

import { setIconClassName } from "@opendash/icons";

import deTranslation from "../translations/de.json";
import enTranslation from "../translations/en.json";
import zhTranslation from "../translations/zh_Hans.json";

import { ConfigProvider } from "antd";
import { Locale } from "antd/lib/locale";
import { CommandBarProvider } from "../components/_provider/CommandBar/CommandBarProvider";
import { hexToRgb, rgbToHex } from "../helper/stringToColor";

type PossibleESModule<T> = T | { default: T };

export class AppFactory {
  private locked: boolean = false;

  private id: string;

  private config: any;

  private plugins: AppPluginInterface[] = [];

  private adapter: {
    User?: UserAdapterInterface;
    Source?: SourceAdapterInterface;
    Navigation?: NavigationAdapterInterface;
    DeviceStorage?: StorageAdapterInterface;
    UserStorage?: StorageAdapterInterface;
  } = {};

  //Services
  private services: ServiceInterface[] = [];

  //Routes
  private routes: Record<string, AppConfigRouteInterface> = {};

  //Languages
  private languages: {
    key: string;
    label: string;
    fallback: string;
    isDefault: boolean;
  }[] = [];

  //Navigation
  private staticNavigationGroups: NavigationGroupInterface[] = [];
  private staticNavigationItems: NavigationItemInterface[] = [];

  //Other UI related stuff
  private disableHeader: boolean = false;
  private disableHeaderSourcePicker: boolean = false;
  private headerSourcePickerProps: any = {};
  private disableFooter: boolean = false;
  private logoImage: string = null;
  private logoMobileSize: number = 200;
  private logoText: string = null;
  private logoLink: string = null;
  private logoLinkExternal: boolean = false;

  private primaryColor: string = "#666767";
  private backgroundColor: string = "#ffffff";
  private colorPaletteHex: string[] = [
    "#666767",
    "#656F7E",
    "#647694",
    "#6285C1",
    "#7A9F8C",
    "#91B957",
    "#B1C754",
    "#D0D450",
    "#C2C482",
    "#B3B3B3",
  ];
  private colorPaletteRGB: [number, number, number][];

  private globalComponents: [React.ComponentType, any][] = [];
  private providerComponents: [React.ComponentType, any][] = [];
  private enableServiceWorker: boolean = false;

  constructor(id: string) {
    this.id = id;

    setIconClassName("anticon");

    this.registerTranslationResolver(
      "en",
      "opendash",
      async () => enTranslation
    );

    this.registerTranslationResolver(
      "de",
      "opendash",
      async () => deTranslation
    );

    this.registerTranslationResolver(
      "zh_Hans",
      "opendash",
      async () => zhTranslation
    );

    this.parseConfig();

    this.registerService($framework.services.DeviceStorageService);
    this.registerService($framework.services.UserService);
    this.registerService($framework.services.UserStorageService);
    this.registerService($framework.services.NavigationService);
    this.registerService($framework.services.SourceService);

    this.registerProviderComponent(CommandBarProvider);

    this.registerRoute({
      path: "/",
      componentSync: OpenDashFrontpage,
    });

    this.registerRoute({
      path: "/debug",
      auth: "both",
      redirectPath: "/debug/translations",
    });

    this.registerRoute({
      path: "/debug/translations",
      auth: "both",
      componentSync: DebugTranslationsRoute,
    });

    this.registerStatefullRoute({
      path: "/admin/debug/route/:test/:var",
      layout: "basic-padding",
      state: TestRouteState,
      componentSync: TestRoute,
    });
  }

  private parseConfig() {
    try {
      // @ts-expect-error
      const config = __OPENDASH_CONFIG__;

      console.log("open.DASH Config:", config);

      this.setConfig(config);
    } catch (error) {}
  }

  private setConfig(config: any) {
    this.config = config;

    if (config?.app?.primaryColor) {
      this.setPrimaryColor(config.app.primaryColor);
    }

    if (config?.app?.backgroundColor) {
      this.setBackgroundColor(config.app.backgroundColor);
    }
    if (config?.app?.colorPalette) {
      if (config.app.colorPalette[0].length === 3) {
        this.setColorPaletteRGB(config.app.colorPalette);
      } else {
        this.setColorPaletteHex(config.app.colorPalette);
      }
    } else {
      this.setColorPaletteHex(this.colorPaletteHex);
    }
  }

  private setPrimaryColor(colorString: string) {
    this.primaryColor = colorString;
  }
  private setColorPaletteRGB(colorPalette: [number, number, number][]) {
    this.colorPaletteRGB = colorPalette;
    this.colorPaletteHex = colorPalette.map((rgb) => rgbToHex(rgb));
  }
  private setColorPaletteHex(colorPalette: string[]) {
    this.colorPaletteHex = colorPalette;
    this.colorPaletteRGB = colorPalette.map((hex) => hexToRgb(hex));
  }
  private setBackgroundColor(colorString: string) {
    this.backgroundColor = colorString;
  }

  async use(plugin: AppPluginInterface): Promise<void> {
    if (this.locked) throw new AppFactoryLockedError("use");

    this.plugins.push(plugin);

    if ("onFactory" in plugin && typeof plugin.onFactory === "function") {
      await Promise.resolve(plugin.onFactory(this));
    }
  }

  registerLanguage(
    key: string,
    label: string,
    fallback: string = undefined,
    isDefault: boolean = false
  ) {
    if (this.locked) throw new AppFactoryLockedError("registerLanguage");
    this.languages = this.languages.filter((lang) => lang.key !== key);
    this.languages.push({ key, label, fallback, isDefault });

    registerLanguage(key, label, fallback);
  }

  registerTranslationResolver(
    language: string,
    namespace: string,
    resolver: TranslationResolverInterface
  ) {
    if (this.locked)
      throw new AppFactoryLockedError("registerTranslationResolver");

    // this.translationResolver.set([language, namespace].join(","), resolver);

    registerTranslationResolver(language, namespace, resolver);
  }

  registerAntDesignTranslation(
    language: string,
    resolver: () => Promise<{ default: Locale }>
  ) {
    if (this.locked)
      throw new AppFactoryLockedError("registerAntDesignTranslation");

    registerTranslationResolver(language, "antd", async () => {
      const { default: translation } = await resolver();

      return { json: JSON.stringify(translation) };
    });
  }

  registerUserAdapter(adapter: PossibleESModule<UserAdapterInterface>): void {
    if (this.locked) throw new AppFactoryLockedError("registerUserAdapter");

    this.adapter.User = this.extractESModule(adapter);
  }

  registerGlobalComponent<P = {}>(
    component: React.ComponentType<P>,
    props?: P
  ) {
    if (this.locked) throw new AppFactoryLockedError("registerGlobalComponent");

    this.globalComponents.push([component, props]);
  }

  registerProviderComponent<P = {}>(
    component: React.ComponentType<P>,
    props?: P
  ) {
    if (this.locked)
      throw new AppFactoryLockedError("registerProviderComponent");

    this.providerComponents.push([component, props]);
  }

  registerService(service: PossibleESModule<ServiceInterface>): void {
    if (this.locked) throw new AppFactoryLockedError("registerService");

    this.services.push(this.extractESModule(service));
  }

  registerSourceAdapter(
    adapter: PossibleESModule<SourceAdapterInterface>
  ): void {
    if (this.locked) throw new AppFactoryLockedError("registerSourceAdapter");

    this.adapter.Source = this.extractESModule(adapter);
  }

  registerNavigationAdapter(
    adapter: PossibleESModule<NavigationAdapterInterface>
  ): void {
    if (this.locked)
      throw new AppFactoryLockedError("registerNavigationAdapter");

    this.adapter.Navigation = this.extractESModule(adapter);
  }

  registerUserStorageAdapter(
    adapter: PossibleESModule<StorageAdapterInterface>
  ): void {
    if (this.locked)
      throw new AppFactoryLockedError("registerUserStorageAdapter");

    this.adapter.UserStorage = this.extractESModule(adapter);
  }

  registerDeviceStorageAdapter(
    adapter: PossibleESModule<StorageAdapterInterface>
  ): void {
    if (this.locked)
      throw new AppFactoryLockedError("registerDeviceStorageAdapter");

    this.adapter.DeviceStorage = this.extractESModule(adapter);
  }

  registerRoute(route: PossibleESModule<AppConfigRouteInterface>): void {
    if (this.locked) throw new AppFactoryLockedError("registerRoute");

    const r = this.extractESModule(route);

    r.auth ||= "authenticated";
    r.offline ||= false;

    this.routes[r.path] = r;
  }

  registerStatefullRoute<T extends RouteStateInterface = RouteStateInterface>(
    route: StatefullSyncRoute<T>
  ): void {
    if (this.locked) throw new AppFactoryLockedError("registerRoute");

    const r = this.extractESModule(route);

    r.auth ||= "authenticated";
    r.offline ||= false;

    this.routes[r.path] = r;
  }

  registerStaticNavigationGroup(group: NavigationGroupInterface): void {
    if (this.locked)
      throw new AppFactoryLockedError("registerStaticNavigationGroup");

    this.staticNavigationGroups.push(group);
  }

  /**
   * Deregisters a naviagtion group. Checks for the id of the element in the currently registered navigation group array. Does nothing if item can not be found.
   * @param itemid The id of the item that should be deleted.
   */
  deregisterStaticNavigationGroup(itemid: string): void {
    if (this.locked)
      throw new AppFactoryLockedError("deregisterStaticNavigationGroup");

    const itemindex = this.staticNavigationGroups.findIndex(
      (elem) => elem.id === itemid
    );

    if (itemindex !== -1) {
      this.staticNavigationGroups.splice(itemindex, 1);
    }
  }

  registerStaticNavigationItem(item: NavigationItemInterface): void {
    if (this.locked)
      throw new AppFactoryLockedError("registerStaticNavigationItem");

    this.staticNavigationItems.push(item);
  }

  /**
   * Deregisters a naviagtion item. Checks for the id of the element in the currently registered navigation item array. Does nothing if item can not be found.
   * @param itemid The id of the item that should be deleted.
   */
  deregisterStaticNavigationItem(itemid: string): void {
    if (this.locked)
      throw new AppFactoryLockedError("deregisterStaticNavigationItem");

    const itemindex = this.staticNavigationItems.findIndex(
      (elem) => elem.id === itemid
    );

    if (itemindex !== -1) {
      this.staticNavigationItems.splice(itemindex, 1);
    }
  }

  registerServiceWorker(): void {
    if (this.locked) throw new AppFactoryLockedError("registerServiceWorker");

    this.enableServiceWorker = true;
  }

  get ui() {
    return {
      disableHeader: () => {
        this.disableHeader = true;
      },
      disableHeaderSourcePicker: () => {
        this.disableHeaderSourcePicker = true;
      },
      enableHeaderSourcePicker: () => {
        this.disableHeaderSourcePicker = false;
      },
      setHeaderSourcePickerProps: (props: any) => {
        this.headerSourcePickerProps = props;
      },
      disableFooter: () => {
        this.disableFooter = true;
      },
      setLogoImage: (value: string) => {
        this.logoImage = value;
      },
      setLogoMobileSize: (value: number) => {
        this.logoMobileSize = value;
      },
      setLogoText: (value: string) => {
        this.logoText = value;
      },
      setLogoLink: (value: string) => {
        this.logoLink = value;
      },
      setLogoLinkExternal: (value: boolean) => {
        this.logoLinkExternal = value;
      },
    };
  }

  async createApp(): Promise<AppInterface> {
    try {
      let lang = this.languages.find(
        (l) =>
          l.key === JSON.parse(window.localStorage.getItem("opendash:language"))
      )?.key;

      if (!lang) {
        lang = this.languages.find((l) => l.isDefault)?.key;
      }

      if (!lang) {
        lang = this.languages[0]?.key;
      }

      changeLanguage(lang);
    } catch (error) {}

    const adapter = this.adapter;

    try {
      window?.document?.documentElement?.style?.setProperty(
        "--opendashPrimaryColor",
        this.primaryColor
      );

      window?.document?.documentElement?.style?.setProperty(
        "--opendash-colors-primary",
        this.primaryColor
      );

      window?.document?.documentElement?.style?.setProperty(
        "--opendashBackgroundColor",
        this.backgroundColor
      );

      window?.document?.documentElement?.style?.setProperty(
        "--opendash-colors-background",
        this.backgroundColor
      );

      ConfigProvider.config({
        theme: {
          primaryColor: this.primaryColor,
        },
      });
    } catch (error) {}

    $framework.init({
      id: this.id,
      config: this.config,
      ui: {
        disableHeader: this.disableHeader,
        disableHeaderSourcePicker: this.disableHeaderSourcePicker,
        disableFooter: this.disableFooter,
        logoImage: this.logoImage,
        logoMobileSize: this.logoMobileSize,
        logoText: this.logoText,
        logoLink: this.logoLink,
        logoLinkExternal: this.logoLinkExternal,

        primaryColor: this.primaryColor,
        backgroundColor: this.backgroundColor,
        colorPaletteHex: this.colorPaletteHex,
        colorPaletteRGB: this.colorPaletteRGB,
        languages: this.languages,
        staticNavigationGroups: this.staticNavigationGroups,
        staticNavigationItems: this.staticNavigationItems,
      },
      _internal: {
        globalComponents: this.globalComponents,
        providerComponents: this.providerComponents,
        services: this.services,
        routes: Object.values(this.routes),
      },
    });

    await $framework.serviceWorker.init(this.enableServiceWorker);

    await $framework.services.DeviceStorageService.init(adapter.DeviceStorage);
    await $framework.services.UserService.init(adapter.User);
    await $framework.services.UserStorageService.init(adapter.UserStorage);
    await $framework.services.SourceService.init(adapter.Source);
    await $framework.services.NavigationService.init(adapter.Navigation);

    for (const plugin of this.plugins) {
      if (
        "onServiceRegistration" in plugin &&
        typeof plugin.onServiceRegistration === "function"
      ) {
        try {
          await Promise.resolve(plugin.onServiceRegistration());
        } catch (error) {
          console.error(
            `[@opendash/core] Plugin.onServiceRegistration(): '${plugin.name}' has thrown the following error:`
          );

          throw error;
        }
      }
    }

    for (const plugin of this.plugins) {
      if ("onApp" in plugin && typeof plugin.onApp === "function") {
        try {
          await Promise.resolve(plugin.onApp($framework));
        } catch (error) {
          console.error(
            `[@opendash/core] Plugin.onApp(): '${plugin.name}' has thrown the following error:`
          );

          throw error;
        }
      }
    }

    return $framework;
  }

  private extractESModule<T = any>(input: PossibleESModule<T>): T {
    // @ts-ignore
    if (input.default) {
      // @ts-ignore
      return input.default;
    }

    return input as T;
  }
}
