import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarSearch,
} from "kbar";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { $framework } from "../../../exports";
import { CommandBarActions } from "./CommandBarActions";
import { CommandBarResults } from "./CommandBarResults";
import { CommandBarStyle } from "./CommandBarStyle";

export const CommandBarProvider = observer<React.PropsWithChildren>(
  function CommandBarProvider({ children }) {
    return (
      <KBarProvider actions={$framework.commands.actions}>
        <CommandBarStyle />
        <CommandBarActions />
        <KBarPortal>
          <KBarPositioner className="opendash-commandbar--positioner">
            <KBarAnimator className="opendash-commandbar--animator">
              <KBarSearch className="opendash-commandbar--search" />
              <CommandBarResults />
            </KBarAnimator>
          </KBarPositioner>
        </KBarPortal>
        {children}
      </KBarProvider>
    );
  }
);
