import dayjs from "dayjs";
import { QuartzObject } from "../types/CronObject";

export function monthCheck(
  value: QuartzObject,
  manipulatedDate: dayjs.Dayjs
): boolean {
  const montharr: string[] = value.month.split(value.DELIMITER_MON);
  const monthisincluded = montharr.includes(
    value.getMonthAbbreviation(
      [manipulatedDate.month()],
      "string",
      undefined,
      ","
    )
  );

  //Check month field
  if (value.month === "*" || value.month === "1") {
    return true;
  } else {
    if (typeof value.DELIMITER_MON !== "undefined") {
      //Month is start and interval
      const dividend = manipulatedDate.month() - (parseInt(montharr[0]) - 1);
      const divisor = parseInt(montharr[1]);
      if (dividend === 0 && divisor === 0) {
        return true;
      }

      if (
        value.DELIMITER_MON === "/" &&
        //Get the correct month (is current date - startdate a multiple of interval?)
        dividend % divisor === 0
      ) {
        return true;
      }

      //Month is comma separated list
      if (value.DELIMITER_MON === "," && monthisincluded) {
        return true;
      }

      //Month is range
      if (
        value.DELIMITER_MON === "-" &&
        manipulatedDate.month() > parseInt(montharr[0]) &&
        manipulatedDate.month() < parseInt(montharr[1])
      ) {
        return true;
      }
    } else {
      //Month is a single month
      const currentMonthAbbr = value.getMonthAbbreviation(
        [manipulatedDate.month()],
        "string",
        undefined,
        ","
      );
      if (currentMonthAbbr === montharr[0]) {
        return true;
      }
    }
  }

  return false;
}
