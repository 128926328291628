import { Col, Collapse, Empty, Row, Statistic } from "antd";
import dayjs from "dayjs";
import React from "react";
import { DataItemDimensionValueInterface } from "../types/DataItemDimensionValueInterface";
import { DataItemInterface } from "../types/DataItemInterface";

import { useTranslation } from "@opendash/core";
import { Description } from "@opendash/ui";
import { useDataService } from "../hooks/useDataService";

interface Statistics {
  min: number;
  max: number;
  sum: number;
  count: number;
  avg: number;
  diffFirstLast: number;
  diffMinMax: number;
  startdate: number;
  enddate: number;
}

interface Props {
  values: [DataItemInterface, number, DataItemDimensionValueInterface[]][];
  style?: React.CSSProperties;
  start?: number;
  end?: number;
}

export const DataStatisticsOverview: React.ElementType<Props> = ({
  style,
  values,
  start = 0,
  end = Number.MAX_VALUE,
}) => {
  const DataService = useDataService();
  const t = useTranslation();
  const [cValues, setCValues] =
    React.useState<[DataItemInterface, number, Statistics][]>(null);

  React.useEffect(() => {
    if (!values) return;
    setCValues(
      values.map((entry) => [
        entry[0],
        entry[1],
        getStatistics(
          entry[2].filter((value) => value.date >= start && value.date <= end)
        ),
      ])
    );
  }, [values]);

  return (
    <>
      {!cValues && <Empty></Empty>}
      {cValues && (
        <div style={style}>
          <Collapse bordered={false} defaultActiveKey={["0"]}>
            {cValues.map((item, idx) => {
              return (
                <Collapse.Panel
                  header={DataService.getItemName(item[0], item[1], false)}
                  key={"" + idx}
                >
                  {item[2].count === 0 && <Empty />}
                  {item[2].count > 0 && (
                    <>
                      <Description>
                        {dayjs(item[2].startdate).format("llll") +
                          " - " +
                          dayjs(item[2].enddate).format("llll")}
                      </Description>
                      <Row style={{ marginBottom: 20 }}>
                        <Col span={12}>
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title={t(
                              "opendash:monitoring.history_options.aggregation_min"
                            )}
                            value={item[2].min.toFixed(3)}
                            suffix={item[0].valueTypes[item[1]].unit}
                          />
                        </Col>
                        <Col span={12}>
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title={t(
                              "opendash:monitoring.history_options.aggregation_max"
                            )}
                            value={item[2].max.toFixed(3)}
                            suffix={item[0].valueTypes[item[1]].unit}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title={t(
                              "opendash:monitoring.history_options.aggregation_count"
                            )}
                            value={item[2].count.toFixed(0)}
                            suffix={"#"}
                          />
                        </Col>
                        <Col span={12}>
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title={t(
                              "opendash:monitoring.history_options.aggregation_mean"
                            )}
                            value={item[2].avg.toFixed(3)}
                            suffix={item[0].valueTypes[item[1]].unit}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title={t(
                              "opendash:monitoring.history_options.aggregation_diffminmax"
                            )}
                            value={item[2].diffMinMax.toFixed(3)}
                            suffix={item[0].valueTypes[item[1]].unit}
                          />
                        </Col>
                        <Col span={12}>
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title={t(
                              "opendash:monitoring.history_options.aggregation_diffFirstLast"
                            )}
                            value={item[2].diffFirstLast.toFixed(3)}
                            suffix={item[0].valueTypes[item[1]].unit}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Statistic
                            decimalSeparator=","
                            groupSeparator="."
                            title={t(
                              "opendash:monitoring.history_options.aggregation_sum"
                            )}
                            value={item[2].sum.toFixed(3)}
                            suffix={item[0].valueTypes[item[1]].unit}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </div>
      )}
    </>
  );
};

function getStatistics(values: DataItemDimensionValueInterface[]): Statistics {
  if (values.length === 0) {
    return {
      min: 0,
      max: 0,
      sum: 0,
      count: 0,
      avg: 0,
      diffFirstLast: 0,
      diffMinMax: 0,
      startdate: 0,
      enddate: new Date().getTime(),
    };
  }
  let sum = 0;
  let max = Number.MIN_VALUE;
  let min = Number.MAX_VALUE;
  const count = values.length;
  let startdate = Number.MAX_VALUE;
  let enddate = 0;
  const diffFirstLast = values[0].value - values.at(-1).value;
  for (let i = 0; i < values.length; i++) {
    sum = sum + values[i].value;
    max = Math.max(values[i].value, max);
    min = Math.min(values[i].value, min);
    startdate = Math.min(startdate, values[i].date);
    enddate = Math.max(enddate, values[i].date);
  }

  return {
    min,
    max,
    sum,
    count,
    avg: count > 0 ? sum / count : 0,
    diffFirstLast,
    diffMinMax: max - min,
    startdate,
    enddate,
  };
}
