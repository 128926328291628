import {
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import {
  Alert,
  Button,
  Col,
  Divider,
  Dropdown,
  Empty,
  Input,
  Menu,
  Row,
  Skeleton,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { FormManager } from "../../..";

const { Title } = Typography;

export default observer((props: { step; updateStep }) => {
  const t = useTranslation();
  const [isChanged, setIsChanged] = React.useState(false);
  const [fieldKey, setFieldKey] = React.useState(0);
  const [setStyle, setSetStyle] = React.useState(-1);
  const fields = () => {
    return props.step.fields || [];
  };
  const formManager = FormManager.getInstance();
  const updateField = (action) => {
    props.updateStep((state) => {
      action(state.fields[fieldKey]);
    });
  };
  const createField = (type) => {
    const key = "field" + new Date().getTime();
    const toReturn = {
      title: t("bde:fields.defaultTitle"),
      description: "",
      type: type.key,
      key: key,
      options: {},
      v_required: {
        value: false,
        err: "Wert muss eingeben werden",
      },
      od_type: type.key === "number" ? "Number" : "String",
    };

    props.updateStep((state) => {
      state.fields[state.fields.length] = toReturn;
    });
  };

  const getTypeName = (type) => {
    const form = FormManager.getInstance().get(type);
    return t(form.label);
  };

  const addFields = (
    <Menu
      onClick={(e) => {
        createField(e);
        setIsChanged(true);
      }}
    >
      {formManager.list().map((type, i) => {
        const form = formManager.get(type);
        return (
          <Menu.Item key={type}>
            <Icon icon={form.icon || "fa:edit"} />
            {" " + t(form.label)}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const getFieldInfos = (fieldKey1) => {
    let fieldKey = fieldKey1 || 0;
    fieldKey = Math.min(fieldKey, fields().length - 1);
    if (fields().length < 1) {
      return (
        <Empty
          description={
            <div>
              <span style={{ display: "block" }}>
                {t("bde:fields.label_noFieldSelected")}
              </span>
              <Dropdown overlay={addFields}>
                <Button>{t("bde:fields.label_addField")}</Button>
              </Dropdown>
            </div>
          }
        />
      );
    }
    return (
      <div>
        {isChanged && (
          <Row>
            <Alert
              style={{ width: "100%" }}
              message={t("bde:app.createitem.note")}
              type="info"
            />
          </Row>
        )}
        <Divider style={{ marginBottom: "20px" }} />
        <Row>
          <Col span={8}>
            <Title level={5}>{t("bde:app.createitem.preview")}</Title>
            <div
              style={{
                border: "1px solid black",
                width: "100%",
                maxWidth: "500px",
                minHeight: "800px",
                padding: "10px",
                paddingRight: "20px",
                paddingLeft: "20px",
              }}
            >
              {fields().map((field, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      marginTop: "8px",
                      marginBottom: "8px",
                      width: "100%",
                      border:
                        fieldKey == i
                          ? "2px solid #bae0ff"
                          : "1px solid lightgray",
                      height: "200px",
                      padding: "10px",
                      color: "darkgray",
                      cursor: "pointer",
                      backgroundColor:
                        setStyle == i
                          ? fieldKey == i
                            ? "rgba(0,0,0,0)"
                            : "#fafafa"
                          : "rgba(0,0,0,0)",
                    }}
                    onMouseEnter={() => {
                      setSetStyle(i);
                    }}
                    onMouseLeave={() => {
                      setSetStyle(-1);
                    }}
                    onClick={(e) => {
                      setFieldKey(i);
                    }}
                  >
                    <b>{i + 1 + ": " + field.title.slice(0, 20)}</b>{" "}
                    {" (" + getTypeName(field.type) + ")"}
                    <Button
                      danger
                      style={{ display: "inline-block", float: "right" }}
                      onClick={(e) => {
                        if (fieldKey == i) {
                          setFieldKey(0);
                        }

                        props.updateStep((state) => {
                          state.fields.splice(i, 1);
                        });

                        setIsChanged(true);
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                    <Skeleton
                      style={{ marginTop: "20px" }}
                      title={false}
                      paragraph={{ rows: 4 }}
                    ></Skeleton>
                  </div>
                );
              })}
              <div
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                  width: "100%",
                  height: "100px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Dropdown overlay={addFields}>
                  <Button key="addMenuBtn">
                    + {t("bde:fields.label_addField")}
                  </Button>
                </Dropdown>
              </div>
            </div>
          </Col>
          <Col span={16}>
            <Title level={5}>{t("bde:app.createitem.edit")}</Title>
            <div
              style={{
                paddingTop: "1px",
                height: "100%",
                padding: "10px",
              }}
            >
              <div
                style={{
                  marginBottom: "8px",
                  width: "100%",
                  padding: "10px",
                  marginTop: 8, //+ fieldKey * 200,
                  border: "1px solid lightgray",
                }}
              >
                <div
                  style={{
                    padding: "20px",
                  }}
                >
                  <b style={{ display: "inline-block" }}>
                    {t("bde:fields.label_editField") + ": "}{" "}
                    <i>{fields()[fieldKey]?.title || ""}</i>
                  </b>
                </div>
                <div style={{ padding: "20px" }}>
                  <b>{t("bde:fields.label_chooseFieldname")}</b>
                  <div>
                    <Input
                      className="od_admin_bde_input"
                      style={{
                        display: "block",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                      placeholder={t("bde:fields.label_fieldname")}
                      value={fields()[fieldKey].title}
                      onChange={(e) => {
                        if (e.target.value != fields()[fieldKey].title) {
                          setIsChanged(true);
                        }
                        props.updateStep((state) => {
                          state.fields[fieldKey].title = e.target.value;
                        });
                      }}
                    />
                  </div>
                  <b>{t("bde:fields.label_editFieldDetails")}</b>
                  <FormItem
                    field={fields()[fieldKey]}
                    updateField={updateField}
                  />

                  <b style={{ display: "block", marginTop: "20px" }}>
                    <b>{t("bde:app.createitem.order")}</b>
                  </b>

                  <Button
                    style={{ marginLeft: "10px" }}
                    icon={<UpOutlined />}
                    onClick={() => {
                      props.updateStep((state) => {
                        state.fields.splice(fieldKey, 1);
                        state.fields.splice(
                          Math.max(0, fieldKey - 1),
                          0,
                          fields()[fieldKey]
                        );
                      });
                      setFieldKey(Math.max(0, fieldKey - 1));
                      setIsChanged(true);
                    }}
                  />
                  <Button
                    style={{ marginLeft: "10px" }}
                    icon={<DownOutlined />}
                    onClick={() => {
                      props.updateStep((state) => {
                        state.fields.splice(fieldKey, 1);
                        state.fields.splice(
                          Math.min(state.fields.length, fieldKey + 1),
                          0,
                          fields()[fieldKey]
                        );
                      });
                      setFieldKey(Math.min(fields().length, fieldKey + 1));
                      setIsChanged(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const getTitleOfField = () => {
    const header = (
      <div>
        <b>{t("bde:entries.label_pagename")}</b>
        <div>
          <Input
            className="od_admin_bde_input"
            style={{ display: "block", width: "100%", marginBottom: "20px" }}
            placeholder={t("bde:entries.label_pagename")}
            value={props.step?.title || ""}
            onChange={(e) => {
              props.updateStep((state) => {
                state.title = e.target.value;
              });
            }}
          />
          <b>{t("bde:entries.label_pagedescr")}</b>
          <TextArea
            className="od_admin_bde_input"
            style={{ display: "block", width: "100%", marginBottom: "20px" }}
            rows={4}
            placeholder={t("bde:entries.label_pagedescr_placholder")}
            value={props.step?.description || ""}
            onChange={(e) => {
              props.updateStep((state) => {
                state.description = e.target.value;
              });
            }}
          />
        </div>
      </div>
    );
    return (
      <div style={{ padding: "20px" }}>
        {header}
        {getFieldInfos(fieldKey || 0)}
      </div>
    );
  };

  return getTitleOfField();
});

function FormItem({ field, updateField }) {
  const t = useTranslation();
  const formManager = FormManager.getInstance();
  if (field && field.type) {
    const EditComponent = formManager.get(field.type).EditComponent;
    return <EditComponent field={field} updateField={updateField} />;
  }

  return (
    <div style={{ marginTop: "20px", textAlign: "center" }}>
      <strong>{t("bde:fields.label_noFieldSelected")}</strong>
      <br />
      <WarningOutlined
        style={{ fontSize: "5em", color: "rgba(0, 0, 0, 0.25)" }}
      />
      <br />
      {t("bde:fields.label_addField")}
    </div>
  );
}
