import { Form, Input, Typography } from "antd";
import React from "react";

import { useTranslation } from "@opendash/core";
const { Text } = Typography;
const { TextArea } = Input;

function TextInput(props) {
  const t = useTranslation();
  let style = {};
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  const state = props.Data;
  const options = state.options || {};
  const rules = [];
  if (state["v_required"] && state["v_required"].value) {
    rules.push({
      required: t("bde:app.forms.common.required"),
      message: t("bde:app.forms.common.error"),
    });
  }
  if (options.validation) {
    rules.push(({ getFieldValue }) => ({
      validator(rule, value) {
        const re = new RegExp(options.validation.expression);
        if (!re.test(value))
          return Promise.reject(t("bde:" + options.validation.err));
        return Promise.resolve();
      },
    }));
  }
  let inputelement = <Input style={style} placeholder={state.hint} />;
  if (state.options.area) {
    inputelement = <TextArea rows={4} style={style} placeholder={state.hint} />;
  }

  return (
    <Form.Item
      name={state.key}
      label={state.title}
      rules={rules}
      style={{ marginBottom: "24px" }}
    >
      {inputelement}
    </Form.Item>
  );
}
export default TextInput;
