import Parse from "parse";

import {
  NavigationAdapterContext,
  NavigationAdapterInterface,
  NavigationGroupInterface,
  NavigationItemInterface,
  UserInterface,
} from "@opendash/core";

import { AdapterConfig } from "../types";
import { _User, NavigationGroup, NavigationItem } from "../types-generated";
import { fetchParse } from "./helper";

export class ParseNavigationAdapter implements NavigationAdapterInterface {
  private context: NavigationAdapterContext;

  private initRunning = false;

  private config: AdapterConfig;

  constructor(config: AdapterConfig) {
    this.config = config;
  }

  onContext(context: NavigationAdapterContext) {
    this.context = context;

    this.init();
  }

  onUser(user: UserInterface) {
    this.init();
  }

  private async init() {
    if (this.initRunning) {
      return;
    }

    if (!(await Parse.User.currentAsync<_User>())) {
      return;
    }

    this.initRunning = true;

    if (navigator.onLine) {
      // TODO: BETTER OFFLINE SUPPORT

      await Promise.all([
        this.fetchNavigationGroups(),
        this.fetchNavigationItems(),
      ]);
    }

    this.context.setLoading(false);

    this.initRunning = false;
  }

  async createNavigationGroup(input: NavigationGroupInterface) {
    try {
      const item = new NavigationGroup(input);

      item.set("label", input.label);
      item.set("icon", input.icon);
      item.set("order", input.order);

      item.setACL(new Parse.ACL(await Parse.User.currentAsync<_User>()));

      await item.save();
      await this.init();

      return item.id;
    } catch (error) {
      console.error(error);
    }
  }

  async updateNavigationGroup(input: NavigationGroupInterface) {
    try {
      const item = new NavigationGroup(input);

      await item.save();
    } catch (error) {
      console.error(error);
    }
  }

  async deleteNavigationGroup(input: NavigationGroupInterface) {
    try {
      const item = new NavigationGroup(input);
      await item.destroy();
    } catch (error) {
      console.error(error);
    }
  }

  async createNavigationItem(input: NavigationItemInterface) {
    try {
      const item = new NavigationItem();

      item.set("group", input.group);
      item.set("place", input.place);
      item.set("order", input.order);
      item.set("label", input.label);
      item.set("icon", input.icon);
      item.set("color", input.color);
      item.set("link", input.link as string);
      item.set("event", input.event);
      item.set("routeCondition", input.routeCondition.toString());
      item.set("activeCondition", input.activeCondition.toString());

      item.setACL(new Parse.ACL(await Parse.User.currentAsync<_User>()));

      await item.save();
      await this.init();

      return item.id;
    } catch (error) {
      console.error(error);
    }
  }

  async updateNavigationItem(input: NavigationItemInterface) {
    try {
      const item = new NavigationItem({ id: input.id });

      item.set("group", input.group);
      item.set("place", input.place);
      item.set("order", input.order);
      item.set("label", input.label);
      item.set("icon", input.icon);
      item.set("color", input.color);
      item.set("link", input.link as string);
      item.set("event", input.event);
      item.set("routeCondition", input.routeCondition.toString());
      item.set("activeCondition", input.activeCondition.toString());

      await item.save();
    } catch (error) {
      console.error(error);
    }
  }

  async deleteNavigationItem(input: { id: string }) {
    try {
      const item = new NavigationItem(input);
      await item.destroy();
    } catch (error) {
      console.error(error);
    }
  }

  private async fetchNavigationGroups() {
    if (!NavigationGroup) {
      return;
    }

    await fetchParse(
      new Parse.Query(NavigationGroup).ascending("order").limit(999999),
      (result) => {
        this.context.setNavigationGroups(
          result.map((value) => this.mapNavigationGroups(value))
        );
      },
      (key, value) => {
        this.context.updateNavigationGroup(
          key,
          this.mapNavigationGroups(value)
        );
      },
      this.config.liveQueries
    );
  }

  private async fetchNavigationItems() {
    if (!NavigationItem) {
      return;
    }

    await fetchParse(
      new Parse.Query(NavigationItem).ascending("order").limit(999999),
      (result) => {
        this.context.setNavigationItems(
          result.map((value) => this.mapNavigationItems(value))
        );
      },
      (key, value) => {
        this.context.updateNavigationItem(key, this.mapNavigationItems(value));
      },
      this.config.liveQueries
    );
  }

  private mapNavigationGroups(input: Parse.Object): NavigationGroupInterface {
    if (!input) {
      return undefined;
    }

    return {
      id: input.id,
      label: input.get("label"),
      icon: input.get("icon"),
      order: input.get("order"),
    };
  }

  private mapNavigationItems(input: Parse.Object): NavigationItemInterface {
    if (!input) {
      return undefined;
    }

    return {
      id: input.id,
      group: input.get("group"),
      place: input.get("place"),
      order: input.get("order"),

      label: input.get("label"),
      icon: input.get("icon"),
      color: input.get("color"),

      link: input.get("link"),
      event: input.get("event"),

      routeCondition: input.get("routeCondition") || null,
      activeCondition: input.get("activeCondition") || null,
    };
  }
}
