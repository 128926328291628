import {
  AppPortal,
  createInternalComponent,
  useServiceStore,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { DateUnitInterface, useDataService } from "@opendash/plugin-timeseries";
import {
  Affix,
  Button,
  Col,
  DatePicker,
  Divider,
  Row,
  Segmented,
  Switch,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";

interface Props {
  open: boolean;
  close: () => void;
}
type selectionModeType =
  | "none"
  | "custom"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";
export const DashboardTimerangeModal = createInternalComponent<Props>(
  function DashboardTimerangeModal({ open, close }) {
    const t = useTranslation();

    const DataService = useDataService();
    const fetchingOptions = useServiceStore(
      DataService,
      (state) => state.overwriteFetchingOptions
    );

    const [dates, setDates] = React.useState<[Dayjs | null, Dayjs | null]>([
      null,
      null,
    ]);
    const [, setURLParams] = useUrlParam(
      "timeOverwrite",
      fetchingOptions,
      "json"
    );
    const [selectionMode, setSelectionMode] =
      React.useState<selectionModeType | null>(
        fetchingOptions ? "custom" : "none"
      );
    const [liveValues, setLiveValues] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (!fetchingOptions) return;
      if (selectionMode === "none") setSelectionMode("custom");
      setLiveValues(!!fetchingOptions.live);
      if (fetchingOptions.historyType === "absolute") {
        setDates([dayjs(fetchingOptions.start), dayjs(fetchingOptions.end)]);
      }
    }, [fetchingOptions]);

    React.useEffect(() => {
      if (selectionMode === "none") {
        setDates([null, null]);
      }
    }, [selectionMode]);
    // <Drawer
    //   open={open}
    //   title={t("opendash:dashboards.timerange_modal_title")}
    //   onClose={close}
    //   height={334}
    //   placement="top"
    // >
    return (
      <>
        {open && (
          <AppPortal place="content">
            <Affix offsetTop={46}>
              <div style={{ backgroundColor: "#FFF", padding: 16 }}>
                {/* <p>{t("opendash:dashboards.timerange_modal_description")}</p> */}
                <Row gutter={16}>
                  <Col span={24}>
                    <Segmented
                      block
                      style={{ width: "100%" }}
                      value={selectionMode}
                      onChange={(selection) => {
                        setSelectionMode(selection as selectionModeType);
                      }}
                      options={[
                        { label: t("opendash:ui.discard"), value: "none" },
                        {
                          label: t(
                            "opendash:monitoring.explorer.step_fetching_time_absolute"
                          ),
                          value: "custom",
                        },
                        { label: t("opendash:ui.day"), value: "day" },
                        { label: t("opendash:ui.week"), value: "week" },
                        { label: t("opendash:ui.month"), value: "month" },
                        { label: t("opendash:ui.quarter"), value: "quarter" },
                        { label: t("opendash:ui.year"), value: "year" },
                      ]}
                    />
                  </Col>
                </Row>
                {selectionMode !== "none" && <Divider />}
                {selectionMode !== "none" && (
                  <Row>
                    <Col span={20}>
                      {selectionMode === "custom" && (
                        <DatePicker.RangePicker
                          showTime
                          value={dates}
                          style={{ width: "100%" }}
                          onChange={(date) => {
                            setDates(date);
                          }}
                        />
                      )}
                      {selectionMode !== "custom" && (
                        <DatePicker
                          value={dates[0]}
                          onChange={(date) => {
                            let dateunit = selectionMode;
                            if (dateunit == "quarter") dateunit = "month";

                            let end = date.endOf(
                              selectionMode as "week" | "year" | "month"
                            );
                            if (selectionMode === "quarter") {
                              end = end.add(2, "month");
                            }
                            setDates([
                              date.startOf(
                                selectionMode as "week" | "year" | "month"
                              ),
                              end,
                            ]);
                          }}
                          style={{ width: "100%" }}
                          picker={
                            selectionMode as
                              | "week"
                              | "quarter"
                              | "year"
                              | "month"
                          }
                        />
                      )}
                    </Col>
                    <Col span={4} style={{ textAlign: "right" }}>
                      {t("opendash:monitoring.history_options.live_enabled")}
                      {"  "}
                      <Switch
                        checked={liveValues}
                        onChange={() => {
                          setLiveValues(!liveValues);
                        }}
                      />
                    </Col>
                  </Row>
                )}

                <>
                  <Divider></Divider>
                  <Row>
                    <Col span={24}>
                      <Button
                        style={{ width: "100%" }}
                        type="primary"
                        children={t("opendash:ui.save")}
                        onClick={() => {
                          DataService.store.update((draft) => {
                            if (!dates[0] || !dates[1]) {
                              setURLParams(undefined);
                              draft.overwriteFetchingOptions = null;
                            } else {
                              let sm = selectionMode;
                              if (sm === "quarter") {
                                sm = "month";
                              }
                              let start = dates[0].valueOf();
                              let end = dates[1].valueOf();
                              if (sm !== "custom") {
                                start = dates[0]
                                  .startOf(sm as "week" | "year" | "month")
                                  .valueOf();
                                end = dates[1]
                                  .endOf(sm as "week" | "year" | "month")
                                  .valueOf();
                              }

                              const fetchOpts = {
                                historyType: "absolute" as
                                  | "absolute"
                                  | "relative"
                                  | "aggregate",
                                start,
                                end,
                                value: 0,
                                unit: "week" as DateUnitInterface,
                                live: liveValues,
                              };

                              draft.overwriteFetchingOptions = fetchOpts;
                            }
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </>

                {/* <DataItemHistoryOptionsPicker
          options={{
            history: true,
            // historyRequired: true,
            historyPreventFuture: true,
          }}
          value={state}
          onChange={(config) => {
            if (config.historyType) {
              const x: DataFetchingOptionsInterface = {
                historyType: config.historyType,
                start: config.start,
                end: config.end,
                value: config.value,
                unit: config.unit,
                live: false,
              };

              setState(x);
            } else {
              setState(undefined);
            }
          }}
        />
        <Space direction="horizontal">
          <Button
            type="primary"
            disabled={equals(state, fetchingOptions)}
            children={t("opendash:ui.save")}
            onClick={() => {
              DataService.store.update((draft) => {
                draft.overwriteFetchingOptions = state;
              });
            }}
          />
          <Button
            //disabled={equals(state, fetchingOptions)}
            children={t("opendash:ui.discard")}
            onClick={() => {
              DataService.store.update((draft) => {
                draft.overwriteFetchingOptions = undefined;
              });
            }}
          />
        </Space> */}
              </div>
            </Affix>
          </AppPortal>
        )}
      </>
    );
  }
);
