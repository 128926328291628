import * as React from "react";

import { useEventListener, uuid } from "..";

type XYPosition = [number, number];

interface PropsDisplay {
  width: number;
  height: number;
  imageWidth: number;
  imageHeight: number;
  imageUrl: string;
  markers: Record<
    string,
    {
      x: number;
      y: number;
      render(props: {
        key: string;
        style: React.CSSProperties;
        onClick?: React.MouseEventHandler<HTMLElement>;
        onMouseDown?: React.MouseEventHandler<HTMLElement>;
      }): React.ReactNode;
    }
  >;
}

interface PropsEdit extends PropsDisplay {
  onCreateMarker(key: string, x: number, y: number): void;
  onUpdateMarkerPosition(key: string, x: number, y: number): void;
  onMarkerDoubleClick(key: string): void;
}

interface Props extends PropsEdit {
  edit: boolean;
}

export const MarkerCanvas = React.memo<Props>(function MarkerCanvas({
  edit,
  ...props
}) {
  if (edit) {
    return <ImageEdit {...props} />;
  }

  return <ImageRender {...props} />;
});

function ImageEdit({
  width,
  height,
  imageWidth,
  imageHeight,
  imageUrl,
  markers,
  onCreateMarker: createMarker,
  onUpdateMarkerPosition: updateMarker,
  onMarkerDoubleClick,
}: PropsEdit) {
  const mouseposition = React.useRef<XYPosition>([0, 0]);
  const annotationDrag = React.useRef<[string, HTMLElement] | null>(null);

  const imageContainerRef = React.useRef<HTMLDivElement>(null);
  const scale = width / imageWidth;

  const calculatePosition = (): XYPosition => {
    if (!imageContainerRef.current) {
      return [0, 0];
    }

    const [mouseX, mouseY] = mouseposition.current;
    const maxX = imageWidth;
    const maxY = imageHeight;

    const viewportOffset = imageContainerRef.current.getBoundingClientRect();

    const offsetTop = viewportOffset.top;
    const offsetLeft = viewportOffset.left;

    let x = (mouseX - offsetLeft - 15) / scale;
    let y = (mouseY - offsetTop - 15) / scale;

    x = Math.round(x / 10) * 10;
    y = Math.round(y / 10) * 10;

    x = Math.round(Math.max(0, Math.min(x, maxX)));
    y = Math.round(Math.max(0, Math.min(y, maxY)));

    return [x, y];
  };

  useEventListener("mousemove", (e: MouseEvent) => {
    mouseposition.current = [e.clientX, e.clientY];

    if (annotationDrag.current !== null) {
      // drag
      const [x, y] = calculatePosition();

      const left = Math.round(x * scale);
      const top = Math.round(y * scale);

      const [, element] = annotationDrag.current;

      element.style.top = top + "px";
      element.style.left = left + "px";
    }
  });

  useEventListener("mouseup", (e: MouseEvent) => {
    if (annotationDrag.current !== null) {
      const [x, y] = calculatePosition();
      const [index] = annotationDrag.current;

      updateMarker(index, x, y);
    }

    annotationDrag.current = null;
  });

  return (
    <div
      ref={imageContainerRef}
      style={{
        width,
        height,
        position: "relative",
        userSelect: "none",
      }}
    >
      <img
        src={imageUrl}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          width,
          height: Math.round(imageHeight * scale),
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 2,
          width,
          height,
        }}
        onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
          const key = uuid();
          const [x, y] = calculatePosition();

          createMarker(key, x, y);
        }}
      />
      {Object.entries(markers).map(([key, marker]) =>
        marker.render({
          key: key,
          style: {
            position: "absolute",
            left: Math.round(marker.x * scale) + "px",
            top: Math.round(marker.y * scale) + "px",
            zIndex: 3,
          },

          onClick: (e) => {
            if (e.detail === 2) {
              // on dubble klick
              onMarkerDoubleClick(key);
            }
          },
          onMouseDown: (e) => {
            // drag start
            annotationDrag.current = [key, e.target as HTMLElement];
          },
        })
      )}
    </div>
  );
}

function ImageRender({
  width,
  height,
  imageWidth,
  imageHeight,
  imageUrl,
  markers,
}: PropsDisplay) {
  const scale = width / imageWidth;

  return (
    <div
      style={{
        width,
        height,
        position: "relative",
      }}
    >
      <img
        src={imageUrl}
        style={{
          width,
          height: Math.round(imageHeight * scale),
        }}
      />
      {Object.entries(markers).map(([key, marker]) =>
        marker.render({
          key: key,
          style: {
            position: "absolute",
            left: Math.round(marker.x * scale) + "px",
            top: Math.round(marker.y * scale) + "px",
          },
        })
      )}
    </div>
  );
}
