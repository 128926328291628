import Parse from "parse";
import { useEffect, useMemo, useState } from "react";
import { NotificationStateProvider } from "..";
import { catchError, useCurrentUser, useLocalStorage } from "../../..";
import { Notification } from "../../../types-generated";

/**
 * A hook for fetching notifications from the Parse backend.
 *
 * This returns the following values: notifications, notificationsCount, unreadNotificationsCount, showRead, setShowRead, markAsRead, loadmore, maxpagesize, loading
 *
 * @returns A tuple containing the notifications, the number of notifications, a boolean to show read notifications, a function to set the show read notifications, a function to mark a notification as read, a function to load more notifications, the max page size and a boolean to indicate if the notifications are loading
 */
export function useNotifications(): [
  Notification[],
  number,
  number,
  boolean,
  (next: boolean) => void,
  (notification: Notification, value: boolean) => void,
  (page: number, pageSize: number) => Promise<void>,
  number,
  boolean,
] {
  const maxpagesize = 10;
  const [showRead, setShowRead] = useLocalStorage(
    "notification.notificationshowRead",
    false
  );
  const state = useMemo(() => NotificationStateProvider.getState(), []);
  const user = useCurrentUser();
  const [reload, setReload] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setReload((prev) => prev + 1);
  }, [showRead]);

  const unreadquery = useMemo(() => {
    const notificationQuery = new Parse.Query(Notification)
      .equalTo("read", false)
      .limit(1000);

    if (typeof user !== "undefined") {
      notificationQuery.equalTo("user", {
        __type: "Pointer",
        objectId: user.id,
        className: "_User",
      });
    }

    return notificationQuery;
  }, [user]);

  const query = useMemo(() => {
    const showReadQuery = new Parse.Query(Notification);

    const notificationQuery = new Parse.Query(Notification)
      .equalTo("read", false)
      .descending("createdAt")
      .limit(maxpagesize);

    showReadQuery
      .equalTo("read", showRead)
      .descending("createdAt")
      .limit(maxpagesize);

    if (typeof user !== "undefined") {
      notificationQuery.equalTo("user", {
        __type: "Pointer",
        objectId: user.id,
        className: "_User",
      });

      showReadQuery.equalTo("user", {
        __type: "Pointer",
        objectId: user.id,
        className: "_User",
      });
    }

    return Parse.Query.or(notificationQuery, showReadQuery);
  }, [reload, user]);

  useEffect(() => {
    setLoading(true);
    const notificationpromise = query.find();
    const countpromise = query.count();

    Promise.all([notificationpromise, countpromise]).then((values) => {
      state.notifications = values[0];
      state.notificationsCount = values[1];
      setLoading(false);
    });
  }, [reload]);

  useEffect(() => {
    unreadquery.count().then((value) => {
      state.unreadNotificationsCount = value;
    });
  }, [reload]);

  const loadmore = async (page: number, pageSize: number) => {
    const [newnotificationsError, newnotifications] = await catchError(
      query
        .skip((page - 1) * pageSize)
        .limit(maxpagesize)
        .find()
    );

    state.notifications = newnotifications;
    setReload((prev) => prev + 1);
  };

  const markAsRead = async (notification: Notification, value: boolean) => {
    notification.set("read", value);
    await notification.save();

    setReload((prev) => prev + 1);
  };

  return [
    state.notifications,
    state.notificationsCount,
    state.unreadNotificationsCount,
    showRead,
    setShowRead,
    markAsRead,
    loadmore,
    maxpagesize,
    loading,
  ];
}
