import { createInternalComponent } from "@opendash/core";
import {
  DataFetchingOptionsInterface,
  DataFetchingSelectionInterface,
  DataInput,
  DataItemDimensionIdentifierInterface,
  DataItemHistoryOptionsPicker,
} from "@opendash/plugin-timeseries";
import { Checkbox, Divider } from "antd";
import * as React from "react";
import {} from "..";

const dataFetchingOptionsKeys = [
  "live",
  "liveRequired",
  "history",
  "historyRequired",
  "historyForceAbsolute",
  "historyForceRelative",
  "aggregation",
  "aggregationRequired",
  "aggregationRequiresHistory",
  "resolution",
  "resolutionRequired",
];

export const MonitoringDevRoute = createInternalComponent(
  function MonitoringDevRoute() {
    const [selection, setSelection] = React.useState<
      DataItemDimensionIdentifierInterface[]
    >([]);
    const [time, setTime] = React.useState<DataFetchingOptionsInterface>();

    const [dataFetchingOptions, setDataFetchingOptions] =
      React.useState<DataFetchingSelectionInterface>({
        live: true,
        liveRequired: false,
        history: true,
        historyRequired: false,
        historyForceAbsolute: false,
        historyForceRelative: false,
        aggregation: true,
        aggregationRequired: false,
        aggregationRequiresHistory: false,
        resolution: true,
        resolutionRequired: false,
      });

    return (
      <div style={{ width: "100%", background: "white", padding: 20 }}>
        <Divider>Datenauswahl</Divider>

        <DataInput
          options={{
            select: "dimension",
            min: 1,
            max: 3,
            types: ["Boolean", "Number", "String"],
          }}
          value={selection}
          onChange={(nextSelection) => setSelection(nextSelection)}
        />

        <Divider />

        <pre>{JSON.stringify(selection, null, 2)}</pre>

        <Divider>Zeitraum Settings</Divider>

        {dataFetchingOptionsKeys.map((key) => (
          <Checkbox
            key={key}
            children={key}
            checked={dataFetchingOptions[key]}
            onChange={(e) => {
              setDataFetchingOptions((x) => ({
                ...x,
                [key]: e.target.checked,
              }));
            }}
          />
        ))}

        <Divider>Zeitraum 123</Divider>

        <DataItemHistoryOptionsPicker
          options={dataFetchingOptions}
          value={time}
          onChange={(nextValue) => {
            setTime(nextValue);
          }}
        />

        <Divider />

        <pre>{JSON.stringify(time, null, 2)}</pre>
      </div>
    );
  }
);
