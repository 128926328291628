import * as Parse from "parse";
import * as React from "react";

import { SourceInterface, useSource } from "@opendash/core";
import { useParseQuery } from "parse-hooks";

import { Options as OpenWareOptions } from "../types";
import { Report } from "./Report";

export interface ReportingContextInterface {
  config: OpenWareOptions;
  reports: Report[];
  loading: boolean;
  source: SourceInterface;
  reload: () => void;
  searchString: string;
  setSearchString: (value: string) => void;
}

export const ReportQuery = new Parse.Query(Report);

export const ReportingContext = React.createContext(undefined);

export function useReportingContext(): ReportingContextInterface {
  return React.useContext(ReportingContext);
}
export interface ReportingProviderProps {
  children?: React.ReactNode;
}

export const ReportingProvider = React.memo<ReportingProviderProps>(
  function ReportingProvider({ children }) {
    const { result: allReports, reload, loading } = useParseQuery(ReportQuery);

    const [source] = useSource();

    const [searchString, setSearchString] = React.useState("");

    const reports = React.useMemo(() => {
      const toReturnFirst = allReports.filter((report) => {
        let language = window.localStorage.getItem("opendash:language")
          ? JSON.parse(
              window.localStorage.getItem("opendash:language") || '"de"'
            )
          : "de";
        return (
          report.get("language") === language ||
          report.get("language") === undefined ||
          report.get("language") === ""
        );
      });

      const toReturn = toReturnFirst.filter((report) => {
        /*const filterString =
          report.get("description") + report.get("name") + report.get("title");

        return (
          !searchString ||
          filterString.toLowerCase().includes(searchString.toLowerCase())
        );*/
        const filterString = (
          report.get("title") +
          report.get("description") +
          report.get("name")
        ).toLowerCase();

        // Splitte den `searchString` in einzelne Wörter und prüfe jedes Wort separat
        const searchTerms = searchString.toLowerCase().trim().split(/\s+/);

        return searchTerms.every((term) => filterString.includes(term));
      });
      return toReturn;
    }, [allReports, source, searchString]);

    return (
      <ReportingContext.Provider
        value={{
          reports,
          source,
          reload,
          loading,
          searchString,
          setSearchString,
        }}
        children={children}
      />
    );
  }
);
