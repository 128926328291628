import { useTranslation } from "@opendash/core";
import { Input } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { ItemAttributes } from "..";

export default observer((props: { field; updateField }) => {
  const t = useTranslation();
  return (
    <div className="od_admin_bde_input">
      <Input
        style={{ display: "block" }}
        addonBefore={t("bde:label_placeholder")}
        value={props.field.hint}
        onChange={(e) => {
          props.updateField((state) => {
            state.hint = e.target.value;
          });
        }}
      />
      {ItemAttributes(props)}
    </div>
  );
});
