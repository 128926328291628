import {
  createInternalComponent,
  SourceInterface,
  SourcePicker,
  useServiceStore,
  useSource,
  useTranslation,
} from "@opendash/core";
import { Input, Modal, Space } from "antd";
import * as React from "react";
import { useDashboards, useMonitoringService } from "..";

interface Props {}

export const DashboardCopyModal = createInternalComponent<Props>(
  function DashboardCopyModal({}) {
    const t = useTranslation();
    const MonitoringService = useMonitoringService();

    const [source, setSource] = React.useState<SourceInterface | undefined>(
      undefined
    );
    const [name, setName] = React.useState<string | undefined>(undefined);

    const dashboards = useDashboards();

    const copyDashboardId = useServiceStore(
      MonitoringService,
      React.useCallback((state) => state.copyDashboardId, [])
    );

    const dashboard = dashboards.find(
      (dashboard) => dashboard.id === copyDashboardId
    );

    const [, , sources] = useSource();

    React.useEffect(() => {
      if (dashboard) {
        const src = sources.find((s) => s.id === dashboard.source);

        setName(dashboard.name);
        setSource(src);
      } else {
        setName(undefined);
        setSource(undefined);
      }
    }, [copyDashboardId]);

    return (
      <Modal
        open={!!copyDashboardId}
        title={t("opendash:dashboards.copy.modal_title")}
        okText={t("opendash:ui.create")}
        onOk={() => {
          MonitoringService.copyDashboard(
            copyDashboardId,
            source.id,
            name
          ).then((id) => {});
        }}
        cancelText={t("opendash:ui.cancel")}
        onCancel={(e) => {
          MonitoringService.closeCopyDashboardDialog();
        }}
        okButtonProps={{ disabled: !source || !name }}
      >
        <p>{t("opendash:dashboards.copy.modal_description")}</p>

        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("opendash:dashboards.copy.modal_placeholder")}
          />

          <SourcePicker
            style={{ width: "100%" }}
            value={source}
            onChange={(newSource) => {
              setSource(newSource);
            }}
          />

          <p>{t("opendash:dashboards.copy.modal_source_description")}</p>
        </Space>
      </Modal>
    );
  }
);
