import { useFeedback, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import Parse from "parse";
import React, { useEffect, useState } from "react";
import { Form, Unit } from "../../../parse";

export default observer((props: { form: any }) => {
  const t = useTranslation();
  const { message } = useFeedback();
  const [reload, setReload] = useState<number>(0);
  const [existingUnits, setExistingUnits] = useState<Array<Unit>>([]);
  const [form, setForm] = useState<Form>(undefined);

  useEffect(() => {
    async function setup() {
      try {
        const parseform = await new Parse.Query(Form).get(props.form.objectid);
        setForm(parseform);

        const innerQuery = new Parse.Query(Form).equalTo(
          "objectId",
          parseform.id
        );

        //Search units for this form
        const units = await new Parse.Query(Unit)
          .matchesQuery("forms", innerQuery)
          .find();

        if (units.length === 0) {
          return [];
        }

        setExistingUnits(units);
      } catch (e) {
        console.error(e);
      }
    }
    setup();
  }, [props.form, reload]);

  const setVisibility = async () => {
    if (
      !existingUnits ||
      existingUnits.length === 0 ||
      existingUnits.length > 1
    ) {
      message.warning(t("bde:app.createform.not_assigned_to_unit"));
    }

    if (isVisibleItem()) {
      form.set("visible_in_app", false);
      await form.save();
      message.success(t("bde:app.changed_visible_no"));
    } else {
      form.set("visible_in_app", true);
      await form.save();
      message.success(t("bde:app.changed_visible_yes"));
    }

    setReload((currVal) => currVal + 1);
  };

  const isVisibleItem = (): boolean => {
    if (typeof form === "undefined") return true;

    return (
      typeof form.get("visible_in_app") === "undefined" ||
      form.get("visible_in_app")
    );
  };

  const isVisibleItemAttributeAvailable = (): boolean => {
    console.log();
    if (typeof form === "undefined") return false;
    if (typeof form.visible_in_app === "undefined") return true;
    return true;
  };

  if (isVisibleItemAttributeAvailable()) {
    return (
      <Tooltip
        title={
          isVisibleItem()
            ? t("bde:app.visible_in_app")
            : t("bde:app.visible_in_app_not")
        }
      >
        <Button
          disabled={existingUnits.length === 0 || existingUnits.length > 1}
          type="default"
          onClick={() => setVisibility()}
          icon={
            isVisibleItem() ? (
              <Icon icon="fa:eye"></Icon>
            ) : (
              <Icon icon="fa:eye-slash"></Icon>
            )
          }
        />
      </Tooltip>
    );
  } else return <></>;
});
