import React from "react";
import { useSource, useTranslation } from "@opendash/core";
import GreisManualInput from "./GreisManualInput";

export default function GreisManual() {
  const [source] = useSource();
  const t = useTranslation();

  return <GreisManualInput spsid={source ? source.tag : ""} />;
}
