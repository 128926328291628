import * as React from "react";

import {
  AdminLayout,
  AppFactory,
  produce,
  useFeedback,
  useTranslation,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { $parse } from "@opendash/plugin-parse";
import { AdminToolbar } from "@opendash/ui";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import Parse from "parse";
import { useEffect, useState } from "react";
import { configProvider } from "../..";
import {
  CreateNewFormModal,
  PinMenuItemButton,
  StepOrderEditor,
  VisibleMenuItemButton,
} from "../../features/forms";
import { Form, Page, Unit } from "../../parse";

const { Option } = Select;

const FormManagePage = (props: { factory: AppFactory }) => {
  const t = useTranslation();
  const { message } = useFeedback();

  const [state, setState] = useState({
    selected: null,
    showOrderModal: false,
    pages: [],
    dirty: [],
    forms: [],
    units: [],
  });
  const [stateSource, setStateSource] = useState({
    loadingSources: true,
    sources: [],
  });

  const [stateItems, setStateItems] = useState({
    oldSource: [{ objectid: "", name: "", sourceTag: "", type: "" }],
    newSource: [{ objectid: "", sourceTag: "", name: "", type: "" }],
  });

  const [selection, setSelection] = useState(null);
  const [currentForm, setCurrentForm] = useState(null);
  const [currentFormSource, setCurrentFormSource] = useState(null);
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    loadSources();
  }, []);

  async function getSources() {
    const query = new Parse.Query(configProvider.config.sources.table).limit(
      9999
    );
    const results = await query.find();
    return results;
  }

  async function getUnits() {
    const query = new Parse.Query(configProvider.config.units.table).limit(
      9999
    );
    const results = await query.find();
    return results;
  }

  const loadSources = async () => {
    try {
      await retrieveFormsAndUpdateState();

      const sources = (await getSources()) || [];
      const unitsQuery = (await getUnits()) || [];

      const filteredSources = sources
        .filter((loc) => !!loc.get("tag"))
        .map((loc) => ({
          type: "source",
          name: `${loc.get("name")} (${loc.get("tag")})`,
          sourceTag: loc.get("tag"),
          objectid: loc.id,
        }));

      const unitFormats = unitsQuery.map((unit) => ({
        type: "unit",
        name: `${unit.get("name")} (${unit.get("source")})`,
        sourceTag: unit.get("source"),
        objectid: unit.id,
      }));

      const sourcesAndGroups = [...filteredSources, ...unitFormats];

      setStateSource(
        produce((x) => {
          x.loadingSources = false;
          x.sources = sourcesAndGroups;
        })
      );
    } catch (err) {
      message.error(t("bde:units.error") + err.message);
    }
  };

  const SourceSelect = (props) => {
    const { IsLoading, Sources, Selected, OnChange } = props;

    if (IsLoading) {
      return <Select style={{ display: "block", width: "100%" }} loading />;
    }

    return (
      <Select
        showSearch
        filterOption={(inputValue, option) =>
          option.children
            .toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        }
        mode="multiple"
        style={{ display: "block", width: "100%" }}
        placeholder={t("bde:units.label_source")}
        className="od_admin_bde_input"
        value={Selected}
        onChange={OnChange}
      >
        {Sources.map((source, i) => (
          <Option key={i} value={i}>
            {`${t(
              source.type === "source"
                ? "bde:admin.create.form.source.source"
                : "bde:admin.create.form.source.group"
            )}: ${source.name}`}
          </Option>
        ))}
      </Select>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: t("bde:forms.label_input_formname"),
      dataIndex: "name",
      key: "name",
      render: (text, form) => (
        <>
          {edit === form.objectid ? (
            <Input
              disabled={!form.canWrite}
              value={form.name}
              onChange={(dForm) => onChangeName(dForm, form)}
              style={{ width: "100%" }}
            />
          ) : (
            <div style={{ width: "100%" }}>{form.name}</div>
          )}
        </>
      ),
    },
    {
      title: t("bde:forms.label_shouldStoreUser"),
      key: "shouldStoreUserInfo",
      responsive: ["lg"],
      align: "center",
      render: (text, form) => (
        <Checkbox
          checked={form.storeUserInfo}
          disabled={!form.canWrite || edit !== form.objectid}
          onChange={() => onChangeStoreUser(form)}
          style={{ width: "10%" }}
        />
      ),
    },
    {
      title: t("bde:forms.label_assigned_pages"),
      key: "pages",
      render: (text, form) => (
        <>
          {form.canWrite && edit === form.objectid && (
            <Select
              mode="tags"
              disabled={!form.canWrite}
              placeholder={t("bde:label_placeholder_choose")}
              value={form.pages}
              onChange={(pages) => onChangeAssignedPages(pages, form)}
              style={{ width: "100%" }}
            >
              {state.pages.map((page) => (
                <Option key={page.objectid} value={page.objectid}>
                  {page.data.title}
                </Option>
              ))}
            </Select>
          )}
          {!form.canWrite ||
            (edit !== form.objectid &&
              form.pages.map((page) => {
                const pageName = state.pages.find(
                  (findPage) => page === findPage.objectid
                ).data.title;
                return <Tag key={page}>{pageName}</Tag>;
              }))}
        </>
      ),
    },
    {
      title: t("bde:forms.label_form_order"),
      key: "order",
      responsive: ["lg"],
      align: "center",
      render: (text, form) => (
        <Button
          disabled={!form.canWrite || edit !== form.objectid}
          onClick={(e) => openOrderEditor(text, form)}
          icon={<Icon icon="fa:sort-numeric-down"></Icon>}
        />
      ),
    },
    {
      title: t("bde:app.createform.assign"),
      key: "source",
      dataIndex: "source",
      render: (text, form) => {
        let result = "";
        for (const unit of state.units) {
          for (const unitform of unit.forms) {
            if (unitform.id === form.objectid) {
              if (result === "") {
                if (unit.name === form.name) {
                  if (stateSource.sources.length > 0) {
                    const gefundenesObjekt = stateSource.sources.find(
                      (objekt) => objekt.sourceTag === unit.source
                    );
                    result =
                      t("bde:admin.create.form.source.source") +
                      ": " +
                      (gefundenesObjekt ? gefundenesObjekt.name : "");
                  }
                } else {
                  result =
                    t("bde:admin.create.form.source.group") + ": " + unit.name;
                }
              } else {
                if (unit.name === form.name) {
                  if (stateSource.sources.length > 0) {
                    const gefundenesObjekt = stateSource.sources.find(
                      (objekt) => objekt.sourceTag === unit.source
                    );
                    result =
                      result +
                      ", " +
                      t("bde:admin.create.form.source.source") +
                      ": " +
                      (gefundenesObjekt ? gefundenesObjekt.name : "");
                  }
                } else {
                  result =
                    result +
                    ", " +
                    t("bde:admin.create.form.source.group") +
                    ": " +
                    unit.name;
                }
              }
            }
          }
        }
        return (
          <>
            {edit === form.objectid ? (
              <SourceSelect
                IsLoading={stateSource.loadingSources}
                Sources={stateSource.sources}
                OnChange={onChangeID}
                Selected={selection}
              />
            ) : (
              <div style={{ width: "100%" }}>{result}</div>
            )}
          </>
        );
      },
    },
    {
      title: t("bde:app.action"),
      key: "save",
      align: "center",
      render: (text, form) => (
        <Space>
          {entryIsDirty(form) ? (
            <Tooltip title={t("bde:save")}>
              <Button
                type="primary"
                disabled={!form.canWrite}
                onClick={(e) => save(text, form)}
                icon={<Icon icon="fa:save"></Icon>}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t("bde:forms.label_edit_form")}>
              <Button
                disabled={!form.canWrite}
                onClick={(e) => {
                  setCurrentForm(form);

                  const formSelection = [];
                  const formSources = [];
                  for (const unit of state.units) {
                    for (const unitform of unit.forms) {
                      if (unitform.id === form.objectid) {
                        if (form.name === unit.name) {
                          //Source
                          const index = stateSource.sources.indexOf(
                            stateSource.sources.find(
                              (e) => e.sourceTag === unit.source
                            )
                          );
                          formSources.push(stateSource.sources[index]);
                          formSelection.push(index);
                        } else {
                          //Unit
                          const index = stateSource.sources.indexOf(
                            stateSource.sources.find((e) => {
                              return e.objectid == unit.objectid;
                            })
                          );
                          formSources.push(stateSource.sources[index]);
                          formSelection.push(index);
                        }
                      }
                    }
                  }

                  setCurrentFormSource(formSources);
                  setSelection(formSelection);

                  if (edit !== form.objectid) {
                    setStateItems(
                      produce((draft) => {
                        draft.oldSource = [
                          {
                            objectid: "",
                            name: "",
                            sourceTag: "",
                            type: "",
                          },
                        ];
                        draft.newSource = [
                          { objectid: "", sourceTag: "", name: "", type: "" },
                        ];
                      })
                    );
                  }
                  setState(
                    produce((draft) => {
                      draft.dirty = [];
                    })
                  );
                  retrieveFormsAndUpdateState();
                  setEdit(
                    edit === null
                      ? form.objectid
                      : edit === form.objectid
                        ? null
                        : form.objectid
                  );
                }}
                icon={<Icon icon="fa:pen"></Icon>}
              />
            </Tooltip>
          )}

          <PinMenuItemButton form={form} factory={props.factory} />
          <VisibleMenuItemButton form={form} />

          <Popconfirm
            title={t("bde:deleteConfirm")}
            disabled={!form.canWrite}
            onConfirm={(e) => {
              deleteForm(text, form);
            }}
            onCancel={(e) => {}}
            okText={t("bde:delete")}
            cancelText={t("bde:cancel")}
          >
            <Button
              icon={<Icon icon="fa:trash" key="delete" />}
              danger
              disabled={!form.canWrite}
            />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: t("bde:label_acl"),
      key: "acl",
      align: "center",
      render: (text, form) => (
        <>
          <Popconfirm
            title={t("bde:admin.forms.overview.acl.popup.title")}
            description={t("bde:admin.forms.overview.acl.popup.desc")}
            onConfirm={() => {
              if (form.canWrite) {
                const toAcl = Form.createWithoutData(form.objectid);
                $parse.ui.editObjectACL(toAcl.className, toAcl.id).then((e) => {
                  if (e) {
                    const rightUnit = state.units.find((unit) =>
                      unit.forms.some(
                        (unitform) => unitform.id === form.objectid
                      )
                    );
                    const toSave = new Unit();
                    if (rightUnit.objectid) {
                      toSave.id = rightUnit.objectid;
                      toSave.fetch().then(
                        async (res) => {
                          res.setACL(toAcl.getACL());
                          res.save().then((result) => {});
                        },
                        (error) => {
                          message.error(
                            t("bde:saveEntryError") +
                              " (Unit): " +
                              error.message
                          );
                        }
                      );
                    }
                  }
                });
              }
            }}
            onCancel={() => {
              if (form.canWrite) {
                const toAcl = Form.createWithoutData(form.objectid);
                $parse.ui.editObjectACL(toAcl.className, toAcl.id);
              }
            }}
            okText={t("bde:admin.forms.overview.acl.popup.yes")}
            cancelText={t("bde:admin.forms.overview.acl.popup.no")}
          >
            <Button icon={<Icon icon="fa:lock" />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const deleteForm = async (text, form) => {
    try {
      const todelete = await Form.createWithoutData(form.objectid).fetch();
      await state.units.forEach(async (element) => {
        if (element.name == form.name) {
          const todeleteUnit = await Unit.createWithoutData(
            element.objectid
          ).fetch();
          const eventRelation = todeleteUnit.relation("forms");
          eventRelation.remove(todelete);
          todeleteUnit.save().then(() => {
            eventRelation
              .query()
              .count()
              .then((e) => {
                if (e == 0 && todeleteUnit.get("name") == form.name) {
                  todeleteUnit.destroy();
                }
              });
          });
        }
      });
      await todelete.destroy().then((resultdel) => {
        message.success;
        t("bde:deleteSuccess");
        retrieveFormsAndUpdateState();
      });
    } catch (error) {
      message.error(t("bde:deleteError") + " " + error.message);
    }
  };

  const onChangeAssignedPages = (pages, form) =>
    updateFormState(form.objectid, { pages });

  const onChangeStoreUser = (form) =>
    updateFormState(form.objectid, { storeUserInfo: !form.storeUserInfo });

  const onChangeName = (dForm, form) =>
    updateFormState(form.objectid, { name: dForm.target.value });

  const updateFormState = (objectId, update) => {
    setState(
      produce((draft) => {
        draft.dirty.push(objectId);
        const cform = draft.forms.find((form) => form.objectid === objectId);
        Object.assign(cform, update);
      })
    );
  };

  const onChangeID = (e) => {
    setSelection(e);
    setStateItems(
      produce((draft) => {
        draft.oldSource = currentFormSource;
        draft.newSource = e.map((index) => stateSource.sources[index]);
      })
    );
    setState(
      produce((draft) => {
        draft.dirty.push(currentForm.objectid);
      })
    );
  };

  const compareArrayObjects = (arr1, arr2) => {
    return (
      arr1.length === arr2.length &&
      arr1.every((obj1) => arr2.some((obj2) => obj2.objectid === obj1.objectid))
    );
  };

  //New Compare Function
  function compareArrays(oldArray, newArray) {
    const added = [];
    const deleted = [];

    // Vergleiche neue Objekte
    for (const newObj of newArray) {
      const existsInOld = oldArray.some(
        (oldObj) => oldObj.objectid === newObj.objectid
      );
      if (!existsInOld) {
        added.push(newObj);
      }
    }

    // Vergleiche gelöschte Objekte
    for (const oldObj of oldArray) {
      const existsInNew = newArray.some(
        (newObj) => newObj.objectid === oldObj.objectid
      );
      if (!existsInNew) {
        deleted.push(oldObj);
      }
    }

    return { added, deleted };
  }

  const setNewNameForUnit = async (oldName, newName) => {
    // Check Name Change
    if (oldName != newName) {
      for (const element of state.units) {
        if (element.name == oldName) {
          const toChangeNameUnit = await Unit.createWithoutData(
            element.objectid
          ).fetch();
          toChangeNameUnit.set("name", newName);
          await toChangeNameUnit.save();
        }
      }
    }
    await retrieveFormsAndUpdateState();
  };

  const save = async (text, form) => {
    try {
      const toSave = form.objectid
        ? await new Form({ id: form.objectid }).fetch()
        : new Form();

      if (!form.objectid) {
        const acl = new Parse.ACL(Parse.User.current());
        toSave.setACL(acl);
      }

      await processSave(toSave, form, !form.objectid);
      setEdit(null);
      setSelection(null);
    } catch (error) {
      message.error(t("bde:saveEntryError") + ": " + error.message);
    }
  };

  const processSave = async (toSave, form, newForm) => {
    const oldName = currentForm.name;
    const newName = form.name;

    toSave.set("RID", form.rid);
    toSave.set("name", form.name);
    toSave.set("form", form.json);
    toSave.set("entries", form.pages);
    toSave.set("storeUserInfo", form.storeUserInfo);

    toSave.save().then(
      async (res) => {
        message.success(t("bde:saveEntrySuccess") + ": " + res.get("name"));
        setState(
          produce((draft) => {
            draft.dirty = draft.dirty.filter((entry) => entry !== res.id);
            if (newForm) {
              draft.forms.push({
                objectid: res.id,
                name: res.get("name"),
                rid: res.get("RID"),
                json: res.get("form"),
                pages: res.get("entries"),
                storeUserInfo: res.get("storeUserInfo"),
              });
            }
          })
        );

        await setNewNameForUnit(oldName, newName);

        // Save new Source
        const checkIfNewSource =
          !stateItems.oldSource ||
          !compareArrayObjects(stateItems.oldSource, stateItems.newSource);

        if (checkIfNewSource) {
          const { added, deleted } = compareArrays(
            stateItems.oldSource,
            stateItems.newSource
          );

          added.forEach((newSourceElement) => {
            if (newSourceElement.type == "unit") {
              const toSaveUnit = new Unit();
              toSaveUnit.id = newSourceElement.objectid;
              toSaveUnit.fetch().then(async (result) => {
                const formRelation = result.relation("forms");
                formRelation.add(res);
                toSaveUnit.save().then((e) => {
                  retrieveFormsAndUpdateState();
                });
              });
            } else {
              const acl = new Parse.ACL(Parse.User.current());
              const toSaveUnit = new Unit();
              toSaveUnit.set("name", currentForm.name);
              toSaveUnit.set("source", newSourceElement.sourceTag);
              const formRelation = toSaveUnit.relation("forms");
              formRelation.add(res);
              toSaveUnit.setACL(acl);
              toSaveUnit.save().then((e) => {
                retrieveFormsAndUpdateState();
              });
            }
          });
          deleted.forEach((oldSourceElement) => {
            const toDeleteUnit = new Unit();
            if (oldSourceElement.type == "unit") {
              toDeleteUnit.id = oldSourceElement.objectid;
            } else {
              const unitToDelete = state.units.find(
                (obj) =>
                  obj.source === oldSourceElement.sourceTag &&
                  obj.name === form.name
              );
              toDeleteUnit.id = unitToDelete.objectid;
            }
            toDeleteUnit.fetch().then(async (res) => {
              const eventRelation = res.relation("forms");
              eventRelation.remove(toSave);
              toDeleteUnit.save().then(
                (success) => {
                  setStateItems(
                    produce((draft) => {
                      draft.oldSource = [
                        {
                          objectid: "",
                          name: "",
                          sourceTag: "",
                          type: "",
                        },
                      ];
                      draft.newSource = [
                        { objectid: "", sourceTag: "", name: "", type: "" },
                      ];
                    })
                  );
                  eventRelation
                    .query()
                    .count()
                    .then((e) => {
                      if (e == 0 && toDeleteUnit.name == form.name) {
                        toDeleteUnit.destroy().then((e) => {
                          retrieveFormsAndUpdateState();
                        });
                      }
                    });

                  retrieveFormsAndUpdateState();
                },
                (error) => {
                  console.error("Fehler beim Löschen des Objekts: ", error);
                }
              );
            });
          });
        }
      },
      (error) => {
        message.warning(t("bde:saveEntryError") + ": " + error.message);
        retrieveFormsAndUpdateState();
      }
    );
  };

  const updateState = (action) => {
    setState(produce(state, action));
  };

  const updateStepOrder = (action) => {
    updateState((state) => {
      const form = state.forms.find((f) => f.objectid === state.selected);
      state.forms = state.forms.map((form) => {
        if (form.objectid === state.selected) {
          action(form);
          if (!state.dirty.includes(form.objectid)) {
            state.dirty.push(form.objectid);
          }
        }
        return form;
      });
    });
  };

  const openOrderEditor = (text, form) => {
    updateState((state) => {
      state.selected = form.objectid;
      state.showOrderModal = true;
    });
  };

  const retrieveFormsAndUpdateState = async () => {
    try {
      const query = new Parse.Query(Page);
      const results = await query.find();

      const formquery = new Parse.Query(Form);
      const formresults = await formquery.find();

      const unitquery = new Parse.Query(Unit);
      unitquery.include("forms");
      const unitresults = await unitquery.find();

      const units = await Promise.all(
        unitresults.map(async (unitelement) => {
          return {
            objectid: unitelement.id,
            name: unitelement.get("name"),
            source: unitelement.get("source"),
            forms: await unitelement.get("forms").query().find(),
          };
        })
      );

      setState(
        produce((draft) => {
          draft.pages = results.map((eintrag) => {
            return {
              objectid: eintrag.id,
              name: eintrag.get("data").title || "",
              pid: eintrag.get("pageID"),
              data: eintrag.get("data"),
            };
          });
          draft.forms = formresults.map((element) => {
            return {
              objectid: element.id,
              name: element.get("name"),
              rid: element.get("RID"),
              json: element.get("form"),
              pages: element.get("entries"),
              storeUserInfo: element.get("storeUserInfo"),
              canWrite: $parse.ui.getObjectPermission(element, "update"),
            };
          });
          draft.units = units;
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const entryIsDirty = (form) => {
    return state.dirty.includes(form.objectid);
  };

  const getModal = () => {
    const form = state.forms.find((f) => f.objectid === state.selected);

    if (form) {
      const pages = form.pages.map((page) => {
        return state.pages.find((form) => form.objectid === page);
      });

      return (
        <Modal
          title={t("bde:forms.label_form_order") + ": " + form.name}
          open={!!form}
          onOk={() => {
            setState(
              produce(state, (state) => {
                state.showOrderModal = false;
                state.selected = null;
              })
            );
          }}
          okText={t("bde:save")}
          cancelText={t("bde:cancel")}
          onCancel={() => {
            setState(
              produce(state, (state) => {
                state.showOrderModal = false;
                state.selected = null;
              })
            );
          }}
          width="75%"
        >
          <StepOrderEditor setSteps={updateStepOrder} Entries={pages} />
        </Modal>
      );
    } else {
      return <div />;
    }
  };

  return (
    <AdminLayout>
      <AdminToolbar
        title={t("bde:forms.label")}
        description={t("bde:forms.description")}
        search={""}
        actions={
          $parse.ui.getClassPermission(
            configProvider.config.forms.table,
            "create"
          )
            ? [
                <CreateNewFormModal
                  key="createform"
                  onClose={(e) => {
                    retrieveFormsAndUpdateState();
                  }}
                />,
              ]
            : []
        }
      />
      <div className="od-page-main">
        <Table
          rowKey={(row) => row.objectid}
          dataSource={state.forms}
          size="middle"
          columns={columns}
        />
        {getModal()}
      </div>
    </AdminLayout>
  );
};

export default FormManagePage;
