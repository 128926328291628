import { useTranslation } from "@opendash/core";
import { Switch } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { ItemAttributes } from "..";

export default observer((props: { field; updateField }) => {
  // return <pre>{JSON.stringify(field, null, "  ")}</pre>;
  const t = useTranslation();
  return (
    <div>
      <div className="od_admin_bde_input">
        <Switch
          checked={props.field.options.time}
          onChange={(e) => {
            props.updateField((state) => {
              state.options.time = e;
            });
          }}
        />{" "}
        {t("bde:fields.label_askTime")}
      </div>
      {ItemAttributes(props)}
    </div>
  );
});
