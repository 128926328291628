import { useOpenDashServices } from "@opendash/core";
import { useParams } from "@opendash/router";
import { Spin } from "antd";
import Parse from "parse";
import React, { useEffect, useRef, useState } from "react";
import { Slideshow } from "../types";

export default () => {
  const innerParams = useParams();
  const [slideShow, setSlideShow] = useState<Slideshow>();
  const [url, setUrl] = useState<string>(null);
  const [slideInterval, setSlideInterval] = useState<NodeJS.Timeout>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const { SourceService } = useOpenDashServices();

  const init = async () => {
    const res = (await new Parse.Query("OD3_Monitoring_Slideshow")
      .equalTo("objectId", innerParams.slideShow)
      .include("dashboards")
      .first()) as Parse.Object;
    const dashboards = await res.relation("dashboards").query().find();
    const result = res.toJSON();
    result.dashboards = dashboards.map((dashboard) => ({
      id: dashboard.id,
      source: dashboard.get("source").id,
    }));
    setSlideShow(result as unknown as Slideshow);
  };
  useEffect(() => {
    init();
  }, []);

  const incrementer = useRef<{
    set: (number) => void;
    inc: () => void;
    get: () => number;
  }>();

  function increment() {
    setCurrentSlide((currentSlide + 1) % slideShow.dashboards.length);
  }
  useEffect(() => {
    incrementer.current = {
      inc: increment,
      set: (number) => {
        setCurrentSlide(number);
      },
      get: () => {
        return currentSlide;
      },
    };
  });

  useEffect(() => {
    if (slideShow && slideShow.dashboards.length > 0) {
      if (slideInterval) {
        clearInterval(slideInterval);
        incrementer.current.set(0);
      }
      setSlideInterval(
        setInterval(() => {
          const newUrl =
            window.location.origin +
            "/monitoring/dashboards/" +
            slideShow.dashboards[incrementer.current.get()].source +
            "/" +
            slideShow.dashboards[incrementer.current.get()].id +
            "?embedded=true";

          SourceService.setCurrent(
            slideShow.dashboards[incrementer.current.get()].source
          );
          incrementer.current.inc();

          setUrl(newUrl);
        }, slideShow.interval)
      );
      const newUrl =
        window.location.origin +
        "/monitoring/dashboards/" +
        slideShow.dashboards[incrementer.current.get()].source +
        "/" +
        slideShow.dashboards[incrementer.current.get()].id +
        "?embedded=true";
      SourceService.setCurrent(
        slideShow.dashboards[incrementer.current.get()].source
      );
      incrementer.current.inc();

      setUrl(newUrl);
    }
    return () => {
      if (slideInterval) {
        clearInterval(slideInterval);
        incrementer.current.set(0);
      }
    };
  }, [slideShow]);
  /*
  const slideShow = useParseQueryFirst(
    
  );
  */
  if (!url) {
    return <Spin />;
  }

  return <iframe style={{ width: "100%", height: "100vh" }} src={url} />;
};

//export default SensorConfiguration;
