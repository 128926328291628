import { AdminLayout, createInternalComponent } from "@opendash/core";
import * as React from "react";
import { CSVExplorer } from "..";

interface Props {}

export const CSVExplorerRoute = createInternalComponent<Props>(
  // eslint-disable-next-line no-empty-pattern
  function CSVExplorerRoute({}) {
    return (
      <AdminLayout contentPadding={true}>
        <CSVExplorer />
      </AdminLayout>
    );
  }
);
