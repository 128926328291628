import React from "react";
import {
  AggregationOperationInterfaceComponentProps,
  AggregationSettingInterface,
} from "./AggregationSettingComponents/AggregationSettingInterface";
import { AggregationOperation } from "./types";

class AggregationService {
  static instance = null as AggregationService | null;
  operations = {} as Record<
    string,
    AggregationSettingInterface<AggregationOperation, any>
  >;

  constructor() {
    this.operations = {};
  }

  static getInstance() {
    if (!AggregationService.instance) {
      AggregationService.instance = new AggregationService();
    }
    return AggregationService.instance;
  }
  public getAggregation(type: string) {
    if (!this.operations[type]) {
      throw new Error(`Aggregation type ${type} not found`);
    }
    return this.operations[type].create();
  }

  public list(type: string) {
    return Object.keys(this.operations);
  }

  public registerOperation(
    type: string,
    operation: AggregationSettingInterface<AggregationOperation, any>
  ) {
    this.operations[type] = operation;
  }
}

const AggregationSettingsView = (
  props: AggregationOperationInterfaceComponentProps<
    AggregationOperation,
    any
  > & {
    operation: string;
  }
) => {
  const { operation, value, onChange, props: innerProps, inline } = props;

  const Aggregation = React.useMemo(
    () => AggregationService.getInstance().getAggregation(operation).create(),
    [operation]
  );
  return (
    <div>
      <Aggregation.ViewComponent
        value={value}
        onChange={onChange}
        props={innerProps}
        inline={inline}
      />
    </div>
  );
};

export { AggregationService, AggregationSettingsView };
