import { produce, useTranslation } from "@opendash/core";
import { useDataService } from "@opendash/plugin-timeseries";
import { Description, IconSelect } from "@opendash/ui";
import React, { useEffect, useState } from "react";
import { SourceAggregationSettingsInterface } from "./SourceAggregationSettingsInterface";

export default (props: SourceAggregationSettingsInterface) => {
  const t = useTranslation();
  const { items, stage, setStage } = props;
  const [operation, setOperation] = useState(stage?.params?.operation || "sum");
  const DataService = useDataService();
  const updateOperation = () => {
    setStage({
      action: "source_aggregation",
      params: {
        operation: operation,
        dimension: items[0][2],
        source: items[0][0],
        id: items[0][1],
        splits: 1,
      },
    });
    let vType = DataService._getOrThrowSync(items[0][0], items[0][1])
      .valueTypes[items[0][2]];

    vType = produce(vType, (draft) => {
      draft.name = `${t(
        "opendash:monitoring.history_options.aggregation_" +
          (operation === "avg" ? "mean" : operation)
      )}  ${draft.name}`;
      if (operation === "count") draft.unit = "#";
    });

    props.setValueTypes([vType]);
  };
  useEffect(() => {
    updateOperation();
  }, [operation]);

  if (!stage) {
    updateOperation();
    return <Description>Invalid Parameters</Description>;
  }

  return (
    <>
      <Description>
        {t("openware:sensor.aggregate.sourceAggregation.hint")}
      </Description>
      <IconSelect
        value={props?.stage?.params?.operation || "sum"}
        size={5}
        onChange={(nextValue) => {
          setOperation(nextValue as "min" | "max" | "avg" | "sum" | "count");
        }}
        options={[
          {
            label: t("opendash:monitoring.history_options.aggregation_sum"),
            icon: null,
            tooltip: null,
            value: "sum",
            disabled: false,
          },
          {
            label: t("opendash:monitoring.history_options.aggregation_mean"),

            icon: null,
            tooltip: null,
            value: "avg",
            disabled: false,
          },
          {
            label: t("opendash:monitoring.history_options.aggregation_min"),

            icon: null,
            tooltip: null,
            value: "min",
            disabled: false,
          },
          {
            label: t("opendash:monitoring.history_options.aggregation_max"),

            icon: null,
            tooltip: null,
            value: "max",
            disabled: false,
          },
          {
            label: t("opendash:monitoring.history_options.aggregation_count"),

            icon: null,
            tooltip: null,
            value: "count",
            disabled: false,
          },
        ]}
      />
    </>
  );
};
