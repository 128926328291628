import {
  $framework,
  createInternalComponent,
  useServiceStore,
  useSource,
  useTranslation,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Dropdown, Modal } from "antd";
import * as React from "react";
import { WidgetContext } from "..";
import {
  WidgetContainer,
  WidgetContent,
  WidgetHeader,
  WidgetHeaderAction,
  WidgetHeaderName,
} from "./WidgetComponentLayoutDefault.layout";

export const WidgetComponentLayoutDefault = createInternalComponent<
  React.PropsWithChildren<{ context: WidgetContext }>
>(function WidgetComponentLayoutDefault({ children, context }) {
  const t = useTranslation();
  const [source] = useSource();
  const name = useServiceStore(
    context,
    (state) => state?.widget?.name || state.name
  );

  const hasSettings =
    context.type?.settingsComponent ||
    context.type?.dataItems ||
    context.type?.dataFetching;

  return (
    <WidgetContainer>
      <WidgetHeader>
        <Dropdown
          placement="bottomRight"
          menu={{
            items: [
              {
                key: "rename",
                title: t("opendash:widgets.rename_modal_tooltip"),
                icon: <Icon icon="fa:pen" />,
                label: t("opendash:ui.rename"),
                onClick: (e) => {
                  context.store.update((state) => {
                    state.rename = true;
                  });
                },
              },
              {
                key: "delete",
                title: t("opendash:widgets.delete_modal_tooltip"),
                icon: <Icon icon="fa:trash" />,
                label: t("opendash:ui.delete"),
                onClick: (e) => {
                  context.store.update((state) => {
                    state.delete = true;
                  });
                },
              },
              {
                key: "share",
                title: t("opendash:widgets.share_modal_tooltip"),
                icon: <Icon icon="fa:share-alt" />,
                label: t("opendash:ui.share"),
                onClick: (e) => {
                  context.store.update((state) => {
                    state.share = true;
                  });
                },
              },
              {
                key: "full",
                title: t("opendash:widgets.fullscreen_tooltip"),
                icon: <Icon icon="fa:expand-arrows" />,
                label: t("opendash:widgets.fullscreen_action"),
                onClick: (e) => {
                  $framework.router.navigate(
                    `/monitoring/widgets/${source.id}/${context.id}`
                  );
                },
              },
              {
                key: "hero",
                title: t("opendash:widgets.hero_tooltip"),
                icon: <Icon icon="fa:arrow-to-top" />,
                label: t("opendash:widgets.hero_action"),
                onClick: (e) => {
                  Modal.confirm({
                    title: t("opendash:widgets.hero_modal_title"),
                    content: t("opendash:widgets.hero_modal_description"),
                    onOk: () => {
                      context.setHero();
                    },
                  });
                },
              },
              {
                key: "heroLeft",
                title: t("opendash:widgets.hero_tooltip_left"),
                icon: <Icon icon="fa:arrow-to-left" />,
                label: t("opendash:widgets.hero_action_left"),
                onClick: (e) => {
                  Modal.confirm({
                    title: t("opendash:widgets.hero_modal_title"),
                    content: t("opendash:widgets.hero_modal_description"),
                    onOk: () => {
                      context.setHeroLeft();
                    },
                  });
                },
              },
              {
                key: "reload",
                title: t("opendash:widgets.reload_tooltip"),
                icon: <Icon icon="fa:sync" />,
                label: t("opendash:widgets.reload"),
                onClick: (e) => {
                  context.refresh();
                },
              },
              {
                disabled: !hasSettings,
                key: "settings",
                title: hasSettings
                  ? t("opendash:widgets.settings_modal_tooltip")
                  : t("opendash:widgets.settings_modal_tooltip_no_settings"),

                icon: <Icon icon="fa:cog" />,
                label: t("opendash:widgets.settings"),
                onClick: (e) => {
                  context.openSettings();
                },
              },
            ],
          }}
        >
          <WidgetHeaderAction>
            <Icon icon="fa:ellipsis-v" />
          </WidgetHeaderAction>
        </Dropdown>

        <WidgetHeaderName title={name}>{name}</WidgetHeaderName>
      </WidgetHeader>

      <WidgetContent ref={context.containerRef}>{children}</WidgetContent>
    </WidgetContainer>
  );
});
