import {
  createInternalComponent,
  produce,
  useSource,
  useUrlParam,
} from "@opendash/core";
import { useParams } from "@opendash/router";
import { Empty } from "antd";
import React from "react";
import { useWidgets } from "../hooks/useWidgets";
import { WidgetStatic } from "./WidgetStatic";

export const WidgetPrint = createInternalComponent(function WidgetRoute() {
  const [cSource, setCSource] = useSource();
  const { id, source } = useParams();
  const widgets = useWidgets();
  const [optionsOverwrites] = useUrlParam("wConfig", null, "json");
  const [typeOverwrite] = useUrlParam("wType", null, "string");
  if (cSource.id !== source) {
    setCSource(source);
  }
  const source_widget = widgets.find((w) => w.id === id);
  console.log({ typeOverwrite, optionsOverwrites });
  const widget = produce(
    source_widget || { type: null, config: null },
    (draft) => {
      if (optionsOverwrites) {
        if (!draft.config) draft.config = {};
        Object.keys(optionsOverwrites).forEach((key) => {
          draft.config[key] = optionsOverwrites[key];
        });
      }
      if (typeOverwrite) {
        draft.type = typeOverwrite;
      }
    }
  );
  if (!widget.type || !widget.config) {
    return <Empty />;
  }
  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        height: "100%",
        backgroundColor: "#fff",
        overflow: "hidden",
      }}
    >
      <WidgetStatic
        type={widget.type}
        config={widget.config}
        edit={false}
      ></WidgetStatic>
    </div>
  );
});
