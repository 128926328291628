import * as React from "react";

import { Layout } from "antd";

import styled from "styled-components";
import {
  AppSidebar,
  Boundary,
  Loading,
  NavigationMenu,
  useElementSize,
  useNavigation,
} from "..";

const Container = styled.div<{ padding: boolean }>`
  background: white;
  width: 100%;
  min-height: 100%;

  padding: ${(p) => (p.padding ? "24px" : "0")};
`;
const Sidebar = styled.div``;
const Content = styled.div``;

interface Props extends React.PropsWithChildren<{}> {
  contentPadding?: boolean;
  siderStyle?: any;
  loading?: boolean;
  contentWidth?: string;
  footerChildren?: React.ReactNode;
}

export const AdminLayout = React.memo<Props>(function AdminLayout({
  children,
  contentPadding = false,
  siderStyle,
  loading,
  contentWidth,
  footerChildren,
}) {
  const { width } = useElementSize({ current: window?.document?.body }, 100);
  const [groups, items, activeItems] = useNavigation("sidebar");

  const isMobile = width < 1200;

  if (isMobile) {
    return (
      <Container padding={contentPadding}>
        <AppSidebar />
        <Boundary>{children}</Boundary>
      </Container>
    );
  }

  return (
    <Layout style={{ background: "rgb(240, 242, 245)", overflow: "hidden" }}>
      {isMobile && <AppSidebar />}
      {!isMobile && (groups.length > 0 || items.length > 0) ? (
        <Layout.Sider width={300} style={{ background: "#fff", ...siderStyle }}>
          <NavigationMenu style={{ height: "100%" }} />
        </Layout.Sider>
      ) : null}
      <Layout style={{ overflow: "auto" }}>
        <Layout.Content
          style={{
            margin: "24px",
            width: contentWidth ? contentWidth : "auto",
          }}
        >
          {loading ? <Loading message="" /> : <Boundary>{children}</Boundary>}
        </Layout.Content>
        {footerChildren ? (
          <Layout.Footer
            style={{ margin: "24px", background: "#fff" }}
            children={footerChildren}
          />
        ) : undefined}
      </Layout>
    </Layout>
  );
});
