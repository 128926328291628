import { makeAutoObservable } from "@opendash/state";
import {
  AutocompleteResultType,
  GeoAdapterInterface,
  GeoCodeResultType,
  GeoCodeReverseResultType,
  PointType,
} from "../types";
import { AutocompleteService } from "./Autocomplete";
import { DialogService } from "./Dialog";
import { LocationService } from "./Location";

export class GeoService {
  public vectorStyle: string | null = null;
  public vectorStyleAvailable: boolean = false;

  public autocompleteAvailable: boolean = false;
  public geocodeAvailable: boolean = false;
  public geocodeReverseAvailable: boolean = false;

  public location = new LocationService();

  public dialogs = new DialogService(this);

  #adapter: GeoAdapterInterface | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public async init() {}

  public registerVectorStyle(style: string) {
    this.vectorStyle = style;
    this.vectorStyleAvailable = true;
  }

  public registerGeoImplementation(implementation: GeoAdapterInterface) {
    this.#adapter = implementation;
    this.autocompleteAvailable = true;
    this.geocodeAvailable = true;
    this.geocodeReverseAvailable = true;
  }

  public async autocomplete(
    query: string,
    limit: number = 5
  ): Promise<AutocompleteResultType[]> {
    if (!this.#adapter?.autocomplete) {
      throw new Error("Feature 'autocomplete' not available.");
    }

    return await this.#adapter.autocomplete(query, limit);
  }

  public async geocode(query: string): Promise<GeoCodeResultType[]> {
    if (!this.#adapter?.geocode) {
      throw new Error("Feature 'geocode' not available.");
    }

    return await this.#adapter.geocode(query);
  }

  public async geocodeReverse(
    point: PointType
  ): Promise<GeoCodeReverseResultType[]> {
    if (!this.#adapter?.geocodeReverse) {
      throw new Error("Feature 'geocodeReverse' not available.");
    }

    return await this.#adapter.geocodeReverse(point);
  }

  public createAutocomplete() {
    if (!this.#adapter?.autocomplete) {
      return undefined;
    }

    return new AutocompleteService(this);
  }
}
