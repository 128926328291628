import { Button, Image, Modal, Spin, Upload } from "antd";
import Parse from "parse";
import React from "react";

import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";

import { ParseClassFileValidation } from "../../types";

export interface FileDisplayProps {
  value: Parse.File;
  isInline: boolean;
}

export const FileDisplay = React.memo<FileDisplayProps>(function FileDisplay({
  value,
  isInline,
}) {
  const file = value as Parse.File;

  const isImage = /\.(png|jpg|jpeg|gif)$/.test(file.name());

  if (!isInline && isImage) {
    return <Image alt={file.name()} src={file.url()} />;
  }

  return (
    <Button
      size="small"
      href={file.url()}
      target="_blank"
      children={file.name()}
      icon={<Icon icon="fa:paperclip" />}
    />
  );
});

export interface FileEditProps {
  value: Parse.File;
  setValue?: (v: Parse.File) => void;
  validation?: ParseClassFileValidation;
  isRequired: boolean;
  isNullable: boolean;
}

export const FileEdit = React.memo<FileEditProps>(function FileEdit({
  value,
  setValue,
  validation,
}) {
  const t = useTranslation();

  // const state = useStore();

  const url = value?.url();
  const name = value?.name();

  const isImage =
    validation?.image ||
    (name &&
      (name.endsWith(".png") ||
        name.endsWith(".jpg") ||
        name.endsWith(".jpeg")));

  const imagePreview = isImage && url;

  const [loading, setLoading] = React.useState(false);

  const fileList = React.useMemo(
    () =>
      value
        ? [
            {
              uid: value.url(),
              name: value.name(),
              status: "done",
              url: value.url(),
            },
          ]
        : [],
    [value]
  );

  const beforeUpload = React.useCallback(
    (file) => {
      if (Array.isArray(validation?.mimeTypes)) {
        if (!validation.mimeTypes.includes(file.type)) {
          Modal.error({
            title: t("parse-admin:file_input.validation_error"),
            content: t("parse-admin:file_input.validation_file_type", {
              types: validation.mimeTypes.join(", "),
            }),
          });

          return false;
        }
      }

      if (validation?.maxFileSize) {
        if (file.size > validation.maxFileSize) {
          Modal.error({
            title: t("parse-admin:file_input.validation_error"),
            content: t("parse-admin:file_input.validation_file_size", {
              size: formatFileSize(validation.maxFileSize),
            }),
          });

          return false;
        }
      }

      setLoading(true);

      const name = file.name.replace(/[^0-9a-z]/gi, "");

      const parseFile = new Parse.File(name, file, file.type);

      parseFile.save().then(
        () => {
          setLoading(false);
          setValue(parseFile);
        },
        (error) => {
          setLoading(false);
          console.error(error);

          Modal.error({
            title: t("parse-admin:file_input.upload_error_title"),
            content: t("parse-admin:file_input.upload_error_content"),
          });
        }
      );

      return false;
    },
    [setLoading]
  );

  return (
    <Upload
      listType="picture-card"
      // @ts-ignore
      fileList={fileList}
      // showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={(info) => {
        if (info.fileList.length === 0) {
          setValue(null);
        }
      }}
    >
      {/* {imagePreview && (
        <img
          src={imagePreview}
          alt={name}
          style={{ width: "100%" }}
        />
      )} */}

      <div>
        {loading ? (
          <Spin />
        ) : (
          <Icon icon="fa:folder-open" style={{ fontSize: 26 }} />
        )}
        <div style={{ marginTop: 2 }}>
          {loading
            ? t("parse-admin:file_input.uploading")
            : value
              ? t("parse-admin:file_input.upload_replace")
              : t("parse-admin:file_input.upload_new")}
        </div>
      </div>
    </Upload>
  );
});

function formatFileSize(bytes: number): string {
  const units = ["B", "KB", "MB", "GB", "PB"];

  let size = bytes;
  let unit = units.shift();

  while (size > 1000 && units.length > 0) {
    size = Math.round(size / 100) / 10;
    unit = units.shift();
  }

  return size + unit;
}
