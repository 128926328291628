import { useFeedback, useLocalStorage, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import {
  DataItemDimensionIdentifierInterface,
  DataItemIdentifierInterface,
  useDataService,
} from "@opendash/plugin-timeseries";
import {
  Button,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Table,
  Tag,
  Typography,
} from "antd";
import cronstrue from "cronstrue/i18n";
import React, { useState } from "react";
import { getRequestServer } from "../DataCollectionOverview";
import AggregationCreateModal from "./AggregationCreateModalComponent";

export default (props) => {
  const { item } = props;
  const localeString =
    (useLocalStorage("opendash:language")[0] as string) || "de";
  const { message } = useFeedback();

  const [modalItem, setModalItem] = useState(
    null as
      | DataItemIdentifierInterface[]
      | DataItemDimensionIdentifierInterface[]
  );

  const t = useTranslation();
  const DataService = useDataService();
  const [aggregations, setAggregations] = React.useState(
    [] as {
      next_execution_ow_job: number;
      name: string;
      options: { stages: any[] };
      interval: { type: "cron"; params: string };
      _id: string;
      type: "aggregation";
      user: string;
      group: "aggregation";
    }[]
  );

  const requestServer = getRequestServer();
  const loadAggregations = async () => {
    const res = await requestServer.get(`schedule/aggregation`);

    if (res.data.status === 200) {
      if (item) {
        return res.data.result.filter((aggregation) => {
          return (
            aggregation.options.stages[0].params.source === item.user &&
            aggregation.options.stages[0].params.id === item.id
          );
        });
      } else {
        return res.data.result;
      }
    } else {
      return [];
    }
  };
  const init = async () => {
    setAggregations(await loadAggregations());
  };
  React.useEffect(() => {
    init();
  }, [item]);
  const columms = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sensor",
      dataIndex: ["options", "stages", 0, "params"],
      key: "sensor",
      render: (_, entry) => {
        return DataService.getItemName(
          DataService._getOrThrowSync(_.source, _.id),
          _.dimension,
          true
        );
      },
    },
    {
      title: "Operation",
      dataIndex: ["options", "stages", 0, "params", "operation"],
      key: "operation",
      render: (_, entry) => {
        return <Tag>{_}</Tag>;
      },
    },
    {
      title: "Interval",
      dataIndex: "interval",
      key: "interval",
      render: (_, entry) => {
        return (
          <Typography.Text>
            {" "}
            {cronstrue.toString(entry.interval.params, {
              locale: localeString,
            })}
          </Typography.Text>
        );
      },
    },
    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      render: (_, entry) => {
        return (
          <Popconfirm
            title={t("openware:sensor.aggregate.deleteConfirm")}
            onConfirm={async () => {
              const res = await requestServer.delete(
                `schedule/aggregation/${entry._id}`
              );
              init();
              if ((res?.status || 0) === 200) {
                message.success(t("openware:sensor.aggregate.deleteSuccess"));
              } else {
                message.success(t("openware:sensor.aggregate.deleteError"));
              }
            }}
          >
            <Icon icon="fa:trash-alt" />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <>
      <Dropdown
        disabled={
          item.valueTypes.filter((vtype) => vtype.type === "Number").length ===
          0
        }
        overlay={
          <Menu
            onClick={(e) => {
              const index = parseInt(e.key);
              setModalItem([[item.source, item.id, index]]);
            }}
            items={item.valueTypes.map((vType, index) => {
              if (vType.type !== "Number") return null;
              return {
                label: vType.name,
                key: index,
              };
            })}
          />
        }
      >
        <Button
          style={{ float: "right", marginBottom: "16px" }}
          type="primary"
          icon={<Icon icon="fa:plus"></Icon>}
        >
          {t("openware:sensor.aggregate.create")}
        </Button>
      </Dropdown>
      <AggregationCreateModal
        onClose={() => {
          setModalItem(null);
          init();
        }}
        open={!!modalItem}
        selection={modalItem}
      ></AggregationCreateModal>
      <Divider></Divider>
      <Table
        size="middle"
        columns={columms}
        dataSource={aggregations}
        rowKey={(record) => record._id}
      ></Table>
      {/* <DataItemEditComponent
        item={{
          name: "asd",
          source: "asd",
          valueTypes: [],
          id: "idasd",
          meta: {},
        }}
        setItem={(item) => {
          console.log(item);
        }}
        allowVTypAddition={true}
      /> */}
    </>
  );
};
