import { useWatch } from "antd/es/form/Form";
import { useMemo } from "react";
import { ParseField } from "../types/FormValues";
import { useMappedParseFields } from "./useMappedParseFields";

type Operation = {
  key: string;
  label: string;
  types: string[]; // allowed types
};

const OPERATIONS: Operation[] = [
  { key: "equalTo", label: "==", types: ["number", "string"] },
  { key: "notEqualTo", label: "!=", types: ["number", "string"] },
  { key: "greaterThan", label: ">", types: ["number"] },
  { key: "lessThan", label: "<", types: ["number"] },
  { key: "greaterThanOrEqual", label: ">=", types: ["number"] },
  { key: "lessThanOrEqual", label: "<=", types: ["number"] },
  { key: "contains", label: "contains", types: ["string"] },
  { key: "notContains", label: "not contains", types: ["string"] },
  { key: "startsWith", label: "starts with", types: ["string"] },
  { key: "endsWith", label: "ends with", types: ["string"] },
];

export const useOperationOptions = (index: number) => {
  const fields = useMappedParseFields();
  const selectedField = useWatch<ParseField>(["operations", index, "field"]);

  // options for the operation select depending on the selected field type
  const operationOptions = useMemo(() => {
    const selectedFieldObject = fields.find(
      (field) => field.field === selectedField?.value
    );
    if (!selectedField || !selectedFieldObject) return [];

    return OPERATIONS.filter((op) =>
      op.types.some((type) => type === selectedFieldObject?.type)
    ).map((op) => {
      return {
        label: op.label,
        value: op.key,
      };
    });
  }, [selectedField, fields]);

  const fieldOptions: ParseField[] = useMemo(() => {
    return fields.map((field) => {
      return {
        label: field.field + " (" + field.type + ")",
        value: field.field,
        type: field.type,
      };
    });
  }, [fields, selectedField]);

  return { fieldOptions, operationOptions };
};
