import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import * as React from "react";

import { Tooltip } from "antd";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid rgb(217, 217, 217);
  /* border-bottom: none; */
  border-radius: 4px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  margin-bottom: 24px;
  background: white;
  width: 100%;
`;

const Option = styled.div<{ disabled: boolean; active: boolean; size: number }>`
  /* border: 1px solid ${(props) => (props.active ? "#1890ff" : "grey")}; */
  width: calc(100% / ${(props) => props.size});
  padding: 10px;

  text-align: center;
  color: ${(props) =>
    props.disabled
      ? "rgba(0, 0, 0, 0.45)"
      : props.active
        ? "#1890ff"
        : "inherit"};
  user-select: none;

  cursor: pointer;
  border-bottom: 1px solid rgb(217, 217, 217);
  &:not(:nth-child(${(props) => props.size}n)) {
    border-right: 1px dotted rgb(217, 217, 217);
  }

  &:not(:nth-child(n + ${(props) => props.size + 1})) {
    border-bottom: 1px solid rgb(217, 217, 217);
  }

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }

  ${(props) => props.disabled && "cursor: not-allowed;"}
`;

const IconHolder = styled.div<{ size }>`
  font-size: 3em;
  font-size: calc(9em / ${(props) => props.size});
  padding-bottom: 5px;
`;
const LabelHolder = styled.div``;

interface Props {
  size?: number;
  options: {
    label: string;
    value: string;
    tooltip: string;
    icon: string;
    disabled: boolean;
  }[];
  value: string;
  onChange: (value: string) => void;
}

export const IconSelect: React.ElementType<Props> = ({
  value,
  options,
  onChange,
  size = 3,
}) => {
  const t = useTranslation();
  const actualValue = value || options[0].value;

  React.useEffect(() => {
    if (!value && options.length > 0) {
      onChange(options.find((o) => !o.disabled)?.value);
    }
  }, [value]);

  return (
    <Container>
      {options.map((option) => (
        <Tooltip
          title={t(option.tooltip)}
          placement="bottom"
          key={option.value}
        >
          <Option
            key={option.value}
            active={actualValue === option.value}
            disabled={option.disabled}
            size={size}
            onClick={() => {
              if (!option.disabled && actualValue !== option.value) {
                onChange(option.value);
              }
            }}
          >
            <IconHolder size={size}>
              <Icon icon={option.icon} />
            </IconHolder>

            <LabelHolder>{t(option.label)}</LabelHolder>

            {/* {actualValue === option.value && "Check"} */}
          </Option>
        </Tooltip>
      ))}
    </Container>
  );
};
