import { Description, IconSelect } from "@opendash/ui";
import { Modal, Select } from "antd";
import React, { useEffect, useMemo } from "react";

import { produce, useTranslation } from "@opendash/core";
import {
  DataItemDimensionIdentifierInterface,
  DataSelect,
} from "@opendash/plugin-timeseries";
import {
  DiffFirstLastAggregation,
  ExtendedIntervalOptions,
  HistoricalFetchAggregation,
  IntervalOptions,
  LiveFetchAggregation,
  SourceAggregation,
  SourceAggregationOperation,
} from "../virtual-sensors/types";

type AggregateTemplateValueType =
  | HistoricalFetchAggregation
  | SourceAggregation
  | DiffFirstLastAggregation
  | LiveFetchAggregation;

type AggregateTemplatePickerProps = {
  value: AggregateTemplateValueType | null;
  interval?: IntervalOptions;
  maxItems?: number;
} & (
  | {
      mode: "modal" | undefined;
      onOk: (x: AggregateTemplateValueType) => void;
      onCancel: () => void;
      open: boolean;
    }
  | {
      mode: "inline";
      onChange: (update: AggregateTemplateValueType) => void;
    }
);
type AggregateTemplateOperationPickerProps = {
  interval?: ExtendedIntervalOptions;
  value: AggregateTemplateValueType;
  onChange: (update: AggregateTemplateValueType) => void;
};
const AggregateTemplateOperationPicker = (
  props: AggregateTemplateOperationPickerProps
) => {
  const { interval, value, onChange } = props;
  const t = useTranslation();
  const [aggConfig, setAggConfig] = React.useState<PickerOperations>("none");
  const defaultInterval = interval || "day";
  const [cInterval, setCInterval] =
    React.useState<ExtendedIntervalOptions>(defaultInterval);

  const currentConfiguration = useMemo(() => {
    const res = produce(value, (draft) => {
      const element = getItem(draft);
      if (!element) return null;
      if (aggConfig !== "none") {
        let pipe: AggregateTemplateValueType;
        if (aggConfig === "diffFirstLast") {
          pipe = {
            action: "diffFirstLast",
            params: {
              dimension: 0,
              timeinterval: cInterval,
              source: element[0],
              id: element[1],
            },
          };
        } else if (aggConfig === "first" || aggConfig === "last") {
          pipe = {
            action: "live",
            params: {
              source: element[0],
              id: element[1],
              amount: 1,
              useStart: aggConfig === "first",
              unit: cInterval,
            },
          };
        } else {
          pipe = {
            action: "source_aggregation",
            params:
              cInterval === "interval"
                ? {
                    source: element[0],
                    id: element[1],
                    operation: aggConfig,
                    dimension: 0,
                    splits: 1,
                  }
                : {
                    operation: aggConfig,
                    dimension: 0,
                    timeinterval: cInterval,
                    source: element[0],
                    id: element[1],
                  },
          } as SourceAggregation;
        }

        return pipe;
      } else {
        return {
          action: "historical" as const,
          params: {
            source: element[0],
            id: element[1],
          },
        } as HistoricalFetchAggregation;
      }
    });
    return res;
  }, [cInterval, aggConfig]);

  useEffect(() => {
    onChange(currentConfiguration);
  }, [currentConfiguration]);
  type PickerOperations =
    | SourceAggregationOperation
    | "none"
    | "diffFirstLast"
    | "first"
    | "last";

  const options = [
    {
      label: t("opendash:monitoring.history_options.aggregation_none"),
      icon: null,
      tooltip: null,
      value: "none",
      disabled: false,
    },
    {
      label: t("opendash:monitoring.history_options.aggregation_sum"),
      icon: null,
      tooltip: null,
      value: "sum",
      disabled: false,
    },
    {
      label: t("opendash:monitoring.history_options.aggregation_mean"),

      icon: null,
      tooltip: null,
      value: "avg",
      disabled: false,
    },
    {
      label: t("opendash:monitoring.history_options.aggregation_min"),

      icon: null,
      tooltip: null,
      value: "min",
      disabled: false,
    },
    {
      label: t("opendash:monitoring.history_options.aggregation_max"),

      icon: null,
      tooltip: null,
      value: "max",
      disabled: false,
    },
    {
      label: t("opendash:monitoring.history_options.aggregation_first"),

      icon: null,
      tooltip: null,
      value: "first",
      disabled: false,
    },
    {
      label: t("opendash:monitoring.history_options.aggregation_last"),

      icon: null,
      tooltip: null,
      value: "last",
      disabled: false,
    },
    {
      label: t("opendash:monitoring.history_options.aggregation_count"),

      icon: null,
      tooltip: null,
      value: "count",
      disabled: false,
    },
    {
      label: t("opendash:monitoring.history_options.aggregation_diffminmax"),

      icon: null,
      tooltip: null,
      value: "diffminmax",
      disabled: false,
    },
    {
      label: t("opendash:monitoring.history_options.aggregation_diffFirstLast"),

      icon: null,
      tooltip: null,
      value: "diffFirstLast",
      disabled: false,
    },
  ];
  const intervalOptions = [
    {
      label: t("opendash:ui.minute"),
      icon: null,
      tooltip: null,
      value: "minute",
      disabled: false,
    },
    {
      label: t("opendash:ui.hour"),
      icon: null,
      tooltip: null,
      value: "hour",
      disabled: false,
    },
    {
      label: t("opendash:ui.day"),
      icon: null,
      tooltip: null,
      value: "day",
      disabled: false,
    },
    {
      label: t("opendash:ui.week"),
      icon: null,
      tooltip: null,
      value: "week",
      disabled: false,
    },
    {
      label: t("opendash:ui.month"),
      icon: null,
      tooltip: null,
      value: "month",
      disabled: false,
    },
    {
      label: t("opendash:ui.year"),
      icon: null,
      tooltip: null,
      value: "year",
      disabled: false,
    },
    {
      label: t("opendash:ui.interval"),
      icon: null,
      tooltip: null,
      value: "interval",
      disabled: false,
    },
  ];
  return (
    <>
      <Description
        children={t(
          "opendash:monitoring.history_options.aggregation_description"
        )}
      ></Description>
      <Select
        value={aggConfig}
        style={{ width: "100%" }}
        onChange={(e) => {
          setAggConfig(e as SourceAggregationOperation);
        }}
        options={options}
      />
      {aggConfig !== "none" && !interval && (
        <>
          <Description
            children={t("openware:monitoring.history_options.interval")}
          ></Description>
          <IconSelect
            value={cInterval}
            size={intervalOptions.length}
            onChange={(e) => {
              setCInterval(e as ExtendedIntervalOptions);
            }}
            options={intervalOptions}
          />
        </>
      )}
    </>
  );
};

const AggregateTemplatePicker = (props: AggregateTemplatePickerProps) => {
  const { value, interval, maxItems, mode } = props;
  let onOk, onCancel, open, onChange;
  if (mode === "modal") {
    onOk = props.onOk;
    onCancel = props.onCancel;
    open = props.open;
  } else {
    onChange = props.onChange;
  }
  const t = useTranslation();
  console.log(value, "value");
  const [currentConfiguration, setCurrentConfiguration] = React.useState(null);

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    onOk(currentConfiguration);
  };
  useEffect(() => {
    console.log(currentConfiguration, "currentConfiguration");
    if (mode === "inline") {
      onChange(currentConfiguration);
    }
  }, [currentConfiguration]);
  const selection = React.useMemo(() => {
    return getItem(currentConfiguration);
  }, [currentConfiguration]);
  return (
    <>
      {mode === "modal" && (
        <Modal
          width={"60%"}
          title={t("openware:reporting.settings.select_item")}
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <DataSelect
            showSearch={true}
            showValue={true}
            showActions={false}
            showType={false}
            showTimestamp={true}
            selectionOptions={{
              select: "dimension",
              min: 1,
              max: 1,
              //types: ["Number"],
            }}
            selection={selection ? [selection] : []}
            onSelection={(nextValue) => {
              const updateItemInfRes = updateItemInfo(
                value,
                nextValue[0],
                interval
              );
              console.log(updateItemInfRes, "updateItemInfRes");
              setCurrentConfiguration(updateItemInfRes);
            }}
          />
          <AggregateTemplateOperationPicker
            value={currentConfiguration}
            onChange={(val) => {
              console.log(val, "modewechsel");
              setCurrentConfiguration(val);
            }}
            interval={interval}
          ></AggregateTemplateOperationPicker>
        </Modal>
      )}
      {mode !== "modal" && (
        <>
          <DataSelect
            showSearch={true}
            showValue={true}
            showActions={false}
            showType={false}
            showTimestamp={true}
            selectionOptions={{
              select: "dimension",
              min: 1,
              max: maxItems,
              //types: ["Number"],
            }}
            selection={[selection]}
            onSelection={(nextValue) => {
              setCurrentConfiguration(
                updateItemInfo(value, nextValue[0], interval)
              );
            }}
          />
          <AggregateTemplateOperationPicker
            value={value}
            onChange={(val) => {
              setCurrentConfiguration(val);
            }}
            interval={interval}
          ></AggregateTemplateOperationPicker>
        </>
      )}
    </>
  );
};
function updateItemInfo(
  element1: AggregateTemplateValueType,
  item: DataItemDimensionIdentifierInterface,
  defaultInterval: ExtendedIntervalOptions
) {
  if (!item) return null;
  const element = element1 || {
    action: "historical",
    params: {
      source: item[0],
      id: item[1],
    },
  };
  if (element.action === "historical") {
    return {
      action: "historical",
      params: {
        source: item[0],
        id: item[1],
      },
    } as HistoricalFetchAggregation;
  } else if (element.action === "source_aggregation") {
    return {
      action: "source_aggregation",
      params: {
        source: item[0],
        id: item[1],
        operation: (element as SourceAggregation).params.operation || "sum",
        dimension: item[2],
        timeinterval:
          (element as SourceAggregation).params.timeinterval || defaultInterval,
      },
    } as SourceAggregation;
  } else if (element.action === "diffFirstLast") {
    return {
      action: "diffFirstLast",
      params: {
        source: item[0],
        id: item[1],
        dimension: item[2],
        timeinterval:
          (element as DiffFirstLastAggregation).params.timeinterval ||
          defaultInterval,
      },
    } as DiffFirstLastAggregation;
  } else {
    return {
      action: "live",
      params: {
        source: item[0],
        id: item[1],
        amount: (element as LiveFetchAggregation).params.amount || 1,
        useStart: (element as LiveFetchAggregation).params.useStart || false,
        unit: (element as LiveFetchAggregation).params.unit || "day",
      },
    } as LiveFetchAggregation;
  }
}
function getItem(element: AggregateTemplateValueType) {
  console.log(element, "element");
  if (!element) return null;
  return [
    element.params.source,
    element.params.id,

    element.action === "historical"
      ? 0
      : (element as SourceAggregation | DiffFirstLastAggregation).params
          .dimension,
  ] as DataItemDimensionIdentifierInterface;
}

export default AggregateTemplatePicker;
