export default {
  everyText: "Jede(s/n)",
  emptyMonths: "Jeden Monat",
  emptyMonthDays: "Jeden Tag im Monat",
  emptyMonthDaysShort: "Tag in Monat",
  emptyWeekDays: "Jeden Tag in der Woche",
  emptyWeekDaysShort: "Tag in der Woche",
  emptyHours: "Jede Stunde",
  emptyMinutes: "Jede Minute",
  emptyMinutesForHourPeriod: "Jede Minute in Stunde",
  yearOption: "Jahr",
  monthOption: "Monat",
  weekOption: "Woche",
  dayOption: "Tag",
  hourOption: "Stunde",
  minuteOption: "Minute",
  rebootOption: "Zurücksetzen",
  prefixPeriod: "Betrachteter Zeitraum",
  prefixMonths: "Monate",
  prefixMonthDays: "Tage im Monat",
  prefixWeekDays: "Tage in der Woche",
  prefixWeekDaysForMonthAndYearPeriod: "Tage in Woche und Jahr",
  prefixHours: "Stunden",
  prefixMinutes: "Minuten",
  prefixMinutesForHourPeriod: "Minuten in Stunde",
  suffixMinutesForHourPeriod: "",
  errorInvalidCron: "Angabe ungültig",
  weekDays: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoche",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],
  months: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  altWeekDays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  altMonths: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ].map((month) => month.substring(0, 3)),
};
