import { makeAutoObservable } from "mobx";
import { NotificationState } from "..";

export class StateProvider {
  private static state: NotificationState = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public static getState(): NotificationState {
    if (typeof this.state === "undefined") {
      this.state = new NotificationState();
    }
    return this.state;
  }
}
