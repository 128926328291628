import { $framework, BaseAdapterInterface, equals } from "..";

import { Store } from "./Store";

type SubscriptionCallback = () => void;

interface StoreCacheHandler {
  get(key: string): Promise<any>;
  set(key: string, value: any): Promise<void>;
}

interface BaseServiceConfig<T> {
  initialState: T;

  cacheHandler?: StoreCacheHandler;
  cacheKey?: string;
  cacheAllowlist?: string[];
  cacheBlocklist?: string[];
}

export class BaseService<T = any> {
  private enabled: boolean = false;
  private loading: boolean = true;

  private subscribers = new Set<SubscriptionCallback>();

  public store: Store<T>;

  constructor(config: BaseServiceConfig<T>) {
    this.store = new Store(config.initialState, {
      cacheHandler: config.cacheHandler,
      cacheKey: config.cacheKey,
      cacheAllowlist: config.cacheAllowlist,
      cacheBlocklist: config.cacheBlocklist,
    });
  }

  public isEnabled() {
    return this.enabled;
  }

  public isLoading() {
    return this.loading;
  }

  public wait(): Promise<void> {
    if (!this.loading) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const unsubscribe = this.subscribe(() => {
        if (!this.loading) {
          resolve();
          unsubscribe();
        }
      });
    });
  }

  public subscribe(callback: SubscriptionCallback): () => void {
    this.subscribers.add(callback);

    return () => {
      this.subscribers.delete(callback);
    };
  }

  public setLoading(value: boolean) {
    this.loading = value;
    this.notifySubscribers();
  }

  public notifySubscribers(): void {
    this.subscribers.forEach((callback) => {
      try {
        callback();
      } catch (error) {
        console.error("Error in service subscription:", error);
      }
    });
  }

  public clearSubscribers(): void {
    this.subscribers.clear();
  }

  protected initAdapter(
    adapter: BaseAdapterInterface,
    context: any,
    errorHandler: (error: Error) => void = (error) => console.error(error)
  ) {
    if (!adapter) {
      return;
    }

    if (typeof adapter !== "object") {
      console.error("Bad type for service adapter", adapter);
      return;
    }

    this.enabled = true;

    if (adapter.onContext) {
      Promise.resolve(adapter.onContext(context)).catch(errorHandler);
    }

    if (adapter.onUser) {
      let current = undefined;

      const updateUserOnChange = () => {
        if (!$framework.services.UserService.isLoading()) {
          if (!equals(current, $framework.services.UserService.currentUser())) {
            current = $framework.services.UserService.currentUser();
            Promise.resolve(adapter.onUser(current)).catch(errorHandler);
          }
        }
      };

      updateUserOnChange();

      $framework.services.UserService.subscribe(() => {
        updateUserOnChange();
      });
    }

    if (adapter.onSource) {
      let current = undefined;
      let descendentsIds: string[] = [];

      const updateSourceOnChange = () => {
        if (!$framework.services.SourceService.isLoading()) {
          const localCurrent = $framework.services.SourceService.getCurrent();
          const localDescendents =
            $framework.services.SourceService.getDescendents(localCurrent);
          const localDescendentsIds = localDescendents.map(
            (source) => source.id
          );

          if (
            !equals(current, localCurrent) ||
            !equals(descendentsIds, localDescendentsIds)
          ) {
            current = localCurrent;
            descendentsIds = localDescendentsIds;
            Promise.resolve(
              adapter.onSource(localCurrent, localDescendents)
            ).catch(errorHandler);
          }
        }
      };

      updateSourceOnChange();

      $framework.services.SourceService.subscribe(() => {
        updateSourceOnChange();
      });
    }
  }
}
