import { GeoAdapterInterface } from "@opendash/plugin-geo";
import * as Parse from "parse";

export class ParseGeoAdapter implements GeoAdapterInterface {
  public async autocomplete(query: string, limit: number) {
    const result = await Parse.Cloud.run("openinc-geo-google-autocomplete", {
      query,
      limit,
      latitude: 0,
      longitude: 0,
    });

    return result;
  }

  public async geocode() {
    const result = await Parse.Cloud.run("openinc-geo-graphhopper-geocode");

    return result;
  }

  public async geocodeReverse() {
    const result = await Parse.Cloud.run(
      "openinc-geo-graphhopper-geocode-reverse"
    );

    return result;
  }
}
