import * as React from "react";

import { AppPortal, useTranslation } from "..";

import { Icon } from "@opendash/icons";
import { HeaderMenuItem as Menu } from "@opendash/ui";
import { Badge, Tooltip } from "antd";

interface Props {
  active?: boolean;
  icon: string;
  tooltip: string;
  onClick(): void;
}

export const HeaderMenuItem = React.memo<Props>(function HeaderMenuItem({
  active,
  icon,
  tooltip,
  onClick,
}) {
  const t = useTranslation();

  return (
    <AppPortal place="headerBeforeMenuRight">
      <Tooltip title={tooltip}>
        <Menu
          onClick={onClick}
          style={{
            backgroundColor: active ? "#eee" : undefined,
          }}
        >
          <Badge dot={active}>
            <Icon
              icon={icon}
              style={{
                color: active ? "var(--ant-primary-color)" : undefined,
              }}
            />
          </Badge>
        </Menu>
      </Tooltip>
    </AppPortal>
  );
});
