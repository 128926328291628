import { Icon } from "@opendash/icons";
import { Menu } from "antd";
import { MenuProps } from "antd/lib/menu";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { $framework, useNavigation, useTranslation } from "..";

interface Props extends MenuProps {}

export const NavigationMenu = observer<Props>(function NavigationMenu(props) {
  const t = useTranslation();
  const [groups, items, activeItems] = useNavigation("sidebar");

  return (
    <Menu
      mode="inline"
      selectedKeys={activeItems}
      defaultOpenKeys={groups.map((group) => group.id)}
      {...props}
      items={items
        .filter((item) => item.group === null)
        .map((items) => {
          return {
            key: items.id,
            label: !items.icon ? (
              t(items.label)
            ) : (
              <span>
                {items.icon && <Icon icon={items.icon} />} {t(items.label)}
              </span>
            ),
            onClick: () => {
              if (items.link && typeof items.link === "string") {
                $framework.router.navigate(items.link as string);
              } else {
                (items.link as () => void)();
              }
            },
          };
        })
        .concat(
          groups.map((group) => {
            return {
              key: group.id,
              label: !group.icon ? (
                t(group.label)
              ) : (
                <span>
                  {group.icon && <Icon icon={group.icon} />} {t(group.label)}
                </span>
              ),
              onClick: () => {},
              children: group.children.map((route) => {
                return {
                  key: route.id,
                  label: t(route.label),
                  icon: route.icon ? <Icon icon={route.icon} /> : undefined,
                  onClick: () => {
                    if (route.link && typeof route.link === "string") {
                      $framework.router.navigate(route.link as string);
                    } else {
                      (route.link as () => void)();
                    }
                  },
                };
              }),
            };
          })
        )}
    />
  );
});
