import { createComponent } from "@opendash/state";
import { Spin } from "antd";
import * as React from "react";
import styled from "styled-components";
import { $parse } from "../..";

type Props = React.PropsWithChildren<{
  width?: number;
}>;

export const AuthLayout = createComponent<Props>(function AuthLayout({
  children,
  width = 600,
}) {
  const currentTenant = $parse.auth.currentTenant;
  return (
    <Container>
      <Content width={width}>
        {currentTenant?.logo && (
          <img src={currentTenant?.logo} alt={currentTenant?.label} />
        )}

        {$parse.auth.loading ? <Spin /> : <Box width={width}>{children}</Box>}
      </Content>
      <Container />
    </Container>
  );
});

export const Container = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  height: 100%;
  width: 100%;
  padding: 10px;
`;

export const Content = styled.div<{ width: number }>`
  width: 100%;
  max-width: ${(p) => p.width}px;
  // padding: 24px 24px;

  z-index: 2;

  img {
    width: auto;
    max-width: 60%;
    display: block;
    margin: 0 auto;
    margin-bottom: 24px;
  }
`;

export const Box = styled.div<{ width: number }>`
  padding: 24px 24px;
  background: white;
  border-bottom: 3px solid var(--ant-primary-color);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 5px;
`;

export const SwitchState = styled.div`
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
`;

export const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  height: 100vh;
  width: 100vw;

  /* background: black; */

  z-index: 1;

  ::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    height: 100vh;
    width: 100vw;

    opacity: 0.5;
    filter: blur(5px);
    transform: scale(1.1);

    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
`;
