import { createInternalComponent } from "@opendash/core";
import { Spin } from "antd";
import * as React from "react";
import {
  WidgetContext,
  WidgetContextInterface,
  useWidgetContextSetup,
} from "..";

export const WidgetComponentRender = createInternalComponent<{
  baseContext: WidgetContext;
  context?: WidgetContextInterface;
}>(function WidgetComponentRender({ baseContext, context: injectedContext }) {
  const internalContext = useWidgetContextSetup(baseContext);
  const context = injectedContext || internalContext;

  const DisplayComponent = React.useMemo(
    () =>
      baseContext?.type?.displayComponent
        ? baseContext?.type.displayComponent
        : null,
    [baseContext?.type?.displayComponent]
  );

  const [state, setState] = React.useState<Object>(
    context.context.containerRef.current
  );
  React.useEffect(() => {
    if (!!context.context.containerRef.current) {
      setState(context.context.containerRef.current);
    }
  }, [context]);

  if (!baseContext?.widget) {
    console.warn(`WidgetSettingsModal: widget "${baseContext?.id}" not found.`);
    return null;
  }

  if (baseContext?.widget && !baseContext?.type) {
    console.warn(
      `WidgetComponent: type "${baseContext?.widget.type}" not found.`
    );
    return null;
  }

  if (baseContext?.store.getState().settings) {
    return null;
  }

  return (
    <>
      {!!state && <DisplayComponent {...context} />}
      {!state && <Spin></Spin>}
    </>
  );
});
