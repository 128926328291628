import { useTranslation } from "@opendash/core";
import { Input } from "antd";
import React from "react";

export interface StringPasswordDisplayProps {
  value: string;
  isInline: boolean;
}

export const StringPasswordDisplay = React.memo<StringPasswordDisplayProps>(
  function StringPasswordDisplay({ value, isInline }) {
    return <span>****</span>;
  }
);

export interface StringPasswordEditProps {
  value: string;
  setValue?: (v: string) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const StringPasswordEdit = React.memo<StringPasswordEditProps>(
  function StringPasswordEdit({ value, setValue }) {
    const t = useTranslation();

    return (
      <Input.Password
        placeholder={t("parse-admin:admin.inputs.password_placeholder")}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  }
);
