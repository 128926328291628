import { createInternalComponent, useTranslation } from "@opendash/core";
import { Modal } from "antd";
import * as React from "react";
import { useDashboard, useMonitoringService } from "..";

interface Props {
  id: string;
  open: boolean;
  close: () => void;
}

export const DashboardDeletionModal = createInternalComponent<Props>(
  function DashboardDeletionModal({ id, open, close }) {
    const t = useTranslation();
    const MonitoringService = useMonitoringService();
    const dashboard = useDashboard(id);

    if (!dashboard) {
      // console.warn(`DashboardSettingsModal: dashboard "${id}" not found.`);
      return null;
    }

    return (
      <Modal
        open={open}
        title={t("opendash:dashboards.delete_modal_title")}
        okText={t("opendash:ui.delete")}
        onOk={() => {
          close();
          MonitoringService.deleteDashboard(dashboard);
        }}
        cancelText={t("opendash:ui.cancel")}
        onCancel={(e) => close()}
        okButtonProps={{ danger: true }}
      >
        {t("opendash:dashboards.delete_modal_description")}
      </Modal>
    );
  }
);
