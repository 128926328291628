import { HelperService } from "./HelperService";
import { LogService } from "./LogService";
import { ParseAdminService } from "./ParseAdminService";
import { ParseAuthService } from "./ParseAuthService";
import { ParseServerService } from "./ParseServerService";
import { ParseUserService } from "./ParseUserService";

export class ParseService {
  public server = new ParseServerService(this);
  public ui = new ParseAdminService(this);
  public helper = new HelperService();
  public log = new LogService();
  public user = new ParseUserService();
  public auth = new ParseAuthService(this);
}
