import * as React from "react";
import { Loading } from "..";

interface Props {
  children: React.ReactNode;
}

export const Suspense: React.ElementType<Props> = ({ children }) => {
  return (
    <React.Suspense fallback={<Loading message="" />} children={children} />
  );
};
