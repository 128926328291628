import {
  $framework,
  SourceInterface,
  createInternalComponent,
  useOpenDashServices,
  useSource,
} from "@opendash/core";
import { useNavigate, useParams } from "@opendash/router";
import * as React from "react";
import {
  DashboardRoute,
  useDashboardCurrent,
  useDashboards,
  useMonitoringService,
} from "..";

interface Props {}

export const DashboardByIDRoute = createInternalComponent<Props>(
  function DashboardByIDRoute({}) {
    const MonitoringService = useMonitoringService();
    const navigate = useNavigate();
    const { id, source: sourceid } = useParams();
    const [source, setSource] = useSource();
    //const dashboards = useDashboardsBySource();
    const dashboards = useDashboards();
    const [dashboard, setDashboard] = useDashboardCurrent();
    const allDashboards = useDashboards();
    const SourceService = useOpenDashServices().SourceService;

    const timeout = React.useRef(null);
    React.useEffect(() => {}, [source]);
    const handleSourceEvent = (newSource: SourceInterface) => {
      let newDashboard = dashboards.find(
        (objekt) => objekt.source === newSource.id
      );

      MonitoringService.setCurrentDashboard(newDashboard);
      navigate(
        "/monitoring/dashboards/" + newSource.id + "/" + newDashboard?.id ||
          undefined,
        {
          replace: true,
        }
      );
      /*
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(async () => {
        // navigate("/monitoring/dashboards/", {
        //   replace: true,
        /*
        const cSource = await SourceService.getCurrent();
        const url = "" + sourceid;
        const store = cSource.id;
        console.log({ url, store });
        if (url !== store) {
          if (!url) {
            if (dashboard) {
              navigate(
                "/monitoring/dashboards/" +
                  dashboard.source +
                  "/" +
                  dashboard.id,
                { replace: true }
              );
            }
          } else {
            navigate("/monitoring/dashboards/" + url + "/" + id, {
              replace: true,
            });
          }
        }
      }, 10);*/
    };

    React.useEffect(() => {
      const unsubscribe = $framework.state.subscribeSelection(
        (state) => state.sources.current,
        (sourceEvent) => {
          handleSourceEvent(sourceEvent);
        }
      );
      //const unsubscribe = SourceService.subscribeSelection();
      return () => {
        unsubscribe();
      };
    }, []);

    /*React.useEffect(() => {
      if (!sourceid) {
        setTimeout(() => {
          let db = dashboard;
          if (!db) {
            db = dashboards.find((d) => {
              return d.source === source.id;
            });
          }

          navigate("/monitoring/dashboards/" + source.id + "/" + db.id, {
            replace: true,
          });
        }, 200);
      }
    }, []);*/

    React.useEffect(() => {
      let x;
      if (sourceid && id) {
        if (!source || sourceid !== source.id) {
          setSource(sourceid);
          if (id) {
            // @ts-ignore
            MonitoringService.setCurrentDashboard({ id });
          } else {
            // @ts-ignore
            MonitoringService.setCurrentDashboard(dashboards[0]);
          }
          navigate(
            "/monitoring/dashboards/" +
              sourceid +
              "/" +
              (id ? id : dashboards[0].id),
            { replace: true }
          );

          //return <Loading message={"...."}></Loading>;
        } else {
          if (id) {
            // @ts-ignore
            MonitoringService.setCurrentDashboard({ id });
            navigate("/monitoring/dashboards/" + sourceid + "/" + id, {
              replace: true,
            });
          } else {
            // @ts-ignore
            MonitoringService.setCurrentDashboard(dashboards[0]);
            navigate(
              "/monitoring/dashboards/" + sourceid + "/" + dashboards[0].id,
              {
                replace: true,
              }
            );
          }
        }

        x = setTimeout(() => {
          navigate("/monitoring/dashboards/" + sourceid + "/" + id, {
            replace: true,
          });
        }, 100);
        return () => {
          clearTimeout(x);
        };
      }
    }, [sourceid, id]);

    return <DashboardRoute />;
  }
);
