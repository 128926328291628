import React, { useState } from "react";

import { Modal, Input } from "antd";

import { useTranslation } from "@opendash/core";

export default function GreisTranspondersAdd({ open, close, addTransponders }) {
  const t = useTranslation();
  const [transponders, setTransponders] = useState([""]);

  const onChange = (e) => {
    const value = e.target.value;

    setTransponders(
      value.split("\n").map((v) => v.trim().replace(/[^a-zA-Z0-9]/g, ""))
    );
  };

  const save = () => {
    addTransponders(
      transponders.map((id) => ({
        id,
      }))
    );
    clearAndClose();
  };

  const clearAndClose = () => {
    setTransponders([""]);
    close();
  };

  return (
    <Modal
      title={t("greis:transponders.admin.add")}
      visible={open}
      onOk={(e) => save()}
      onCancel={(e) => clearAndClose()}
    >
      <p>{t("greis:transponders.admin.add_description")}</p>
      <Input.TextArea
        value={transponders.join("\n")}
        onChange={onChange}
        placeholder={t("greis:transponders.admin.add_input_placeholder")}
        autoSize={{ minRows: 2, maxRows: 12 }}
      />
    </Modal>
  );
}
