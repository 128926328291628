import * as Parse from "parse";

import { makeAutoObservable } from "@opendash/state";

import { $parse } from ".";
import {
  AnyViewType,
  ParseClassConfig,
  ParseClassSchema,
  ParseSearchFilterType,
  ParseTableFilterType,
  ParseTableSortOrderType,
} from "../types";
import { ParseQueryState } from "./ParseQueryState";

export class ParseFilterState {
  persistInUrl: boolean = false;

  className: string;
  view: AnyViewType;

  page: number = 1;
  defaultPageSize: number = 1000;
  pageSize: number = 20;
  sortKey: string = undefined;
  sortOrder: ParseTableSortOrderType = undefined;
  filter: ParseTableFilterType = {};
  search: ParseSearchFilterType = {};
  customQuery: (query: Parse.Query) => void = undefined;
  selection: string[] = [];

  schema: ParseClassSchema;
  config: ParseClassConfig;

  constructor(
    className: string,
    view: AnyViewType,
    persistInUrl: boolean = false
  ) {
    makeAutoObservable(this);

    this.className = className;

    this.config = $parse.ui.getClassConfig(className);
    this.schema = $parse.ui.getClassSchema(className);
    this.customQuery = this.config?.customFilter;
    if (this.config?.orderField) {
      this.sortKey = this.config.orderField;
      this.sortOrder = "ascend";
    }

    this.view = view;

    this.persistInUrl = !!persistInUrl;
  }

  public setSelection(value: string[]): void {
    this.selection = value;
  }

  public setPage(value: number): void {
    this.page = value;
  }

  public setPageSize(value: number): void {
    this.pageSize = value;
  }

  public setSortKey(value: string): void {
    this.sortKey = value;
  }

  public setCustomQuery(value: (query: Parse.Query) => void): void {
    this.customQuery = value;
  }

  public setSortOrder(value: ParseTableSortOrderType): void {
    this.sortOrder = value;
  }

  /**
   * Set the filter for the query.
   * value should look like this: { field: ["equals", "value"] }
   *
   * @param value The value to filter for.
   */
  public setFilter(value: ParseTableFilterType): void {
    for (const key of Object.keys(value)) {
      if (!Array.isArray(value[key]) || value[key].length === 0) {
        delete value[key];
      }
    }

    this.filter = value;
  }

  /**
   * Set the search for the query.
   * value should look like this: { field: "value" }
   *
   * @param value The value to search for.
   */
  public setSearch(value: ParseSearchFilterType): void {
    this.search = value;
  }

  get query(): Parse.Query {
    const config = $parse.ui.getClassConfig(this.className);
    const schema = $parse.ui.getClassSchema(this.className);
    let q = new Parse.Query(this.className);

    q.limit(this.pageSize);

    if (this.page) {
      q.skip((this.page - 1) * this.pageSize);
    }

    if (this.sortKey && this.sortOrder === "ascend") {
      q.ascending(this.sortKey);
    }

    if (this.sortKey && this.sortOrder === "descend") {
      q.descending(this.sortKey);
    }

    if (this.view.type === "table") {
      const fields = this.view.fields || config.displayFields || [];

      for (const field of fields) {
        const fieldSchema = schema?.fields[field];

        if (
          fieldSchema &&
          fieldSchema?.type === "Pointer" &&
          fieldSchema?.targetClass
        ) {
          q.include(field);
        }
      }
    }

    if (this.view.type === "canban") {
      const fields = [this.view.groupByField];

      for (const field of fields) {
        const fieldSchema = schema?.fields[field];

        if (
          fieldSchema &&
          fieldSchema?.type === "Pointer" &&
          fieldSchema?.targetClass
        ) {
          q.include(field);
        }
      }

      q.limit(999999);
    }

    if (this.filter) {
      Object.entries(this.filter).forEach(
        ([field, [filterType, filterValue]]) => {
          if (filterType === "equals" && filterValue) {
            q.equalTo(field, filterValue);
          }

          if (filterType === "equals_not" && filterValue) {
            q.notEqualTo(field, filterValue);
          }

          if (filterType === "starts_with" && filterValue) {
            q.startsWith(field, filterValue as string);
          }

          if (filterType === "ends_with" && filterValue) {
            q.endsWith(field, filterValue as string);
          }

          if (filterType === "contains" && filterValue) {
            q.contains(field, filterValue as string);
          }

          if (filterType === "exists") {
            q.exists(field);
          }

          if (filterType === "exists_not") {
            q.doesNotExist(field);
          }

          if (filterType === "equals_true") {
            q.equalTo(field, true);
          }

          if (filterType === "equals_false") {
            q.equalTo(field, false);
          }
        }
      );
    }

    if (this.search) {
      const searchqueries = [];

      Object.entries(this.search).forEach(([field, value]) => {
        const querymatches = new Parse.Query(q.className).matches(
          field,
          new RegExp(value.replaceAll(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i")
        );

        searchqueries.push(querymatches);
      });

      if (searchqueries.length !== 0) {
        q = Parse.Query.and(q, Parse.Query.or(...searchqueries));
      }
    }

    if (this.customQuery) {
      this.customQuery(q);
    }
    return q;
  }

  get data() {
    return new ParseQueryState(this.query, {
      count: true,
    });
  }

  get paginationConfig() {
    return {
      current: this.page,
      pageSize: this.pageSize,
      defaultPageSize: this.defaultPageSize,
      total: this.data.count,
      showSizeChanger: true,
      pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000],
      onChange: (page: number, pageSize: number) => {
        this.setPage(page);
        this.setPageSize(pageSize);
      },
    };
  }

  get selectionConfig() {
    return {
      selectedRowKeys: this.selection,
      onChange: (selection: string[]) => {
        this.setSelection(selection);
      },
    };
  }
}
