import { useTranslation } from "@opendash/core";
import { Button } from "antd";
import React from "react";
import { $parse } from "../../state";

export interface RelationDisplayProps {
  object: Parse.Object;
  field: string;
}

export const RelationDisplay = React.memo<RelationDisplayProps>(
  function RelationDisplay({ object, field }) {
    const t = useTranslation();

    return (
      <>
        <Button
          size="small"
          children={t("parse-admin:admin.relations.show_relation_label")}
          style={{ width: "100%" }}
          onClick={() => $parse.ui.openRelationDialogForObject(object, field)}
        />
      </>
    );
  }
);

export interface RelationEditProps {
  object: Parse.Object;
  field: string;
  isRequired: boolean;
  isNullable: boolean;
}

export const RelationEdit = React.memo<RelationEditProps>(
  function RelationEdit({ object, field }) {
    const t = useTranslation();

    return (
      <Button
        size="small"
        children={t("parse-admin:admin.relations.show_relation_label")}
        style={{ width: "100%" }}
        onClick={() => $parse.ui.openRelationDialogForObject(object, field)}
      />
    );
  }
);
