import { translate } from "@opendash/i18n";
import {
  $framework,
  AuthError,
  AuthPayloadInterface,
  RoleInterface,
  UserAdapterInterface,
  UserInterface,
  urlBase64ToUint8Array,
} from "..";

import { BaseService } from "../classes/BaseService";
import type { FrameworkService } from "./FrameworkService";
import { UserAdapterContext } from "./UserAdapterContext";

interface StateInterface {
  currentUser: UserInterface | null;
  offline: boolean;
  validated: boolean;
  config: Record<string, string>;
  permissions: string[];

  users: UserInterface[];
  roles: RoleInterface[];
}

export class UserService extends BaseService<StateInterface> {
  private framework: FrameworkService;
  private adapter: UserAdapterInterface;
  private context: UserAdapterContext;

  constructor(framework: FrameworkService) {
    super({
      initialState: {
        currentUser: null,
        offline: false,
        validated: false,
        config: {},
        permissions: [],

        users: [],
        roles: [],
      },
    });

    this.framework = framework;
    this.context = new UserAdapterContext(this, framework);
  }

  public async init(adapter: UserAdapterInterface) {
    this.adapter = adapter;
    this.initAdapter(adapter, this.context);
  }

  currentUser(): UserInterface {
    return this.store.select((state) => state.currentUser);
  }

  isLoggedIn(): boolean {
    return !!this.currentUser();
  }

  async login(payload: AuthPayloadInterface): Promise<void> {
    try {
      try {
        const message = {
          id: 1,
          user: payload.username,
          pass: payload.password,
        };
        //@ts-ignore
        window.webkit.messageHandlers.userlogging.postMessage(message);
      } catch (error) {
        console.log("No Mobile Browser detected...");
      }

      await this.adapter.login(payload);

      window.location.reload();
    } catch (error) {
      this.handleError(error);
    }
  }

  async register(payload: AuthPayloadInterface): Promise<void> {
    try {
      await this.adapter.register(payload);
    } catch (error) {
      this.handleError(error);
    }
  }

  async logout(): Promise<void> {
    try {
      await this.adapter.logout();

      window.location.href = "/auth/login";
    } catch (error) {
      this.handleError(error);
    }
  }

  async changePassword(user: object): Promise<void> {
    try {
      console.log("haha");
      await this.adapter.changePassword(user);
    } catch (error) {
      this.handleError(error);
    }
  }

  getConfig(key: string): string {
    return this.store.select((state) => state.config[key]) || "";
  }

  getConfigBoolean(key: string): boolean {
    const config = this.getConfig(key);

    if (!config || config.toLowerCase() === "true" || config === "0") {
      return false;
    }

    return true;
  }

  hasPermission(key: string): boolean {
    return this.store.select((state) => state.permissions.includes(key));
  }

  async registerWebPush() {
    if (!this.adapter.handlePushSubscription) {
      throw new Error("Not supported by UserAdapter");
    }

    const notificationPermission = await Notification.requestPermission();

    if (notificationPermission !== "granted") {
      throw new Error("Permission not granted");
    }

    const registration = await navigator.serviceWorker.ready;

    const existingSubscription =
      await registration.pushManager.getSubscription();

    if (existingSubscription) {
      await this.handlePushSubscription("web", existingSubscription.toJSON());
    } else {
      if (existingSubscription) {
        await existingSubscription.unsubscribe();
      }
      const vapidPublicKey = this.getConfig("WEB_PUSH_VAPID_PUBLIC_KEY");
      const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey as string);

      const newSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      });

      await this.handlePushSubscription("web", newSubscription.toJSON());
    }
  }

  public async handlePushSubscription(type: string, data: any): Promise<void> {
    if (!this.adapter.handlePushSubscription) {
      throw new Error("Not supported by UserAdapter");
    }

    const previousIdentifier =
      await this.framework.services.DeviceStorageService.get<string>(
        "PushIdentifier"
      );

    const nextIdentifier = await this.adapter.handlePushSubscription(
      previousIdentifier,
      type,
      data
    );

    await this.framework.services.DeviceStorageService.set<string>(
      "PushIdentifier",
      nextIdentifier
    );
  }

  private async handleError(error: Error) {
    if (error instanceof AuthError || "type" in error) {
      const authError = error as AuthError;

      switch (authError.type) {
        case "LOGIN_BAD_PASSWORD":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.LOGIN_BAD_PASSWORD")
          );
          break;
        case "LOGIN_BAD_USER":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.LOGIN_BAD_USER")
          );
          break;
        case "LOGIN_BAD_USER_PASSWORD":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.LOGIN_BAD_USER_PASSWORD")
          );
          break;
        case "LOGIN_MISSING_EMAIL_VERIFICATION":
          $framework.antd_msg.error(
            await translate(
              "opendash:auth.error.LOGIN_MISSING_EMAIL_VERIFICATION"
            )
          );
          break;
        case "LOGIN_MISSING_VERIFICATION":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.LOGIN_MISSING_VERIFICATION")
          );
          break;
        case "LOGIN_BANNED":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.LOGIN_BANNED")
          );
          break;
        case "LOGIN_UNKNOWN_ERROR":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.LOGIN_UNKNOWN_ERROR")
          );
          break;
        case "SIGNUP_DUPLICATE_USERNAME":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.SIGNUP_DUPLICATE_USERNAME")
          );
          break;
        case "SIGNUP_DUPLICATE_EMAIL":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.SIGNUP_DUPLICATE_EMAIL")
          );
          break;
        case "SIGNUP_VALIDATION_ERROR":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.SIGNUP_VALIDATION_ERROR")
          );
          break;
        case "SIGNUP_UNKNOWN_ERROR":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.SIGNUP_UNKNOWN_ERROR")
          );
          break;
        case "SESSION_EXPIRED":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.SESSION_EXPIRED")
          );
          break;
        case "SESSION_UNKNOWN_ERROR":
          $framework.antd_msg.error(
            await translate("opendash:auth.error.SESSION_UNKNOWN_ERROR")
          );
          break;
        case "UNKNOWN_ERROR":
        default:
          $framework.antd_msg.error(
            await translate("opendash:auth.error.UNKNOWN")
          );
          break;
      }
    } else {
      console.error(error);
      $framework.antd_msg.error(await translate("opendash:auth.error.UNKNOWN"));
    }
  }
}
