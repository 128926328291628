import { $framework } from "@opendash/core";
import { makeAutoObservable, runInAction } from "@opendash/state";
import Parse from "parse";
import {
  runAuthChangePassword,
  runAuthEmailVerification,
  runAuthEmailVerificationRequest,
  runAuthLogin,
  runAuthLoginAdmin,
  runAuthLoginPasswordless,
  runAuthPasswordReset,
  runAuthPasswordResetRequest,
  runAuthSignup,
  runAuthValidateToken,
} from "../cloud-functions";
import { getErrorMessage } from "../helper/getErrorMessage";
import type { ParseService } from "./ParseService";

export class ParseAuthService {
  private parse: ParseService;

  public loading: boolean = true;
  public authentificated: boolean = false;
  public simulation: boolean = !!window.localStorage.getItem(
    "$parse.auth.simulation"
  );

  public loginDefaults = {
    login: "",
    password: "",
  };

  public signupDefaults = {
    tenant: null,
    username: "",
    name: "",
    email: "",
    password: "",
  };

  constructor(parse: ParseService) {
    this.parse = parse;

    makeAutoObservable(this);
  }

  get currentTenant() {
    return this.parse.server.tenants.find(
      (t) => t.key === this.signupDefaults.tenant
    );
  }

  public async init() {
    await this.parse.server.lock.wait();

    this.parseUrl();

    runInAction(() => {
      this.loading = false;
    });
  }

  public getTenantOptions() {
    const tenants = [...this.parse.server.tenants].map((x) => ({
      label: x.label,
      value: x.value,
    }));

    return tenants;
  }

  public async login(data: { login: string; password: string }) {
    try {
      const { sessionToken } = await runAuthLogin(data);

      await Parse.User.become(sessionToken);

      await this.parse.server.init();

      $framework.ui2.message(
        "success",
        "parse-admin:auth.feedback_login_success"
      );

      if ($framework.router.searchParams.url) {
        const url = decodeURIComponent($framework.router.searchParams.url);

        $framework.router.navigate(url);
      } else {
        $framework.router.navigate(`/`);
      }
    } catch (error) {
      this.handleError(error, "login");
    }
  }

  public async loginAlt(login: string, password: string) {
    return await this.login({ login, password });
  }

  public async simulateUser(data: {
    userId?: string;
    email?: string;
    username?: string;
  }) {
    try {
      const { sessionToken } = await runAuthLoginAdmin(data);

      window.localStorage.setItem(
        "$parse.auth.simulation",
        JSON.stringify({
          name: this.parse.user.displayName(),
          sessionToken: this.parse.user.sessionToken(),
        })
      );

      await Parse.User.become(sessionToken);

      await this.parse.server.init();

      runInAction(() => {
        this.simulation = true;
      });

      $framework.ui2.message(
        "success",
        "parse-admin:auth.feedback_login_success"
      );

      $framework.router.navigate(`/`);
    } catch (error) {
      this.handleError(error, "login");
    }
  }

  public async simulateStop() {
    try {
      const simJson = window.localStorage.getItem("$parse.auth.simulation");

      window.localStorage.removeItem("$parse.auth.simulation");

      runInAction(() => {
        this.simulation = false;
      });

      if (!simJson) {
        return void $framework.ui2.message(
          "error",
          "parse-admin:auth.feedback_login_error"
        );
      }

      const sim = JSON.parse(simJson) as { name: string; sessionToken: string };

      await Parse.User.become(sim.sessionToken);
      await this.parse.server.init();

      $framework.ui2.message(
        "success",
        "parse-admin:auth.feedback_login_success"
      );

      $framework.router.navigate(`/`);
    } catch (error) {
      this.handleError(error, "login");
    }
  }

  public async loginPasswordless(data: { email: string }) {
    try {
      await runAuthLoginPasswordless({
        ...data,
        url: window.location.origin + "?session={{token}}",
      });

      $framework.ui2.message("success", "parse-admin:auth.feedback_email_sent");
      $framework.router.navigate(`/auth/account/login-passwordless-success`);
    } catch (error) {
      this.handleError(error, "login");
    }
  }

  public async signup(data: {
    email: string;
    password: string;
    name: string;
    tenant?: string;
    username?: string;
    tosAccepted: boolean;
  }) {
    try {
      const { success, sessionToken } = await runAuthSignup(data);

      if (success !== true) {
        throw new Parse.Error(
          Parse.Error.INTERNAL_SERVER_ERROR,
          "Unexpected response."
        );
      }

      if (sessionToken) {
        await Parse.User.become(sessionToken);
        await this.parse.server.init();
      }

      $framework.ui2.message(
        "success",
        "parse-admin:auth.feedback_signup_success"
      );

      if (this.parse.server.config.AUTH_VERIFY_EMAIL) {
        await this.requestEmailVerification({ email: data.email }, true);
      }

      $framework.router.navigate(`/auth/account/signup-success`);
    } catch (error) {
      this.handleError(error, "signup");
    }
  }

  public async logout() {
    try {
      await Parse.User.logOut();
      await this.parse.server.init();
    } catch (error) {
      this.handleError(error, "other");
      console.error(error);
    } finally {
      window.localStorage.clear();
      window.location.reload();
    }
  }

  public async validateToken(type: "verify-email" | "password-reset") {
    try {
      const response = await runAuthValidateToken({
        token: $framework.router.searchParams.token,
      });

      if (!response.valid) {
        switch (type) {
          case "verify-email":
            return $framework.router.navigate(
              `/auth/account/verify-email-invalid`
            );

          case "password-reset":
            return $framework.router.navigate(
              `/auth/account/password-reset-invalid`
            );
        }
      }
    } catch (error) {
      this.handleError(error, "other");
    }
  }

  public async requestEmailVerification(
    data: { email: string },
    silent?: boolean
  ) {
    try {
      await runAuthEmailVerificationRequest({
        ...data,
        url:
          window.location.origin.replace("capacitor", "https") +
          "/auth/account/verify-email?token={{token}}",
      });

      if (!silent) {
        $framework.ui2.message(
          "success",
          "parse-admin:auth.feedback_email_sent"
        );

        $framework.router.navigate(`/auth/account/verify-email-send-success`);
      }
    } catch (error) {
      this.handleError(error, "other");
    }
  }

  public async sendEmailVerification() {
    try {
      await runAuthEmailVerification({
        token: $framework.router.searchParams.token,
      });
      $framework.router.navigate(`/auth/account/verify-email-success`);
    } catch (error) {
      this.handleError(error, "other");
    }
  }

  public async requestPasswordReset(data: { email: string }) {
    try {
      await runAuthPasswordResetRequest({
        ...data,
        url:
          window.location.origin.replace("capacitor", "https") +
          "/auth/account/password-reset?token={{token}}",
      });
      $framework.ui2.message("success", "parse-admin:auth.feedback_email_sent");
      $framework.router.navigate(`/auth/account/password-reset-send-success`);
    } catch (error) {
      this.handleError(error, "other");
    }
  }

  public async sendPasswordReset(data: { password: string }) {
    try {
      await runAuthPasswordReset({
        ...data,
        token: $framework.router.searchParams.token,
      });
      $framework.router.navigate(`/auth/account/password-reset-success`);
    } catch (error) {
      this.handleError(error, "other");
    }
  }

  public async changePassword(data: { password: string }) {
    try {
      await runAuthChangePassword({
        password: data.password,
        userId: Parse.User.current().id,
      });
      $framework.ui2.message(
        "success",
        "parse-admin:auth.feedback_password_change_success"
      );

      setTimeout(() => {
        this.logout();
        window.location.reload();
      }, 2000);
    } catch (error) {
      this.handleError(error, "other");
    }
  }

  private handleError(error: unknown, type: "login" | "signup" | "other") {
    if (error instanceof Parse.Error) {
      $framework.ui2.message("error", getErrorMessage(error));
      // switch (error.code) {
      //   case Parse.Error.USERNAME_TAKEN:
      //     $framework.ui2.message(
      //       "error",
      //       "parse-admin:auth.feedback_username_taken"
      //     );
      //     break;

      //   case Parse.Error.EMAIL_TAKEN:
      //     $framework.ui2.message(
      //       "error",
      //       "parse-admin:auth.feedback_email_taken"
      //     );
      //     break;

      //   default:
      //     if (type === "login") {
      //       $framework.ui2.message(
      //         "error",
      //         "parse-admin:auth.feedback_login_error"
      //       );
      //     } else if (type === "signup") {
      //       $framework.ui2.message(
      //         "error",
      //         "parse-admin:auth.feedback_signup_error"
      //       );
      //     } else {
      //       $framework.ui2.message(
      //         "error",
      //         "parse-admin:auth.feedback_unknown_error"
      //       );
      //     }
      //     break;
      // }
    } else {
      $framework.ui2.message(
        "error",
        "parse-admin:auth.feedback_unknown_error"
      );
    }
  }

  private parseUrl() {
    if ($framework.router.pathname !== "/auth/signup") {
      return;
    }

    const url = new URL(window.location.href);

    const tenant = url.searchParams.get("tenant");

    if (tenant) {
      this.signupDefaults.tenant = tenant;

      // url.searchParams.delete("username");
    }

    const username = url.searchParams.get("username");

    if (username) {
      this.signupDefaults.username = username;
      this.loginDefaults.login = username;

      // url.searchParams.delete("username");
    }

    const email = url.searchParams.get("email");

    if (email) {
      this.signupDefaults.email = email;
      this.loginDefaults.login = email;

      // url.searchParams.delete("email");
    }

    const name = url.searchParams.get("name");

    if (name) {
      this.signupDefaults.name = name;

      // url.searchParams.delete("name");
    }

    if (username || email || name) {
      $framework.router.navigate(url, { replace: true });
    }
  }
}
