import { useServiceStore, uuid } from "@opendash/core";
import * as React from "react";
import { WidgetPresetInterface, useMonitoringService } from "..";

export function useWidgetPresets(): WidgetPresetInterface[] {
  const MonitoringService = useMonitoringService();

  const presets = useServiceStore(
    MonitoringService,
    (state) => state.allWidgetPresets
  );

  const typePresets: WidgetPresetInterface[] = React.useMemo(() => {
    return MonitoringService.types
      .map((widgetType) => {
        return widgetType.presets.map((preset) => {
          return {
            id: uuid(),
            isOwner: false,
            isShared: false,
            isReadOnly: true,
            layout: preset.layout || [4, 4],
            ...preset,
            widget: {
              ...preset.widget,
              type: widgetType.type,
            },
          };
        });
      })
      .flat();
  }, []);

  return React.useMemo(() => {
    return [...presets, ...typePresets];
  }, [typePresets, presets]);
}
