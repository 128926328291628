import * as React from "react";

import { Button, Divider } from "antd";
import styled from "styled-components";

const Container = styled.div<{ height: string }>`
  width: 100%;
  height: ${(p) => (p.height ? p.height : "100%")};

  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

const Icon = styled.div`
  font-size: 3em;
`;

const Type = styled.div`
  padding-top: 15px;
  font-weight: bold;
`;

const Message = styled.div`
  margin-bottom: 15px;
`;

interface ErrorMessageProps {
  icon: string | React.ReactNode;
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  actionLabel: string | React.ReactNode;
  actionClick: () => void;
  secondActionLabel?: string | React.ReactNode;
  secondActionClick?: () => void;
  height?: string;
}

export const ErrorMessage = React.forwardRef<HTMLDivElement, ErrorMessageProps>(
  (
    {
      icon,
      title,
      message,
      actionLabel,
      actionClick,
      secondActionLabel,
      secondActionClick,
      height,
    },
    ref
  ) => {
    return (
      <Container ref={ref} height={height}>
        <div>
          {icon && <Icon>{icon}</Icon>}
          {title && <Type>{title}</Type>}
          {message && <Message>{message}</Message>}
          <Button type="primary" onClick={actionClick}>
            {actionLabel}
          </Button>
          {secondActionLabel && (
            <>
              <Divider type="vertical" />
              <Button type="primary" onClick={secondActionClick}>
                {secondActionLabel}
              </Button>
            </>
          )}
        </div>
      </Container>
    );
  }
);
