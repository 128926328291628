import * as React from "react";
import { WidgetConfigInterface, WidgetContext, useMonitoringService } from "..";

export function useWidgetBaseContextDraftSetup(
  type: string,
  config: WidgetConfigInterface<any>,
  fullscreen: boolean = false
): WidgetContext {
  const MonitoringService = useMonitoringService();

  const context = React.useMemo(
    () => MonitoringService.createWidgetDraftContext(),
    []
  );

  React.useEffect(() => {
    context.setWidget({
      id: undefined,
      name: undefined,
      config: config,
      type,
    });
  }, [context, type]);

  React.useEffect(() => {
    context.replaceDraft(config);
  }, [context, config]);

  React.useEffect(() => {
    context.store.update((state) => {
      state.fullscreen = fullscreen;
    });
  }, [context, fullscreen]);

  return context;
}
