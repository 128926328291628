import { createInternalComponent } from "@opendash/core";
import * as React from "react";
import { WidgetContextInterface } from "..";

export const WidgetSettingsRenderWithoutSteps = createInternalComponent<{
  context: WidgetContextInterface;
}>(function WidgetSettingsRenderWithoutSteps({ context }) {
  const SettingsComponent = React.useMemo(
    () =>
      context.context?.type?.settingsComponent
        ? context.context.type.settingsComponent
        : null,
    [context.context?.type?.settingsComponent]
  );

  if (!SettingsComponent) {
    return null;
  }

  return <SettingsComponent {...context} />;
});
