import { useForceRender } from "@opendash/core";
import * as React from "react";
import { DataItemInterface, DataItemValueInterface, useDataService } from "..";

export function useDataItemValue(
  item: DataItemInterface,
  value?: DataItemValueInterface
): DataItemValueInterface {
  const DataService = useDataService();
  const forceRender = useForceRender();

  React.useEffect(() => {
    if (item && !value) {
      return DataService.subscribeValue(item, () => {
        forceRender();
      });
    }
  }, [item, value]);
  if (value) {
    return value;
  }
  if (!item) {
    return undefined;
  }

  return DataService._getValueOrThrowSync(item);
}
