import {
  $framework,
  AppInterface,
  BaseService,
  FrameworkService,
  equals,
} from "@opendash/core";
import { DataItemDimensionIdentifierInterface } from "@opendash/plugin-timeseries";
import produce from "immer";
import {
  AlarmActionInterface,
  AlarmInterface,
  AlarmWebhookInterface,
  DashboardInterface,
  MonitoringAdapterInterface,
  MonitoringPluginConfig,
  WidgetInterface,
  WidgetPresetInterface,
  WidgetTypeInterface,
} from "..";

import { MonitoringAdapterContext } from "./MonitoringAdapterContext";
import { WidgetContext } from "./states/WidgetContext";

type StateInterface = {
  currentDashboard: string | undefined;
  allDashboards: DashboardInterface[];
  allWidgets: WidgetInterface[];
  allWidgetPresets: WidgetPresetInterface[];

  copyDashboardId: string | undefined;
  loadingDashboard: boolean | false;
  alarms: AlarmInterface[];
  alarmWebhooks: AlarmWebhookInterface[];
  alarmActions: AlarmActionInterface[];
};

export class MonitoringService extends BaseService<StateInterface> {
  private app: AppInterface;
  private adapter: MonitoringAdapterInterface;
  private context: MonitoringAdapterContext;
  private framework: FrameworkService;
  private widgetTypes = new Set<WidgetTypeInterface<any>>();
  private widgetContextCache = new Map<string, WidgetContext>();

  public readonly triggerTypes = [
    "string_change",
    "string_equals",
    "string_equals_not",
    "string_includes",
    "string_includes_not",
    "string_starts_with",
    "string_starts_with_not",
    "string_ends_with",
    "string_ends_with_not",
    "boolean_change",
    "boolean_true",
    "boolean_false",
    "boolean_rising_edge",
    "boolean_falling_edge",
    "number_change",
    "number_equals",
    "number_equals_not",
    "number_gt",
    "number_lt",
    "number_in_range",
    "number_out_of_range",
  ];

  public readonly alarmRuleTypes = [
    // {
    //   value: "string_change",
    //   label: "opendash:data.trigger.string_change",
    // },
    {
      value: "string_equals",
      label: "opendash:data.trigger.string_equals",
    },
    {
      value: "string_equals_not",
      label: "opendash:data.trigger.string_equals_not",
    },
    {
      value: "string_includes",
      label: "opendash:data.trigger.string_includes",
    },
    {
      value: "string_includes_not",
      label: "opendash:data.trigger.string_includes_not",
    },
    {
      value: "string_starts_with",
      label: "opendash:data.trigger.string_starts_with",
    },
    {
      value: "string_starts_with_not",
      label: "opendash:data.trigger.string_starts_with_not",
    },
    {
      value: "string_ends_with",
      label: "opendash:data.trigger.string_ends_with",
    },
    {
      value: "string_ends_with_not",
      label: "opendash:data.trigger.string_ends_with_not",
    },
    {
      value: "string_equals_ref",
      label: "opendash:data.trigger.string_equals_ref",
    },
    {
      value: "string_equals_not_ref",
      label: "opendash:data.trigger.string_equals_not_ref",
    },
    {
      value: "string_includes_ref",
      label: "opendash:data.trigger.string_includes_ref",
    },
    {
      value: "string_includes_not_ref",
      label: "opendash:data.trigger.string_includes_not_ref",
    },
    {
      value: "string_starts_with_ref",
      label: "opendash:data.trigger.string_starts_with_ref",
    },
    {
      value: "string_starts_with_not_ref",
      label: "opendash:data.trigger.string_starts_with_not_ref",
    },
    {
      value: "string_ends_with_ref",
      label: "opendash:data.trigger.string_ends_with_ref",
    },
    {
      value: "string_ends_with_not_ref",
      label: "opendash:data.trigger.string_ends_with_not_ref",
    },
    // {
    //   value: "boolean_change",
    //   label: "opendash:data.trigger.boolean_change",
    // },
    {
      value: "boolean_true",
      label: "opendash:data.trigger.boolean_true",
    },
    {
      value: "boolean_false",
      label: "opendash:data.trigger.boolean_false",
    },
    {
      value: "boolean_equals_ref",
      label: "opendash:data.trigger.boolean_equals_ref",
    },
    {
      value: "boolean_equals_not_ref",
      label: "opendash:data.trigger.boolean_equals_not_ref",
    },
    // {
    //   value: "boolean_rising_edge",
    //   label: "opendash:data.trigger.boolean_rising_edge",
    // },
    // {
    //   value: "boolean_falling_edge",
    //   label: "opendash:data.trigger.boolean_falling_edge",
    // },
    // {
    //   value: "number_change",
    //   label: "opendash:data.trigger.number_change",
    // },
    {
      value: "number_equals",
      label: "opendash:data.trigger.number_equals",
    },
    {
      value: "number_equals_not",
      label: "opendash:data.trigger.number_equals_not",
    },
    {
      value: "number_gt",
      label: "opendash:data.trigger.number_gt",
    },
    {
      value: "number_lt",
      label: "opendash:data.trigger.number_lt",
    },
    {
      value: "number_in_range",
      label: "opendash:data.trigger.number_in_range",
    },
    {
      value: "number_out_of_range",
      label: "opendash:data.trigger.number_out_of_range",
    },
    {
      value: "number_equals_ref",
      label: "opendash:data.trigger.number_equals_ref",
    },
    {
      value: "number_equals_not_ref",
      label: "opendash:data.trigger.number_equals_not_ref",
    },
    {
      value: "number_gt_ref",
      label: "opendash:data.trigger.number_gt_ref",
    },
    {
      value: "number_lt_ref",
      label: "opendash:data.trigger.number_lt_ref",
    },
  ];

  public readonly actionTypes = ["email", "notification", "webhook"];

  public readonly devices = [
    // {
    //   id: "a",
    //   name: "Device A",
    // },
  ];

  constructor() {
    super({
      initialState: {
        currentDashboard: undefined,
        allDashboards: [],
        allWidgets: [],
        allWidgetPresets: [],
        copyDashboardId: undefined,
        loadingDashboard: false,
        alarms: [],
        alarmWebhooks: [],
        alarmActions: [],
      },
      cacheHandler: $framework.services.DeviceStorageService,
      cacheKey: "opendash:monitoring-service",
      cacheAllowlist: ["currentDashboard"],
    });
  }

  public async init(config: MonitoringPluginConfig) {
    if (Array.isArray(config.widgets)) {
      for (const widget of config.widgets) {
        this.widgetTypes.add(widget);
      }
    }

    this.adapter = config.adapter;
    this.context = new MonitoringAdapterContext(this);

    this.initAdapter(this.adapter, this.context);
  }

  public registerWidget(widget: WidgetTypeInterface) {
    this.widgetTypes.add(widget);
  }

  public get types(): WidgetTypeInterface[] {
    return Array.from(this.widgetTypes.values());
  }

  public get adapterSupportsDashboardSharing() {
    return !!this.adapter.openDashboardSharingDialog;
  }

  public get adapterSupportsWidgetSharing() {
    return !!this.adapter.openWidgetSharingDialog;
  }

  public getDashboard(id: string): DashboardInterface {
    return this.store.getState().allDashboards.find((db) => db.id === id);
  }

  public listDashboards(): DashboardInterface[] {
    return this.store.getState().allDashboards;
  }

  public getCurrentDashboard(): DashboardInterface {
    const x = this.store.select((state) =>
      state.allDashboards.find(
        (db) =>
          db.id === state.currentDashboard &&
          db.source === $framework.services.SourceService.getCurrent()?.id
      )
    );
    return x;
  }

  public setCurrentDashboard(dashboard: DashboardInterface): void {
    if (!dashboard || !dashboard.id) {
      console.warn("MonitoringService.setCurrentDashboard(db) db.id missing");
      return;
    }

    this.store.update((draft) => {
      draft.currentDashboard = dashboard?.id;
    });

    this.notifySubscribers();
  }

  public async createDashboard(dashboard: DashboardInterface): Promise<string> {
    // if (!dashboard.id) {
    //   console.warn(
    //     "MonitoringService.createDashboard(dashboard) dashboard.id missing"
    //   );
    //   return;
    // }

    const source = $framework.services.SourceService.getCurrent();

    return await this.adapter.createDashboard({
      ...dashboard,
      source: source?.id,
    });
  }

  public async copyDashboard(
    dashboardId: string,
    source: string,
    name: string
  ): Promise<string> {
    if (!dashboardId) {
      console.warn("MonitoringService.copyDashboard() dashboardId missing");
      return;
    }

    if (!source) {
      console.warn("MonitoringService.copyDashboard() source missing");
      return;
    }

    if (!name) {
      console.warn("MonitoringService.copyDashboard() name missing");
      return;
    }

    const dashboard = this.getDashboard(dashboardId);

    const dashboardWidgets = dashboard.widgets.map((id) =>
      this.getWidgetById(id)
    );

    const widgets: string[] = [];
    const widgetMap: Record<string, string> = {};

    for (const widget of dashboardWidgets) {
      const id = await this.createWidget({
        ...widget,
        id: undefined,
      });

      widgets.push(id);
      widgetMap[widget.id] = id;
    }

    const layout = dashboard.layout.map((l) => {
      return { ...l, i: widgetMap[l.i] };
    });

    const id = await this.adapter.createDashboard({
      // @ts-ignore
      id: undefined,
      name,
      source,
      widgets,
      layout,
    });

    await this.closeCopyDashboardDialog();

    return id;
  }

  public async openCopyDashboardDialog(dashboardId: string): Promise<void> {
    this.store.update((draft) => {
      draft.copyDashboardId = dashboardId;
    });
  }

  public async closeCopyDashboardDialog(): Promise<void> {
    this.store.update((draft) => {
      draft.copyDashboardId = undefined;
    });
  }

  public async updateDashboard(dashboard: DashboardInterface): Promise<void> {
    if (!dashboard.id) {
      console.warn(
        "MonitoringService.updateDashboard(dashboard) dashboard.id missing"
      );
      return;
    }

    return await this.adapter.updateDashboard(dashboard);
  }

  public async updateDashboardLayout(
    layout: DashboardInterface["layout"]
  ): Promise<void> {
    const currentDashboard = this.getCurrentDashboard();

    return await this.adapter.updateDashboard({
      ...currentDashboard,
      layout,
    });
  }

  public async deleteDashboard(dashboard: DashboardInterface): Promise<void> {
    if (!dashboard.id) {
      console.warn(
        "MonitoringService.deleteDashboard(dashboard) dashboard.id missing"
      );
      return;
    }

    return await this.adapter.deleteDashboard(dashboard);
  }

  public async openDashboardSharingDialog(dashboard: DashboardInterface) {
    if (!dashboard.id) {
      console.warn(
        "MonitoringService.openDashboardSharingDialog(dashboard) dashboard.id missing"
      );
      return;
    }

    if (!this.adapter.openDashboardSharingDialog) {
      console.warn(
        "MonitoringService.openDashboardSharingDialog(dashboard) adapter.openDashboardSharingDialog not implemented"
      );
      return;
    }

    return await this.adapter.openDashboardSharingDialog(dashboard);
  }

  getWidgetById(id: string) {
    return this.store.getState().allWidgets.find((widget) => widget.id === id);
  }

  public async createWidget(widget: WidgetInterface): Promise<string> {
    return await this.adapter.createWidget(widget);
  }

  public async updateWidget(widget: WidgetInterface): Promise<void> {
    return await this.adapter.updateWidget(widget);
  }

  public async deleteWidget(widget: WidgetInterface): Promise<void> {
    for (const dashboard of this.listDashboards()) {
      const widgetId = widget.id;

      if (dashboard.widgets.includes(widget.id)) {
        const dashboardUpdate = produce(dashboard, (draft) => {
          draft.widgets = draft.widgets.filter((w) => w !== widgetId);
          draft.layout = draft.layout.filter((l) => l.i !== widgetId);
        });

        await this.updateDashboard(dashboardUpdate);
      }
    }
    return await this.adapter.deleteWidget(widget);
  }

  public async openWidgetSharingDialog(widget: WidgetInterface) {
    if (!widget.id) {
      console.warn(
        "MonitoringService.openWidgetSharingDialog(widget) widget.id missing"
      );
      return;
    }

    if (!this.adapter.openWidgetSharingDialog) {
      console.warn(
        "MonitoringService.openWidgetSharingDialog(widget) adapter.openWidgetSharingDialog not implemented"
      );
      return;
    }

    return await this.adapter.openWidgetSharingDialog(widget);
  }

  public async createWidgetPreset(
    preset: Omit<
      WidgetPresetInterface,
      "id" | "isOwner" | "isShared" | "isReadOnly"
    >
  ) {
    if (!this.adapter.createWidgetPreset) {
      console.warn(
        "MonitoringService.createWidgetPreset(widget) adapter.createWidgetPreset not implemented"
      );
      return;
    }

    return await this.adapter.createWidgetPreset(preset);
  }

  public async updateWidgetPreset(preset: WidgetPresetInterface) {
    if (!this.adapter.updateWidgetPreset) {
      console.warn(
        "MonitoringService.updateWidgetPreset(widget) adapter.updateWidgetPreset not implemented"
      );
      return;
    }

    return await this.adapter.updateWidgetPreset(preset);
  }

  public async openWidgetPresetSharingDialog(widget: WidgetPresetInterface) {
    if (!widget.id) {
      console.warn(
        "MonitoringService.openWidgetPresetSharingDialog(widget) widget.id missing"
      );
      return;
    }

    if (!this.adapter.openWidgetPresetSharingDialog) {
      console.warn(
        "MonitoringService.openWidgetPresetSharingDialog(widget) adapter.openWidgetPresetSharingDialog not implemented"
      );
      return;
    }

    return await this.adapter.openWidgetPresetSharingDialog(widget);
  }

  public async addPresetsToDashboard(
    dashboard: DashboardInterface,
    presets: WidgetPresetInterface[]
  ): Promise<void> {
    const widgets = [];
    const layout = [];

    if (presets.length > 1) {
      this.store.update((draft) => {
        draft.loadingDashboard = true;
      });
    }


    

    let index = 0;
    let upIndex = 1;
    let oldX = 0;
    let oldY = 0;
    for (const preset of presets) {
      // @ts-ignore
      const id = await this.createWidget(preset.widget);
      widgets.push(id);
      let sortedArray = JSON.parse(JSON.stringify(dashboard.layout));
      sortedArray = sortedArray.sort((a, b) => b.y - a.y);
      try {
        if(sortedArray && sortedArray.length > 0) {
        oldY = sortedArray[0].y+50;}
      } catch (e) {
        console.log("Error: ", e);
      }

      if (Array.isArray(preset.layout)) {
        const [h, w] = preset.layout;
        if (oldX + (index == 0 ? 0 : w) > 24 - w) {
          oldX = 0;
          oldY = 9999 + upIndex;
          upIndex++;
          layout.push({ i: id, w, h, x: oldX, y: oldY });
          oldX = oldX;
        } else {
          layout.push({ i: id, w, h, x: oldX + (index == 0 ? 0 : w), y: oldY });
          oldX = oldX + (index == 0 ? 0 : w);
        }
      } else {
        layout.push({ i: id, w: 4, h: 4, x: 0, y: 9999 });
      }
      index++;

      await this.updateDashboard({
        ...dashboard,
        widgets: [...dashboard.widgets, ...widgets],
        layout: [...dashboard.layout, ...layout],
      });
    }

    this.store.update((draft) => {
        draft.loadingDashboard = false;  
    });
  }

  public async setHero(widgetId: string, height?: number) {
    const dashboard = this.getCurrentDashboard();

    const newDashboard = produce(dashboard, (draft) => {
      const heroConfig = JSON.parse(draft.heroWidget || "{}");
      heroConfig.top = {
        widget: widgetId,
        height: height || 20,
      };
      draft.heroWidget = JSON.stringify(heroConfig);
      draft.widgets = draft.widgets.filter((w) => w !== widgetId);
      draft.layout = draft.layout.filter((l) => l.i !== widgetId);
    });

    await this.updateDashboard(newDashboard);
  }
  public async setHeroLeft(widgetId: string, size?: number) {
    const dashboard = this.getCurrentDashboard();

    const newDashboard = produce(dashboard, (draft) => {
      const heroConfig = JSON.parse(draft.heroWidget || "{}");
      heroConfig.left = {
        widget: widgetId,
        width: size || 20,
      };
      draft.heroWidget = JSON.stringify(heroConfig);
      draft.widgets = draft.widgets.filter((w) => w !== widgetId);
      draft.layout = draft.layout.filter((l) => l.i !== widgetId);
    });

    await this.updateDashboard(newDashboard);
  }

  public getHeroWidget(dashboard: DashboardInterface) {
    const dashboardHeroString = dashboard?.heroWidget;
    let dashboardHero = null as {
      top: { widget: string; height: number };
      left: { widget: string; width: number };
    };
    try {
      dashboardHero = JSON.parse(dashboardHeroString);
    } catch (e) {}
    const { UserService } = $framework.services;
    let defaultHeroConfig = null as {
      top: { widget: string; height: number };
      left: { widget: string; width: number };
    };
    try {
      defaultHeroConfig = JSON.parse(
        UserService.getConfig("MONITORING_DEFAULT_HERO") || "{}"
      );
    } catch (e) {
      //nothing to do
    }

    const widgetId =
      dashboardHero?.top?.widget || defaultHeroConfig?.top?.widget || null;

    const widgets = this.store.getState().allWidgets;

    const widget = widgets.find((w) => w.id === widgetId);

    if (!widget) {
      return undefined;
    }
    return dashboardHero?.top || defaultHeroConfig.top;
  }

  public getHeroWidgetLeft(dashboard: DashboardInterface) {
    const dashboardHeroString = dashboard?.heroWidget;
    let dashboardHero = null as {
      top: { widget: string; height: number };
      left: { widget: string; width: number };
    };
    try {
      dashboardHero = JSON.parse(dashboardHeroString);
    } catch (e) {
      //Nothing to do here
    }
    const { UserService } = $framework.services;
    let defaultHeroConfig = null as {
      top: { widget: string; height: number };
      left: { widget: string; width: number };
    };
    try {
      defaultHeroConfig = JSON.parse(
        UserService.getConfig("MONITORING_DEFAULT_HERO_LEFT") || "{}"
      );
    } catch (e) {
      //nothing to do
    }

    const widgetId =
      dashboardHero?.left?.widget || defaultHeroConfig?.left?.widget || null;

    const widgets = this.store.getState().allWidgets;

    const widget = widgets.find((w) => w.id === widgetId);

    if (!widget) {
      return undefined;
    }
    return dashboardHero?.left || defaultHeroConfig.left;
  }

  public clearWidgetContextCache() {
    this.widgetContextCache.clear();
  }
  public createWidgetContext(id: string) {
    if (this.widgetContextCache.has(id)) {
      return this.widgetContextCache.get(id);
    }

    const context = new WidgetContext(this, id);

    this.widgetContextCache.set(id, context);

    return context;
  }

  public createWidgetDraftContext() {
    return new WidgetContext(this);
  }

  getAlarm(id: string): AlarmInterface {
    return this.store.select((state) =>
      state.alarms.find((alarm) => alarm.id === id)
    );
  }

  listAlarms(): AlarmInterface[] {
    return this.store.select((state) => state.alarms);
  }

  listAlarmsForItem(
    item: DataItemDimensionIdentifierInterface
  ): AlarmInterface[] {
    try {
      return this.listAlarms().filter((alarm) => equals(alarm.item, item));
    } catch (error) {
      return [];
    }
  }

  async createAlarm(alarm: Omit<AlarmInterface, "id">): Promise<string> {
    return await this.adapter.createAlarm(alarm);
  }

  async updateAlarm(alarm: AlarmInterface): Promise<void> {
    return await this.adapter.updateAlarm(alarm);
  }

  async deleteAlarm(alarm: AlarmInterface): Promise<void> {
    return await this.adapter.deleteAlarm(alarm);
  }

  listWebhooks(): AlarmWebhookInterface[] {
    return this.store.select((state) => state.alarmWebhooks);
  }

  listAlarmActions(): AlarmActionInterface[] {
    return this.store.select((state) => state.alarmActions);
  }
}
