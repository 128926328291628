import { Outlet } from "@opendash/router";
import * as React from "react";
import { Boundary, OpenDashGlobals } from "..";
import Layout from "./_layout";

export const RouterRootHandler = React.memo(function RouterRootHandler() {
  return (
    <>
      <OpenDashGlobals />
      <Boundary>
        <Layout>
          <Boundary>
            <Outlet />
          </Boundary>
        </Layout>
      </Boundary>
    </>
  );
});
