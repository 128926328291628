import {
  AdminLayout,
  Loading,
  produce,
  useFeedback,
  useOpenDashServices,
  useTranslation,
} from "@opendash/core";
import { Route, Routes, useParams } from "@opendash/router";
import { AdminToolbar } from "@opendash/ui";
import Parse from "parse";
import React, { useState } from "react";
import SensorTable from "./SensorTableOverview";

import {
  DataItemInterface,
  DataItemValueInterface,
} from "@opendash/plugin-timeseries";
import { Input, Layout, Result } from "antd";
import { OpenWareProvider } from "../common/OpenWareContext";

const { Search } = Input;
const { Content, Sider } = Layout;

const axios = require("axios").default;

let requestServer;
export const getRequestServer = () => {
  return requestServer;
};
export const DataCollecionOverview = (props) => {
  const { notification } = useFeedback();

  requestServer = axios.create({
    baseURL:
      (props.config.secure ? "https://" : "http://") +
      props.config.host +
      "/api/",
    timeout: 60000,
    headers: {
      "od-session": Parse.User.current().getSessionToken(),
      "Content-Type": "application/json",
    },
  });
  const t = useTranslation();
  const [sensorState, setSensorState] = useState<any>({});
  const [unitFilter, setUnitFilter] = useState<any>({});
  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const params = useParams();
  const { SourceService } = useOpenDashServices();
  const cSourceID = SourceService.getCurrent().id;
  const sources = React.useMemo(() => {
    return [
      SourceService.getCurrent().tag,
      ...SourceService.getDescendents(SourceService.getCurrent()).map(
        (source) => source.tag
      ),
    ];
  }, [cSourceID]);

  React.useEffect(() => {
    updateSensorItems();
  }, [sources]);

  const updateSensorItems = async () => {
    setShowLoading(false);
    try {
      const res = await requestServer.get(
        "admin/sensors/" + SourceService.getCurrent().tag
      );
      for (const cSource of SourceService.getDescendents(
        SourceService.getCurrent()
      )) {
        const resultOfDesc = await requestServer.get(
          "admin/sensors/" + cSource.tag
        );
        resultOfDesc.data.forEach((item) => {
          res.data.push(item);
        });
      }

      const grItems = [];

      res.data.forEach((item) => {
        if (sources.indexOf(item.source || item.user) === -1) return;
        let active = true;
        if ("active" in item.meta) {
          active = !!item.meta.active;
        }
        item.meta.active = active;

        let visible = true;
        if ("visible" in item.meta) {
          visible = !!item.meta.visible;
        }
        item.meta.visible = visible;

        let onChange = false;
        if ("onChange" in item.meta) {
          onChange = !!item.meta.onChange;
        }
        item.meta.onChange = onChange;

        let id_source = item.id;
        if ("id_source" in item.meta) {
          id_source = item.meta.id_source;
        }
        item.meta.id_source = id_source;
        let source_source = item.user;
        if ("source_source" in item.meta) {
          source_source = item.meta.source_source;
        }
        item.meta.source_source = source_source;
        item.source = item.source || item.user;
        grItems.push(item);
      });
      setSensorState({
        items: grItems,
      });
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      setShowError(true);
      console.error(error);
    }
  };

  const updateItem = async (
    id: string | null,
    newItem: (DataItemInterface & { values: DataItemValueInterface[] }) | null,
    deleteItem: boolean | null,
    hideNotifcation?: boolean | null
  ) => {
    if (id == null && newItem == null) {
      updateSensorItems();
      return null;
    }
    if (deleteItem) {
      const res = await requestServer.delete(
        "admin/sensors/" + newItem.meta.source_source + "/" + id
      );
      if (res.status === 200) {
        console.log({ res });
        if (!hideNotifcation)
          notification.success({
            message: t("openware:admin.sensors.sensorconfig.deleteSuccess"),
            description: t(
              "openware:admin.sensors.sensorconfig.deleteSuccessDescription",
              { name: newItem.name }
            ),
            placement: "topRight",
          });
      } else {
        if (!hideNotifcation)
          notification.error({
            message: t("openware:admin.sensors.sensorconfig.deleteError"),
            description: t(
              "openware:admin.sensors.sensorconfig.deleteErrorDescription",
              { item: newItem.name, error: res.data }
            ),
            placement: "topRight",
          });
      }
      updateSensorItems();
      return res;
    }

    const update = produce(newItem, (draft) => {
      draft.meta.id_source = id;
      draft.values = [];
    });

    const res = await requestServer.post("admin/sensors", [update]);

    if (res.status === 200) {
      if (!hideNotifcation)
        notification.success({
          message: t("openware:admin.sensors.sensorconfig.saveSuccess"),
          description: t(
            "openware:admin.sensors.sensorconfig.saveSuccessDescription",
            { name: update.name }
          ),
          placement: "topRight",
        });
    } else {
      if (!hideNotifcation)
        notification.error({
          message: t("openware:admin.sensors.sensorconfig.saveError"),
          description: t(
            "openware:admin.sensors.sensorconfig.saveErrorDescription",
            { name: update.name, error: res.data }
          ),
          placement: "topRight",
        });
    }
    updateSensorItems();
    return res;
  };

  if (showError) {
    return (
      <Result
        title={t("openware:admin.sensors.sensorconfig.error")}
        subTitle={t("openware:admin.sensors.sensorconfig.errorSubtitle")}
      ></Result>
    );
  }
  return (
    <OpenWareProvider config={props.config}>
      <AdminLayout>
        <AdminToolbar
          title={t("openware:admin.sensors.sensorconfig.label")}
          description={t("openware:admin.sensors.sensorconfig.description")}
          search={""}
          actions={[]}
        />
        <div className="od-page-main">
          <Layout style={{ background: "#fff" }}>
            <Layout style={{ backgroundColor: "var(--opendash-color-white)" }}>
              <Content
                className="site-layout-background"
                style={{
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Routes>
                  <Route
                    path="*"
                    element={
                      <div>
                        {sensorState.items && !showLoading ? (
                          <SensorTable
                            items={sensorState.items}
                            updateItem={updateItem}
                          ></SensorTable>
                        ) : (
                          <Loading message={""} />
                        )}
                      </div>
                    }
                  ></Route>
                </Routes>
              </Content>
            </Layout>
          </Layout>
        </div>
      </AdminLayout>
    </OpenWareProvider>
  );
};
