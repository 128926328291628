import type { StorageAdapterInterface, StorageOptionsInterface } from "..";

import { BaseService } from "../classes/BaseService";
import type { FrameworkService } from "./FrameworkService";
import { StorageAdapterContext } from "./StorageAdapterContext";

export class StorageService extends BaseService {
  private framework: FrameworkService;
  private adapter: StorageAdapterInterface;
  private context: StorageAdapterContext;

  private storage = new Map<string, string>();
  private cache = new Map<string, any>();

  constructor(framework: FrameworkService) {
    super({
      initialState: {},
    });

    this.framework = framework;
    this.context = new StorageAdapterContext(this.storage, this);
  }

  public async init(adapter: StorageAdapterInterface) {
    this.adapter = adapter;
    this.initAdapter(adapter, this.context);
  }

  public async get<T>(key: string): Promise<T> {
    return this._getSync(key);
  }

  public async set<T>(
    key: string,
    value: T,
    options?: StorageOptionsInterface<T>
  ) {
    const nextValue = options?.encode
      ? options.encode(value)
      : JSON.stringify(value);

    if (this.storage.has(key) && this.storage.get(key) === nextValue) {
      return;
    }

    if (!this.adapter) {
      return void console.warn(
        "StorageService.set was called before an adapter was supplied",
        key,
        value
      );
    }

    return await this.adapter.set(key, nextValue);
  }

  public async delete(key: string) {
    return await this.adapter.delete(key);
  }

  public async keys(): Promise<string[]> {
    return this._keysSync();
  }

  public async clear(): Promise<void> {
    return await this.adapter.clear();
  }

  public _getSync<T>(key: string, options?: StorageOptionsInterface<T>): T {
    if (this.cache.has(key)) {
      return this.cache.get(key);
    }

    if (this.storage.has(key)) {
      const cache = options?.decode
        ? options.decode(this.storage.get(key))
        : JSON.parse(this.storage.get(key));

      this.cache.set(key, cache);

      return cache;
    }

    return undefined;
  }

  public _keysSync(): string[] {
    return Array.from(this.storage.keys());
  }

  public _clearCache(key: string) {
    this.cache.delete(key);
  }
}
