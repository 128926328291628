import { useDeepCompareEffect, useTranslation } from "@opendash/core";
import "@opendash/icons";
import { Icon } from "@opendash/icons";
import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  InputNumber,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import produce from "immer";
import * as React from "react";
import styled from "styled-components";
import {
  AggregationOperationInterface,
  AggregationOperationSelect,
  DataFetchingOptionsInterface,
  DataFetchingSelectionInterface,
} from "..";

const COL_STYLE: React.CSSProperties = { marginBottom: 24 };
const COL_NO_MARGIN_STYLE: React.CSSProperties = { marginBottom: 4 };
const INPUT_STYLE: React.CSSProperties = { width: "100%" };

const DatePickerStyle = styled.div`
  .ant-picker-input > input {
    text-align: center;
  }
  a,
  area,
  button,
  [role="button"],
  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    text-align: center;
  }
  .ant-select-selection-item {
    text-align: center;
  }

  a,
  area,
  button,
  [role="button"],
  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    text-align: center;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    text-align: center;
  }

  .ant-checkbox-wrapper {
    text-align: left;
    margin: 0px;
  }
`;

interface Props {
  value: DataFetchingOptionsInterface;
  onChange: (nextValue: DataFetchingOptionsInterface) => void;
  options?: DataFetchingSelectionInterface;
  style?: React.CSSProperties;
}

export function DataItemHistoryOptionsPicker({
  style,
  options = {},
  value = {},
  onChange,
}: Props) {
  const t = useTranslation();

  const allow = evaluateAllow(options);
  const show = evaluateShow(options, value);

  useDeepCompareEffect(() => {
    onChange(fixValue(options, value));
  }, [value, options]);

  if (!options?.live && !options?.history) {
    return <span>{t("opendash:monitoring.history_options.invalid")}</span>;
  }

  if (options?.live && !options?.history) {
    return (
      <Row gutter={16}>
        <Col style={COL_STYLE} span={24}>
          <Checkbox
            checked={value.live}
            disabled={!options?.live || options?.liveRequired}
            onChange={(e) => {
              onChange(
                merge(value, {
                  live: e.target.checked,
                })
              );
            }}
          >
            {t("opendash:monitoring.history_options.live_enabled")}
          </Checkbox>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          align="middle"
          style={{
            width: "100%",
            fontWeight: "normal",
          }}
        >
          <Col xs={0} sm={3}>
            <Icon
              icon="fa:calendar"
              style={{
                textAlign: "center",
                fontSize: "40px",
                color: window
                  .getComputedStyle(document.documentElement)
                  .getPropertyValue("--opendash-primary-color"),
              }}
            />
          </Col>
          <Col xs={24} sm={21}>
            <Row
              gutter={16}
              style={{ textAlign: "center", marginBottom: "-20px" }}
            >
              <Col style={COL_STYLE} span={24}>
                <Radio.Group
                  style={{ width: "100%" }}
                  size="large"
                  value={
                    !value.historyType && !value.live
                      ? "none"
                      : !value.historyType && value.live
                        ? "live"
                        : value.historyType
                  }
                  onChange={(e) => {
                    const v = e.target.value;

                    if (v === "none") {
                      onChange(
                        merge(value, {
                          live: false,
                          historyType: undefined,
                        })
                      );
                    } else if (v === "live") {
                      onChange(
                        merge(value, {
                          live: true,
                          historyType: undefined,
                        })
                      );
                    } else {
                      onChange(
                        merge(value, {
                          historyType: v,
                          limit: undefined,
                        })
                      );
                    }
                  }}
                >
                  {allow.live && (
                    <Radio.Button
                      value="live"
                      style={{
                        width:
                          allow.relative && allow.absolute
                            ? "33.33%"
                            : allow.relative
                              ? "50%"
                              : allow.absolute
                                ? "50%"
                                : "100%",
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                    >
                      {t("opendash:monitoring.history_options.type_live")}
                    </Radio.Button>
                  )}
                  {allow.relative && (
                    <Radio.Button
                      value="relative"
                      style={{
                        width:
                          allow.live && allow.absolute
                            ? "33.33%"
                            : allow.live
                              ? "50%"
                              : allow.absolute
                                ? "50%"
                                : "100%",
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                    >
                      {t("opendash:monitoring.history_options.type_relative")}
                    </Radio.Button>
                  )}
                  {allow.absolute && (
                    <Radio.Button
                      value="absolute"
                      style={{
                        width:
                          allow.live && allow.relative
                            ? "33.33%"
                            : allow.live
                              ? "50%"
                              : allow.relative
                                ? "50%"
                                : "100%",
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                    >
                      {t("opendash:monitoring.history_options.type_absolute")}
                    </Radio.Button>
                  )}
                </Radio.Group>
              </Col>
            </Row>
            {show.relative && (
              <>
                <DatePickerStyle
                  style={{
                    width: "100%",
                    fontWeight: "normal",
                  }}
                >
                  <Row gutter={12} justify="end">
                    <Col style={COL_STYLE} span={8}>
                      <InputNumber
                        disabled={!value.historyType}
                        style={INPUT_STYLE}
                        min={1}
                        value={value.value}
                        onChange={(nextValue: number) =>
                          onChange(
                            merge(value, {
                              value: Math.floor(Math.max(nextValue, 1)),
                            })
                          )
                        }
                      />
                    </Col>
                    <Col style={COL_STYLE} span={16}>
                      <Select
                        disabled={!value.historyType}
                        style={INPUT_STYLE}
                        value={value.unit}
                        onChange={(unit) => {
                          onChange(
                            merge(value, {
                              unit,
                            })
                          );
                        }}
                      >
                        <Select.Option value="second">
                          {t("opendash:ui.second", { count: value.value })}
                        </Select.Option>
                        <Select.Option value="minute">
                          {t("opendash:ui.minute", { count: value.value })}
                        </Select.Option>
                        <Select.Option value="hour">
                          {t("opendash:ui.hour", { count: value.value })}
                        </Select.Option>
                        <Select.Option value="day">
                          {t("opendash:ui.day", { count: value.value })}
                        </Select.Option>
                        <Select.Option value="week">
                          {t("opendash:ui.week", { count: value.value })}
                        </Select.Option>
                        <Select.Option value="month">
                          {t("opendash:ui.month", { count: value.value })}
                        </Select.Option>
                        <Select.Option value="year">
                          {t("opendash:ui.year", { count: value.value })}
                        </Select.Option>
                      </Select>
                    </Col>
                  </Row>

                  <Row gutter={16} style={{ textAlign: "left" }}>
                    <Col style={COL_STYLE} span={24}>
                      {show.liveRelative && (
                        <Checkbox
                          children={t(
                            "opendash:monitoring.history_options.type_relative_disable_live"
                          )}
                          checked={!value.live}
                          onChange={(e) => {
                            onChange(
                              merge(value, {
                                live: !e.target.checked,
                              })
                            );
                          }}
                        />
                      )}
                      {allow.relativeFuture && (
                        <Checkbox
                          children={t(
                            "opendash:monitoring.history_options.type_relative_future_show"
                          )}
                          checked={show.future}
                          onChange={(e) => {
                            onChange(
                              merge(value, {
                                futureValue: e.target.checked ? 1 : 0,
                              })
                            );
                          }}
                        />
                      )}
                      <Checkbox
                        checked={
                          value.includeLowerBound || value.includeUpperBound
                        }
                        onChange={(e) => {
                          const checked = e.target.checked;
                          onChange(
                            merge(
                              value,
                              checked
                                ? {
                                    includeLowerBound: true,
                                  }
                                : {
                                    includeLowerBound: false,
                                    includeUpperBound: false,
                                  }
                            )
                          );
                        }}
                      >
                        {t("opendash:data.includeBounds")}
                      </Checkbox>
                    </Col>
                  </Row>
                </DatePickerStyle>
              </>
            )}

            {show.future && (
              <>
                <Divider plain orientation="left">
                  {t(
                    "opendash:monitoring.history_options.type_relative_future_show"
                  )}
                </Divider>
                <DatePickerStyle style={{ width: "100%" }}>
                  <Row gutter={16}>
                    <Col style={COL_STYLE} span={12}>
                      <InputNumber
                        disabled={!value.historyType}
                        style={INPUT_STYLE}
                        min={1}
                        value={value.futureValue}
                        onChange={(nextValue: number) =>
                          onChange(
                            merge(value, {
                              futureValue: Math.floor(Math.max(nextValue, 1)),
                            })
                          )
                        }
                      />
                    </Col>
                    <Col style={COL_STYLE} span={12}>
                      <Select
                        disabled={!value.historyType}
                        style={INPUT_STYLE}
                        value={value.futureUnit}
                        onChange={(unit) => {
                          onChange(
                            merge(value, {
                              futureUnit: unit,
                            })
                          );
                        }}
                      >
                        <Select.Option value="second">
                          {t("opendash:ui.second", {
                            count: value.futureValue,
                          })}
                        </Select.Option>
                        <Select.Option value="minute">
                          {t("opendash:ui.minute", {
                            count: value.futureValue,
                          })}
                        </Select.Option>
                        <Select.Option value="hour">
                          {t("opendash:ui.hour", { count: value.futureValue })}
                        </Select.Option>
                        <Select.Option value="day">
                          {t("opendash:ui.day", { count: value.futureValue })}
                        </Select.Option>
                        <Select.Option value="week">
                          {t("opendash:ui.week", { count: value.futureValue })}
                        </Select.Option>
                        <Select.Option value="month">
                          {t("opendash:ui.month", { count: value.futureValue })}
                        </Select.Option>
                        <Select.Option value="year">
                          {t("opendash:ui.year", { count: value.futureValue })}
                        </Select.Option>
                      </Select>
                    </Col>
                  </Row>
                </DatePickerStyle>
              </>
            )}

            {show.absolute && (
              <>
                <DatePickerStyle style={{ width: "100%" }}>
                  <DatePicker.RangePicker
                    disabled={!value.historyType}
                    allowClear={false}
                    // TODO:
                    // @ts-ignore
                    placeholder={t("opendash:ui.select_date_range")}
                    style={{ width: "100%" }}
                    value={[dayjs(value?.start), dayjs(value?.end)]}
                    onChange={(nextValue) =>
                      onChange(
                        merge(value, {
                          start: nextValue[0].valueOf(),
                          end: nextValue[1].valueOf(),
                          unit: undefined,
                          value: undefined,
                        })
                      )
                    }
                  />
                  <Checkbox
                    checked={value.includeLowerBound || value.includeUpperBound}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      onChange(
                        merge(
                          value,
                          checked
                            ? {
                                includeLowerBound: true,
                              }
                            : {
                                includeLowerBound: false,
                                includeUpperBound: false,
                              }
                        )
                      );
                    }}
                  >
                    {t("opendash:data.includeBounds")}
                  </Checkbox>
                </DatePickerStyle>
              </>
            )}
            {!show.aggregation &&
              (value.historyType === "absolute" ||
                value.historyType == "relative") &&
              (value.includeLowerBound || value.includeUpperBound) && (
                <>
                  <Divider plain orientation="left">
                    <>
                      {t("opendash:data.includeBounds")}
                      <Tooltip title={t("opendash:data.includeBoundsInfo")}>
                        <Icon icon="fa:info-circle" />
                      </Tooltip>
                    </>
                  </Divider>
                  <Row>
                    <Col span={24}>
                      <Select
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          const selection = e;
                          onChange(
                            merge(value, {
                              includeLowerBound:
                                selection === "lower" || selection === "both",
                              includeUpperBound:
                                selection === "upper" || selection === "both",
                            })
                          );
                        }}
                        value={
                          value.includeLowerBound && value.includeUpperBound
                            ? "both"
                            : value.includeLowerBound
                              ? "lower"
                              : "upper"
                        }
                      >
                        <Select.Option value="lower">
                          {" "}
                          {t("opendash:data.includeLowerBound")}
                        </Select.Option>
                        <Select.Option value="upper">
                          {" "}
                          {t("opendash:data.includeUpperBound")}
                        </Select.Option>
                        <Select.Option value="both">
                          {" "}
                          {t("opendash:data.includeBothBounds")}
                        </Select.Option>
                      </Select>
                    </Col>
                  </Row>
                </>
              )}

            {show.aggregation && (
              <>
                <Row gutter={16}>
                  <Divider orientation="center">
                    {t(
                      "opendash:monitoring.history_options.aggregation_description"
                    )}
                  </Divider>
                  <div style={{ height: "15px", width: "100%" }}></div>

                  <Col style={COL_STYLE} span={24}>
                    <AggregationOperationSelect
                      style={INPUT_STYLE}
                      disabled={!options?.aggregation}
                      value={
                        value.aggregation && value.aggregationOperation
                          ? value.aggregationOperation
                          : "none"
                      }
                      onChange={(
                        aggregation: AggregationOperationInterface | "none"
                      ) => {
                        onChange(
                          produce(value, (draft) => {
                            if (aggregation === "none") {
                              draft.aggregation = false;
                              draft.aggregationOperation = undefined;
                              draft.aggregationSplits = undefined;
                            } else {
                              draft.aggregation = true;
                              draft.aggregationOperation = aggregation;
                            }
                          })
                        );
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
}

function merge(
  current: DataFetchingOptionsInterface,
  update: Partial<DataFetchingOptionsInterface>
): DataFetchingOptionsInterface {
  return Object.assign({}, current || {}, update);
}

function implies(a: boolean, b: boolean): boolean {
  if (a) {
    return b;
  } else {
    return true;
  }
}

function evaluateAllow(options: DataFetchingSelectionInterface) {
  return {
    live:
      options?.history &&
      options?.live &&
      !(options?.historyRequired && options?.historyForceAbsolute),

    history: !!options?.history,

    relative: options?.history && !options?.historyForceAbsolute,

    relativeFuture:
      options?.history &&
      !options?.historyForceAbsolute &&
      !options?.historyPreventFuture,

    absolute:
      options?.history &&
      !options?.historyForceRelative &&
      !options.liveRequired,

    none: !options?.historyRequired && !options?.liveRequired,
  };
}

function evaluateShow(
  options: DataFetchingSelectionInterface,
  value: DataFetchingOptionsInterface
) {
  return {
    relative:
      options?.history &&
      !options.historyForceAbsolute &&
      value.historyType === "relative",

    absolute:
      options?.history &&
      !options.historyForceRelative &&
      !options?.liveRequired &&
      value.historyType === "absolute",

    future: !!value.futureValue,

    liveRelative: options?.live && !options?.liveRequired,

    aggregation:
      options?.aggregation &&
      implies(options?.aggregationRequiresHistory, !!value.historyType),
  };
}

function fixValue(
  options: DataFetchingSelectionInterface,
  value: DataFetchingOptionsInterface
) {
  const allow = evaluateAllow(options);
  return produce(value, (draft) => {
    if (!options.live) {
      draft.live = false;
    } else if (options.live && options.liveRequired) {
      draft.live = true;
    }

    if (!allow.history) {
      Object.assign(draft, {
        historyType: undefined,
        start: undefined,
        end: undefined,
        unit: undefined,
        value: undefined,
        futureUnit: undefined,
        futureValue: undefined,
        aggregation: undefined,
      });
    } else if (
      options.history &&
      options.historyRequired &&
      !draft.historyType
    ) {
      draft.historyType = "relative";
    }

    if (draft.historyType === "absolute" && !allow.absolute) {
      draft.historyType = "relative";
    }

    if (draft.historyType === "relative" && !allow.relative) {
      draft.historyType = "absolute";
    }

    if (draft.historyType === "absolute") {
      draft.live = false;

      if (!draft.start) {
        draft.start = dayjs().subtract(7, "day").startOf("day").valueOf();
      }

      if (!draft.end) {
        draft.end = Date.now();
      }
    } else {
      draft.start = undefined;
      draft.end = undefined;
    }

    if (draft.historyType === "relative") {
      if (options.live && draft.live === undefined) {
        draft.live = true;
      }

      if (!draft.value) {
        draft.value = 1;
      }

      if (!draft.unit) {
        draft.unit = "week";
      }

      if (!draft.futureValue) {
        draft.futureValue = 0;
      }

      if (!draft.futureUnit) {
        draft.futureUnit = "week";
      }
    } else {
      draft.value = undefined;
      draft.unit = undefined;
      draft.futureValue = undefined;
      draft.futureUnit = undefined;
    }

    if (draft.live && !draft.historyType) {
      draft.limit = draft.limit || 1;
    }

    if (!options.aggregation) {
      draft.aggregation = false;
    } else if (
      options.aggregation &&
      options.aggregationRequired &&
      !draft.aggregation
    ) {
      draft.aggregation = true;
    } else if (!draft.historyType && options?.aggregationRequiresHistory) {
      draft.aggregation = undefined;
      draft.aggregationOperation = undefined;
    }

    if (draft.aggregation && !draft.aggregationOperation) {
      draft.aggregationOperation = "count";
    }
  });
}
