import Parse from "parse";
import React, { useEffect, useState } from "react";

import { useFeedback } from "@opendash/core";
import { useTranslation } from "@opendash/i18n";
import { Checkbox, Divider, Input, List, Modal, Tabs } from "antd";
import { useParseQuery } from "parse-hooks";
import { $parse } from "../../state";

const userQuery = new Parse.Query(Parse.User).ascending("name").limit(1000);
const roleQuery = new Parse.Query(Parse.Role).ascending("name").limit(1000);

interface Props {
  object: Parse.Object;

  hideRoles?: boolean;
  hideUsers?: boolean;
  hideSelf?: boolean;
  hideRead?: boolean;
  hideWrite?: boolean;
  readLabel?: string;
  writeLabel?: string;

  onClose: (saved: boolean) => void;
}

export const AclDialog = React.memo<Props>(function AclDialog({
  object,
  hideRoles = false,
  hideUsers = false,
  hideSelf = false,
  hideRead = false,
  hideWrite = false,
  readLabel = "parse-admin:admin.edit_permission_read",
  writeLabel = "parse-admin:admin.edit_permission_write",
  onClose,
}) {
  const t = useTranslation();
  const { message } = useFeedback();

  const [searchString, setSearchString] = useState("");

  const [userPermissonChanges, setUserPermissionChanges] = React.useState(0);
  const [rolePermissonChanges, setRolePermissionChanges] = React.useState(0);

  const [currentTab, setCurrentTab] = React.useState("roles");

  const users = useParseQuery(userQuery);
  const roles = useParseQuery(roleQuery);

  const [acl, setAcl] = React.useState(new Parse.ACL());

  React.useEffect(() => {
    const newAcl = new Parse.ACL(object?.getACL()?.toJSON());

    setAcl(newAcl);
  }, [object?.getACL()]);

  useEffect(() => {
    if (object) {
      users.reload();
      roles.reload();
    }
  }, [object]);

  const onSave = (e) => {
    object.setACL(acl);

    object.save().then(
      (ok) => {
        message.success(t("parse-admin:admin.edit_permission_success"));
        onClose(true);
      },
      (error) => {
        message.error(t("parse-admin:admin.edit_permission_error"));
        console.error(error);
        // TODO
        onClose(false);
      }
    );
  };

  const usersFiltered = React.useMemo(() => {
    let filteredBySearch = users.result.filter((user) =>
      getUserName(user).toLowerCase().includes(searchString.toLowerCase())
    );

    if (hideSelf) {
      filteredBySearch = filteredBySearch.filter(
        (user) => user.id !== $parse.user.current()?.id
      );
    }

    const hasWritePermissions = filteredBySearch.filter((user) =>
      acl.getWriteAccess(user)
    );

    const hasReadPermissions = filteredBySearch.filter(
      (user) => acl.getReadAccess(user) && !acl.getWriteAccess(user)
    );

    const hasNoPermissions = filteredBySearch.filter(
      (user) => !acl.getReadAccess(user)
    );

    return hasWritePermissions.concat(hasReadPermissions, hasNoPermissions);
  }, [users.result, searchString, acl, userPermissonChanges, hideSelf]);

  const rolesFiltered = React.useMemo(() => {
    const filteredBySearch = roles.result.filter((role) =>
      getRoleName(role).toLowerCase().includes(searchString.toLowerCase())
    );

    const hasWritePermissions = filteredBySearch.filter((role) =>
      acl.getRoleWriteAccess(role)
    );

    const hasReadPermissions = filteredBySearch.filter(
      (role) => acl.getRoleReadAccess(role) && !acl.getRoleWriteAccess(role)
    );

    const hasNoPermissions = filteredBySearch.filter(
      (role) => !acl.getRoleReadAccess(role)
    );

    return hasWritePermissions.concat(hasReadPermissions, hasNoPermissions);
  }, [roles.result, searchString, acl, rolePermissonChanges]);

  React.useEffect(() => {
    if (
      !!searchString &&
      currentTab === "roles" &&
      rolesFiltered.length === 0 &&
      usersFiltered.length > 0
    ) {
      setCurrentTab("users");
    }

    if (
      !!searchString &&
      currentTab === "users" &&
      usersFiltered.length === 0 &&
      rolesFiltered.length > 0
    ) {
      setCurrentTab("roles");
    }
  }, [usersFiltered, rolesFiltered, currentTab, !searchString]);

  if (!object) {
    return null;
  }

  return (
    <Modal
      open={!!object}
      title={t("parse-admin:admin.edit_permission_title")}
      onCancel={(e) => onClose(false)}
      onOk={onSave}
      okText={t("parse-admin:admin.edit_permission_save")}
    >
      <Input.Search
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        placeholder={t("parse-admin:admin.edit_permission_filter")}
      />
      <Divider />

      <Tabs
        activeKey={currentTab}
        onChange={(tab) => {
          setCurrentTab(tab);
        }}
      >
        {!hideRoles && (
          <Tabs.TabPane
            tab={t("parse-admin:admin.edit_permission_roles_tab")}
            key="roles"
          >
            <List
              loading={roles.loading && !object}
              itemLayout="horizontal"
              dataSource={rolesFiltered}
              rowKey={(role) => role.id}
              renderItem={(role) => {
                const readAccess = acl.getRoleReadAccess(role);
                const writeAccess = acl.getRoleWriteAccess(role);

                return (
                  <List.Item
                    actions={[
                      <>
                        {!hideRead && (
                          <Checkbox
                            key="read"
                            children={t(readLabel)}
                            checked={readAccess}
                            onChange={() => {
                              acl.setRoleReadAccess(role, !readAccess);

                              if (readAccess) {
                                acl.setRoleWriteAccess(role, !readAccess);
                              }

                              setRolePermissionChanges((i) => i + 1);
                            }}
                          />
                        )}
                        {!hideWrite && (
                          <Checkbox
                            key="write"
                            children={t(writeLabel)}
                            checked={writeAccess}
                            onChange={() => {
                              acl.setRoleReadAccess(role, !writeAccess);
                              acl.setRoleWriteAccess(role, !writeAccess);

                              setRolePermissionChanges((i) => i + 1);
                            }}
                          />
                        )}
                      </>,
                    ]}
                  >
                    {getRoleName(role)}
                  </List.Item>
                );
              }}
            />
          </Tabs.TabPane>
        )}
        {!hideUsers && (
          <Tabs.TabPane
            tab={t("parse-admin:admin.edit_permission_users_tab")}
            key="users"
          >
            <List
              loading={users.loading && !object}
              itemLayout="horizontal"
              dataSource={usersFiltered}
              rowKey={(user) => user.id}
              renderItem={(user) => {
                const readAccess = acl.getReadAccess(user);
                const writeAccess = acl.getWriteAccess(user);

                return (
                  <List.Item
                    actions={[
                      <>
                        <Checkbox
                          key="read"
                          children={t("parse-admin:admin.edit_permission_read")}
                          checked={readAccess}
                          onChange={() => {
                            acl.setReadAccess(user, !readAccess);

                            if (readAccess) {
                              acl.setWriteAccess(user, !readAccess);
                            }

                            setUserPermissionChanges((i) => i + 1);
                          }}
                        />
                        <Checkbox
                          key="write"
                          children={t(
                            "parse-admin:admin.edit_permission_write"
                          )}
                          checked={writeAccess}
                          onChange={() => {
                            acl.setReadAccess(user, !writeAccess);
                            acl.setWriteAccess(user, !writeAccess);

                            setUserPermissionChanges((i) => i + 1);
                          }}
                        />
                      </>,
                    ]}
                  >
                    {getUserName(user)}
                  </List.Item>
                );
              }}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Modal>
  );
});

function getUserName(user: Parse.User) {
  const [name, username, email] = [
    user.get("name"),
    user.getUsername(),
    user.getEmail(),
  ].filter(Boolean);

  let result = name;

  if (username) {
    result += ` (${username})`;
  }

  if (email) {
    result += `, ${email}`;
  }
  return result;
}

function getRoleName(role: Parse.Role) {
  return role.get("label") || role.getName();
}
