import { AdminLayout, Loading, produce, useTranslation } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import { Button, Form, Input, Modal, Radio, Select, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import Parse from "parse";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Options } from "../Plugin";
const { TextArea } = Input;
const { Option } = Select;

export const FeedbackProvider = observer((props: Partial<Options>) => {
  const [form] = Form.useForm();
  const t = useTranslation();
  const [state, setState] = useState<{
    modalVisible: boolean;
    dataList: DataType[];
    stillLoading: boolean;
  }>({
    modalVisible: false,
    dataList: [],
    stillLoading: true,
  });

  React.useEffect(() => {
    getIssues();
  }, []);

  React.useEffect(() => {
    getIssues();
  }, [state.modalVisible]);

  const createFeedbackModal = async (modalVisible) => {
    setState(
      produce((draft) => {
        draft.modalVisible = modalVisible;
      })
    );
  };

  const locale = {
    emptyText: t("feedback:overview.empty"),
  };

  const team =
    props.feedbackURL.split("=")[props.feedbackURL.split("=").length - 1];

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const renderers = {
    p: (props) => <p style={{ margin: "0px" }}>{props.children}</p>,
  };

  const getIssues = async () => {
    try {
      const response = await fetch(props.feedbackURL);
      const issues = await response.json();
      let result: DataType[] = issues.result;
      result = result.filter(function (el) {
        return (
          el.state != "Duplicate" &&
          el.state != "Canceled" &&
          el.state != "Done"
        );
      });

      //@ts-ignore
      result = result.map(({ id: key, ...rest }) => ({
        key,
        ...rest,
      }));

      setState(
        produce((draft) => {
          draft.dataList = result;
          draft.stillLoading = false;
        })
      );
    } catch (e) {
      console.error("Error getting Issues");
      setState(
        produce((draft) => {
          draft.stillLoading = false;
        })
      );
    }
  };

  const sendFeedback = async (values: any) => {
    if (!values.team) {
      values.team = team;
    }
    if (values.tags) {
      values.type = values.tags;
    }

    values.assignedTo = props.feedbackAssigned || "";

    values.description =
      values.description + " |||| " + Parse.User.current().getEmail();

    const rawResponse = await fetch(props.feedbackURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
    const content = await rawResponse.json();

    setState(
      produce((draft) => {
        draft.modalVisible = false;
      })
    );
    form.resetFields();
  };

  interface DataType {
    id: string;
    createdAt: string;
    updatedAt: string;
    state?: string;
    labels: string[];
    title: string;
    description: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: t("feedback:table.title"),
      key: "title",
      dataIndex: "title",
    },
    {
      title: t("feedback:table.createdate"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <>{dayjs(text).format("DD.MM.YYYY @ HH:mm:ss")}</>,
      sorter: (a, b) => {
        if (dayjs(a.createdAt) < dayjs(b.createdAt)) {
          return -1;
        } else if (dayjs(a.createdAt) > dayjs(b.createdAt)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: t("feedback:table.lastdate"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <>{dayjs(text).format("DD.MM.YYYY @ HH:mm:ss")}</>,
      responsive: ["md"],
      sorter: (a, b) => {
        if (dayjs(a.updatedAt) < dayjs(b.updatedAt)) {
          return -1;
        } else if (dayjs(a.updatedAt) > dayjs(b.updatedAt)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: t("feedback:table.status"),
      dataIndex: "state",
      key: "state",
      render: (state) => (
        <>
          {state
            ? t(
                "feedback:overview.issue." +
                  state.toLowerCase().replace(" ", "")
              )
            : t("feedback:overview.issue.open")}
        </>
      ),
    },
    {
      title: t("feedback:table.user"),
      dataIndex: "description",
      key: "user",
      responsive: ["lg"],
      render: (user) => (
        <span style={{ margin: "5px !important" }}>
          <ReactMarkdown
            components={renderers}
            children={
              user.split("||||").length > 1
                ? user.split("||||")[user.split("||||").length - 1]
                : t("feedback:overview.nouser")
            }
          />
        </span>
      ),
    },
    {
      title: t("feedback:table.tags"),
      key: "labels",
      dataIndex: "labels",
      responsive: ["md"],
      render: (_, { labels }) => (
        <>
          {labels.map((tag) => {
            const color = tag.length > 6 ? "geekblue" : "red";
            return (
              <Tag color={color} key={tag}>
                {t("feedback:overview.tag." + tag.toLowerCase()).toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  return (
    <AdminLayout>
      <AdminToolbar
        title={t("feedback:overview.title")}
        description={t("feedback:overview.description")}
        actions={[
          <Button
            key="create"
            type="primary"
            onClick={() => createFeedbackModal(true)}
            children={t("feedback:overview.create.action")}
          />,
        ]}
      />
      {state.stillLoading && <Loading message={""} />}
      {!state.stillLoading && (
        <div className="od-page-main">
          <Modal
            title={t("feedback:overview.create.new")}
            style={{ top: 20 }}
            open={state.modalVisible}
            onCancel={() => {
              setState(
                produce((draft) => {
                  draft.modalVisible = false;
                })
              );
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setState(
                    produce((draft) => {
                      draft.modalVisible = false;
                    })
                  );
                  form.resetFields();
                }}
              >
                {t("feedback:overview.create.cancel")}
              </Button>,
              <Button
                form="feedbackform"
                key="submit"
                htmlType="submit"
                type="primary"
              >
                {t("feedback:overview.create.save")}
              </Button>,
            ]}
          >
            <Form
              form={form}
              id="feedbackform"
              labelCol={{ span: 6 }}
              layout="horizontal"
              style={{ maxWidth: 600 }}
              onFinish={sendFeedback}
            >
              <Form.Item
                name="title"
                label={t("feedback:overview.create.title")}
                rules={[{ required: true }]}
              >
                <Input placeholder="Titel..." />
              </Form.Item>
              <Form.Item
                name="description"
                label={t("feedback:overview.create.desc")}
                rules={[{ required: true }]}
              >
                <TextArea
                  placeholder={t("feedback:overview.create.descdot")}
                  rows={4}
                />
              </Form.Item>
              <Form.Item name="team" label={t("feedback:overview.create.team")}>
                <Select disabled defaultValue={team}>
                  <Option value={team}>{team}</Option>
                  <Option value={t("feedback:overview.create.other")}>
                    {t("feedback:overview.create.other")}
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item name="tags" label="Tags">
                <Radio.Group>
                  <Radio value="bug">{t("feedback:overview.create.bug")}</Radio>
                  <Radio value="improvement">
                    {t("feedback:overview.create.improvment")}
                  </Radio>
                  <Radio value="feature">
                    {t("feedback:overview.create.feature")}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {/*
          <Form.Item
            label="Anhang"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload action="/upload.do" listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Anhang</div>
              </div>
            </Upload>
              </Form.Item>*/}
            </Form>
          </Modal>
          <Table
            size="middle"
            locale={locale}
            columns={columns}
            style={{ width: "100%" }}
            expandable={{
              columnWidth: 1,
              expandedRowRender: (record) => (
                <ReactMarkdown
                  children={
                    record.description.split("||||").length > 1
                      ? record.description.split("||||")[0]
                      : record.description
                  }
                />
              ),
              rowExpandable: (record) =>
                record.description !== "Not Expandable",
            }}
            dataSource={state.dataList}
          />
        </div>
      )}
    </AdminLayout>
  );
});

/*

- Title, Text, Team, Project, Label/Text, Bild?
- Icon für Übersicht im linken Menü
- ID zu KEY ändern?
- BDE Archive werden immer alle History angezeigt egal welche Source ausgewählt, vielleicht source -> Auswahlnahmen setzen?
-
-
-

*/
