import * as Parse from "parse";

export async function fetchParse<T extends Parse.Object>(
  query: Parse.Query<T>,
  onResult: (result: T[]) => void,
  onUpdateResult?: (key: string, result: T) => void,
  useLiveQueries?: boolean
) {
  const result = await query.find();

  onResult(result);

  if (useLiveQueries && onUpdateResult) {
    query.subscribe().then((subscription) => {
      subscription.on("open", () => {
        console.info("subscription opened");
      });

      subscription.on("create", (object: T) => {
        console.info("object created");
        onUpdateResult(object.id, object);
      });

      subscription.on("update", (object: T) => {
        console.info("object updated");
        onUpdateResult(object.id, object);
      });

      subscription.on("enter", (object: T) => {
        console.info("object entered");
        onUpdateResult(object.id, object);
      });

      subscription.on("leave", (object: T) => {
        console.info("object left");
        onUpdateResult(object.id, undefined);
      });

      subscription.on("delete", (object: T) => {
        console.info("object deleted");
        onUpdateResult(object.id, undefined);
      });

      subscription.on("close", () => {
        console.info("subscription closed");
      });
    });
  }
}
