import {
  createInternalComponent,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Menu } from "antd";
import * as React from "react";
import { WidgetContext } from "..";
import {
  WidgetActions,
  WidgetContainer,
  WidgetContent,
} from "./WidgetComponentLayoutBorderless.layout";

export const WidgetComponentLayoutBorderless = createInternalComponent<
  React.PropsWithChildren<{ context: WidgetContext }>
>(function WidgetComponentLayoutBorderless({ children, context }) {
  const t = useTranslation();

  const [editMode] = useUrlParam("dashboard_edit", false);

  const hasSettings =
    context.type?.settingsComponent ||
    context.type?.dataItems ||
    context.type?.dataFetching;

  return (
    <WidgetContainer
      color={
        (context.widget.config?._style?.borderless || false) && !editMode
          ? "rgba(0,0,0,0)"
          : "white"
      }
      border={
        (context.widget.config?._style?.borderless || false) && !editMode
          ? "none"
          : "1px solid #d9d9d9"
      }
    >
      {editMode && (
        <WidgetActions>
          <Menu mode="horizontal">
            {context.widget.isOwner && (
              <Menu.Item
                key="delete"
                title={t("opendash:widgets.delete_modal_tooltip")}
                icon={<Icon icon="fa:trash" />}
                children={t("opendash:ui.delete")}
                onClick={(e) => {
                  context.store.update((state) => {
                    state.delete = true;
                  });
                }}
              />
            )}

            <Menu.Item
              key="reload"
              title={t("opendash:widgets.reload_tooltip")}
              icon={<Icon icon="fa:sync" />}
              children={t("opendash:widgets.reload")}
              onClick={(e) => {
                context.refresh();
              }}
            />

            {!context.widget.isReadOnly && (
              <Menu.Item
                disabled={!hasSettings}
                key="settings"
                title={
                  hasSettings
                    ? t("opendash:widgets.settings_modal_tooltip")
                    : t("opendash:widgets.settings_modal_tooltip_no_settings")
                }
                icon={<Icon icon="fa:cog" />}
                children={t("opendash:widgets.settings")}
                onClick={(e) => {
                  context.openSettings();
                }}
              />
            )}
          </Menu>
        </WidgetActions>
      )}

      <WidgetContent ref={context.containerRef}>{children}</WidgetContent>
    </WidgetContainer>
  );
});
