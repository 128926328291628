import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import duration from "dayjs/plugin/duration";
import objectSupport from "dayjs/plugin/objectSupport";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekday from "dayjs/plugin/weekday";
import {
  CronState,
  dayofmonthCheck,
  dayofweekCheck,
  monthCheck,
  QuartzFit,
  yearCheck,
} from "..";

dayjs.extend(objectSupport);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(duration);

/**
 * An iterator for cron expressions. Will return the next date in the sequence described by the cron string.
 *
 * @author Nico Vitt
 * @param value A QuartzObject
 * @param start The startdate of the cron expression range.
 * @param end The end date of the cron expression range.
 * @param unit The unit that the date is increased by (like 1 day or 1 second)
 */

export function* cronIterator(data: CronState) {
  //This is the difference between start and end (e.g. in days)
  let manipulatedDate = data.startdate;
  const range =
    typeof data.currentEnddate !== "undefined"
      ? data.currentEnddate.diff(data.startdate, data.diffunit)
      : dayjs({
          year: data.selectedYearInCalendar,
          month: data.selectedMonthInCalendar,
        })
          .add(1, "month")
          .endOf("month")
          .diff(data.startdate, data.diffunit);

  //Will iterate the amount of differences added to start
  for (let i = 0; i <= range; i++) {
    //Check if fits quartzobject
    //Currently ignore seconds, minutes, hours
    const fitsquartz: QuartzFit = {
      seconds: () => true,
      minutes: () => true,
      hours: () => true,
      dayofmonth: dayofmonthCheck,
      month: monthCheck,
      dayofweek: dayofweekCheck,
      year: yearCheck,
    };

    // console.log(
    //   Object.values(fitsquartz).map((entry) =>
    //     entry(data.quartzobject, manipulatedDate)
    //   )
    // );

    if (
      Object.values(fitsquartz).every(
        (entry) => !!entry(data.quartzobject, manipulatedDate)
      )
    ) {
      yield manipulatedDate;
    }
    manipulatedDate = manipulatedDate.add(1, data.diffunit);
  }
  return manipulatedDate;
}
