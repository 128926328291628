import React from "react";

import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Card, Typography } from "antd";
// import  { InfoCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;

function InfoMessage(props) {
  const t = useTranslation();
  let style = { width: "100%" };
  if (props.elementStyle) {
    style = Object.assign(style, props.elementStyle);
  }
  const state = props.Data;

  return (
    <Card
      title={state.title}
      extra={<Icon icon="fa:exclamation" />}
      bordered={true}
      style={style}
    >
      <p>{state.hint}</p>
    </Card>
  );
}
export default InfoMessage;
