import Parse from "parse";
import React from "react";

import { Button, Drawer, Select, Spin } from "antd";
import { useParseQuery } from "parse-hooks";
import styled from "styled-components";

import { useDebounce, useForceRender, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";

import { $parse } from "../../state";
import { getObjectTitle, translateClassName } from "../helper";
import { ParseObject } from "../ParseObject";

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

export interface PointerDisplayProps {
  value: Parse.Object;
}

export const PointerDisplay = React.memo<PointerDisplayProps>(
  function PointerDisplay({ value }) {
    const t = useTranslation();
    const forceRender = useForceRender();

    const schema = $parse.ui.getClassSchema(value.className);
    const config = $parse.ui.getClassConfig(value.className);

    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
      if (value) {
        const includes = (config?.displayFields || []).filter(
          (field) =>
            schema.fields[field]?.type === "Pointer" &&
            schema.fields[field]?.targetClass
        );

        value.fetchWithInclude(includes).then(
          () => {
            forceRender();
          },
          (e) => {
            console.error(e);
          }
        );
      }
    }, [value?.id]);

    const label = getObjectTitle(
      value,
      config,
      translateClassName(t, value.className)
    );

    return (
      <>
        <Button
          size="small"
          children={label}
          style={{ width: "100%" }}
          onClick={() => setVisible(true)}
        />

        <Drawer
          title={translateClassName(t, value.className)}
          open={visible}
          onClose={() => setVisible(false)}
          width={600}
        >
          <ParseObject object={value} disableObjectFetch={true} />
        </Drawer>
      </>
    );
  }
);

export interface PointerEditProps {
  value: Parse.Object;
  setValue?: (v: Parse.Object) => void;
  targetClass: string;
  isRequired: boolean;
  isNullable: boolean;
}

export const PointerEdit = React.memo<PointerEditProps>(function PointerEdit({
  value,
  setValue,
  targetClass,
}) {
  const t = useTranslation();

  const [searchString, setSearchString] = React.useState("");
  const searchStringDebounced = useDebounce(searchString, 500);

  const targetSchema = $parse.ui.schemaMap[targetClass];
  const targetFieldsFromConfig = $parse.ui.configMap[targetClass]?.titleFields;

  const targetFields = targetFieldsFromConfig;

  const query = React.useMemo(() => {
    if (!targetClass || !targetFields) {
      return null;
    }

    return Parse.Query.or(
      new Parse.Query(targetClass).equalTo("objectId", searchStringDebounced),
      ...targetFields.map((attribute) =>
        new Parse.Query(targetClass).matches(
          attribute,
          new RegExp(searchStringDebounced),
          "i"
        )
      )
    )
      .limit(10000)
      .ascending(targetFields[0]);
  }, [targetClass, targetFields, searchStringDebounced]);

  const { result, loading, reload } = useParseQuery(query);

  return (
    <Container>
      <Select
        showSearch
        style={{ flex: 1 }}
        placeholder={t("parse-admin:admin.inputs.pointer_placeholder")}
        notFoundContent={loading ? <Spin size="small" /> : null}
        value={value?.id}
        onChange={(nextValue) => {
          setValue(result.find((item) => item.id === nextValue) || null);
        }}
        onSearch={(newSearchString) => {
          setSearchString(newSearchString);
        }}
        filterOption={false}
        allowClear
      >
        {value?.id && (
          <Select.Option
            key={value.id}
            value={value.id}
            children={targetFields
              .map((attribute) => value.get(attribute))
              .join(", ")}
          />
        )}
        {result
          .filter((item) => item.id !== value?.id)
          .map((item) => (
            <Select.Option
              key={item.id}
              value={item.id}
              children={targetFields
                .map((attribute) => item.get(attribute))
                .join(", ")}
            />
          ))}
      </Select>
      {targetSchema.evaluatedClassLevelPermissions?.create && (
        <Button
          icon={<Icon icon="fa:plus" />}
          onClick={async () => {
            const id = await $parse.ui.createObject(targetClass);

            await reload();

            const object = new Parse.Object(targetClass, { id });

            setValue(object);
          }}
        />
      )}
    </Container>
  );
});
