import { produce, useTranslation } from "@opendash/core";
import { useParams } from "@opendash/router";
import React, { useEffect, useState } from "react";
import MassEditModal from "./MassEditModal";
import SensorDetailModal from "./SensorDetailModal";

import { Icon } from "@opendash/icons";
import { Button, Input, Space, Switch, Table, Tooltip, Typography } from "antd";

import {
  DataItemInterface,
  DataItemValueDisplay,
  DataItemValueInterface,
  useDataService,
} from "@opendash/plugin-timeseries";
import MassDeleteModal from "./MassDeleteModal";

const { Search } = Input;
const { Text } = Typography;
type SensorConfigProps = {
  items: (DataItemInterface & { values: DataItemValueInterface[] })[];
  updateItem: (
    idsource: string,
    newItem: DataItemInterface & { values: DataItemValueInterface[] },
    deleteItem?: boolean
  ) => void;
};

export default (props) => {
  const DataService = useDataService();
  const innerParams = useParams();
  const [detail, setDetail] = useState({
    item: null,
  });
  const itemConfigs = props.items;

  const [availableItems, setAvailableItems] = useState<DataItemInterface[]>([]);
  const [massEdit, setMassEdit] = useState<DataItemInterface[]>([]);
  const [massDelete, setMassDelete] = useState<DataItemInterface[]>([]);
  const [selection, setSelection] = useState<DataItemInterface[]>([]);
  const [paginationState, setPaginationState] = useState<{
    current: number;
    pageSize: number;
  }>({
    current: 1,
    pageSize: 25,
  });

  const initItems = async () => {
    const items = (await DataService.list()).map((item) => {
      return produce(item, (draft) => {
        draft.meta = itemConfigs.find((cItem) => {
          return cItem.id === item.id && cItem.source === item.source;
        })?.meta || {
          ...draft.meta,
          ...{
            active: true,
            visible: true,
            onChange: false,
            source_source: item.source,
            id_source: item.id,
          },
        };
        return draft;
      });
    });
    for (const conf of itemConfigs) {
      if (
        !items.find(
          (item) => item.id === conf.id && item.source === conf.source
        )
      ) {
        conf.meta = {
          ...{
            active: true,
            visible: true,
            onChange: false,
            source_source: conf.source,
            id_source: conf.id,
          },
          ...conf.meta,
        };
        items.push(conf);
      }
    }
    setAvailableItems(items);
  };
  useEffect(() => {
    initItems();
  }, [itemConfigs]);

  const updateItem = props.updateItem;
  let detailModal: React.ReactNode;
  const [filter, setFilter] = useState<any>({});
  const t = useTranslation();

  const modalClose = () => {
    setDetail(
      produce(detail, (draft) => {
        delete draft.item;
      })
    );
  };

  const modalSave = async (
    idsource,
    newItem,
    deleteItem?,
    hideNotifcation?
  ) => {
    const x = await updateItem(idsource, newItem, deleteItem, hideNotifcation);
    setDetail(
      produce(detail, (draft) => {
        delete draft.item;
      })
    );
    return x;
  };
  if (detail.item) {
    detailModal = (
      <SensorDetailModal
        item={detail.item}
        onClose={modalClose}
        onSave={modalSave}
      />
    );
  }

  const columns = [
    {
      title: t("openware:sensor.admin.name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b, sortorder) => {
        return a.name.localeCompare(b.name);
      },
      render: (text, row, index) => {
        return (
          <Space direction="vertical" size="small">
            <a
              key="recordlink"
              onClick={() => {
                setDetail(
                  produce(detail, (draft) => {
                    draft.item = row;
                  })
                );
              }}
            >
              {text}
            </a>
            <Typography.Paragraph
              key="recordtype"
              type="secondary"
              style={{ marginBottom: "0px" }}
            >
              {row.valueTypes
                .map((type) => type.name)
                .join(", ")
                .substring(0, 100)}
            </Typography.Paragraph>
          </Space>
        );
      },
    },
    {
      title: t("openware:sensor.admin.sourcepick"),
      dataIndex: "source",
      width: "10%",
      key: "source",
      sorter: (a, b, sortorder) => {
        return a.user.localeCompare(b.user);
      },
      render: (text, row, index) => {
        return <Typography.Text copyable={true}>{row.source}</Typography.Text>;
      },
    },
    {
      title: t("openware:sensor.admin.lastmessage"),
      dataIndex: "data",
      width: "25%",
      align: "center" as const,
      key: "date",
      sorter: (a, b, sortorder) => {
        return (
          DataService._getValueOrThrowSync(a).date -
          DataService._getValueOrThrowSync(b).date
        );
      },
      render: (text, row, index) => {
        return (
          <Space direction="vertical" size="small">
            {row.valueTypes.map((answer, i) => {
              return (
                <div key={i + "valuedisplay"}>
                  <DataItemValueDisplay item={row} dimension={i} />

                  {row.valueTypes.length > 1 && i < row.valueTypes.length - 1
                    ? " " + "|" + " "
                    : ""}
                </div>
              );
            })}
            <Typography.Paragraph
              type="secondary"
              style={{ marginBottom: "0px" }}
            >
              <DataItemValueDisplay item={row} dimension={"date"} />
            </Typography.Paragraph>
          </Space>
        );
      },
    },
    {
      title: (
        <Tooltip title={t("openware:sensor.admin.savevalues")}>
          <Icon icon="fa:save" />
        </Tooltip>
      ),
      dataIndex: "active",
      width: "15px",
      key: "active",
      render: (text, row, index) => {
        let active = true;
        if ("active" in row.meta) {
          active = !!row.meta.active;
        }

        return (
          <Tooltip title={t("openware:sensor.admin.savevalues")}>
            <Switch
              size="small"
              checked={active}
              onChange={(checked) => {
                const item = produce(row, (draft) => {
                  draft.meta.active = checked;
                });
                modalSave(row.meta.id_source, item);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Tooltip title={t("openware:sensor.admin.showvaluesindashboard")}>
          <Icon icon="fa:eye" />
        </Tooltip>
      ),
      dataIndex: "visible",
      width: "15px",
      key: "visbile",
      render: (text, row, index) => {
        return (
          <Tooltip title={t("openware:sensor.admin.showvaluesindashboard")}>
            <Switch
              size="small"
              checked={row.meta.visible}
              onChange={(checked) => {
                const item = produce(row, (draft) => {
                  draft.meta.visible = checked;
                });
                modalSave(row.meta.id_source, item);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Tooltip title={t("openware:sensor.admin.saveonlychanges")}>
          <Icon icon="fa:chart-line" />
        </Tooltip>
      ),
      dataIndex: "onchange",
      width: "15px",
      key: "onchange",
      render: (text, row, index) => {
        return (
          <Tooltip title={t("openware:sensor.admin.saveonlychanges")}>
            <Switch
              size="small"
              checked={row.meta.onChange}
              onChange={(checked) => {
                const item = produce(row, (draft) => {
                  draft.meta.onChange = checked;
                });
                modalSave(row.meta.id_source, item);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Tooltip title={t("openware:sensor.admin.resetconfig")}>
          <Icon icon="fa:undo" />
        </Tooltip>
      ),
      dataIndex: "delete",
      width: "15px",
      key: "delete",
      render: (text, row, index) => {
        return (
          <Button
            danger
            type="link"
            disabled={
              !itemConfigs.find((item) => {
                return item.id === row.id && item.source === row.source;
              })
            }
            onClick={() => {
              modalSave(row.meta.id_source, row, true);
            }}
          >
            <Icon icon="fa:undo" />
          </Button>
        );
      },
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   width: "10%",
    //   key: "id",
    //   render: (text, row, index) => {
    //     return (
    //       <Text
    //         type="secondary"
    //         ellipsis={{ tooltip: row.id }}
    //         style={{ overflow: "hidden", width: 100 }}
    //       >
    //         {row.id}
    //       </Text>
    //     );
    //   },
    // },
  ];

  return (
    <div className="sensortable">
      <Search
        placeholder="Filter..."
        onChange={(val) => {
          const text = val.target.value.toLowerCase();
          setFilter({ text });
        }}
        style={{
          width: "calc(100% - 20px)",
          marginLeft: "10px",
          marginRight: "10px",
          marginBottom: "10px",
        }}
      />

      <Table
        rowKey={(record: DataItemInterface) => record.id}
        size="middle"
        pagination={{
          pageSize: paginationState.pageSize,
          position: ["bottomRight"],
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50", "100", "1000"],
          onChange: (page, pageSize) => {
            setPaginationState({
              current: page,
              pageSize: pageSize,
            });
          },
          current: paginationState.current,
        }}
        rowSelection={{
          onSelect: (record, selected, selectedRows, nativeEvent) => {
            console.log({ record, selected, selectedRows, nativeEvent });
            setSelection(selectedRows);
          },
          onSelectAll: (selected, selectedRows, changeRows) => {
            console.log({ selected, selectedRows, changeRows });
            setSelection(selectedRows);
          },
        }}
        /*
        expandable={{
          expandedRowRender: (record) => {
            const items = record.valueTypes.map((vType, idx) => {
              return {
                key: idx,
                label: vType.name,
                span: 3,
                children: (
                  <DataItemValueDisplay
                    item={record}
                    dimension={idx}
                  ></DataItemValueDisplay>
                ),
              };
            });
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  padding: "16px",
                }}
              >
                <Typography.Text
                  type="secondary"
                  copyable={true}
                  style={{ textAlign: "right" }}
                >
                  {record.id}
                </Typography.Text>
                <Descriptions items={items} bordered size="small" />
              </div>
            );
          },
        }}*/
        dataSource={availableItems
          .filter((item) => {
            if (filter.text && filter.text.includes("unit=")) {
              let treffer = false;
              const unitFilterText = filter.text
                .replace("unit=", "")
                .toLocaleLowerCase();
              item.valueTypes.forEach((e) => {
                if (e.unit.toLocaleLowerCase() == unitFilterText) {
                  treffer = true;
                }
              });
              return treffer;
            } else if (filter.text && filter.text.includes("name=")) {
              let treffer = false;
              const nameFilterText = filter.text
                .replace("name=", "")
                .toLocaleLowerCase();
              item.valueTypes.forEach((e) => {
                if (e.name.toLocaleLowerCase() == nameFilterText) {
                  treffer = true;
                }
              });
              return treffer;
            } else if (filter.text && filter.text.includes("contains=")) {
              let treffer = false;
              const innameFilterText = filter.text
                .replace("contains=", "")
                .toLocaleLowerCase();
              item.valueTypes.forEach((e) => {
                console.log(
                  e.name.toLocaleLowerCase().indexOf(innameFilterText)
                );
                if (
                  e.name.toLocaleLowerCase().indexOf(innameFilterText) !== -1
                ) {
                  treffer = true;
                }
              });
              return treffer;
            } else {
              return (
                JSON.stringify(item)
                  .toLocaleLowerCase()
                  .indexOf(filter.text || "") !== -1
              );
            }
          })
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          })}
        columns={columns}
      />
      {selection.length >= 1 && (
        <div style={{ display: "flex", gap: 8 }}>
          <Button
            onClick={() => {
              setMassEdit(selection);
            }}
            icon={<Icon icon="fa:cog" />}
          >
            {t("openware:admin.sensors.massEdit.title", {
              count: selection.length,
            })}
          </Button>

          <Button
            onClick={() => {
              setMassDelete(selection);
            }}
            icon={<Icon icon="fa:trash" />}
            danger
          >
            {t("openware:admin.sensors.massDelete.title", {
              count: selection.length,
            })}
          </Button>
        </div>
      )}

      {detailModal}
      <MassEditModal
        items={massEdit}
        onClose={() => setMassEdit([])}
        onAfterSave={() => {
          modalSave(null, null);
        }}
        save={modalSave}
      />
      <MassDeleteModal
        items={massDelete}
        onClose={() => setMassDelete([])}
      ></MassDeleteModal>
    </div>
  );
};

//export default SensorConfiguration;
