import { useTranslation } from "@opendash/i18n";
import { Segmented } from "antd";
import React from "react";

type CustomFormItemProps = {
  value?: number;
  onChange?: (value: number) => void;
};

export const TimeSeries: React.FC<CustomFormItemProps> = ({
  value,
  onChange,
}) => {
  const t = useTranslation();

  return (
    <>
      <Segmented<number>
        options={[
          { label: t("maintenance:timeseries.pattern.radio.once"), value: 0 },
          {
            label: t("maintenance:timeseries.pattern.radio.daily"),
            value: 1,
          },
          {
            label: t("maintenance:timeseries.pattern.radio.weekly"),
            value: 2,
          },
          {
            label: t("maintenance:timeseries.pattern.radio.monthly"),
            value: 3,
          },
          {
            label: t("maintenance:timeseries.pattern.radio.yearly"),
            value: 4,
          },
        ]}
        value={value}
        onChange={(value) => {
          onChange(value);
        }}
      />
    </>
  );
};
