import React, { useMemo } from "react";

import { Parse, useParseQueryFirst } from "parse-hooks";
import {
  AdminLayout,
  Loading,
  useSource,
  usePermission,
  LinkedSourcePicker,
  useTranslation,
} from "@opendash/core";

import GreisOperatingHoursSelect from "./GreisOperatingHoursSelect";
import { Divider } from "antd";

const OperatingService = "HG_Runtime";

export default function GreisOperating() {
  const [source] = useSource();
  const t = useTranslation();
  console.log(source);
  const serviceQuery = useMemo(
    () =>
      source?.tag
        ? new Parse.Query(OperatingService).equalTo("spsid", source.tag)
        : null,
    [source]
  );

  const parseUser = Parse.User.current();
  const parseUserItem = parseUser;
  const serviceConfig = useParseQueryFirst(serviceQuery);
  const isAdmin = usePermission("hg:runtime-monitor");

  if (serviceConfig.loading) {
    return <Loading message="" />;
  }

  return (
    <GreisOperatingHoursSelect
      spsid={source ? source.tag : undefined}
      admin={isAdmin}
      config={serviceConfig}
      user={parseUserItem}
      name={source ? source.name : ""}
    />
  );
}
