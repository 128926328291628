import { safeStringEncode } from "@opendash/core";
import dayjs from "dayjs";
import produce from "immer";
import {
  Aggregation,
  DataFetchingEvaluatedOptionsInterface,
  DataFetchingOptionsInterface,
} from "..";

export function evaluateDataFetchingOptions(
  option: DataFetchingOptionsInterface,
  itemKey: string
): DataFetchingEvaluatedOptionsInterface {
  const option2 = produce(option, (draft) => {
    if (draft.historyType === "relative") {
      /*
      if (draft.unit === "week") {
        // TODO
        // use this for dayjs.js untill we implement user settings
        // for start of week
        // @ts-ignore
        draft.unit = "isoWeek";
      }
*/
      draft.historyType = "absolute";

      draft.end = draft.historyRelativeSnap
        ? dayjs().endOf(draft.unit).valueOf() + 1
        : dayjs().endOf("minute").valueOf() + 1;

      if (draft.historyRelativeOffset) {
        draft.end = dayjs(draft.end)
          .add(draft.value * draft.historyRelativeOffset, draft.unit)
          .valueOf();
      }

      draft.start = dayjs(draft.end)
        .subtract(draft.value, draft.unit)
        .valueOf();

      draft.value = undefined;
      draft.unit = undefined;
      draft.historyRelativeSnap = undefined;
    }

    if (draft.aggregationSplits) {
      draft.aggregationPots = Aggregation.createPotsFromSplits(
        draft.start,
        draft.end,
        draft.aggregationSplits
      );

      //draft.aggregationSplits = undefined;
    }

    if (draft.aggregationSteps) {
      // const splits = dayjs(draft.start).diff(
      //   draft.end,
      //   draft.aggregationSteps,
      //   true
      // );

      // if (Number.isInteger(splits)) {
      //   draft.aggregationSplits = splits;
      // } else {
      //   console.warn(
      //     "DataFetchingOptionsInterface.aggregationSteps is ignored, as the resulting value for aggregationSplits is not an integer."
      //   );
      // }

      draft.aggregationPots = Aggregation.createPotsFromUnit(
        draft.start,
        draft.end,
        draft.aggregationSteps
      );

      draft.aggregationSteps = undefined;
    }

    if (draft.aggregationGroup) {
      draft.aggregationPots = Aggregation.createPotsFromUnitGrouped(
        draft.start,
        draft.end,
        draft.aggregationGroup
      );

      draft.aggregationGroup = undefined;
    }
    if (draft.aggregationDateUnit) {
      draft.aggregationPots = undefined;
      draft.aggregationSteps = undefined;
      draft.aggregationGroup = undefined;
    }

    if (
      !draft.aggregation //||
      //!draft.aggregationOperation ||
      //!draft.aggregationPots
    ) {
      draft.aggregation = undefined;
      draft.aggregationPots = undefined;
      draft.aggregationOperation = undefined;
    }

    if (
      !draft.resolution ||
      !draft.resolutionMode ||
      !Number.isInteger(draft.resolutionMaxValues)
    ) {
      draft.resolution = undefined;
      draft.resolutionMode = undefined;
      draft.resolutionMaxValues = undefined;
    } else {
      draft.resolutionMaxValues = Math.abs(draft.resolutionMaxValues);
    }
  });

  const result: DataFetchingEvaluatedOptionsInterface = {
    start: option2.start,
    end: option2.end,
    limit: option2.limit,
    includeLowerBound: option2.includeLowerBound,
    includeUpperBound: option2.includeUpperBound,

    resolution: !!option2.resolution,
    resolutionMode: option2.resolutionMode,
    resolutionMaxValues: option2.resolutionMaxValues,

    aggregation: !!option2.aggregation,
    aggregationPots: option2.aggregationPots,
    aggregationSplits: option2.aggregationSplits,
    aggregationDateUnit: option2.aggregationDateUnit,
    aggregationOperation: option2.aggregationOperation,
    aggregationSteps: option2.aggregationSteps,
    aggregationPipe: option2.aggregationPipe,
  };

  const encodedKey = safeStringEncode(itemKey);
  if (option2.filter && option2.filter[encodedKey]) {
    result.filter = option2.filter[encodedKey];
  }

  // TODO: Remove console.log
  //console.log("evaluateDataFetchingOptions", option, result);

  return result;
}
