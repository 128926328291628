import {
  compare,
  createInternalComponent,
  useElementSize,
  useTranslation,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import {
  DataItemChangedRelative,
  DataItemInterface,
  DataItemValueDisplay,
  useDataItems,
  useDataService,
} from "@opendash/plugin-timeseries";
import { Button, Drawer, Input, Typography } from "antd";
import * as React from "react";
import { FixedSizeList as List } from "react-window";
import { AlarmModalToggle, DataSidebarDetails } from "..";
import {
  Container,
  FilterContainer,
  TableCellAction,
  TableCellDate,
  TableCellFull,
  TableCellName,
  TableCellValue,
  TableContainer,
  TableRowData,
  TableRowHeader,
  TableRowLabels,
  rowHeight,
} from "./DataSidebar.layout";

interface Props {}

export const DataSidebarTable = createInternalComponent<Props>(
  function DataSidebarTable({}) {
    const t = useTranslation();
    const [searchString, setSearchString] = React.useState("");
    const [details, setDetails] = React.useState<
      [DataItemInterface, number] | null
    >(null);

    const containerRef = React.useRef<HTMLDivElement>();
    const containerSize = useElementSize(containerRef);

    const isMobile = containerSize.width < 600;
    const className = isMobile ? "mobile" : undefined;

    const DataService = useDataService();

    const allItems = useDataItems();

    const allItemDimensions = React.useMemo(() => {
      return allItems.flatMap((item) =>
        item.valueTypes.map(
          (valueType, dimension) =>
            [item, dimension] as [DataItemInterface, number]
        )
      );
    }, [allItems]);

    const items = React.useMemo(() => {
      if (searchString) {
        return DataService.searchItemDimensions(
          searchString,
          allItemDimensions
        );
      }

      return allItemDimensions;
    }, [allItemDimensions, searchString]);

    const rows = React.useMemo(() => {
      const groupMap: Record<
        string,
        {
          key: string;
          label: string;
          data: [DataItemInterface, number][];
        }
      > = {};

      for (const [item, dimension] of items) {
        const group = DataService.getItemName(item);

        if (!groupMap[group]) {
          const key = DataService.getItemKey(item);
          const label = DataService.getItemName(item);

          groupMap[group] = { key, label, data: [] };
        }

        groupMap[group].data.push([item, dimension]);
      }

      const groups = Object.values(groupMap).sort(
        compare((group) => group.label)
      );

      const result: any[] = [];

      for (const { key, label, data } of groups) {
        result.push({
          key: "header~" + key,
          type: "header",
          label: label,
          count: data.length,
        });

        result.push({
          key: "label~" + key,
          type: "label",
        });

        let i = 0;

        for (const [item, dimension] of data) {
          result.push({
            type: "data",
            source: item.source,
            className: i++ % 2 === 0 ? "even" : "odd",
            id: item.id,
            dimension,
            item: item,
            key: "data~" + DataService.getItemKey(item, dimension),
            name: DataService.getItemName(item, dimension, true),
          });
        }
      }

      return result;
    }, [items]);

    const TableRow = ({ index, style }) => {
      const row = rows[index];

      if (row.type === "header") {
        return (
          <TableRowHeader key={row.key} style={style}>
            <TableCellFull className={className}>
              {row.label} ({row.count})
            </TableCellFull>
          </TableRowHeader>
        );
      }

      if (row.type === "label") {
        return (
          <TableRowLabels key={row.key} style={style}>
            <TableCellName className={className}>
              {t("opendash:monitoring.data_sidebar.col_name")}
            </TableCellName>
            <TableCellValue className={className}>
              {t("opendash:monitoring.data_sidebar.col_value")}
            </TableCellValue>
            <TableCellDate className={className}>
              {t("opendash:monitoring.data_sidebar.col_date")}
            </TableCellDate>
            <TableCellAction className={className}></TableCellAction>
          </TableRowLabels>
        );
      }

      if (row.type === "data") {
        return (
          <TableRowData key={row.key} style={style} className={row.className}>
            <TableCellName className={className}>
              <Typography.Text ellipsis={{ tooltip: row.name }}>
                {row.name}
              </Typography.Text>
            </TableCellName>
            <TableCellValue
              className={
                className
                  ? className + " sidebar-value-cell"
                  : "sidebar-value-cell"
              }
            >
              <DataItemValueDisplay item={row.item} dimension={row.dimension} />
            </TableCellValue>
            <TableCellDate className={className}>
              <DataItemChangedRelative item={row.item} />
            </TableCellDate>
            <TableCellAction className={className}>
              {isMobile && (
                <Button
                  type="text"
                  size="small"
                  icon={<Icon icon="fa:chevron-right" />}
                  onClick={() => {
                    setDetails([row.item, row.dimension]);
                  }}
                />
              )}
              {!isMobile && (
                <AlarmModalToggle
                  item={row.item}
                  dimension={row.dimension}
                  buttonProps={{ size: "small" }}
                />
              )}
            </TableCellAction>
          </TableRowData>
        );
      }

      return null;
    };

    return (
      <Container>
        <FilterContainer>
          <Input.Search
            placeholder={t("opendash:ui.search_enter_placeholder")}
            onSearch={(value) => {
              setSearchString(value);
            }}
          />
        </FilterContainer>
        <TableContainer ref={containerRef}>
          <List
            height={containerSize.height}
            width={containerSize.width}
            itemCount={rows.length}
            itemSize={rowHeight}
            children={TableRow}
          />
        </TableContainer>

        <Drawer
          title={details && DataService.getItemName(...details)}
          open={!!details}
          onClose={(e) => setDetails(null)}
        >
          {!!details && <DataSidebarDetails details={details} />}
        </Drawer>
      </Container>
    );
  }
);
