import {
  AppFactory,
  NavigationPlaceInterface,
  useFeedback,
  useTranslation,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import Parse from "parse";
import React, { useEffect, useState } from "react";
import { Form, Unit } from "../../../parse";

export default observer((props: { form: any; factory: AppFactory }) => {
  const t = useTranslation();
  const { message } = useFeedback();
  const [reload, setReload] = useState<number>(0);
  const [existingUnits, setExistingUnits] = useState<Array<Unit>>([]);
  // const [activeMenuItems, setActiveMenuItems] = useState<Array<NavigationItem>>(
  //   []
  // );
  const [form, setForm] = useState<Form>(undefined);

  //Link is /bde/form/{unit}/{form}/0?refence=null
  const [link, setLink] = useState<string>("");

  useEffect(() => {
    async function setup() {
      try {
        const parseform = await new Parse.Query(Form).get(props.form.objectid);
        setForm(parseform);

        const innerQuery = new Parse.Query(Form).equalTo(
          "objectId",
          parseform.id
        );

        //Search units for this form
        const units = await new Parse.Query(Unit)
          .matchesQuery("forms", innerQuery)
          .find();

        if (units.length === 0) {
          return [];
        }

        const tmplink = `/bde/formshortcut/${units[0].id}/${props.form.objectid}/0?reference=null`;

        //Get all menu items with this exact link
        // const menuitems = await new Parse.Query(NavigationItem)
        //   .equalTo("link", tmplink)
        //   .find();

        setLink(tmplink);
        setExistingUnits(units);
        // setActiveMenuItems(menuitems);
      } catch (e) {}
    }
    setup();
  }, [props.form, reload]);

  const pinAsMenuItem = async () => {
    if (!existingUnits || existingUnits.length === 0) {
      message.warning(t("bde:app.createform.not_assigned_to_unit"));
    }

    if (existingUnits.length >= 2) {
      //TODO Open select unit modal
    }

    if (isActiveMenuItem()) {
      //Menuitem already exists for this form.
      //Set undefined
      form.set("pinOnFrontpageLink", "");
      await form.save();
      message.success(t("bde:app.saved_ping"));

      props.factory.deregisterStaticNavigationItem(form.id);
    } else {
      form.set("pinOnFrontpageLink", link);
      await form.save();
      message.success(t("bde:app.saved_ping"));

      props.factory.registerStaticNavigationItem({
        id: form.id,
        group: "admin/bde",
        place: "frontpage" as NavigationPlaceInterface,
        order: 175 + Math.round(Math.random() * 25),
        label: form.get("name"),
        icon: "fa:plus",
        permission: "bde:can-see-app",
        link: form.get("pinOnFrontpageLink"),
        routeCondition: "",
        activeCondition: "",
        color: "var(--opendash-colors-primary)",
      });
    }

    setReload((currVal) => currVal + 1);
  };

  const isActiveMenuItem = (): boolean => {
    if (typeof form === "undefined") return false;

    return (
      typeof form.get("pinOnFrontpageLink") !== "undefined" &&
      form.get("pinOnFrontpageLink").length > 0
    );
  };

  return (
    <Tooltip title={t("bde:pin_to_start")}>
      <Button
        disabled={existingUnits.length === 0 || existingUnits.length > 1}
        type={isActiveMenuItem() ? "primary" : "default"}
        onClick={() => pinAsMenuItem()}
        icon={<Icon icon="fa:map-pin"></Icon>}
      />
    </Tooltip>
  );
});
