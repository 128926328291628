import * as React from "react";
import { useEffect } from "react";
import { SourceInterface, useForceRender, useOpenDashServices } from "..";

type useSourceResult = [
  SourceInterface,
  (input: string) => void,
  SourceInterface[],
  boolean,
  boolean,
];

/**
 * @return
 *    1. currently selected source as `SourceInterface`
 *    2. callback to set the current source
 *    3. all sources as `SourceInterface[]`
 *    4. loading state
 *    5. enabled state
 *
 */
export function useSource(): useSourceResult {
  const { SourceService } = useOpenDashServices();

  const forceRender = useForceRender();

  useEffect(() => {
    return SourceService.subscribe(() => {
      forceRender();
    });
  }, []);

  return [
    SourceService.getCurrent(),
    React.useCallback((input: string) => {
      SourceService.setCurrent(input);
    }, []),
    SourceService.getAll(),
    SourceService.isLoading(),
    SourceService.isEnabled(),
  ] as [
    SourceInterface,
    (input: string) => void,
    SourceInterface[],
    boolean,
    boolean,
  ];
}
