import { makeAutoObservable, runInAction } from "@opendash/state";
import { AutocompleteResultType } from "../types";

import type { GeoService } from "./Geo";

export class AutocompleteService {
  public loading: boolean = false;
  public done: boolean = false;
  public results: AutocompleteResultType[] = [];

  public query: string = "";

  private service: GeoService;

  constructor(service: GeoService) {
    makeAutoObservable(this);

    this.service = service;
  }

  get options() {
    return this.results.map((result) => ({
      value: result.titleFull,
      label: result.titleFull,
      result,
    }));
  }

  setQuery(query: string) {
    this.query = query;
    this.loading = true;
    this.done = false;

    this.service
      .autocomplete(query)
      .then((result) => {
        runInAction(() => {
          this.results = result;
          this.loading = false;
          this.done = true;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
