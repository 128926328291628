import { DeleteOutlined } from "@ant-design/icons";
import { produce, useTranslation } from "@opendash/core";
import { Button, Input, Select, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { ItemAttributes } from "..";
import { configProvider } from "../../..";

const { TabPane } = Tabs;
const { Option } = Select;

export default observer((props: { field; updateField }) => {
  const t = useTranslation();
  const [acState, setAcState] = React.useState({
    type: props.field.list ? 2 : 1,
    options: props.field.items || [],
    selectedList: props.field.list,
    lists: [],
  });
  React.useEffect(() => {
    init();
  }, []);
  const init = async () => {
    const x = new Parse.Query(configProvider.config.lists.table);
    x.limit(999999999);
    try {
      const result = await x.find();
      setAcState(
        produce((draft) => {
          draft.lists = result.map((lItem) => {
            return lItem.toJSON();
          });
        })
      );
    } catch (e) {
      console.error(e);
    }
  };
  React.useEffect(() => {
    setAcState(
      produce((draft) => {
        draft.options = props.field.items || [];
      })
    );
  }, [props.field.items]);

  const checkerSettings = (
    <div>
      <Tabs
        defaultActiveKey={"" + acState.type}
        onChange={(e) => {
          setAcState(
            produce((draft) => {
              draft.type = parseInt(e);
              props.updateField((state) => {
                if (draft.type !== 2) {
                  state.list = void 0;
                } else {
                  state.list = acState.selectedList;
                }
              });
            })
          );
        }}
      >
        <TabPane tab={t("bde:fields.label_customList")} key="1">
          <div className="od_admin_bde_input">
            {acState.options.map((element, index) => {
              return (
                <div key={index} style={{ display: "block" }}>
                  <Input
                    style={{ display: "inline-block", width: "30%" }}
                    addonBefore={t("bde:fields.label_optionKey")}
                    value={element.value}
                    onChange={(e) => {
                      props.updateField((state) => {
                        state.items[index].value = e.target.value;
                      });
                    }}
                  />
                  <Input
                    style={{ display: "inline-block", width: "50%" }}
                    addonBefore={t("bde:fields.label_optionLabel")}
                    value={element.label}
                    onChange={(e) => {
                      props.updateField((state) => {
                        state.items[index].label = e.target.value;
                      });
                    }}
                  />
                  <Button
                    type="default"
                    style={{ display: "inline-block" }}
                    onClick={(e) => {
                      props.updateField((state) => {
                        state.items.splice(index, 1);
                      });
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </div>
              );
            })}
            <Button
              type="dashed"
              style={{ display: "block", marginTop: "10px" }}
              onClick={(e) => {
                props.updateField((state) => {
                  const x = [...acState.options];
                  x.push({ value: "", label: "" });
                  setAcState(
                    produce((draft) => {
                      draft.options = x;
                    })
                  );
                  state.items = x;
                });
              }}
            >
              + {t("bde:fields.label_addOptionEnty")}
            </Button>
          </div>
        </TabPane>
        <TabPane tab={t("bde:fields.label_existingList")} key="2">
          <Select
            style={{ width: "100%" }}
            placeholder={t("bde:fields.label_chooseExistingList")}
            value={acState.selectedList}
            onChange={(value) => {
              setAcState(
                produce((draft) => {
                  draft.selectedList = value;
                })
              );
              props.updateField((state) => {
                state.list = value;
              });
            }}
          >
            {acState.lists.map((item) => {
              return <Option value={item.objectId}>{item.list}</Option>;
            })}
          </Select>
        </TabPane>
      </Tabs>
      {ItemAttributes(props)}
    </div>
  );
  return <div>{checkerSettings}</div>;
});
