import { useTranslation } from "@opendash/core";
import { Form, Select } from "antd";
import Parse from "parse";
import React from "react";
import { configProvider } from "../../..";

function AutoComplete(props) {
  const t = useTranslation();
  let style = {};
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  const state = props.Data;
  const setValue = props.SetValue;
  const options = state.options || [];
  const [items, setItems] = React.useState(state.items || []);
  const rules = [];
  const initList = async () => {
    try {
      const listObj = new Parse.Object(configProvider.config.lists.table);
      listObj.id = state.list;
      const listOptions = await new Parse.Query(
        configProvider.config.lists.children
      )
        .equalTo("list", listObj)
        .limit(999999)
        .find();
      setItems(
        listOptions.map((parseOption) => {
          return {
            value: parseOption.get("key"),
            label: parseOption.get("value"),
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  React.useEffect(() => {
    if (state.list) {
      initList();
    }
  }, []);

  if (state["v_required"] && state["v_required"].value) {
    rules.push({
      required: t("bde:app.forms.common.required"),
      message: t("bde:app.forms.common.error"),
    });
  }
  const mergedStyle = { ...{ heigt: "10em" }, ...style };

  return (
    <Form.Item
      name={state.key}
      label={state.title}
      rules={rules}
      style={{ marginBottom: "24px" }}
    >
      <Select
        className="bde-autocomplete"
        showSearch
        //tyle={mergedStyle}
        options={items}
        listHeight={300}
        filterOption={(input, option) =>
          JSON.stringify(option).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    </Form.Item>
  );
}
export default AutoComplete;
