import {
  createInternalComponent,
  produce,
  useTranslation,
  uuid,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Tree } from "antd";
import * as React from "react";
import styled from "styled-components";
import {
  DataFetchingFilterInterface,
  DataFetchingFilterRule,
  DataItemDimensionIdentifierInterface,
  DataItemIdentifierInterface,
} from "..";

const WrapperDiv = styled.div`
  margin-bottom: 20px;

  .ant-tree-switcher {
    display: none;
  }

  .ant-tree-node-content-wrapper {
    padding: 0;
    border-radius: 0;
    :hover {
      background: transparent;
    }
  }
`;

const ConditionDiv = styled.div`
  margin-bottom: 10px;
`;

const ConditionTypeDiv = styled.div`
  display: flex;
`;
const ConditionTypeLeftDiv = styled.div`
  display: flex;
  gap: 3px;
  flex: 1;
`;

const ConditionTypeRightDiv = styled.div`
  display: flex;
  gap: 3px;

  margin-left: auto;
`;
const ConditionRuleDiv = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 5px;
  margin-top: 5px;
`;

type Condition = DataFetchingFilterInterface;

type Props =
  | {
      mode: "item";
      triggerItem: DataItemIdentifierInterface;
      value: Condition;
      onChange(value: Condition): void;
    }
  | {
      mode: "dimension";
      triggerItem: DataItemDimensionIdentifierInterface;
      value: Condition;
      onChange(value: Condition): void;
    };

function getDefaultRuleCondition(
  triggerItem:
    | DataItemIdentifierInterface
    | DataItemDimensionIdentifierInterface
): Condition {
  const [source, id, dimension] = triggerItem;

  return {
    key: uuid(),
    type: "rule",
    source,
    id,
    dimension,
    // @ts-ignore
    rule: {},
  };
}

export const DataFetchingFilter = createInternalComponent<Props>(
  function DataFetchingFilter({ mode, triggerItem, value, onChange }) {
    const t = useTranslation();
    const expandedKeys = React.useMemo(() => getKeys(value), [value]);

    const isRoot = React.useCallback(
      (key: string) => {
        return value?.key === key;
      },
      [value]
    );

    const resetCondition = React.useCallback(() => {
      onChange(getDefaultRuleCondition(triggerItem));
    }, [value]);

    const updateCondition = React.useCallback(
      (key: string, update: Partial<Condition>) => {
        onChange(
          produce(value, (draft) => {
            updateConditionRecursive(draft, key, update);
          })
        );
      },
      [value]
    );

    const deleteCondition = React.useCallback(
      (key: string) => {
        if (isRoot(key)) {
          onChange(undefined);
        } else {
          onChange(
            produce(value, (draft) => {
              deleteConditionRecursive(draft, key);
            })
          );
        }
      },
      [value]
    );

    const addCondition = React.useCallback(
      (key: string) => {
        onChange(
          produce(value, (draft) => {
            addConditionRecursive(
              draft,
              key,
              getDefaultRuleCondition(triggerItem)
            );
          })
        );
      },
      [value]
    );

    if (!value) {
      return (
        <WrapperDiv>
          <p>
            <i>{t("opendash:data.filter.none")}</i>
          </p>
          <Button
            children={t("opendash:data.filter.enable")}
            onClick={() => {
              resetCondition();
            }}
          />
        </WrapperDiv>
      );
    }

    return (
      <WrapperDiv>
        <Tree
          selectedKeys={[]}
          treeData={[value]}
          expandedKeys={expandedKeys}
          showLine={{ showLeafIcon: false }}
          blockNode
          titleRender={(node: Condition) => {
            return (
              <ConditionDiv>
                <ConditionTypeDiv>
                  <ConditionTypeLeftDiv>
                    <Button
                      disabled={
                        "children" in node &&
                        (node.children.length > 1 ||
                          (node.children[0] && "children" in node.children[0]))
                      }
                      type={node.type === "rule" ? "primary" : "default"}
                      size="small"
                      children={t("opendash:data.filter.type_label_rule")}
                      onClick={() => {
                        updateCondition(
                          node.key,
                          getDefaultRuleCondition(triggerItem)
                        );
                      }}
                    />
                    <Button
                      type={node.type === "and" ? "primary" : "default"}
                      size="small"
                      children={t("opendash:data.filter.type_label_and")}
                      onClick={() => {
                        updateCondition(node.key, { type: "and" });
                      }}
                    />
                    <Button
                      type={node.type === "or" ? "primary" : "default"}
                      size="small"
                      children={t("opendash:data.filter.type_label_or")}
                      onClick={() => {
                        updateCondition(node.key, { type: "or" });
                      }}
                    />
                  </ConditionTypeLeftDiv>

                  <ConditionTypeRightDiv>
                    <Button
                      size="small"
                      icon={<Icon icon="fa:trash" />}
                      onClick={() => {
                        deleteCondition(node.key);
                      }}
                    />

                    {(node.type === "and" || node.type === "or") && (
                      <Button
                        size="small"
                        icon={<Icon icon="fa:plus" />}
                        onClick={() => {
                          addCondition(node.key);
                        }}
                      />
                    )}
                  </ConditionTypeRightDiv>
                </ConditionTypeDiv>

                {node.type === "rule" && (
                  <ConditionRuleDiv>
                    <DataFetchingFilterRule
                      //triggerItem={triggerItem}
                      value={node}
                      onChange={(nextValue) => {
                        updateCondition(node.key, nextValue);
                      }}
                    />
                  </ConditionRuleDiv>
                )}
              </ConditionDiv>
            );
          }}
        />
      </WrapperDiv>
    );
  }
);

function getKeys(current: Condition, keys: string[] = []) {
  if (current) {
    keys.push(current.key);

    if ("children" in current) {
      for (const c of current.children) {
        getKeys(c, keys);
      }
    }
  }

  return keys;
}

function updateConditionRecursive(
  root: Condition,
  key: string,
  update: Partial<Condition>
) {
  if (root) {
    if (root.key === key) {
      if (update.type && update.type !== root.type) {
        if (update.type === "rule") {
          if ("children" in root) {
            delete root.children;
          }
        }

        if (update.type === "and" || update.type === "or") {
          if (root.type === "rule") {
            update.children = [{ ...root, key: uuid() }];

            delete root.source;
            delete root.id;
            delete root.dimension;
            delete root.rule;
          }
        }
      }

      Object.assign(root, update);
    } else {
      if ("children" in root) {
        for (const child of root.children) {
          updateConditionRecursive(child, key, update);
        }
      }
    }
  }
}

function addConditionRecursive(
  root: Condition,
  key: string,
  newCondition: Condition
) {
  if ("children" in root) {
    if (root.key === key) {
      root.children.unshift(newCondition);
    } else {
      for (const child of root.children) {
        addConditionRecursive(child, key, newCondition);
      }
    }
  }
}

function deleteConditionRecursive(root: Condition, key: string) {
  if ("children" in root) {
    root.children = root.children.filter((child) => child.key !== key);

    for (const child of root.children) {
      deleteConditionRecursive(child, key);
    }
  }
}
