import {
  DataInput,
  DataItemDimensionValueInterface,
  useDataService,
} from "@opendash/plugin-timeseries";
import { DatePicker, Form, Input, Select, Tag } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

function DateBySensorValue(props) {
  let style = {};
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  const state = props.Data;

  const key = props.Data.key;
  const formData = props.FormData;
  const [value, setValue] = React.useState<number>();
  const [date, setDate] = React.useState<Dayjs>(dayjs());
  const [item, setItem] = React.useState<[string, string, number] | null>(
    props.Data.options.dataItem || null
  );
  const [values, setValues] = React.useState<DataItemDimensionValueInterface[]>(
    []
  );
  const DataService = useDataService();
  //DataService.getValues(state.options.dataItem);
  React.useEffect(() => {
    initData();
  }, [date, item]);

  React.useEffect(() => {
    console.log({ value, formData });
    if (formData) {
      formData.setFieldsValue({ [key]: value });
    }
    console.log(formData.getFieldsValue());
  }, [value]);
  const initData = async () => {
    if (item && date) {
      const dataItem = await DataService.get(item[0], item[1]);
      const cValues = await DataService.fetchValues(dataItem, {
        historyType: "absolute",
        start: date.startOf("day").valueOf(),
        end: date.endOf("day").valueOf(),
      });
      setValues(DataService.filterAndKeepChangedValues(cValues));
    }
  };

  const rules = [];
  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }

  return (
    <>
      <Form.Item
        name={state.key}
        label={state.title}
        rules={rules}
        className="hidden_custom_form"
      >
        <Input value={value} style={{ display: "none", height: 0 }}></Input>
      </Form.Item>
      <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
        <DatePicker value={date} onChange={(value) => setDate(value)} />
        <DataInput
          value={[item]}
          options={{ select: "dimension", min: 1, max: 1 }}
          onChange={(newItem) => {
            setItem(newItem[0]);
          }}
        />
        <Select
          options={values.map((value) => {
            return {
              label: (
                <div
                  style={{ display: "flex", gap: "8px", flexDirection: "row" }}
                >
                  {dayjs(value.date).format("HH:mm:ss")}
                  {value.value.map((v) => (
                    <Tag>{v}</Tag>
                  ))}
                </div>
              ),
              value: value.date,
            };
          })}
          listHeight={300}
          onChange={(e) => {
            console.log(e);
            setValue(e);
          }}
        />
      </div>
    </>
  );
}
export default DateBySensorValue;
