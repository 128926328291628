import { AdminLayout, useTranslation } from "@opendash/core";
import { useDataService } from "@opendash/plugin-timeseries";
import { AdminToolbar } from "@opendash/ui";
import { Button } from "antd";
import React from "react";
import {
  OpenWareProvider,
  OpenWareProviderProps,
} from "../common/OpenWareContext";
//import { VirtualSensorProvider, useVirtualSensorContext } from "./context";

export default function VirtualSensorRoute({ config }: OpenWareProviderProps) {
  return (
    <OpenWareProvider config={config}>
      <AdminLayout>
        <VirtualSensorOverview />
      </AdminLayout>
    </OpenWareProvider>
  );
}

function VirtualSensorOverview() {
  const t = useTranslation();

  const DataService = useDataService();
  // const {
  //   vKPIs: items,
  //   error,
  //   reload,
  //   add: updateOne,
  //   addEmpty,
  //   deleteOne,
  // } = useFetchVirtualKPI();
  const [filter, setFilter] = React.useState("");
  return (
    <>
      <AdminToolbar
        title={t("openware:virtual_sensors.title")}
        description={t("openware:virtual_sensors.description")}
        search={filter}
        onSearch={setFilter}
        actions={[
          <Button
            key="create"
            type="primary"
            onClick={() => {}}
            children={t("openware:virtual_sensors.create.action")}
          />,
        ]}
      />
      <div className="od-page-main">
        {/* <List
          dataSource={items}
          renderItem={(item: any) => (
            <List.Item
              actions={[
                <Button
                  icon={<Icon icon="fa:trash" />}
                  onClick={() => {
                    //deleteOne(item.id);
                  }}
                />,
              ]}
            >
              <span>
                <b style={{ marginRight: 10 }}>
                  {item?.template?.name || "No Name"}
                </b>
                {(item.pipe as AggregationPipe)?.stages?.map((stage) => {
                  return <Tag>{stage.action}</Tag>;
                })}
              </span>
            </List.Item>
          )}
        /> */}
      </div>
    </>
  );
}
