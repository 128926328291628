import { useTranslation } from "@opendash/i18n";
import { Select } from "antd";
import React, { useEffect, useState } from "react";

type CustomFormItemProps = {
  value?: number;
  onChange: (value: number) => void;
};

export const TimeSeriesMonthlyDay: React.FC<CustomFormItemProps> = ({
  value,
  onChange,
}) => {
  const t = useTranslation();
  const [currentValue, setCurrentValue] = useState<number>(value);
  const options = [
    {
      label: t("maintenance:timeseries.monthly.day.first"),
      value: 0,
    },
    {
      label: t("maintenance:timeseries.monthly.day.second"),
      value: 1,
    },
    {
      label: t("maintenance:timeseries.monthly.day.third"),
      value: 2,
    },
    {
      label: t("maintenance:timeseries.monthly.day.fourth"),
      value: 3,
    },
    {
      label: t("maintenance:timeseries.monthly.day.last"),
      value: 4,
    },
  ];

  useEffect(() => {
    //Propagate the current Value on component activation/when it get's visible
    onChange(currentValue);
  }, []);

  return (
    <Select
      style={{ marginBottom: 24 }}
      defaultValue={currentValue}
      onChange={(v) => {
        onChange(v);
        setCurrentValue(v);
      }}
      options={options}
    />
  );
};
