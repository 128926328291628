import * as React from "react";

import styled from "styled-components";

const Menu = styled.div`
  position: relative;
  display: flex;
  /* padding: 0 24px; */
  height: 36px;
  line-height: 36px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-bottom: 1px solid #d9d9d9;
`;

const MenuLeft = styled.div`
  display: flex;
  flex: 1 1;
`;

const MenuRight = styled.div`
  float: right;
`;

const MenuCenter = styled.div`
  margin: 0 auto;
`;

interface Props {
  left?: any;
  right?: any;
  center?: any;
}

export const WidgetActionBar = React.forwardRef<HTMLDivElement, Props>(
  function WidgetActionBar({ left = null, right = null, center = null }, ref) {
    return (
      <Menu ref={ref}>
        {left && <MenuLeft>{left}</MenuLeft>}
        {right && <MenuCenter>{right}</MenuCenter>}
        {center && <MenuRight>{center}</MenuRight>}
      </Menu>
    );
  }
);
