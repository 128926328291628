import { useFeedback, useTranslation } from "@opendash/core";
import { Modal } from "antd";
import downloadJs from "downloadjs";

import { useOpenWareContext } from "../common/OpenWareContext";
import { Report } from "./Report";
import { useReportingContext } from "./ReportingContext";

export function useReportApi() {
  const t = useTranslation();

  const ow = useOpenWareContext();
  const context = useReportingContext();
  const { message } = useFeedback();

  function create(attributes) {
    new Report(attributes).save().then(
      (report) => {
        message.success(t("openware:reporting.create.success"));

        // setCreate(false);

        context.reload();

        //download(report);
      },
      () => {
        message.error(t("openware:reporting.create.error"));
      }
    );
  }

  function deleteWithoutConfirm(report: Report) {
    report.destroy().then(
      () => {
        message.success(t("openware:reporting.delete.success"));

        context.reload();
      },
      () => {
        message.error(t("openware:reporting.delete.error"));

        context.reload();
      }
    );
  }

  function deleteWithConfirm(report: Report) {
    Modal.confirm({
      title: t("openware:reporting.delete.title"),
      content: t("openware:reporting.delete.content"),
      okText: t("openware:reporting.delete.yes"),
      okType: "danger",
      onOk: () => {
        deleteWithoutConfirm(report);
      },
    });
  }

  async function download(
    report: Report,
    interval: number[],
    reference: string
  ) {
    const hideMessage = message.loading(
      t("openware:reporting.download.start"),
      0
    );

    try {
      const filename = report.get("template").url().split(".");
      const type = filename[filename.length - 1];
      const downloadName = report.get("name") + "." + type;
      const body = { params: report.toJSON() };
      body.params.template = report.get("template").url();
      body.params.start = interval[0];
      body.params.end = interval[1];
      if (reference && reference !== "") {
        body.params.reference = reference;
      }

      const response = await ow.fetch("/api/report/" + report.get("type"), {
        method: "POST",
        body: JSON.stringify(body),
      });

      const blob = await response.blob();

      downloadJs(blob, downloadName);
      hideMessage();
      message.success(t("openware:reporting.download.success"));
    } catch (error) {
      console.error(error);
      hideMessage();
      message.error(t("openware:reporting.download.error"));
    } finally {
    }
  }

  return {
    create,
    deleteWithoutConfirm,
    deleteWithConfirm,
    download,
  };
}
