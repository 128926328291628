import { Icon } from "@opendash/icons";
import { ActionId, ActionImpl, KBarResults, useMatches } from "kbar";
import { observer } from "mobx-react-lite";
import * as React from "react";

export const CommandBarResults = observer(function CommandBarResults() {
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div className="opendash-commandbar--group">{item}</div>
        ) : (
          <CommandBarResult
            action={item}
            active={active}
            currentRootActionId={rootActionId}
          />
        )
      }
    />
  );
});

const CommandBarResult = observer<{
  action: ActionImpl;
  active: boolean;
  currentRootActionId: ActionId;
}>(function CommandBarResult({ action, active, currentRootActionId }) {
  const ancestors = React.useMemo(() => {
    if (!currentRootActionId) return action.ancestors;
    const index = action.ancestors.findIndex(
      (ancestor) => ancestor.id === currentRootActionId
    );
    return action.ancestors.slice(index + 1);
  }, [action.ancestors, currentRootActionId]);

  return (
    <div
      className={
        active
          ? "opendash-commandbar--result opendash-commandbar--result--active"
          : "opendash-commandbar--result"
      }
    >
      <div className="opendash-commandbar--result-container">
        {action.icon && <Icon icon={action.icon as string} />}

        <div className="opendash-commandbar--result-content">
          <div className="opendash-commandbar--result-parents-container">
            {ancestors?.length > 0 &&
              ancestors.map((ancestor) => (
                <React.Fragment key={ancestor.id}>
                  <span className="opendash-commandbar--result-parents-title">
                    {ancestor.name}
                  </span>
                  <span className="opendash-commandbar--result-parents-divider">
                    &rsaquo;
                  </span>
                </React.Fragment>
              ))}
            <span className="opendash-commandbar--result-title">
              {action.name}
            </span>
          </div>
          {action.subtitle && (
            <span className="opendash-commandbar--result-description">
              {action.subtitle}
            </span>
          )}
        </div>
      </div>
      {action.shortcut?.length ? (
        <div aria-hidden className="opendash-commandbar--result-keys">
          {action.shortcut.map((sc) => (
            <kbd key={sc} className="opendash-commandbar--result-key">
              {sc}
            </kbd>
          ))}
        </div>
      ) : null}
    </div>
  );
});
