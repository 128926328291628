export class Lock {
  private promise: Promise<void> | undefined;
  private resolver: any = null;

  constructor(locked: boolean = false) {
    if (locked) {
      this.lock();
    }
  }

  lock() {
    this.promise = new Promise((resolve) => {
      this.resolver = resolve;
    });
  }

  unlock() {
    if (this.resolver) this.resolver();
  }

  wait() {
    if (this.promise) return this.promise;

    return Promise.resolve();
  }
}
