import { useServiceStore } from "@opendash/core";
import React from "react";
import { WidgetInterface } from "..";
import { useMonitoringService } from "./useMonitoringService";

export function useWidget(id: string): WidgetInterface {
  const MonitoringService = useMonitoringService();

  return useServiceStore(
    MonitoringService,
    React.useCallback(
      (state) => state.allWidgets.find((widget) => widget.id === id),
      [id]
    )
  );
}
