import Parse from "parse";
import React from "react";

import * as FT from "./fieldtypes";

import { $parse } from "../state";
import { ParseClassConfig } from "../types";

interface Props {
  object: Parse.Object;
  field: string;
  config?: ParseClassConfig;
  onChange?(value: any): void;
  isInline?: boolean;
}

/**
 * ParseField is a component that renders a field of a ParseObject.
 * It automatically detects the type of the field and renders the correct input field.
 * If the field is not defined in the schema, it will render a simple text display.
 * If the field is not defined in the schema and a custom field is defined in the class config, it will render the custom field.
 *
 * @param object The ParseObject to render the field from.
 * @param field The field name to render.
 * @param config The class config to use for rendering.
 * @param onChange The callback function to call when the value changes.
 * @param isInline If true, the field will be rendered inline.
 *
 */
export const ParseField = React.memo<Props>(function ParseField({
  object,
  field,

  isInline = false,
  config: configOverwrite,
  onChange: setValue,
}) {
  const className = object.className;
  const classSchema = $parse.ui.getClassSchema(className);
  const classConfig = configOverwrite || $parse.ui.getClassConfig(className);
  const customFields = classConfig.customFields;
  if (!classSchema) {
    return null;
  }

  const schema = classSchema.fields[field];

  if (!schema && !customFields?.[field]) {
    return null;
  }

  const value = object.get(field);

  if (field === "id" || field === "objectId") {
    return <span>{object.id}</span>;
  }

  if (!setValue && (value === undefined || value === null)) {
    return <span>-</span>;
  }

  if (customFields && customFields[field]) {
    return customFields[field]({
      value,
      setValue,
      fieldname: field,
      parseObject: object,
    });
  }

  const isRequired = !!schema.required && !schema.defaultValue;
  const isNullable = !isRequired;

  switch (schema.type.toLowerCase()) {
    case "string":
      if (field === "text" || field.endsWith("Text")) {
        return !setValue ? (
          <FT.StringTextDisplay value={value} isInline={isInline} />
        ) : (
          <FT.StringTextEdit
            value={value}
            setValue={setValue}
            isRequired={isRequired}
            isNullable={isNullable}
          />
        );
      }

      if (field === "color" || field.endsWith("Color")) {
        return !setValue ? (
          <FT.StringColorDisplay value={value} />
        ) : (
          <FT.StringColorEdit
            value={value}
            setValue={setValue}
            isRequired={isRequired}
            isNullable={isNullable}
          />
        );
      }

      if (field === "icon" || field.endsWith("Icon")) {
        return !setValue ? (
          <FT.StringIconDisplay value={value} />
        ) : (
          <FT.StringIconEdit
            value={value}
            setValue={setValue}
            isRequired={isRequired}
            isNullable={isNullable}
          />
        );
      }

      if (classConfig?.asSelect?.[field]) {
        return !setValue ? (
          <FT.StringSelectDisplay
            value={value}
            options={classConfig.asSelect?.[field]}
          />
        ) : (
          <FT.StringSelectEdit
            value={value}
            setValue={setValue}
            options={classConfig.asSelect?.[field]}
            isRequired={isRequired}
            isNullable={isNullable}
          />
        );
      }

      return !setValue ? (
        <FT.StringDisplay value={value} />
      ) : (
        <FT.StringEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "stringtext":
      return !setValue ? (
        <FT.StringTextDisplay value={value} isInline={isInline} />
      ) : (
        <FT.StringTextEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "stringselect":
      return !setValue ? (
        <FT.StringSelectDisplay
          value={value}
          options={classConfig.asSelect?.[field]}
        />
      ) : (
        <FT.StringSelectEdit
          value={value}
          setValue={setValue}
          options={classConfig.asSelect?.[field]}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "stringcolor":
      return !setValue ? (
        <FT.StringColorDisplay value={value} />
      ) : (
        <FT.StringColorEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "stringicon":
      return !setValue ? (
        <FT.StringIconDisplay value={value} />
      ) : (
        <FT.StringIconEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "boolean":
      return !setValue ? (
        <FT.BooleanDisplay value={value} />
      ) : (
        <FT.BooleanEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "number":
      return !setValue ? (
        <FT.NumberDisplay value={value} />
      ) : (
        <FT.NumberEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "date":
      return !setValue ? (
        <FT.DateDisplay value={value} isoformat={false} />
      ) : (
        <FT.DateEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "object":
      return !setValue ? (
        <FT.ObjectDisplay value={value} />
      ) : (
        <FT.ObjectEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "array":
      if (classConfig?.asSelect?.[field]) {
        return !setValue ? (
          <FT.ArraySelectDisplay
            value={value}
            options={classConfig.asSelect?.[field]}
          />
        ) : (
          <FT.ArraySelectEdit
            value={value}
            setValue={setValue}
            options={classConfig.asSelect?.[field]}
            isRequired={isRequired}
            isNullable={isNullable}
          />
        );
      }

      return !setValue ? (
        <FT.ArrayDisplay value={value} />
      ) : (
        <FT.ArrayEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "arrayselect":
      return !setValue ? (
        <FT.ArraySelectDisplay
          value={value}
          options={classConfig?.asSelect?.[field]}
        />
      ) : (
        <FT.ArraySelectEdit
          value={value}
          setValue={setValue}
          options={classConfig?.asSelect?.[field]}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "geopoint":
      return !setValue ? (
        <FT.GeoPointDisplay value={value} />
      ) : (
        <FT.GeoPointEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "polygon":
      return !setValue ? (
        <FT.PolygonDisplay value={value} />
      ) : (
        <FT.PolygonEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "file":
      return !setValue ? (
        <FT.FileDisplay value={value} isInline={isInline} />
      ) : (
        <FT.FileEdit
          value={value}
          setValue={setValue}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "pointer":
      return !setValue ? (
        <FT.PointerDisplay value={value} />
      ) : (
        <FT.PointerEdit
          value={value}
          setValue={setValue}
          targetClass={schema.targetClass}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    case "relation":
      return !setValue ? (
        <FT.RelationDisplay object={object} field={field} />
      ) : (
        <FT.RelationEdit
          object={object}
          field={field}
          isRequired={isRequired}
          isNullable={isNullable}
        />
      );
    default:
      return <span>???</span>;
  }
});
