import { produce, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import {
  AggregationOperationSelect,
  DataInput,
  DataItemDimensionIdentifierInterface,
  useDataService,
} from "@opendash/plugin-timeseries";
import { Description } from "@opendash/ui";
import {
  Button,
  Collapse,
  Divider,
  Empty,
  InputNumber,
  Typography,
} from "antd";
import React from "react";
import { HierachyBuilderProps, HierachyItemProps } from "../types";
import { keySafeId } from "./helper";

const Hierachy = ({
  value,
  setValue,
  readonly = false,
}: HierachyBuilderProps) => {
  const itemsForCollapse = value.map((item, idx) => {
    return {
      key: item.label + idx,
      label: (
        <Typography.Title
          level={5}
          editable={
            readonly
              ? false
              : {
                  triggerType: ["icon"],
                  icon: (
                    <Icon
                      icon="fa:pen"
                      style={{ color: "rgba(0, 0, 0, 0.45)" }}
                    />
                  ),
                  onChange: (text) => {
                    setValue(
                      produce(value, (draft) => {
                        draft[idx].label = text;
                      })
                    );
                  },
                }
          }
        >
          {item.label}
        </Typography.Title>
      ),
      extra: (
        <>
          {!readonly && (
            <Button
              type="link"
              icon={<Icon icon="fa:trash" />}
              onClick={() => {
                setValue(
                  produce(value, (draft) => {
                    draft.splice(idx, 1);
                  })
                );
              }}
            />
          )}
        </>
      ),
      children: (
        <HierachyItem
          readonly={readonly}
          value={item}
          setValue={(item) => {
            setValue(
              produce(value, (draft) => {
                draft[idx] = item;
              })
            );
          }}
          level={0}
        />
      ),
    };
  });
  return (
    <>
      {itemsForCollapse.length > 0 && (
        <div
          style={{
            borderBottom: "1px solid #ccc",
            backgroundColor: "rgba(0,0,0,0.01)",
          }}
        >
          <Collapse
            size="small"
            items={itemsForCollapse}
            bordered={false}
            expandIconPosition="right"
          />
        </div>
      )}
      {itemsForCollapse.length === 0 && <Empty></Empty>}
    </>
  );
};

const HierachyItem = ({
  value,
  setValue,
  level = 0,
  style = {},
  readonly = false,
}: HierachyItemProps) => {
  const DataService = useDataService();
  const t = useTranslation();
  const [addItem, setAddItem] = React.useState(false);

  const safeAccessItemName = React.useMemo(
    () => (item: DataItemDimensionIdentifierInterface) => {
      try {
        return DataService.getItemName(
          DataService._getOrThrowSync(item[0], item[1]),
          item[2],
          false
        );
      } catch (error) {
        return "deleted/NA";
      }
    },
    [DataService]
  );
  return (
    <div
      style={{
        marginLeft: level * 0,
        flexGrow: 1,
      }}
    >
      <Description>
        {t("openware:aggregate.sankey.labels.nodeAggregateBy")}
      </Description>
      <AggregationOperationSelect
        style={{ width: "100%" }}
        value={value.aggregateBy}
        disabled={readonly}
        onChange={(next) => {
          setValue(
            produce(value, (draft) => {
              draft.aggregateBy = next;
            })
          );
        }}
      ></AggregationOperationSelect>

      <Divider style={{ margin: "8px 0px" }}></Divider>

      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}
      >
        <Typography.Text type="secondary">
          {value.values.length === 0
            ? t("openware:aggregate.sankey.labels.datapoints", {
                amount: "Keine",
              })
            : t("openware:aggregate.sankey.labels.datapoints", {
                amount: value.values.length,
              })}{" "}
        </Typography.Text>

        {!readonly && (
          <Button
            type="dashed"
            onClick={() => setAddItem(!addItem)}
            style={{ marginLeft: "auto", backgroundColor: "rgba(0,0,0,0)" }}
            icon={<Icon icon={addItem ? "fa:save" : "fa:plus"} />}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {addItem && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <DataInput
              style={{ width: "100%" }}
              options={{ select: "dimension", types: ["Number"], min: 0 }}
              value={value.values}
              onChange={(nextValue) => {
                setValue(
                  produce(value, (draft) => {
                    draft.values = nextValue;
                    nextValue.forEach((item) => {
                      draft.valueAggregationOperations[keySafeId(item)] =
                        draft.valueAggregationOperations[keySafeId(item)] ||
                        "sum";
                    });
                  })
                );
              }}
            />
          </div>
        )}
        {!addItem && (
          <>
            {value.values && value.values.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {/* <Typography.Text type="secondary">
                  {value.aggregateBy === "sum" &&
                    t("opendash:monitoring.history_options.aggregation_sum")}
                  {value.aggregateBy === "min" &&
                    t("opendash:monitoring.history_options.aggregation_sum")}
                  {value.aggregateBy === "max" &&
                    t("opendash:monitoring.history_options.aggregation_sum")}
                  {value.aggregateBy === "avg" &&
                    t("opendash:monitoring.history_options.aggregation_mean")}
                  {value.aggregateBy === "diffminmax" &&
                    t(
                      "opendash:monitoring.history_options.aggregation_diffminmax"
                    )}
                  {value.aggregateBy === "count" &&
                    t("opendash:monitoring.history_options.aggregation_count")}
                </Typography.Text> */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {value.values.map((item) => {
                    const key = keySafeId(item);
                    return (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                          }}
                        >
                          <Typography.Text
                            type="secondary"
                            style={{ flexGrow: 1 }}
                          >
                            {safeAccessItemName(item)}
                          </Typography.Text>
                          <AggregationOperationSelect
                            style={{ width: "200px" }}
                            value={value.valueAggregationOperations[key]}
                            disabled={readonly}
                            onChange={(next) => {
                              console.log({ ...value }, next);
                              setValue(
                                produce(value, (draft) => {
                                  draft.valueAggregationOperations[key] = next;
                                  if (!draft.valueAggregationParams) {
                                    draft.valueAggregationParams = {};
                                  }
                                  if (!draft.valueAggregationParams[key]) {
                                    draft.valueAggregationParams[key] =
                                      next === "diffFirstLast"
                                        ? { offset: 0 }
                                        : {};
                                  }
                                })
                              );
                            }}
                          ></AggregationOperationSelect>
                        </div>
                        {value.valueAggregationOperations[key] ===
                          "diffFirstLast" && (
                          <>
                            <InputNumber
                              disabled={readonly}
                              onChange={(number) => {
                                setValue(
                                  produce(value, (draft) => {
                                    if (!draft.valueAggregationParams) {
                                      draft.valueAggregationParams = {};
                                    }
                                    if (!draft.valueAggregationParams[key]) {
                                      draft.valueAggregationParams[key] = {};
                                    }
                                    draft.valueAggregationParams[key].offset =
                                      number * 1000;
                                  })
                                );
                              }}
                              value={
                                (value.valueAggregationParams?.[key]?.offset ||
                                  0) / 1000
                              }
                              step={1}
                              addonAfter={t("opendash:ui.second_plural")}
                            />
                            <Description>
                              {t("openware:aggregate.diffFirstLast.offset")}
                            </Description>
                          </>
                        )}
                      </>
                      // <Tag>
                      //
                      // </Tag>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Divider style={{ margin: "8px 0px" }} />
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}
      >
        <Typography.Text type="secondary">
          {value.children.length === 0
            ? t("openware:aggregate.sankey.labels.subgroups", {
                amount: "Keine",
              })
            : t("openware:aggregate.sankey.labels.subgroups", {
                amount: value.children.length,
              })}{" "}
        </Typography.Text>
        {!readonly && (
          <Button
            type="dashed"
            onClick={() => {
              setValue(
                produce(value, (draft) => {
                  draft.children.push({
                    label: "New Group",
                    children: [],
                    values: [],
                    aggregateBy: "sum",
                    valueAggregationOperations: {},
                    valueAggregationParams: {},
                  });
                })
              );
            }}
            style={{ marginLeft: "auto", backgroundColor: "rgba(0,0,0,0)" }}
            icon={<Icon icon="fa:plus" />}
          />
        )}
      </div>
      {value.children.length > 0 && (
        <Hierachy
          value={value.children}
          setValue={(nextValue) => {
            setValue(
              produce(value, (draft) => {
                draft.children = nextValue;
              })
            );
          }}
          readonly={readonly}
        />
      )}
    </div>
  );
};

export default ({
  value = [],
  setValue,
  readonly = false,
}: HierachyBuilderProps) => {
  console.log({ value, setValue, readonly });
  return (
    <>
      <Hierachy value={value} setValue={setValue} readonly={readonly} />

      {!readonly && (
        <Button
          type="dashed"
          style={{
            width: "100%",
            marginTop: "8px",
            backgroundColor: "rgba(0,0,0,0)",
          }}
          onClick={() => {
            setValue(
              produce(value, (draft) => {
                draft.push({
                  label: "New Group",
                  values: [],
                  children: [],
                  aggregateBy: "sum",
                  valueAggregationOperations: {},
                  valueAggregationParams: {},
                });
              })
            );
          }}
        >
          +
        </Button>
      )}
    </>
  );
};
