import { makeAutoObservable } from "mobx";
import { CronState } from "..";

export class StateProvider {
  private static state: CronState = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public static getState(): CronState {
    if (typeof this.state === "undefined") {
      this.state = new CronState();
    }
    return this.state;
  }
}
