import React from "react";

import { ColorPicker } from "@opendash/ui";

export interface StringColorDisplayProps {
  value: string;
}

export const StringColorDisplay = React.memo<StringColorDisplayProps>(
  function StringColorDisplay({ value }) {
    return (
      <>
        <span
          style={{
            display: "inline-block",
            width: 10,
            height: 10,
            borderRadius: 3,
            background: value,
          }}
          children=" "
        />
        {value}
      </>
    );
  }
);

export interface StringColorEditProps {
  value: string;
  setValue?: (v: string) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const StringColorEdit = React.memo<StringColorEditProps>(
  function StringColorEdit({ value, setValue }) {
    return <ColorPicker value={value} onChange={setValue} />;
  }
);
