import { createInternalComponent, useTranslation } from "@opendash/core";
import { Drawer } from "antd";
import * as React from "react";
import { DataSidebarTable } from "..";

interface Props {
  open: boolean;
  close: () => void;
}
export const DataSidebar = createInternalComponent<Props>(function DataSidebar({
  open,
  close,
}) {
  const t = useTranslation();

  return (
    <Drawer
      title={t("opendash:monitoring.data_sidebar.title")}
      open={open}
      width={Math.min(800, window.innerWidth)}
      onClose={(e) => close()}
      styles={{ body: { padding: 0 } }}
    >
      {open && <DataSidebarTable />}
    </Drawer>
  );
});
