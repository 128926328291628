import { useTranslation } from "@opendash/i18n";
import { InputNumber } from "antd";
import React, { useEffect, useState } from "react";

type CustomFormItemProps = {
  value?: number;
  onChange: (value: number) => void;
};

export const TimeSeriesMonthlyDate: React.FC<CustomFormItemProps> = ({
  value,
  onChange,
}) => {
  const t = useTranslation();
  const [currentValue, setCurrentValue] = useState<number>(value);

  useEffect(() => {
    //Propagate the current Value on component activation/when it get's visible
    onChange(currentValue);
  }, []);

  return (
    <InputNumber
      min={1}
      max={31}
      defaultValue={currentValue}
      style={{ width: "100%", marginBottom: 24 }}
      addonBefore={t("maintenance:timeseries.monthly.date.addon.before.at")}
      addonAfter={t("maintenance:timeseries.monthly.date.addon.after.day")}
      onChange={(v) => {
        onChange(v);
        setCurrentValue(v);
      }}
    />
  );
};
