import { useTranslation } from "@opendash/core";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import * as React from "react";
import { AggregationOperationInterface } from "..";

type Props =
  | ({ showNone?: true } & SelectProps<AggregationOperationInterface | "none">)
  | ({ showNone?: false } & SelectProps<AggregationOperationInterface>);

export const AggregationOperationSelect = React.memo<Props>(
  React.forwardRef(function AggregationOperationSelect(
    { showNone = false, ...props },
    ref
  ) {
    const t = useTranslation();

    return (
      <Select {...props}>
        {showNone && (
          <Select.Option
            value="none"
            children={t("opendash:monitoring.history_options.aggregation_none")}
          />
        )}
        <Select.Option
          value="min"
          children={t("opendash:monitoring.history_options.aggregation_min")}
        />
        <Select.Option
          value="max"
          children={t("opendash:monitoring.history_options.aggregation_max")}
        />
        <Select.Option
          value="sum"
          children={t("opendash:monitoring.history_options.aggregation_sum")}
        />
        <Select.Option
          value="stdd"
          children={t("opendash:monitoring.history_options.aggregation_stdd")}
        />
        <Select.Option
          value="variance"
          children={t(
            "opendash:monitoring.history_options.aggregation_variance"
          )}
        />
        <Select.Option
          value="avg"
          children={t("opendash:monitoring.history_options.aggregation_mean")}
        />
        <Select.Option
          value="count"
          children={t("opendash:monitoring.history_options.aggregation_count")}
        />
        <Select.Option
          value="diffminmax"
          children={t(
            "opendash:monitoring.history_options.aggregation_diffminmax"
          )}
        />
        <Select.Option
          value="diffFirstLast"
          children={t(
            "opendash:monitoring.history_options.aggregation_diffFirstLast"
          )}
        />
      </Select>
    );
  })
);
