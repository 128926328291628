import {
  createInternalComponent,
  useSource,
  useTranslation,
} from "@opendash/core";
import { useParams } from "@opendash/router";
import * as React from "react";
import { WidgetComponent } from "..";

export const WidgetRoute = createInternalComponent(function WidgetRoute() {
  const t = useTranslation();
  const [cSource, setCSource] = useSource();
  const { id, source } = useParams();

  if (cSource.id !== source) {
    setCSource(source);
  }
  return <WidgetComponent id={id} fullscreen={true} />;
});
