import { useTranslation } from "@opendash/core";
import { Select } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { configProvider } from "../../..";
import FormList from "../../../app/forms/FormList";
import { BDEFormListState } from "../../../pages/OverviewPage";

const { Option } = Select;

export default observer((props: { state: BDEFormListState }) => {
  const t = useTranslation();

  const options = Object.keys(props.state.units).map((key) => (
    <Option key={key} value={key} style={{ width: "50%" }}>
      {props.state.units[key][configProvider.config.units.field]}
    </Option>
  ));

  const getChildren = (key, alreadyAdded) => {
    return Object.values(props.state.units)
      .filter((unit) => {
        return (
          unit[configProvider.config.units.source].indexOf(
            key[configProvider.config.units.source]
          ) == 0 &&
          unit[configProvider.config.units.source].split(".").length ===
            key[configProvider.config.units.source].split(".").length + 1
        );
      })
      .map((child: any) => {
        alreadyAdded.push(child[configProvider.config.units.source]);
        const children = getChildren(
          child[configProvider.config.units.field],
          alreadyAdded
        );
        return {
          key: child.objectId,
          value: child.objectId,
          label: child[configProvider.config.units.field],
          children: children.length > 0 ? children : void 0,
        };
      });
  };

  return (
    <div key="UnitList">
      {Object.keys(props.state.units)
        .filter((unitID) => {
          return (
            props.state.unitFilter.includes("__all") ||
            props.state.unitFilter.includes(unitID)
          );
        })
        .map((key) => {
          return (
            <FormList
              key={key}
              Unit={props.state.units[key]}
              Reference={props.state.reference}
            />
          );
        })}
      <div
        style={{
          textAlign: "center",
          padding: "5px",
          paddingTop: "10px",
          paddingBottom: "10px",
          fontSize: "15px",
          color: "var(--opendash-color-gray)",
        }}
      >
        {props.state.units && props.state.formcount > 1
          ? t("bde:app.select.formcount", {
              count: props.state.formcount,
            })
          : t("bde:app.select.formcountsingle", {
              count: props.state.formcount,
            })}
      </div>
    </div>
  );
});
