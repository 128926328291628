// @ts-nocheck

import * as React from "react";
import styled from "styled-components";

import throttle from "lodash.throttle";

import { Icon } from "@opendash/icons";
import { Input, Popover } from "antd";

const SQUARE_SIZE = 200;
const BAR_SIZE = 20;
const CROSS_SIZE = 15;
const THROTTLE_DELAY = 50;

const COLOR_SPACES = ["hex", "rgb", "hsl"];

const COLOR_NAMES: Record<string, [number, number, number]> = {
  aliceblue: [240, 248, 255],
  antiquewhite: [250, 235, 215],
  aqua: [0, 255, 255],
  aquamarine: [127, 255, 212],
  azure: [240, 255, 255],
  beige: [245, 245, 220],
  bisque: [255, 228, 196],
  black: [0, 0, 0],
  blanchedalmond: [255, 235, 205],
  blue: [0, 0, 255],
  blueviolet: [138, 43, 226],
  brown: [165, 42, 42],
  burlywood: [222, 184, 135],
  cadetblue: [95, 158, 160],
  chartreuse: [127, 255, 0],
  chocolate: [210, 105, 30],
  coral: [255, 127, 80],
  cornflowerblue: [100, 149, 237],
  cornsilk: [255, 248, 220],
  crimson: [220, 20, 60],
  cyan: [0, 255, 255],
  darkblue: [0, 0, 139],
  darkcyan: [0, 139, 139],
  darkgoldenrod: [184, 134, 11],
  darkgray: [169, 169, 169],
  darkgreen: [0, 100, 0],
  darkgrey: [169, 169, 169],
  darkkhaki: [189, 183, 107],
  darkmagenta: [139, 0, 139],
  darkolivegreen: [85, 107, 47],
  darkorange: [255, 140, 0],
  darkorchid: [153, 50, 204],
  darkred: [139, 0, 0],
  darksalmon: [233, 150, 122],
  darkseagreen: [143, 188, 143],
  darkslateblue: [72, 61, 139],
  darkslategray: [47, 79, 79],
  darkslategrey: [47, 79, 79],
  darkturquoise: [0, 206, 209],
  darkviolet: [148, 0, 211],
  deeppink: [255, 20, 147],
  deepskyblue: [0, 191, 255],
  dimgray: [105, 105, 105],
  dimgrey: [105, 105, 105],
  dodgerblue: [30, 144, 255],
  firebrick: [178, 34, 34],
  floralwhite: [255, 250, 240],
  forestgreen: [34, 139, 34],
  fuchsia: [255, 0, 255],
  gainsboro: [220, 220, 220],
  ghostwhite: [248, 248, 255],
  gold: [255, 215, 0],
  goldenrod: [218, 165, 32],
  gray: [128, 128, 128],
  green: [0, 128, 0],
  greenyellow: [173, 255, 47],
  grey: [128, 128, 128],
  honeydew: [240, 255, 240],
  hotpink: [255, 105, 180],
  indianred: [205, 92, 92],
  indigo: [75, 0, 130],
  ivory: [255, 255, 240],
  khaki: [240, 230, 140],
  lavender: [230, 230, 250],
  lavenderblush: [255, 240, 245],
  lawngreen: [124, 252, 0],
  lemonchiffon: [255, 250, 205],
  lightblue: [173, 216, 230],
  lightcoral: [240, 128, 128],
  lightcyan: [224, 255, 255],
  lightgoldenrodyellow: [250, 250, 210],
  lightgray: [211, 211, 211],
  lightgreen: [144, 238, 144],
  lightgrey: [211, 211, 211],
  lightpink: [255, 182, 193],
  lightsalmon: [255, 160, 122],
  lightseagreen: [32, 178, 170],
  lightskyblue: [135, 206, 250],
  lightslategray: [119, 136, 153],
  lightslategrey: [119, 136, 153],
  lightsteelblue: [176, 196, 222],
  lightyellow: [255, 255, 224],
  lime: [0, 255, 0],
  limegreen: [50, 205, 50],
  linen: [250, 240, 230],
  magenta: [255, 0, 255],
  maroon: [128, 0, 0],
  mediumaquamarine: [102, 205, 170],
  mediumblue: [0, 0, 205],
  mediumorchid: [186, 85, 211],
  mediumpurple: [147, 112, 219],
  mediumseagreen: [60, 179, 113],
  mediumslateblue: [123, 104, 238],
  mediumspringgreen: [0, 250, 154],
  mediumturquoise: [72, 209, 204],
  mediumvioletred: [199, 21, 133],
  midnightblue: [25, 25, 112],
  mintcream: [245, 255, 250],
  mistyrose: [255, 228, 225],
  moccasin: [255, 228, 181],
  navajowhite: [255, 222, 173],
  navy: [0, 0, 128],
  oldlace: [253, 245, 230],
  olive: [128, 128, 0],
  olivedrab: [107, 142, 35],
  orange: [255, 165, 0],
  orangered: [255, 69, 0],
  orchid: [218, 112, 214],
  palegoldenrod: [238, 232, 170],
  palegreen: [152, 251, 152],
  paleturquoise: [175, 238, 238],
  palevioletred: [219, 112, 147],
  papayawhip: [255, 239, 213],
  peachpuff: [255, 218, 185],
  peru: [205, 133, 63],
  pink: [255, 192, 203],
  plum: [221, 160, 221],
  powderblue: [176, 224, 230],
  purple: [128, 0, 128],
  rebeccapurple: [102, 51, 153],
  red: [255, 0, 0],
  rosybrown: [188, 143, 143],
  royalblue: [65, 105, 225],
  saddlebrown: [139, 69, 19],
  salmon: [250, 128, 114],
  sandybrown: [244, 164, 96],
  seagreen: [46, 139, 87],
  seashell: [255, 245, 238],
  sienna: [160, 82, 45],
  silver: [192, 192, 192],
  skyblue: [135, 206, 235],
  slateblue: [106, 90, 205],
  slategray: [112, 128, 144],
  slategrey: [112, 128, 144],
  snow: [255, 250, 250],
  springgreen: [0, 255, 127],
  steelblue: [70, 130, 180],
  tan: [210, 180, 140],
  teal: [0, 128, 128],
  thistle: [216, 191, 216],
  tomato: [255, 99, 71],
  turquoise: [64, 224, 208],
  violet: [238, 130, 238],
  wheat: [245, 222, 179],
  white: [255, 255, 255],
  whitesmoke: [245, 245, 245],
  yellow: [255, 255, 0],
  yellowgreen: [154, 205, 50],
};

function parseColorToHsl(input: string): [string, number, number, number] {
  try {
    if (typeof input === "string") {
      if (input in COLOR_NAMES) {
        return ["hex", ...convertRGBtoHSL(...COLOR_NAMES[input])];
      }

      if (input.startsWith("#")) {
        return ["hex", ...convertRGBtoHSL(...hexToRgb(input))];
      }

      if (input.startsWith("hsl(")) {
        const regexp =
          /hsl\(\s*(\d+)\s*,\s*(\d+(?:\.\d+)?%)\s*,\s*(\d+(?:\.\d+)?%)\)/g;
        const [h, s, l] = regexp.exec(input).slice(1);

        return ["hsl", parseInt(h), parseInt(s), parseInt(l)];
      }

      if (input.startsWith("rgb(")) {
        const [r, g, b] = input.match(/\d+/g);
        const [h, s, l] = convertRGBtoHSL(
          parseInt(r),
          parseInt(g),
          parseInt(b)
        );

        return ["rgb", h, s, l];
      }
    }
  } catch (error) {}

  return ["hex", 180, 100, 50];
}

function parseColorToRGB(input: string): [number, number, number] {
  try {
    if (typeof input === "string") {
      if (input in COLOR_NAMES) {
        return [...COLOR_NAMES[input]];
      }

      if (input.startsWith("#")) {
        return [...hexToRgb(input)];
      }

      if (input.startsWith("hsl(")) {
        const regexp =
          /hsl\(\s*(\d+)\s*,\s*(\d+(?:\.\d+)?%)\s*,\s*(\d+(?:\.\d+)?%)\)/g;
        const [h, s, l] = regexp.exec(input).slice(1);

        const [r, g, b] = convertHSLtoRGB(
          parseInt(h),
          parseInt(s),
          parseInt(l)
        );

        return [r, g, b];
      }

      if (input.startsWith("rgb(")) {
        const [r, g, b] = input.match(/\d+/g);

        return [parseInt(r), parseInt(g), parseInt(b)];
      }
    }
  } catch (error) {}

  return [0, 0, 0];
}

function convertHSLtoRGB(h, s, l) {
  s /= 100;
  l /= 100;

  let r, g, b;

  if (s == 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function hexToRgb(hex: string): [number, number, number] {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16)) as [number, number, number];
}

function convertRGBtoHSL(
  red: number,
  green: number,
  blue: number
): [number, number, number] {
  const r = red / 255;
  const g = green / 255;
  const b = blue / 255;
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const delta = max - min;
  let h;
  let s;

  if (max === min) {
    h = 0;
  } else if (r === max) {
    h = (g - b) / delta;
  } else if (g === max) {
    h = 2 + (b - r) / delta;
  } else if (b === max) {
    h = 4 + (r - g) / delta;
  }

  h = Math.min(h * 60, 360);

  if (h < 0) {
    h += 360;
  }

  const l = (min + max) / 2;

  if (max === min) {
    s = 0;
  } else if (l <= 0.5) {
    s = delta / (max + min);
  } else {
    s = delta / (2 - max - min);
  }

  return [Math.round(h), Math.round(s * 100), Math.round(l * 100)];
}

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };

  return `#${f(0)}${f(8)}${f(4)}`;
}

function usePaintHue(canvas) {
  React.useEffect(() => {
    const ctx = canvas.current.getContext("2d");
    ctx.rect(0, 0, SQUARE_SIZE, BAR_SIZE);

    const gradient = ctx.createLinearGradient(0, 0, SQUARE_SIZE, 0);
    for (let i = 0; i <= 360; i += 30) {
      gradient.addColorStop(i / 360, `hsl(${i}, 100%, 50%)`);
    }
    ctx.fillStyle = gradient;
    ctx.fill();
  }, [canvas]);
}

function usePaintSquare(canvas, hue) {
  React.useEffect(() => {
    const ctx = canvas.current.getContext("2d");
    ctx.fillStyle = `hsl(${hue}, 100%, 50%)`;
    ctx.fillRect(0, 0, SQUARE_SIZE, SQUARE_SIZE);
    const gradientWhite = ctx.createLinearGradient(0, 0, SQUARE_SIZE, 0);
    gradientWhite.addColorStop(0, `rgba(255, 255, 255, 1)`);
    gradientWhite.addColorStop(1, `rgba(255, 255, 255, 0)`);
    ctx.fillStyle = gradientWhite;
    ctx.fillRect(0, 0, SQUARE_SIZE, SQUARE_SIZE);
    const gradientBlack = ctx.createLinearGradient(0, 0, 0, SQUARE_SIZE);
    gradientBlack.addColorStop(0, `rgba(0, 0, 0, 0)`);
    gradientBlack.addColorStop(1, `rgba(0, 0, 0, 1)`);
    ctx.fillStyle = gradientBlack;
    ctx.fillRect(0, 0, SQUARE_SIZE, SQUARE_SIZE);
  }, [canvas, hue]);
}

export const HueWrapper = styled.div`
  position: relative;
  width: ${SQUARE_SIZE + "px"};
  height: ${BAR_SIZE + "px"};
  cursor: ew-resize;
`;

export const HueCanvas = styled.canvas.attrs((p) => ({
  width: SQUARE_SIZE,
  height: BAR_SIZE,
}))``;

export const HueHandle = styled.div.attrs<{ left: number; animate: boolean }>(
  (p) => ({
    style: {
      left: p.left + "px",
      transition: p.animate ? "left .25s ease-out" : "0s",
    },
  })
)`
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${BAR_SIZE}px;
  height: ${BAR_SIZE}px;
  pointer-events: none;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Hue = ({ hue, updateColor, animate }) => {
  const hueX = Math.round((SQUARE_SIZE / 360) * hue - BAR_SIZE / 2);

  const bar = React.useRef(null);
  const canvas = React.useRef(null);
  const updateColorRef = React.useRef(updateColor);

  updateColorRef.current = updateColor;

  usePaintHue(canvas);

  React.useEffect(() => {
    function computePosition(e) {
      const viewportOffset = canvas.current?.getBoundingClientRect();

      const offsetLeft = viewportOffset.left + window.pageXOffset;

      return Math.max(0, Math.min(e.clientX - offsetLeft, SQUARE_SIZE));
    }

    function computeHue(x) {
      return Math.round(x * (360 / SQUARE_SIZE));
    }

    const onMouseMove = throttle((e) => {
      const x = computePosition(e);
      const hue = computeHue(x);

      updateColorRef.current({ hue });
    }, THROTTLE_DELAY);

    function onMouseUp(e) {
      const x = computePosition(e);
      const hue = computeHue(x);

      updateColorRef.current({ hue });

      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
    }

    function onMouseDown(e) {
      document.body.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp);
    }

    const barRef = bar.current;
    barRef.addEventListener("mousedown", onMouseDown);

    return () => {
      barRef.removeEventListener("mousedown", onMouseDown);
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  return (
    <HueWrapper ref={bar}>
      <HueHandle left={hueX} animate={animate}>
        <Svg name="handle" />
      </HueHandle>
      <HueCanvas ref={canvas} />
    </HueWrapper>
  );
};

const Svg = ({ name, ...rest }) => {
  return (
    <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {name === "check" && (
        <>
          <path d="M2.2326 30.1008l3.7139-5.9333L23.072 34.8882l19.2522-30.41 5.914 3.744-23.001 36.3315-5.914-3.744.0037-.0067z" />
        </>
      )}
      {name === "cross" && (
        <>
          <path
            d="M34.3656 3.0593000000000004A24.084 24.084 0 1 0 15.166 47.2354 24.084 24.084 0 1 0 34.3656 3.0593zM32.0123 8.474a18.18 18.18 0 0 1-14.493 33.3467A18.18 18.18 0 0 1 32.0123 8.474z"
            fill="#dfdfdf"
          />
          <path
            d="M36.7852 11.4797A18.168 18.168 0 1 0 12.8026 38.777a18.168 18.168 0 1 0 23.9826-27.2973zM33.6263 15.0752a13.382 13.382 0 0 1-17.6649 20.1063 13.382 13.382 0 0 1 17.6649-20.1063z"
            fill="#363636"
          />
        </>
      )}
      {name === "handle" && (
        <>
          <path
            d="M34.736998 0v49.921h-5.578V0zm-16.737 49.921V0h5.578v49.921z"
            fill="#dfdfdf"
          />
          <path
            fill="#363636"
            d="M31.371873.078V50h-2.316V.078zM23.470873 0v49.922h-2.316V0z"
          />
        </>
      )}
    </svg>
  );
};

export const SquareWrapper = styled.div`
  position: relative;
  width: ${SQUARE_SIZE + "px"};
  height: ${SQUARE_SIZE + "px"};
  cursor: crosshair;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
`;

export const Canvas = styled.canvas.attrs((p) => ({
  width: SQUARE_SIZE,
  height: SQUARE_SIZE,
}))``;

export const Cross = styled.div.attrs<{
  top: number;
  left: number;
  animate: boolean;
}>((p) => ({
  style: {
    top: p.top + "px",
    left: p.left + "px",
    width: CROSS_SIZE + "px",
    height: CROSS_SIZE + "px",
    transition: p.animate ? "top .25s ease-out, left .25s ease-out" : "0s",
  },
}))`
  position: absolute;
  display: grid;
  justify-items: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Square = ({ hue, saturation, lightness, animate, updateColor }) => {
  const squareXY = React.useMemo(
    () => computeSquareXY(saturation, lightness),
    [saturation, lightness]
  );

  const square = React.useRef(null);
  const canvas = React.useRef(null);
  const updateColorRef = React.useRef(updateColor);

  updateColorRef.current = updateColor;

  usePaintSquare(canvas, hue);

  React.useEffect(() => {
    const canvasRef = canvas.current;
    const ctx = canvasRef.getContext("2d");

    function computePosition(e) {
      const viewportOffset = canvasRef.getBoundingClientRect();

      const offsetTop = viewportOffset.top + window.pageYOffset;
      const offsetLeft = viewportOffset.left + window.pageXOffset;

      const x = Math.max(0, Math.min(e.clientX - offsetLeft, SQUARE_SIZE));

      const y = Math.max(0, Math.min(e.clientY - offsetTop, SQUARE_SIZE));

      return [x, y];
    }

    function changeColor(e) {
      const [x, y] = computePosition(e);

      const hsb_s = Math.round((x / SQUARE_SIZE) * 100);
      const hsb_b = Math.round((1 - y / SQUARE_SIZE) * 100);

      const l = ((2 - hsb_s / 100) * hsb_b) / 2;
      const s = (hsb_s * hsb_b) / (l < 50 ? l * 2 : 200 - l * 2) || 0;

      updateColorRef.current({ saturation: s, lightness: l });
    }

    const onMouseMove = throttle((e) => {
      changeColor(e);
    }, THROTTLE_DELAY);

    function onMouseUp(e) {
      changeColor(e);
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
    }

    function onMouseDown(e) {
      document.body.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp);
    }

    canvasRef.addEventListener("mousedown", onMouseDown);

    return () => {
      canvasRef.removeEventListener("mousedown", onMouseDown);
    };
  }, []);

  return (
    <SquareWrapper ref={square}>
      <Cross top={squareXY[1]} left={squareXY[0]} animate={animate}>
        <Svg name="cross" />
      </Cross>
      <Canvas ref={canvas} />
    </SquareWrapper>
  );
};

const ColorInputWrapper = styled.div`
  width: 100%;

  margin: 0 3px;
  display: flex;
  align-items: center;
  user-select: none;
  label {
    font-size: 10px;
    margin-right: 5px;
  }
  input {
    font-family: monospace;
    font-size: 10px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const ColorInput = ({ label, value, max, min, setValue }) => {
  function onChange(e) {
    const value = Math.max(min, Math.min(max, parseInt(e.target.value) || 0));

    setValue(value);
  }

  return (
    <ColorInputWrapper>
      <label>{label}</label>
      <Input style={{ width: "100%" }} value={value} onChange={onChange} />
    </ColorInputWrapper>
  );
};

const ColorInputHex = ({ label, value, setValue }) => {
  return (
    <ColorInputWrapper>
      <label>{label}</label>
      <Input
        autoFocus
        style={{ width: "100%" }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </ColorInputWrapper>
  );
};

const ColorSpaceToggleInnerElement = styled.button`
  padding: 2px 0 0 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 14px;
  margin-right: 10px;
`;

const ColorSpaceToggle = ({ value, onChange }) => {
  return (
    <ColorSpaceToggleInnerElement
      children={<Icon icon="fa:sort" />}
      onClick={() => {
        const index = COLOR_SPACES.indexOf(value);
        const nextIndex = (index + 1) % COLOR_SPACES.length;
        const nextValue = COLOR_SPACES[nextIndex];

        onChange(nextValue);
      }}
    />
  );
};

const PickerInner = styled.div`
  user-select: none;

  > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Inputs = styled.div`
  width: ${SQUARE_SIZE}px;
  display: flex;
  align-items: center;
  justify-items: center;
`;

const InputsInner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  margin: 0 -3px;
`;

function computeSquareXY(s, l) {
  const t = (s * (l < 50 ? l : 100 - l)) / 100;
  const s1 = Math.round((200 * t) / (l + t)) | 0;
  const b1 = Math.round(t + l);
  const x = (SQUARE_SIZE / 100) * s1 - CROSS_SIZE / 2;
  const y = SQUARE_SIZE - (SQUARE_SIZE / 100) * b1 - CROSS_SIZE / 2;
  return [x, y];
}

interface Props {
  value: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
}

export const ColorPicker = React.memo<Props>(function ColorPicker({
  value,
  onChange,
  ...inputProps
}) {
  const [animate, setAnimate] = React.useState(false);

  const [space, hue, saturation, lightness] = React.useMemo(
    () => parseColorToHsl(value),
    [value]
  );

  const [r, g, b] = React.useMemo(() => parseColorToRGB(value), [value]);

  const updateColor = React.useCallback(
    (c: {
      space?: string;
      hue?: number;
      saturation?: number;
      lightness?: number;
    }) => {
      const cs = c.space || space;
      const h = c.hue || hue;
      const s = c.saturation || saturation;
      const l = c.lightness || lightness;

      if (cs === "rgb") {
        const [r, g, b] = convertHSLtoRGB(h, s, l);

        const rgb = `rgb(${r}, ${g}, ${b})`;

        onChange(rgb);
      } else if (cs === "hsl") {
        const hsl = `hsl(${h}, ${s}%, ${l}%)`;

        onChange(hsl);
      } else {
        const hex = hslToHex(h, s, l);

        onChange(hex);
      }
    },
    [space, hue, saturation, lightness, onChange]
  );

  function onHueChange(hue) {
    setAnimate(true);
    updateColor({ hue });
  }

  function onSaturationChange(saturation) {
    setAnimate(true);
    updateColor({ saturation });
  }

  function onLightnessChange(lightness) {
    setAnimate(true);
    updateColor({ lightness });
  }

  function onRedChange(r) {
    setAnimate(true);

    const rgb = `rgb(${r}, ${g}, ${b})`;
    onChange(rgb);
  }

  function onGreenChange(g) {
    setAnimate(true);

    const rgb = `rgb(${r}, ${g}, ${b})`;
    onChange(rgb);
  }

  function onBlueChange(b) {
    setAnimate(true);

    const rgb = `rgb(${r}, ${g}, ${b})`;
    onChange(rgb);
  }

  return (
    <>
      <Popover
        trigger="click"
        content={
          <PickerInner>
            <Square
              hue={hue}
              saturation={saturation}
              lightness={lightness}
              animate={animate}
              updateColor={updateColor}
            />
            <Hue hue={hue} animate={animate} updateColor={updateColor} />
            <Inputs>
              <ColorSpaceToggle
                value={space}
                onChange={(nextSpace) => {
                  updateColor({ space: nextSpace });
                }}
              />
              <InputsInner>
                {space === "hsl" && (
                  <ColorInput
                    label="H"
                    value={hue}
                    min={0}
                    max={360}
                    setValue={onHueChange}
                  />
                )}

                {space === "hsl" && (
                  <ColorInput
                    label="S"
                    value={saturation}
                    min={0}
                    max={100}
                    setValue={onSaturationChange}
                  />
                )}

                {space === "hsl" && (
                  <ColorInput
                    label="L"
                    value={lightness}
                    min={0}
                    max={100}
                    setValue={onLightnessChange}
                  />
                )}

                {space === "rgb" && (
                  <ColorInput
                    label="R"
                    value={r}
                    min={0}
                    max={255}
                    setValue={onRedChange}
                  />
                )}

                {space === "rgb" && (
                  <ColorInput
                    label="G"
                    value={g}
                    min={0}
                    max={255}
                    setValue={onGreenChange}
                  />
                )}

                {space === "rgb" && (
                  <ColorInput
                    label="B"
                    value={b}
                    min={0}
                    max={255}
                    setValue={onBlueChange}
                  />
                )}

                {space === "hex" && (
                  <ColorInputHex
                    label="hex"
                    value={value}
                    setValue={onChange}
                  />
                )}
              </InputsInner>
            </Inputs>
          </PickerInner>
        }
      >
        <Input
          {...inputProps}
          readOnly
          value={value}
          prefix={
            <div
              style={{
                background: value,
                width: 22,
                height: 22,
                borderRadius: 2,
                marginLeft: -7,
              }}
            />
          }
        />
      </Popover>
    </>
  );
});
