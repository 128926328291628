import Parse from "parse";
import React from "react";

import { Modal } from "antd";

import { useTranslation } from "@opendash/i18n";

import { AclDialog } from "./dialogs/AclDialog";
import { ObjectDialog } from "./dialogs/ObjectDialog";
import { RelationDialog } from "./dialogs/RelationDialog";

import { useFeedback } from "@opendash/core";
import { AnyDialog } from "../types";

type Props = {
  dialog: AnyDialog;
};

export const ParseDialog = React.memo<Props>(function ParseDialog({ dialog }) {
  const t = useTranslation();
  const { message } = useFeedback();

  if (dialog?.type === "delete") {
    console.log("delete", dialog);
    Modal.destroyAll();
    Modal.confirm({
      title: t("parse-admin:admin.delete.confirm_title"),
      content: t("parse-admin:admin.delete.confirm_message", {
        count: dialog.objectIds.length,
      }),
      okText: t("parse-admin:admin.delete.confirm_ok"),
      okType: "danger",
      cancelText: t("parse-admin:admin.delete.confirm_cancel"),
      onOk() {
        const objects = dialog.objectIds.map(
          (id) => new Parse.Object(dialog.className, { id })
        );

        Parse.Object.destroyAll(objects).then(
          () => {
            message.success(
              t("parse-admin:admin.delete.success", {
                count: dialog.objectIds.length,
              })
            );

            dialog.resolve(true);
            dialog.close();
          },
          (error) => {
            message.error(t("parse-admin:admin.delete.error"));

            dialog.reject(error);
            dialog.close();
          }
        );
      },
      onCancel() {
        dialog.resolve(false);
        dialog.close();
      },
    });
  }
  React.useEffect(() => {
    if (dialog?.type === "delete") {
      console.log("delete", dialog);

      Modal.confirm({
        title: t("parse-admin:admin.delete.confirm_title"),
        content: t("parse-admin:admin.delete.confirm_message", {
          count: dialog.objectIds.length,
        }),
        okText: t("parse-admin:admin.delete.confirm_ok"),
        okType: "danger",
        cancelText: t("parse-admin:admin.delete.confirm_cancel"),
        onOk() {
          const objects = dialog.objectIds.map(
            (id) => new Parse.Object(dialog.className, { id })
          );

          Parse.Object.destroyAll(objects).then(
            () => {
              message.success(
                t("parse-admin:admin.delete.success", {
                  count: dialog.objectIds.length,
                })
              );

              dialog.resolve(true);
              dialog.close();
            },
            (error) => {
              message.error(t("parse-admin:admin.delete.error"));

              dialog.reject(error);
              dialog.close();
            }
          );
        },
        onCancel() {
          dialog.resolve(false);
          dialog.close();
        },
      });
    }
    return () => {
      Modal.destroyAll();
    };
  }, [dialog?.type]);

  if (dialog?.type === "open") {
    return (
      <ObjectDialog
        className={dialog.object.className}
        object={dialog.object}
        fields={dialog.fields}
        type={dialog.drawer ? "drawer" : "modal"}
        onClose={(objectIdOrNull) => {
          dialog.resolve(objectIdOrNull);
          dialog.close();
        }}
      />
    );
  }

  if (dialog?.type === "create" || dialog?.type === "update") {
    return (
      <ObjectDialog
        className={dialog.className}
        object={dialog.object}
        fields={dialog.fields}
        onClose={(objectIdOrNull) => {
          dialog.resolve(objectIdOrNull);
          dialog.close();
        }}
      />
    );
  }

  if (dialog?.type === "acl") {
    return (
      <AclDialog
        object={dialog.object}
        hideSelf={dialog.hideSelf}
        hideUsers={dialog.hideUsers}
        hideRoles={dialog.hideRoles}
        hideRead={dialog.hideRead}
        hideWrite={dialog.hideWrite}
        readLabel={dialog.readLabel}
        writeLabel={dialog.writeLabel}
        onClose={(saved) => {
          dialog.resolve(saved);
          dialog.close();
        }}
      />
    );
  }

  if (dialog?.type === "relation") {
    return (
      <RelationDialog
        object={dialog.object}
        field={dialog.field}
        onClose={() => {
          dialog.resolve();
          dialog.close();
        }}
      />
    );
  }

  return null;
});
