import { Form } from "antd";
import React, { useEffect } from "react";
import { useTypedWatch } from "./hooks/useTypedWatch";
import ClassFieldSelect from "./inputs/ClassFieldSelect";
import ClassSelect from "./inputs/ClassSelect";
import OperationList from "./inputs/OperationList";
import { Operation } from "./types/FormValues";

type Props = {};

export default (props: Props) => {
  const form = Form.useFormInstance();
  const className = useTypedWatch("className");
  const classFields = useTypedWatch("keys");
  const operations = useTypedWatch("operations");

  // reset fields depending on the parse class selection
  useEffect(() => {
    form.resetFields(["keys"]);
    form.resetFields(["operations"]);
  }, [className]);

  // reset fields depending on the parse class fields selection
  useEffect(() => {
    if (!operations) return;

    const invalidOperations = operations.filter(
      (op: Operation) => !classFields?.includes(op?.field?.value)
    );

    form.setFieldsValue({
      operations: operations.filter(
        (o) =>
          !invalidOperations.some((op) => op?.field.value === o?.field.value)
      ),
    });
  }, [classFields]);

  return (
    <>
      <ClassSelect />
      {className && <ClassFieldSelect />}
      {classFields && <OperationList />}
    </>
  );
};
