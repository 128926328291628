import { useTranslation } from "@opendash/i18n";
import { $parse } from "@opendash/plugin-parse";
import { Form, Select } from "antd";
import React, { useMemo } from "react";

type Props = {};
export default (props: Props) => {
  const t = useTranslation();

  const options = useMemo(() => {
    return $parse.server.schema.map((c) => {
      const translationString = "classes." + c.className;
      const label =
        t("openware:" + translationString) === translationString
          ? c.className
          : t("openware:" + translationString);

      return {
        label: label,
        value: c.className,
      };
    });
  }, [$parse.server.schema, $parse.server.schema.length]);

  return (
    <Form.Item name="className" label="Parse-Klasse">
      <Select options={options} showSearch />
    </Form.Item>
  );
};
