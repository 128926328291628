import * as React from "react";

import { Navigate } from "@opendash/router";
import {
  Container,
  ContainerInner,
  Element,
  FrontPageHero,
  HeroElement,
} from "./OpenDashFrontpage.layout";

import {
  $framework,
  NavigationItemInterface,
  useNavigation,
  useTranslation,
} from "..";

export const OpenDashFrontpage = React.memo(function OpenDashFrontpage() {
  const t = useTranslation();
  const [, items] = useNavigation("frontpage");

  const orderSortFunction = (
    a: NavigationItemInterface,
    b: NavigationItemInterface
  ) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  const elements: Array<NavigationItemInterface> = React.useMemo(
    () =>
      items
        .sort(orderSortFunction)
        .filter((item) => item.link)
        .filter((item) => item.icon)
        .filter((item) => item.color)
        .map((item) => ({
          ...item,
          onClick: () => {
            if (item.link) {
              if (typeof item.link === "string") {
                if (
                  item.link.startsWith("https://") ||
                  item.link.startsWith("http://") ||
                  item.link.startsWith("tel:") ||
                  item.link.startsWith("mailto:")
                ) {
                  window.open(item.link);
                } else {
                  $framework.router.navigate(item.link);
                }
              } else if (typeof item.link === "function") {
                (item.link as () => void)();
              }
            }
          },
        })),
    [items]
  );

  if (elements.length === 1) {
    if (elements[0].link && typeof elements[0].link === "string") {
      return <Navigate to={elements[0].link} />;
    }
  }

  /**
   * Only elements with an order higher than 100 will be displayed.
   * @param element An element of type NavigationItemInterface
   * @returns The element as a card for the frontpage
   */
  function getHeroElement(element: NavigationItemInterface) {
    const elementcount = elements.filter((parentelement) => {
      if (typeof parentelement.group !== "undefined") {
        return parentelement.group === element.group;
      }
      return false;
    });

    if (elementcount.length > 1 && element.order > 100) {
      return <HeroElement key={element.id} {...element} />;
    }
    return;
  }

  function countHeroElements() {
    const elementcount = elements.map((element) => {
      return elements.filter((parentelement) => {
        if (typeof parentelement.group !== "undefined") {
          return parentelement.group === element.group && element.order > 100;
        }
        return false;
      });
    });

    return elementcount.some((value) => value.length > 0);
  }

  function getElement(e: NavigationItemInterface) {
    //Exclude child elements
    //Those will be put to the hero
    const groupelements = elements
      .filter((elem: NavigationItemInterface) => elem.group === e.group)
      .sort(orderSortFunction);

    if (groupelements.length > 0 && groupelements[0].id !== e.id) {
      return null;
    }

    //Get top level elements
    return <Element key={e.id} {...e} />;
  }

  return (
    <>
      <Container style={{ margin: "auto" }}>
        <ContainerInner>
          {elements.map((e) => getElement(e))}

          {elements.length === 0 && t("opendash:frontpage.empty")}
        </ContainerInner>
        {countHeroElements() ? (
          <FrontPageHero>
            {elements.map((e) => getHeroElement(e))}
          </FrontPageHero>
        ) : null}
      </Container>
    </>
  );
});
