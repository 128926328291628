import { List } from "antd";
import React from "react";

import { createComponent } from "@opendash/state";

import type { ParseFilterState } from "../../state/ParseFilterState";
import { CardViewType } from "../../types";
import { ParseObjectCard } from "../ParseObjectCard";

interface Props {
  state: ParseFilterState;
  view: CardViewType;
}

export const CardView = createComponent<Props>(function CardView({
  state,
  view,
}) {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 5,
        xxl: 6,
      }}
      rowKey={(row) => row.id}
      dataSource={state.data.result}
      loading={state.data.loading}
      pagination={state.paginationConfig}
      renderItem={(object) => {
        return (
          <List.Item>
            <ParseObjectCard
              object={object}
              imageField={view.imageField}
              titleFields={view.titleFields || state.config.titleFields}
              descriptionFields={
                view.descriptionFields || state.config.displayFields
              }
              onChange={() => state.data.reload()}
            />
          </List.Item>
        );
      }}
    />
  );
});
