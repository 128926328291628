import {
  produce,
  SourcePicker,
  useSource,
  useTranslation,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Descriptions, Divider, Empty, Input } from "antd";
import React, { useEffect, useState } from "react";
import { DataItemEditComponentInterface } from "./DataItemEditComponentInterface";

export default (props: DataItemEditComponentInterface) => {
  const { item, setItem, allowVTypAddition } = props;
  const t = useTranslation();
  const [edit, setEdit] = useState(props.edit);
  const [, , sources] = useSource();
  const [source, setSource] = useState(null);

  useEffect(() => {
    setSource(sources.find((source) => source.tag === item.source));
  }, []);

  return (
    <>
      {!props.disableEdit && (
        <Button
          type="link"
          style={{ float: "right" }}
          icon={<Icon icon={edit ? "fa:check" : "fa:edit"} />}
          onClick={() => {
            setEdit(!edit);
          }}
        />
      )}
      <Descriptions
        title={item.name}
        bordered
        items={[
          {
            label: t("opendash:data.item.id"),
            span: 3,
            children: !edit ? (
              item.id
            ) : (
              <Input
                value={item.id}
                onChange={(e) => {
                  setItem(
                    produce(item, (draft) => {
                      draft.meta["id_source"] =
                        draft.meta["id_source"] || draft.id;
                      draft.id = e.target.value;
                    })
                  );
                }}
              />
            ),
          },
          {
            label: t("opendash:data.item.source"),
            span: 3,
            children: !edit ? (
              // For backwards compatibitity look for a user prop.
              // @ts-ignore
              item.source || item.user
            ) : (
              <SourcePicker
                style={{ width: "100%" }}
                value={source}
                onChange={(value) => {
                  setSource(value);
                  setItem(
                    produce(item, (draft) => {
                      // For backwards compatibitity look for a user prop.
                      // @ts-ignore
                      draft.user = value.tag;
                      draft.source = value.tag;
                    })
                  );
                }}
              />
            ),
          },
          {
            label: t("opendash:data.item.name"),
            span: 3,
            children: !edit ? (
              item.name
            ) : (
              <Input
                value={item.name}
                onChange={(e) => {
                  setItem(
                    produce(item, (draft) => {
                      draft.name = e.target.value;
                    })
                  );
                }}
              />
            ),
          },
        ]}
      />

      <Divider />

      <Descriptions
        title={t("opendash:data.item.valueTypes")}
        bordered
        items={item.valueTypes
          .map((vType, index) => {
            return Object.keys(vType).map((key) => {
              return {
                label:
                  key === "unit"
                    ? t("opendash:data.item.vTypeUnit", {
                        index: index + 1,
                      })
                    : key === "name"
                      ? t("opendash:data.item.vTypeName", {
                          index: index + 1,
                        })
                      : "",
                key: index + key,
                children: !edit ? (
                  vType[key]
                ) : (
                  <Input
                    value={vType[key]}
                    onChange={(e) => {
                      setItem(
                        produce(item, (draft) => {
                          draft.valueTypes[index][key] = e.target.value;
                        })
                      );
                    }}
                  />
                ),
              };
            });
          })
          .flat()
          .filter((item) => item.label !== "")}
      />

      {item.valueTypes.length === 0 && <Empty></Empty>}

      {allowVTypAddition && (
        <Button
          type="dashed"
          icon={<Icon icon="fa:plus"></Icon>}
          style={{ width: "100%" }}
          onClick={() => {
            setItem(
              produce(item, (draft) => {
                draft.valueTypes.push({
                  name: t("opendash:data.item.vTypeName", {
                    index: draft.valueTypes.length + 1,
                  }),
                  type: "Number",
                  unit: t("opendash:data.item.vTypeunit", {
                    index: draft.valueTypes.length + 1,
                  }),
                });
              })
            );
          }}
        />
      )}
    </>
  );
};
