import Parse from "parse";
import React from "react";

import { Button, Descriptions } from "antd";

import { useForceRender, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { AdminToolbar } from "@opendash/ui";

import { $parse } from "../state";
import { ParseField } from "./ParseField";
import { ObjectDialog } from "./dialogs/ObjectDialog";
import {
  getObjectTitle,
  translateClassDescription,
  translateClassField,
  translateClassName,
} from "./helper";

interface Props {
  title?: string;
  subTitle?: string;
  object?: Parse.Object | undefined;
  fields?: string[];
  editFields?: string[];
  defaultMode?: "view" | "edit";
  disableView?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  disableObjectFetch?: boolean;
}

export const ParseObject = React.memo<Props>(function ParseObject({
  object,
  ...props
}) {
  if (!object) {
    return null;
  }

  return <ParseObjectInternal object={object} {...props} />;
});

interface PropsInternal extends Props {
  object: Parse.Object;
}

const ParseObjectInternal = React.memo<PropsInternal>(
  function ParseObjectInternal({
    title,
    subTitle,
    object,
    fields: fieldsOverwrite,
    editFields: editFieldsOverwrite,
    defaultMode = "view",
    disableView = false,
    disableEdit = false,
    disableDelete = false,
    disableObjectFetch = false,
  }) {
    const t = useTranslation();
    const forceRender = useForceRender();

    const schema = $parse.ui.schemaMap[object.className];
    const config = $parse.ui.configMap[object.className];

    const fields = React.useMemo(() => {
      return Array.isArray(fieldsOverwrite) && fieldsOverwrite.length > 0
        ? fieldsOverwrite
        : config.displayFields || [];
    }, [fieldsOverwrite, config?.displayFields]);

    const [isEditMode, setEditMode] = React.useState(
      defaultMode === "edit" || disableView
    );

    React.useEffect(() => {
      if (object && !disableObjectFetch) {
        const includes = fields.filter(
          (field) =>
            schema.fields[field]?.type === "Pointer" &&
            schema.fields[field]?.targetClass
        );

        object.fetchWithInclude(includes).then(
          () => {
            forceRender();
          },
          (e) => {
            console.error(e);
          }
        );
      }
    }, [object?.id, disableObjectFetch, fields, schema]);

    return (
      <>
        <AdminToolbar
          title={
            title
              ? title
              : getObjectTitle(
                  object,
                  config,
                  translateClassName(t, object.className)
                )
          }
          description={
            subTitle
              ? subTitle
              : translateClassDescription(t, object.className, 1)
          }
          actions={
            <>
              {!disableView && isEditMode && (
                <Button
                  key="discard"
                  icon={<Icon icon="fa:times" />}
                  onClick={(e) => {
                    setEditMode(false);
                  }}
                  children={t("parse-admin:admin.edit.cancel")}
                />
              )}

              {!isEditMode &&
                $parse.ui.getObjectPermission(
                  object,
                  "update",
                  disableEdit
                ) && (
                  <Button
                    key="edit"
                    type="primary"
                    icon={<Icon icon="fa:edit" />}
                    onClick={(e) => {
                      setEditMode(true);
                    }}
                    children={t("parse-admin:admin.edit.action")}
                  />
                )}

              {$parse.ui.getObjectPermission(
                object,
                "delete",
                disableDelete
              ) && (
                <Button
                  key="delete"
                  danger={true}
                  icon={
                    <Icon
                      icon="fa:trash"
                      key="delete"
                      style={{ color: "#ff4d4d" }}
                    />
                  }
                  onClick={(e) =>
                    $parse.ui
                      .deleteObject(object.className, object.id)
                      .then(() => {
                        // trigger on delete
                      })
                  }
                  children={t("parse-admin:admin.delete.action_single")}
                />
              )}
            </>
          }
        />
        <div className="od-page-main">
          {!isEditMode && (
            <Descriptions bordered column={1}>
              {fields.map((field) => {
                return (
                  <Descriptions.Item
                    key={field}
                    label={translateClassField(t, object.className, field)}
                  >
                    <ParseField
                      object={object}
                      field={field}
                      isInline={false}
                    />
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          )}
          {isEditMode && (
            <ObjectDialog
              type="inline"
              className={object.className}
              object={object}
              fields={editFieldsOverwrite || config.editFields}
              onClose={() => {
                if (!disableView) {
                  setEditMode(false);
                }
              }}
            />
          )}
        </div>
      </>
    );
  }
);
