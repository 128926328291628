import * as React from "react";

const BASE = 1000;
const FACTOR = 0.8;

const INITIAL_STATE: React.CSSProperties = {
  visibility: "hidden",
  position: "absolute",
  fontSize: `16pt`,
  //lineHeight: `${BASE}px`,
  height: "auto",
  width: "auto",
  textAlign: "center",
  // whiteSpace: "nowrap",
};

interface Props {
  width: number;
  height: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const FitText: React.ElementType<Props> = ({
  width,
  height,
  style,
  children,
}) => {
  const element = React.useRef(null);

  React.useEffect(() => {
    if (element.current && width > 0 && height > 0) {
      Object.assign(element.current.style, INITIAL_STATE);

      const factor = Math.max(
        width / element.current.scrollWidth,
        height / element.current.scrollHeight
      );
      const fontRatio = 0.35;
      const text = element.current.textContent.length;
      const fontSize = Math.min(180, Math.max(10, width / text));
      //const fontSize = Math.floor((BASE / factor) * FACTOR);
      //const fontSize = factor;
      Object.assign(element.current.style, {
        visibility: "visible",
        position: "static",
        fontSize: fontSize + "px",
        //lineHeight: height + "px",
        maxHeight: height + "px",
        width: width + "px",
        textAlign: "center",
        //whiteSpace: "nowrap",
      });
    }
  }, [element.current, width, height, children, element.current?.textContent]);

  return (
    <div ref={element} style={style}>
      {children}
    </div>
  );
};
