import styled from "styled-components";

export const rowHeight = 35;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const FilterContainer = styled.div`
  padding: 24px;

  border-bottom: 2px solid #eee;
`;

export const TableContainer = styled.div`
  width: 100%;

  flex: 1;

  overflow: hidden;
`;

export const TableRowHeader = styled.div`
  background: var(--ant-primary-color);
  color: white;
  font-size: 14px;

  height: ${rowHeight}px;
`;

export const TableRowLabels = styled.div`
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #eee;

  font-weight: bold;

  height: ${rowHeight}px;
`;

export const TableRowData = styled.div`
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #eee;

  height: ${rowHeight}px;

  &.even {
    background: #fafafa;
    border-bottom: 1px solid #eee;
  }
`;

export const TableCellFull = styled.div`
  width: 100%;
  padding: 0 5px;
  line-height: ${rowHeight - 1}px;
`;

export const TableCell = styled.div`
  padding: 0 5px;

  width: 100%;

  overflow: hidden;
`;

export const TableCellName = styled(TableCell)`
  flex: 1;

  line-height: ${rowHeight - 1}px;
`;

export const TableCellValue = styled(TableCell)`
  width: 250px;

  &.mobile {
    width: 50%;
  }

  line-height: ${rowHeight - 1}px;
`;

export const TableCellDate = styled(TableCell)`
  width: 120px;

  line-height: ${rowHeight - 1}px;

  &.mobile {
    display: none;
  }
`;

export const TableCellAction = styled(TableCell)`
  width: 40px;

  line-height: 0px;
  padding: ${(rowHeight - 1 - 24 - 2) / 2}px 5px;
`;
