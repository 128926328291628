import {} from "antd";
import React from "react";

import { Icon } from "@opendash/icons";
import { IconPicker } from "@opendash/ui";

export interface StringIconDisplayProps {
  value: string;
}

export const StringIconDisplay = React.memo<StringIconDisplayProps>(
  function StringIconDisplay({ value }) {
    return (
      <>
        <Icon icon={value} /> <span>{value}</span>
      </>
    );
  }
);

export interface StringIconEditProps {
  value: string;
  setValue?: (v: string) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const StringIconEdit = React.memo<StringIconEditProps>(
  function StringIconEdit({ value, setValue }) {
    return <IconPicker value={value} onChange={setValue} />;
  }
);
