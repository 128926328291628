import { createInternalComponent } from "@opendash/core";
import { Route, Routes } from "@opendash/router";
import * as React from "react";
import {
  AlarmManagementRoute,
  CSVExplorerRoute,
  DashboardByIDRoute,
  DashboardRoute,
  ExplorerRoute,
  MonitoringDevRoute,
  MonitoringGlobals,
  WidgetPrint,
  WidgetRoute,
} from "..";

export const MonitoringRoute = createInternalComponent(
  function MonitoringRoute() {
    return (
      <MonitoringGlobals>
        <Routes>
          <Route path="dashboard" element={<DashboardRoute />} />
          <Route path="dashboards" element={<DashboardByIDRoute />} />
          <Route
            path="dashboards/:source/:id"
            element={<DashboardByIDRoute />}
          />
          <Route path="widgets/:source/:id" element={<WidgetRoute />} />
          <Route path="print/:source/:id" element={<WidgetPrint />} />
          <Route path="print/:source" element={<WidgetPrint />} />

          <Route path="explore" element={<ExplorerRoute />} />
          <Route path="csvexplore" element={<CSVExplorerRoute />} />
          <Route path="alarms" element={<AlarmManagementRoute />} />
          <Route path="dev" element={<MonitoringDevRoute />} />
        </Routes>
      </MonitoringGlobals>
    );
  }
);
