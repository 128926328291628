import { Input } from "antd";
import React from "react";

export interface StringDisplayProps {
  value: string;
}

export const StringDisplay = React.memo<StringDisplayProps>(
  function StringDisplay({ value }) {
    return <span>{value}</span>;
  }
);

export interface StringEditProps {
  value: string;
  setValue?: (v: string) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const StringEdit = React.memo<StringEditProps>(function StringEdit({
  value,
  setValue,
}) {
  return <Input value={value} onChange={(e) => setValue(e.target.value)} />;
});
