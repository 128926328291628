import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { InputNumber } from "antd";
import React from "react";

export interface NumberDisplayProps {
  value: number;
}

export const NumberDisplay = React.memo<NumberDisplayProps>(
  function NumberDisplay({ value }) {
    return <span>{value}</span>;
  }
);

export interface NumberEditProps {
  value: number;
  setValue?: (v: number) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const NumberEdit = React.memo<NumberEditProps>(function NumberEdit({
  value,
  setValue,
  isNullable,
}) {
  const t = useTranslation();

  const getDecimalSeperator = () => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(navigator.languages[0])
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === "decimal").value;
  };

  return (
    <InputNumber
      placeholder={t("parse-admin:admin.inputs.number_placeholder")}
      style={{ width: "100%" }}
      parser={(v) => parseFloat(v.toString().replaceAll(",", ".")) || 0}
      value={value}
      decimalSeparator={getDecimalSeperator()}
      onChange={(v) => setValue(parseFloat(v.toString().replaceAll(",", ".")))}
      addonAfter={
        !isNullable ? undefined : (
          <Icon
            icon="fa:undo"
            onClick={() => {
              setValue(null);
            }}
          />
        )
      }
    />
  );
});
