import * as React from "react";

import { Spin } from "antd";

export function Loading({ message }) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: 50,
        textAlign: "center",
      }}
    >
      <Spin
        style={{
          height: "100%",
          width: "100%",
          padding: 50,
          textAlign: "center",
        }}
      />
      {message && <div style={{ paddingTop: 20 }}>{message}</div>}
    </div>
  );
}
