import Parse from "parse";

import {
  StorageAdapterContext,
  StorageAdapterInterface,
  UserInterface,
} from "@opendash/core";

import { AdapterConfig } from "../types";
import { _User, UserData } from "../types-generated";
import { fetchParse } from "./helper";

export class ParseStorageAdapter implements StorageAdapterInterface {
  private userDataCache = new Map<string, Parse.Object>();

  private context: StorageAdapterContext;
  private config: AdapterConfig;

  constructor(config: AdapterConfig) {
    this.config = config;
  }

  onContext(context: StorageAdapterContext) {
    this.context = context;

    this.init();
  }

  onUser(user: UserInterface) {
    this.init();
  }

  private async init() {
    if (!(await Parse.User.currentAsync<_User>())) {
      return;
    }

    this.context.clear();

    if (navigator.onLine) {
      await fetchParse(
        new Parse.Query(UserData).ascending("key").limit(999999),
        (result) => {
          result.forEach((value) => {
            this.userDataCache.set(value.get("key"), value);
            this.context.set(value.get("key"), value.get("value"));
          });
        },
        (key, value) => {
          if (value) {
            this.userDataCache.set(value.get("key"), value);
            this.context.set(value.get("key"), value.get("value"));
          }
        },
        this.config.liveQueries
      );
    }

    this.context.setLoading(false);
  }

  public async set(key: string, value: any): Promise<void> {
    if (!navigator.onLine) {
      // TODO: BETTER OFFLINE SUPPORT
      return;
    }

    const user = await Parse.User.currentAsync<_User>();

    const result = await new Parse.Query(UserData)
      .equalTo("user", user)
      .equalTo("key", key)
      .limit(999999)
      .first();

    if (result) {
      await result.save({ value });
    } else {
      const acl = new Parse.ACL();

      acl.setReadAccess(user, true);
      acl.setWriteAccess(user, true);

      const obj = new UserData({
        user: user,
        key,
        value,
      });

      obj.setACL(acl);

      await obj.save();
    }

    this.context.set(key, value);
  }

  public async delete(key: string): Promise<void> {
    if (!navigator.onLine) {
      // TODO: BETTER OFFLINE SUPPORT
      return;
    }

    const result = await new Parse.Query(UserData)
      .equalTo("user", await Parse.User.currentAsync<_User>())
      .equalTo("key", key)
      .limit(999999)
      .first();

    await result.destroy();

    this.context.delete(key);
  }

  public async clear(): Promise<void> {}
}
