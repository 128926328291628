import { Breadcrumb, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { $parse } from "@opendash/plugin-parse";
import { Description } from "@opendash/ui";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Switch,
  Typography,
} from "antd";
import React, { useState } from "react";
import styled, { CSSProperties } from "styled-components";
import { Report } from "./Report";
import { asDrawer as ReportEditor } from "./reports/generics/common/GenericSettingsModal";
import { useReportApi } from "./useReportApi";

const ReportCardListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 24px;
`;

export function ReportingLayout({ children }) {
  return (
    <div style={{ minWidth: 1280, padding: "0 50px" }}>
      <Breadcrumb style={{ margin: "16px 0" }}></Breadcrumb>
      <Layout style={{ padding: "24px 0", background: "#fff" }}>
        <Layout.Content style={{ padding: "0 24px" }}>
          {children}
        </Layout.Content>
      </Layout>
    </div>
  );
}

export function ReportCardList(props: {
  setCreate: (e: boolean) => void;
  reports: Report[];
  onUpdate: (updates: Report[]) => void;
}) {
  /*if (!props.reports || props.reports.length === 0) {
    return <Empty />;
  }*/
  return (
    <ReportCardListContainer>
      <Row gutter={16} style={{ width: "100%" }}>
        {/*<Col sm={6} ><BasicReportCard setCreate={props.setCreate} /></Col> FOR ARENS ONLY */}

        {props.reports
          .slice()
          .sort((a, b) => {
            if (
              a.get("name").includes("Gesamt") &&
              !b.get("name").includes("Gesamt")
            ) {
              return -1;
            } else if (
              !a.get("name").includes("Gesamt") &&
              b.get("name").includes("Gesamt")
            ) {
              return 1;
            } else {
              return a.get("name").localeCompare(b.get("name"));
            }
          })
          .map((report: Report) => (
            <Col sm={props.reports.length > 1 ? 6 : ""}>
              <ReportCard
                key={report.id}
                report={report}
                onUpdate={(update) => {
                  props.onUpdate([update]);
                }}
              />
            </Col>
          ))}
      </Row>
    </ReportCardListContainer>
  );
}

function BasicReportCard(props: { setCreate: (e: boolean) => void }) {
  const t = useTranslation();

  return (
    <Card
      style={{ marginBottom: 24 }}
      loading={false}
      actions={[
        <div
          onClick={() => {
            props.setCreate(true);
          }}
        >
          <Icon icon="fa:plus" key="download" />
        </div>,
      ]}
    >
      <Card.Meta
        title={t("openware:reporting.basic.title")}
        description={""}
        avatar={
          <Icon
            icon="fa:file-word"
            style={{ fontSize: 35, color: "#1B5EBE" }}
          />
        }
      />

      <div style={{ minHeight: "4em", paddingTop: "10px" }}>
        <Typography.Paragraph
          ellipsis={{ rows: 3, expandable: true, symbol: ">" }}
        >
          {t("openware:reporting.basic.desc")}
        </Typography.Paragraph>
      </div>
    </Card>
  );
}

function ReportCard(props: {
  report: Report;
  onUpdate: (report: Report) => void;
}) {
  const api = useReportApi();
  const [edit, setEdit] = useState<boolean>(false);
  const [download, setDownload] = useState<Report>(null);
  const [reportInterval, setReportInterval] = useState<number[]>(null);
  const [reference, setReference] = useState<string>(null);
  const [includeRef, setIncludeRef] = useState<boolean>(false);
  const datePickValues = props.report.get("options").datePickValues || [
    "day",
    "week",
    "month",
    "year",
    "custom",
  ];
  const [pickerMode, setPickerMode] = useState<
    "date" | "week" | "month" | "year" | "custom"
  >(datePickValues && datePickValues.length > 0 ? datePickValues[0] : "date");

  const showRef =
    props.report.get("options").showRef == undefined
      ? true
      : props.report.get("options").showRef;

  const deletable =
    props.report.get("options").deletable == undefined
      ? true
      : props.report.get("options").deletable;

  const editable = $parse.ui.getObjectPermission(props.report, "acl"); //hasWriteAccess(Parse.User.current(), props.report);
  const t = useTranslation();
  const setTime = (date, dateString) => {
    if (!date) {
      setReportInterval(null);
      return;
    }

    setReportInterval([
      date.startOf(pickerMode).valueOf(),
      date.endOf(pickerMode).valueOf(),
    ]);
  };
  const setRange = (start, end) => {
    if (!start || !end) {
      setReportInterval(null);
      return;
    }
    setReportInterval([start.valueOf(), end.valueOf()]);
  };

  const getActions = () => {
    return deletable && editable
      ? [
          <div
            onClick={() => {
              setDownload(props.report);
            }}
          >
            <Icon icon="fa:download" key="download" />
          </div>,
          <div
            onClick={() => {
              api.deleteWithConfirm(props.report);
            }}
          >
            {deletable}
            <Icon icon="fa:trash" key="delete" />
          </div>,
          <div
            onClick={() => {
              setEdit(true);
            }}
          >
            <Icon icon="fa:pen" key="edit" />
          </div>,
        ]
      : deletable && !editable
        ? [
            <div
              onClick={() => {
                setDownload(props.report);
              }}
            >
              <Icon icon="fa:download" key="download" />
            </div>,
            <div
              onClick={() => {
                api.deleteWithConfirm(props.report);
              }}
            >
              {deletable}
              <Icon icon="fa:trash" key="delete" />
            </div>,
          ]
        : !deletable && editable
          ? [
              <div
                onClick={() => {
                  setDownload(props.report);
                }}
              >
                <Icon icon="fa:download" key="download" />
              </div>,
              <div
                onClick={() => {
                  setEdit(true);
                }}
              >
                <Icon icon="fa:pen" key="edit" />
              </div>,
            ]
          : [
              <div
                onClick={() => {
                  setDownload(props.report);
                }}
              >
                <Icon icon="fa:download" key="download" />
              </div>,
            ];
  };

  const radioStyle = {
    width: 100 / datePickValues.length + "%",
    textAlign: "center",
  } as CSSProperties;
  return (
    <Card style={{ marginBottom: 24 }} loading={false} actions={getActions()}>
      {props.report.get("icon") ? (
        <Card.Meta
          title={props.report.get("name")}
          description={
            <div style={{ marginTop: "-8px" }}>{props.report.get("title")}</div>
          }
          avatar={
            <Icon
              icon={props.report.get("icon")}
              style={{
                fontSize: 35,
                color: props.report.get("iconColor") ?? "#000",
              }}
            />
          }
        />
      ) : props.report.get("type") === "xlsx" ? (
        <Card.Meta
          title={props.report.get("name")}
          description={
            <div style={{ marginTop: "-8px" }}>{props.report.get("title")}</div>
          }
          avatar={
            <Icon
              icon="fa:file-excel"
              style={{ fontSize: 35, color: "#008000" }}
            />
          }
        />
      ) : (
        <Card.Meta
          title={props.report.get("name")}
          description={
            <div style={{ marginTop: "-8px" }}>{props.report.get("title")}</div>
          }
          avatar={
            <Icon
              icon="fa:file-word"
              style={{ fontSize: 35, color: "#1B5EBE" }}
            />
          }
        />
      )}

      <div style={{ minHeight: "4em", paddingTop: "10px" }}>
        <Typography.Paragraph
          style={{ minHeight: "66px" }}
          ellipsis={{ rows: 3, expandable: true, symbol: ">" }}
        >
          {props.report.get("description")}
        </Typography.Paragraph>
        <ReportEditor
          open={edit}
          onClose={() => {
            setEdit(false);
          }}
          onUpdate={(update) => {
            props.onUpdate(update);
          }}
          report={props.report}
        />
      </div>
      <Modal
        title={download?.get("name") || ""}
        open={!!download}
        okButtonProps={{
          disabled: !(
            reportInterval &&
            reportInterval.length == 2 &&
            reportInterval[0] <= reportInterval[1]
          ),
        }}
        onOk={() => {
          if (
            reportInterval &&
            reportInterval.length == 2 &&
            reportInterval[0] <= reportInterval[1]
          ) {
            api.download(props.report, reportInterval, reference);
          }
        }}
        onCancel={() => setDownload(null)}
      >
        <Description>
          {t("opendash:monitoring.explorer.step_fetching_title")}
        </Description>
        <Radio.Group
          defaultValue={
            datePickValues && datePickValues.length > 0
              ? datePickValues[0]
              : "date"
          }
          buttonStyle="solid"
          style={{ width: "100%", marginBottom: "10px" }}
          onChange={(mode) => {
            const value = mode.target.value;
            setPickerMode(value);
          }}
        >
          {datePickValues.includes("date") && (
            <Radio.Button value="date" style={radioStyle}>
              {t("opendash:ui.day")}
            </Radio.Button>
          )}
          {datePickValues.includes("week") && (
            <Radio.Button value="week" style={radioStyle}>
              {t("opendash:ui.week")}
            </Radio.Button>
          )}
          {datePickValues.includes("month") && (
            <Radio.Button value="month" style={radioStyle}>
              {t("opendash:ui.month")}
            </Radio.Button>
          )}
          {datePickValues.includes("year") && (
            <Radio.Button value="year" style={radioStyle}>
              {t("opendash:ui.year")}
            </Radio.Button>
          )}
          {datePickValues.includes("custom") && (
            <Radio.Button value="custom" style={radioStyle}>
              {t("opendash:monitoring.explorer.step_fetching_title")}
            </Radio.Button>
          )}
        </Radio.Group>

        {pickerMode !== "custom" && (
          <DatePicker
            style={{ width: "100%" }}
            size="large"
            picker={pickerMode}
            onChange={setTime}
          />
        )}
        {pickerMode === "custom" && (
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            size="large"
            onChange={setRange}
          />
        )}
        {showRef && (
          <>
            <Divider />
            <Description>
              {t("bde:app.order.id")}{" "}
              <Switch
                checked={includeRef}
                onChange={(val) => {
                  setIncludeRef(val);
                  if (!val) {
                    setReference(null);
                  }
                }}
              ></Switch>
            </Description>
          </>
        )}
        {includeRef && (
          <Input
            style={{ width: "100%" }}
            value={reference}
            onChange={(e) => {
              const value = e.target.value;

              setReference(value);
            }}
          />
        )}
      </Modal>
    </Card>
  );
}
