import { createComponent } from "@opendash/state";
import React from "react";
import { $parse } from "../..";

export const AuthVerifyEmailRoute = createComponent(
  function AuthVerifyEmailRoute() {
    React.useEffect(() => {
      run();
      async function run() {
        if (!$parse.auth.loading) {
          await $parse.auth.validateToken("verify-email");
          await $parse.auth.sendEmailVerification();
        }
      }
    }, [$parse.auth.loading]);
    return null;
  }
);
