import { makeAutoObservable } from "@opendash/state";
import { PointType, PolygonType } from "../types";
import type { GeoService } from "./Geo";

export interface BaseDialog<T> {
  resolve: (response?: T) => void;
  reject: (error: Error) => void;
}

interface ShowPointDialog extends BaseDialog<void> {
  latitude: number;
  longitude: number;
}

interface EditPointDialog extends BaseDialog<PointType> {
  latitude: number;
  longitude: number;
}

interface ShowPolygonDialog extends BaseDialog<void> {
  polygon: PolygonType;
}

export class DialogService {
  private geo: GeoService;

  public __showPointDialog: ShowPointDialog | null = null;
  public __editPointDialog: EditPointDialog | null = null;
  public __showPolygonDialog: ShowPolygonDialog | null = null;

  constructor(geo: GeoService) {
    this.geo = geo;

    makeAutoObservable(this);

    // this.geo.location.getCurrentPosition().then((point) => {
    //   this.showPointDialog(point);
    // });
  }

  public showPointDialog(point: PointType) {
    return new Promise((resolve, reject) => {
      this.__showPointDialog = {
        ...point,
        resolve,
        reject,
      };
    });
  }

  public cancelShowPointDialog() {
    if (this.__showPointDialog) {
      this.__showPointDialog.resolve();
      this.__showPointDialog = null;
    }
  }

  public updateShowPointDialogPosition(point: PointType) {
    if (this.__showPointDialog) {
      this.__showPointDialog.latitude = point.latitude;
      this.__showPointDialog.longitude = point.longitude;
    }
  }

  public async editPointDialog(point?: PointType) {
    const point2 = point || (await this.geo.location.getCurrentPosition());

    return new Promise<PointType | void>((resolve, reject) => {
      this.__editPointDialog = {
        ...point2,
        resolve,
        reject,
      };
    });
  }

  public resolveEditPointDialog() {
    if (this.__editPointDialog) {
      this.__editPointDialog.resolve({
        latitude: this.__editPointDialog.latitude,
        longitude: this.__editPointDialog.longitude,
      });

      this.__editPointDialog = null;
    }
  }

  public cancelEditPointDialog() {
    if (this.__editPointDialog) {
      this.__editPointDialog.resolve();
      this.__editPointDialog = null;
    }
  }

  public updateEditPointDialogPosition(point: PointType) {
    if (this.__editPointDialog) {
      this.__editPointDialog.latitude = point.latitude;
      this.__editPointDialog.longitude = point.longitude;
    }
  }

  public showPolygonDialog(polygon: PolygonType) {
    return new Promise((resolve, reject) => {
      this.__showPolygonDialog = {
        polygon,
        resolve,
        reject,
      };
    });
  }

  public cancelShowPolygonDialog() {
    if (this.__showPolygonDialog) {
      this.__showPolygonDialog.resolve();
      this.__showPolygonDialog = null;
    }
  }

  public updateShowPolygonDialogPosition(polygon: PolygonType) {
    if (this.__showPolygonDialog) {
      this.__showPolygonDialog.polygon = polygon;
    }
  }
}
