import { useParams } from "@opendash/router";
import dayjs from "dayjs";
import produce from "immer";
import Parse from "parse";
import React from "react";
import styled from "styled-components";

import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Result,
  Row,
  Select,
  Statistic,
  Switch,
} from "antd";
// import  { InfoCircleOutlined } from "@ant-design/icons";
import { useLocalStorage, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { configProvider } from "../../..";

const Toggle = styled.div`
  & .ant-switch-handle {
    left: 1px;
    top: 0px;
    width: 100px;
    height: 100px;
    &::before {
      border-radius: 50px;
    }
  }
  & .ant-switch.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 100px) !important;
  }
`;
const { Option } = Select;
export default (props) => {
  const locale = (useLocalStorage("opendash:language")[0] as string) || "de";
  dayjs.locale(locale);
  const t = useTranslation();
  const data = props.Data;
  const formData = props.FormData;
  const urlParams = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryparams = Object.fromEntries(urlSearchParams.entries());
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [now, setNow] = React.useState(new Date().getTime());
  const [events, setEvents] = React.useState({
    newEvent: {
      start: true,
      time: new Date().getTime(),
    },
    lastEvent: {
      start: false,
      time: new Date().getTime(),
    },
  });
  const getLastEventFromParse = async () => {
    if (!configProvider.config.results.table) return;
    const q = new Parse.Query(configProvider.config.results.table);
    q.equalTo("result.form.objectId", urlParams.form);
    q.equalTo("result.source.objectId", urlParams.unit);
    q.equalTo("result.user.username", Parse.User.current().getUsername());
    if (queryparams.reference !== "null")
      q.equalTo("result.reference", queryparams.reference);
    q.descending("createdAt");
    const last = (await q.first()).toJSON();

    if (last.result.form.form) {
      Object.keys(last.result.form.form)
        .filter((key) => key.indexOf("step") === 0)
        .forEach((key) => {
          const field = last.result.form.form[key].fields.find(
            (field) => field.key === data.key
          );
          if (field) {
            setEvents({
              lastEvent: {
                start: field.value[0],
                time: last.result.saveDate,
              },
              newEvent: {
                start: !field.value[0],
                time: new Date().getTime(),
              },
            });
          }
        });
    }
  };

  const rules = [];
  if (data["v_required"] && data["v_required"].value) {
    rules.push({
      required: t("bde:app.forms.common.required"),
      message: t("bde:app.forms.common.error"),
    });
  }

  React.useEffect(() => {
    const x = {};
    x[data.key + "_start"] = events.newEvent.start;
    x[data.key + "_duration"] = events.newEvent.start
      ? 0
      : events.newEvent.time - events.lastEvent.time;
    formData.setFieldsValue(x);
  }, [events.newEvent]);

  React.useEffect(() => {
    if (events.newEvent.start === events.lastEvent.start) {
      setShowDrawer(true);
    }
  }, [events.newEvent.start, events.lastEvent.start]);
  React.useEffect(() => {
    getLastEventFromParse();
    const interval = setInterval(() => {
      setNow(new Date().getTime());
      setEvents(
        produce((draft) => {
          draft.newEvent.time = new Date().getTime();
        })
      );
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const getHeader = () => {
    return (
      <Row gutter={16} style={{ marginBottom: "20px" }}>
        <Col span={8}>
          <Statistic
            title={t("bde:app.forms.startstop.currentTime")}
            value={dayjs(now).format("DD.MM.YYYY @ HH:mm:ss")}
          />
        </Col>
        <Col span={8}>
          {events.lastEvent.start ? (
            <Statistic
              title={t("bde:app.forms.startstop.started")}
              value={dayjs(events.lastEvent.time).format(
                "DD.MM.YYYY @ HH:mm:ss"
              )}
            />
          ) : (
            <Statistic title="Begonnen um" value={"-"} />
          )}
        </Col>
        <Col span={8}>
          {events.lastEvent.start ? (
            <Statistic
              title={t("bde:app.forms.startstop.duration")}
              value={dayjs.duration(now - events.lastEvent.time).humanize()}
            />
          ) : (
            <Statistic
              title={t("bde:app.forms.startstop.duration")}
              value={"-"}
            />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <Form.Item
        className="hidden_custom_form"
        name={data.key + "_start"}
        label={data.title}
        rules={rules}
        style={{ marginBottom: "0px" }}
      >
        <Input style={{ display: "none" }} />
      </Form.Item>
      <Form.Item
        className="hidden_custom_form"
        name={data.key + "_duration"}
        rules={rules}
        style={{ marginBottom: "0px" }}
      >
        <Input style={{ display: "none" }} />
      </Form.Item>
      <div
        className="startstop_holder"
        style={{ width: "100%", textAlign: "center" }}
      >
        {getHeader()}
        <h4>{t("bde:app.forms.startstop.event.label")}</h4>

        <Toggle>
          <Switch
            style={{ width: "80%", height: "100px" }}
            checkedChildren={t("bde:app.forms.startstop.events.start")}
            unCheckedChildren={t("bde:app.forms.startstop.events.stop")}
            checked={events.newEvent.start}
            onChange={(e) => {
              setEvents(
                produce((draft) => {
                  draft.newEvent = {
                    start: e,
                    time: new Date().getTime(),
                  };
                })
              );
            }}
          />
        </Toggle>

        {/*
        <Radio.Group
          style={{ width: "100%" }}
          value={events.newEvent.start}
          buttonStyle="solid"
          onChange={(e) => {
            setEvents(
              produce((draft) => {
                draft.newEvent = {
                  start: e.target.value,
                  time: new Date().getTime(),
                };
              })
            );
          }}
        >
          {!allowMultiStart || !events.lastEvent.start ? (
            <Radio.Button style={{ width: "50%" }} value={true}>
              Start
            </Radio.Button>
          ) : (
            ""
          )}
          {!allowMultiStart || events.lastEvent.start ? (
            <Radio.Button style={{ width: "50%" }} value={false}>
              Stop
            </Radio.Button>
          ) : (
            ""
          )}
          </Radio.Group>*/}
        <p className="ant-statistic-title" style={{ marginTop: "10px" }}>
          <Icon icon="fa:exclamation" /> {t("bde:app.forms.startstop.sendInfo")}
        </p>
        <Drawer
          open={showDrawer}
          placement="bottom"
          height="80%"
          onClose={() => {
            setEvents(
              produce((draft) => {
                draft.newEvent.start = !draft.lastEvent.start;
              })
            );
            setShowDrawer(false);
          }}
        >
          {" "}
          <Result
            title={t("bde:app.forms.startstop.duplicateState.title")}
            subTitle={t("bde:app.forms.startstop.duplicateState.confirm")}
            extra={
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  setShowDrawer(false);
                }}
              >
                {t("bde:app.forms.startstop.duplicateState.submit")}
              </Button>
            }
          />
        </Drawer>
      </div>
    </div>
  );
};
