import dayjs from "dayjs";
import { QuartzObject } from "../types/CronObject";

export function dayofweekCheck(
  value: QuartzObject,
  manipulatedDate: dayjs.Dayjs
): boolean {
  const dayofweekarr: string[] = value.dayofweek.split(value.DELIMITER_DOW);
  const dayofweekisincluded = dayofweekarr.includes(
    value.getDayOfWeekAbbreviation(
      [manipulatedDate.weekday()],
      "string",
      undefined,
      ","
    )
  );

  //Check dayofweek field
  if (value.dayofweek === "*" || value.dayofweek === "?") {
    return true;
  } else {
    if (typeof value.DELIMITER_DOW !== "undefined") {
      //Day is start and interval
      const dividend = manipulatedDate.weekday() - parseInt(dayofweekarr[0]);
      const divisor = parseInt(dayofweekarr[1]);
      if (value.DELIMITER_DOW === "/" && dividend % divisor === 0) {
        return true;
      }
    }
    //Day is comma separated list
    // value.DELIMITER_DOW === "," &&
    if (dayofweekisincluded) {
      return true;
    }
  }

  return false;
}
