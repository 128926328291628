import React, { useState } from "react";
import {
  Alert,
  Modal,
  Radio,
  Input,
  Button,
  DatePicker,
  Select,
  Descriptions,
} from "antd";
import { useSource, useTranslation } from "@opendash/core";
import { Parse } from "parse-hooks";
import dayjs, { Dayjs } from "dayjs";

const { Option } = Select;

interface CreateServiceModalProps {
  edit: boolean;
  visible: boolean;
  onCancel: () => void;
  spsid: string;
}

const CreateServiceModal: React.FC<CreateServiceModalProps> = ({
  edit,
  visible,
  onCancel,
  spsid,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [source] = useSource();
  const t = useTranslation();
  const [currentOPHours, setCurrentOPHours] = useState<number>(0);
  const [machineName, setMachineName] = useState<string>("");
  const [ophoursStatus, setOphoursStatus] = useState<number>(2);
  const [hourStatus, setHourStatus] = useState<number>(2);
  const [mail, setMail] = useState<string>("");
  const [validate, setValidate] = useState<boolean>(true);
  const [validateText, setValidateText] = useState<string>("");
  const [ophoursValueStart, setOphoursValueStart] = useState<string>("");
  const [ophoursValue, setOphoursValue] = useState<string>("");
  const [myStartDate, setMyStartDate] = useState<Dayjs | null>(null);
  const [hoursValue, setHoursValue] = useState<string>("");
  const [itemDate, setItemDate] = useState<{ id: number; name: string }[]>([
    { id: 1, name: "Monatlich" },
    { id: 2, name: "Vierteljährlich" },
    { id: 3, name: "Halbjährlich" },
    { id: 4, name: "Jährlich" },
    { id: 5, name: "2-Jährig" },
  ]);

  React.useEffect(() => {
    console.log("LANGUGE", window.localStorage.getItem("opendash:language"));
    const ServiceEntry = Parse.Object.extend("HG_Service");
    const query = new Parse.Query(ServiceEntry);

    const sourceQuery = new Parse.Query("OD3_Source");
    sourceQuery.equalTo("tag", spsid);

    query.include("source");
    query.matchesQuery("source", sourceQuery);

    query.first().then((results) => {
      if (results) {
        setCurrentOPHours(results.get("op_hours"));
        setMachineName(
          results.get("source").get("name") +
            " (" +
            (results.get("source").get("machineNumber") ??
              results.get("source").get("tag")) +
            ")"
        );
      }
    });
  }, [visible, spsid]);

  React.useEffect(() => {
    if (edit) {
      const ServiceEntry = Parse.Object.extend("HG_Service");

      const query = new Parse.Query(ServiceEntry);
      const sourceQuery = new Parse.Query("OD3_Source");
      sourceQuery.equalTo("tag", spsid);
      query.include("source");
      query.matchesQuery("source", sourceQuery);

      query.first().then((results) => {
        if (results) {
          if (
            Array.isArray(results.get("last_services")) &&
            results.get("last_services").length > 0
          ) {
            const lastService =
              results.get("last_services")[
                results.get("last_services").length - 1
              ];
            if (lastService && lastService.active === true) {
              setIsActive(true);
            }
          }

          setCurrentOPHours(results.get("op_hours"));
          setMail(results.get("service_body")[0].mail);
          setOphoursStatus(results.get("service_body")[0].active);
          setOphoursValueStart(results.get("service_body")[0].start);
          setOphoursValue(results.get("service_body")[0].value);
          setHourStatus(results.get("service_body")[1].active);
          setMyStartDate(
            results.get("service_body")[1].start
              ? dayjs(results.get("service_body")[1].start)
              : null
          );
          setHoursValue(results.get("service_body")[1].value);
        }
      });
    }
  }, [edit, spsid]);

  const sendEntry = async () => {
    // Validate
    let error = 1;
    let validatePuffer = true;

    const operatingHoursValue = parseInt(ophoursValue);
    const operatingHoursValueStart = parseInt(ophoursValueStart);

    if (ophoursStatus === 1 && operatingHoursValue && operatingHoursValue > 0) {
      if (operatingHoursValueStart <= currentOPHours) {
        validatePuffer = false;
        setValidate(false);
        setValidateText(
          t("greis:serviceview.new_modal.validate_text_operating_hours_start")
        );
        error = 2;
      }
    } else if (ophoursStatus !== 2) {
      validatePuffer = false;
      setValidate(false);
      setValidateText(
        t("greis:serviceview.new_modal.validate_text_operating_hours_error")
      );
    }

    if (hourStatus === 1 && hoursValue && parseInt(hoursValue) > 0) {
      const pick = dayjs(myStartDate);
      const now = dayjs().endOf("day");

      if (pick.valueOf() <= now.valueOf()) {
        validatePuffer = false;
        setValidate(false);
        setValidateText(
          t("greis:serviceview.new_modal.validate_text_time_start")
        );
        error = 2;
      }
    } else if (hourStatus !== 2) {
      validatePuffer = false;
      setValidate(false);
      setValidateText(
        t("greis:serviceview.new_modal.validate_text_time_error")
      );
    }

    const re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    if (!mail || mail == "" || (mail && !re.test(mail))) {
      validatePuffer = false;
      setValidate(false);
      setValidateText(
        t("greis:serviceview.new_modal.validate_text_mail_error")
      );
    }

    if (validatePuffer) {
      setValidate(true);
      try {
        const serviceArray: any[] = []; // Typ für serviceArray
        const serviceOPHObject: any = {}; // Typ für serviceOPHObject
        const serviceHObject: any = {}; // Typ für serviceHObject

        serviceOPHObject.type = "op";
        serviceOPHObject.active = ophoursStatus;
        serviceOPHObject.start = operatingHoursValueStart || 0; // Standardwert
        serviceOPHObject.value =
          ophoursStatus === 2 ? "-1" : operatingHoursValue.toString();
        serviceOPHObject.mail = mail;

        serviceHObject.type = "hour";
        serviceHObject.active = hourStatus;
        serviceHObject.start = myStartDate
          ? dayjs(myStartDate).startOf("day").valueOf()
          : 0;

        serviceHObject.value = hourStatus === 2 ? "-1" : hoursValue;
        serviceHObject.mail = mail;

        serviceArray.push(serviceOPHObject);
        serviceArray.push(serviceHObject);

        const ServiceEntry = Parse.Object.extend("HG_Service");
        const query = new Parse.Query(ServiceEntry);

        const sourcePointer = new Parse.Object("OD3_Source");
        sourcePointer.id = source.id;

        query.include("source");
        query.equalTo("source", sourcePointer);
        const results = await query.find();

        if (results.length === 0) {
          const newService = new ServiceEntry();
          newService.set("source", sourcePointer);
          newService.set("service_body", serviceArray);
          newService.set("op_hours", 0);
          let language = window.localStorage.getItem("opendash:language")
            ? JSON.parse(
                window.localStorage.getItem("opendash:language") || '"de"'
              )
            : "de";
          newService.set("language", language + "");
          await newService.save();
        } else {
          if (edit) {
            if (serviceHObject.start > 0) {
              if (dayjs().startOf("day").valueOf() > serviceHObject.start) {
                let lastFiredFake = serviceHObject.start;
                let lastFiredFakePuffer = lastFiredFake;
                results[0].set("last_hours_fired", lastFiredFakePuffer);
              } else {
                results[0].set("last_hours_fired", 0);
              }
            }
          }
          let language = window.localStorage.getItem("opendash:language")
            ? JSON.parse(
                window.localStorage.getItem("opendash:language") || '"de"'
              )
            : "de";
          results[0].set("language", language + "");
          results[0].set("service_body", serviceArray);
          await results[0].save();
        }

        deleteEntry();

        onCancel();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const deleteEntry = () => {
    setOphoursStatus(2);
    setOphoursValue("");
    setOphoursValueStart("");
    setHourStatus(2);
    setHoursValue("");
    setMail("");
    setMyStartDate(null);
    setValidate(true);
    onCancel();
  };

  return (
    <Modal
      title={
        machineName != ""
          ? t("greis:serviceview.new_modal.title_with") + machineName
          : t("greis:serviceview.new_modal.title")
      }
      open={visible}
      onCancel={() => {
        deleteEntry();
        onCancel();
      }}
      footer={null}
      width={855}
      style={{ maxHeight: "90%" }}
    >
      {!validate && (
        <>
          <Alert message={validateText} type="error" />
          <br />
        </>
      )}
      {isActive ? (
        <>
          <Alert
            message={t("greis:serviceview.new_modal.alarm_active")}
            type="error"
          />
          <br />
        </>
      ) : (
        <>
          <Descriptions bordered column={1} style={{ marginBottom: "20px" }}>
            <Descriptions.Item
              label={t("greis:serviceview.new_modal.desc_op_title")}
            >
              <Radio.Group
                onChange={(e) => {
                  setValidate(true);
                  setOphoursStatus(e.target.value);
                }}
                value={ophoursStatus}
              >
                <Radio value={1}>
                  {t("greis:serviceview.new_modal.desc_activate")}
                </Radio>
                <Radio value={2}>
                  {t("greis:serviceview.new_modal.desc_deactivate")}
                </Radio>
              </Radio.Group>
            </Descriptions.Item>

            {ophoursStatus === 1 && (
              <>
                <Descriptions.Item
                  label={
                    t("greis:serviceview.new_modal.desc_op_start") +
                    " - " +
                    currentOPHours +
                    "):"
                  }
                >
                  <Input
                    placeholder={t(
                      "greis:serviceview.new_modal.desc_enter_value"
                    )}
                    type="number"
                    value={ophoursValueStart}
                    onChange={(e) => {
                      setValidate(true);
                      setOphoursValueStart(e.target.value);
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("greis:serviceview.new_modal.desc_op_interval")}
                >
                  <Input
                    placeholder={t(
                      "greis:serviceview.new_modal.desc_enter_value"
                    )}
                    type="number"
                    value={ophoursValue}
                    onChange={(e) => {
                      setValidate(true);
                      setOphoursValue(e.target.value);
                    }}
                  />
                </Descriptions.Item>
              </>
            )}

            <Descriptions.Item
              label={t("greis:serviceview.new_modal.desc_time_title")}
            >
              <Radio.Group
                onChange={(e) => {
                  setValidate(true);
                  setHourStatus(e.target.value);
                }}
                value={hourStatus}
              >
                <Radio value={1}>
                  {t("greis:serviceview.new_modal.desc_activate")}
                </Radio>
                <Radio value={2}>
                  {t("greis:serviceview.new_modal.desc_deactivate")}
                </Radio>
              </Radio.Group>
            </Descriptions.Item>

            {hourStatus === 1 && (
              <>
                <Descriptions.Item
                  label={t("greis:serviceview.new_modal.desc_time_start")}
                >
                  <DatePicker
                    format="DD.MM.YYYY"
                    value={myStartDate ? dayjs(myStartDate) : null}
                    onChange={(date) => {
                      setValidate(true);
                      setMyStartDate(date);
                    }}
                    style={{ width: "100%", padding: 0, margin: 0 }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("greis:serviceview.new_modal.desc_time_interval")}
                >
                  <Select
                    value={hoursValue}
                    onChange={(value) => {
                      setValidate(true);
                      setHoursValue(value);
                    }}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      textIndent: "25%",
                    }}
                  >
                    {itemDate.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Descriptions.Item>
              </>
            )}

            <Descriptions.Item
              label={t("greis:serviceview.new_modal.desc_time_mail_receiver")}
            >
              <Input
                placeholder={t(
                  "greis:serviceview.new_modal.desc_time_mail_receiver_hint"
                )}
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
            </Descriptions.Item>
          </Descriptions>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button type="primary" onClick={sendEntry} style={{ width: "80%" }}>
              <i className="fa fa-plus-square" aria-hidden="true"></i>{" "}
              {t("greis:serviceview.new_modal.button_save")}
            </Button>
            &nbsp;
            <Button onClick={deleteEntry} style={{ width: "80%" }}>
              <i className="fa fa-trash" aria-hidden="true"></i>{" "}
              {t("greis:serviceview.new_modal.button_cancel")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CreateServiceModal;
