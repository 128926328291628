import { makeAutoObservable } from "mobx";
import type { FrameworkService } from "./FrameworkService";

export class UserService2 {
  private framework: FrameworkService;

  constructor(framework: FrameworkService) {
    makeAutoObservable(this);

    this.framework = framework;
  }
}
