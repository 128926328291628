import * as Parse from "parse";
import * as React from "react";

import { Options as OpenWareOptions } from "../types";

export class OpenWareContext {
  public config: OpenWareOptions;

  constructor(config: OpenWareOptions) {
    this.config = config;
  }

  public async fetch(path: string, init: RequestInit = {}) {
    const secure = this.config.secure ? "s" : "";
    const host = this.config.host;

    const url = `http${secure}://${host}${path}`;

    const headers = {
      ...(init.headers || {}),
      "OD-SESSION": Parse.User.current().getSessionToken(),
    };

    return fetch(url, {
      ...init,
      headers,
    });
  }
}

export const OpenWareReactContext = React.createContext<OpenWareContext>(null);

export function useOpenWareContext(): OpenWareContext {
  return React.useContext(OpenWareReactContext);
}
export interface OpenWareProviderProps {
  config: OpenWareOptions;
  children?: React.ReactNode;
}

export const OpenWareProvider = React.memo<OpenWareProviderProps>(
  function OpenWareProvider({ config, children }) {
    const ow = React.useMemo(() => new OpenWareContext(config), [config]);

    return <OpenWareReactContext.Provider value={ow} children={children} />;
  }
);
