import dayjs, { Dayjs } from "dayjs";
import { RegexGroups } from "./RegexGroups";

/**
 * Class of a cron object in quartz format.
 * @author Nico Vitt
 */
export class QuartzObject implements RegexGroups {
  constructor(startdate?: Dayjs) {
    if (typeof startdate !== "undefined") {
      this.SECOND = startdate.get("second").toString();
      this.MINUTE = startdate.get("minute").toString();
      this.HOUR = startdate.get("hour").toString();
      this.MONTH = startdate.get("month").toString();
      this.YEAR = startdate.get("year").toString();
    }
  }

  private readonly _delimiters = ["/", ",", "-", "#"];
  private readonly weekdayabbr = [
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT",
    "SUN",
  ];
  private readonly monthabbr = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OKT",
    "NOV",
    "DEZ",
  ];

  SECOND: string = "*";
  MINUTE: string = "*";
  HOUR: string = "*";
  DAYOFWEEK: string = "*";
  MONTH: string = "*";
  DAYOFMONTH: string = "?";
  YEAR: string = "*";
  // WEEK: string = "*";

  //Delimiters
  DELIMITER_DOM: string;
  DELIMITER_DOW: string;
  DELIMITER_HR: string;
  DELIMITER_MIN: string;
  DELIMITER_MON: string;
  DELIMITER_SEC: string;
  DELIMITER_YR: string;
  // DELIMITER_WK: string;

  //End
  END_DOM: string;
  END_DOW: string;
  END_HR: string;
  END_MIN: string;
  END_MON: string;
  END_SEC: string;
  END_YR: string;
  // END_WK: string;

  //Start
  START_DOM: string;
  START_DOW: string;
  START_HR: string;
  START_MIN: string;
  START_MON: string;
  START_SEC: string;
  START_YR: string;
  // START_WK: string;

  public get seconds(): string {
    return this.SECOND;
  }

  public set seconds(v: string) {
    let found = false;
    for (const entry of this._delimiters) {
      if (v.includes(entry)) {
        found = true;
        const splittedarr = v.split(entry);
        this.START_SEC = splittedarr[0];
        this.DELIMITER_SEC = entry;
        this.END_SEC = splittedarr[1];
      }
    }
    if (!found) {
      this.START_SEC = undefined;
      this.DELIMITER_SEC = undefined;
      this.END_SEC = undefined;
    }

    this.SECOND = v;
  }

  public get minutes(): string {
    return this.MINUTE;
  }

  public set minutes(v: string) {
    let found = false;
    for (const entry of this._delimiters) {
      if (v.includes(entry)) {
        found = true;
        const splittedarr = v.split(entry);
        this.START_MIN = splittedarr[0];
        this.DELIMITER_MIN = entry;
        this.END_MIN = splittedarr[1];
      }
    }
    if (!found) {
      this.START_MIN = undefined;
      this.DELIMITER_MIN = undefined;
      this.END_MIN = undefined;
    }

    this.MINUTE = v;
  }

  public get hours(): string {
    return this.HOUR;
  }

  public set hours(v: string) {
    let found = false;
    for (const entry of this._delimiters) {
      if (v.includes(entry)) {
        found = true;
        const splittedarr = v.split(entry);
        this.START_HR = splittedarr[0];
        this.DELIMITER_HR = entry;
        this.END_HR = splittedarr[1];
      }
    }
    if (!found) {
      this.START_HR = undefined;
      this.DELIMITER_HR = undefined;
      this.END_HR = undefined;
    }

    this.HOUR = v;
  }

  public get dayofmonth(): string {
    return this.DAYOFMONTH;
  }

  public set dayofmonth(v: string) {
    let found = false;
    for (const entry of this._delimiters) {
      if (v.includes(entry)) {
        found = true;
        const splittedarr = v.split(entry);
        this.START_DOM = splittedarr[0];
        this.DELIMITER_DOM = entry;
        this.END_DOM = splittedarr[1];
      }
    }
    if (!found) {
      this.START_DOM = undefined;
      this.DELIMITER_DOM = undefined;
      this.END_DOM = undefined;
    }

    this.DAYOFMONTH = v;
  }

  public get month(): string {
    return this.MONTH;
  }

  public set month(v: string) {
    let found = false;
    for (const entry of this._delimiters) {
      if (v.includes(entry)) {
        found = true;
        const splittedarr = v.split(entry);
        this.START_MON = splittedarr[0];
        this.DELIMITER_MON = entry;
        this.END_MON = splittedarr[1];
      }
    }
    if (!found) {
      this.START_MON = undefined;
      this.DELIMITER_MON = undefined;
      this.END_MON = undefined;
    }

    this.MONTH = v;
  }

  public get dayofweek(): string {
    return this.DAYOFWEEK;
  }

  public set dayofweek(v: string) {
    let found = false;
    for (const entry of this._delimiters) {
      if (v.includes(entry)) {
        found = true;
        let splittedarr = v.split(entry);

        if (entry === ",") {
          this.START_DOW = splittedarr[0];
          this.END_DOW = splittedarr.slice(-1)[0];
        } else if (entry === "#") {
          splittedarr = v.split(",");

          this.START_DOW = splittedarr[0];
          this.END_DOW = splittedarr.slice(-1)[0];
        } else {
          this.START_DOW = splittedarr[0];
          this.END_DOW = splittedarr[1];
        }
        this.DELIMITER_DOW = entry;
      }
    }
    if (this.weekdayabbr.includes(v)) {
      //If v is only one weekday
      this.DELIMITER_DOW = "/";
    }
    if (!found) {
      this.START_DOW = undefined;
      this.DELIMITER_DOW = undefined;
      this.END_DOW = undefined;
    }

    this.DAYOFWEEK = v;
  }

  // public get week(): string {
  //   return this.WEEK;
  // }

  // public set week(v: string) {
  //   for (const entry of this._delimiters) {
  //     if (v.includes(entry)) {
  //       const splittedarr = v.split(entry);
  //       this.START_WK = splittedarr[0];
  //       this.DELIMITER_WK = entry;
  //       this.END_WK = splittedarr[1];
  //     }
  //   }

  //   this.WEEK = v;
  // }

  public get year(): string {
    return this.YEAR;
  }

  public set year(v: string) {
    let found = false;
    for (const entry of this._delimiters) {
      if (v.includes(entry)) {
        found = true;
        const splittedarr = v.split(entry);
        this.START_YR = splittedarr[0];
        this.DELIMITER_YR = entry;
        this.END_YR = splittedarr[1];
      }
    }
    if (!found) {
      this.START_YR = undefined;
      this.DELIMITER_YR = undefined;
      this.END_YR = undefined;
    }

    this.YEAR = v;
  }

  /**
   * Resets the cron object to the default values.
   */
  public reset(): void {
    this.SECOND = "*";
    this.MINUTE = "*";
    this.HOUR = "*";
    this.DAYOFWEEK = "*";
    this.MONTH = "*";
    this.DAYOFMONTH = "?";
    this.YEAR = "*";
    // this.WEEK = "*";
  }

  /**
   * Returns the value of the form field for the given type.
   * @param type
   * @returns
   */
  public getInitialFormValue(type: string): any {
    switch (type) {
      case "daily":
        return 1;
      case "monthly-day":
        return 1;
      case "monthly-date":
        return 1;
      case "monthly-series":
        return 1;
      case "day-of-week":
        return 1;
      case "weekday-select":
        return [dayjs().weekday()];
      case "weekly":
        return 1;
      case "yearly":
        return 1;
      default:
        return 0;
    }
  }

  /**
   *
   * @param value
   * @param type
   * @param suffix
   * @param joinBy
   * @returns string
   */
  getDayOfWeekAbbreviation(
    value: Array<number>,
    type: string,
    suffix: string | undefined,
    joinBy: string
  ): string {
    return value
      .sort((a, b) => a - b)
      .map((weekdayindex) => {
        return type === "number"
          ? weekdayindex + (suffix ? suffix : "")
          : type === "string"
            ? this.weekdayabbr[weekdayindex] + (suffix ? suffix : "")
            : null;
      })
      .join(joinBy);
  }

  /**
   * Maps a number array of months to the abbreviations (number or stirng)
   * @param value
   * @param type
   * @param suffix
   * @param joinBy
   * @returns
   */
  getMonthAbbreviation(
    value: Array<number>,
    type: string,
    suffix: string | undefined,
    joinBy: string
  ): string {
    return value
      .sort((a, b) => a - b)
      .map((monthindex) => {
        return type === "number"
          ? monthindex + 1 + (suffix ? suffix : "")
          : type === "string"
            ? this.monthabbr[monthindex] + (suffix ? suffix : "")
            : null;
      })
      .join(joinBy);
  }
}
