import { catchError, uuid } from "@opendash/core";
import { makeAutoObservable, runInAction } from "@opendash/state";
import * as Parse from "parse";
import {
  AnyDialog,
  AnyViewType,
  ParseClassConfig,
  ParseClassSchema,
  PermissionInterface,
} from "..";
import {
  runDeregisterPermission,
  runRegisterPermission,
} from "../cloud-functions";
import { getObjectActions } from "../components/helper";
import { Permission } from "../types-generated";
import { ParseFilterState } from "./ParseFilterState";
import type { ParseService } from "./ParseService";

export class ParseAdminService {
  private parse: ParseService;

  public loading: boolean = true;
  public error: Error | null = null;
  //public config: ParseClassConfig[] = [];
  public configMap: Record<string, ParseClassConfig<any>> = {};
  public defaultViewMap: Record<string, AnyViewType> = {};

  public dialog: Record<string, AnyDialog> = {};

  constructor(parse: ParseService) {
    this.parse = parse;

    makeAutoObservable(this);
  }

  get userId() {
    return this.parse.server.userId;
  }

  get roles() {
    return this.parse.server.roles;
  }

  get schema() {
    return this.parse.server.schema;
  }

  get schemaMap() {
    return this.parse.server.schemaMap;
  }

  clearDialog(): void {
    this.dialog = {};
  }

  async init(configs: ParseClassConfig[] = null) {
    try {
      await this.parse.server.lock.wait();

      if (configs && Array.isArray(configs)) {
        for (const config of configs) {
          //this.config.push(config);
          this.configMap[config.className] = config;
        }
      }
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }

  getClassSchema(className: string): ParseClassSchema | undefined {
    return this.schemaMap[className];
  }

  getClassConfig(className: string): ParseClassConfig | undefined {
    return this.configMap[className];
  }

  setClassConfig<T extends Parse.Object>(config: ParseClassConfig<T>): void {
    //this.config.push(config);
    this.configMap[config.className] = config;
  }

  setDefaultView(className: string, view: AnyViewType): void {
    this.defaultViewMap[className] = view;
  }

  async openObject(
    object?: Parse.Object,
    settings?: {
      fields?: string[];
      drawer?: boolean;
    }
  ): Promise<string> {
    const dialogKey = uuid();

    return new Promise((resolve, reject) => {
      this.dialog[dialogKey] = {
        type: "open",
        object,
        fields:
          settings?.fields ||
          this.configMap[object.className]?.editFields ||
          [],
        drawer: settings?.drawer === true,
        resolve,
        reject,
        close: () => {
          this.dialog[dialogKey] = null;
        },
      };
    });
  }

  async createObject(
    className: string,
    initialObject?: Parse.Object,
    fields?: string[]
  ): Promise<string> {
    const object = initialObject || new Parse.Object(className);

    // TODO: handle default acl

    if (!initialObject && this.configMap[className]?.defaultACL) {
      const defaultACL = this.configMap[className]?.defaultACL();

      if (defaultACL) {
        object.setACL(new Parse.ACL(defaultACL));
      }
    }

    const dialogKey = uuid();

    return new Promise((resolve, reject) => {
      this.dialog[dialogKey] = {
        type: "create",
        className,
        object,
        fields: fields || this.configMap[className]?.createFields || [],
        resolve,
        reject,
        close: () => {
          this.dialog[dialogKey] = null;
        },
      };
    });
  }

  async copyObject(
    className: string,
    objectId: string,
    fields?: string[]
  ): Promise<string> {
    const from = await new Parse.Query(className).get(objectId);
    const fromJson = from.toJSON();

    delete fromJson.objectId;
    delete fromJson.createdAt;
    delete fromJson.updatedAt;

    runInAction(() => {
      Object.keys(fromJson).forEach((key) => {
        if (
          this.parse.server.schemaMap[className].fields[key].type == "Pointer"
        ) {
          fromJson[key] = {
            __type: "Pointer",
            className:
              this.parse.server.schemaMap[className].fields[key].targetClass,
            objectId: fromJson[key].objectId,
          };
        }
      });
    });

    const object = new Parse.Object(className, fromJson);

    const dialogKey = uuid();

    return new Promise((resolve, reject) => {
      this.dialog[dialogKey] = {
        type: "create",
        className,
        object,
        fields: fields || this.configMap[className]?.createFields || [],
        resolve,
        reject,
        close: () => {
          this.dialog[dialogKey] = null;
        },
      };
    });
  }

  async updateObject(
    className: string,
    objectId: string,
    fields?: string[]
  ): Promise<string> {
    const obj = await new Parse.Query(className).get(objectId);

    if (!obj) {
      throw new Error("Object not found.");
    }

    const dialogKey = uuid();

    return new Promise((resolve, reject) => {
      this.dialog[dialogKey] = {
        type: "update",
        className,
        objectId,
        object: obj,
        fields: fields || this.configMap[className]?.editFields,
        resolve,
        reject,
        close: () => {
          this.dialog[dialogKey] = null;
        },
      };
    });
  }

  async deleteObject(
    className: string,
    objectId: string | string[]
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogKey = uuid();
      const objectIds = Array.isArray(objectId) ? objectId : [objectId];

      this.dialog[dialogKey] = {
        type: "delete",
        className,
        objectIds,
        resolve,
        reject,
        close: () => {
          this.dialog[dialogKey] = null;
        },
      };
    });
  }

  async editObjectACL(
    className: string,
    objectId: string,
    settings?: {
      hideSelf?: boolean;
      hideUsers?: boolean;
      hideRoles?: boolean;
      hideWrite?: boolean;
      hideRead?: boolean;
      readLabel?: string;
      writeLabel?: string;
    }
  ): Promise<boolean> {
    const obj = await new Parse.Query(className).get(objectId);

    const dialogKey = uuid();

    return new Promise((resolve, reject) => {
      this.dialog[dialogKey] = {
        type: "acl",
        className,
        objectId,
        object: obj,
        hideSelf: settings?.hideSelf || false,
        hideUsers: settings?.hideUsers || false,
        hideRoles: settings?.hideRoles || false,
        hideRead: settings?.hideRead || false,
        hideWrite: settings?.hideWrite || false,
        readLabel: settings?.readLabel,
        writeLabel: settings?.writeLabel,
        resolve,
        reject,
        close: () => {
          this.dialog[dialogKey] = null;
        },
      };
    });
  }

  /**
   * This function checks if the user has one of the specified permissions. It can be used to show or hide several UI-ELements.
   *
   * @param permission An array of permissions to check
   * @returns If the user has one of the specified permissions returns true, otherwise false
   */
  public async checkPermissionForUser(permissions: string[]) {
    const user = this.parse.user.current();

    if (!user) {
      return false;
    }

    //Fetch OD3_Permission from Parse
    const foundPermission = await Parse.Query.or(
      ...permissions.map((permission) =>
        new Parse.Query(Permission).equalTo("key", permission)
      )
    ).find();

    if (foundPermission.length !== 0) {
      return true;
    }

    return false;
  }

  /**
   * Registers a permission. Checks if the permission is already registered. Does nothing if the permission is already registered.
   * Adds it to OD3_Permission table.
   * @param permission The permission that should be registered.
   */
  async registerPermission(permission: PermissionInterface): Promise<void> {
    const [error, response] = await catchError(
      runRegisterPermission({ permission })
    );
    if (error) {
      console.error("Error while registering permission", error);
    }
  }

  /**
   * Deregisters a permission. Checks for the key of the element in the currently registered permission array. Does nothing if item can not be found.
   * Deletes it from OD3_Permission table.
   * @param permissionKey The key of the permission that should be deleted.
   */
  async deregisterPermission(permissionKey: string): Promise<void> {
    const [error, response] = await catchError(
      runDeregisterPermission({ permissionKey })
    );
    if (error) {
      console.error("Error while deregistering permission", error);
    }
  }

  public async openRelationDialog(
    className: string,
    objectId: string,
    field: string
  ) {
    const object = await new Parse.Query(className).get(objectId);

    return this.openRelationDialogForObject(object, field);
  }

  public async openRelationDialogForObject(
    object: Parse.Object,
    field: string
  ) {
    const dialogKey = uuid();

    return new Promise((resolve, reject) => {
      this.dialog[dialogKey] = {
        type: "relation",
        object,
        field,
        resolve,
        reject,
        close: () => {
          this.dialog[dialogKey] = null;
        },
      };
    });
  }

  public getClassPermission(
    className: string,
    type: "create" | "delete",
    disable?: boolean
  ) {
    if (disable === true) {
      return false;
    }

    const clp = this.schemaMap[className]?.evaluatedClassLevelPermissions;

    return !!clp?.[type];
  }

  public getObjectActions(object: Parse.Object, onChange: () => void) {
    return getObjectActions(object, onChange);
  }
  public getObjectPermission(
    object: Parse.Object,
    type: "update" | "delete" | "acl",
    disable?: boolean
  ) {
    if (disable === true) {
      return false;
    }

    const className = object.className;

    if (type === "acl") {
      if (this.configMap[className]?.disableACLEditing) {
        return false;
      }

      type = "update";
    }

    const clp = this.schemaMap[className]?.evaluatedClassLevelPermissions;

    if (!clp?.[type]) {
      return false;
    }

    const acl = object.getACL();

    if (!acl) {
      return true;
    }

    if (acl.getPublicWriteAccess()) {
      return true;
    }

    if (acl.getWriteAccess(this.userId)) {
      return true;
    }

    for (const role of this.roles) {
      if (acl.getRoleWriteAccess(role)) {
        return true;
      }
    }

    return false;
  }

  createFilterState(
    className: string,
    view: AnyViewType,
    persistInUrl: boolean = false
  ) {
    return new ParseFilterState(className, view, persistInUrl);
  }
}
