import {
  createInternalComponent,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { useNavigate } from "@opendash/router";
import * as React from "react";
import {
  DashboardDisplay,
  DashboardLayout,
  useDashboardCurrent,
  useDashboardsBySource,
} from "..";

interface Props {}

export const DashboardRoute = createInternalComponent<Props>(
  function DashboardRoute({}) {
    const t = useTranslation();

    const dashboards = useDashboardsBySource();
    const [dashboard, setDashboard] = useDashboardCurrent();

    const [, setCreate] = useUrlParam("db_create", false);
    const navigate = useNavigate();
    // Create a new dashboard, if there is none
    React.useEffect(() => {
      if (!dashboard) {
        if (dashboards.length === 0) {
          setCreate(true);
        } else {
          setDashboard(dashboards[0]);
        }
      }
    }, [dashboards]);

    return (
      <DashboardLayout>
        <DashboardDisplay dashboard={dashboard} />
      </DashboardLayout>
    );
  }
);
