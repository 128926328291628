import { makeAutoObservable } from "mobx";
import { Notification } from "../../../types-generated";

export class NotificationState {
  _unreadNotificationsCount: number = 0;
  _notificationsCount: number = 0;
  _notifications: Notification[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get unreadNotificationsCount(): number {
    return this._unreadNotificationsCount;
  }

  set unreadNotificationsCount(count: number) {
    this._unreadNotificationsCount = count;
  }

  get notificationsCount(): number {
    return this._notificationsCount;
  }

  set notificationsCount(count: number) {
    this._notificationsCount = count;
  }

  get notifications(): Notification[] {
    return this._notifications;
  }

  set notifications(notifications: Notification[]) {
    this._notifications = notifications;
  }
}
