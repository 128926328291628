import Parse from "parse";

import type { List } from "./List";

export interface ListEntryAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  key: string;
  list: List;
  value: string;
}

export class ListEntry extends Parse.Object<ListEntryAttributes> {
  static className: string = "OD3_BDE_ListEntry";

  constructor(data?: Partial<ListEntryAttributes>) {
    super("OD3_BDE_ListEntry", data as ListEntryAttributes);
  }

  get key(): string {
    return super.get("key");
  }
  set key(value: string) {
    super.set("key", value);
  }
  get list(): List {
    return super.get("list");
  }
  set list(value: List) {
    super.set("list", value);
  }
  get value(): string {
    return super.get("value");
  }
  set value(value: string) {
    super.set("value", value);
  }
}

Parse.Object.registerSubclass("OD3_BDE_ListEntry", ListEntry);
