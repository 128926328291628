import { Drawer } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";
import { LinkedSourcePicker, NavigationMenu, useOpenDashApp } from "..";

export const Container = styled.div``;

const ContentContainer = styled.div``;

const SourcePickerContainer = styled.div`
  padding: 16px;
`;

interface Props extends React.PropsWithChildren<{}> {
  showSourcePicker?: boolean;
}

export const AppSidebar = observer<Props>(function AppSidebar({
  children,
  showSourcePicker,
}) {
  const app = useOpenDashApp();

  React.useEffect(() => {
    app.ui2.updateSidebar({ available: true });

    return () => {
      app.ui2.updateSidebar({
        open: false,
        available: false,
      });
    };
  }, []);

  return (
    <Drawer
      open={app.ui2.sidebar.open}
      onClose={() => app.ui2.updateSidebar({ open: false })}
      closable={false}
      placement="left"
      rootStyle={{
        position: "fixed",
        zIndex: 998,
        top: "var(--opendash-header-height)",
        height: "calc(100vh - var(--opendash-header-height))",
      }}
      styles={{ body: { padding: 0 } }}
      width={500}
      autoFocus={false}
    >
      <Container
        onClick={() => {
          app.ui2.updateSidebar({ open: false });
        }}
      >
        {showSourcePicker && (
          <SourcePickerContainer onClick={(e) => e.stopPropagation()}>
            <LinkedSourcePicker style={{ width: "100%" }} />
          </SourcePickerContainer>
        )}

        <ContentContainer>{children}</ContentContainer>

        <NavigationMenu />
      </Container>
    </Drawer>
  );
});
