import { Badge, Calendar } from "antd";
import dayjs from "dayjs";
import React from "react";

import { createComponent } from "@opendash/state";

import { $parse } from "../../state";
import type { ParseFilterState } from "../../state/ParseFilterState";
import { CalendarViewType } from "../../types";
import { ParseFields } from "../ParseFields";

const dateFormat = "YYYY-MM-DD";
const monthFormat = "YYYY-MM";
const dateFormatYearless = "MM-DD";
const monthFormatYearless = "MM";

interface Props {
  state: ParseFilterState;
  view: CalendarViewType;
}

export const CalendarView = createComponent<Props>(function CalendarView({
  state,
  view,
}) {
  const datesGrouped = React.useMemo(() => {
    const result: Record<string, Parse.Object[]> = {};

    for (const object of state.data.result) {
      const date = dayjs(object.get(view.dateField));
      const dateKey = date.format(
        view.ignoreYear ? dateFormatYearless : dateFormat
      );
      const monthKey = date.format(
        view.ignoreYear ? monthFormatYearless : monthFormat
      );

      if (!result[dateKey]) {
        result[dateKey] = [];
      }

      result[dateKey].push(object);

      if (!result[monthKey]) {
        result[monthKey] = [];
      }

      result[monthKey].push(object);
    }

    return result;
  }, [state.data.result, view.dateField, view.ignoreYear]);

  return (
    <Calendar
      dateCellRender={(date) => {
        const dateKey = date.format(
          view.ignoreYear ? dateFormatYearless : dateFormat
        );

        const objects = datesGrouped[dateKey];

        if (!objects) {
          return null;
        }

        return (
          <div>
            {objects.map((o) => (
              <div
                key={o.id}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  $parse.ui.openObject(o);
                }}
              >
                <Badge
                  status="success"
                  text={
                    <ParseFields
                      object={o}
                      fields={view.titleFields || state.config.titleFields}
                      isInline
                    />
                  }
                />
              </div>
            ))}
          </div>
        );
      }}
      monthCellRender={(date) => {
        const monthKey = date.format(
          view.ignoreYear ? monthFormatYearless : monthFormat
        );

        const objects = datesGrouped[monthKey];

        if (!objects) {
          return 0;
        }

        return objects.length;
      }}
    />
  );
});
