import { $parse } from "@opendash/plugin-parse";
import { useWatch } from "antd/es/form/Form";
import { useMemo } from "react";

const SUPPORTED_FIELD_TYPES = ["number", "string"];

export const useMappedParseFields = (getAll?: boolean) => {
  const className = useWatch<string>("className");
  const keys = useWatch<string[]>("keys");

  // all valid fields for the selected class
  const fields = useMemo(() => {
    const rawFields = $parse.server.schema.find(
      (c) => c.className === className
    )?.fields;
    if (!rawFields) return [];

    const mappedFields = Object.keys(rawFields).map((key) => ({
      field: key,
      type: rawFields[key].type.toLocaleLowerCase(),
    }));

    if (getAll) return mappedFields;

    const validFields = mappedFields.filter(
      (field) =>
        SUPPORTED_FIELD_TYPES.includes(field.type) && keys.includes(field.field)
    );

    return validFields;
  }, [
    $parse.server.schema,
    $parse.server.schema.length,
    className,
    getAll,
    keys,
  ]);

  return fields;
};
