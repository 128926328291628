import { produce, useTranslation } from "@opendash/core";
import { DataItemInterface } from "@opendash/plugin-timeseries";
import {
  Button,
  Collapse,
  DatePicker,
  Divider,
  Drawer,
  Input,
  Popconfirm,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { getRequestServer } from "./DataCollectionOverview";

type MassEditModalProps = {
  items: DataItemInterface[];
  onClose: () => void;
};

export default function MassDeleteModal({
  items,
  onClose,
}: MassEditModalProps) {
  const [statusInfo, setStatusInfo] = useState<string | null>(null);
  const [deleteConfirm, setDeleteConfirm] = useState<string>("");
  const [working, setWorking] = useState<boolean>(false);
  const [deletionRange, setDeletionRange] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(1, "day"),
    dayjs(),
  ]);
  const requestServer = getRequestServer();

  const deleteData = async (source, id, start, end) => {
    const res = await requestServer.delete(
      `data/items/${encodeURIComponent(source)}/${encodeURIComponent(
        id
      )}/${start}/${end}`
    );
    /*
    if (
      res.status === 200 &&
      start == 0 &&
      new Date(end).getFullYear() === 3000
    ) {
      api.success({
        message: t("openware:sensor.admin.delete.success"),
        description: id,
        placement: "topRight",
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }*/
    return res;
  };
  const t = useTranslation();

  const handleDelete = async (all: boolean) => {
    setWorking((draft) => {
      return true;
    });
    for (const item of items) {
      const max_end = new Date();
      max_end.setFullYear(3000);
      try {
        const res = await (all
          ? deleteData(item.source, item.id, 0, max_end.getTime())
          : deleteData(
              item.source,
              item.id,
              deletionRange[0].valueOf(),
              deletionRange[1].valueOf()
            ));
        console.log({ res });
        setStatusInfo(
          produce((draft) => {
            const value =
              (draft ? draft + "\n" : "") +
              (res.status === 200
                ? t("openware:sensor.admin.delete.success", {
                    name: item.name,
                  })
                : t("openware:sensor.admin.delete.error", {
                    name: item.name,
                    error: res.data,
                  }));
            return value;
          })
        );
      } catch (error) {
        setStatusInfo(
          produce((draft) => {
            const value =
              (draft ? draft + "\n" : "") +
              t("openware:sensor.admin.delete.error", {
                name: item.name,
                error: error,
              });
            return value;
          })
        );
      }
    }
    setWorking((draft) => {
      return false;
    });
  };
  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  } as React.CSSProperties;
  return (
    <Drawer
      placement="right"
      width={"40%"}
      open={items.length > 0}
      onClose={onClose}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Collapse>
            <Collapse.Panel
              key="1"
              header={t("openware:admin.sensors.massDelete.title", {
                count: items.length,
              })}
            >
              {items.map((item) => {
                return (
                  <div>
                    <Typography.Text>{item.name}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary">
                      {item.valueTypes
                        .map((type) => type.name)
                        .join(", ")
                        .substring(0, 100)}
                    </Typography.Text>
                  </div>
                );
              })}
            </Collapse.Panel>
          </Collapse>

          <Divider>
            <Typography.Text type="secondary">
              {t("openware:admin.sensors.massDelete.deleteAll")}
            </Typography.Text>
          </Divider>
          <Input
            placeholder={t(
              "openware:admin.sensors.massDelete.typeConfirmMessage"
            )}
            value={deleteConfirm}
            onChange={(e) => setDeleteConfirm(e.target.value)}
          />
          <Button
            danger
            onClick={() => handleDelete(true)}
            type="primary"
            loading={working}
            disabled={deleteConfirm !== "1234567890"}
          >
            {t("openware:admin.sensors.massDelete.deleteAll")}
          </Button>
          <Divider>
            <Typography.Text type="secondary">
              {t("openware:admin.sensors.massDelete.deleteInterval")}
            </Typography.Text>
          </Divider>
          <DatePicker.RangePicker
            value={deletionRange}
            showTime={true}
            onChange={(dates) => {
              setDeletionRange(dates as [Dayjs, Dayjs]);
            }}
            presets={[
              {
                label: t("openware:admin.sensors.massDelete.thisMinute"),
                value: [dayjs().startOf("minute"), dayjs().endOf("minute")],
              },
              {
                label: t("openware:admin.sensors.massDelete.thisHour"),
                value: [dayjs().startOf("hour"), dayjs().endOf("hour")],
              },
              {
                label: t("openware:admin.sensors.massDelete.thisDay"),
                value: [dayjs().startOf("day"), dayjs().endOf("day")],
              },
              {
                label: t("openware:admin.sensors.massDelete.thisWeek"),
                value: [dayjs().startOf("week"), dayjs().endOf("week")],
              },
              {
                label: t("openware:admin.sensors.massDelete.thisMonth"),
                value: [dayjs().startOf("month"), dayjs().endOf("month")],
              },
              {
                label: t("openware:admin.sensors.massDelete.thisYear"),
                value: [dayjs().startOf("year"), dayjs().endOf("year")],
              },
            ]}
          ></DatePicker.RangePicker>
          <Popconfirm
            onConfirm={() => {
              handleDelete(false);
            }}
            title={t("openware:admin.sensors.massDelete.confirm")}
          >
            <Button danger type="primary" loading={working}>
              {t("openware:admin.sensors.massDelete.deleteInterval")}
            </Button>
          </Popconfirm>

          {statusInfo && (
            <pre
              style={{
                maxHeight: "400px",
                overflowY: "scroll",
                border: "1px solid grey",
                backgroundColor: "rgba(0,0,0,0.2)",
                padding: "8px",
              }}
            >
              {statusInfo}
            </pre>
          )}
        </div>
      </div>
    </Drawer>
  );
}
