import { useTranslation } from "@opendash/core";
import { JSONEditor } from "@opendash/ui";
import { Button, Tooltip, Typography } from "antd";
import React from "react";

export interface ObjectDisplayProps {
  value: any;
}

export const ObjectDisplay = React.memo<ObjectDisplayProps>(
  function ObjectDisplay({ value }) {
    return (
      <Tooltip title={JSON.stringify(value)}>
        <Typography.Text code>Object</Typography.Text>
      </Tooltip>
    );
  }
);

export interface ObjectEditProps {
  value: any;
  setValue?: (v: any) => void;
  isRequired: boolean;
  isNullable: boolean;
  collapsed?: number;
}

export const ObjectEdit = React.memo<ObjectEditProps>(function ObjectEdit({
  value,
  setValue,
  collapsed,
}) {
  const t = useTranslation();
  const [status, setStatus] = React.useState<"dirty" | "clean">("clean");
  const [objectData, setObjectData] = React.useState(value);
  const editObjectData = (edit: { updated_src: any }) => {
    setObjectData(edit.updated_src);
    setStatus("dirty");
  };

  React.useEffect(() => {
    setObjectData(value);
  }, [value]);

  return (
    <>
      <JSONEditor
        src={objectData}
        mode="editor"
        name={null}
        displayDataTypes={false}
        collapsed={collapsed ? collapsed : 0}
        displayObjectSize={false}
        onEdit={editObjectData}
        onAdd={editObjectData}
        onDelete={editObjectData}
        allowRawEdit={true}
      />
      {status !== "clean" && (
        <>
          <Button
            style={{ float: "right" }}
            type="primary"
            onClick={() => {
              setValue(objectData);
              setStatus("clean");
            }}
          >
            {t("opendash:ui.save")}
          </Button>
          <Button
            style={{ float: "right" }}
            type="link"
            onClick={() => {
              setStatus("clean");
              setObjectData(value);
            }}
          >
            {t("opendash:ui.cancel")}
          </Button>
        </>
      )}
    </>
  );
});
