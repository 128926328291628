import { Boundary, createInternalComponent } from "@opendash/core";
import * as React from "react";
import { DashboardManagement } from "..";

interface Props {
  children: React.ReactNode;
}
export const DashboardLayout = createInternalComponent<Props>(
  function DashboardLayout({ children }) {
    return (
      <>
        <DashboardManagement />
        <Boundary>{children}</Boundary>
      </>
    );
  }
);
