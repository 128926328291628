import Parse from "parse";
import { ReactNode } from "react";
import { Form, Page, Result, Unit } from "../../../parse";

const demo = {
  logo: undefined as string,
  menuItems: [] as { label: string; target: string; description?: string }[],
  routes: [] as { path: string; component: () => ReactNode }[],
  qrEnabled: false,
  forms: {
    table: Form.className,
    conf: null,
    get: async (unit, ref) => {
      const pUnit = await Unit.createWithoutData(unit.objectId).fetch();

      const formQuery = pUnit.relation("forms").query();
      const toReturnParse = await formQuery.find();
      const toReturn = toReturnParse.map((form) => {
        const x = form.toJSON();
        x.id = x.objectId;
        return x as unknown as Form & { id: string };
      });

      return toReturn;
    },
  },
  pages: {
    table: Page.className,
  },
  referenceStrings: [
    "app.order.label",
    "app.order.id",
    "app.order.prompt",
    "app.order.withoutreference",
    "app.frontpage.labelShort",
  ],
  units: {
    table: Unit.className,
    field: "name",
    source: "source",
    get: async (ref): Promise<{ objectId: string; source: string }[]> => {
      const results = await new Parse.Query(Unit).find();
      return results.map(
        (form) =>
          form.toJSON() as unknown as { objectId: string; source: string }
      );
    },
  },
  references: {
    get: async (): Promise<{ value: string; key: any }[]> => {
      return [];
    },
  },
  lists: {
    table: "OD3_BDE_Listen",
    children: "OD3_BDE_Listeneintraege",
  },
  results: {
    table: Result.className,
  },
  sources: {
    table: "OD3_Source",
  },
};

export default demo;
