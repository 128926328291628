import dayjs from "dayjs";
import { QuartzObject } from "../types/CronObject";

export function dayofmonthCheck(
  value: QuartzObject,
  manipulatedDate: dayjs.Dayjs
): boolean {
  const dayofmontharr: number[] = value.dayofmonth
    .split(value.DELIMITER_DOM)
    .map((entry) => parseInt(entry));
  const dayofmonthisincluded = dayofmontharr.includes(manipulatedDate.date());

  //Check dayofmonth field
  if (value.dayofmonth === "*" || value.dayofmonth === "?") {
    return true;
  } else {
    if (typeof value.DELIMITER_DOM !== "undefined") {
      //Day is start and interval

      const dividend = manipulatedDate.date() - dayofmontharr[0];
      const divisor = dayofmontharr[1];
      if (
        value.DELIMITER_DOM === "/" &&
        dividend >= 0 &&
        dividend % divisor === 0
      ) {
        return true;
      }

      //Day is comma separated list
      if (value.DELIMITER_DOM === "," && dayofmonthisincluded) {
        return true;
      }
    } else {
      //Day is a single day
      if (manipulatedDate.date() === dayofmontharr[0]) {
        return true;
      }
    }
  }

  return false;
}
