import { AppFactory, AppPluginInterface } from "@opendash/core";
import { $monitoring } from "@opendash/plugin-monitoring";

import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import { Config } from ".";
import chatvis from "./chatvis";
dayjs.extend(isoWeek);
dayjs.extend(dayOfYear);

import {
  CWTableWidget,
  Dependency,
  FelixTest,
  KPICanvasWidget,
  KPIMappedWidget,
  KPIMinMaxWidget,
  KPIWidget,
  MultiKPIWidget,
  NetworkGraph,
  NetworkTopology,
  OpenPorts,
  PatternHeatmapWidget,
  PatternWidget,
  PieWidget,
  StatesGanttWidget,
  StatesWidget,
  TableEnergyStep,
  TableWidget,
  TimeSeriesCompareWidget,
  TimeSeriesWidget,
  XYCompare,
} from ".";
import hcTestWidgetDoku from "./widgets/hc-test-widget-doku";

export class HighchartsPlugin implements AppPluginInterface {
  name: "HighchartsPlugin";

  _config: Partial<Config> = {};

  constructor(config?: Partial<Config>) {
    this._config = config;
  }

  async onFactory(factory: AppFactory) {
    //@ts-ignore
    Highcharts.setOptions({
      plotOptions: {
        series: {
          animation: false,
        },
      },
    });
    await factory.registerTranslationResolver(
      "en",
      "highcharts",
      async () => await import("./translations/en.json")
    );
    await factory.registerTranslationResolver(
      "de",
      "highcharts",
      async () => await import("./translations/de.json")
    );
    await factory.registerTranslationResolver(
      "zh_Hans",
      "highcharts",
      async () => await import("./translations/zh_Hans.json")
    );

    factory.registerRoute({
      path: "/highcharts/chat/*",
      component: async () => ({ default: chatvis }),
    });

    $monitoring.registerWidget(Dependency);
    $monitoring.registerWidget(KPIWidget);
    $monitoring.registerWidget(KPICanvasWidget);
    $monitoring.registerWidget(MultiKPIWidget);
    $monitoring.registerWidget(KPIMinMaxWidget);
    $monitoring.registerWidget(PatternWidget);
    $monitoring.registerWidget(PatternHeatmapWidget);
    $monitoring.registerWidget(PieWidget);
    $monitoring.registerWidget(StatesWidget);
    $monitoring.registerWidget(StatesGanttWidget);
    $monitoring.registerWidget(TimeSeriesWidget);
    $monitoring.registerWidget(TimeSeriesCompareWidget);
    $monitoring.registerWidget(XYCompare);
    $monitoring.registerWidget(CWTableWidget);
    $monitoring.registerWidget(TableWidget);
    $monitoring.registerWidget(TableEnergyStep);
    $monitoring.registerWidget(KPIMappedWidget);
    $monitoring.registerWidget(NetworkTopology);
    $monitoring.registerWidget(NetworkGraph);

    if (window.location.href.includes("localhost")) {
      //Do not register the test widgets in production
      $monitoring.registerWidget(hcTestWidgetDoku);
      $monitoring.registerWidget(FelixTest);
      $monitoring.registerWidget(OpenPorts);
    }
  }
}
