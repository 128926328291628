import {
  Button,
  Checkbox,
  Divider,
  Input,
  Select,
  Steps,
  Table,
  Tabs,
} from "antd";
import * as React from "react";

import {
  useImmerState,
  useSource,
  useTranslation,
  useUrlParam,
} from "@opendash/core";

import {
  DataExplorerDescription,
  DataFetchingOptionsInterface,
  DataItemDimensionIdentifierInterface,
  DataItemHistoryOptionsPicker,
  DataItemInterface,
  DataSelect,
  evaluateDataFetchingOptions,
  useDataService,
} from "@opendash/plugin-timeseries";

interface StateInterface {
  _items: [string, string, number][];
  _time: DataFetchingOptionsInterface;

  title: string;
  description: string;
  start: number;
  end: number;
  items: {
    index: number;
    id: string;
    user: string;
    valueDimension: number;
    label: string;
    interval: string;
    canSum: boolean;
    showData: boolean;
    showChart: boolean;
  }[];
}

export interface Props {
  style?: React.CSSProperties;
  onSave: (callback: () => any) => void;
}

export const Settings = React.memo<Props>(({ onSave }) => {
  const t = useTranslation();
  const [step, setStep] = useUrlParam("tab", 0, "json");
  const [source] = useSource();

  const [state, updateState] = useImmerState<StateInterface>({
    _items: [],
    _time: {
      start: Date.now() - 1000 * 60 * 60 * 1,
      end: Date.now(),
    },

    title: "",
    description: "",
    start: 0,
    end: 0,
    items: [],
  });

  onSave(() => state);

  const items = useItems(state);

  React.useEffect(() => {
    updateState((draft) => {
      draft.items = state._items.map(([source, id, valueDimension], index) => ({
        index,
        id,
        user: source,
        valueDimension,
        label: `${items?.[id]?.name} - ${items?.[id]?.valueTypes?.[valueDimension]?.name}`,
        interval: "3600000",
        canSum: false,
        showData: true,
        showChart: true,
      }));
    });
  }, [state?._items]);

  React.useEffect(() => {
    updateState((draft) => {
      const time = evaluateDataFetchingOptions(state._time, "");

      draft.start = time.start;
      draft.end = time.end;
    });
  }, [state?._time]);

  return (
    <>
      <Steps current={step} onChange={setStep}>
        <Steps.Step
          title={t("openware:reporting.types.basic.items_title")}
          subTitle={t("opendash:data_explorer.step_0_subtitle", {
            count: state._items.length,
          })}
          status={step !== 0 && state._items.length === 0 ? "error" : undefined}
        />
        <Steps.Step title={t("openware:reporting.types.basic.time_title")} />
        <Steps.Step
          title={t("openware:reporting.types.basic.settings_title")}
        />
      </Steps>

      <Divider></Divider>

      <Tabs activeKey={step.toString()} renderTabBar={() => <React.Fragment />}>
        <Tabs.TabPane
          tab={t("openware:reporting.types.basic.items_title")}
          key="0"
        >
          <DataExplorerDescription
            children={t("openware:reporting.types.basic.items_description")}
          />

          <DataSelect
            selectionOptions={{
              select: "dimension",
              min: 1,
              types: ["Number"],
            }}
            selection={state._items}
            onSelection={(nextValue) => {
              updateState((draft) => {
                draft._items =
                  nextValue as DataItemDimensionIdentifierInterface[];
              });
            }}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={t("openware:reporting.types.basic.time_title")}
          key="1"
        >
          <DataExplorerDescription
            children={t("openware:reporting.types.basic.time_description")}
          />

          <DataItemHistoryOptionsPicker
            options={{
              history: true,
              historyRequired: true,
            }}
            value={state._time}
            onChange={(value) => {
              updateState((draft) => void (draft._time = value));
            }}
          />

          <br></br>
          <br></br>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={t("openware:reporting.types.basic.settings_title")}
          key="2"
        >
          <DataExplorerDescription
            children={t("openware:reporting.types.basic.settings_description")}
          />

          <Input
            style={{ marginBottom: 24 }}
            placeholder={t("openware:reporting.types.basic.title")}
            value={state.title}
            onChange={(event) => {
              const value = event.target.value;
              updateState((draft) => void (draft.title = value));
            }}
          />

          <Input
            style={{ marginBottom: 24 }}
            placeholder={t("openware:reporting.types.basic.description")}
            value={state.description}
            onChange={(event) => {
              const value = event.target.value;
              updateState((draft) => void (draft.description = value));
            }}
          />

          <DataExplorerDescription
            children={t(
              "openware:reporting.types.basic.settings_items_description"
            )}
          />

          <Table
            size="middle"
            dataSource={state.items}
            rowKey="index"
            columns={[
              {
                title: t("openware:reporting.types.basic.settings_table_label"),
                dataIndex: "label",
                key: "label",
                // render: (value, row) => {
                //   return (
                //     <Input
                //       disabled={true}
                //       value={value}
                //       onChange={event => {
                //         const value = event.target.value;
                //         updateState(draft => {
                //           draft.items[row.index].label = value;
                //         });
                //       }}
                //     />
                //   );
                // }
              },
              {
                title: t(
                  "openware:reporting.types.basic.settings_table_showData"
                ),
                dataIndex: "showData",
                key: "showData",
                render: (value, row) => {
                  return (
                    <Checkbox
                      checked={value}
                      onChange={(event) => {
                        const value = event.target.checked;
                        updateState((draft) => {
                          draft.items[row.index].showData = value;
                        });
                      }}
                    />
                  );
                },
              },
              {
                title: t(
                  "openware:reporting.types.basic.settings_table_showChart"
                ),
                dataIndex: "showChart",
                key: "showChart",
                render: (value, row) => {
                  return (
                    <Checkbox
                      checked={value}
                      onChange={(event) => {
                        const value = event.target.checked;
                        updateState((draft) => {
                          draft.items[row.index].showChart = value;
                        });
                      }}
                    />
                  );
                },
              },
              {
                title: t(
                  "openware:reporting.types.basic.settings_table_interval"
                ),
                dataIndex: "interval",
                key: "interval",
                render: (value, row) => {
                  return (
                    <Select
                      value={value}
                      onChange={(value) => {
                        updateState((draft) => {
                          draft.items[row.index].interval = value;
                        });
                      }}
                    >
                      <Select.Option
                        value="900000"
                        children={t(
                          "openware:reporting.types.basic.interval_15min"
                        )}
                      />

                      <Select.Option
                        value="3600000"
                        children={t(
                          "openware:reporting.types.basic.interval_1h"
                        )}
                      />

                      <Select.Option
                        value="86400000"
                        children={t(
                          "openware:reporting.types.basic.interval_1d"
                        )}
                      />

                      <Select.Option
                        value="604800000"
                        children={t(
                          "openware:reporting.types.basic.interval_7d"
                        )}
                      />
                    </Select>
                  );
                },
              },
            ]}
          />
        </Tabs.TabPane>
      </Tabs>

      <div>
        <Button
          type="default"
          disabled={step <= 0}
          onClick={(e) => setStep(step - 1)}
          children={t("opendash:ui.back")}
        />
        <Button
          type="primary"
          disabled={step >= 2}
          onClick={(e) => setStep(step + 1)}
          style={{ float: "right" }}
          children={t("opendash:ui.next")}
        />
      </div>
      {/* <Divider />
      <div>
        <pre>{JSON.stringify({ params: state }, null, 2)}</pre>
      </div> */}
    </>
  );
});

// DO NOT COPY this function, it may break at any time
function useItems(state: StateInterface): Record<string, DataItemInterface> {
  const DataService = useDataService();

  return React.useMemo(() => {
    return Object.fromEntries(
      state._items.map(([source, id]) => [
        id,
        DataService._getOrThrowSync(source, id),
      ])
    );
  }, [state?._items]);
}
