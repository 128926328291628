import {
  AppSidebar,
  HeaderMenuItem,
  createInternalComponent,
  useFeedback,
  useSource,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { useNavigate } from "@opendash/router";
import { Button, Menu, Tag } from "antd";
import * as React from "react";
import styled from "styled-components";
import {
  useDashboardCurrent,
  useDashboardsBySource,
  useMonitoringService,
} from "..";

const DashboardMenuContainer = styled.div``;

const DashboardButtonContainer = styled.div`
  float: right;

  .ant-btn-icon-only,
  .ant-btn-icon-only > * {
    font-size: 15px;
  }
`;

const EditToolbarPlaceholder = styled.div`
  height: 60px;
  background: red;
`;

const EditToolbar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;

  background: white;

  box-shadow: rgb(0 0 0 / 20%) 0px 0px 1px 1px;
  z-index: 99;

  display: flex;
`;

const EditToolbarDescription = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

const EditToolbarActions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`;

interface Props {}

export const DashboardManagement = createInternalComponent<Props>(
  function DashboardManagement({}) {
    const t = useTranslation();
    const { message } = useFeedback();

    const MonitoringService = useMonitoringService();

    const dashboards = useDashboardsBySource();
    const [currentDashboard, setDashboard] = useDashboardCurrent();

    const [editMode, setEditMode] = useUrlParam("dashboard_edit", false);
    const [create, setCreate] = useUrlParam("db_create", false);
    const [addWidgets, setAddWidgets] = useUrlParam("db_add_widgets", false);
    const [rename, setRename] = useUrlParam<string>("db_rename", null);
    const [remove, setRemove] = useUrlParam<string>("db_delete", null);
    const [, setExplorer] = useUrlParam("explorer", false);
    const navigate = useNavigate();
    const [source, setSource] = useSource();
    return (
      <>
        {/* <EditToolbarPlaceholder /> */}
        {editMode && (
          <EditToolbar>
            <EditToolbarDescription
              children={t("opendash:dashboards.editmode.info")}
            />
            <EditToolbarActions>
              <Button
                type="primary"
                children={t("opendash:widgets.create.action")}
                onClick={() => {
                  setAddWidgets(true);
                }}
              />
              <Button
                children={t("opendash:dashboards.editmode.action_end")}
                onClick={() => {
                  setEditMode(false);
                }}
              />
            </EditToolbarActions>
          </EditToolbar>
        )}
        {window.innerWidth > 570 && (
          <HeaderMenuItem
            icon="fa:pen"
            active={editMode}
            tooltip={
              editMode
                ? t("opendash:dashboards.editmode.action_end")
                : t("opendash:dashboards.editmode.action")
            }
            onClick={() => {
              setEditMode(!editMode);
            }}
          />
        )}
        <AppSidebar showSourcePicker={true}>
          <DashboardMenuContainer>
            <Menu
              mode="inline"
              selectedKeys={[currentDashboard?.id]}
              openKeys={["dashboard"]}
            >
              <Menu.SubMenu
                key="dashboard"
                title={
                  <>
                    {t("opendash:dashboards.management_title")}
                    <DashboardButtonContainer>
                      <Button
                        type="link"
                        icon={<Icon icon="fa:plus" />}
                        title={t("opendash:dashboards.create.action")}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setCreate(true);
                        }}
                      />
                    </DashboardButtonContainer>
                  </>
                }
              >
                {dashboards
                  .sort((a, b) => {
                    return a.name.localeCompare(b.name);
                  })
                  .map((dashboard) => (
                    <Menu.Item
                      key={dashboard.id}
                      children={
                        <>
                          <span style={{ marginRight: 10 }}>
                            {dashboard.name.length<25?dashboard.name:dashboard.name.substring(0,25)+"..."}
                          </span>

                          {dashboard.isShared && (
                            <Tag>{t("opendash:ui.shared")}</Tag>
                          )}

                          {dashboard.isReadOnly && (
                            <Tag>{t("opendash:ui.readonly")}</Tag>
                          )}

                          <DashboardButtonContainer>
                            {MonitoringService.adapterSupportsDashboardSharing && (
                              <Button
                                type="link"
                                icon={<Icon icon="fa:share-alt" />}
                                title={t(
                                  "opendash:dashboards.share.action_desc"
                                )}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  MonitoringService.openDashboardSharingDialog(
                                    dashboard
                                  );
                                }}
                              />
                            )}
                            <Button
                              type="link"
                              icon={<Icon icon="fa:pen" />}
                              title={t(
                                "opendash:dashboards.rename.action_desc"
                              )}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setRename(dashboard.id);
                              }}
                            />
                            <Button
                              type="link"
                              icon={<Icon icon="fa:copy" />}
                              title={t("opendash:dashboards.copy.action_desc")}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                MonitoringService.openCopyDashboardDialog(
                                  dashboard.id
                                );
                              }}
                            />
                            <Button
                              type="link"
                              icon={<Icon icon="fa:trash" />}
                              title={t(
                                "opendash:dashboards.delete.action_desc"
                              )}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setRemove(dashboard.id);
                              }}
                            />
                            <Button
                              type="link"
                              icon={<Icon icon="fa:link" />}
                              title={t("opendash:dashboards.link.action_desc")}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const dashboardLink =
                                  window.location.origin.toString() +
                                  "/monitoring/dashboards/" +
                                  source.id +
                                  "/" +
                                  dashboard.id;
                                const data = [
                                  new ClipboardItem({
                                    "text/plain": new Blob([dashboardLink], {
                                      type: "text/plain",
                                    }),
                                  }),
                                ];
                                navigator.clipboard.write(data).then(
                                  function () {
                                    message.success(
                                      t(
                                        "opendash:dashboards.link.action_success"
                                      )
                                    );
                                  },
                                  function () {
                                    console.error(
                                      t(
                                        "opendash:dashboards.link.action_failed"
                                      )
                                    );
                                  }
                                );
                              }}
                            />
                          </DashboardButtonContainer>
                        </>
                      }
                      title={t("opendash:dashboards.change.action_desc")}
                      //onClick={() => setDashboard(dashboard)}
                      onClick={() => {
                        navigate(
                          "/monitoring/dashboards/" +
                            source.id +
                            "/" +
                            dashboard.id
                        );
                      }}
                    />
                  ))}
              </Menu.SubMenu>
            </Menu>
          </DashboardMenuContainer>
          <DashboardMenuContainer>
            <Menu
              mode="inline"
              selectedKeys={[
                ...(addWidgets ? ["widget_create"] : []),
                ...(create ? ["dashboard_create"] : []),
                ...(editMode ? ["dashboard_edit"] : []),
              ]}
              openKeys={["dashboard"]}
            >
              <Menu.SubMenu
                key="dashboard"
                title={t("opendash:monitoring.widgets.title")}
              >
                <Menu.Item
                  key="widget_create"
                  disabled={!currentDashboard}
                  children={t("opendash:widgets.create.action")}
                  onClick={() => {
                    setAddWidgets(true);
                  }}
                />

                <Menu.Item
                  key="explorer"
                  disabled={!currentDashboard}
                  children={t("opendash:monitoring.explorer.label")}
                  onClick={() => {
                    setExplorer(true);
                  }}
                />
                {/* 
                <Menu.Item
                  key="dashboard_edit"
                  disabled={!currentDashboard}
                  children={t("opendash:dashboards.editmode.action")}
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                />*/}
              </Menu.SubMenu>
            </Menu>
          </DashboardMenuContainer>
        </AppSidebar>

        {/* <Divider
            type="vertical"
            style={{ float: "left", top: 8, height: 32 }}
          />

          <HeaderMenuItem
            disabled={!dashboard}
            tooltip={t("opendash:widgets.create.action")}
            onClick={() => setAddWidgets(true)}
            icon="fa:plus"
          />
          <HeaderMenuItem
            disabled={!dashboard}
            tooltip={t("opendash:dashboards.editmode.action")}
            onClick={() => setEditMode(!editMode)}
            icon={
              editMode ? "fa:lock-open": "fa:lock"
            }
          />

          <Divider
            type="vertical"
            style={{ float: "left", top: 8, height: 32 }}
          />

          <HeaderMenuItem
            tooltip={t("opendash:monitoring.data_sidebar.action_desc")}
            onClick={() => setDatasidebar(true)}
            icon="fa:list-alt"
          /> */}
      </>
    );
  }
);
