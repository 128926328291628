import { useTranslation } from "@opendash/i18n";
import { Collapse, Form } from "antd";
import dayjs, { Dayjs } from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  EditorFormType,
  EndDatePicker,
  StartDatePicker,
  TimeSeries,
  useEditorItems,
} from "..";

require("dayjs/locale/de");
dayjs.locale("de");
dayjs.extend(objectSupport);

/**
 * React component to create cron strings visually
 * @author Nico Vitt
 */
export const CronEditor: React.FC<EditorFormType> = observer(
  ({ value, onChange, reset, showCalendar = true, showCronString = true }) => {
    const t = useTranslation();
    const [state, editoritems] = useEditorItems({
      value: value,
      onChange: onChange,
      showCalendar,
      showCron: showCronString,
      reset: reset,
    });

    return (
      <>
        <Form.Item
          label={t("maintenance:schedule.create.form.startdate.label")}
          name="startdate"
          rules={[
            {
              required: true,
              message: t(
                "maintenance:schedule.create.form.startdate.rules.required"
              ),
            },
          ]}
        >
          <StartDatePicker
            onlyWeeks={state.onlyWeeks}
            setOnlyWeeks={(v: boolean) => {
              runInAction(() => {
                state.onlyWeeks = v;
              });
            }}
            onChange={(newdate: Dayjs) => {
              runInAction(() => {
                state.startdate = newdate;
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label={t("maintenance:schedule.create.form.enddate.label")}
          name="enddate"
          rules={[{ required: false }]}
        >
          <EndDatePicker
            onlyWeeks={state.onlyWeeks}
            startdate={state.startdate}
            onChange={(v) => (state.currentEnddate = v)}
            disabled={state.timeSeries === 0}
          />
        </Form.Item>
        <Form.Item
          label={t("maintenance:schedule.create.form.timeseries.label")}
          name="timeseries"
          initialValue={state.timeSeries}
          rules={[
            {
              required: true,
              message: t(
                "maintenance:schedule.create.form.timeseries.rules.required"
              ),
            },
          ]}
        >
          <TimeSeries
            onChange={(value) => {
              runInAction(() => {
                state.timeSeries = value;
              });
            }}
          />
        </Form.Item>
        <Collapse items={editoritems} />
      </>
    );
  }
);
