import { DatePicker } from "antd";
import dayjs from "dayjs";
import React from "react";

import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(LocalizedFormat);

export interface DateDisplayProps {
  value: Date;
  isoformat?: boolean;
}

export const DateDisplay = React.memo<DateDisplayProps>(function DateDisplay({
  value,
  isoformat = false,
}) {
  if (isoformat) {
    return <span>{value.toJSON()}</span>;
  }
  return <span>{dayjs(value).format("L LTS")}</span>;
});

export interface DateEditProps {
  value: Date;
  setValue?: (v: Date) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const DateEdit = React.memo<DateEditProps>(function DateEdit({
  value,
  setValue,
  isNullable,
}) {
  return (
    <DatePicker
      value={value ? dayjs(value) : undefined}
      onChange={(v) => setValue(v?.toDate() || null)}
      style={{ width: "100%" }}
      allowClear={isNullable}
    />
  );
});
