import { $framework, useTranslation } from "@opendash/core";
import { createComponent } from "@opendash/state";
import { Button, Form, Input, Typography } from "antd";
import React from "react";
import { $parse } from "../..";
import { AuthLayout } from "./AuthLayout";

export const AuthPasswordResetRoute = createComponent(
  function AuthPasswordResetRoute() {
    const t = useTranslation();

    React.useEffect(() => {
      if (!$parse.auth.loading) {
        $parse.auth.validateToken("password-reset");
      }
    }, [$parse.auth.loading]);

    if ($parse.auth.loading) return null;

    return (
      <AuthLayout width={400}>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(data) => {
            $parse.auth.sendPasswordReset(data);
          }}
        >
          <Typography.Title
            level={3}
            children={t("parse-admin:auth.password_reset_title")}
          />

          <Typography.Paragraph
            children={t("parse-admin:auth.password_reset_description")}
          />

          <Form.Item
            name="password"
            label={t("parse-admin:auth.field_password_label")}
            required
            tooltip={t("parse-admin:auth.field_password_tooltip")}
            rules={[
              {
                required: true,
                message: t(
                  "parse-admin:auth.field_password_validation_required"
                ),
              },
              {
                type: "string",
                min: $parse.server.config.AUTH_PASSWORD_MIN_LENGTH,
                message: t(
                  "parse-admin:auth.field_password_validation_length",
                  {
                    length: $parse.server.config.AUTH_PASSWORD_MIN_LENGTH,
                  }
                ),
              },
            ]}
          >
            <Input.Password
              placeholder={t("parse-admin:auth.field_password_placeholder")}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              children={t("opendash:ui.submit")}
            />
            <div
              style={{
                height: "20px",
                width: "100%",
              }}
            ></div>
            <Button
              key="login"
              type="primary"
              style={{ width: "100%" }}
              children={t("parse-admin:auth.back_to_login")}
              onClick={() => {
                $framework.router.navigate("/auth/login");
              }}
            />
          </Form.Item>
        </Form>
      </AuthLayout>
    );
  }
);
