import { DatePicker } from "antd";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";

type CustomFormItemProps = {
  value?: Dayjs;
  onChange?: (date: Dayjs) => void;
  onlyWeeks?: boolean;
  startdate?: Dayjs;
  disabled: boolean;
};

export const EndDatePicker: React.FC<CustomFormItemProps> = ({
  value,
  onChange,
  onlyWeeks,
  startdate,
  disabled,
}) => {
  const [enddate, setEnddate] = useState<Dayjs>(value);

  useEffect(() => {
    //If the startdate changes and is greater than the current enddate value end date will be changed
    if (startdate > value) {
      setEnddate(startdate);
    }
  }, [startdate]);

  return (
    <DatePicker
      onChange={(date, dateString) => {
        if (date !== null) {
          setEnddate(date);
          onChange(date);
        }
      }}
      allowClear={true}
      picker={onlyWeeks ? "week" : "date"}
      disabledDate={(current) => {
        // Can not select dates prior to the dates from startdate
        return current < startdate;
      }}
      // renderExtraFooter={() => {
      //   //Add a Checkbox for week selection
      //   return (
      //     <Checkbox
      //       defaultChecked={onlyWeeks}
      //       onChange={(e) => {
      //         onlyWeeks = e.target.checked;
      //         onChange(enddate);
      //       }}
      //     >
      //       Auswahl von Kalenderwochen
      //     </Checkbox>
      //   );
      // }}
      value={enddate}
      style={{ width: "100%" }}
      showTime={{ format: "HH:mm" }}
      format={onlyWeeks ? "[KW] WW in YYYY" : "DD.MM.YYYY @ HH:mm"}
      disabled={disabled}
    />
  );
};
