import { $framework } from "../exports";

const DISALLOED_HUE_RANGES = [
  [20, 210],
  [240, 340],
];

const NUMBER_RANGE =
  360 -
  DISALLOED_HUE_RANGES.reduce(
    (previous, [start, end]) => previous + (end - start),
    0
  );

export function stringToColor(str: string): string {
  const hash = stringToHash(str);
  const color = numberToColor(hash);

  return color;
}

function stringToHash(str: string): number {
  let hash = 0;

  str = btoa(str);

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  hash = hash * 9301 + 49297;

  return hash;
}

function quersumme(zahl) {
  const tmp = zahl.toString().split("");
  let quer = 0;
  for (let i = 0; i < tmp.length; i++) {
    quer += Number(tmp[i]);
  }
  return quer;
}

function numberToColor(hash: number): string {
  let startNumber = hash;
  if (startNumber < 0) {
    startNumber = startNumber * -1;
  }
  startNumber = quersumme(startNumber);
  //open.DASH Blau #4186c6
  //open.INC Grau #8d8e8f
  //open.INC Grün #7fbb47
  //open.INC Gelb #d5d92e

  const colorPalette = $framework.ui.colorPaletteHex;
  startNumber = startNumber % colorPalette.length;
  // const colorPalette = [
  //   "#e8c720",
  //   "#8d8e8f",
  //   "#7fbb47",
  //   "#d5d92e",
  //   "#222",
  //   "#666",
  //   "#aaa",
  //   "#ddd",
  //   "#4186c6",
  //   "#f2414d",
  //   "#12ccaa",
  //   "#e32d2d",
  //   "#42617d",
  // ];

  return colorPalette[startNumber];

  /*
  hash = Math.abs(hash);

  const saturation = 70;
  const lightness = 40;

  const hue = DISALLOED_HUE_RANGES.reduce(
    (acc, [start, end]) => (acc >= start ? acc + (end - start) : acc),
    hash % NUMBER_RANGE
  );

  const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  return HSLToHex(hue, saturation, lightness);*/
}
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex([r, g, b]) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexToRgb(hex): [number, number, number] {
  const result = hex.match(
    /^#?([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})$|^#?([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])$/
  );
  let components = null;
  if (result) {
    // For 6-digit hex colors
    if (result[1]) {
      components = [result[1], result[2], result[3]];
    }
    // For 3-digit hex colors, double each digit
    if (result[4]) {
      components = [
        result[4] + result[4],
        result[5] + result[5],
        result[6] + result[6],
      ];
    }
  }
  if (!components) throw new Error("Invalid color pallete provided");
  return [
    parseInt(components[0], 16),
    parseInt(components[1], 16),
    parseInt(components[2], 16),
  ];
}

function HSLToHex(h, s, l) {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  let rString = Math.round((r + m) * 255).toString(16);
  let gString = Math.round((g + m) * 255).toString(16);
  let bString = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (rString.length == 1) rString = "0" + rString;
  if (gString.length == 1) gString = "0" + gString;
  if (bString.length == 1) bString = "0" + bString;

  return "#" + rString + gString + bString;
}

// test:
// new Array(1000).fill(0).forEach((_, i) => console.info(numberToColor(i)));
