import { createWidget } from "@opendash/plugin-monitoring";
import React from "react";
import imageLink from "./preview";
import { ConfigInterface } from "./types";

export default createWidget<ConfigInterface>({
  type: "opendash-widget-hc-kpi-canvas",
  meta: {},
  displayComponent: React.lazy(() => import("./component")),
  settingsComponent: React.lazy(() => import("./settings")),
  mobileSize: () => {
    return 600;
  },
  presets: [
    {
      label: "highcharts:canvas.title",
      description: "highcharts:canvas.description",
      tags: ["kpi"],
      imageLink,
      layout: [12, 8],
      widget: {
        config: {},
      },
    },
  ],
});
