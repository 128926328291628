import React from "react";

import { useTranslation } from "@opendash/core";

import { Button, Input, Select } from "antd";
import styled from "styled-components";

import { Icon } from "@opendash/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";

const AdminTableFilterContainer = styled.div`
  padding: 8px;
`;

interface Props extends FilterDropdownProps {
  type: string;
}

export const ParseTableFilter = React.memo<Props>(function ParseTableFilter({
  type,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) {
  const t = useTranslation();

  if (type === "Boolean") {
    return (
      <AdminTableFilterContainer>
        <Select
          placeholder={t("parse-admin:admin.filter.select_placeholder")}
          style={{ width: 208, marginBottom: 8, display: "block" }}
          defaultValue={selectedKeys[0]}
          onChange={(v: string) => {
            setSelectedKeys([v]);
          }}
        >
          <Select.Option
            value="equals_true"
            children={t("parse-admin:admin.filter.equals_true")}
          />
          <Select.Option
            value="equals_false"
            children={t("parse-admin:admin.filter.equals_false")}
          />
          <Select.Option
            value="exists"
            children={t("parse-admin:admin.filter.exists")}
          />
          <Select.Option
            value="exists_not"
            children={t("parse-admin:admin.filter.exists_not")}
          />
        </Select>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<Icon icon="fa:search" />}
          size="small"
          style={{ width: 100, marginRight: 8 }}
          children={t("parse-admin:admin.filter.apply")}
        />

        <Button
          onClick={() => {
            clearFilters();
            confirm();
          }}
          size="small"
          style={{ width: 100 }}
          children={t("parse-admin:admin.filter.reset")}
        />
      </AdminTableFilterContainer>
    );
  }

  return (
    <AdminTableFilterContainer>
      <Select
        placeholder={t("parse-admin:admin.filter.select_placeholder")}
        style={{ width: 208, marginBottom: 8, display: "block" }}
        defaultValue={selectedKeys[0]}
        onChange={(v: string) => {
          setSelectedKeys([v]);
        }}
      >
        <Select.Option
          value="contains"
          children={t("parse-admin:admin.filter.contains")}
        />
        <Select.Option
          value="equals"
          children={t("parse-admin:admin.filter.equals")}
        />
        <Select.Option
          value="equals_not"
          children={t("parse-admin:admin.filter.equals_not")}
        />
        <Select.Option
          value="starts_with"
          children={t("parse-admin:admin.filter.starts_with")}
        />
        <Select.Option
          value="ends_with"
          children={t("parse-admin:admin.filter.ends_with")}
        />
        <Select.Option
          value="exists"
          children={t("parse-admin:admin.filter.exists")}
        />
        <Select.Option
          value="exists_not"
          children={t("parse-admin:admin.filter.exists_not")}
        />
      </Select>
      {[
        "equals",
        "equals_not",
        "starts_with",
        "ends_with",
        "contains",
      ].includes(selectedKeys[0] as string) && (
        <Input
          value={selectedKeys[1]}
          onChange={(e) => setSelectedKeys([selectedKeys[0], e.target.value])}
          onPressEnter={() => confirm()}
          placeholder={t("parse-admin:admin.filter.input_placeholder")}
          style={{ width: 208, marginBottom: 8, display: "block" }}
        />
      )}
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<Icon icon="fa:search" />}
        size="small"
        style={{ width: 100, marginRight: 8 }}
        children={t("parse-admin:admin.filter.apply")}
      />

      <Button
        onClick={() => {
          clearFilters();
          confirm();
        }}
        size="small"
        style={{ width: 100 }}
        children={t("parse-admin:admin.filter.reset")}
      />
    </AdminTableFilterContainer>
  );
});
