import type {
  NavigationAdapterInterface,
  NavigationGroupInterface,
  NavigationItemInterface,
  NavigationPlaceInterface,
} from "..";

import { BaseService } from "../classes/BaseService";
import type { FrameworkService } from "./FrameworkService";
import { NavigationAdapterContext } from "./NavigationAdapterContext";

export class NavigationService extends BaseService {
  private framework: FrameworkService;
  private adapter: NavigationAdapterInterface;
  private context: NavigationAdapterContext;

  private dynamicNavigationGroups = new Set<NavigationGroupInterface>();
  private dynamicNavigationItems = new Set<NavigationItemInterface>();

  constructor(framework: FrameworkService) {
    super({
      initialState: {},
    });

    this.framework = framework;
    this.context = new NavigationAdapterContext(this, framework);
  }

  public async init(adapter: NavigationAdapterInterface) {
    this.adapter = adapter;
    this.initAdapter(adapter, this.context);
  }

  getGroups(place: NavigationPlaceInterface): NavigationGroupInterface[] {
    return Array.from(this.dynamicNavigationGroups.values());
  }

  getItems(place: NavigationPlaceInterface): NavigationItemInterface[] {
    return Array.from(this.dynamicNavigationItems.values());
  }

  registerDynamicNavigationGroup(group: NavigationGroupInterface): () => void {
    this.dynamicNavigationGroups.add(group);
    super.notifySubscribers();

    return () => {
      this.dynamicNavigationGroups.delete(group);
      super.notifySubscribers();
    };
  }

  registerDynamicNavigationItem(item: NavigationItemInterface): () => void {
    this.dynamicNavigationItems.add(item);
    super.notifySubscribers();

    return () => {
      this.dynamicNavigationItems.delete(item);
      super.notifySubscribers();
    };
  }
}
