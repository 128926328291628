import {
  Loading,
  createInternalComponent,
  equals,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Drawer, InputNumber, Typography } from "antd";
import debounce from "lodash.debounce";
import * as React from "react";
import GridLayout from "react-grid-layout";
import styled from "styled-components";
import {
  DashboardInterface,
  DashboardStyle,
  WidgetComponent,
  useMonitoringService,
  useWidgetTypes,
  useWidgetsForDashboard,
} from "..";
import { ErrorMessage } from "./_layout";

interface Props {
  dashboard: DashboardInterface;
}

const Container = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;

  width: 100%;
  min-height: 100%;

  /* > .react-grid-layout {
    min-height: 100%;
  } */
`;

export const DashboardDisplay = createInternalComponent<Props>(
  function DashboardDisplay({ dashboard }) {
    const MonitoringService = useMonitoringService();
    const t = useTranslation();
    const container = React.useRef();

    const [layout, setLayout] = React.useState(dashboard?.layout);
    const [dbLoading, setDBLoading] = React.useState(false);
    const [dbPreLoading, setDBPreLoading] = React.useState(true);

    const widgets = useWidgetsForDashboard(dashboard);
    const widgetTypes = useWidgetTypes();

    const [editMode] = useUrlParam("dashboard_edit", false);

    const [, setAddDashboard] = useUrlParam("db_create", false);
    const [, setAddWidgets] = useUrlParam("db_add_widgets", false);
    const [, setDashboardAssist] = useUrlParam("db_assist", false);

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const [size, setSize] = React.useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    React.useEffect(() => {
      const handleResize = debounce(() => {
        setSize({
          width: document.body.clientWidth,
          height: document.body.clientHeight,
        });
      }, 200);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    const hero = MonitoringService.getHeroWidget(dashboard);
    const heroLeft = MonitoringService.getHeroWidgetLeft(dashboard);
    const heroHeight = hero?.height || 0;
    const heroLeftWidth = heroLeft?.width || 0;
    const heroLeftHeight = React.useMemo(() => {
      return (100 - heroHeight) * (document.documentElement.clientHeight / 100);
    }, [heroHeight, size.height]);

    const dashBoardWidth =
      size.width - (document.documentElement.clientWidth / 100) * heroLeftWidth;

    // Update layout, if it changes elsewhere
    React.useEffect(() => {
      if (dashboard?.layout && !equals(layout, dashboard.layout)) {
        setLayout(dashboard.layout);
      }
    }, [dashboard?.id, dashboard?.layout]);

    React.useEffect(() => {
      if (dashboard?.widgets.length > 0) {
        if (widgets.length > 0) {
          setTimeout(() => {
            setDBPreLoading(false);
          }, 200);
        }
      } else {
        setDBPreLoading(false);
      }
    }, [widgets]);

    const heroTop = (
      <>
        {hero && editMode && (
          <div
            style={{
              position: "absolute",
              top: "62px",
              zIndex: 1000,
              right: "10px",
              background: "#fff",
              border: "1px solid #f0f0f0",
              lineHeight: "46px",
              width: "392px",
              boxShadow: "rgb(0 0 0 / 20%) 0px 0px 1px 1px",
              borderRadius: "2px",
              padding: "0px 10px",
            }}
          >
            <Icon icon="fa:arrows-alt-v"></Icon>
            <Typography.Text style={{ marginLeft: "5px" }}>
              {t("opendash:ui.adjust_height")}
            </Typography.Text>
            <InputNumber
              min={10}
              max={100}
              step={5}
              addonAfter="%"
              style={{
                float: "right",
                marginTop: "6px",
                marginRight: "5px",
                width: "200px",
              }}
              value={hero.height}
              onChange={(e) => {
                const newHeight = e;
                MonitoringService.setHero(hero.widget, newHeight);
              }}
            ></InputNumber>
          </div>
        )}
        {hero && (
          <div
            style={{
              width: "100%",
              height: hero.height + "vh",
              overflow: "auto",
            }}
          >
            <WidgetComponent id={hero.widget} borderless />
          </div>
        )}
      </>
    );

    const heroLeftWrapper = (
      <>
        {heroLeft && size.width < 600 && (
          <>
            <Button
              type="text"
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
              }}
              onClick={() => {
                setMobileOpen(true);
              }}
            >
              <Icon icon="fa:arrow-right"></Icon>
            </Button>
            <Drawer
              open={mobileOpen}
              placement="left"
              closable={true}
              onClose={() => {
                setMobileOpen(false);
              }}
            >
              <div
                className="hero-left"
                style={{
                  height: "calc(100% - 40px)",
                  width: "calc(100% - 20px)",
                }}
              >
                <WidgetComponent id={heroLeft.widget} borderless />
              </div>
            </Drawer>
          </>
        )}
        {heroLeft && size.width >= 600 && (
          <div
            className="hero-left"
            style={{
              width: heroLeft.width + "vw",
              height:
                "calc(" +
                heroLeftHeight +
                "px - var(--opendash-header-height) - 40px)",
              flexGrow: 0,
            }}
          >
            <WidgetComponent id={heroLeft.widget} borderless />
          </div>
        )}
        {heroLeft && editMode && (
          <div
            className="hero-left-resize"
            style={{
              position: "absolute",
              top: !hero ? "62px" : "calc(" + hero.height + "vh" + " + 62px)",
              zIndex: 1000,
              left: "calc(" + heroLeft.width + "vw - 402px)",
              background: "#fff",
              border: "1px solid #f0f0f0",
              lineHeight: "46px",
              width: "392px",
              boxShadow: "rgb(0 0 0 / 20%) 0px 0px 1px 1px",
              borderRadius: "2px",
              padding: "0px 10 px",
            }}
          >
            <Icon icon="fa:arrows-alt-v"></Icon>
            <Typography.Text style={{ marginLeft: "5px" }}>
              {t("opendash:ui.adjust_width")}
            </Typography.Text>
            <InputNumber
              min={10}
              max={100}
              step={5}
              addonAfter="%"
              style={{
                float: "right",
                marginTop: "6px",
                marginRight: "5px",
                width: "200px",
              }}
              value={heroLeft.width}
              onChange={(e) => {
                const newWidth = e;
                MonitoringService.setHeroLeft(heroLeft.widget, newWidth);
              }}
            ></InputNumber>
          </div>
        )}
      </>
    );

    MonitoringService.store.subscribe((state) => {
      if (state.loadingDashboard != dbLoading) {
        setDBLoading(state.loadingDashboard);
      }
    });

    if (dbLoading || dbPreLoading) {
      return <Loading message="" />;
    }

    if (!dashboard) {
      return (
        <>
          <Container ref={container}>
            {heroTop}
            <div style={{ display: "flex", flexDirection: "row" }}>
              {heroLeftWrapper}

              <div style={{ width: "100%", flexGrow: 1 }}>
                <ErrorMessage
                  height="400px"
                  ref={container}
                  icon={<Icon icon="fa:frown" />}
                  title={t(
                    "opendash:monitoring.dashboards.error.no_dashboard.title"
                  )}
                  message={t(
                    "opendash:monitoring.dashboards.error.no_dashboard.message"
                  )}
                  actionLabel={t("opendash:dashboards.create.action")}
                  actionClick={() => {
                    setAddDashboard(true);
                  }}
                />
              </div>
            </div>
          </Container>
        </>
      );
    }

    if (dashboard?.widgets?.length === 0) {
      return (
        <>
          <Container ref={container}>
            {heroTop}
            <div style={{ display: "flex", flexDirection: "row" }}>
              {heroLeftWrapper}

              <div style={{ width: "100%", flexGrow: 1 }}>
                <ErrorMessage
                  height="400px"
                  ref={container}
                  icon={<Icon icon="fa:frown" />}
                  title={t(
                    "opendash:monitoring.dashboards.error.no_widgets.title"
                  )}
                  message={t(
                    "opendash:monitoring.dashboards.error.no_widgets.message"
                  )}
                  actionLabel={t("opendash:widgets.create.action")}
                  actionClick={() => {
                    setAddWidgets(true);
                  }}
                  secondActionLabel={t("opendash:widgets.create.secondaction")}
                  secondActionClick={() => {
                    setDashboardAssist(true);
                  }}
                />
              </div>
            </div>
          </Container>
        </>
      );
    }

    if (size.width < 600) {
      return (
        <Container ref={container}>
          {heroTop}
          {(!!layout.find((entry) => entry.i === dashboard?.widgets[0]) ||
            dashboard?.widgets.length === 0) && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {heroLeftWrapper}
              {/* {hero && (
            <div style={{ width: "100%", height: "" + hero.height + "vh" }}>
              <WidgetComponent id={hero.widget} borderless />
            </div>
          )} */}
              <div style={{ flexGrow: 1 }}>
                {widgets
                  .sort((a, b) => {
                    const coordA = layout.find((l) => l.i === a.id);
                    const coordB = layout.find((l) => l.i === b.id);

                    const { x: xA, y: yA } = coordA;
                    const { x: xB, y: yB } = coordB;
                    if (yA === yB) {
                      return xA - xB;
                    } else {
                      return yA - yB;
                    }
                  })
                  .map((widget) => {
                    const containerWidth = size.width;
                    const widgetType = widgetTypes.find(
                      (wt) => wt.type === widget.type
                    );

                    const orientation = window.matchMedia(
                      "(orientation: portrait)"
                    ).matches
                      ? "portrait"
                      : "landscape";

                    const height = widgetType?.mobileSize
                      ? widgetType.mobileSize(
                          containerWidth,
                          orientation,
                          widget.config
                        )
                      : (containerWidth / 3) * 2;

                    if (height === null) {
                      return null;
                    }

                    return (
                      <div
                        key={widget.id}
                        style={{
                          height: Math.floor(height) + 50,
                          padding: 10,
                          width: size.width,
                        }}
                      >
                        <WidgetComponent
                          id={widget.id}
                          borderless={
                            widget.config?._style?.borderless || false
                          }
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </Container>
      );
    }

    return (
      <>
        <Container ref={container}>
          <DashboardStyle />
          {heroTop}

          <div style={{ display: "flex", flexDirection: "row" }}>
            {heroLeft && heroLeftWrapper}
            {dashboard && dashboard?.layout && (
              <GridLayout
                useCSSTransforms={false}
                key={dashboard.id}
                style={{ flexGrow: 1 }}
                verticalCompact={true}
                width={dashBoardWidth}
                margin={[0, 0]}
                //containerPadding={[8, 8]}
                cols={24}
                rowHeight={80 /*Math.floor(size.width / 24)*/}
                layout={layout}
                isDraggable={!!editMode}
                isResizable={!!editMode}
                resizeHandles={["s", "e", "se"]}
                onLayoutChange={(nextLayout) => {
                  setLayout(nextLayout);
                  if (editMode) {
                    MonitoringService.updateDashboardLayout(nextLayout);
                  }
                }}
              >
                {widgets.map((widget) => {
                  return (
                    <div
                      key={widget.id}
                      style={{
                        padding:
                          "_style" in widget.config &&
                          "padding" in widget.config._style
                            ? widget.config._style.padding
                            : 8,
                      }}
                    >
                      <WidgetComponent
                        id={widget.id}
                        borderless={widget.config?._style?.borderless || false}
                      />
                    </div>
                  );
                })}
              </GridLayout>
            )}
          </div>
          {editMode && <div style={{ minHeight: "24px" }}></div>}
        </Container>
      </>
    );
  }
);
