import { $parse } from "../state";
import { ParseClassConfig } from "../types";

export function getObjectTitle(
  object: Parse.Object,
  config: ParseClassConfig,
  defaultLabel?: string
) {
  let label: string = defaultLabel || object.id;

  if (
    config &&
    Array.isArray(config.titleFields) &&
    config.titleFields.length > 0
  ) {
    label = config.titleFields
      .map((attribute) => object.get(attribute))
      .join(", ");
  } else {
    console.warn(`Config for Pointer -> '${object.className}' not found.`);
  }

  return label;
}

function getTranslationPrefix(className: string) {
  const config = $parse.ui.getClassConfig(className);
  const prefix = config?.translationPrefix || "parse-custom:classes.";

  return prefix + className;
}

export function translateClassName(
  t,
  className: string,
  count: number = 1
): string {
  const prefix = getTranslationPrefix(className);
  return t(`${prefix}.label`, {
    default: className.split("_").pop(),
    count,
  });
}

export function translateClassDescription(
  t,
  className: string,
  count: number = 1
): string {
  const prefix = getTranslationPrefix(className);

  return t(`${prefix}.view_description`, {
    default: t("parse-admin:admin.label"),
    count,
  });
}

export function translateClassDialog(
  t,
  className: string,
  mode: "create" | "update" | "edit"
): string {
  if (mode === "edit") {
    mode = "update";
  }

  const prefix = getTranslationPrefix(className);

  return t(`${prefix}.${mode}_dialog_title`, {
    default: t(`parse-admin:admin.${mode}.modal_title`, {
      type: translateClassName(t, className),
    }),
  });
}

export function translateClassField(
  t,
  className: string,
  fieldName: string,
  count: number = 1
): string {
  const prefix = getTranslationPrefix(className);

  return t(`${prefix}.fields.${fieldName}`, {
    default: fieldName === "id" ? "" : fieldName,
    count,
  });
}

export function translateClassFieldDescription(
  t,
  className: string,
  fieldName: string,
  count: number = 1
): string {
  const prefix = getTranslationPrefix(className);

  return t(`${prefix}.fields.${fieldName}_description`, {
    default: "",
    count,
  });
}

export function getObjectActions(object: Parse.Object, onChange?: () => void) {
  const actions = [];

  if ($parse.ui.getObjectPermission(object, "update")) {
    actions.push({
      key: "edit",
      title: "parse-admin:admin.edit.tooltip",
      icon: "fa:pen",
      onClick: () =>
        $parse.ui.updateObject(object.className, object.id).then(() => {
          if (onChange) onChange();
        }),
    });
  }

  if ($parse.ui.getObjectPermission(object, "acl")) {
    actions.push({
      key: "acl",
      title: "parse-admin:admin.edit_permission_title",
      icon: "fa:lock",
      onClick: () =>
        $parse.ui.editObjectACL(object.className, object.id).then(() => {
          if (onChange) onChange();
        }),
    });
  }

  if ($parse.ui.getClassPermission(object.className, "create")) {
    actions.push({
      key: "copy",
      title: "parse-admin:admin.copy.tooltip",
      icon: "fa:copy",
      onClick: (e) =>
        $parse.ui.copyObject(object.className, object.id).then(() => {
          if (onChange) onChange();
        }),
    });
  }

  if ($parse.ui.getObjectPermission(object, "delete")) {
    actions.push({
      key: "delete",
      title: "parse-admin:admin.delete.tooltip_single",
      icon: "fa:trash",
      onClick: () =>
        $parse.ui.deleteObject(object.className, object?.id).then(() => {
          if (onChange) onChange();
        }),
    });
  }

  return actions;
}
