import React from "react";

import { createComponent } from "@opendash/state";

import { $parse } from "../state";
import { ParseDialog } from "./ParseDialog";

export const ParseGlobals = createComponent(function ParseGlobals() {
  return (
    <>
      {Object.entries($parse.ui.dialog).map(([key, dialog]) => (
        <ParseDialog key={key} dialog={dialog} />
      ))}
    </>
  );
});
