import { $framework, Lock, Subscription, UserInterface } from "@opendash/core";
import { makeAutoObservable } from "@opendash/state";
import Parse from "parse";
import { runConfig } from "../cloud-functions";
import {
  ParseClassSchema,
  ParseServerConfig,
  ParseServerTenantOption,
} from "../types";
import type { ParseService } from "./ParseService";

export class ParseServerService {
  private parse: ParseService;

  public lock = new Lock(true);
  public subscription = new Subscription<boolean>(false);

  public userId: string | undefined;
  public user: UserInterface | undefined;
  public userTenant: ParseServerTenantOption | undefined = undefined;
  public users: Parse.User[] = [];
  public roles: string[] = [];
  public config: Partial<ParseServerConfig> = {};
  public tenants: Array<ParseServerTenantOption> = [];
  public schema: ParseClassSchema[] = [];
  public schemaMap: Record<string, ParseClassSchema> = {};

  constructor(parse: ParseService) {
    this.parse = parse;

    makeAutoObservable(this);
  }

  public async init() {
    await this.fetch();
  }

  private async fetch() {
    this.lock.lock();

    try {
      const result = await runConfig();
      const user = await this.fetchUser();
      const users = result.userId
        ? await new Parse.Query(Parse.User)
            .limit(1_000_000)
            .ascending(["email", "username", "name"])
            .find()
        : [];

      const userTenant = result.tenants.find((t) => t.key === user?.tenant);

      this.userId = result.userId;
      this.user = user;
      this.users = users;
      this.roles = result.roles;
      this.config = result.config;
      this.tenants = result.tenants;
      this.userTenant = userTenant;
      this.schema = result.schema;
      this.schemaMap = Object.fromEntries(
        this.schema.map((s) => [s.className, s])
      );

      await this.parse.user.init();

      this.lock.unlock();

      await this.subscription.notify(!!user);

      $framework.ui2.setLinks({
        copyrightText: result.config.APP_COPYRIGHT_TEXT || "",
        copyrightLink: result.config.APP_COPYRIGHT_LINK || "",

        imprint: result.config.APP_IMPRINT_URL || "",
        tos: result.config.APP_TOS_URL || "",
        dataProtection: result.config.APP_DATA_PROTECTION_URL || "",

        imprintTenant: userTenant?.imprintUrl || "",
        dataProtectionTenant: userTenant?.dataProtectionUrl || "",
      });
    } catch (error) {
      if (error instanceof Parse.Error) {
        if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
          await Parse.User.logOut();
          return await this.init();
        }
      }

      console.error(
        "Error at $parse.server.init(): The application will not work as intended."
      );

      console.error(error);
    }
  }

  private async fetchUser(): Promise<UserInterface | undefined> {
    try {
      const user = await Parse.User.currentAsync();

      if (user && navigator.onLine) {
        await user.fetchWithInclude(["tenant"]);
      }

      if (user) {
        return {
          id: user.id,
          name: user.get("name") as string,
          email: user.getEmail(),
          username: user.getUsername(),
          session: user.getSessionToken(),
          tenant: user.get("tenant")?.id || undefined,
        };
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(error);

      return undefined;
    }
  }
}
