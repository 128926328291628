import dayjs from "dayjs";
import { QuartzObject } from "../types/CronObject";

export function yearCheck(
  value: QuartzObject,
  manipulatedDate: dayjs.Dayjs
): boolean {
  const yeararr: number[] = value.year
    .split(value.DELIMITER_YR)
    .map((entry) => parseInt(entry));
  const yearisincluded = yeararr.includes(manipulatedDate.year());

  //Check year field
  if (value.year === "*") {
    return true;
  } else {
    if (typeof value.DELIMITER_YR !== "undefined") {
      //Year is start and interval
      if (
        value.DELIMITER_YR === "/" &&
        (manipulatedDate.get("year") - yeararr[0]) % yeararr[1] === 0
      ) {
        return true;
      }

      //Year is comma separated list
      if (value.DELIMITER_YR === "," && yearisincluded) {
        return true;
      }

      //Year is range
      if (
        value.DELIMITER_YR === "-" &&
        manipulatedDate.year() > yeararr[0] &&
        manipulatedDate.year() < yeararr[1]
      ) {
        return true;
      }
    } else {
      if (parseInt(value.year) === manipulatedDate.year()) {
        return true;
      }
    }
  }
  return false;
}
