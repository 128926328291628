import {
  useFeedback,
  useOpenDashServices,
  useTranslation,
} from "@opendash/core";
import { useDashboards } from "@opendash/plugin-monitoring";
import { CustomParseFieldProps } from "@opendash/plugin-parse/dist/types";
import { Button, Transfer } from "antd";
import Modal from "antd/lib/modal/Modal";
import Parse from "parse";
import React, { useEffect, useState } from "react";
export default (props: CustomParseFieldProps) => {
  const [initialDashboards, setInitialDashboards] = useState<string[]>([]);
  const allDashboards = useDashboards();
  const { message } = useFeedback();
  const { SourceService } = useOpenDashServices();
  const t = useTranslation();
  const sources = SourceService.getAll();
  const DashboardClass = Parse.Object.extend("OD3_Dashboard");
  const [selected, setSelected] = useState<{ key: string; title: string }[]>(
    []
  );

  const selectable = allDashboards
    .filter((dashboard) => {
      return !!sources.find((source) => source.id === dashboard.source);
    })
    .map((dashboard) => {
      return {
        key: dashboard.id,
        title:
          (sources.find((source) => source.id === dashboard.source).name ||
            "") +
          " - " +
          dashboard.name,
      };
    })
    .sort((a, b) => {
      return a.title.localeCompare(b.title);
    });

  const [open, setOpen] = useState(false);

  const init = async () => {
    if (!props.value) return;
    const res = await (props.value as Parse.Relation)
      .query()
      .limit(99999)
      .find();
    setInitialDashboards(res.map((pDashboard) => pDashboard.id));

    setSelected(
      res
        .map((dashboard) => dashboard.toJSON())
        .map((dashboard) => {
          return {
            key: dashboard.objectId,
            title:
              (sources.find((source) => source.id === dashboard.source.objectId)
                .name || "") +
              " - " +
              dashboard.name,
          };
        })
        .sort((a, b) => {
          return a.title.localeCompare(b.title);
        })
    );
  };
  useEffect(() => {
    if (props.parseObject) {
      init();
    }
  }, [props.parseObject]);

  return (
    <>
      <Button
        type="default"
        style={{ width: "100%" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {initialDashboards.length + " Dashboard(s)"}
      </Button>
      <Modal
        title={t("opendash:dashboards.management_title")}
        width={"75%"}
        open={open}
        onCancel={() => {
          setSelected(selected);
          setOpen(false);
        }}
        onOk={async () => {
          for (const obj of initialDashboards) {
            if (!selected.find((selectedItem) => obj === selectedItem.key)) {
              (props.value as Parse.Relation).remove(
                DashboardClass.createWithoutData(obj)
              );
            }
          }

          for (const obj of selected) {
            (props.value as Parse.Relation).add(
              DashboardClass.createWithoutData(obj.key)
            );
          }

          try {
            await props.parseObject.save();
            setOpen(false);
          } catch (error) {
            message.error(t("opendash:monitoring.widgets.error.default"));
          }
        }}
      >
        <Transfer
          listStyle={{
            width: "75%",
          }}
          targetKeys={selected.map((item) => item.key)}
          rowKey={(obj) => obj.key}
          onChange={(list) => {
            setSelected(
              selectable.filter((dashboard) => {
                return list.indexOf(dashboard.key) !== -1;
              })
            );
          }}
          dataSource={selectable}
          render={(item) => item.title}
        ></Transfer>
      </Modal>
    </>
  );
};

//export default SensorConfiguration;
