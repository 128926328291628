import {
  $framework,
  AppInterface,
  BaseService,
  equals,
  ServicesInterface,
  SourceIdentifierInterface,
  Store,
  stringToColor,
} from "@opendash/core";
import {
  ComplexDataType,
  evaluateSearchString,
  search,
} from "@opendash/core/dist/helper/search";
import produce from "immer";
import {
  DataAdapterInterface,
  DataFetchingFilterInterface,
  DataFetchingOptionsInterface,
  DataItemDimensionIdentifierInterface,
  DataItemDimensionValueInterface,
  DataItemIdentifierInterface,
  DataItemInterface,
  DataItemValueInterface,
  evaluateDataFetchingOptions,
  TimeseriesPluginConfig,
} from "..";

import { DataAdapterContext } from "./DataAdapterContext";

type SubscriptionCallback = () => void;
type SubCancelCB = () => void;

type StateInterface = {
  overwriteFetchingOptions: DataFetchingOptionsInterface | undefined;
};

export class DataService extends BaseService<StateInterface> {
  private app: AppInterface;
  private services: ServicesInterface;
  private adapter: DataAdapterInterface;
  private context: DataAdapterContext;

  private itemStore = new Map<string, DataItemInterface>();
  private itemSubscriptions = new Map<string, Set<SubscriptionCallback>>();
  private itemListSubscriptions: Set<SubscriptionCallback> = new Set();
  private valueStore = new Map<string, DataItemValueInterface>();
  private valueSubscriptions = new Map<string, Set<SubscriptionCallback>>();

  private sourceNames: Record<string, string> = {};
  private itemNameOverwrites: Record<string, Record<string, string>> = {};

  private itemNameCache: Record<string, string> = {};
  private itemNameDimensionCache: Record<string, string> = {};
  private itemNameTaken: Record<string, DataItemInterface> = {};

  public readonly filterRuleTypes = [
    // {
    //   value: "string_change",
    //   label: "opendash:data.trigger.string_change",
    // },
    {
      value: "string_equals",
      label: "opendash:data.trigger.string_equals",
    },
    {
      value: "string_equals_not",
      label: "opendash:data.trigger.string_equals_not",
    },
    {
      value: "string_includes",
      label: "opendash:data.trigger.string_includes",
    },
    {
      value: "string_includes_not",
      label: "opendash:data.trigger.string_includes_not",
    },
    {
      value: "string_starts_with",
      label: "opendash:data.trigger.string_starts_with",
    },
    {
      value: "string_starts_with_not",
      label: "opendash:data.trigger.string_starts_with_not",
    },
    {
      value: "string_ends_with",
      label: "opendash:data.trigger.string_ends_with",
    },
    {
      value: "string_ends_with_not",
      label: "opendash:data.trigger.string_ends_with_not",
    },
    {
      value: "string_equals_ref",
      label: "opendash:data.trigger.string_equals_ref",
    },
    {
      value: "string_equals_not_ref",
      label: "opendash:data.trigger.string_equals_not_ref",
    },
    {
      value: "string_includes_ref",
      label: "opendash:data.trigger.string_includes_ref",
    },
    {
      value: "string_includes_not_ref",
      label: "opendash:data.trigger.string_includes_not_ref",
    },
    {
      value: "string_starts_with_ref",
      label: "opendash:data.trigger.string_starts_with_ref",
    },
    {
      value: "string_starts_with_not_ref",
      label: "opendash:data.trigger.string_starts_with_not_ref",
    },
    {
      value: "string_ends_with_ref",
      label: "opendash:data.trigger.string_ends_with_ref",
    },
    {
      value: "string_ends_with_not_ref",
      label: "opendash:data.trigger.string_ends_with_not_ref",
    },
    // {
    //   value: "boolean_change",
    //   label: "opendash:data.trigger.boolean_change",
    // },
    {
      value: "boolean_true",
      label: "opendash:data.trigger.boolean_true",
    },
    {
      value: "boolean_false",
      label: "opendash:data.trigger.boolean_false",
    },
    {
      value: "boolean_equals_ref",
      label: "opendash:data.trigger.boolean_equals_ref",
    },
    {
      value: "boolean_equals_not_ref",
      label: "opendash:data.trigger.boolean_equals_not_ref",
    },
    // {
    //   value: "boolean_rising_edge",
    //   label: "opendash:data.trigger.boolean_rising_edge",
    // },
    // {
    //   value: "boolean_falling_edge",
    //   label: "opendash:data.trigger.boolean_falling_edge",
    // },
    // {
    //   value: "number_change",
    //   label: "opendash:data.trigger.number_change",
    // },
    {
      value: "number_equals",
      label: "opendash:data.trigger.number_equals",
    },
    {
      value: "number_equals_not",
      label: "opendash:data.trigger.number_equals_not",
    },
    {
      value: "number_gt",
      label: "opendash:data.trigger.number_gt",
    },
    {
      value: "number_lt",
      label: "opendash:data.trigger.number_lt",
    },
    {
      value: "number_in_range",
      label: "opendash:data.trigger.number_in_range",
    },
    {
      value: "number_out_of_range",
      label: "opendash:data.trigger.number_out_of_range",
    },
    {
      value: "number_equals_ref",
      label: "opendash:data.trigger.number_equals_ref",
    },
    {
      value: "number_equals_not_ref",
      label: "opendash:data.trigger.number_equals_not_ref",
    },
    {
      value: "number_gt_ref",
      label: "opendash:data.trigger.number_gt_ref",
    },
    {
      value: "number_lt_ref",
      label: "opendash:data.trigger.number_lt_ref",
    },
  ];

  constructor() {
    super({
      initialState: {
        overwriteFetchingOptions: undefined,
      },
    });
  }

  async init(config: TimeseriesPluginConfig) {
    this.adapter = config.adapter;
    this.context = new DataAdapterContext(this);

    this.initAdapter(this.adapter, this.context);

    const updateSourceOnChange = () => {
      const { SourceService, UserStorageService } = $framework.services;

      if (!SourceService.isLoading()) {
        const currentSource = SourceService.getCurrent();
        const childSources = SourceService.getDescendents(currentSource);

        this.sourceNames = {};

        for (const s of [currentSource, ...childSources]) {
          this.itemNameOverwrites[s.tag] =
            UserStorageService._getSync(`opendash/data/names/${s.tag}`) || {};

          this.sourceNames[s.tag] = s.name;
        }
      }
    };

    updateSourceOnChange();

    $framework.services.SourceService.subscribe(() => {
      updateSourceOnChange();
    });
  }

  private notifyDataSubscribers(
    subscribers: Set<SubscriptionCallback>,
    clear: boolean = false
  ): void {
    const overwriteOpts = (this.store as Store<StateInterface>).getState()
      .overwriteFetchingOptions;
    const staticOverwrite = overwriteOpts && !overwriteOpts.live;

    if (staticOverwrite) return;

    subscribers.forEach((callback) => {
      try {
        callback();
      } catch (error) {
        console.error("Error in subscription:", error);
      }
    });

    if (clear) {
      subscribers.clear();
    }
  }

  public subscribeItemList(callback: SubscriptionCallback): SubCancelCB {
    this.itemListSubscriptions.add(callback);

    return () => {
      this.itemListSubscriptions.delete(callback);
    };
  }

  public subscribeItem(
    item: DataItemInterface,
    callback: SubscriptionCallback
  ): SubCancelCB {
    const key = this.keyForItem(item);

    if (!this.itemSubscriptions.has(key)) {
      this.itemSubscriptions.set(key, new Set());
    }

    this.itemSubscriptions.get(key).add(callback);

    return () => {
      this.itemSubscriptions.get(key).delete(callback);
    };
  }

  public subscribeValue(
    item: DataItemInterface,
    callback: SubscriptionCallback
  ): SubCancelCB {
    const key = this.keyForItem(item);

    if (!this.valueSubscriptions.has(key)) {
      this.valueSubscriptions.set(key, new Set());
    }

    this.valueSubscriptions.get(key).add(callback);

    return () => {
      this.valueSubscriptions.get(key).delete(callback);
    };
  }

  public async get(source: string, id: string): Promise<DataItemInterface> {
    return this._getOrThrowSync(source, id);
  }

  public _getOrThrowSync(source: string, id: string): DataItemInterface {
    const item = this.itemStore.get(this.keyForIdentifier([source, id]));

    if (!item) {
      throw new Error(`Item with id "${id}" not found`);
    }

    return item;
  }

  public _getOrUndefinedSync(
    source: string,
    id: string
  ): DataItemInterface | undefined {
    return this.itemStore.get(this.keyForIdentifier([source, id]));
  }

  public async getValue(
    item: DataItemInterface
  ): Promise<DataItemValueInterface> {
    return this._getValueOrThrowSync(item);
  }

  public _getValueOrThrowSync(item: DataItemInterface): DataItemValueInterface {
    return this.valueStore.get(this.keyForItem(item));
  }

  public async list(): Promise<DataItemInterface[]> {
    return this._listOrThrowSync();
  }

  public _listOrThrowSync(): DataItemInterface[] {
    return Array.from(this.itemStore.values());
  }

  public async setItem(item: DataItemInterface): Promise<void> {
    const key = this.keyForItem(item);

    if (!this.itemSubscriptions.has(key)) {
      this.itemSubscriptions.set(key, new Set());
    }

    if (!this.itemStore.has(key) || !equals(this.itemStore.get(key), item)) {
      this.itemStore.set(key, item);
      this.createItemNameCache(item);

      this.notifyDataSubscribers(this.itemListSubscriptions);
      this.notifyDataSubscribers(this.itemSubscriptions.get(key));
    }
  }

  public async setItems(items: DataItemInterface[]): Promise<void> {
    let hasChanges = false;

    for (const item of items) {
      const key = this.keyForItem(item);

      if (!this.itemSubscriptions.has(key)) {
        this.itemSubscriptions.set(key, new Set());
      }

      if (!this.itemStore.has(key) || !equals(this.itemStore.get(key), item)) {
        hasChanges = true;

        this.itemStore.set(key, item);
        this.createItemNameCache(item);

        this.notifyDataSubscribers(this.itemSubscriptions.get(key));
      }
    }

    if (hasChanges) {
      this.notifyDataSubscribers(this.itemListSubscriptions);
    }
  }

  public async setValue(
    item: DataItemInterface,
    value: DataItemValueInterface
  ): Promise<void> {
    const key = this.keyForItem(item);

    if (!this.valueSubscriptions.has(key)) {
      this.valueSubscriptions.set(key, new Set());
    }

    if (!this.valueStore.has(key) || !equals(this.valueStore.get(key), value)) {
      this.valueStore.set(key, value);

      this.notifyDataSubscribers(this.valueSubscriptions.get(key));
    }
  }

  public async removeItem(item: DataItemInterface): Promise<void> {
    const key = this.keyForItem(item);

    this.itemStore.delete(key);
    this.valueStore.delete(key);

    this.notifyDataSubscribers(this.itemListSubscriptions);
    this.notifyDataSubscribers(this.itemSubscriptions.get(key));
    this.notifyDataSubscribers(this.valueSubscriptions.get(key));
  }

  public async clear() {
    this.setLoading(true);

    // clear stores
    this.itemStore.clear();
    this.valueStore.clear();

    this.notifyDataSubscribers(this.itemListSubscriptions);

    Array.from(this.itemSubscriptions.keys()).forEach((key) => {
      this.notifyDataSubscribers(this.itemSubscriptions.get(key));
    });

    Array.from(this.valueSubscriptions.keys()).forEach((key) => {
      this.notifyDataSubscribers(this.valueSubscriptions.get(key));
    });
  }
  public evaluateDataFetchingOptions(
    options: DataFetchingOptionsInterface,
    item: DataItemInterface
  ) {
    const key = this.keyForItem(item);
    return evaluateDataFetchingOptions(options, key);
  }

  public async fetchValues(
    item: DataItemInterface,
    options: DataFetchingOptionsInterface
  ): Promise<DataItemValueInterface[]> {
    await this.wait();

    if (options.live && !options.historyType) {
      const itemValue = this._getValueOrThrowSync(item);

      return [itemValue];
    }

    if (!options.historyType) {
      return [];
    }

    const key = this.keyForItem(item);
    const evaluatedOptions = evaluateDataFetchingOptions(options, key);

    return await this.adapter
      .fetchValues(item, evaluatedOptions)
      .then((values) =>
        applyDataFetchingFilter(values, evaluatedOptions.filter)
      );
  }

  public async fetchValuesMultiItem(
    items: DataItemInterface[],
    options: DataFetchingOptionsInterface
  ): Promise<[DataItemInterface, DataItemValueInterface[]][]> {
    await this.wait();

    if (options.live && !options.historyType) {
      return items.map((item) => {
        const itemValue = this._getValueOrThrowSync(item);

        return [item, [itemValue]] as [
          DataItemInterface,
          DataItemValueInterface[],
        ];
      });
    }

    const historyRequests = items.map((item) =>
      this.fetchValues(item, options)
    );

    const histories = await Promise.all(historyRequests);

    const resultEntries = items.map(
      (item, i) =>
        [item, histories[i]] as [DataItemInterface, DataItemValueInterface[]]
    );

    return resultEntries;
  }

  public fetchValuesMultiItemLive(
    items: DataItemInterface[],
    options: DataFetchingOptionsInterface,
    callback: (value: [DataItemInterface, DataItemValueInterface[]][]) => void,
    onLiveValue?: (
      item: DataItemInterface,
      value: DataItemValueInterface,
      allItemsIndex: number,
      allItems: DataItemInterface[]
    ) => void
  ): SubCancelCB {
    let canceled = false;
    const cancleCallbacks = new Set<SubCancelCB>();

    this.fetchValuesMultiItem(items, options).then((fetchedValueHistory) => {
      let currentValueHistory = fetchedValueHistory;

      callback(currentValueHistory);

      if (options.live) {
        items.forEach((item, index, allItems) => {
          cancleCallbacks.add(
            this.subscribeValue(item, () => {
              const currentValue = this._getValueOrThrowSync(item);

              if (onLiveValue) {
                onLiveValue(item, currentValue, index, allItems);
              } else {
                currentValueHistory = produce(currentValueHistory, (draft) => {
                  for (const [draftItem, draftValues] of draft) {
                    if (
                      draftItem.source === item.source &&
                      draftItem.id === item.id
                    ) {
                      draftValues.push({
                        date: currentValue.date,
                        value: currentValue.value,
                      });

                      if (options.limit && options.limit < draftValues.length) {
                        draftValues.splice(
                          0,
                          draftValues.length - options.limit
                        );
                      }

                      break;
                    }
                  }
                });

                callback(currentValueHistory);
              }
            })
          );
        });
      }
    });

    return () => {
      canceled = true;
      cancleCallbacks.forEach((cb) => {
        cb();
      });
    };
  }

  public async fetchDimensionValues(
    item: DataItemInterface,
    dimension: number,
    options: DataFetchingOptionsInterface
  ): Promise<DataItemDimensionValueInterface[]> {
    await this.wait();

    if (options.live && !options.historyType) {
      const itemValue = this._getValueOrThrowSync(item);
      const dimensionValue: DataItemDimensionValueInterface = {
        date: itemValue.date,
        value: itemValue.value[dimension],
      };

      return [dimensionValue];
    }

    if (!options.historyType) {
      return [];
    }

    const key = this.keyForItem(item, dimension);

    const evaluatedOptions = evaluateDataFetchingOptions(options, key);
    return await this.adapter
      .fetchDimensionValues(item, dimension, evaluatedOptions)
      .then((values) =>
        applyDataFetchingDimensionFilter(values, evaluatedOptions.filter)
      );
  }

  public async fetchAggregationPipe(
    options: DataFetchingOptionsInterface
  ): Promise<[DataItemInterface, DataItemValueInterface[]]> {
    if (!(options.aggregation && options.aggregationPipe)) {
      throw new Error("Aggregation Pipe needs to be provided");
    }
    const evaluatedOptions = evaluateDataFetchingOptions(options, "none");
    return this.adapter.aggregate(evaluatedOptions.aggregationPipe);
  }

  public async fetchDimensionValuesMultiItem(
    items: [DataItemInterface, number][],
    options: DataFetchingOptionsInterface
  ): Promise<[DataItemInterface, number, DataItemDimensionValueInterface[]][]> {
    await this.wait();

    if (options.live && !options.historyType) {
      return items.map(([item, dimension], i) => {
        const itemValue = this._getValueOrThrowSync(item);
        const dimensionValue: DataItemDimensionValueInterface = {
          date: itemValue.date,
          value: itemValue.value[dimension],
        };

        return [item, dimension, [dimensionValue]] as [
          DataItemInterface,
          number,
          DataItemDimensionValueInterface[],
        ];
      });
    }

    const historyRequests = items.map(([item, dimension]) =>
      this.fetchDimensionValues(item, dimension, options)
    );

    const histories = await Promise.all(historyRequests);

    const resultEntries = items.map(
      ([item, dimension], i) =>
        [item, dimension, histories[i]] as [
          DataItemInterface,
          number,
          DataItemDimensionValueInterface[],
        ]
    );

    return resultEntries;
  }

  public fetchDimensionValuesMultiItemLive(
    items: [DataItemInterface, number][],
    options: DataFetchingOptionsInterface,
    callback: (
      value: [DataItemInterface, number, DataItemDimensionValueInterface[]][]
    ) => void,
    onLiveValue?: (
      item: DataItemInterface,
      dimension: number,
      value: DataItemDimensionValueInterface,
      allItemsIndex: number,
      allItems: [DataItemInterface, number][]
    ) => void
  ): SubCancelCB {
    let canceled = false;
    const subscriber = new Set<SubCancelCB>();

    this.fetchDimensionValuesMultiItem(items, options).then(
      (fetchedValueHistory) => {
        let currentValueHistory = fetchedValueHistory;

        callback(currentValueHistory);

        if (options.live) {
          items.forEach(([item, dimension], index, allItems) => {
            subscriber.add(
              this.subscribeValue(item, () => {
                const currentValue = this._getValueOrThrowSync(item);

                if (onLiveValue) {
                  onLiveValue(
                    item,
                    dimension,
                    {
                      date: currentValue.date,
                      value: currentValue.value[dimension],
                    },
                    index,
                    allItems
                  );
                } else {
                  currentValueHistory = produce(
                    currentValueHistory,
                    (draft) => {
                      for (const [
                        draftItem,
                        draftDimension,
                        draftValues,
                      ] of draft) {
                        if (
                          draftItem.source === item.source &&
                          draftItem.id === item.id &&
                          draftDimension === dimension
                        ) {
                          draftValues.push({
                            date: currentValue.date,
                            value: currentValue.value[dimension],
                          });

                          if (
                            options.limit &&
                            options.limit < draftValues.length
                          ) {
                            draftValues.splice(
                              0,
                              draftValues.length - options.limit
                            );
                          }

                          break;
                        }
                      }
                    }
                  );

                  callback(currentValueHistory);
                }
              })
            );
          });
        }
      }
    );

    return () => {
      canceled = true;
      subscriber.forEach((cb) => {
        cb();
      });
    };
  }

  private createItemNameCache(item: DataItemInterface, x: boolean = false) {
    const key = this.getItemKey(item);

    const source = item.source;
    const id = item.id;

    let name = "";
    let prefix = "";

    // item = this._getOrThrowSync(source, id);

    const overwrites = this.itemNameOverwrites[source];

    if (overwrites) {
      name = overwrites[key] || item.name;
    } else {
      name = item.name;
    }

    const taken = this.itemNameTaken[name];

    if (taken || x) {
      if (!x) {
        this.createItemNameCache(taken, true);
      }

      const sourceName = this.sourceNames[source];

      if (sourceName) {
        prefix = `${sourceName} - `;
      }
    }

    const fullname = prefix + name;

    this.itemNameCache[key] = fullname;
    this.itemNameTaken[name] = item;

    for (let index = 0; index < item.valueTypes.length; index++) {
      const valueType = item.valueTypes[index];

      const dimensionKey = this.getItemKey(item, index);

      const dimName = overwrites?.[dimensionKey] || valueType.name;

      this.itemNameCache[dimensionKey] = `${fullname} ${dimName}`;
      this.itemNameDimensionCache[dimensionKey] = dimName;
    }
  }

  public getItemName(
    item: DataItemInterface,
    dimension?: number,
    dimensionOnly: boolean = false
  ): string {
    const key = Number.isInteger(dimension)
      ? this.getItemKey(item, dimension)
      : this.getItemKey(item);

    let name = dimensionOnly
      ? this.itemNameDimensionCache[key]
      : this.itemNameCache[key];

    if (!name) {
      this.createItemNameCache(item);

      name = dimensionOnly
        ? this.itemNameDimensionCache[key]
        : this.itemNameCache[key];

      if (!name) {
        name = "NAME MISSING";
      }
    }

    return name;
  }

  public async setItemName(item: DataItemInterface, name: string) {
    await this.setItemDimensionName(item, undefined, name);
  }

  public async setItemDimensionName(
    item: DataItemInterface,
    dimension,
    name: string
  ) {
    const { UserStorageService } = $framework.services;

    const storageKey = `opendash/data/names/${item.source}`;
    const itemKey = JSON.stringify(
      Number.isInteger(dimension) ? [item.id, dimension] : [item.id]
    );

    const overwrites = UserStorageService._getSync(storageKey) || {};

    const newOverwrites = produce(overwrites, (draft) => {
      draft[itemKey] = name;
    });

    await UserStorageService.set(storageKey, newOverwrites);
  }

  public async update(item: DataItemInterface): Promise<void> {
    return await this.adapter.update(item);
  }

  /**
   * Merges the data using fill strategy. This means that the data is filled with the last known value for each timestamp and data source respectively.
   *
   * @param values - An array of arrays containing DataItemDimensionValueInterface objects.
   * @returns An array of objects with date and values properties.
   */
  public mergeDataUsingFill(
    values: DataItemDimensionValueInterface[][],
    singleFill?: number
  ) {
    let candidates;

    if (singleFill === 0 || singleFill) {
      candidates = values[singleFill].map((e) => e.date as number);
    } else {
      candidates = values.flatMap((valuesEntry) =>
        valuesEntry.map((entry) => entry.date as number)
      );
    }

    const allTs = Array.from(new Set(candidates)) as number[];
    allTs.sort((a, b) => a - b);

    const result = allTs
      .map((ts, tsIdx) => {
        return {
          date: ts as number,
          values: [
            ...values.map((valueSet, vIdx) => {
              const index = this.findLastValueBefore(valueSet, null, null, ts);
              return valueSet[index];
            }),
          ],
        };
      })
      .map((entry, idx, allValues) => {
        for (const innerValidx in entry.values) {
          const innerVal = entry.values[innerValidx];
          if (!innerVal) {
            const lastValue = allValues[idx - 1]?.values[innerValidx];
            if (lastValue) {
              entry.values[innerValidx] = lastValue;
            }
          }
        }
        return entry;
      });
    return result;
  }
  public findLastValueBefore(
    values: DataItemValueInterface[],
    start: number,
    end: number,
    ts: number
  ) {
    const i = 0;
    if (values.length === 0)
      throw new Error("Values must contain at least 1 element");

    if (start == null) start = 0;
    if (end == null) end = values.length - 1;
    const midIndex = Math.floor((end + start) / 2);
    if (!values[midIndex])
      throw new Error("Start/End Interval is not applicable to array length");
    if (values[midIndex].date === ts) return midIndex;

    if (values[start].date > ts) {
      return -1;
    }
    if (values[end].date <= ts) return end;

    if (
      values[start].date === ts ||
      (start + 1 >= midIndex && values[start].date <= ts)
    ) {
      if (midIndex + 1 < values.length) {
        if (values[midIndex + 1].date <= ts) {
          return midIndex + 1;
        }
      }
      return values[midIndex].date <= ts ? midIndex : start;
    }

    if (values[midIndex].date > ts) {
      return this.findLastValueBefore(values, start, midIndex, ts);
    } else {
      return this.findLastValueBefore(values, midIndex + 1, end, ts);
    }
  }

  public filterAndKeepChangedValues(values: DataItemValueInterface[]) {
    const result = values.filter((value, index, array) => {
      if (index === 0) return true;
      let equals = true;
      for (const dimIndex in value.value) {
        if (value.value[dimIndex] !== array[index - 1].value[dimIndex]) {
          equals = false;
          break;
        }
      }
      return !equals;
    });
    return result;
  }

  public getItemColor(item: DataItemInterface, dimension: number = 0): string {
    if (item.meta.color) {
      return item.meta.color;
    }

    return stringToColor(item.id + "-" + dimension);
  }

  public searchItems(
    searchString: string,
    items: DataItemInterface[]
  ): DataItemInterface[] {
    return search(searchString, items, (item) =>
      this._searchCreateItemIndex(item)
    );
  }

  public searchItemDimensions(
    searchString: string,
    items: [DataItemInterface, number][]
  ): [DataItemInterface, number][] {
    return search(searchString, items, ([item, dimension]) =>
      this._searchCreateItemIndex(item, dimension)
    );
  }

  public evaluateSearchForItem(
    searchString: string,
    item: DataItemInterface,
    dimension?: number
  ): boolean {
    if (item.meta.color) {
      return item.meta.color;
    }

    const data = this._searchCreateItemIndex(item, dimension);

    return evaluateSearchString(searchString, data);
  }

  public _searchCreateItemIndex(
    item: DataItemInterface,
    dimension?: number
  ): ComplexDataType {
    if (item.meta.color) {
      return item.meta.color;
    }

    const { SourceService } = $framework.services;

    const source = SourceService.getAll().find(
      (source) => source.tag === item.source
    );

    if (dimension !== undefined) {
      return {
        text: this.getItemName(item, dimension),
        keys: {
          source: [item.source, source.name],
          id: item.id,
          type: item.valueTypes[dimension]?.type,
          unit: item.valueTypes[dimension]?.unit,
        },
      };
    }

    return {
      text: this.getItemName(item),
      keys: {
        source: [item.source, source.name],
        id: item.id,
      },
    };
  }

  public keyForIdentifier(
    item:
      | SourceIdentifierInterface
      | DataItemIdentifierInterface
      | DataItemDimensionIdentifierInterface
  ): string {
    if (Array.isArray(item)) {
      if (item.length === 2) {
        return JSON.stringify(item);
      }

      if (item.length === 3) {
        if (Number.isInteger(item[2])) {
          return JSON.stringify([item[0], item[1], item[2]]);
        } else {
          return JSON.stringify([item[0], item[1]]);
        }
      }

      // if (item.length === 4) {
      //   return JSON.stringify([item[0], item[1], item[2]]);
      // }
    }

    if (typeof item === "string") {
      return;
    }

    return "invalid_identifier";
  }

  public keyForItem(item: DataItemInterface, dimension?: number): string {
    if (Number.isInteger(dimension)) {
      return JSON.stringify([item.source, item.id, dimension]);
    }

    return JSON.stringify([item.source, item.id]);
  }

  public getItemKey(item: DataItemInterface, dimension?: number): string {
    return this.keyForItem(item, dimension);
  }
}

function applyDataFetchingFilter(
  values: DataItemValueInterface[],
  filter: DataFetchingFilterInterface
): DataItemValueInterface[] {
  if (!filter) {
    return values;
  }

  const result = values.filter((v) => filterRecursiv(filter, v));

  return result;
}

function applyDataFetchingDimensionFilter(
  values: DataItemDimensionValueInterface[],
  filter: DataFetchingFilterInterface
): DataItemValueInterface[] {
  if (!filter) {
    return values;
  }

  const result = values.filter((v) => filterRecursiv(filter, v));

  return result;
}

function filterRecursiv(
  filter: DataFetchingFilterInterface,
  v: DataItemValueInterface | DataItemDimensionValueInterface,
  refValue: any = undefined
): boolean {
  try {
    if (filter.type === "and") {
      return filter.children.every((childFilter) =>
        filterRecursiv(childFilter, v, refValue)
      );
    }

    if (filter.type === "or") {
      return filter.children.some((childFilter) =>
        filterRecursiv(childFilter, v, refValue)
      );
    }

    if (filter.type === "rule") {
      const value = Array.isArray(v.value)
        ? v.value[filter.dimension]
        : v.value;
      const stringValue = value as string;
      const numberValue = value as number;
      const booleanValue = value as boolean;

      switch (filter.rule.type) {
        case "string_equals":
          return stringValue === filter.rule.string;
        case "string_equals_not":
          return stringValue !== filter.rule.string;
        case "string_includes":
          return stringValue.includes(filter.rule.string);
        case "string_includes_not":
          return !stringValue.includes(filter.rule.string);
        case "string_starts_with":
          return stringValue.startsWith(filter.rule.string);
        case "string_starts_with_not":
          return !stringValue.startsWith(filter.rule.string);
        case "string_ends_with":
          return stringValue.endsWith(filter.rule.string);
        case "string_ends_with_not":
          return !stringValue.endsWith(filter.rule.string);
        case "string_equals_ref":
          return stringValue === (refValue as string);
        case "string_equals_not_ref":
          return stringValue !== (refValue as string);
        case "string_includes_ref":
          return stringValue.includes(refValue as string);
        case "string_includes_not_ref":
          return !stringValue.includes(refValue as string);
        case "string_starts_with_ref":
          return stringValue.startsWith(refValue as string);
        case "string_starts_with_not_ref":
          return !stringValue.startsWith(refValue as string);
        case "string_ends_with_ref":
          return stringValue.endsWith(refValue as string);
        case "string_ends_with_not_ref":
          return !stringValue.endsWith(refValue as string);
        case "boolean_true":
          return booleanValue === true;
        case "boolean_false":
          return booleanValue === false;
        case "boolean_equals_ref":
          return booleanValue === (refValue as boolean);
        case "boolean_equals_not_ref":
          return booleanValue !== (refValue as boolean);
        case "number_equals":
          return numberValue === filter.rule.value;
        case "number_equals_not":
          return numberValue !== filter.rule.value;
        case "number_gt":
          return numberValue > filter.rule.value;
        case "number_lt":
          return numberValue < filter.rule.value;
        case "number_in_range":
          return numberValue < filter.rule.max && numberValue > filter.rule.min;
        case "number_out_of_range":
          return numberValue > filter.rule.max && numberValue < filter.rule.min;
        case "number_equals_ref":
          return numberValue === (refValue as number);
        case "number_equals_not_ref":
          return numberValue !== (refValue as number);
        case "number_gt_ref":
          return numberValue > (refValue as number);
        case "number_lt_ref":
          return numberValue < (refValue as number);
        default:
          throw new Error("Rule not implemented.");
      }
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}
