import { $framework } from "@opendash/core";
import { useTranslation } from "@opendash/i18n";
import { createComponent } from "@opendash/state";
import { Button, Form, Input, Typography } from "antd";
import React from "react";
import { $parse } from "../..";
import { AuthLayout } from "./AuthLayout";

export const AuthPasswordResetRequestRoute = createComponent(
  function AuthPasswordResetRequestRoute() {
    const t = useTranslation();

    if ($parse.auth.loading) return null;

    return (
      <AuthLayout width={400}>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(data) => {
            $parse.auth.requestPasswordReset(data);
          }}
        >
          <Typography.Title
            level={3}
            children={t("parse-admin:auth.password_reset_request_title")}
          />

          <Typography.Paragraph
            children={t("parse-admin:auth.password_reset_request_description")}
          />

          <Form.Item
            name="email"
            label={t("parse-admin:auth.field_email_label")}
            required
            rules={[
              {
                required: true,
                message: t("parse-admin:auth.field_email_validation_required"),
              },
            ]}
          >
            <Input
              placeholder={t("parse-admin:auth.field_email_placeholder")}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              children={t("opendash:ui.submit")}
            />
            <div
              style={{
                height: "20px",
                width: "100%",
              }}
            ></div>
            <Button
              key="login"
              type="primary"
              style={{ width: "100%" }}
              children={t("parse-admin:auth.back_to_login")}
              onClick={() => {
                $framework.router.navigate("/auth/login");
              }}
            />
          </Form.Item>
        </Form>
      </AuthLayout>
    );
  }
);
