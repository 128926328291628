import {
  RouterStatefullHandler,
  RouterStatefullHandlerProps,
  StatefullSyncRoute,
} from "@opendash/router";
import {
  LoaderFunctionArgs,
  Navigate,
  RouteObject,
  createBrowserRouter,
} from "@opendash/router/dist/internal";
import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import {
  RouterErrorHandler,
  RouterLayoutBasic,
  RouterLayoutBasicPadding,
  RouterRootHandler,
} from "..";
import type { FrameworkService } from "./FrameworkService";

type ParamsType = Record<string, string | undefined>;
type NavigateOptions = Partial<{
  replace: boolean;
  backIfPossible: boolean;
  keepQueryParams: boolean;
}>;

export class RouterService {
  private framework: FrameworkService;
  private __router: ReturnType<typeof createBrowserRouter> | null = null;

  private history: { url: string; pathname: string; search: string }[] = [];

  public pathname: string = window.location.pathname;
  public search: string = window.location.search;
  public url: string = this.pathname + this.search;

  public searchParams: ParamsType = getSearchParams(this.search);
  // public params: ParamsType = {};

  constructor(framework: FrameworkService) {
    makeAutoObservable(this);

    this.framework = framework;
  }

  public getURL(): URL {
    return new URL(window.location.href);
  }

  public navigate(url: string | URL, options?: NavigateOptions) {
    if (url instanceof URL) {
      url = url.pathname + url.search;
    }

    if (options?.backIfPossible && this.history.length > 0) {
      if (url === this.history.at(-1)?.url) {
        return void this.back();
      }
    }

    if (options?.keepQueryParams) {
      url += this.search;
    }

    if (options?.replace) {
      this.replaceState(url);
    } else {
      this.pushState(url);
    }
  }

  public isBackAvailable() {
    return this.history.length > 0;
  }

  public back() {
    if (this.isBackAvailable()) {
      this.history.pop();
      this.go(-1);
    }
  }

  public go(delta: number) {
    this.__router?.navigate(delta);
  }

  public pushState(url: string) {
    this.__router?.navigate(url);
  }

  public replaceState(url: string) {
    this.__router?.navigate(url, { replace: true });
  }

  public setSearchParam(
    key: string,
    value: string | undefined,
    options?: NavigateOptions
  ) {
    const params = new URLSearchParams(this.search);

    if (value) {
      params.set(key, value);
    } else {
      params.delete(key);
    }

    const search = params.toString();

    if (search) {
      this.navigate(this.pathname + "?" + search, options);
    } else {
      this.navigate(this.pathname, options);
    }
  }

  public __getRoutes(): ReturnType<typeof createBrowserRouter> {
    if (this.__router) {
      return this.__router;
    }

    const routes = this.framework._internal.routes;

    this.__router = createBrowserRouter([
      {
        path: "/",
        element: React.createElement(RouterRootHandler),
        errorElement: React.createElement(RouterErrorHandler),
        children: [
          {
            errorElement: React.createElement(RouterErrorHandler),
            children: routes.map((route) => {
              const path = route.path;

              let element: React.ReactElement | null = null;

              const RouteLayout = getLayout(route.layout);

              if ("redirectPath" in route) {
                element = React.createElement(Navigate, {
                  to: route.redirectPath,
                  replace: true,
                });
              } else if ("state" in route && "componentSync" in route) {
                element = React.createElement(RouterStatefullHandler, {
                  key: path,
                  RouteState: route.state,
                  RouteComponent: route.componentSync,
                  RouteLayout,
                });
              } else if ("component" in route) {
                element = React.createElement(
                  React.lazy(route.component),
                  route.props || {}
                );
              } else if ("componentSync" in route) {
                element = React.createElement(
                  route.componentSync,
                  route.props || {}
                );
              }

              const loader = async ({
                request,
                params,
              }: LoaderFunctionArgs) => {
                if (!element) {
                  throw new Response("", {
                    status: 404,
                    statusText: "Element Not Found",
                  });
                }

                const authenticated =
                  !!this.framework.services.UserService.isLoggedIn();

                if (route.auth === "authenticated" && !authenticated) {
                  throw new Response("", {
                    status: 401,
                    statusText: "Unauthorized",
                  });
                }

                if (route.auth === "unauthenticated" && authenticated) {
                  return new Response("", {
                    status: 302,
                    headers: {
                      Location: "/",
                    },
                  });
                }

                const hasPermission =
                  !route.permission ||
                  (Array.isArray(route.permission)
                    ? route.permission.every((p) =>
                        this.framework.services.UserService.hasPermission(p)
                      )
                    : this.framework.services.UserService.hasPermission(
                        route.permission
                      ));

                if (!hasPermission) {
                  throw new Response("", {
                    status: 403,
                    statusText: "Permission denied.",
                  });
                }

                return null;
              };

              const childRoute: RouteObject = {
                path,
                element,
                loader,
              };

              return childRoute;
            }),
          },
        ],
      },
    ]);

    this.__router.subscribe((state) => {
      // Params:
      // console.log(state.matches.at(-1)?.params);

      const pathname = state.location.pathname;
      const search = state.location.search;
      const url = pathname + search;

      if (this.url === url) return;

      runInAction(() => {
        this.history.push({
          url: this.url,
          pathname: this.pathname,
          search: this.search,
        });

        this.url = url;
        this.pathname = pathname;
        this.search = search;
        this.searchParams = getSearchParams(search);
      });
    });

    return this.__router;
  }
}

function getSearchParams(search: string): ParamsType {
  return Object.fromEntries(new URLSearchParams(search).entries());
}

function getLayout(
  layout: StatefullSyncRoute["layout"]
): RouterStatefullHandlerProps["RouteLayout"] {
  if (!layout) {
    return null;
  }

  if (typeof layout === "string") {
    if (layout === "basic") {
      return RouterLayoutBasic;
    }

    if (layout === "basic-padding") {
      return RouterLayoutBasicPadding;
    }

    console.warn(
      `[$framework.router] Layout '${layout}' not found. Fallback to no layout.`
    );

    return null;
  }

  return layout as RouterStatefullHandlerProps["RouteLayout"];
}
