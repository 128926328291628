import * as cronjsMatcher from "@datasert/cronjs-matcher";
import {
  AdminLayout,
  useBreadcrumb,
  useSource,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { AdminToolbar, Description } from "@opendash/ui";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import type { FormInstance } from "antd/es/form";
import dayjs from "dayjs";
import produce from "immer";
import * as Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React, { useContext, useState } from "react";

import { StageInterface } from "../admin-sensors/aggregation/AggregationSettingsComponentType";
import {
  OpenWareProvider,
  OpenWareProviderProps,
} from "../common/OpenWareContext";
import { Report } from "../reporting/Report";
const { Search } = Input;
const EditableContext = React.createContext<FormInstance<any> | null>(null);
export const ReportQuery = new Parse.Query(Report);

export default function ReportingScheduleRoute({
  config,
}: OpenWareProviderProps) {
  return (
    <OpenWareProvider config={config}>
      <AdminLayout>
        <ReportingOverview config={config} />
      </AdminLayout>
    </OpenWareProvider>
  );
}

function ReportingOverview(config) {
  const t = useTranslation();
  const [source] = useSource();
  useBreadcrumb("openware:reporting.label");

  const [state, setState] = useState<{
    resultTable: DataType[];
    stillLoading: boolean;
  }>({
    resultTable: [],
    stillLoading: true,
  });
  type ScheduledReport = {
    interval: string;
    timeframe?: number;
    recipents: string[];
    type: string;
    params: {
      mailto?: string;
      template?: string;
      title?: string;
      description?: string;
      items?: { reportid: string; stages: StageInterface[] }[];
      type?: string;
      offset?: number;
    };
  };
  const [newScheduledReport, setNewScheduledReport] = useState<ScheduledReport>(
    {
      interval: "59 59 23 ? * SUN *",
      recipents: [],
      type: "standardReport",
      params: {},
    }
  );
  const [editing, setEditing] = useState("");
  const [editingData, setEditingData] = useState({});
  const [create, setCreate] = useUrlParam("create", false, "json");
  const [searchString, setSearchString] = React.useState("");
  const { result: allReports, reload, loading } = useParseQuery(ReportQuery);

  const handleInputChange = (e, id) => {
    setEditingData((prevData) => ({
      ...prevData,
      [id]: e.target.value,
    }));
  };

  React.useEffect(() => {
    getScheduledReportList();
  }, []);

  const reports = React.useMemo(() => {
    const toReturnFirst = allReports.filter((report) => {
      const language = window.localStorage.getItem("opendash:language")
        ? JSON.parse(window.localStorage.getItem("opendash:language") || '"de"')
        : "de";
      return (
        report.get("language") === language ||
        report.get("language") === undefined ||
        report.get("language") === ""
      );
    });
    const toReturn = toReturnFirst.filter((report) => {
      /*const filterString =
        report.get("title") + report.get("description") + report.get("name");
      return (
        !searchString ||
        filterString.toLowerCase().includes(searchString.toLowerCase())
      );*/
      const filterString = (
        report.get("title") +
        report.get("description") +
        report.get("name")
      ).toLowerCase();

      // Splitte den `searchString` in einzelne Wörter und prüfe jedes Wort separat
      const searchTerms = searchString.toLowerCase().trim().split(/\s+/);

      return searchTerms.every((term) => filterString.includes(term));
    });
    return toReturn;
  }, [allReports, source, searchString]);

  const getScheduledReportList = async () => {
    /* DEMO DATA
    let entry = {} as DataType;
    entry.id = "test.id" + Math.random();
    entry.next = "59 59 23 L * ? *"; //Month
    entry.type = { type: "Energiebericht", file: "xlsx" };
    entry.recipe = ["castelli@openinc.de", "castelli@me.com"];
    entry.user = "castelli@openinc.de";

    setState(
      produce((draft) => {
        draft.resultTable.push(entry);
      })
    );
    */

    const url =
      (config.config.secure ? "https://" : "http://") +
      config.config.host +
      "/api/schedule/report/";
    const data = await fetch(url, {
      headers: {
        "OD-SESSION": Parse.User.current().getSessionToken(),
        "Access-Control-Allow-Origin": "*",
      },
    });
    data.json().then(async (e) => {
      const entryArray = [];

      for (const element of e) {
        const userNameQuery = new Parse.Query(Parse.User).equalTo(
          "objectId",
          element.user
        );
        const userName = await userNameQuery.first();
        const userNameString = userName ? userName.get("name") : "Unknown User";
        console.log(1);
        const entry = {} as DataType;
        entry.id = element._id;
        entry.next = element.interval.params;
        entry.type = {
          type: `${element.options.name} (${element.options.title})`,
          file: element.options.type,
          icon: element.options.icon,
          iconColor: element.options.iconColor,
        };
        console.log(2);
        entry.recipe = element.options.mailto.split(",");
        entry.user = userNameString;
        entry.timeframe = element.options.timeframe || "interval";
        console.log(3);
        entryArray.push(entry);
        console.log(4);
      }

      setState(
        produce((draft) => {
          draft.resultTable = entryArray;
        })
      );
    });

    setState(
      produce((draft) => {
        draft.stillLoading = false;
      })
    );
  };

  interface EditableRowProps {
    index: number;
  }

  const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof DataType;
    record: DataType;
    handleSave: (record: DataType) => void;
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const form = useContext(EditableContext)!;

    let childNode = children;

    if (editable) {
      childNode =
        editing === record.id ? (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            initialValue={editingData[record.id] || record.recipe.join(",")}
          >
            <Input
              type="text"
              value={record.recipe.join(",")}
              onBlur={(e) => handleInputChange(e, record.id)}
            />
          </Form.Item>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{ paddingRight: 24 }}
          >
            {children}
          </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  interface DataType {
    id: string;
    next: string;
    type: {};
    recipe: string[];
    user: string;
    timeframe: "month" | "quarter" | "year" | "interval";
    time: string;
  }

  type EditableTableProps = Parameters<typeof Table>[0];
  type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;
  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: t("openware:reporting.schedule.table.type"),
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (result) => (
        <div>
          {result.icon ? (
            <Icon
              icon={result.icon}
              style={{ color: result.iconColor ?? "#000" }}
            />
          ) : result.file == "xlsx" ? (
            <Icon icon="fa:file-word" style={{ color: "#1B5EBE" }} />
          ) : (
            <Icon icon="fa:file-word" style={{ color: "#1B5EBE" }} />
          )}{" "}
          {result.type}
        </div>
      ),
    },
    {
      title: t("openware:reporting.schedule.table.recipe"),
      dataIndex: "recipe",
      key: "recipe",
      align: "center",
      editable: true,
      render: (result, record) =>
        result.map((value) => {
          return <Tag key={value}>{value}</Tag>;
        }),
    },
    {
      title: t("openware:reporting.schedule.table.user"),
      dataIndex: "user",
      key: "user",
      align: "center",
      responsive: ["md"],
    },
    {
      title: t("openware:reporting.schedule.table.timer"),
      dataIndex: "next",
      key: "time",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (result) => (
        <Tooltip placement="topLeft" title={result}>
          {getStringFromCron(result)}
        </Tooltip>
      ),
    },
    {
      title: t("openware:reporting.schedule.table.timeframe"),
      dataIndex: "timeframe",
      key: "timeframe",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (result) => (
        <Tooltip placement="topLeft" title={getStringFromTimeframe(result)}>
          {getStringFromTimeframe(result)}
        </Tooltip>
      ),
    },
    {
      title: t("openware:reporting.schedule.table.createdat"),
      dataIndex: "next",
      key: "next",
      responsive: ["md"],
      align: "center",
      render: (date) => (
        <>{dayjs(getNextCronTime(date)).format("DD.MM.YYYY @ HH:mm:ss")}</>
      ),
    },
    {
      title: t("openware:reporting.schedule.table.action"),
      align: "center",
      key: "action",
      dataIndex: "id",
      render: (id) => (
        <Space size="middle">
          {editing !== id ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                editReport(id);
              }}
            >
              <Icon icon="fa:pen" />
            </div>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onMouseUp={() => {
                saveEditReport(id);
              }}
            >
              <Icon icon="fa:save" />
            </div>
          )}
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              Modal.confirm({
                title: t("openware:reporting.schedule.delete.title"),
                content: t("openware:reporting.schedule.delete.content"),
                okText: t("openware:reporting.schedule.delete.yes"),
                okType: "danger",
                onOk: () => {
                  delReport(id);
                },
              });
            }}
          >
            <Icon icon="fa:trash" key="delete" style={{ color: "#ff4d4d" }} />
          </div>
        </Space>
      ),
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const getStringFromCron = (cronExpression) => {
    if (cronExpression == "59 59 23 ? * * *") {
      return t("openware:reporting.schedule.cron.day");
    }
    if (cronExpression == "59 59 23 ? * SUN *") {
      return t("openware:reporting.schedule.cron.week");
    }
    if (cronExpression == "59 59 23 L * ? *") {
      return t("openware:reporting.schedule.cron.month");
    }
    if (cronExpression == "59 59 23 L MAR,JUN,SEP,DEC ? *") {
      return t("openware:reporting.schedule.cron.quarter");
    }
    if (cronExpression == "59 59 23 L DEC,JUN ? *") {
      return t("openware:reporting.schedule.cron.halfyear");
    }
    if (cronExpression == "59 59 23 31 DEC ? *") {
      return t("openware:reporting.schedule.cron.year");
    }
    return "";
  };

  const getStringFromTimeframe = (value: string) => {
    switch (value) {
      case "interval": // <Interval>
        return t("openware:reporting.schedule.table.timeframes.interval");
      case "month": // Monat
        return t("openware:reporting.schedule.table.timeframes.month");
      case "quarter": // Quartal
        return t("openware:reporting.schedule.table.timeframes.quarter");
      case "year": // Jahr
        return t("openware:reporting.schedule.table.timeframes.year");
      default:
        return "";
    }
  };

  const getNextCronTime = (cronExpression) => {
    const nextDate = cronjsMatcher.getFutureMatches(cronExpression, {
      hasSeconds: true,
      timezone: "Europe/Berlin",
      formatInTimezone: true,
    });
    return nextDate[0];
  };
  const getNextNextCronTime = (cronExpression) => {
    const nextDate = cronjsMatcher.getFutureMatches(cronExpression, {
      hasSeconds: true,
      timezone: "Europe/Berlin",
      formatInTimezone: true,
    });
    return nextDate[1];
  };

  const addReport = async (params) => {
    const url =
      (config.config.secure ? "https://" : "http://") +
      config.config.host +
      "/api/schedule/report/";
    const data = await fetch(url, {
      method: "POST",
      headers: {
        "OD-SESSION": Parse.User.current().getSessionToken(),
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((e) => {
      getScheduledReportList();
    });
  };

  const delReport = async (id) => {
    const url =
      (config.config.secure ? "https://" : "http://") +
      config.config.host +
      "/api/schedule/report/" +
      id;
    const data = await fetch(url, {
      method: "DELETE",
      headers: {
        "OD-SESSION": Parse.User.current().getSessionToken(),
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    data.json().then((e) => {
      getScheduledReportList();
    });
  };

  const editReport = (id) => {
    if (editing === id) {
      setEditing("");
    } else {
      setEditing(id);
    }
  };

  const saveEditReport = (id) => {
    const updatedData = editingData[id];
    setState(
      produce((draft) => {
        draft.resultTable = updateStringById(
          state.resultTable,
          id,
          updatedData
        );
      })
    );
    setEditing("");
  };

  function updateStringById(arr, id, newString) {
    if (newString) {
      return arr.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            recipe: newString ? newString.split(",") : [newString],
          };
        }
        return item;
      });
    } else {
      return arr;
    }
  }

  function getStartOfPeriod(date, period) {
    const inputDate = dayjs(date);

    switch (period) {
      case 2: // Monat
        return inputDate.startOf("month").valueOf();
      case 3: // Quartal
        const quarterStartMonth = Math.floor(inputDate.month() / 3) * 3;
        return inputDate.month(quarterStartMonth).startOf("month").valueOf();
      case 4: // Jahr
        return inputDate.startOf("year").valueOf();
      default:
        throw new Error(
          "Ungültiger Periodenparameter. Unterstützte Werte sind 2, 3 und 4."
        );
    }
  }

  return (
    <>
      <AdminToolbar
        title={t("openware:reporting.sidebar.schedule")}
        description={t("openware:reporting.schedule.description")}
        actions={[
          <Button key="create" type="primary" onClick={() => setCreate(true)}>
            {t("openware:reporting.schedule.newbutton")}
          </Button>,
        ]}
      />
      <div
        style={{
          width: "100%",
          background: "white",
          paddingTop: "24px",
        }}
      >
        {" "}
        <Table
          size="middle"
          style={{ paddingLeft: "24px", paddingRight: "24px" }}
          columns={columns as ColumnTypes}
          dataSource={state.resultTable}
          loading={state.stillLoading}
          components={components}
          rowClassName={() => "editable-row"}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
          }}
        ></Table>
        <Modal
          title={t("openware:reporting.schedule.create.title")}
          open={create}
          onCancel={() => {
            setCreate(false);
            setNewScheduledReport(
              produce((draft) => {
                draft.interval = "59 59 23 ? * SUN *";
                draft.recipents = [];
              })
            );
          }}
          width={800}
          onOk={() => {
            const schedule = {
              params: {
                mailto: newScheduledReport.recipents.toString(),
                timeframe:
                  newScheduledReport.timeframe === 1
                    ? undefined
                    : newScheduledReport.timeframe,
                ...newScheduledReport.params,
              },
              jobname: "id" + new Date().getTime() + Math.random(),
              interval: {
                type: "cron",
                params: newScheduledReport.interval,
              },
            };
            //console.log(schedule);
            addReport(schedule);
            setCreate(false);
          }}
        >
          <Description style={{ textAlign: "left" }}>
            {t("openware:reporting.schedule.create.type.title")}

            <Search
              placeholder={t("opendash:ui.search_placeholder")}
              onSearch={(value) => setSearchString(value)}
              onChange={(e) => setSearchString(e.target.value)}
              style={{ marginBottom: "20px" }}
            />

            <Card title="">
              {/* ARENS ONLY
              <Card.Grid
                style={{
                  width: "33,3333%",
                  textAlign: "left",
                  border:
                    newScheduledReport.type == "standardReport"
                      ? "1px solid #1890ff"
                      : "",
                }}
                key={"standardReport"}
                onClick={() => {
                  setNewScheduledReport(
                    produce((draft) => {
                      draft.type = "standardReport";
                      draft.params = {};
                    })
                  );
                }}
              >
               
                <Card.Meta
                  title={t("openware:reporting.basic.title")}
                  description={""}
                  avatar={
                    <Icon
                      icon="fa:file-word"
                      style={{ fontSize: 35, color: "#1B5EBE" }}
                    />
                  }
                />
                <div style={{ minHeight: "4em", paddingTop: "10px" }}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 3, expandable: false, symbol: ">" }}
                  >
                    {t("openware:reporting.basic.desc")}
                  </Typography.Paragraph>
                </div>
              </Card.Grid>*/}
              {reports.map((report) => {
                return (
                  <Card.Grid
                    style={{
                      width: "33,3333%",
                      textAlign: "left",
                      padding: "15px",
                      paddingTop: "25px",
                      border:
                        newScheduledReport.type == report.id
                          ? "1px solid #1890ff"
                          : "",
                    }}
                    key={report.id}
                    onClick={() => {
                      setNewScheduledReport(
                        produce((draft) => {
                          draft.type = report.id;
                          draft.params = report.toJSON() as unknown;
                          draft.params.template = report.get("template").url();
                          draft.params.offset = 0;
                        })
                      );
                    }}
                  >
                    <Tooltip
                      title={
                        report.get("name") + " (" + report.get("title") + ")"
                      }
                    >
                      {report.get("icon") ? (
                        <Card.Meta
                          title={report.get("name")}
                          description={
                            <div style={{ marginTop: "-8px" }}>
                              {report.get("title")}
                            </div>
                          }
                          avatar={
                            <Icon
                              icon={report.get("icon")}
                              style={{
                                fontSize: 35,
                                color: report.get("iconColor") ?? "#000",
                              }}
                            />
                          }
                        />
                      ) : report.get("type") === "xlsx" ? (
                        <Card.Meta
                          title={report.get("name")}
                          description={
                            <div style={{ marginTop: "-8px" }}>
                              {report.get("title")}
                            </div>
                          }
                          avatar={
                            <Icon
                              icon="fa:file-excel"
                              style={{ fontSize: 35, color: "#008000" }}
                            />
                          }
                        />
                      ) : (
                        <Card.Meta
                          title={report.get("name")}
                          description={
                            <div style={{ marginTop: "-8px" }}>
                              {report.get("title")}
                            </div>
                          }
                          avatar={
                            <Icon
                              icon="fa:file-word"
                              style={{ fontSize: 35, color: "#1B5EBE" }}
                            />
                          }
                        />
                      )}
                      <div style={{ minHeight: "4em", paddingTop: "10px" }}>
                        <Typography.Paragraph
                          ellipsis={{ rows: 3, expandable: false, symbol: ">" }}
                        >
                          {report.get("description")}
                        </Typography.Paragraph>
                      </div>
                    </Tooltip>
                  </Card.Grid>
                );
              })}
            </Card>
          </Description>

          <Divider />

          <Description style={{ textAlign: "left" }}>
            {t("openware:reporting.schedule.create.interval.title")}
            <Select
              value={newScheduledReport.interval}
              onChange={(e) => {
                setNewScheduledReport(
                  produce((draft) => {
                    draft.interval = e;
                  })
                );
              }}
              style={{ width: "100%" }}
              options={[
                {
                  value: "59 59 23 ? * * *",
                  label: t("openware:reporting.schedule.create.interval.day"),
                },
                {
                  value: "59 59 23 ? * SUN *",
                  label: t("openware:reporting.schedule.create.interval.week"),
                },
                {
                  value: "59 59 23 L * ? *",
                  label: t("openware:reporting.schedule.create.interval.month"),
                },
                {
                  value: "59 59 23 L MAR,JUN,SEP,DEC ? *",
                  label: t(
                    "openware:reporting.schedule.create.interval.quarter"
                  ),
                },
                {
                  value: "59 59 23 L DEC,JUN ? *",
                  label: t("openware:reporting.schedule.create.interval.half"),
                },
                {
                  value: "59 59 23 31 DEC ? *",
                  label: t("openware:reporting.schedule.create.interval.year"),
                },
              ]}
            />
            {newScheduledReport.interval && (
              <Description style={{ textAlign: "right", fontSize: "12px" }}>
                <i>
                  {t("openware:reporting.schedule.create.interval.next")}:{" "}
                  {dayjs(getNextCronTime(newScheduledReport.interval)).format(
                    "DD.MM.YYYY @ HH:mm:ss"
                  )}
                </i>
              </Description>
            )}
          </Description>

          <Description style={{ textAlign: "left" }}>
            {t("openware:reporting.schedule.create.timeframe.title")}
            <Select
              value={newScheduledReport.timeframe}
              onChange={(e: number) => {
                setNewScheduledReport(
                  produce((draft) => {
                    if (e == 1) {
                      delete draft.timeframe;
                    } else {
                      draft.timeframe = e;
                    }
                  })
                );
              }}
              style={{ width: "100%" }}
              options={[
                {
                  value: 1,
                  label: t(
                    "openware:reporting.schedule.create.timeframe.frominterval"
                  ),
                },
                {
                  value: 2,
                  label: t(
                    "openware:reporting.schedule.create.timeframe.month"
                  ),
                },
                {
                  value: 3,
                  label: t(
                    "openware:reporting.schedule.create.timeframe.quarter"
                  ),
                },
                {
                  value: 4,
                  label: t("openware:reporting.schedule.create.timeframe.year"),
                },
              ]}
            />
            {newScheduledReport.timeframe && (
              <Description style={{ textAlign: "right", fontSize: "12px" }}>
                <i>
                  {t("openware:reporting.schedule.create.timeframe.example")}:{" "}
                  {newScheduledReport.timeframe == 1
                    ? dayjs(
                        getNextCronTime(newScheduledReport.interval)
                      ).format("DD.MM.YYYY @ HH:mm:ss") +
                      " - " +
                      dayjs(
                        getNextNextCronTime(newScheduledReport.interval)
                      ).format("DD.MM.YYYY @ HH:mm:ss")
                    : dayjs(
                        getStartOfPeriod(
                          dayjs(getNextCronTime(newScheduledReport.interval)),
                          newScheduledReport.timeframe
                        )
                      ).format("DD.MM.YYYY @ HH:mm:ss") +
                      " - " +
                      dayjs(
                        getNextCronTime(newScheduledReport.interval)
                      ).format("DD.MM.YYYY @ HH:mm:ss")}
                </i>
              </Description>
            )}
          </Description>

          <Description style={{ textAlign: "left" }}>
            {t("openware:reporting.schedule.create.recipe.title")}
            <Input
              type="text"
              value={newScheduledReport.recipents}
              onChange={(e) => {
                setNewScheduledReport(
                  produce((draft) => {
                    draft.recipents = e.target.value.toLowerCase().split(",");
                    if (e.target.value == "" || e.target.value.length < 1) {
                      draft.recipents = [];
                    }
                  })
                );
              }}
            ></Input>
            {newScheduledReport.recipents &&
              newScheduledReport.recipents.length > 0 && (
                <Description style={{ textAlign: "right", fontSize: "12px" }}>
                  <i>
                    {newScheduledReport.recipents.map((value) => {
                      return <Tag key={value}>{value.toLowerCase()}</Tag>;
                    })}
                  </i>
                </Description>
              )}
          </Description>
        </Modal>
      </div>
    </>
  );
}
