import React from "react";

import { $parse } from "../state";
import { AnyViewType } from "../types";

import { ParseFilterState } from "../state/ParseFilterState";

import { ViewLayout, onRow } from "./views/ViewLayout";

import { CalendarView } from "./views/CalendarView";
import { CanbanView } from "./views/CanbanView";
import { CardView } from "./views/CardView";
import { ListView } from "./views/ListView";
import { TableView } from "./views/TableView";

type Props = {
  className: string;
  pagination?: number;
  view?: AnyViewType;
  state?: ParseFilterState;
  beforeCreate?: () => Parse.Object | null;
  afterCreate?: (classname: string, objectId: string) => void;
  beforeDelete?: (classname: string, objectId: string[]) => boolean;
  afterDelete?: (classname: string, objectId: string[]) => void;
  hideCreate?: boolean;
  onRow?: onRow;
  headerExtra?: React.ReactNode;
  customFilter?: (query: Parse.Query) => void;
};

export const ParseSetView = React.memo<Props>(function ParseSetView({
  className,
  view: customView,
  state,
  pagination,
  beforeCreate = $parse.ui.getClassConfig(className)?.beforeCreate,
  afterCreate = $parse.ui.getClassConfig(className)?.afterCreate,
  beforeDelete = $parse.ui.getClassConfig(className)?.beforeDelete,
  afterDelete = $parse.ui.getClassConfig(className)?.afterDelete,
  hideCreate = $parse.ui.getClassConfig(className)?.hideCreate,
  onRow = $parse.ui.getClassConfig(className)?.onRow,
  headerExtra,
  customFilter,
}) {
  const view = customView || $parse.ui.defaultViewMap[className];

  if (!view) {
    return null;
  }

  return (
    <ParseSetViewInner
      className={className}
      view={view}
      state={state}
      beforeCreate={beforeCreate}
      beforeDelete={beforeDelete}
      afterCreate={afterCreate}
      afterDelete={afterDelete}
      hideCreate={hideCreate}
      onRow={onRow}
      pagination={pagination}
      headerExtra={headerExtra}
      customFilter={customFilter}
    />
  );
});

const ParseSetViewInner = React.memo<Props>(function ParseSetViewInner({
  className,
  view,
  pagination,
  state: customState,
  beforeCreate,
  afterCreate,
  beforeDelete,
  afterDelete,
  hideCreate,
  onRow,
  headerExtra,
}) {
  const state = React.useMemo(
    () => customState || $parse.ui.createFilterState(className, view, true),
    [className, view, customState]
  );

  return (
    <ViewLayout
      state={state}
      view={view}
      beforeCreate={beforeCreate}
      beforeDelete={beforeDelete}
      afterCreate={afterCreate}
      afterDelete={afterDelete}
      hideCreate={hideCreate}
      onRow={onRow}
      headerExtra={headerExtra}
    >
      <ParseSetViewSwitch
        className={className}
        pagination={pagination}
        state={state}
        view={view}
        onRow={onRow}
      />
    </ViewLayout>
  );
});

const ParseSetViewSwitch = React.memo<Props>(function ParseSetViewSwitch({
  view,
  pagination,
  state,
  onRow,
}) {
  switch (view.type) {
    case "table":
      return (
        <TableView
          state={state}
          pagination={pagination}
          view={view}
          onRow={onRow}
        />
      );
    case "list":
      return <ListView state={state} view={view} />;
    case "calendar":
      return <CalendarView state={state} view={view} />;
    case "card":
      return <CardView state={state} view={view} />;
    case "canban":
      return <CanbanView state={state} view={view} />;
    default:
      return null;
  }
});
