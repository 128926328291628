import { createInternalComponent, useTranslation } from "@opendash/core";
import {
  DataItemDimensionIdentifierInterface,
  DataItemInterface,
  useDataService,
} from "@opendash/plugin-timeseries";
import { Modal, Tabs } from "antd";
import * as React from "react";
import { AlarmCreate, AlarmInterface, AlarmList, useAlarmsForItem } from "..";

interface Props {
  item: DataItemInterface;
  dimension: number;
  open: boolean;
  close: () => void;
  onSave?: (id: string) => void;
}

function getEmptyAlarm(
  identifier: DataItemDimensionIdentifierInterface
): Omit<AlarmInterface, "id"> {
  const alarm = {
    item: identifier,
    trigger: {},
    action: {},
  };

  // This will be fixed by the form default values
  // @ts-ignore
  return alarm;
}

export const AlarmModal = createInternalComponent<Props>(function AlarmModal({
  item,
  dimension,
  open,
  close,
  onSave,
}) {
  const t = useTranslation();

  const DataService = useDataService();

  const alarms = useAlarmsForItem(item, dimension);

  const [tab, setTab] = React.useState("list");

  React.useEffect(() => {
    if (tab === "list" && alarms.length === 0) {
      setTab("create");
    }
  }, [tab, alarms.length]);

  return (
    <Modal
      open={open}
      title={
        item &&
        t("opendash:monitoring.alarms.modal_title", {
          name: DataService.getItemName(item, dimension),
        })
      }
      cancelText={t("opendash:ui.close")}
      onCancel={(e) => close()}
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
      // okButtonProps={{ disabled: !name }}
    >
      <Tabs
        activeKey={tab}
        onChange={(nextTab) => setTab(nextTab)}
        destroyInactiveTabPane={true}
      >
        <Tabs.TabPane
          disabled={alarms.length === 0}
          tab={t("opendash:monitoring.alarms.modal.tab_list")}
          key="list"
        >
          {item && <AlarmList alarms={alarms} />}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t("opendash:monitoring.alarms.modal.tab_create")}
          key="create"
        >
          {item && (
            <AlarmCreate
              item={item}
              dimension={dimension}
              onSave={() => {
                setTab("list");
              }}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
});
