import * as React from "react";

import { SourcePicker, useSource } from "..";

interface Props {
  style?: React.CSSProperties;
  asButton?: boolean;
  showLine?: boolean;
  defaultExpanded?: boolean;
}

export function LinkedSourcePicker({
  style,
  asButton,
  showLine,
  defaultExpanded,
}: Props) {
  const [source, setSource, sources] = useSource();

  if (!sources || sources.length === 0) {
    return null;
  }

  return (
    <SourcePicker
      value={source}
      onChange={(source) => setSource(source.id)}
      style={style}
      asButton={asButton}
      showLine={showLine}
      defaultExpanded={defaultExpanded}
    />
  );
}
