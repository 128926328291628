import { createInternalComponent, useTranslation } from "@opendash/core";
import {
  DataItemChangedRelative,
  DataItemInterface,
  DataItemValueDisplay,
  useDataService,
} from "@opendash/plugin-timeseries";
import { Descriptions, Divider } from "antd";
import * as React from "react";
import { AlarmModalToggle } from "..";

interface Props {
  details: [DataItemInterface, number];
}

export const DataSidebarDetails = createInternalComponent<Props>(
  function DataSidebarDetails({ details }) {
    const [item, dimension] = details;
    const t = useTranslation();
    const DataService = useDataService();

    return (
      <>
        <Descriptions
          // title="Custom Size"
          bordered
          // extra={<AlarmModalToggle item={item} dimension={dimension} />}
        >
          <Descriptions.Item
            label={t("opendash:monitoring.data_sidebar.col_name")}
          >
            {DataService.getItemName(item)}
          </Descriptions.Item>
          <Descriptions.Item
            label={t("opendash:monitoring.data_sidebar.col_name_dimension")}
          >
            {DataService.getItemName(item, dimension, true)}
          </Descriptions.Item>
          <Descriptions.Item
            label={t("opendash:monitoring.data_sidebar.col_type")}
          >
            {item.valueTypes[dimension]?.type}
          </Descriptions.Item>
          <Descriptions.Item
            label={t("opendash:monitoring.data_sidebar.col_value")}
          >
            <DataItemValueDisplay item={item} dimension={dimension} />
          </Descriptions.Item>
          <Descriptions.Item
            label={t("opendash:monitoring.data_sidebar.col_date")}
          >
            <DataItemChangedRelative item={item} />
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <AlarmModalToggle item={item} dimension={dimension} />
      </>
    );
  }
);
