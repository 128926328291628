import { makeAutoObservable } from "@opendash/state";
import { Log } from "../types-generated";

export class LogService {
  constructor() {
    makeAutoObservable(this);
  }

  async createLogEntry(
    type: string,
    references?: Record<string, { className: string; objectId: string }[]>
  ) {
    const log = new Log({ type, references });

    await log.save();
  }
}
