import { createWidget } from "@opendash/plugin-monitoring";
import React from "react";
import ImageLink from "./image";
import { ConfigInterface } from "./types";

// this file is the entry point for widgets

// with the help of the createWidget function, alot of the workload is done behind the scenes
export default createWidget<ConfigInterface>({
  type: "opendash-widget-hc-test-widget-doku", // the type of the widget is the identifier of your widget

  // here you define the component that should be displayed, when adding the widget to the dashboard
  displayComponent: React.lazy(() => import("./component")),

  // the settings component manages any additional settings on top of the default settings wich every widget has
  settingsComponent: React.lazy(() => import("./settings")),

  // here you define the type of data that can be selected
  // in our example, we only accept numbers from at least one dimension, because we are going to use a line chart
  // wich can only represent numbers
  dataItems: {
    select: "dimension",
    min: 1,
    types: ["Number"],
  },

  // here we enable the options to select live data and history data, so both can be computed by the component
  dataFetching: {
    live: true,
    history: true,
  },

  // this section is important for the widget-selection screen
  // and the attributes shown here influence the appearence of the preview
  // As it is an array of config interfaces, it is possible to present the widget
  // with different preset configs, such as the displayed icon or custom configs
  presets: [
    {
      // the label and description are shown underneath the preview image
      // for localization (translation) purposes you use the translation key here
      // and add it to the translations of this plugin ("plugins/highcharts/src/translations")
      label: "highcharts:explorer.test-widget-doku.title",
      description: "highcharts:explorer.test-widget-doku.description",

      // the tag is the category in wich the widget preset should be presented
      tags: ["kpi"],

      // the imageLink is a base64 coded image. as the code is very long, we use an extra file for it and import it here
      // hint: you should update this base64 code last because you have to take a screenshot of your widget and convert it in base64
      imageLink: ImageLink,

      // here you can specify your configs for this preset
      // you can extend the config by extending the ConfigType in the types.ts of this widget
      widget: {
        config: {
          icon: "fa:info",
        },
      },
    },
  ],
});
