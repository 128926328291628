import { makeAutoObservable } from "@opendash/state";
import * as Parse from "parse";
import { getUserRoles } from "../cloud-functions";
import { _User } from "../types-generated";

export class ParseUserService {
  public loading: boolean = true;

  #user: _User | null = null;
  #roles: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async init() {
    this.setUser(await Parse.User.currentAsync<_User>());

    try {
      this.setRoles(await getUserRoles());
    } catch (error) {
      console.error("Failed to fetch user roles", error);
    }
    this.loading = false;
  }

  current(): _User | null {
    return this.#user;
  }

  id(): string | null {
    return this.#user?.id || null;
  }

  sessionToken(): string | null {
    return this.#user?.getSessionToken() || null;
  }

  username(): string | null {
    return this.#user?.getUsername() || null;
  }

  email(): string | null {
    return this.#user?.getEmail() || null;
  }

  name(): string | null {
    return this.#user?.get("name") || null;
  }

  displayName(): string | null {
    return this.name() || this.username() || this.email();
  }

  roles(): string[] {
    return this.#roles || [];
  }

  private setUser(user: _User) {
    this.#user = user || null;
  }

  private setRoles(roles: string[]) {
    this.#roles = roles || null;
  }
}
