import React from "react";
import styled from "styled-components";

import { PageHeader } from "@ant-design/pro-layout";
import { Input, Space } from "antd";

import { useTranslation } from "@opendash/i18n";

const AdminToolbarHead = styled.div`
  position: relative;
  display: flex;
`;

const AdminToolbarBack = styled.button`
  font-size: 20px;
  margin-right: 20px;
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;
`;

const AdminToolbarTitle = styled.div`
  flex: 1 1;

  p {
    margin: 0;
  }
`;

const AdminToolbarActions = styled.div`
  float: right;
  margin-left: 10px;
`;

const AdminToolbarContent = styled.div``;
const AdminToolbarSearchBar = styled.div``;

interface AdminToolbarProps {
  title?: string;
  description?: string;
  search?: string;
  searchInitial?: string;
  onSearch?(value: string): void;
  actions?: React.ReactNode | React.ReactNode[];

  children?: React.ReactNode;
  onBackButton?(): void;
}

export const AdminToolbar = React.memo<AdminToolbarProps>(
  function AdminToolbar({
    title,
    description,
    search,
    searchInitial,
    onSearch,
    actions,
    children,
    onBackButton,
  }) {
    const t = useTranslation();

    return (
      <PageHeader
        className="od-page-header"
        title={title}
        subTitle={description}
        onBack={onBackButton}
        extra={<Space>{actions}</Space>}
        ghost={false}
      >
        {!!children && children !== "" && children}

        {onSearch && (
          <AdminToolbarSearchBar>
            <Input.Search
              placeholder={t("opendash:ui.search_enter_placeholder")}
              style={{ width: "100%" }}
              {...(searchInitial !== undefined &&
                searchInitial !== "" && { defaultValue: searchInitial })}
              {...(search !== undefined && search !== "" && { value: search })}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              onSearch={(v) => {
                onSearch(v);
              }}
            />
          </AdminToolbarSearchBar>
        )}
      </PageHeader>
    );
  }
);
