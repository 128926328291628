import { createInternalComponent, useTranslation } from "@opendash/core";
import {
  DataItemDimensionIdentifierInterface,
  DataSelect,
} from "@opendash/plugin-timeseries";
import { Description } from "@opendash/ui";
import { Modal } from "antd";
import * as React from "react";
import { WidgetPresetInterface, useWidgetPresets } from "..";

interface Props {
  open: boolean;
  close: () => void;
  limit?: number;
  onSave?: (presets: WidgetPresetInterface[]) => void;
}

export const DashboardAssistantModal = createInternalComponent<Props>(
  function DashboardAssistantModal({ open, close, limit = 1, onSave }) {
    const t = useTranslation();
    const presets = useWidgetPresets();

    React.useEffect(() => {
      if (!open) {
        updateitems([]);
      }
    }, [open]);

    const value = [];
    const [items, updateitems] = React.useState(
      value?.map((element) => {
        return [
          element.params.source,
          element.params.id,
          element.params.dimension,
        ] as DataItemDimensionIdentifierInterface;
      }) || []
    );

    return (
      <Modal
        title={t("opendash:dashboard.assistant.modal.title")}
        okText={t("opendash:dashboard.assistant.modal.save")}
        cancelText={t("opendash:ui.close")}
        open={open}
        width={"60%"}
        onCancel={(e) => close()}
        onOk={() => {
          /* Aktuelle Hilfsfunktion nur opendash-widget-hc-kpi */
          const presetKPI = presets.find((preset) => {
            return (
              !preset.isShared && preset.widget.type == "opendash-widget-hc-kpi"
            );
          });
          const presetsArray = [];
          items.forEach((dataItem) => {
            const createPreset = JSON.parse(JSON.stringify(presetKPI));
            createPreset.widget.config = {
              icon: "fa:info",
              _sources: [],
              _items: [],
              _dimensions: [dataItem],
            };
            presetsArray.push(createPreset);
          });
          onSave(presetsArray);
          close();
        }}
      >
        <div
          style={{ maxHeight: "60vh", minHeight: "40vh", overflowY: "scroll" }}
        >
          <Description>
            {t("opendash:dashboard.assistant.modal.desc")}
          </Description>
          <DataSelect
            showSearch={true}
            showValue={true}
            showActions={false}
            showType={false}
            showTimestamp={true}
            selectionOptions={{
              select: "dimension",
              min: 0,
            }}
            selection={items}
            onSelection={(nextValue) => {
              updateitems(nextValue as DataItemDimensionIdentifierInterface[]);
            }}
          />
        </div>
      </Modal>
    );
  }
);
