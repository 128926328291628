import {
  AdminLayout,
  produce,
  useSource,
  useTranslation,
} from "@opendash/core";
import {
  WidgetCreationModal,
  WidgetStatic,
  useWidgetPresets,
} from "@opendash/plugin-monitoring";
import { CustomParseFieldProps } from "@opendash/plugin-parse";
import Parse from "parse";
import React from "react";

import { Icon } from "@opendash/icons";
import { $parse } from "@opendash/plugin-parse";
import { Source, Tenant } from "@opendash/plugin-parse/dist/types-generated";
import { AdminToolbar } from "@opendash/ui";
import { Button, Divider, Empty, Modal, Table, Tooltip } from "antd";
import { useParseQuery } from "parse-hooks";

type props = {};

export type ReportImage = {
  name: string;
  type: string;
  config: any;
  updatedAt: Date;
  objectId: string;
};

export const CustomNameField = (data: CustomParseFieldProps) => {
  return <Button type="link">{data.value}</Button>;
};
const ReportImageEditor = (props: props) => {
  const t = useTranslation();
  const presets = useWidgetPresets();
  const [source] = useSource();
  const [open, setOpen] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [selected, setSelected] = React.useState<ReportImage>();
  const query = React.useMemo(() => {
    const q = new Parse.Query("OD3_Monitoring_ReportImage");
    q.equalTo("tenant", Parse.User.current().get("tenant"));
    q.equalTo("source", new Source({ id: source.id }));
    return q;
  }, [source.id]);
  const { result: images, loading, error, reload } = useParseQuery(query);
  const [paginationState, setPaginationState] = React.useState<{
    current: number;
    pageSize: number;
  }>({
    current: 1,
    pageSize: 25,
  });
  const columns = [
    {
      key: "name",
      dataIndex: "name",
      title: t(
        "openware:reporting.images.OD3_Monitoring_ReportImage.fields.name"
      ),
      render: (value, record, index) => {
        return (
          <Button
            type="link"
            onClick={() => {
              if (selected?.objectId === value) {
                setSelected(null);
                return;
              }
              setSelected(record);
            }}
          >
            {selected?.objectId === record.objectId ? <b>{value}</b> : value}
          </Button>
        );
      },
    },
    {
      key: "type",
      dataIndex: "type",
      title: t(
        "openware:reporting.images.OD3_Monitoring_ReportImage.fields.type"
      ),
      render: (value, record, index) => {
        const preset = presets.find((p) => p.widget.type === value);
        return t(preset?.label) || value;
      },
    },

    // {
    //   key: "config",
    //   dataIndex: "config",
    //   title: t(
    //     "openware:reporting.images.OD3_Monitoring_ReportImage.fields.config"
    //   ),
    // },
    {
      key: "updatedAt",
      dataIndex: "updatedAt",
      title: t(
        "openware:reporting.images.OD3_Monitoring_ReportImage.fields.updatedAt"
      ),
    },
    {
      key: "pre",
      dataIndex: "pre",
      title: "", //t("openware:reporting.images.showPreview"),
      width: "20px",
      render: (value, record, index) => {
        const url = `${window.location.origin}/openware/reporting/imageprint/${record.source.objectId}/${record.objectId}?embedded=true`;

        return (
          <Icon
            icon="fa:play"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(url, "_blank");
            }}
          />
        );
      },
    },
    {
      key: "id",
      dataIndex: "id",
      title: "",
      render: (id, row) => {
        return (
          <div style={{ width: 130 }}>
            {$parse.ui
              .getObjectActions(
                new Parse.Object("OD3_Monitoring_ReportImage", {
                  id: row.objectId,
                }),
                () => {
                  reload();
                }
              )
              .map((a) => (
                <Tooltip key={a.key} title={t(a.title)}>
                  <Icon
                    icon={a.icon}
                    onClick={a.onClick}
                    style={{
                      marginLeft: 10,
                      marginBottom: 5,
                      marginTop: 5,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              ))}
          </div>
        );
      },
    },
  ];
  const onSave = async (data) => {
    const toSave = new Parse.Object("OD3_Monitoring_ReportImage");

    if (data.objectId) toSave.set("id", data.objectId);
    toSave.set("name", "image1");
    toSave.set("type", data.type || data.widget.type);
    toSave.set("config", data.config || data.widget.config);
    toSave.set(
      "tenant",
      data.tenant?.objectId
        ? new Tenant({ id: data.tenant.objectId })
        : Parse.User.current().get("tenant")
    );
    toSave.set(
      "source",
      data.source?.objectId
        ? new Source({ id: data.source.objectId })
        : new Source({ id: source.id })
    );
    const acl = {};
    acl[Parse.User.current().id] = { read: true, write: true };
    toSave.setACL(new Parse.ACL(acl));
    return toSave.save();
  };

  return (
    <AdminLayout>
      <AdminToolbar
        title={t("openware:reporting.images.OD3_Monitoring_ReportImage.label")}
        description={t(
          "openware:reporting.images.OD3_Monitoring_ReportImage.description"
        )}
        actions={[
          <Button
            type="primary"
            icon={<Icon icon="fa:plus" />}
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("parse-admin:admin.create.action")}
          </Button>,
          <Button
            type="default"
            icon={<Icon icon="fa:sync" />}
            onClick={() => {
              reload();
            }}
          >
            {t("parse-admin:admin.reload_action")}
          </Button>,
        ]}
      />
      <div style={{ backgroundColor: "#fff", padding: "16px" }}>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selected?.objectId === record.objectId) {
                  setSelected(null);
                  return;
                }
                setSelected(record);
              }, // click row
              onDoubleClick: (event) => {}, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {}, // mouse leave row
            };
          }}
          dataSource={images.map((image) => image.toJSON())}
          columns={columns}
          size="small"
          pagination={{
            pageSize: paginationState.pageSize,
            position: ["bottomRight"],
            showSizeChanger: true,
            pageSizeOptions: ["10", "25", "50", "100", "1000"],
            onChange: (page, pageSize) => {
              setPaginationState({
                current: page,
                pageSize: pageSize,
              });
            },
            current: paginationState.current,
          }}
        />
      </div>
      {!selected && (
        <Empty
          style={{ marginTop: "60px" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 60 }}
          description={t("openware:reporting.images.selectToEditHint")}
        />
      )}
      {selected && (
        <>
          <div
            style={{
              padding: "16px",
              marginTop: "16px",
              width: "100%",
              display: "flex",
              backgroundColor: "#fff",
              flexDirection: "column",

              gap: "8px",
            }}
          >
            <WidgetStatic
              type={selected.type}
              config={selected.config}
              edit={true}
              style={{ flexGrow: 1 }}
              onSave={(config) => {
                onSave(
                  produce(selected, (draft) => {
                    draft.config = config;
                  })
                )
                  .then((res) => {
                    reload();
                    setSelected(res.toJSON() as unknown as ReportImage);
                  })
                  .catch(console.error);
              }}
            />
            <Divider></Divider>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                setShowPreview(true);
              }}
            >
              {t("openware:reporting.images.showPreview")}
            </Button>
          </div>
        </>
      )}
      {selected && showPreview && (
        <Modal
          open={showPreview}
          width={"60%"}
          onCancel={() => {
            setShowPreview(false);
          }}
          onOk={() => {
            onSave(selected);
            setShowPreview(false);
          }}
        >
          <div
            style={{
              height: "50vh",
              padding: "16px",
              marginTop: "16px",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <WidgetStatic
              type={selected.type}
              config={selected.config}
              style={{ flexGrow: 1 }}
              onChange={(newConfs) => {
                setSelected(
                  produce((draft) => {
                    draft.config = newConfs[0];
                  })
                );
              }}
            />
          </div>
        </Modal>
      )}
      <WidgetCreationModal
        open={open}
        onSave={(data) => {
          onSave(data[0]).then(reload);
        }}
        close={() => {
          setOpen(false);
        }}
      />
    </AdminLayout>
  );
};

export default ReportImageEditor;
