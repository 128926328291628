import Parse from "parse";
import React from "react";

import { ParseField } from "./ParseField";

interface Props {
  object: Parse.Object;
  fields: string[];

  isInline?: boolean;
}

export const ParseFields = React.memo<Props>(function ParseFields({
  object,
  fields,
  isInline = false,
}) {
  return (
    <>
      {fields.map((field, i, a) => (
        <span key={field}>
          <ParseField object={object} field={field} isInline={isInline} />
          {i !== a.length - 1 && ", "}
        </span>
      ))}
    </>
  );
});
