import { useTranslation } from "@opendash/i18n";
import { Flex, Spin, Tag } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect, useState } from "react";
import { CronEditor, ScheduleCronObjectType } from "..";

/**
 * React component to create a cron form
 * You have to wrap it in antd <Form> component
 *
 * @param value - ValueChangeType
 * @param setValue - Function which returns the value of type ValueChangeType
 *
 * @author Nico Vitt
 */
export const CronForm = observer(
  (props: {
    value: ScheduleCronObjectType;
    setValue: (value: ScheduleCronObjectType) => void;
  }) => {
    const t = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      setLoading(false);
    }, [props.value]);

    if (loading) {
      return <Spin />;
    }

    //If props.setValue is undefined the component is only for display
    if (typeof props.setValue === "undefined") {
      return (
        <Flex vertical gap="middle">
          <Tag>
            {t("maintenance:cron.display.startdate.label", {
              date:
                typeof props.value.run_startdate !== "undefined"
                  ? dayjs(props.value.run_startdate).format("DD.MM.YYYY HH:mm")
                  : null,
            })}
          </Tag>
          <Tag>
            {t("maintenance:cron.display.enddate.label", {
              date:
                typeof props.value.run_enddate !== "undefined"
                  ? dayjs(props.value.run_enddate).format("DD.MM.YYYY HH:mm")
                  : null,
            })}
          </Tag>
          <Tag>
            {t("maintenance:cron.display.cron.label", {
              cron: props.value.run_cron,
            })}
          </Tag>
        </Flex>
      );
    } else {
      //If props.setValue is a function and not undefined, return CronEditor
      return (
        <CronEditor
          reset={(fields: string[]) => {
            return true;
          }}
          onChange={(value: ScheduleCronObjectType) => {
            //Check if props.setValue is a function and not undefined, else return
            if (
              typeof props.setValue === "undefined" ||
              typeof props.setValue !== "function" ||
              typeof value === "undefined"
            ) {
              return;
            }

            props.setValue({
              run_cron: value.run_cron,
              run_startdate: new Date(value.run_startdate.toISOString()),
              run_enddate:
                typeof value.run_enddate !== "undefined"
                  ? new Date(value.run_enddate.toISOString())
                  : new Date(value.run_startdate.toISOString()),
              is_week: value.is_week,
            });
          }}
          showCronString={true}
          showCalendar={true}
          value={props.value}
        />
      );
    }
  }
);
