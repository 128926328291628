import { Form, Select } from "antd";
import produce from "immer";
import Parse from "parse";
import React from "react";

function ShelfSelect(props) {
  let style = {};
  if (props.elementStyle) {
    style = props.elementStyle;
  }
  const state = props.Data;
  const data = props.key;
  const formData = props.FormData;
  const [items, setItems] = React.useState(state.items || []);
  const [shelf, setShelf] = React.useState("");

  const rules = [];
  const initList = async () => {
    try {
      const shelfOptions = await new Parse.Query("ARFA_CUSTOMER")
        .equalTo("name", localStorage.getItem("openchecklistref"))
        .first();
      const listOptions = await new Parse.Query("ARFA_SHELF")
        .equalTo("customer", shelfOptions.id)
        .limit(999999)
        .find();

      setItems(
        listOptions.map((parseOption) => {
          return {
            value: parseOption.id,
            label:
              parseOption.get("Regalnummer") + " - " + parseOption.get("name"),
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
  React.useEffect(() => {
    initList();
  }, []);

  React.useEffect(() => {
    const x = {};
    if (shelf != "") {
      x[data.key] = shelf;
      formData.setFieldsValue(x);
    }
  }, [shelf]);

  if (state["v_required"]) {
    rules.push({
      required: state["v_required"].value,
      message: state["v_required"].err,
    });
  }
  const mergedStyle = { ...{ heigt: "10em" }, ...style };

  return (
    <Form.Item name={state.key} label={state.title} rules={rules}>
      <Select
        className="bde-autocomplete"
        showSearch
        //style={mergedStyle}
        options={items}
        listHeight={300}
        onChange={(e) => {
          setShelf(
            produce((draft) => {
              draft = e;
            })
          );
        }}
        filterOption={(input, option) =>
          JSON.stringify(option).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    </Form.Item>
  );
}
export default ShelfSelect;
