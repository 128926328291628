import { FontSizeOutlined, FormOutlined } from "@ant-design/icons";
import {
  AdminLayout,
  produce,
  useFeedback,
  useTranslation,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { $parse } from "@opendash/plugin-parse";
import { AdminToolbar } from "@opendash/ui";
import {
  Button,
  Card,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import Parse from "parse";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { configProvider } from "../..";
import { EditSteps } from "../../features/forms";
import { Form, Page, Unit } from "../../parse";

const { Option } = Select;

const PageManagePage = (props) => {
  const t = useTranslation();
  const [edit, setEdit] = useState(null);
  const [currentUnit, setCurrentUnit] = useState(null);
  const { message } = useFeedback();

  const [state, setState] = useState({
    showModal: false,
    showUnitModal: false,
    selected: null,
    loadingSources: false,
    sources: [],
    units: {},
    originalUnits: {},
    dirty: [],
    forms: [],
    formsUnit: {} as Record<
      string,
      {
        objectid: string;
        name: string;
        rid: string;
      }
    >,
    original: {},
    isDirty: [],
    isDirtyUnit: [],
    tempUnit: {
      name: "",
      source: "",
    },
    tempItem: {
      pageID: "",
      data: {
        title: "",
        fields: [],
      },
    },
  });

  const [selection, setSelection] = useState(props.Selected || 0);

  const onChangeUnitName = (dForm, form) => {
    setState(
      produce((draft) => {
        draft.isDirtyUnit.push(form.objectid);
        console.log(form, dForm);
        console.log(state.units);
        const unitKey = Object.keys(draft.units).find((key) => {
          return draft.units[key].objectid === form.objectid;
        });

        if (unitKey) {
          draft.units[unitKey].name = dForm.target.value;
        }
      })
    );
  };

  const getPage = useCallback(async () => {
    const query = new Parse.Query(Page);
    const results = await query.find();
    return results;
  }, []);

  const getForms = useCallback(async () => {
    const query = new Parse.Query(Form);
    const results = await query.find();
    return results;
  }, []);

  const getSources = useCallback(async () => {
    const query = new Parse.Query(configProvider.config.sources.table);
    const results = await query.find();
    return results;
  }, []);

  const columns = [
    {
      title: t("bde:entries.label_input_entryname"),
      dataIndex: "name",
      key: "name",
    },
    /*{ title: t("bde:entries.label_id"), dataIndex: "pid", key: "pid" },*/
    {
      title: t("bde:entries.label_editPage"),
      dataIndex: "data",
      align: "center" as const,
      key: "data",
      render: (text, form) => (
        <div>
          <Button
            style={{ display: "inline-block", marginRight: "5px" }}
            onClick={(e) => {
              openFormEditor(text, form);
            }}
            disabled={!form.canWrite}
          >
            <FormOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: t("bde:delete"),
      key: "save",
      align: "center" as const,
      render: (text, form) => (
        <div>
          <Popconfirm
            title={t("bde:deleteConfirm")}
            disabled={!form.canWrite}
            onConfirm={(e) => {
              deleteEntry(text, form);
            }}
            onCancel={(e) => {}}
            okText={t("bde:delete")}
            cancelText={t("bde:cancel")}
          >
            <Button
              icon={
                <Icon
                  icon="fa:trash"
                  key="delete"
                  style={{ color: "#ff4d4d" }}
                />
              }
              disabled={!form.canWrite}
              danger
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const rowUnit = [
    {
      title: t("bde:units.label_input_unitname"),
      dataIndex: "name",
      key: "name",
      render: (text, unit) => {
        return (
          <>
            {edit == unit.objectid ? (
              <Input
                disabled={!unit.canWrite}
                value={unit.name}
                onChange={(dForm) => {
                  onChangeUnitName(dForm, unit);
                }}
                style={{ width: "100%" }}
              />
            ) : (
              <div style={{ width: "100%" }}>{unit.name}</div>
            )}
          </>
        );
      },
    },
    {},
    { title: t("bde:units.label_source"), dataIndex: "ID", key: "ID" },
    {
      title: t("bde:units.label_table_assignedForms"),
      key: "forms",
      align: "center" as const,
      render: (text, unit) => (
        <>
          {unit?.canWrite && edit == unit.objectid && (
            <Select
              mode="tags"
              placeholder={t("bde:label_placeholder_choose")}
              value={unit.forms}
              onChange={(forms) => {
                handleChange(forms, unit);
              }}
              style={{ width: "100%" }}
            >
              {Object.values(state.formsUnit).map((form: any) => {
                return (
                  <Option key={form.objectid} value={form.objectid}>
                    {form.name}
                  </Option>
                );
              })}
            </Select>
          )}
          {!unit?.canWrite ||
            (edit != unit.objectid &&
              unit.forms.map((form: any) => {
                const forms = Object.values(state.formsUnit).find((e) => {
                  return e.objectid == form;
                });
                return <Tag key={forms.objectid}>{forms.name}</Tag>;
              }))}
        </>
      ),
    },
    {
      title: t("bde:save") + " / " + t("bde:delete"),
      key: "save",
      align: "center" as const,
      render: (text, unit) => (
        <div>
          {entryIsDirtyUnit(unit) ? (
            <Tooltip title={t("bde:save")}>
              <Button
                type="primary"
                disabled={!unit.canWrite}
                onClick={(e) => saveUnit(text, unit)}
                icon={<Icon icon="fa:save"></Icon>}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t("bde:forms.label_edit_form")}>
              <Button
                disabled={!unit.canWrite}
                onClick={(e) => {
                  setCurrentUnit(unit);

                  setState(
                    produce((draft) => {
                      draft.dirty = [];
                    })
                  );
                  retrieveFormsAndUpdateState();
                  setEdit(
                    edit == null
                      ? unit.objectid
                      : edit == unit.objectid
                        ? null
                        : unit.objectid
                  );
                }}
                icon={<Icon icon="fa:pen"></Icon>}
              />
            </Tooltip>
          )}

          <Popconfirm
            title={t("bde:deleteConfirm")}
            onConfirm={(e) => {
              deleteUnit(text, unit);
            }}
            onCancel={(e) => {}}
            okText={t("bde:delete")}
            cancelText={t("bde:cancel")}
            disabled={!unit.canWrite}
          >
            <Button
              icon={
                <Icon
                  icon="fa:trash"
                  key="delete"
                  style={{ color: "#ff4d4d" }}
                />
              }
              disabled={!unit.canWrite}
              danger
            />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: t("bde:label_acl"),
      key: "acl",
      align: "center",
      render: (text, unit) => (
        <>
          <Button
            onClick={async (e) => {
              if (unit.canWrite) {
                const toAcl = Unit.createWithoutData(unit.objectid);
                $parse.ui
                  .editObjectACL(toAcl.className, toAcl.id)
                  .then((e) => {});
              }
            }}
            icon={<Icon icon="fa:lock" />}
          />
        </>
      ),
    },
  ];

  const handleChange = (forms, unit) => {
    setState(
      produce((cState) => {
        const x = cState.units[unit.objectid];
        x.forms = forms;
        cState.units[unit.objectid] = x;
        cState.isDirtyUnit.push(unit.objectid);
      })
    );
  };

  const deleteUnit = (text, unit) => {
    const todelete = new Unit({ id: unit.objectid });

    todelete.fetch().then(
      (res) => {
        res.destroy().then(
          (res) => {
            message.success(
              t("bde:app.createitem.delsuccess") + res.get("name")
            );
            retrieveUnitsAndUpdateState();
          },
          (error) => {
            message.error(t("bde:app.createitem.delerror") + error.message);
          }
        );
      },
      (error) => {
        message.error(t("bde:app.createitem.delsuccess") + error.message);
      }
    );
  };

  const deleteEntry = (text, form) => {
    const todelete = new Page({ id: form.objectid });
    todelete.fetch().then(
      (res) => {
        res.destroy().then(
          (res) => {
            message.success(
              t("bde:deleteSuccess") + " " + res.get("data").title
            );
            retrieveFormsAndUpdateState();
          },
          (error) => {
            message.error(t("bde:deleteError") + " " + error.message);
          }
        );
      },
      (error) => {
        message.error(t("bde:deleteError") + " " + error.message);
      }
    );
  };

  const save = async (text, form) => {
    if (!form.objectid) {
      const toSave = new Page();
      toSave.set("id", form.objectid);
      toSave.set("pageID", form.pid);
      toSave.set("data", form.data);
      const acl = new Parse.ACL(Parse.User.current());
      toSave.setACL(acl);
      try {
        await toSave.save();
        message.success(
          t("bde:saveEntrySuccess") + ": " + toSave.get("data").title
        );
        retrieveFormsAndUpdateState();
      } catch (error) {
        message.error(t("bde:saveEntryError") + ": " + error.message);
      }
    } else {
      const toSave = new Page({ id: form.objectid });
      try {
        await toSave.fetch();
        toSave.set("id", form.objectid);
        toSave.set("pageID", form.pid);
        toSave.set("data", form.data);
        await toSave.save();
        message.success(
          t("bde:saveEntrySuccess") + ": " + toSave.get("data").title
        );
        retrieveFormsAndUpdateState();
      } catch (error) {
        console.error(error);
        message.error(t("bde:saveEntryError") + ": " + error.message);
      }
    }
  };

  const saveUnit = (text, unit) => {
    const toSave = new Unit({ id: unit.objectid });
    toSave.fetch().then(
      (res) => {
        const rel = res.relation("forms");

        unit.forms.forEach((form) => {
          rel.add(Form.createWithoutData(form));
        });

        state.originalUnits[unit.objectid].forms.forEach((form) => {
          if (!unit.forms.includes(form)) {
            rel.remove(Form.createWithoutData(form));
          }
        });
        res.name = unit.name;

        res.save().then(
          (res) => {
            message.success(
              t("bde:app.createitem.delsuccess") + res.get("name")
            );
            retrieveUnitsAndUpdateState();
          },
          (error) => {
            message.error(t("bde:app.createitem.delerror") + error.message);
          }
        );
      },
      (error) => {
        console.error(error);
        message.error(t("bde:app.createitem.delerror") + error.message);
      }
    );
    setEdit(null);
  };

  const SourceSelect = (props) => {
    const IsLoading = props.IsLoading;
    const sources = props.Sources;
    if (IsLoading) {
      return (
        <Select style={{ display: "block", width: "100%" }} loading></Select>
      );
    }
    return (
      <Select
        style={{ display: "block", width: "100%" }}
        placeholder={t("bde:units.label_source")}
        className="od_admin_bde_input"
        value={props.Selected}
        onChange={(e) => {
          props.OnChange(e);
        }}
      >
        {sources.map((source, i) => {
          return <Option value={i}>{source.name}</Option>;
        })}
      </Select>
    );
  };

  const updateStep = (action) => {
    setState(
      produce((x) => {
        x.forms = x.forms.map((form) => {
          if (form.objectid !== state.selected) {
            return form;
          }
          action(form.data);
          x.dirty.push(form.objectid);
          return form;
        });
      })
    );
  };

  const openFormEditor = (text, form) => {
    setState(
      produce((state) => {
        state.selected = form.objectid;
      })
    );
  };

  useEffect(() => {
    retrieveFormsAndUpdateState();
    retrieveUnitsAndUpdateState();
  }, []);

  const retrieveFormsAndUpdateState = async () => {
    try {
      const result = await getPage();

      setState(
        produce((draft) => {
          draft.forms = result.map((element) => {
            return {
              objectid: element.id,
              name: element.get("data").title,
              pid: element.get("pageID"),
              data: element.get("data"),
              canWrite: $parse.ui.getObjectPermission(element, "update"),
            };
          });

          draft.original = {};

          draft.forms.forEach((form) => {
            draft.original[form.objectid] = JSON.stringify(form);
          });
          draft.dirty = [];
          draft.selected = 0;
        })
      );
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const entryIsDirty = (form) => {
    return state.dirty.includes(form.objectid);
  };

  const entryIsDirtyUnit = (unit) => {
    return state.isDirtyUnit.includes(unit.objectid);
  };

  const handleOk = (e) => {
    setState(
      produce((state) => {
        state.selected = null;
      })
    );

    entferneDuplikate(state.dirty).forEach((element) => {
      save(null, findeObjektMitID(element, state.forms));
    });
  };

  const handleCancel = (e) => {
    setState(
      produce((state) => {
        state.dirty = state.dirty.filter((item) => {
          return item !== state.selected;
        });
        state.forms = state.forms.map((cForm) => {
          if (cForm.objectid !== state.selected) {
            return cForm;
          }
          return JSON.parse(state.original[state.selected]);
        });
        state.selected = null;
      })
    );
  };

  const findeObjektMitID = (id, objektArray) => {
    for (let i = 0; i < objektArray.length; i++) {
      if (objektArray[i].objectid === id) {
        return objektArray[i];
      }
    }
    return null;
  };

  const entferneDuplikate = (array) => {
    return array.filter((item, index) => array.indexOf(item) === index);
  };

  const getModal = () => {
    const form = state.forms.find((f) => f.objectid === state.selected);
    const f_title =
      t("bde:entries.label_editPage") + (form ? " " + form.name : "");

    return (
      <>
        {form && (
          <Modal
            title={<b>{f_title}</b>}
            open={!!form}
            onOk={handleOk}
            okText={t("bde:save")}
            cancelText={t("bde:cancel")}
            onCancel={handleCancel}
            width="75%"
          >
            <EditSteps step={form.data} updateStep={updateStep} />
          </Modal>
        )}
      </>
    );
  };

  const retrieveUnitsAndUpdateState = () => {
    const allData = [];
    //get forms from server;
    allData.push(getForms());

    //get Units from server;
    const query = new Parse.Query(Unit);
    allData.push(query.find());
    //@ts-ignore
    Promise.all(allData).then((data, err) => {
      if (err) {
        message.error(t("bde:app.createitem.delerror") + err.message);
        return;
      }
      const promises = [];
      let cResults;
      // Units aus data[1] werden gemappt
      cResults = data[1].map((result) => {
        promises.push(result.relation("forms").query().find());
        return {
          name: result.get("name"),
          objectid: result.id,
          ID: result.get("source") || "",
          forms: [],
          canWrite: $parse.ui.getObjectPermission(result, "update", false),
        };
      });
      Promise.all(promises).then((formRes) => {
        formRes.forEach((forms, index) => {
          cResults[index].forms = forms.map((form) => {
            return form.id;
          });
        });
        setState(
          produce((x) => {
            x.isDirtyUnit = [];
            x.originalUnits = {};
            const tempUnits = {};
            const tempOriginals = {};
            cResults.forEach((result) => {
              tempOriginals[result.objectid] = JSON.parse(
                JSON.stringify(result)
              );
              tempUnits[result.objectid] = result;
            });
            x.units = tempUnits;
            x.originalUnits = tempOriginals;

            data[0].forEach((elements) => {
              x.formsUnit[elements.id] = {
                objectid: elements.id,
                name: elements.get("name"),
                rid: elements.get("RID"),
              };
            });
          })
        );
      });
    });
  };

  const handleOkUnit = (e) => {
    const toSave = new Unit();
    toSave.set("source", state.tempUnit.source);
    toSave.set("name", state.tempUnit.name);
    const acl = new Parse.ACL(Parse.User.current());
    toSave.setACL(acl);
    toSave.save().then(
      (res) => {
        message.success(t("bde:units.creationSuccess") + res.get("name"));
        setState(
          produce((x) => {
            x.showUnitModal = false;
            x.tempUnit = {} as { name: string; source: string };
          })
        );
        retrieveUnitsAndUpdateState();
      },
      (error) => {
        message.error(t("bde:units.error") + error.message);
      }
    );
  };

  const handleCancelUnit = (e) => {
    setState(
      produce((x) => {
        x.showUnitModal = false;
      })
    );
  };

  const onChangeName = (e) => {
    setState(
      produce((draft) => {
        draft.tempUnit.name = e.target.value;
      })
    );
  };

  const onChangeID = (e) => {
    setSelection(e);

    setState(
      produce((draft) => {
        draft.tempUnit.source = state.sources[e].sourceTag;
      })
    );
  };

  const onPageClick = (e) => {
    setState(
      produce((cState) => {
        cState.showModal = true;
        cState.tempItem = {
          pageID: "page" + new Date().getTime(),
          data: {
            title: "",
            fields: [],
          },
        };
      })
    );
  };

  const onUnitClick = async (e) => {
    setState(
      produce((draft) => {
        draft.showUnitModal = true;
        draft.loadingSources = true;
      })
    );
    try {
      const sources = (await getSources()) || [];
      setState(
        produce((draft) => {
          draft.loadingSources = false;
          draft.sources = sources
            .filter((firstloc) => {
              return !!firstloc.get("tag");
            })
            .map((loc) => {
              return {
                name: loc.get("name") + " (" + loc.get("tag") + ")",
                sourceTag: loc.get("tag"),
              };
            });
          draft.tempUnit.source = draft.sources[0].sourceTag;
        })
      );
    } catch (err) {
      message.error(t("bde:units.error") + err.message);
      setState(
        produce((x) => {
          x.showModal = false;
        })
      );
    }
  };

  const handleNewOk = (e) => {
    const toSave = new Page();
    toSave.set("data", state.tempItem.data);
    toSave.set("pageID", state.tempItem.pageID);
    const acl = new Parse.ACL(Parse.User.current());
    toSave.setACL(acl);
    toSave.save().then(
      (res) => {
        message.success(
          t("bde:saveEntrySuccess") + ": " + res.get("data").title
        );
        setState(
          produce((x) => {
            x.showModal = false;
            x.tempItem = {
              pageID: "page" + new Date().getTime(),
              data: {
                title: t("bde:entries.label_newPage"),
                fields: [],
              },
            };
          })
        );
        retrieveFormsAndUpdateState();
      },
      (error) => {
        message.error(t("bde:saveEntryError") + ": " + error.message);
      }
    );
  };

  const handleNewCancel = (e) => {
    setState(
      produce((cState) => {
        cState.showModal = false;
      })
    );
  };

  return (
    <AdminLayout>
      <AdminToolbar
        title={t("bde:app.createitem.customtitle")}
        description={t("bde:app.createitem.customdesc")}
        search={""}
        actions={
          $parse.ui.getClassPermission(
            configProvider.config.pages.table,
            "create"
          )
            ? [
                <Button type="primary" onClick={onPageClick} key="createform">
                  {t("bde:entries.newForm")}
                </Button>,
                <Button type="primary" onClick={onUnitClick} key="createunit">
                  {t("bde:units.newUnit")}
                </Button>,
              ]
            : []
        }
      />
      <div className="od-page-main">
        <Tabs centered defaultActiveKey="1">
          <Tabs.TabPane tab={t("bde:app.createitem.pagetitle")} key="1">
            <Table
              rowKey={(row) => row.objectid}
              dataSource={state.forms}
              size="middle"
              columns={columns}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("bde:app.createitem.entrytitle")} key="2">
            <Table
              rowKey={(rowUnit) => rowUnit.objectId}
              dataSource={Object.values(state.units)}
              //@ts-ignore
              columns={rowUnit}
              size="middle"
            />
          </Tabs.TabPane>
        </Tabs>
        {getModal()}
      </div>
      <Modal
        title={t("bde:entries.newForm")}
        open={state.showModal}
        onOk={handleNewOk}
        onCancel={handleNewCancel}
      >
        <Card style={{ marginBottom: "10px" }} title={t("bde:label_hint")}>
          {t("bde:entries.page_creation_info")}
        </Card>
        <Input
          prefix={<FontSizeOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder={t("bde:entries.label_pagename")}
          style={{ display: "flex" }}
          value={state.tempItem.data.title}
          onChange={(e) => {
            setState(
              produce(state, (cstate) => {
                cstate.tempItem.data.title = e.target.value;
              })
            );
          }}
        />
      </Modal>
      <Modal
        title={t("bde:units.newUnit")}
        open={state.showUnitModal}
        onOk={handleOkUnit}
        onCancel={handleCancelUnit}
      >
        <Card
          style={{ marginBottom: "10px" }}
          title={t("bde:label_hint")}
          //style={{ width: 300 }}
        >
          {t("bde:units.unit_creation_info")}
        </Card>
        <Input
          prefix={<FontSizeOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder={t("bde:units.label_input_unitname")}
          defaultValue={state.tempUnit.name}
          value={state.tempUnit.name}
          onChange={onChangeName}
          style={{ display: "flex", marginBottom: "10px" }}
        />

        <SourceSelect
          IsLoading={state.loadingSources}
          Sources={state.sources}
          OnChange={onChangeID}
          Selected={selection}
        />
      </Modal>
    </AdminLayout>
  );
};

export default PageManagePage;
