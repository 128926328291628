import { makeAutoObservable } from "@opendash/state";
import { PointType } from "../types";

type CallbackType = () => void;

export class LocationService {
  public latitude: number = 0;
  public longitude: number = 0;
  public accuracy: number = 0;
  public altitudeAccuracy: number = 0;
  public altitude: number = 0;
  public speed: number = 0;
  public heading: number = 0;

  public updatedAt: number = Date.now();

  private geolocationWatcher: number | null = null;
  private watcher: Set<CallbackType> = new Set();

  constructor() {
    makeAutoObservable(this);
  }

  get point(): PointType {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
    };
  }

  private setPosition(position: GeolocationPosition) {
    this.latitude = position.coords.latitude;
    this.longitude = position.coords.longitude;
    this.accuracy = position.coords.accuracy;

    this.updatedAt = position.timestamp;

    this.watcher.forEach((callback) => {
      try {
        callback();
      } catch (error) {
        console.error(
          "[@opendash/plugin-geo] location.watch(): Error in callback:",
          error
        );
      }
    });
  }

  public async getCurrentPosition(): Promise<PointType> {
    if (!navigator.geolocation) {
      throw new Error(
        "[@opendash/plugin-geo] location.getCurrentPosition(): !navigator.geolocation"
      );
    }

    return new Promise<PointType>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setPosition(position);

          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => reject(error)
      );
    });
  }

  public async watch(callback: CallbackType) {
    if (this.watcher.size === 0) {
      this.init();
    }

    this.watcher.add(callback);

    return () => {
      this.watcher.delete(callback);

      if (this.watcher.size === 0) {
        this.close();
      }
    };
  }

  private async init() {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => this.setPosition(position),
          (error) => console.error(error)
        );

        this.geolocationWatcher = navigator.geolocation.watchPosition(
          (position) => this.setPosition(position),
          (error) => console.error(error)
        );
      }
    } catch (error) {
      console.error("[@opendash/plugin-geo] location.init() Error:", error);
    }
  }

  private close() {
    if (this.geolocationWatcher) {
      navigator.geolocation.clearWatch(this.geolocationWatcher);
    }
  }
}
