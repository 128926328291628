import {
  TimeMode,
  isDateUnit,
  getTimeString as timestring,
} from "@opendash/core";
import {
  isMillisecondsUnit,
  isSecondsUnit,
} from "@opendash/core/dist/helper/dateHelper";
import { Progress, Typography } from "antd";
import * as React from "react";
import {
  DataItemDimensionValueInterface,
  DataItemInterface,
  useDataItemValue,
} from "..";
import { DataItemValueInterface } from "../types/DataItemValueInterface";

interface Props {
  item: DataItemInterface;
  dimension: number | "date";
  booleanSize?: number;
  timeMode?: TimeMode;
  timeDisplayMode?: string;
  stringCopy?: boolean;
  value?: DataItemValueInterface;
  singleValue?: DataItemDimensionValueInterface;
  round?: number;
  onClick?: () => void;
}

const getTimeString = (tMode, timeDisplay, unit, dimension, value) => {
  const cValue = dimension === "date" ? value.date : value.value[dimension];
  return timestring(tMode, timeDisplay, unit, cValue);
};

export const DataItemValueDisplay = React.memo<Props>(
  ({
    item,
    dimension,
    value,
    booleanSize = 10,
    timeMode = -1,
    timeDisplayMode = "full",
    stringCopy = false,
    singleValue,
    round = 2,
    onClick,
  }) => {
    const duration_modes = ["unix", "unit", "full", "humanize"];
    const date_modes = ["unix", "full", "date", "time", "humanize"];
    const [tMode, setTMode] = React.useState(timeMode);

    let tdm;
    if (tMode === TimeMode.DateMode) {
      tdm =
        date_modes.indexOf(timeDisplayMode) === -1 ? "full" : timeDisplayMode;
    } else {
      tdm =
        duration_modes.indexOf(timeDisplayMode) === -1
          ? "unit"
          : timeDisplayMode;
    }
    const [timeDisplay, setTimeDisplay] = React.useState(tdm);
    const unit = dimension === "date" ? "ms" : item.valueTypes[dimension].unit;
    if (singleValue) {
      const values = new Array(item.valueTypes.length);
      values[dimension] = singleValue.value;
      value = { date: singleValue.date, value: values };
    }
    value = useDataItemValue(item, value);
    if (!value) {
      return <pre>Error</pre>;
    }

    if (tMode === -1) {
      const cValue = dimension === "date" ? value.date : value.value[dimension];
      if (isSecondsUnit(unit) || isMillisecondsUnit(unit)) {
        if (cValue > 1000 * 3600 * 24 * 365 * 10) {
          setTMode(TimeMode.DateMode);
        } else {
          setTMode(TimeMode.DurationMode);
          setTimeDisplay("full");
        }
      } else {
        setTMode(TimeMode.DurationMode);
        setTimeDisplay("unit");
      }
    }

    if (dimension !== "date" && !item?.valueTypes?.[dimension]) {
      console.warn(
        `DataItemValueDisplay: Item Value Dimension not found '${item?.id}' '${dimension}'`
      );

      return null;
    }

    if (
      dimension !== "date" &&
      (value?.value?.[dimension] === null ||
        value?.value?.[dimension] === undefined)
    ) {
      console.warn(
        `DataItemValueDisplay: Value Dimension not found '${item?.id}' '${dimension}'`
      );

      return null;
    }
    //return value?.value[dimension] || "empty";
    if (item.valueTypes[dimension]?.type === "String") {
      if (isBase64Image(value.value[dimension])) {
        return (
          <a
            className="data-item-image"
            onClick={() => {
              if (onClick) onClick();
              openImageInNewTab(value.value[dimension]);
            }}
          >
            <img style={{ height: "50px" }} src={value.value[dimension]} />{" "}
          </a>
        );
      }
      if (isLink(value.value[dimension])) {
        return (
          <a
            className="data-item-link"
            href={value.value[dimension]}
            target="_blank"
            rel="noreferrer"
          >
            Link
          </a>
        );
      }

      return (
        <Typography.Text
          className="data-item-string"
          copyable={stringCopy}
          ellipsis={{ tooltip: value.value[dimension] }}
        >
          {value.value[dimension]}
        </Typography.Text>
      );
    }

    if (dimension === "date" || item.valueTypes[dimension].type === "Number") {
      if (dimension === "date" || isDateUnit(item.valueTypes[dimension].unit)) {
        return (
          <span
            className="data-item-date"
            onClick={() => {
              if (onClick) onClick();
              const modeArray =
                tMode === TimeMode.DateMode ? date_modes : duration_modes;

              const currIdx = modeArray.indexOf(timeDisplay);
              const nextIdx = (currIdx + 1) % modeArray.length;

              setTimeDisplay(modeArray[nextIdx]);
            }}
          >
            {getTimeString(tMode, timeDisplay, unit, dimension, value)}
          </span>
        );
      }
      if (item.valueTypes[dimension].unit === "%") {
        return (
          <Progress
            className="data-item-percentage"
            percent={Math.round(value.value[dimension] * 100) / 100}
            steps={5}
          />
        );
      }
      return (
        <span className="data-item-number">
          {typeof value.value[dimension] === "number"
            ? value.value[dimension].toFixed(round)
            : value.value[dimension]}{" "}
          {item.valueTypes[dimension].unit}
        </span>
      );
    }

    if (item.valueTypes[dimension]?.type === "Boolean") {
      return (
        <span
          className="data-item-boolean"
          style={{
            display: "inline-block",
            width: booleanSize,
            height: booleanSize,
            borderRadius: booleanSize,
            border: "1px solid var(--ant-primary-color)",
            background: value.value[dimension]
              ? "var(--ant-primary-color)"
              : "transparent",
          }}
        />
      );
    }

    console.warn(
      "DataItemValueDisplay: item or dimension not available or no way to display value type"
    );

    return null;
  }
);
function openImageInNewTab(base64) {
  const win = window.open();
  win.document.write(
    '<iframe src="' +
      base64 +
      '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
  );
}
function isLink(text) {
  const urlEx = new RegExp("((http|ftp|https):/{2})");
  return urlEx.test(text);
  //return match.length > 0;
  //return ("" + text).toLowerCase().indexOf("http") == 0;
}
function isBase64Image(text) {
  if (typeof text !== "string") return false;
  return text.startsWith("data:image");
}
