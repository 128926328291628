import { useTranslation } from "@opendash/i18n";
import { InputNumber } from "antd";
import React, { useEffect, useState } from "react";

type CustomFormItemProps = {
  value?: number;
  onChange: (value: number) => void;
};

export const TimeSeriesWeeklyInput: React.FC<CustomFormItemProps> = ({
  value,
  onChange,
}) => {
  const t = useTranslation();
  const [currentValue, setCurrentValue] = useState<number>(value);

  function getAddonBefore() {
    switch (currentValue) {
      case 1:
        return t("maintenance:timeseries.weekly.addon.before.1");
      default:
        return t("maintenance:timeseries.weekly.addon.before.multiple");
    }
  }

  function getAddonAfter() {
    switch (currentValue) {
      case 1:
        return t("maintenance:timeseries.weekly.addon.after.1");
      default:
        return t("maintenance:timeseries.weekly.addon.after.multiple");
    }
  }

  useEffect(() => {
    //Propagate the current Value on component activation/when it get's visible
    onChange(currentValue);
  }, []);

  return (
    <InputNumber
      min={1}
      style={{ width: "100%", marginBottom: 24 }}
      addonBefore={getAddonBefore()}
      addonAfter={getAddonAfter()}
      defaultValue={currentValue}
      onChange={(value) => {
        setCurrentValue(value);
        onChange(value);
      }}
    />
  );
};
