import { Icon } from "@opendash/icons";
import React from "react";
import { Description } from "./Description";

type props = {
  message?: string;
  active: boolean;
  height: number;
  width: number;
  style?: React.CSSProperties;
  icon?: string;
  children?: React.ReactNode;
};
const InactiveOverlay = ({
  message,
  active,
  height,
  width,
  style,
  icon,
  children,
}: props) => {
  return (
    <>
      {active && (
        <div
          style={{
            zIndex: 1,
            margin: "auto",
            ...style,
            width: width,
            height: height,
            backdropFilter: "blur(10px)",
            position: "absolute",
            textAlign: "center",
          }}
        >
          {icon && (
            <Icon
              icon={icon}
              size={"4em"}
              color="grey"
              style={{ marginTop: height / 2 - 20 }}
            />
          )}
          {message && <Description>{message}</Description>}
          {children}
        </div>
      )}
    </>
  );
};
export { InactiveOverlay };
