import React from "react";

import { useTranslation } from "@opendash/i18n";

import { Icon } from "@opendash/icons";
import TextArea from "antd/lib/input/TextArea";
import ReactJson, { ReactJsonViewProps } from "react-json-view";

interface JSONEditorProps extends ReactJsonViewProps {
  mode: "editor" | "viewer";
  allowRawEdit?: boolean;
}

export const JSONEditor = React.memo<JSONEditorProps>(
  function AdminToolbar(props) {
    const t = useTranslation();
    const { mode, allowRawEdit } = props;
    const [raw, setRaw] = React.useState<string>(JSON.stringify(props.src));
    const [rawValid, setRawValid] = React.useState<boolean>(true);
    const [showRawEditor, setShowRawEditor] = React.useState<boolean>(false);

    React.useEffect(() => {
      setRaw(JSON.stringify(props.src));
    }, [props.src]);

    return (
      <>
        {allowRawEdit && (
          <Icon
            style={{ position: "absolute", right: 0, top: 0 }}
            icon="fa:code"
            onClick={() => {
              console.log("test");
              setShowRawEditor(!showRawEditor);
            }}
          />
        )}
        {mode !== "viewer" && showRawEditor && (
          <TextArea
            style={{ width: "calc(100% - 24px)" }}
            value={raw}
            onChange={(e) => {
              const text = e.target.value;
              setRaw(text);
              try {
                const newValue = JSON.parse(text);
                if (typeof props.onEdit === "function") {
                  props.onEdit({
                    updated_src: newValue, //new src value
                    name: "root", //new var name
                    namespace: [], //list, namespace indicating var location
                    new_value: newValue, //new variable value
                    existing_value: props.src, //existing variable value
                    existing_src: props.src,
                  });
                }
              } catch (error) {
                if (typeof props.onEdit === "function") {
                  props.onEdit({
                    updated_src: props.src, //new src value
                    name: "root", //new var name
                    namespace: [], //list, namespace indicating var location
                    new_value: props.src, //new variable value
                    existing_value: props.src, //existing variable value
                    existing_src: props.src,
                  });
                }
              }
            }}
          />
        )}

        {mode !== "viewer" && !showRawEditor && (
          <ReactJson style={{ width: "calc(100% - 24px)" }} {...props} />
        )}

        {mode === "viewer" && <ReactJson {...props} />}
      </>
    );
  }
);
