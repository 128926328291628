import { Icon } from "@opendash/icons";
import { Navigate } from "@opendash/router";
import {
  isRouteErrorResponse,
  useRouteError,
} from "@opendash/router/dist/internal";
import { Button, Result } from "antd";
import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "..";

const Container = styled.div`
  width: 100%;

  .ant-result {
    width: 100%;
  }
`;

export const RouterErrorHandler = React.memo(function RouterErrorHandler() {
  const t = useTranslation();
  const error = useRouteError();

  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    if (isRouteErrorResponse(error) && counter > 0 && "status" in error) {
      if (error.status === 401) {
        setTimeout(() => {
          setCounter((c) => c - 1);
        }, 1000);
      }
    }
  }, [counter]);

  React.useEffect(
    () => void console.error("Error in Routing Action:", error),
    [error]
  );

  let title = "opendash:error.internal_title";
  let subTitle = "opendash:error.internal_subtitle";
  let action = "opendash:error.action_home";
  let href = "/";
  let suffix = "";

  if (isRouteErrorResponse(error) && "status" in error) {
    if (error.status === 401) {
      title = "opendash:error.unauthorized_title";
      subTitle = "opendash:error.unauthorized_subtitle";
      action = "opendash:error.action_login";
      href = "/auth/login?url=" + encodeURIComponent(window.location.pathname);

      suffix = ` (${counter}s)`;
    }

    if (error.status === 403) {
      title = "opendash:error.permission_denied_title";
      subTitle = "opendash:error.permission_denied_subtitle";
    }

    if (error.status === 404) {
      title = "opendash:error.not_found_title";
      subTitle = "opendash:error.not_found_subtitle";
    }
  }

  if (counter === 0) {
    return <Navigate to={href} />;
  }

  return (
    <Container>
      <Result
        status="info"
        icon={<Icon icon="fa:exclamation-circle" />}
        title={t(title)}
        subTitle={t(subTitle)}
        extra={[
          <Button
            key="home"
            type="primary"
            children={t(action) + suffix}
            onClick={(e) => {
              window.location.href = href;
            }}
          />,
          // <Button
          //   children={t("opendash:error.reset")}
          //   onClick={(e) => {
          //     reset(true);
          //   }}
          // />,
        ]}
      />
    </Container>
  );
});
