import {
  Boundary,
  createInternalComponent,
  useTranslation,
} from "@opendash/core";
import { Modal } from "antd";
import * as React from "react";
import {
  WidgetSettingsRender,
  useWidgetBaseContextSetup,
  useWidgetContextSetup,
} from "..";

const css = `
  .ant-modal-content {
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 7px;
  }
  .ant-tabs-nav {
    margin-top: -13px !important;
    width: 100% !important;
  }
  

  
  .ant-tabs-tab {
    display: block !important; /* centers text inside tabs */
    flex: 1;
    text-align: center;
  }
  
  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }

`;

interface Props {
  id: string;
  open: boolean;
}

export const WidgetSettingsModal = createInternalComponent<Props>(
  function WidgetSettingsModal({ id, open }) {
    const t = useTranslation();

    const context = useWidgetBaseContextSetup(id);
    const fullContext = useWidgetContextSetup(context);

    if (!context.widget) {
      console.warn(`WidgetSettingsModal: widget "${id}" not found.`);
      return null;
    }

    if (!context.type) {
      console.warn(`WidgetComponent: type "${context.widget.type}" not found.`);
      return null;
    }

    return (
      <Modal
        closable={false}
        open={open}
        okText={t("opendash:ui.save")}
        onOk={() => {
          context.saveSettings();
        }}
        cancelText={t("opendash:ui.cancel")}
        onCancel={(e) => {
          context.cancelSettings();
        }}
        width={Math.min(800, window.innerWidth)}
        styles={{ body: { padding: 0 } }}
      >
        <style dangerouslySetInnerHTML={{ __html: css }} />
        <Boundary>
          <WidgetSettingsRender context={fullContext} />
        </Boundary>
      </Modal>
    );
  }
);
