import { Icon } from "@opendash/icons";
import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "..";

export const Container = styled.div`
  height: auto;
  min-height: 100%;
  width: 80%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
`;

export const ContainerInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

export const FrontPageHero = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  // box-shadow: inset 0px 0px 12px -2px rgba(0, 0, 0, 0.66);
  padding: 5px;
  // border-radius: 10px;
  margin-top: 1px;
`;

const SingleTileContainer = styled.div``;

const SubTile = styled.div`
  width: 80px;
  height: 80px;
  margin: 5px 10px;
  background-color: ${(props) => props.color || "#676767"};
  color: #ffffff;
  z-index: 2;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: normal;

  :hover {
    background: white;
    color: ${(props) => props.color || "#676767"};
  }

  @media (min-width: 480px) {
    width: 110px;
    height: 110px;
  }
`;

const SubTileIconHolder = styled.div`
  margin-top: 5px;
  text-align: center;
  font-size: 20px;

  @media (min-width: 480px) {
    font-size: 30px;
  }
`;

const SubTileLabelnHolder = styled.div`
  text-align: center;
  font-size: 12px;
  padding: 5px;

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

export const Tile = styled.div`
  width: 150px;
  height: 150px;

  @media (min-width: 480px) {
    width: 200px;
    height: 200px;
  }

  margin: 10px;

  background: ${(props) => props.color || "#676767"};
  color: white;

  z-index: 2;

  border: 3px solid ${(props) => props.color || "#676767"};
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  :hover {
    background: white;
    color: ${(props) => props.color || "#676767"};
  }
`;

export const TileInner = styled.div``;

export const LabelHolder = styled.div`
  text-align: center;
  font-size: 16px;
  padding: 10px;
`;

export const IconHolder = styled.div`
  text-align: center;
  font-size: 40px;

  @media (min-width: 480px) {
    font-size: 60px;
  }
`;

export const Element = ({ label, icon, color, onClick, children }: any) => {
  const t = useTranslation();

  return (
    <SingleTileContainer>
      <Tile color={color} onClick={onClick}>
        <TileInner>
          <IconHolder>
            <Icon icon={icon} />
          </IconHolder>
          <LabelHolder>{t(label)}</LabelHolder>
        </TileInner>
      </Tile>
    </SingleTileContainer>
  );
};

export const HeroElement = ({ label, icon, color, onClick, children }: any) => {
  const t = useTranslation();

  return (
    <SingleTileContainer>
      <SubTile color={color} onClick={onClick}>
        <SubTileIconHolder>
          <Icon icon={icon} />
        </SubTileIconHolder>
        <SubTileLabelnHolder>{t(label)}</SubTileLabelnHolder>
      </SubTile>
    </SingleTileContainer>
  );
};
