import { Divider, Select, TreeSelect, Typography } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import React, { useEffect } from "react";
import { arrayToDistinct } from "../helper/arrayToDistinct";
import { treeToArray } from "../helper/arrayToTree";
const { Text } = Typography;

const ALL_VALUE = "SELECT_WITH_ALL_VALUE";
const DIVIDER_VALUE = "SELECT_WITH_ALL_NO_VALUE";

export type DefaultOptionTypeWithChildren = DefaultOptionType & {
  children?: DefaultOptionTypeWithChildren[];
};

type Props<K> = {
  options: K extends true // if isTree is true, the options must be an array of DefaultOptionTypeWithChildren
    ? DefaultOptionTypeWithChildren[]
    : DefaultOptionType[];
  value: Array<string | number>;
  onChange: (value: Array<string | number>) => void;
  allLabel: React.ReactNode;

  isTree?: K; // if true, the options are displayed as a tree
  loading?: boolean;
  title?: React.ReactNode;
  placeholder?: string;
  style?: React.CSSProperties;
  id?: string;
  autoSelectDepth?: number | undefined;
  onSearch?: (searchString: string) => void;
  searchAttribute?: keyof DefaultOptionType;
};

/**
 * A selection input with an option to select all options.
 */
export function SelectWithAll<K>(props: Props<K>) {
  // filter options to check if allOption already exists. if not => add it
  const allOptionExists = props.options.find((opt) => {
    opt.value === ALL_VALUE;
  });

  if (!allOptionExists && props.options.length > 0) {
    const divider: DefaultOptionTypeWithChildren = {
      label: <Divider style={{ margin: "10px 0" }} />,
      disabled: true,
      value: DIVIDER_VALUE,
    };

    const allOption: DefaultOptionTypeWithChildren = {
      label: props.allLabel,
      value: ALL_VALUE,
    };

    props.options.push(divider, allOption);
  }

  // make all options distinct by value
  const optionsDistinct = arrayToDistinct(props.options, (opt) =>
    opt.value?.toString()
  );

  // get all the options in a flat array if it is an tree select
  const flatOptions = props.isTree
    ? treeToArray(
        optionsDistinct,
        (obj) => obj.children as DefaultOptionTypeWithChildren[]
      )
    : optionsDistinct;

  // if all options are present in the value array, display the allValue in selection
  const hasAll = hasAllSelected();
  const displayedValue = hasAll ? [ALL_VALUE] : props.value;

  useEffect(() => {
    if (!props.value) return;

    if (props.value.some((val) => val === ALL_VALUE)) updateStates(props.value);
  }, [props.value]);

  // checks if all values of the options are in the value array
  function hasAllSelected() {
    if (!props.value) return false;

    return (
      props.value.some((val) => val === ALL_VALUE) ||
      props.value.length ===
        flatOptions.filter(
          (option) =>
            option.value !== ALL_VALUE && option.value !== DIVIDER_VALUE
        ).length
    );
  }

  // updates any changes and calls the onChange given to this component with the computed values
  function updateStates(values: Array<string | number>) {
    // if allValue is selected and another value is clicked, remove the allValue
    if (values.length > 1 && values[0] === ALL_VALUE) {
      const filteredValues = values.filter((value) => {
        return value !== ALL_VALUE;
      });

      const valuesWithChildren = getValuesWithChildren(
        filteredValues,
        props.autoSelectDepth ?? 0
      );
      props.onChange && props.onChange(valuesWithChildren);
      return;
    }

    const allOptionsIds = flatOptions
      .filter(
        (option) => option.value !== ALL_VALUE && option.value !== DIVIDER_VALUE
      )
      .map((option) => option.value);

    // if the allOption is clicked or all the options are being selected,
    // switch to allOption and change the value to the array containing all options values
    if (
      (values.length > 1 && values[values.length - 1] === ALL_VALUE) ||
      values.length === flatOptions.length - 1
    ) {
      props.onChange && props.onChange(allOptionsIds);
      return;
    }

    // if values still contains the allValue
    if (values.some((val) => val === ALL_VALUE)) {
      props.onChange && props.onChange(allOptionsIds);
      return;
    }

    const valuesWithChildren = getValuesWithChildren(
      values,
      props.autoSelectDepth ?? 0
    );

    props.onChange && props.onChange(valuesWithChildren);
  }

  // if selected value has children => also select those if childSelect is true
  function getValuesWithChildren(
    valuesToCheck: Array<string | number>,
    depth: number
  ) {
    const returnValue: Array<string | number> = [...valuesToCheck];
    if (depth <= 0) return returnValue;

    valuesToCheck.forEach((value) => {
      // find the option of the checked value if it has children and was the value triggering the onChange event
      const optWithChilren = flatOptions.find(
        (opt) =>
          value === opt.value &&
          opt.children?.length > 0 &&
          (!props.value.find((currentValue) => currentValue === value) ||
            hasAllSelected())
      );

      // if an option was found, add all children that are not already selected
      if (optWithChilren)
        optWithChilren.children.forEach((child) => {
          if (!valuesToCheck.includes(child.value))
            returnValue.push(child.value);
        });
    });

    return returnValue.length !== valuesToCheck.length
      ? getValuesWithChildren(returnValue, depth - 1)
      : returnValue;
  }

  return (
    <div>
      <Text>{props.title}</Text>
      {!props.isTree && (
        <Select
          id={props.id}
          style={props.style}
          options={optionsDistinct}
          allowClear
          showSearch
          mode="multiple"
          onChange={updateStates}
          value={displayedValue}
          placeholder={props.placeholder}
          loading={props.loading}
          onSearch={props.onSearch ?? (() => null)}
          optionFilterProp={props.searchAttribute?.toString() ?? "label"}
        />
      )}
      {props.isTree && (
        <TreeSelect
          id={props.id}
          style={props.style}
          treeData={optionsDistinct}
          multiple
          showSearch
          treeDefaultExpandAll
          value={displayedValue}
          onChange={updateStates}
          loading={props.loading}
          onSearch={props.onSearch ?? (() => null)}
          treeNodeFilterProp={props.searchAttribute?.toString() ?? "label"}
          placeholder={props.placeholder}
        />
      )}
    </div>
  );
}
