import { useTranslation } from "@opendash/core";
import { Input } from "antd";
import React from "react";

export interface StringTextDisplayProps {
  value: string;
  isInline: boolean;
}

export const StringTextDisplay = React.memo<StringTextDisplayProps>(
  function StringTextDisplay({ value, isInline }) {
    const rows = value.split("\n");

    if (isInline) {
      return <span>{rows[0] + (rows.length > 1 ? "..." : "")}</span>;
    } else {
      return (
        <>
          {rows.map((row, i) => (
            <p key={i}>{row}</p>
          ))}
        </>
      );
    }
  }
);

export interface StringTextEditProps {
  value: string;
  setValue?: (v: string) => void;
  isRequired: boolean;
  isNullable: boolean;
}

export const StringTextEdit = React.memo<StringTextEditProps>(
  function StringTextEdit({ value, setValue }) {
    const t = useTranslation();

    return (
      <Input.TextArea
        placeholder={t("parse-admin:admin.inputs.string_placeholder")}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoSize={{
          minRows: 3,
          maxRows: 10,
        }}
      />
    );
  }
);
