import React from "react";

import { AdminLayout } from "@opendash/core";
import { Route, Routes, useNavigate, useParams } from "@opendash/router";
import { useParseGet } from "parse-hooks";

import { AdapterOptions } from "../";
import { $parse } from "../state";

import { ParseObject } from "./ParseObject";
import { ParseSetView } from "./ParseSetView";

const ParseAdminTableRoute = React.memo(
  function ParseAdminTableRoute(pagination: { paginations }) {
    const { className } = useParams();

    return (
      <ParseSetView className={className} pagination={pagination.paginations} />
    );
  }
);

const ParseObjectViewRoute = React.memo(function ParseAdminTableRoute() {
  const { className, objectId } = useParams();

  // @ts-ignore
  const { result: object } = useParseGet(className, objectId);

  return <ParseObject object={object} />;
});

export function ParseAdminInterface(cfg: AdapterOptions) {
  return (
    <AdminLayout contentPadding={true}>
      <Routes>
        <Route
          path=":className"
          element={<ParseAdminTableRoute paginations={cfg.usePagination} />}
        />
        <Route
          path=":className/view/:viewType"
          element={<ParseAdminTableRoute paginations={cfg.usePagination} />}
        />
        <Route path=":className/:objectId" element={<ParseObjectViewRoute />} />
        <Route path="*" element={<DefaultRoute />} />
      </Routes>
    </AdminLayout>
  );
}

function DefaultRoute({}) {
  const navigate = useNavigate();

  const className = Object.keys($parse.ui.configMap)[0];

  React.useEffect(() => {
    if (className) {
      navigate(`/admin/parse/${className}`);
    }
  }, []);

  return null;
}
