import { useTranslation } from "@opendash/core";
import { Divider, Form, Input, Radio, Select } from "antd";
import dayjs from "dayjs";
import produce from "immer";
import React, { useState } from "react";

const { Option } = Select;
export default (props) => {
  const t = useTranslation();
  const data = props.Data;
  const formData = props.FormData;
  const rules = [];
  if (data["v_required"] && data["v_required"].value) {
    rules.push({
      required: data["v_required"].value,
      message: data["v_required"].err,
    });
  }
  const start = dayjs(props.Start);
  if (props.Mode == "single") {
    start.set("hours", 0);
    start.set("minutes", 0);
    start.set("seconds", 0);
  }
  const [pickerState, setPickerState] = useState({
    mode: props.Mode || "single",

    now: start,
    end: props.End
      ? dayjs(props.End)
      : dayjs(props.Start).add(Math.min(props.Item?.sollzeit || 1, 8), "hour"),
  });
  React.useEffect(() => {
    if (!formData) return;
    const x = {};

    if (pickerState.mode == "single") {
      x[data.key] =
        pickerState.now.hour() * (1000 * 60 * 60) +
        pickerState.now.minute() * (1000 * 60);
      if (props.ShowSeconds) {
        x[data.key] += pickerState.now.second() * 1000;
      }
    } else {
      x[data.key] = pickerState.now.diff(pickerState.end) * -1;
    }

    formData.setFieldsValue(x);
  }, [pickerState.now, pickerState.end, pickerState.mode]);

  const getPicker = (end) => {
    const now = !end ? pickerState.now : pickerState.end;
    if (!now) return "";

    const prefix = !end ? props.PrefixStart : props.PrefixEnd;
    const suffix = !end ? props.SuffixStart : props.SuffixEnd;
    const seconds = props.ShowSeconds;
    const setHours = (e) => {
      setPickerState(
        produce((draft) => {
          const newNow = dayjs(now).set("hours", e);
          if (!end) {
            draft.now = newNow;
          } else {
            draft.end = newNow;
          }
        })
      );
    };
    const setMinutes = (e) => {
      setPickerState(
        produce((draft) => {
          const newNow = dayjs(now).set("minutes", e);
          if (!end) {
            draft.now = newNow;
          } else {
            draft.end = newNow;
          }
        })
      );
    };
    const setSeconds = (e) => {
      setPickerState(
        produce((draft) => {
          let newNow = dayjs(now).set("seconds", e);
          newNow = newNow;
          if (!end) {
            draft.now = newNow;
          } else {
            draft.end = newNow;
          }
        })
      );
    };
    return (
      <div>
        {end ? <Divider /> : ""}
        <div className="timespan_picker_single">
          {prefix}
          <Select
            listItemHeight={80}
            listHeight={320}
            size="large"
            value={now.hour()}
            onChange={setHours}
            bordered={false}
            suffixIcon={t("bde:app.forms.timpicker.suffix.hour")}
          >
            {[...Array(24).keys()].map((hour) => {
              return (
                <Option className="select_option" value={hour}>
                  {hour < 10 ? "0" + hour : hour}
                </Option>
              );
            })}
          </Select>
          <span>:</span>
          <Select
            className="timepicker"
            listItemHeight={80}
            listHeight={320}
            size="large"
            value={now.minute()}
            onChange={setMinutes}
            bordered={false}
            suffixIcon={t("bde:app.forms.timpicker.suffix.minute")}
          >
            {[...Array(60).keys()].map((min) => {
              return (
                <Option className="select_option" value={min}>
                  {min < 10 ? "0" + min : min}
                </Option>
              );
            })}
          </Select>
          {seconds ? (
            <>
              <span>:</span>
              <Select
                className="timepicker"
                listItemHeight={80}
                listHeight={320}
                size="large"
                value={now.second()}
                onChange={setSeconds}
                bordered={false}
                suffixIcon={t("bde:app.forms.timpicker.suffix.second")}
              >
                {[...Array(60).keys()].map((sec) => {
                  return (
                    <Option className="select_option" value={sec}>
                      {sec < 10 ? "0" + sec : sec}
                    </Option>
                  );
                })}
              </Select>
            </>
          ) : (
            ""
          )}
          {suffix}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Form.Item
        className="hidden_custom_form"
        name={data.key}
        label={t("bde:" + data.title)}
        rules={rules}
        style={{ marginBottom: "0px" }}
      >
        <Input style={{ display: "none" }} />
      </Form.Item>
      <div className="timepicker_drawer" style={{ textAlign: "center" }}>
        <Radio.Group
          style={{ width: "100%" }}
          options={[
            {
              label: t("bde:" + "app.forms.timpicker.duration"),
              value: "single",
            },
            {
              label: t("bde:" + "app.forms.timpicker.startstop"),
              value: "complete",
            },
          ]}
          onChange={(e) => {
            setPickerState(
              produce((draft) => {
                draft.mode = e.target.value;
              })
            );
          }}
          value={pickerState.mode}
          optionType="button"
          buttonStyle="solid"
        />
        <div
          className="timespan_picker"
          style={{
            margin: "20px",
            width: "calc(100% - 40px)",
            textAlign: "center",
          }}
        >
          {getPicker(false)}
          {pickerState.mode === "complete" && getPicker(true)}
        </div>
      </div>
    </div>
  );
};
